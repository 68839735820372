<template>
  <div class="selected-preview">
    <div class="preview-header">
      <h3>Selected Items ({{ selectedCount }})</h3>
      <button v-if="selectedCount" @click="$emit('clear')" class="clear-btn">
        Clear all
      </button>
    </div>
    <div class="preview-content">
      <div v-if="selectedCount" class="selected-items">
        <div v-for="item in selectedItems" :key="item.id" class="selected-item">
          <span class="item-name">{{ item.name }}</span>
          <button @click="$emit('remove', item.id)" class="remove-btn">
            ×
          </button>
        </div>
      </div>
      <div v-else class="no-selection">No items selected</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectedPreview",
  props: {
    selectedItems: {
      type: Array,
      required: true,
    },
  },
  computed: {
    selectedCount() {
      return this.selectedItems.length;
    },
  },
  emits: ["remove", "clear"],
};
</script>

<style scoped>
.selected-preview {
  margin-top: 16px;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  background: white;
  margin-bottom: 15px;
  width: 51%;
}

.preview-header {
  padding: 12px 16px;
  border-bottom: 1px solid #e4e7ed;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h3 {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: #606266;
}

.clear-btn {
  background: none;
  border: none;
  color: #009995;
  cursor: pointer;
  font-size: 12px;
  padding: 1px 8px;
  box-shadow: none !important;
}

.clear-btn:hover {
  color: #009995;
  border: none !important;
  box-shadow: none !important;
}

.preview-content {
  padding: 12px 16px;
  max-height: 200px;
  overflow-y: auto;
}

.selected-items {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.selected-item {
  display: flex;
  align-items: center;
  background: #f4f4f5;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 12px;
}

.item-name {
  color: #606266;
}

.remove-btn {
  background: none;
  border: none;
  color: #909399;
  cursor: pointer;
  font-size: 14px;
  padding: 0 0 0 4px;
  line-height: 1;
}

.remove-btn:hover {
  color: #f56c6c;
}

.no-selection {
  color: #909399;
  font-size: 14px;
  text-align: center;
  padding: 20px 0;
}
</style>
