<template>
  <v-col :cols="12" :lg="6" class="d-flex ga-5 align-center">
    <div class="d-flex ga-1 align-center input-data-text">
      <AssigneeIcon />
      <label class="calender-input-label text-capitalize">assignee</label>
    </div>
    <div>
      <v-menu>
        <template v-slot:activator="{ props }">
          <span
            v-bind="props"
            class="assignee-list-selected-option d-flex align-center ga-2"
          >
            <img
              src="@/assets/images/Avatar.svg"
              class="circle-img"
              :alt="store.assignee.name"
            />
            <span class="text-capitalize small-text">
              {{ store.assignee.name }}
            </span>
          </span>
        </template>
        <v-list class="assignee-list">
          <v-list-item
            v-for="(assignee, i) in assigneeList"
            :key="i"
            @click="selectAssignee(assignee)"
            :class="{ active: assignee.name === store.assignee.name }"
          >
            <span class="assignee-list-option d-flex align-center ga-2">
              <img
                src="@/assets/images/Avatar.svg"
                class="circle-img"
                :alt="assignee.name"
              />
              <span class="text-capitalize">{{ assignee.name }}</span>
            </span>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-col>
</template>

<script>
import { useTaskStore } from "@/store/taskStore";
import AssigneeIcon from "./icons/AssigneeIcon.vue";

export default {
  name: "TaskAssignee",
  components: {
    AssigneeIcon,
  },
  data() {
    return {
      store: useTaskStore(),
      assigneeList: [
        { id: 1, name: "Mohamed Ahmed", img: "@/assets/images/Avatar.svg" },
        { id: 2, name: "Ahmed Mohamed", img: "@/assets/images/Avatar.svg" },
        { id: 3, name: "Mahmoud Ahmed", img: "@/assets/images/Avatar.svg" },
        { id: 4, name: "Omar Ahmed", img: "@/assets/images/Avatar.svg" },
        { id: 5, name: "Ahmed Omar", img: "@/assets/images/Avatar.svg" },
      ],
    };
  },
  methods: {
    selectAssignee(assignee) {
      this.store.updateAssignee(assignee);
    },
  },
};
</script>
