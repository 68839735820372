import SelectMetaObjective from "@/views/campains/meta/SelectMetaObjective.vue";
import MetaIndex from "@/views/campains/meta/MetaIndex.vue";
import MetaSecoundStep from "@/views/campains/meta/MetaSecoundStep.vue";
import MestaLastStep from "@/views/campains/meta/MestaLastStep.vue";
import SelectAcountId from "@/views/campains/meta/SelectAcountId.vue";

const metaRoutes = [
  {
    path: "/meta",
    name: "SelectMetaObjective",
    component: SelectMetaObjective,
    meta: {
      title: "Meta",
      description: "",
    },
  },
  {
    path: "/meta-adsmanager",
    name: "MetaIndex",
    component: MetaIndex,
    meta: {
      title: "Meta",
      description: "",
    },
  },
  {
    path: "/meta-adsmanager/manage",
    name: "MetaSecoundStep",
    component: MetaSecoundStep,
    meta: {
      title: "Meta",
      description: "",
    },
  },
  {
    path: "/meta/manage/ads",
    name: "MetaLastStep",
    component: MestaLastStep,
    meta: {
      title: "Meta",
      descriptors: "",
    },
  },
  {
    path: "/meta/manage/ads/account_id",
    name: "SelectAcountId",
    component: SelectAcountId,
    meta: {
      title: "Meta",
      descriptors: "",
    },
  },
];

export default metaRoutes;
