<template>
  <router-view></router-view>
</template>

<script>

import "@/assets/snapchatStyle/snapchatStyle.css";
export default {
  mounted() {
    this.$router.push({ name: "SnapFirstStep" });
  },
  methods: {
    goTonextStep() {
      this.$router.push({ name: "SnapSecoundStep" });
    },
  },
};
</script>

