<template>
  <v-col :cols="12" :lg="9">
    <div class="bold-text mb-2">Post Review</div>
    <div
      v-if="store.selectedBranch"
      class="post-review d-flex flex-column ga-3"
    >
      <div class="body d-flex justify-space-between align-center">
        <div class="branch d-flex align-center ga-2">
          <img
            class="circle-img"
            :src="store.selectedBranch.image"
            :alt="store.selectedBranch.name"
          />
          <span>{{ store.selectedBranch.name }}</span>
        </div>
        <div class="sponsored" v-if="store.postType === 'sponsored'">
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_2618_16918)">
              <path
                d="M19.9997 10.2499C19.9997 8.83652 19.283 7.59069 18.193 6.85569C18.4439 5.56569 18.0697 4.17819 17.0705 3.17819C16.0714 2.17902 14.6839 1.80486 13.393 2.05569C12.658 0.96569 11.4122 0.249023 9.99888 0.249023C8.58555 0.249023 7.33971 0.96569 6.60471 2.05569C5.31471 1.80486 3.92638 2.17902 2.92721 3.17819C1.92805 4.17736 1.55388 5.56486 1.80471 6.85569C0.714714 7.59069 -0.00195312 8.83652 -0.00195312 10.2499C-0.00195312 11.6632 0.714714 12.909 1.80471 13.644C1.55388 14.934 1.92805 16.3224 2.92721 17.3215C3.92638 18.3207 5.31388 18.6949 6.60471 18.444C7.33971 19.534 8.58555 20.2507 9.99888 20.2507C11.4122 20.2507 12.658 19.534 13.393 18.444C14.683 18.6949 16.0714 18.3207 17.0705 17.3215C18.0697 16.3224 18.4439 14.9349 18.193 13.644C19.283 12.909 19.9997 11.6632 19.9997 10.2499ZM8.86971 9.21652L11.4039 9.63902C12.5222 9.82486 13.333 10.7832 13.333 11.9165C13.333 13.2949 12.2114 14.4165 10.833 14.4165V16.0832H9.16638V14.4165C7.78805 14.4165 6.66638 13.2949 6.66638 11.9165H8.33305C8.33305 12.3757 8.70721 12.7499 9.16638 12.7499H10.833C11.293 12.7499 11.6664 12.3757 11.6664 11.9165C11.6664 11.6015 11.4405 11.3349 11.1297 11.2832L8.59471 10.8607C7.47721 10.6749 6.66555 9.71652 6.66555 8.58319C6.66555 7.20486 7.78721 6.08319 9.16555 6.08319V4.41652H10.8322V6.08319C12.2105 6.08319 13.3322 7.20486 13.3322 8.58319H11.6655C11.6655 8.12402 11.2922 7.74986 10.8322 7.74986H9.16555C8.70638 7.74986 8.33221 8.12402 8.33221 8.58319C8.33221 8.89819 8.55805 9.16486 8.86888 9.21652H8.86971Z"
                fill="#237276"
              />
            </g>
            <defs>
              <clipPath id="clip0_2618_16918">
                <rect
                  width="20"
                  height="20"
                  fill="white"
                  transform="translate(0 0.25)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
      <div class="text">{{ store.caption || "No caption added yet" }}</div>
      <div class="footer">
        <div class="platforms d-flex ga-2">
          <v-icon
            v-for="platform in store.selectedPlatforms"
            :key="platform.id"
            :icon="platform.icon"
            :color="platform.color"
            size="small"
          ></v-icon>
        </div>
      </div>
    </div>
    <div v-else class="no-branch-selected">
      Please select a branch to see the post review
    </div>
  </v-col>
</template>

<script>
import { useTaskStore } from "@/store/taskStore";

export default {
  name: "TaskPostReview",
  data() {
    return {
      store: useTaskStore(),
    };
  },
};
</script>

<style scoped>
.no-branch-selected {
  color: #666;
  font-style: italic;
  padding: 1rem;
  text-align: center;
  background: #f5f5f5;
  border-radius: 8px;
}
</style>
