<template>
  <div class="card-pattern identify-card-meta">
    <div class="card-header mb-4 d-flex align-center justify-space-between">
      <label for="">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#007e59"
        >
          <path
            d="m423.23-309.85 268.92-268.92L650-620.92 423.23-394.15l-114-114L267.08-466l156.15 156.15ZM480.07-100q-78.84 0-148.21-29.92t-120.68-81.21q-51.31-51.29-81.25-120.63Q100-401.1 100-479.93q0-78.84 29.92-148.21t81.21-120.68q51.29-51.31 120.63-81.25Q401.1-860 479.93-860q78.84 0 148.21 29.92t120.68 81.21q51.31 51.29 81.25 120.63Q860-558.9 860-480.07q0 78.84-29.92 148.21t-81.21 120.68q-51.29 51.31-120.63 81.25Q558.9-100 480.07-100Zm-.07-60q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
          />
        </svg>
        <span class="roboto-black">Identity</span>
      </label>
      <div
        class="d-flex align-center ga-2 actions-card-header"
        v-if="store.partnership_status"
      >
        <button>Edit</button>
        <button>
          <svg
            width="16"
            height="16"
            fill="#000"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path
              d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0L284.2 0c12.1 0 23.2 6.8 28.6 17.7L320 32l96 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 96C14.3 96 0 81.7 0 64S14.3 32 32 32l96 0 7.2-14.3zM32 128l384 0 0 320c0 35.3-28.7 64-64 64L96 512c-35.3 0-64-28.7-64-64l0-320zm96 64c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16z"
            />
          </svg>
        </button>
      </div>
    </div>
    <div class="card-body identify-card-body">
      <div class="facebook-page mb-3">
        <label for="" class="d-block mb-1 roboto-bold">* Facebook Page</label>
        <v-select
          v-model="chargedSelectedItem"
          :items="facebookPages"
          disabled
          density="compact"
          variant="outlined"
          return-object
          item-value="id"
          item-title="title"
          hide-details
        >
          <template v-slot:selection="{ item }">
            <img
              style="
                width: 20px;
                height: 20px;
                border-radius: 50%;
                margin-right: 10px;
              "
              :src="item.raw?.imageUrl"
              alt=""
            />
            {{ item.raw?.name || "" }}
          </template>
          <template v-slot:item="{ item }">
            <v-list-item @click="setChargedPage(item)">
              <div class="my-form">
                <div class="ga-3">
                  <input
                    :checked="
                      chargedSelectedItem &&
                      chargedSelectedItem.id === item.raw.id
                    "
                    :id="item.raw.id"
                    type="radio"
                    name="selectedPageId"
                    :value="item"
                  />
                  <div class="d-flex align-center ga-1">
                    <img :src="item.raw.imageUrl" alt="" />
                    <label
                      :for="item.raw.id"
                      class="d-block"
                      style="font-size: 14px"
                    >
                      {{ item.raw?.name }}
                    </label>
                  </div>
                </div>
              </div>
            </v-list-item>
          </template>
        </v-select>
      </div>
      <div class="instagram-account mb-3">
        <label class="d-block mb-1 roboto-bold" for="">Instagram account</label>
        <v-select
          v-model="selectedInstagramPage"
          :items="instagramPages"
          density="compact"
          variant="outlined"
          return-object
          item-value="id"
          item-title="title"
          hide-details
        >
          <template v-slot:selection="{ item }">
            <img
              style="
                width: 20px;
                height: 20px;
                border-radius: 50%;
                margin-right: 10px;
              "
              :src="item.raw.imageUrl"
              alt=""
            />
            {{ item?.title || "" }}
          </template>
          <template v-slot:item="{ item }">
            <v-list-item @click="setInstagremAccount(item)">
              <div class="my-form">
                <div class="ga-3">
                  <input
                    :checked="
                      selectedInstagramPage &&
                      selectedInstagramPage.id === item.raw.id
                    "
                    :id="item.raw.id"
                    type="radio"
                    name="option"
                    :value="item"
                  />
                  <div class="d-flex align-center ga-1">
                    <img
                      style="
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        margin-right: 10px;
                      "
                      :src="item.raw.imageUrl"
                      alt=""
                    />
                    <label
                      :for="item.raw.id"
                      class=""
                      style="font-size: 14px"
                      >{{ item.title }}</label
                    >
                  </div>
                </div>
              </div>
            </v-list-item>
          </template>
        </v-select>
      </div>
    </div>
  </div>
</template>

<script>
import { meta } from "@/store/meta/meta.js";
import apiClient from "@/core/buildRequest";
export default {
  data() {
    return {
      store: meta(),
      facebookPages: [],
      chargedSelectedItem: [],
      instagramPages: [],
      selectedInstagramPage: null,
    };
  },
  mounted() {
    const storedData = localStorage.getItem("facebook_page_data");
    try {
      this.chargedSelectedItem = storedData ? JSON.parse(storedData).raw : [];
    } catch (error) {
      console.error(
        "Error parsing localStorage data for facebook_page_data:",
        error
      );
      this.chargedSelectedItem = [];
    }
    this.getInstigramPages();
  },
  methods: {
    setInstagremAccount(item) {
      this.selectedInstagramPage = item.raw;
      localStorage.setItem("instagram_account", item.raw.id);
    },
    getInstigramPages() {
      apiClient.get("/publish-posts/all/pages").then((response) => {
        console.log(response.data.data.data);
        let data = response.data.data.data;

        this.instagramPages = data
          .filter((item) => item.instagram_business_account) 
          .map((item) => ({
            id: item.instagram_business_account.id,
            title: item.name,
            imageUrl: item.picture?.data?.url || "", 
          }));
          this.selectedInstagramPage = this.instagramPages.length > 0 ? this.instagramPages[0] : null;

      }).catch((error) => {
        console.log(error);
      });
    },
  },
};
</script>
