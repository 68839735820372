<template>
  <div class="">getting token for this : {{ code }}</div>
</template>

<script>
import apiClient from "@/core/buildRequest";

export default {
  name: "GoogleDriveRedirectView",
  data() {
    return {
      code: "",
    };
  },
  mounted() {
    this.getGoogleDriveToken();
  },
  methods: {
    getGoogleDriveToken() {
      const code = this.$route.query.code;
      this.code = this.$route.query.code;

      if (code) {
        const body = {
          code: code,
          user_id: JSON.parse(localStorage.getItem("user")).data.id,
        };
        console.log(body);
        apiClient
          .post("/auth/google/drive/callback", body)
          .then((response) => {
            const access_token = response.data.data.access_token;
            const access_token_expire_at = response.data.data.expire_date;
            localStorage.setItem("drive_token", access_token);
            localStorage.setItem(
              "google_driveTokenExpireAt",
              access_token_expire_at
            );
            this.$router.push({ name: "integrations" });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        console.error("OAuth code not found in query parameters.");
      }
    },
  },
};
</script>
