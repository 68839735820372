<template>
  <!-- Tracking  -->
  <div class="tracking-card ad-set-details-card mb-6">
    <h6 class="page-header-title mb-3">Tracking</h6>
    <v-card max-width="100%" class="pa-5 mb-5 rounded-lg" elevation="1">
      <div class="card-header">
        <label for="" class="d-block status-title">Snap Pixel </label>
        <switch-toogle
          :checked="snap_pixel"
          @tooglePaused="toogle_snap_pixel"
        ></switch-toogle>
        <div class="mb-4"></div>
        <label for="" class="d-block status-title"
          >Snap <strong>App ID Tracking</strong>
        </label>
        <switch-toogle
          :checked="app_tracking_id"
          @tooglePaused="toogle_app_tracking_id"
        ></switch-toogle>
        <div
          class="alert-for-app mt-5"
          v-if="
            app_tracking_id &&
            (selectedOption === 'Awareness & Engagement' ||
              selectedOption === 'Traffic' ||
              selectedOption === 'App Promotion')
          "
        >
          <div class="warnning-card">
            <h4>Have you added your app?</h4>
            <p>
              We haven’t found any Snap App IDs associated with this ad account.
              Make sure to authenticate your app in
              <a href="#">Business Manager</a>
              and associate it with this ad account.
            </p>
            <p>
              App events tracking via Snap App ID unlocks several benefits such
              as Mobile App Custom Audiences or App Re-engagement Optimization
              Goals. This is optional but recommended.
            </p>
            <a
              href="https://businesshelp.snapchat.com/s/article/snap-app-id?language=en_US"
              >Learn More about Snap App IDs</a
            >
          </div>
          <div class="snap-app">
            <label for="">Snap App ID </label>
            <v-select
              variant="solo"
              density="compact"
              style="
                background: #e1e3e5
                  repeating-linear-gradient(
                    45deg,
                    #d1d3d5,
                    #d1d3d5 2px,
                    #e1e3e5 0,
                    #e1e3e5 10px
                  );
              "
              disabled
            ></v-select>
            <p class="app-id-validate mb-5">
              <span class="d-block">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="#444546"
                  xmlns="http://www.w3.org/2000/svg"
                  class="sds-icon sds-icon-incomplete sds-input-status styles-1hnbkxj"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13 8C13 10.7614 10.7614 13 8 13V8V3C10.7614 3 13 5.23858 13 8ZM8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2Z"
                  ></path>
                </svg>
              </span>
              Snap App ID is required
            </p>
            <label class="d-block mb-1" for=""
              ><strong>SKAdNetwork</strong>
            </label>
            <switch-toogle disabled="true"></switch-toogle>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import SwitchToogle from "@/components/snapchat/Label&input/SwitchToogle.vue";

export default {
  components: { SwitchToogle },
  data() {
    return {
      snap_pixel: false,
      app_tracking_id: false,
      selectedOption: localStorage.getItem("selectedOption"),
    };
  },
  methods: {
    toggleAttached() {
      this.snap_pixel = true;
    },

    toogle_snap_pixel() {
      this.snap_pixel = !this.snap_pixel;
      localStorage.setItem("snap_pixel", this.snap_pixel);
    },
    toogle_app_tracking_id() {
      this.app_tracking_id = !this.app_tracking_id;
      localStorage.setItem("app_tracking_id", this.snap_pixel);
    },
  },
  watch: {},
  mounted() {
    // this.snap_pixel = localStorage.getItem("snap_pixel") === "true";
    // this.app_tracking_id = localStorage.getItem("app_tracking_id") === "true";
    if (localStorage.getItem("app_tracking_id")) {
      this.app_tracking_id = localStorage.getItem("app_tracking_id") === "true";
    } else {
      this.app_tracking_id = true;
      localStorage.setItem("app_tracking_id", this.app_tracking_id);
    }
    if (localStorage.getItem("snap_pixel")) {
      this.snap_pixel = localStorage.getItem("snap_pixel") === "true";
    } else {
      this.snap_pixel = true;
      localStorage.setItem("snap_pixel", this.snap_pixel);
    }
  },
};
</script>
