<template>
  <div class="card-pattern ios-campaign-card-meta">
    <div class="card-header mb-2 d-flex align-center justify-space-between">
      <label for="" class="d-flex align-center">
        <svg
          v-if="iosCampaign"
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#007e59"
        >
          <path
            d="m423.23-309.85 268.92-268.92L650-620.92 423.23-394.15l-114-114L267.08-466l156.15 156.15ZM480.07-100q-78.84 0-148.21-29.92t-120.68-81.21q-51.31-51.29-81.25-120.63Q100-401.1 100-479.93q0-78.84 29.92-148.21t81.21-120.68q51.29-51.31 120.63-81.25Q401.1-860 479.93-860q78.84 0 148.21 29.92t120.68 81.21q51.31 51.29 81.25 120.63Q860-558.9 860-480.07q0 78.84-29.92 148.21t-81.21 120.68q-51.29 51.31-120.63 81.25Q558.9-100 480.07-100Zm-.07-60q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
          />
        </svg>
        <span class="roboto-black">iOS 14+ campaign </span>
      </label>
      <div class="d-flex align-center ga-2">
        <span class="roboto-light" v-if="!iosCampaign">Off</span>
        <span class="roboto-light" v-else>On</span>
        <label class="toggle-switch">
          <input type="checkbox" v-model="iosCampaign" />
          <div class="toggle-switch-background">
            <div class="toggle-switch-handle"></div>
          </div>
        </label>
      </div>
    </div>
    <div class="card-body">
      <p class="roboto-regular">
        Create a campaign to help you reach people using iOS 14.5 and later
        devices. An iOS 14+ campaign will not deliver to devices using iOS 13.7
        or earlier. Learn more
      </p>
      <div class="select-app mt-3" v-if="iosCampaign" >
        <label for="" class="roboto-bold">App</label>
        <div class="search-container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="20px"
            viewBox="0 -960 960 960"
            width="20px"
            fill="#434343"
          >
            <path
              d="M765-144 526-383q-30 22-65.79 34.5-35.79 12.5-76.18 12.5Q284-336 214-406t-70-170q0-100 70-170t170-70q100 0 170 70t70 170.03q0 40.39-12.5 76.18Q599-464 577-434l239 239-51 51ZM384-408q70 0 119-49t49-119q0-70-49-119t-119-49q-70 0-119 49t-49 119q0 70 49 119t119 49Z"
            />
          </svg>
          <input
            type="search"
            placeholder="Search for an app"
            class="search-input"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      iosCampaign: false,
    };
  },
  watch: {
    iosCampaign() {
      localStorage.setItem("is_skadnetwork_attribution", this.iosCampaign);
    },
  },
};
</script>
