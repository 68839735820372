<template>
  <div class="card-pattern partnership-ad">
    <div class="card-header mb-0 d-flex align-center justify-space-between">
      <label for="" class="">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#007e59"
        >
          <path
            d="m423.23-309.85 268.92-268.92L650-620.92 423.23-394.15l-114-114L267.08-466l156.15 156.15ZM480.07-100q-78.84 0-148.21-29.92t-120.68-81.21q-51.31-51.29-81.25-120.63Q100-401.1 100-479.93q0-78.84 29.92-148.21t81.21-120.68q51.29-51.31 120.63-81.25Q401.1-860 479.93-860q78.84 0 148.21 29.92t120.68 81.21q51.31 51.29 81.25 120.63Q860-558.9 860-480.07q0 78.84-29.92 148.21t-81.21 120.68q-51.29 51.31-120.63 81.25Q558.9-100 480.07-100Zm-.07-60q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
          />
        </svg>
        <span class="roboto-black">Partnership ad</span>
      </label>
      <div class="d-flex align-center ga-2">
        <span class="roboto-light" v-if="!store.partnership_status">Off</span>
        <span class="roboto-light" v-else>On</span>
        <label class="toggle-switch">
          <input type="checkbox" v-model="store.partnership_status" />
          <div class="toggle-switch-background">
            <div class="toggle-switch-handle"></div>
          </div>
        </label>
      </div>
    </div>
    <div class="card-body partnership-ad-body">
      <p class="roboto-regular text-description">
        Run ads with creators, brands and other businesses. These ads will
        feature both identities in the header. Learn more
      </p>
      <div class="posts mt-2" v-if="store.partnership_status">
        <p class="roboto-bold">Posts</p>
        <span class="post-desc">
          View and select from your partner's posts using their ad code, or
          search for specific posts by post ID or URL.
        </span>
      </div>
      <div class="enter-url-code" v-if="store.partnership_status">
        <button @click="dialog = true">Enter ad code or post info</button>
        <v-dialog v-model="dialog" width="1200" class="enter-url-code-dialog">
          <v-card
            max-width="1200"
            title="Enter partnership ad code, post ID or post URL"
          >
            <v-card-text>
              <v-container class="pa-0 enter-url-code-container">
                <v-row>
                  <v-col cols="8">
                    <p class="roboto-regular mb-4">
                      This will set the first identity of the partnership ad and
                      will use the media associated with the code or post info.
                      For partnership ad code, contact the post's creator and
                      request that they share it with you. For post ID or post
                      URL, make sure that you have account-level permissions
                      from the creator to run your ad with the associated media.
                      Learn how to use ad codes, post ID or post URL
                    </p>
                    <v-text-field
                      placeholder="Enter ad code provided by the creator, or post ID or post URL"
                      variant="outlined"
                      density="compact"
                      class="mt-3"
                      hide-details
                      single-line
                      v-model="isFormValid"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="4"
                    class="pa-0 partener-ship-preview"
                    style="background-color: #f2f2f2"
                  >
                    <div class="partenter-ship-container pa-3">
                      <p class="title">Partnership ad preview</p>
                      <v-select
                        disabled="true"
                        v-model="chargedSelectedItem"
                        :items="partnershipItems"
                        density="compact"
                        variant="outlined"
                        return-object
                        item-value="id"
                        item-title="title"
                        hide-details
                      >
                        <template v-slot:selection="{ item }">
                          {{ item?.title || "" }}
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list-item>
                            <span :v-html="item.raw.imgUrl"></span>
                            <label
                              :for="item.raw.id"
                              class="d-block"
                              style="font-size: 14px"
                              >{{ item.title }}</label
                            >
                          </v-list-item>
                        </template>
                      </v-select>

                      <div class="instagram-preview">
                        <div class="instagram-header">
                          <div class="left-header position-relative">
                            <img src="@/assets/images/meta/1.png" alt="" />
                            <img
                              src="@/assets/images/meta/2.png"
                              class="secound-img"
                              alt=""
                            />
                          </div>
                          <div class="right-header">
                            <p class="d-flex align-center ga-1">
                              <span class="roboto-bold">Instagram</span>
                              <span class="roboto-regular">and</span>
                              <span class="roboto-bold">Snapchat</span>
                            </p>
                            <span class="sponsored d-block">Sponsored </span>
                          </div>
                        </div>
                        <div class="instagram-body">
                          <img
                            src="@/assets/images/meta/photo_8647307 1.svg"
                            alt=""
                          />
                        </div>
                        <div
                          class="instagram-footer d-flex align-center justify-space-between px-2"
                        >
                          <div class="left-footer d-flex align-center ga-1">
                            <svg
                              width="20"
                              height="20"
                              fill="#000"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path
                                d="M225.8 468.2l-2.5-2.3L48.1 303.2C17.4 274.7 0 234.7 0 192.8l0-3.3c0-70.4 50-130.8 119.2-144C158.6 37.9 198.9 47 231 69.6c9 6.4 17.4 13.8 25 22.3c4.2-4.8 8.7-9.2 13.5-13.3c3.7-3.2 7.5-6.2 11.5-9c0 0 0 0 0 0C313.1 47 353.4 37.9 392.8 45.4C462 58.6 512 119.1 512 189.5l0 3.3c0 41.9-17.4 81.9-48.1 110.4L288.7 465.9l-2.5 2.3c-8.2 7.6-19 11.9-30.2 11.9s-22-4.2-30.2-11.9zM239.1 145c-.4-.3-.7-.7-1-1.1l-17.8-20-.1-.1s0 0 0 0c-23.1-25.9-58-37.7-92-31.2C81.6 101.5 48 142.1 48 189.5l0 3.3c0 28.5 11.9 55.8 32.8 75.2L256 430.7 431.2 268c20.9-19.4 32.8-46.7 32.8-75.2l0-3.3c0-47.3-33.6-88-80.1-96.9c-34-6.5-69 5.4-92 31.2c0 0 0 0-.1 .1s0 0-.1 .1l-17.8 20c-.3 .4-.7 .7-1 1.1c-4.5 4.5-10.6 7-16.9 7s-12.4-2.5-16.9-7z"
                              />
                            </svg>
                            <svg
                              width="20"
                              height="20"
                              fill="#000"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path
                                d="M123.6 391.3c12.9-9.4 29.6-11.8 44.6-6.4c26.5 9.6 56.2 15.1 87.8 15.1c124.7 0 208-80.5 208-160s-83.3-160-208-160S48 160.5 48 240c0 32 12.4 62.8 35.7 89.2c8.6 9.7 12.8 22.5 11.8 35.5c-1.4 18.1-5.7 34.7-11.3 49.4c17-7.9 31.1-16.7 39.4-22.7zM21.2 431.9c1.8-2.7 3.5-5.4 5.1-8.1c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208s-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6c-15.1 6.6-32.3 12.6-50.1 16.1c-.8 .2-1.6 .3-2.4 .5c-4.4 .8-8.7 1.5-13.2 1.9c-.2 0-.5 .1-.7 .1c-5.1 .5-10.2 .8-15.3 .8c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4c4.1-4.2 7.8-8.7 11.3-13.5c1.7-2.3 3.3-4.6 4.8-6.9l.3-.5z"
                              />
                            </svg>
                            <svg
                              width="20"
                              height="20"
                              fill="#000"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path
                                d="M16.1 260.2c-22.6 12.9-20.5 47.3 3.6 57.3L160 376l0 103.3c0 18.1 14.6 32.7 32.7 32.7c9.7 0 18.9-4.3 25.1-11.8l62-74.3 123.9 51.6c18.9 7.9 40.8-4.5 43.9-24.7l64-416c1.9-12.1-3.4-24.3-13.5-31.2s-23.3-7.5-34-1.4l-448 256zm52.1 25.5L409.7 90.6 190.1 336l1.2 1L68.2 285.7zM403.3 425.4L236.7 355.9 450.8 116.6 403.3 425.4z"
                              />
                            </svg>
                          </div>
                          <div class="right-footer">
                            <svg
                              width="20"
                              height="20"
                              fill="#000"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 384 512"
                            >
                              <path
                                d="M0 48C0 21.5 21.5 0 48 0l0 48 0 393.4 130.1-92.9c8.3-6 19.6-6 27.9 0L336 441.4 336 48 48 48 48 0 336 0c26.5 0 48 21.5 48 48l0 440c0 9-5 17.2-13 21.3s-17.6 3.4-24.9-1.8L192 397.5 37.9 507.5c-7.3 5.2-16.9 5.9-24.9 1.8S0 497 0 488L0 48z"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <template v-slot:actions>
              <v-btn
                class="mx-1"
                text="Cancel"
                variant="outlined"
                @click="dialog = false"
              ></v-btn>
              <v-btn
                class="mx-1"
                text="Done"
                color="primary"
                variant="elevated"
                :disabled="isFormValid != '' ? false : true"
                @click="dialog = false"
              ></v-btn>
            </template>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { meta } from "@/store/meta/meta.js";


export default {
  data() {
    return {
      partnershipStatus: this.partnership_status,
      dialog: false,
      partnershipItems: [
        {
          id: 1,
          title: "Instigram",
          imgUrl: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 448 512"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.--><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>`,
        },
        {
          id: 2,
          title: "Facebook",
          imgUrl: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 448 512"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.--><path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64h98.2V334.2H109.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H255V480H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z"/></svg>`,
        },
        {
          id: 3,
          title: "Massenger",
          imgUrl: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.--><path d="M256.6 8C116.5 8 8 110.3 8 248.6c0 72.3 29.7 134.8 78.1 177.9 8.4 7.5 6.6 11.9 8.1 58.2A19.9 19.9 0 0 0 122 502.3c52.9-23.3 53.6-25.1 62.6-22.7C337.9 521.8 504 423.7 504 248.6 504 110.3 396.6 8 256.6 8zm149.2 185.1l-73 115.6a37.4 37.4 0 0 1 -53.9 9.9l-58.1-43.5a15 15 0 0 0 -18 0l-78.4 59.4c-10.5 7.9-24.2-4.6-17.1-15.7l73-115.6a37.4 37.4 0 0 1 53.9-9.9l58.1 43.5a15 15 0 0 0 18 0l78.4-59.4c10.4-8 24.1 4.5 17.1 15.6z"/></svg>`,
        },
      ],
      chargedSelectedItem: {
        id: 1,
        title: "Instigram",
        imgUrl: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 448 512"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.--><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>`,
      },
      isFormValid: "",
      store: meta(),
    };
  },
  
  
};
</script>
