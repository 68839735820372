<template>
    <v-menu>
      <template v-slot:activator="{ props }">
        <span class="status-menu" v-bind="props">
          <svg
            width="5"
            height="8"
            viewBox="0 0 5 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M5 4.00033L0 7.54199L0 0.458658L5 4.00033Z" fill="white" />
          </svg>
        </span>
      </template>
  
      <v-list class="status-list">
        <v-list-item
          v-for="(item, i) in statusList"
          :key="i"
          :class="{ active: item.title === store.status.title }"
          @click="selectStatus(item)"
        >
          <span class="d-flex align-center ga-2">
            <span :style="{ backgroundColor: item.color }" class="dot"></span>
            <span>{{ item.title }}</span>
          </span>
        </v-list-item>
      </v-list>
    </v-menu>
  </template>
  
  <script>
  import { useTaskStore } from '@/store/taskStore';
  
  export default {
    name: 'StatusMenu',
    setup() {
      const store = useTaskStore();
  
      const statusList = [
        { title: 'To Do', color: 'rgba(83, 100, 113, 1)' },
        { title: 'In Progress', color: 'rgba(0, 133, 255, 1)' },
        { title: 'Completed', color: 'rgba(0, 186, 52, 1)' }
      ];
  
      const selectStatus = (status) => {
        store.updateStatus(status);
      };
  
      return {
        store,
        statusList,
        selectStatus
      };
    }
  };
  </script>
  
  <style scoped>
  .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
  
  .status-list {
    min-width: 150px;
  }
  
  .active {
    background-color: rgba(0, 0, 0, 0.05);
  }
  </style>