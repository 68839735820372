<template>
  <v-container
    fluid
    style="background-color: #f6f6f6; min-height: 100vh"
    class="pa-0 ma-0"
  >
    <v-row
      no-gutters=""
      style="position: fixed; top: 0; width: 100%; z-index: 99"
    >
      <v-col cols="12">
        <TiktokNavbar />
      </v-col>
    </v-row>
    <v-row
      class=""
      style="height: calc(100% - 70px); margin-top: 4%"
      no-gutters=""
    >
      <v-col cols="2">
        <TikTokSideBar />
      </v-col>
      <v-col cols="10">
        <div class="all-contain-first-step all-contain-secound-step">
          <ad-group-name></ad-group-name>
          <OptimizeLocations v-if="optimizeLocations" />
          <placement-comp></placement-comp>
          <targeting-comp></targeting-comp>
          <ContentExclusions v-if="content_exclusions"  />
          <budgetSchedule />
          <BiddingOptimization v-if="!optimizeTraffic && !optimizeCommunity " />
          <BiddingOptimizationTraffic v-if="optimizeTraffic  " />
          <BiddingOptimizeCommunty v-if="optimizeCommunity" />
          <div class="tiktok-footer mt-4">
            <div class="footer d-flex align-center justify-space-between">
              <div>
                <button class="exit-btn">Exit</button>
              </div>
              <div class="continue">
                <button @click="continueToStep()" class="continue-btn">
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import "@/assets/css/tiktok/index.css";
import TiktokNavbar from "@/components/tiktok/TiktokNavbar.vue";
import TikTokSideBar from "@/components/tiktok/TikTokSideBar.vue";
import AdGroupName from "@/components/tiktok/SecoundStep/AdGroupName.vue";
import PlacementComp from "@/components/tiktok/SecoundStep/PlacementComp.vue";
import TargetingComp from "@/components/tiktok/SecoundStep/TargetingComp.vue";
import ContentExclusions from "@/components/tiktok/SecoundStep/ContentExclusions.vue";
import budgetSchedule from "@/components/tiktok/SecoundStep/budgetSchedule.vue";
import BiddingOptimization from "@/components/tiktok/SecoundStep/BiddingOptimization.vue";
import OptimizeLocations from "@/components/tiktok/SecoundStep/OptimizeLocations.vue";
import BiddingOptimizationTraffic from "@/components/tiktok/SecoundStep/BiddingOptimizationTraffic.vue";
import BiddingOptimizeCommunty from "@/components/tiktok/SecoundStep/BiddingOptimizeCommunty.vue";
// import TrafficPlacement from "@/components/tiktok/SecoundStep/TrafficPlacement.vue";

export default {
  components: {
    TikTokSideBar,
    TiktokNavbar,
    OptimizeLocations,
    AdGroupName,
    PlacementComp,
    TargetingComp,
    ContentExclusions,
    budgetSchedule,
    BiddingOptimization,
    BiddingOptimizationTraffic,
    BiddingOptimizeCommunty,
  },
  data() {
    return {
      optimizeLocations: false,
      optimizeTraffic: false,
      optimizeCommunity: false,
      content_exclusions:true
    };
  },
  methods: {
    continueToStep() {
      this.$router.push({ name: "TiktokThirdStep" });
    },
  },
  mounted() {
    const selectedObject = localStorage.getItem("selected_objective");
    if (selectedObject === "TRAFFIC") {
      this.optimizeLocations = true;
      this.content_exclusions = false
    } else {
      this.optimizeLocations = false;
    }

    if (selectedObject === "TRAFFIC") {
      this.optimizeTraffic = true;
    } else {
      this.optimizeTraffic = false;
    }
    if (selectedObject === "ENGAGEMENT") {
      this.optimizeCommunity = true;
    } else {
      this.optimizeCommunity = false;
    }
    
  },
};
</script>
, PlacementComp
