<template>
  <div class="d-flex flex-column align-center ga-1">
    <div
      v-for="activity in activities"
      :key="activity.id"
      class="single-overview"
    >
      <div class="head d-flex justify-space-between align-center">
        <div class="icon-name d-flex ga-2 align-center">
          <div class="icon d-flex">
            <img
              :src="
                activity.type === 'Meeting'
                  ? meetingIcon
                  : activity.type === 'Note'
                  ? noteIcon
                  : callSuIcon
              "
              alt="Note"
              width="24"
            />
          </div>
          <div class="name text-capitalize">{{ activity.type }}</div>
        </div>
        <div class="date text-capitalize">
          {{ activity.from }}
        </div>
      </div>
      <div class="body">
        <div class="body-card">
          <div class="card-head d-flex justify-space-between align-center mb-2">
            <div class="name-img d-flex align-center ga-2">
              <img class="circle-img" :src="activity.user.image" alt="" />
              <div class="name d-flex flex-column">
                <span
                  >{{ activity.user.salutation }}/{{ activity.user.first_name
                  }}{{ activity.user.last_name }}</span
                >
                <span class="small-text">
                  {{ activity.created_at }}
                </span>
              </div>
            </div>
            <div class="option">
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 2.5C10 3.32843 9.32843 4 8.5 4C7.67157 4 7 3.32843 7 2.5C7 1.67157 7.67157 1 8.5 1C9.32843 1 10 1.67157 10 2.5Z"
                  fill="#666666"
                />
                <path
                  d="M10 8.5C10 9.32843 9.32843 10 8.5 10C7.67157 10 7 9.32843 7 8.5C7 7.67157 7.67157 7 8.5 7C9.32843 7 10 7.67157 10 8.5Z"
                  fill="#666666"
                />
                <path
                  d="M10 14.5C10 15.3284 9.32843 16 8.5 16C7.67157 16 7 15.3284 7 14.5C7 13.6716 7.67157 13 8.5 13C9.32843 13 10 13.6716 10 14.5Z"
                  fill="#666666"
                />
              </svg>
            </div>
          </div>
          <div
            class="card-body"
            v-html="sanitizedContent(activity.content)"
          ></div>
          <v-divider class="my-2"></v-divider>
          <div class="card-footer d-flex justify-end">read more</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NoteIcon from "@/assets/images/icons/note.svg";
import CallSuIcon from "@/assets/images/icons/call-su.svg";
import MeetingIcon from "@/assets/images/icons/meeting.svg";
import DOMPurify from "dompurify";
export default {
  props: ["item", "selectedSortItem", "activities"],
  name: "OverviewContents",
  data() {
    return {
      localSelectedSortItem: "Oldest",
    };
  },
  watch: {
    selectedSortItem(newVal) {
      this.localSelectedSortItem = newVal;
    },
  },
  methods: {
    sanitizedContent(content) {
      return DOMPurify.sanitize(content);
    },
  },
  computed: {
    noteIcon() {
      return NoteIcon;
    },
    callSuIcon() {
      return CallSuIcon;
    },
    meetingIcon() {
      return MeetingIcon;
    },
    // activities() {
    //   const activitiesCopy = [...this.item.activities];
    //   if (this.localSelectedSortItem === "Newest") {
    //     activitiesCopy.sort(
    //       (a, b) => new Date(b.created_at) - new Date(a.created_at)
    //     );
    //   } else if (this.localSelectedSortItem === "Oldest") {
    //     activitiesCopy.sort(
    //       (a, b) => new Date(a.created_at) - new Date(b.created_at)
    //     );
    //   }

    //   return activitiesCopy;
    // },
  },
};
</script>

<style scoped>
.single-overview {
  width: 50%;
}

/* Header Section */
.single-overview .head {
}
.single-overview .icon-name {
}
.single-overview .icon {
}
.single-overview .name {
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  text-align: left;
  color: #666666;
}
.single-overview .date {
  font-size: 12px;
  font-weight: 400;
  line-height: 28px;
  color: #999999;
  background-color: unset;
}

/* Body Section */
.single-overview .body {
  padding: 16px;
  border-left: solid #d3cfcf 1px;
  margin-left: 12px;
  margin-top: 10px;
}
.single-overview .body-card {
  padding: 12px 20px 12px 20px;
  border-radius: 12px;
  opacity: 0px;
  background: #ffffff;
  border: 1px solid #d6d6d6;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #666666;
  padding: 6px 14px;
}

/* Card Header */
.single-overview .body-card .card-head {
}
.single-overview .body-card .name-img {
}
.single-overview .body-card .circle-img {
}
.single-overview .body-card .name {
}
.single-overview .body-card .option {
}

/* Card Body */
.single-overview .body-card .card-body {
}

/* Card Footer */
.single-overview .body-card .card-footer {
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  color: #237276;
  cursor: pointer;
  text-transform: capitalize;
}
</style>
