<template>
  <div class="placement-card ad-details">
    <div class="card-header">
      <p class="card-title">Ad details</p>
    </div>
    <div class="card-body edit-inventory ad-format">
      <h4 class="mb-5">Ad format</h4>
      <div class="container">
        <div class="radio-tile-group">
          <div class="input-container">
            <input
              id="walk"
              @click="changeMediaType('SINGLE_VIDEO')"
              checked
              class="radio-button"
              type="radio"
              name="radio"
              value="SINGLE_VIDEO"
              v-model="media_type"
            />
            <div class="radio-tile">
              <div class="d-flex align-start ga-2">
                <svg
                  id="Layer_1"
                  style="margin: 7px 0"
                  width="18"
                  height="18px"
                  enable-background="new 0 0 28 28"
                  viewBox="0 0 28 28"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <path
                      d="m4.9975586 26.0024414h18.0048828c1.6542969 0 3-1.3457031 3-3v-18.0048828c0-1.6542969-1.3457031-3-3-3h-18.0048828c-1.6542969 0-3 1.3457031-3 3v18.0048828c0 1.6542969 1.3457031 3 3 3zm18.0048828-2h-18.0048828c-.5512695 0-1-.4487305-1-1v-13.1875h20.0048828v13.1875c0 .5512695-.4487305 1-1 1zm-19.0048828-19.0048828c0-.3132324.1538086-.5817261.3800659-.7652588l3.8930664 3.5826416h-4.2731323zm6.451416-1 4.1456299 3.8173828h-3.3701782l-4.1481934-3.8173828zm6.3217774 0 4.1449585 3.8173828h-3.3689575l-4.1455688-3.8173828zm7.2316894 1v2.8173828h-.135376l-4.1448974-3.8173828h3.2802734c.5512695 0 1 .4487305 1 1z"
                    />
                    <path
                      d="m16.5825195 15.2387695-3.9921875-2.4511719c-1.2840843-.7896376-2.9428711.1374598-2.9428711 1.6459961v4.902832c0 1.507164 1.6566553 2.4369068 2.9428711 1.6464844l3.9921875-2.4516602c1.2291527-.7545604 1.2265702-2.5395059 0-3.2924804zm-4.9350586 3.9760743v-4.6601563l3.824707 2.3115234z"
                    />
                  </g>
                </svg>
                <div>
                  <label for="walk" class="radio-tile-label mb-0">
                    Single video
                  </label>
                  <p class="description mb-0">
                    Create an ad with one video or multiple images.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="carosuel_img" class="radio-tile-group">
          <div class="input-container">
            <input
              id="curousel_images"
              @click="changeMediaType('CAROUSEL_IMAGES')"
              class="radio-button"
              type="radio"
              name="radio"
              value="CAROUSEL_IMAGES"
              v-model="media_type"
            />
            <div class="radio-tile">
              <div class="d-flex align-start ga-2">
                <img
                  style="
                    width: 18px;
                    height: 18px;
                    position: relative;
                    top: 6px;
                  "
                  src="@/assets/tiktokImage/Untitled.png"
                  alt=""
                />

                <div>
                  <label for="curousel_images" class="radio-tile-label mb-0">
                    Carousel images
                  </label>
                  <p class="description mb-0">
                    Create an ad with 1-35 images, displayed in a carousel.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ad-creative my-6" v-if="media_type === 'CAROUSEL_IMAGES'">
        <h4 class="mb-3">Ad creative</h4>
        <label for="" class="mb-3 d-block">Images</label>
        <div class="button-group">
          <button @click="imageDrawer = !imageDrawer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="16px"
              viewBox="0 -960 960 960"
              width="16px"
              fill="#121415"
            >
              <path
                d="M444-444H240v-72h204v-204h72v204h204v72H516v204h-72v-204Z"
              />
            </svg>
            <span>Upload</span>
          </button>
          <button>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="16px"
              viewBox="0 -960 960 960"
              width="16px"
              fill="#121415"
            >
              <path
                d="M444-444H240v-72h204v-204h72v204h204v72H516v204h-72v-204Z"
              />
            </svg>
            <span>Choose from library</span>
          </button>
        </div>
        <!-- upload music -->
        <div class="upload-music mt-4">
          <label for="">Music</label>
          <div class="button-group">
            <button @click="musicDrawer = true">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="16px"
                viewBox="0 -960 960 960"
                width="16px"
                fill="#121415"
              >
                <path
                  d="M444-444H240v-72h204v-204h72v204h204v72H516v204h-72v-204Z"
                />
              </svg>
              <span>Upload</span>
            </button>
          </div>
        </div>
      </div>
      <div class="ad-creative my-6" v-else>
        <h4 class="mb-3">Ad creative</h4>
        <label for="" class="mb-3 d-block">Video</label>
        <div class="videos-preview d-flex align-end flex-wrap mb-3" v-if="videoPreview.length > 0" >
          <div>

          </div>
          <video
              class="media"
              height="100%"
              :src="videoPreview[0].url"
            ></video>
          <div
            class="video-preview"
            v-for="(video, index) in videos"
            :key="index"
          >
            <p>{{ video.file.name }}</p>
          </div>
        </div>
        <div class="button-group">
          <button @click.stop="drawer = !drawer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="16px"
              viewBox="0 -960 960 960"
              width="16px"
              fill="#121415"
            >
              <path
                d="M444-444H240v-72h204v-204h72v204h204v72H516v204h-72v-204Z"
              />
            </svg>
            <span>Upload</span>
          </button>
          <button>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="16px"
              viewBox="0 -960 960 960"
              width="16px"
              fill="#121415"
            >
              <path
                d="M444-444H240v-72h204v-204h72v204h204v72H516v204h-72v-204Z"
              />
            </svg>
            <span> Choose from library </span>
          </button>
          <button>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="16px"
              viewBox="0 -960 960 960"
              width="16px"
              fill="#121415"
            >
              <path
                d="M444-444H240v-72h204v-204h72v204h204v72H516v204h-72v-204Z"
              />
            </svg>
            <span> Create new </span>
          </button>
          <v-navigation-drawer width="1200" location="right" v-model="drawer">
            <v-list-item style="background-color: #f9f9f9" title="Upload video">
              <button @click.stop="drawer = !drawer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="20px"
                  viewBox="0 -960 960 960"
                  width="20px"
                  fill="#000"
                >
                  <path
                    d="m291-240-51-51 189-189-189-189 51-51 189 189 189-189 51 51-189 189 189 189-51 51-189-189-189 189Z"
                  />
                </svg>
              </button>
            </v-list-item>

            <v-divider></v-divider>
            <v-container>
              <v-row no-gutters>
                <v-col cols="12">
                  <div class="upload-container pa-4">
                    <div
                      class="upload-area mb-4"
                      @drop.prevent="handleDrop"
                      @dragover.prevent="handleDragOver"
                      @dragleave.prevent="handleDragLeave"
                      :class="{ 'drag-over': isDragging }"
                    >
                      <input
                        type="file"
                        ref="fileInput"
                        @change="handleFileSelect"
                        accept=".mp4,.mov,.mpeg,.avi"
                        multiple
                        style="display: none"
                      />
                      <div class="upload-placeholder">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="40px"
                          width="40px"
                          viewBox="0 -960 960 960"
                          fill="#017976"
                        >
                          <path
                            d="M260-180q-82.92 0-141.46-57.53Q60-295.06 60-378.15q0-74.54 47.96-131.12t118.96-67.04Q246.15-666 317.12-723q70.96-57 162.88-57 108.64 0 184.32 75.68Q740-628.64 740-520v20h12.31q63.23 4.92 105.46 50.85Q900-403.23 900-340q0 66.92-46.15 113.46Q807.69-180 740.77-180H522.31Q492-180 471-201q-21-21-21-51.31v-219.08l-74 72.77-42.15-41.76L480-586.54l146.15 146.16L584-398.62l-74-72.77v219.08q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85H740q42 0 71-29t29-71q0-42-29-71t-71-29h-60v-80q0-83-58.5-141.5T480-720q-83 0-141.5 58.5T280-520h-20.77q-56.85 0-98.04 41Q120-438 120-380t41 99q41 41 99 41h100v60H260Zm220-270Z"
                          />
                        </svg>
                        <p class="text-subtitle-2 d-flex align-center mt-4">
                          <span>Drop files here, or</span>
                          <a @click="$refs.fileInput.click()" class="">
                            click to upload
                          </a>
                        </p>
                      </div>
                    </div>
                    <div class="recommended-format">
                      <p class="">
                        <span>Recommended format</span> : .mp4, .mov, .mpeg, or
                        .avi
                      </p>
                      <p><span>File size</span> : 500 MB per file</p>
                    </div>
                    <v-alert
                      v-if="error"
                      type="error"
                      class="mb-4"
                      closable
                      @click:close="error = ''"
                    >
                      {{ error }}
                    </v-alert>

                    <div v-if="videos.length > 0">
                      <v-table>
                        <thead>
                          <tr>
                            <th class="text-left">File Name</th>
                            <th class="text-left">Size</th>
                            <th class="text-left">Added</th>
                            <th class="text-left">Status</th>
                            <th class="text-left">Progress</th>
                            <th class="text-left">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(video, index) in videos" :key="video.id">
                            <td>{{ video.file.name }}</td>
                            <td>{{ formatFileSize(video.file.size) }}</td>
                            <td>{{ formatTimestamp(video.timestamp) }}</td>
                            <td>
                              <v-chip
                                :color="getStatusColor(video.status)"
                                size="small"
                              >
                                {{ video.status }}
                              </v-chip>
                            </td>
                            <td>
                              <v-progress-linear
                                v-if="video.status === 'uploading'"
                                :model-value="video.progress"
                                color="primary"
                                height="20"
                              >
                                <template v-slot:default="{ value }">
                                  <strong>{{ Math.ceil(value) }}%</strong>
                                </template>
                              </v-progress-linear>
                              <span
                                v-else-if="video.status === 'completed'"
                                class="text-success"
                              >
                                100%
                              </span>
                            </td>
                            <td>
                              <div class="d-flex gap-2">
                                <v-btn
                                  v-if="video.status === 'pending'"
                                  color="#017976"
                                  variant="text"
                                  density="comfortable"
                                  @click="removeVideo(index)"
                                >
                                  <div class="d-flex">
                                    <span>
                                      <svg
                                        width="15"
                                        fill="#017976"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512"
                                      >
                                        <path
                                          d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0L284.2 0c12.1 0 23.2 6.8 28.6 17.7L320 32l96 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 96C14.3 96 0 81.7 0 64S14.3 32 32 32l96 0 7.2-14.3zM32 128l384 0 0 320c0 35.3-28.7 64-64 64L96 512c-35.3 0-64-28.7-64-64l0-320zm96 64c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16z"
                                        />
                                      </svg>
                                    </span>
                                    <span style="color: #017976">Delete</span>
                                  </div>
                                </v-btn>
                                <v-btn
                                  v-else-if="video.status === 'failed'"
                                  color="warning"
                                  variant="text"
                                  density="comfortable"
                                  @click="retryUpload(index)"
                                >
                                  Retry
                                </v-btn>
                                <v-btn
                                  v-if="video.status === 'completed'"
                                  color="error"
                                  variant="text"
                                  density="comfortable"
                                  @click="confirmRemove(index)"
                                >
                                  Remove
                                </v-btn>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </v-table>

                      <div class="d-flex justify-end mt-4">
                        <v-btn
                          :disabled="!hasUploadableVideos || isUploading"
                          :loading="isUploading"
                          @click="uploadVideos"
                        >
                          Upload All Videos
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-navigation-drawer>
        </div>
        <!-- Confirmation Dialog -->
        <v-dialog v-model="showConfirmDialog" max-width="400">
          <v-card>
            <v-card-title class="text-h5"> Remove Video </v-card-title>
            <v-card-text>
              Are you sure you want to remove this uploaded video? This action
              cannot be undone.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="grey-darken-1"
                variant="text"
                @click="showConfirmDialog = false"
              >
                Cancel
              </v-btn>
              <v-btn color="error" variant="text" @click="handleConfirmRemove">
                Remove
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <ImageUploadDrawer
        @closeDrawer="imageDrawer = false"
        :modelValue="imageDrawer"
      />

      <MusicUploadDrawer
        v-model="musicDrawer"
        @upload-complete="handleMusicUpload"
      />

      <div class="text my-6">
        <label class="text-label" for="">Text</label>
        <div class="text-input">
          <input
            @input="textValuePostStatus($event.target.value)"
            v-model="text_value_post"
            type="text"
            name=""
            id=""
          />
          <span>{{ text_value_post.length }} / 100</span>
        </div>
      </div>
      <div
        class="checked-label mb-5 smart-campaign mb-1 d-flex align-center ga-1"
      >
        <label class="toggle-switch">
          <input
            @change="callToActionStatus"
            type="checkbox"
            v-model="call_to_action"
            id="user-comment"
          />
          <div class="toggle-switch-background">
            <div class="toggle-switch-handle"></div>
            <svg
              class="closemark"
              xmlns="http://www.w3.org/2000/svg"
              height="30px"
              viewBox="0 -960 960 960"
              width="30px"
              fill=""
            >
              <path
                d="M291-253.85 253.85-291l189-189-189-189L291-706.15l189 189 189-189L706.15-669l-189 189 189 189L669-253.85l-189-189-189 189Z"
              />
            </svg>
            <svg
              class="checkmark"
              xmlns="http://www.w3.org/2000/svg"
              height="30px"
              viewBox="0 -960 960 960"
              width="30px"
              fill=""
            >
              <path
                d="M389-259.35 187.35-460l58.89-59.89L389-377.13l324.76-323.76L772.65-642 389-259.35Z"
              />
            </svg>
          </div>
        </label>
        <label for="user-comment" class="label-for-input">
          Call to action
        </label>
      </div>
      <div class="call-to-actions mb-5" v-if="call_to_action">
        <div class="radio-buttons-container">
          <div class="radio-button">
            <input
              @change="callToActionType"
              type="radio"
              id="Dynamic"
              value="Dynamic"
              v-model="call_to_action_type"
              class="radio-button__input"
              name="call_to_action_type"
            />
            <label
              for="Dynamic"
              class="text-subtitle-2 mb-0 radio-button__label"
            >
              <span class="radio-button__custom"></span>
              Dynamic
            </label>
            <span class="d-block small-text mb-2 ml-8 w-75">
              Automatically show different call to action text to different
              users to maximize performance.
              <button
                @click="selected_text = true"
                class="pa-0 d-flex align-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="18px"
                  viewBox="0 -960 960 960"
                  width="18px"
                  fill="#009995"
                  class="d-flex"
                >
                  <path
                    d="M480-312q70 0 119-49t49-119q0-70-49-119t-119-49q-70 0-119 49t-49 119q0 70 49 119t119 49Zm0-72q-40 0-68-28t-28-68q0-40 28-68t68-28q40 0 68 28t28 68q0 40-28 68t-68 28Zm0 192q-142.6 0-259.8-78.5Q103-349 48-480q55-131 172.2-209.5Q337.4-768 480-768q142.6 0 259.8 78.5Q857-611 912-480q-55 131-172.2 209.5Q622.6-192 480-192Zm0-288Zm0 216q112 0 207-58t146-158q-51-100-146-158t-207-58q-112 0-207 58T127-480q51 100 146 158t207 58Z"
                  />
                </svg>
                <span class="text-caption d-flex" style="color: #009995"
                  >View selected text
                </span>
              </button>
            </span>
          </div>
          <div class="radio-button">
            <input
              @change="callToActionType"
              type="radio"
              id="Standard"
              value="Standard"
              v-model="call_to_action_type"
              class="radio-button__input"
              name="call_to_action_type"
            />
            <label for="Standard" class="text-subtitle-2 radio-button__label">
              <span class="radio-button__custom"></span>
              Standard
            </label>
          </div>
        </div>
        <div
          class="select-action-type"
          v-if="call_to_action_type === 'Standard'"
        >
          <v-select
            return-object
            variant="outlined"
            :items="settingsItems"
            item-title="title"
            density="compact"
            v-model="call_to_action_text"
          ></v-select>
          <span
            >{{ call_to_action_text.value }}
            {{ call_to_action_text.title }}</span
          >
        </div>
      </div>
      <v-divider></v-divider>
      <div class="destination my-5">
        <v-expansion-panels variant="accordion">
          <v-expansion-panel>
            <v-expansion-panel-title>Destination</v-expansion-panel-title>
            <v-expansion-panel-text class="my-5 pa-0">
              <div class="container mb-5">
                <div class="radio-tile-group ga-3">
                  <div class="input-container">
                    <input
                      id="Website"
                      class="radio-button"
                      type="radio"
                      name="Destination"
                      value="Website"
                      v-model="Destination"
                    />
                    <div class="radio-tile">
                      <div class="d-flex align-start ga-2">
                        <div>
                          <p class="description mb-0">Website</p>
                          <label for="Website" class="radio-tile-label mb-0">
                            URL
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="input-container">
                    <input
                      id="customPage"
                      class="radio-button"
                      type="radio"
                      name="Destination"
                      value="customPage"
                      v-model="Destination"
                    />
                    <div class="radio-tile">
                      <div class="d-flex align-start ga-2">
                        <div>
                          <p class="description mb-0">TikTok Instant Page</p>
                          <label for="customPage" class="radio-tile-label mb-0">
                            Custom page
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="Destination === 'Website'" class="destination-URL">
                <h4 class="mb-3">Destination URL</h4>
                <p class="text-caption mb-2">
                  By entering a URL, you grant TikTok permission to scan,
                  download and modify the assets from this web page to generate
                  suggestions.
                </p>
                <div class="website-url mb-3 d-flex ga-2 align-canter">
                  <input
                    type="url"
                    v-model="url"
                    @input="validateUrl"
                    placeholder="Enter URL"
                    :class="{ invalid: !isValid }"
                  />
                  <button @click="handlePreview" :disabled="!isValid || !url">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      width="16"
                      height="16"
                      fill="#121415"
                    >
                      <path
                        d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32l82.7 0L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3l0 82.7c0 17.7 14.3 32 32 32s32-14.3 32-32l0-160c0-17.7-14.3-32-32-32L320 0zM80 32C35.8 32 0 67.8 0 112L0 432c0 44.2 35.8 80 80 80l320 0c44.2 0 80-35.8 80-80l0-112c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 112c0 8.8-7.2 16-16 16L80 448c-8.8 0-16-7.2-16-16l0-320c0-8.8 7.2-16 16-16l112 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L80 32z"
                      />
                    </svg>
                    <span> Preview </span>
                  </button>
                </div>
                <div class="direct-user-deeplink">
                  <div
                    class="checked-label mb-5 smart-campaign mb-1 d-flex align-center ga-1"
                  >
                    <label class="toggle-switch">
                      <input
                        @change="directUserStatus"
                        type="checkbox"
                        v-model="direct_users_deeplink"
                        id="direct_users_deeplink_id"
                      />
                      <div class="toggle-switch-background">
                        <div class="toggle-switch-handle"></div>
                        <svg
                          class="closemark"
                          xmlns="http://www.w3.org/2000/svg"
                          height="30px"
                          viewBox="0 -960 960 960"
                          width="30px"
                          fill=""
                        >
                          <path
                            d="M291-253.85 253.85-291l189-189-189-189L291-706.15l189 189 189-189L706.15-669l-189 189 189 189L669-253.85l-189-189-189 189Z"
                          />
                        </svg>
                        <svg
                          class="checkmark"
                          xmlns="http://www.w3.org/2000/svg"
                          height="30px"
                          viewBox="0 -960 960 960"
                          width="30px"
                          fill=""
                        >
                          <path
                            d="M389-259.35 187.35-460l58.89-59.89L389-377.13l324.76-323.76L772.65-642 389-259.35Z"
                          />
                        </svg>
                      </div>
                    </label>
                    <label
                      for="direct_users_deeplink_id"
                      class="label-for-input"
                    >
                      Direct users to deeplink first
                    </label>
                  </div>
                  <div v-if="direct_users_deeplink">
                    <input
                      @input="validateDirectUserUrl"
                      type="url"
                      class="direct-user-deeplink-input"
                      :class="{
                        invalid: isInvalid,
                        valid: !isInvalid && direct_user_url,
                      }"
                      placeholder="Scheme, Universal, and App Link formats are supported"
                      v-model="direct_user_url"
                    />
                    <p v-if="isInvalid" class="error-message">
                      Enter deep link URL
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="destination-custom-page mb-5"
                v-if="Destination === 'customPage'"
              >
                <h4 class="mb-3">Custom page</h4>
                <div class="button-group d-flex align-center ga-2">
                  <button class="custome-page-create">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="16px"
                      viewBox="0 -960 960 960"
                      width="16px"
                      fill="#121415"
                    >
                      <path
                        d="M444-444H240v-72h204v-204h72v204h204v72H516v204h-72v-204Z"
                      />
                    </svg>
                    <span>Create</span>
                  </button>
                  <button class="custome-page-create">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="16px"
                      viewBox="0 -960 960 960"
                      width="16px"
                      fill="#121415"
                    >
                      <path
                        d="M444-444H240v-72h204v-204h72v204h204v72H516v204h-72v-204Z"
                      />
                    </svg>
                    <span>Choose from library</span>
                  </button>
                </div>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <v-divider></v-divider>
      <div class="check-the-box py-5">
        <div class="d-flex ga-3 align-start">
          <input
            id="searchFeed"
            v-model="searchFeed"
            type="checkbox"
            @change="searchFeedValue"
            class="ui-checkbox"
          />
          <label class="feed-search-label" for="searchFeed"
            >By checking the box, you authorize the ad and the associated
            performance metrics of the campaign to be displayed on TikTok
            branded or affiliated platforms.</label
          >
        </div>
      </div>
      <v-dialog v-model="selected_text" width="auto">
        <v-card
          elevation="0"
          max-width="400"
          text="The system will use the text you select to achieve optimal results."
          title="Edit text options"
        >
          <div class="card-text-select">
            <v-list
              v-model:selected="settingsSelection"
              lines="one"
              select-strategy="leaf"
            >
              <v-list-subheader>Text options </v-list-subheader>
              <v-list-item
                v-for="item in settingsItems"
                :key="item.value"
                :title="item.title"
                :value="item.value"
              >
                <template v-slot:prepend="{ isSelected }">
                  <v-list-item-action start>
                    <v-checkbox-btn
                      color="#009995"
                      :model-value="isSelected"
                    ></v-checkbox-btn>
                  </v-list-item-action>
                </template>
              </v-list-item>
            </v-list>
          </div>

          <template v-slot:actions>
            <v-btn class="confirm-btn" text="Confirm"></v-btn>
            <v-btn
              class="cancel-btn"
              text="Cancel"
              @click="selected_text = false"
            ></v-btn>
          </template>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
// import Treeselect from "vue3-treeselect";
import { formatFileSize, validateVideoFile } from "@/core/fileUtils.js";
import { getStatusColor, createVideoEntry } from "@/core/videoUtils";
import ImageUploadDrawer from "./ImageUploadDrawer.vue";
import MusicUploadDrawer from "./MusicUploadDrawer.vue";
import { validateUrl } from "@/core/urlValidator";
import { tiktokData } from "@/store/tiktokStore/tiktokData";
import { mapActions, mapState } from "pinia";

export default {
  components: { ImageUploadDrawer, MusicUploadDrawer },
  data() {
    return {
      adFormate: "SINGLE_VIDEO",
      drawer: false,
      isDragging: false,
      error: "",
      videos: [],
      isUploading: false,
      showConfirmDialog: false,
      videoToRemove: null,
      call_to_action: false,
      call_to_action_type: "Dynamic",
      selected_text: false,
      settingsItems: [
        {
          id: 1,
          value: "PREORDER_NOW",
          title: "Pre-order now",
          label: "Pre-order now",
        },
        {
          id: 2,
          value: "EXPERIENCE_NOW",
          title: "Experience now",
          label: "Experience now",
        },
        {
          id: 3,
          value: "DOWNLOAD_NOW",
          title: "Download Now",
          label: "Download Now",
        },
        {
          id: 4,
          value: "INTERESTED",
          title: "Interested",
          label: "Interested",
        },
        {
          id: 5,
          value: "VISIT_STORE",
          title: "Visit store",
          label: "Visit store",
        },
        {
          id: 6,
          value: "WATCH_NOW",
          title: "Watch now",
          label: "Watch now",
        },
        {
          id: 7,
          value: "SIGN_UP",
          title: "Sign up",
          label: "Sign up",
        },
        {
          id: 8,
          value: "LISTEN_NOW",
          title: "Listen now",
          label: "Listen now",
        },
        {
          id: 9,
          value: "ORDER_NOW",
          title: "Order now",
          label: "Order now",
        },
        {
          id: 10,
          value: "BOOK_NOW",
          title: "Book now",
          label: "Book now",
        },
        {
          id: 11,
          value: "SHOP_NOW",
          title: "Shop now",
          label: "Shop now",
        },
        {
          id: 12,
          value: "PLAY_GAME",
          title: "Play game",
          label: "Play game",
        },
        {
          id: 13,
          value: "LEARN_MORE",
          title: "Learn more",
          label: "Learn more",
        },
        {
          id: 14,
          value: "CONTACT_US",
          title: "Contact us",
          label: "Contact us",
        },
        {
          id: 15,
          value: "APPLY_NOW",
          title: "Apply now",
          label: "Apply now",
        },
        {
          id: 16,
          value: "READ_MORE",
          title: "Read more",
          label: "Read more",
        },
        {
          id: 17,
          value: "VIEW_NOW",
          title: "View now",
          label: "View now",
        },
        {
          id: 18,
          value: "GET_QUOTE",
          title: "Get quote",
          label: "Get quote",
        },
        {
          id: 19,
          value: "INSTALL_NOW",
          title: "Install now",
          label: "Install now",
        },
        {
          id: 20,
          value: "GET_SHOWTIMES",
          title: "Get showtimes",
          label: "Get showtimes",
        },
        {
          id: 21,
          value: "SUBSCRIBE",
          title: "Subscribe",
          label: "Subscribe",
        },
        {
          id: 22,
          value: "GET_TICKETS_NOW",
          title: "Get tickets now",
          label: "Get tickets now",
        },
        {
          id: 23,
          value: "CALL_NOW",
          title: "Call now",
          label: "Call now",
        },
        {
          id: 24,
          value: "SEND_MESSAGE",
          title: "Send message",
          label: "send message",
        },
        {
          id: 25,
          value: "VIEW_PROFILE",
          title: "View profile",
          label: "View profile",
        },
        {
          id: 26,
          value: "WATCH_LIVE",
          title: "Watch live",
          label: "Watch live",
        },
        {
          id: 27,
          value: "JOIN_THIS_HASHTAG",
          title: "Join this hashtag",
          label: "Join this hashtag",
        },
        {
          id: 28,
          value: "SHOOT_WITH_THIS_EFFECT",
          title: "Shoot with this effect",
          label: "Shoot with this effect",
        },
        {
          id: 29,
          value: "VIEW_VIDEO_WITH_THIS_EFFECT",
          title: "View video with this effect",
          label: "View video with this effect",
        },
      ],
      call_to_action_text: {
        id: 29,
        value: "VIEW_VIDEO_WITH_THIS_EFFECT",
        title: "View video with this effect",
        label: "View video with this effect",
      },
      settingsSelection: [],
      Destination: "Website",
      url: "",
      isValid: true,
      direct_users_deeplink: false,
      direct_user_url: "",
      isInvalid: false,
      carosuel_img: false,
      imageDrawer: false,
      musicDrawer: false,
    };
  },
  mounted() {
    if (localStorage.getItem("call_to_action")) {
      this.call_to_action = localStorage.getItem("call_to_action");
    }
    const savedSelection = localStorage.getItem("settingsSelection");
    if (savedSelection) {
      this.settingsSelection = JSON.parse(savedSelection);
    }
    if (localStorage.getItem("call_to_action_type")) {
      this.call_to_action_type = localStorage.getItem("call_to_action_type");
    }
    const storedValue = localStorage.getItem("call_to_action_text");
    if (storedValue) {
      this.call_to_action_text = storedValue;
    }
    if (localStorage.getItem("direct_users_deeplink") === "true") {
      this.direct_users_deeplink = localStorage.getItem(
        "direct_users_deeplink"
      );
    }
    localStorage.setItem("ad_formate", this.adFormate);
    const store = tiktokData();
    this.videos = store.videos_arr;
    if (localStorage.getItem("selected_objective") === "TRAFFIC") {
      this.carosuel_img = true;
    }
  },
  computed: {
    hasUploadableVideos() {
      return this.videos.some((video) => video.status === "pending");
    },
    ...mapState(tiktokData, [
      "text_value_post",
      "videoPreview",
      "media_fils",
      "callToaction",
      "media_type",
    ]),
  },
  methods: {
    ...mapActions(tiktokData, [
      "textValuePostStatus",
      "callToactionStatusStore",
      "changeMediaType",
    ]),
    formatFileSize,
    getStatusColor,
    formatTimestamp(timestamp) {
      return new Date(timestamp).toLocaleString();
    },
    handleDragOver() {
      this.isDragging = true;
    },
    handleDragLeave() {
      this.isDragging = false;
    },
    handleDrop(e) {
      this.isDragging = false;
      const files = Array.from(e.dataTransfer.files);
      this.processFiles(files);
    },
    handleFileSelect(e) {
      const files = Array.from(e.target.files);
      this.processFiles(files);
    },
    processFiles(files) {
      files.forEach((file) => {
        const validation = validateVideoFile(file);
        if (validation.isValid) {
          this.videos.push(createVideoEntry(file));
        } else {
          this.error = `${file.name}: ${validation.error}`;
        }
      });
    },
    removeVideo(index) {
      this.videos.splice(index, 1);
    },
    confirmRemove(index) {
      this.videoToRemove = index;
      this.showConfirmDialog = true;
    },
    async handleConfirmRemove() {
      this.videoPreview.splice(this.videoToRemove, 1);
      if (this.videoToRemove !== null) {
        await new Promise((resolve) => setTimeout(resolve, 500));
        this.removeVideo(this.videoToRemove);
      }
      this.showConfirmDialog = false;
      this.videoToRemove = null;
    },
    updatePreviewUrl(file) {
      this.media_fils.push(file);
      if (file instanceof File) {
        const previewUrl = URL.createObjectURL(file);
        const store = tiktokData();
        console.log("Before Push:", store.videoPreview.length);
        if (Array.isArray(store.videoPreview)) {
          store.videoPreview.push({
            url: previewUrl,
            fileName: file.name,
            fileType: file.type,
          });
          console.log("After Push:", store.videoPreview);
        }
        return previewUrl;
      } else {
        console.error("Provided file is not a valid File object");
        return null;
      }
    },
    async uploadVideos() {
      if (this.videos.length > 0) {
        if (!this.previewUrl) {
          this.videos.forEach((video) => {
            if (video.file) {
              console.log(video.file);

              this.updatePreviewUrl(video.file);
            }
          });
        }
      }

      this.isUploading = true;
      const pendingVideos = this.videos.filter(
        (video) => video.status === "pending"
      );

      for (const video of pendingVideos) {
        try {
          video.status = "uploading";
          video.progress = 0;

          // Simulated upload progress
          for (let i = 0; i <= 100; i += 10) {
            video.progress = i;
            await new Promise((resolve) => setTimeout(resolve, 200));
          }

          // Here you would implement your actual upload logic
          // For example, using FormData and fetch/axios to send to your backend
          await new Promise((resolve) => setTimeout(resolve, 500));

          video.status = "completed";
          video.progress = 100;
        } catch (error) {
          video.status = "failed";
          video.error = "Upload failed";
        }
      }

      this.isUploading = false;
    },
    async retryUpload(index) {
      const video = this.videos[index];
      video.status = "pending";
      video.progress = 0;
      video.error = null;
      await this.uploadVideos();
    },
    callToActionStatus() {
      localStorage.setItem("call_to_action", this.call_to_action);
    },
    callToActionType() {
      localStorage.setItem("call_to_action_type", this.call_to_action_type);
    },
    validateUrl() {
      if (!this.url) {
        this.isValid = true;
        return;
      }
      try {
        new URL(this.url);
        this.isValid = true;
        localStorage.setItem("deeplink_format_type", this.url);
      } catch {
        this.isValid = false;
      }
    },
    handlePreview() {
      if (this.isValid && this.url) {
        window.open(this.url, "_blank");
      }
    },
    directUserStatus() {
      localStorage.setItem("direct_users_deeplink", this.direct_users_deeplink);
    },
    validateDirectUserUrl() {
      this.isInvalid = !validateUrl(this.directUserUrl);
    },
    handleMusicUpload(musicData) {
      console.log("Music uploaded:", musicData);
      this.musicDrawer = false;
    },
  },
  watch: {
    settingsSelection: {
      handler(newValue) {
        localStorage.setItem("settingsSelection", JSON.stringify(newValue));
      },
      deep: true,
    },
    call_to_action_text(newValue) {
      // Store the selected value in localStorage
      localStorage.setItem("call_to_action_text", newValue.value);
      this.callToactionStatusStore(newValue.title);
    },
  },
};
</script>

<style scoped>
.upload-container {
  width: 100%;
}
.upload-container .recommended-format p {
  color: #6d6e70;
  font-size: 12px;
  font-weight: normal;
}
.upload-container .recommended-format p span {
  font-weight: bold;
}
.upload-area {
  border: 2px dashed #ccc;
  border-radius: 8px;
  padding: 110px;
  text-align: center;
  transition: all 0.3s ease;
  background-color: #f5f5f5;
}
.upload-area:hover {
  border: 2px dashed #017976;
}
.upload-area.drag-over {
  border-color: #2196f3;
  background-color: #e3f2fd;
}
.upload-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.upload-placeholder p a {
  color: #017976;
  text-decoration: none;
  cursor: pointer;
  font-weight: normal;
  margin-left: 5px;
}
.selected-file {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.button-group {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}
button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  transition: all 0.3s ease;
}
button:hover {
  background-color: #f5f5f5;
}
</style>
