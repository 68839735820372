<template>
  <div class="card-pattern meta-ad-setup">
    <div class="card-header mb-2">
      <label for="">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#007e59"
        >
          <path
            d="m423.23-309.85 268.92-268.92L650-620.92 423.23-394.15l-114-114L267.08-466l156.15 156.15ZM480.07-100q-78.84 0-148.21-29.92t-120.68-81.21q-51.31-51.29-81.25-120.63Q100-401.1 100-479.93q0-78.84 29.92-148.21t81.21-120.68q51.29-51.31 120.63-81.25Q401.1-860 479.93-860q78.84 0 148.21 29.92t120.68 81.21q51.31 51.29 81.25 120.63Q860-558.9 860-480.07q0 78.84-29.92 148.21t-81.21 120.68q-51.29 51.31-120.63 81.25Q558.9-100 480.07-100Zm-.07-60q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
          />
        </svg>
        <span class="roboto-black">Ad setup</span>
      </label>
    </div>
    <div class="card-body meta-ad-setup-body">
      <v-select
        v-model="adSetSelected"
        :items="adSetUpselect"
        density="compact"
        variant="outlined"
        return-object
        item-value="id"
        item-title="title"
        hide-details
      >
        <template v-slot:selection="{ item }">
          {{ item?.title || "" }}
        </template>
        <template v-slot:item="{ item }">
          <v-list-item @click="setAdSelected(item)">
            <div class="my-form">
              <div class="ga-3">
                <input
                  :checked="adSetSelected && adSetSelected.id === item.raw.id"
                  :id="item.raw.id"
                  type="radio"
                  name="option"
                  :value="item"
                />
                <div class="d-flex flex-column ga-1">
                  <label
                    :for="item.raw.id"
                    class="d-block"
                    style="font-size: 14px"
                    >{{ item.title }}</label
                  >
                </div>
              </div>
            </div>
          </v-list-item>
        </template>
      </v-select>
      <div class="ad-setup-formate my-3">
        <div class="formate-header mb-2">
          <p class="roboto-bold">Format</p>
          <span class="d-block">
            Choose how you'd like to structure your ad.
          </span>
        </div>
        <div class="formate-body">
          <div
            class="my-form"
            :style="{
              opacity: item.disabled ? '0.5' : '1',
              cursor: item.disabled ? 'not-allowed' : 'pointer',
            }"
            v-for="item in formateDate"
            :key="item.id"
          >
            <div class="ga-3">
              <input
                :style="{
                  opacity: item.disabled ? '0.5' : '1',
                  cursor: item.disabled ? 'not-allowed' : 'pointer',
                }"
                :disabled="item.disabled"
                :checked="item.checked"
                :id="item.id"
                type="radio"
                name="selectedFormat"
                :value="item.title"
              />
              <div class="d-flex flex-column ga-1">
                <label :for="item.id" class="d-block" style="font-size: 14px">
                  {{ item.title }}
                  <span class="d-block">
                    {{ item.descroptions }}
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-divider class="my-3"></v-divider>
      <label class="checkbox">
        <input
          type="checkbox"
          @click="setMultiAdvertiserAds"
          v-model="multiAdvertiserAds"
        />
        <span class="checkmark"></span>
        <span class="text d-block" style="font-size: 14px;">Multi-advertiser ads</span>
        <span style="font-size: 12px; margin-left: 30px;" class="d-block">
          Your ads can appear alongside other ads in the same ad unit to help
          people discover products and services from businesses that are
          personalised to them. Your ad creative may be resized or cropped to
          fit the ad unit.
        </span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdSetup",
  data() {
    return {
      adSetUpselect: [
        { title: "Creat Ad ", id: 1 },
        { title: "Use existing post", id: 2 },
        { title: "Use Creative Hub mokeup", id: 3 },
      ],
      adSetSelected: { title: "Creat Ad ", id: 1 },
      formateDate: [
        {
          id: 1,
          title: "Single image or video",
          descroptions:
            "One image or video, or a slideshow with multiple images",
          checked: true,
          disabled: false,
        },
        {
          id: 2,
          title: "Carousel",
          descroptions: "Two or more scrollable images or videos",
          checked: false,
          disabled: false,
        },
        {
          id: 3,
          title: "Collection",
          descroptions:
            "A group of items that opens into a full-screen mobile experience",
          checked: false,
          disabled: true,
        },
      ],
      multiAdvertiserAds: false,
    };
  },
mounted() {
    localStorage.setItem("ad_setup", this.adSetSelected.id);
    localStorage.setItem("meta_multi_advertiser_ads", this.multiAdvertiserAds);  
},
  methods: {
    setAdSelected(item) {
      this.adSetSelected = item.raw;
      localStorage.setItem("ad_setup", item.raw.value);
    },
    setMultiAdvertiserAds(){
      localStorage.setItem("meta_multi_advertiser_ads", this.multiAdvertiserAds);
    }
  },
};
</script>
