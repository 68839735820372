<template>
  <div class="brand-safety-card my-3">
    <div class="brand-safety-card-header">
      <p class="roboto-bold">Brand safety and suitability</p>
      <p class="roboto-regular">
        Prevent your ads from appearing near content that's not conducive to
        your brand. Manage in Brand safety and suitability
      </p>
    </div>
    <div class="inventory-filter mb-3">
      <label for="">Inventory filter </label>
      <v-expansion-panels>
        <v-expansion-panel
          title="Only applies to: Facebook in-stream videos and Ads on Facebook Reels"
        >
          <v-expansion-panel-text class="d-flex">
            <div
              class="my-form"
              v-for="select in inventoryFilter"
              :key="select.id"
            >
              <div
                class="ga-3 flex-column align-start px-2"
                style="
                  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                  height: 100%;
                  border-radius: 8px;
                  background-color: #fff;
                "
              >
                <input
                  :id="select.id"
                  type="radio"
                  name="inventory_filter"
                  :value="select.value"
                  :checked="select.checked"
                />
                <div class="d-flex flex-column ga-1">
                  <label
                    @click="inventoryFilterFun(select.value)"
                    :for="select.id"
                    class="d-block"
                    style="font-size: 14px; color: #1c2b33"
                  >
                    <span class="d-block">
                      {{ select.title }}
                    </span>
                    <p style="font-size: 12px">
                      {{ select.descriptions }}
                    </p>
                  </label>
                </div>
              </div>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div class="inventory-filter">
      <!-- <label for="">Only applies to: Audience Network </label>
        <label for="">Current filter setting: Expanded  (ad set)</label> -->
      <v-expansion-panels>
        <v-expansion-panel
          title="Current filter setting: Expanded  (ad set)"
          tile="Only applies to: Audience Network "
        >
          <v-expansion-panel-text class="d-flex">
            <div
              class="my-form"
              v-for="select in audienceNetwork"
              :key="select.id"
            >
              <div
                class="ga-3 flex-column align-start px-2"
                style="
                  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                  height: 100%;
                  border-radius: 8px;
                  background-color: #fff;
                "
              >
                <input
                  :id="select.id"
                  type="radio"
                  name="audience_network"
                  :value="select.value"
                  :checked="select.checked"
                />
                <div class="d-flex flex-column ga-1">
                  <label
                    @click="audienceNetworkFun(select.value)"
                    :for="select.id"
                    class="d-block"
                    style="font-size: 14px; color: #1c2b33"
                  >
                    <span class="d-block">
                      {{ select.title }}
                    </span>
                    <p style="font-size: 12px">
                      {{ select.descriptions }}
                    </p>
                  </label>
                </div>
              </div>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <PublisherList />
  </div>
</template>

<script>
import PublisherList from "./PublisherList.vue";

export default {
  components: { PublisherList },
  data() {
    return {
      checked: false,
      inventoryFilter: [
        {
          id: 0,
          title: "Expanded inventory",
          descriptions:
            "Show ads on all content that adhere to our Content Monetisation Policies so that you get the most reach.",
          value: "expanded_inventory",
          checked: false,
        },
        {
          id: 1,
          title: "Moderate inventory",
          descriptions: "Exclude highly sensitive content.",
          value: "moderate_inventory",
          checked: true,
        },
        {
          id: 2,
          title: "Limited inventory",
          descriptions:
            "Exclude additional sensitive content, as well as all live videos. This lowers reach and can increase costs.",
          value: "limited_inventory",
          checked: false,
        },
      ],
      audienceNetwork: [
        {
          id: 0,
          title: "Expanded inventory",
          descriptions:
            "Show ads on all content that adhere to our Content Monetisation Policies so that you get the most reach.",
          value: "expanded_inventory",
          checked: false,
        },
        {
          id: 1,
          title: "Moderate inventory",
          descriptions: "Exclude highly sensitive content.",
          value: "moderate_inventory",
          checked: true,
        },
        {
          id: 2,
          title: "Limited inventory",
          descriptions:
            "Exclude additional sensitive content, as well as all live videos. This lowers reach and can increase costs.",
          value: "limited_inventory",
          checked: false,
        },
      ],
    };
  },
  methods: {
    inventoryFilterFun(value) {
      localStorage.setItem("inventory_filter", value);
    },
    audienceNetworkFun(value) {
      localStorage.setItem("meta_audience_network", value);
    },
  },
};
</script>
