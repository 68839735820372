import SignupView from "@/views/SignupView";
import ErrorPage from "@/views/ErrorPage.vue";
import LoginPage from "@/views/LoginPage.vue";
import HomePage from "@/views/HomePage.vue";
import Home from "@/views/portfolio/Home.vue";
import Privacy from "@/views/portfolio/Privacy.vue";
import Terms from "@/views/portfolio/Terms.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
const generalRoutes = [
  {
    path: "/sign-up",
    name: "SignUp",
    component: SignupView,
    meta: {
      title: "Sign up",
      description: "",
      auth: "unauth",
    },
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: ErrorPage,
    meta: {
      title: "Not Found",
      description: "",
    },
  },
  {
    path: "/login",
    name: "LoginPage",
    component: LoginPage,
    meta: {
      title: "login",
      description: "",
      auth: "unauth",
    },
  },
  {
    path: "/home-page",
    name: "HomePage",
    component: HomePage,
    meta: {
      title: "HomePage",
      description: "",
      auth: "auth",
    },
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
    meta: {
      title: "Privacy Policy",
      description: "",
      auth: "unauth",
    },
  },
  {
    path: "/",
    name: "portfolio",
    component: Home,
    meta: {
      title: "portfolio",
      description: "",
    },
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
  },
  {
    path: "/terms",
    name: "Terms",
    component: Terms,
  },
];

export default generalRoutes;
