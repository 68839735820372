<template>
  <img
    class="media-preview"
    v-if="
      post.file.file &&
      post.file.file.type &&
      post.file.file.type.startsWith('image/')
    "
    :src="post.file.preview"
    alt="Post Image"
  />
  <video
    v-if="
      post.file.file &&
      post.file.file.type &&
      post.file.file.type.startsWith('video/')
    "
    width="100%"
    :src="post.file.preview"
    autoplay
    muted
    loop
    class="media-preview"
  ></video>
  <div class="card-header px-3 py-2">
    <div class="story-loading mb-3">
      <span class="persent-load"></span>
    </div>
    <div class="d-flex align-center justify-space-between">
      <div class="d-flex align-center ga-2">
        <img
          class="user-img"
          src="@/assets/soft-ware-img/bea761cbd2d4483c5dec5f8359ede007.png"
          alt=""
        />
        <div class="user-info">
          <p>Vish Patil <span>4h</span></p>
          <p>
            <svg
              width="11"
              height="14"
              viewBox="0 0 11 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.16667 0C4.89052 0 4.66667 0.241077 4.66667 0.538462V3.41026C4.66667 3.41016 4.66667 3.41035 4.66667 3.41026V8.62222C4.17252 8.17016 3.5325 7.89744 2.83333 7.89744C1.26853 7.89744 0 9.26354 0 10.9487C0 12.6339 1.26853 14 2.83333 14C4.39814 14 5.66667 12.6339 5.66667 10.9487C5.66667 10.8634 5.66342 10.7789 5.65704 10.6954C5.66336 10.6612 5.66667 10.6259 5.66667 10.5897L5.66667 3.94872H9.16667C10.1792 3.94872 11 3.06477 11 1.97436C11 0.883951 10.1792 0 9.16667 0H5.16667Z"
                fill="white"
              />
            </svg>
            <span>Neha Kakkar</span>
          </p>
        </div>
      </div>
      <div class="header-actions">
        <span>
          <svg
            width="20"
            height="4"
            viewBox="0 0 20 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 0C0.895431 0 0 0.895431 0 2C0 3.10457 0.895431 4 2 4C3.10457 4 4 3.10457 4 2C4 0.895431 3.10457 0 2 0Z"
              fill="white"
            />
            <path
              d="M8 2C8 0.895431 8.89543 0 10 0C11.1046 0 12 0.895431 12 2C12 3.10457 11.1046 4 10 4C8.89543 4 8 3.10457 8 2Z"
              fill="white"
            />
            <path
              d="M16 2C16 0.895431 16.8954 0 18 0C19.1046 0 20 0.895431 20 2C20 3.10457 19.1046 4 18 4C16.8954 4 16 3.10457 16 2Z"
              fill="white"
            />
          </svg>
        </span>
        <span>
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.7437 1.49378C10.0854 1.15206 10.0854 0.598015 9.7437 0.256292C9.40197 -0.0854308 8.84793 -0.0854308 8.50621 0.256292L5 3.76251L1.49379 0.256293C1.15207 -0.08543 0.598026 -0.0854304 0.256303 0.256293C-0.0854198 0.598016 -0.0854196 1.15206 0.256303 1.49378L3.76251 4.99999L0.256292 8.50622C-0.0854305 8.84794 -0.0854308 9.40198 0.256292 9.74371C0.598015 10.0854 1.15206 10.0854 1.49378 9.74371L5 6.23748L8.50622 9.74371C8.84794 10.0854 9.40199 10.0854 9.74371 9.74371C10.0854 9.40199 10.0854 8.84794 9.74371 8.50622L6.23749 4.99999L9.7437 1.49378Z"
              fill="white"
            />
          </svg>
        </span>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <img src="@/assets/soft-ware-img/symbol.svg" alt="" />
  </div>
</template>
<script>
import { publishPost } from "@/store/publishPost/publishPost.js";
export default {
  name: "FacebookStoryReview",
  components: {},
  data() {
    return {
      store: publishPost(),
      post: {},
    };
  },
  watch: {
    "store.getCarouselSlider": {
      handler() {
        this.post = this.store.post["facebook"].find(
          (item) => item.type === "story"
        );
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
video {
  object-fit: cover;
}
</style>