<template>
  <v-row>
    <v-col cols="12" md="12">
      <div class="task-card">
        <div class="task-title d-flex align-center justify-space-between">
          {{ task.name }}

          <div class="d-flex flex-wrap">
            <div class="d-flex align-center ga-2">
              <span v-for="(member, index) in task.members" :key="index">
                <img
                  class="circle-img"
                  :src="member.image"
                  :alt="member.name"
                />
              </span>
            </div>
          </div>
        </div>
        <div class="d-flex flex-wrap align-center ga-3 data">
          <div class="element d-flex align-center ga-2">
            <span class="title">Status :</span
            ><span class="value">{{ task.status }}</span>
          </div>
          <div class="element d-flex align-center ga-2">
            <span class="title">Due Date :</span
            ><span class="value">{{ task.due_date }}</span>
          </div>
          <div class="element d-flex align-center ga-2">
            <span class="title">Cost :</span
            ><span class="value">{{ task.estimated_bouns }}$</span>
          </div>
          <div class="element d-flex align-center ga-2">
            <span class="title">Weight :</span
            ><span class="value">{{ task.weight }} $</span>
          </div>
          <div class="element d-flex align-center ga-2">
            <span class="title">Expected Bouns :</span
            ><span class="value"> {{ task.estimated_bouns }} $</span>
          </div>
        </div>
        <div class="d-flex flex-wrap align-center ga-3 data mt-4">
          <div class="element d-flex align-center ga-2">
            <span class="title">Description :</span>
            <span class="value">
              {{
                task.description?.length > 20
                  ? task.description.slice(0, 20) + "..."
                  : task.description
              }}
            </span>
          </div>
        </div>
      </div>
    </v-col>
    <v-col cols="12" md="12" class="">
      <div class="task-card h-100">
        <div class="task-title d-flex align-center justify-space-between">
          <span> Related Accounts </span>
          <div>
            <v-btn
              id="show-hidden-columns"
              variant="text"
              class="show-hidden-columns pa-0"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_3141_74103)">
                  <path
                    d="M11.9603 11.96C10.8207 12.8286 9.43306 13.3099 8.00033 13.3333C3.33366 13.3333 0.666992 7.99996 0.666992 7.99996C1.49625 6.45456 2.64642 5.10436 4.04033 4.03996M6.60032 2.82663C7.05921 2.71921 7.52903 2.66552 8.00033 2.66663C12.667 2.66663 15.3337 7.99996 15.3337 7.99996C14.929 8.75703 14.4464 9.46978 13.8937 10.1266M9.41366 9.41329C9.23056 9.60979 9.00976 9.7674 8.76443 9.87671C8.51909 9.98602 8.25426 10.0448 7.98572 10.0495C7.71718 10.0543 7.45043 10.0049 7.2014 9.90429C6.95236 9.8037 6.72614 9.65398 6.53622 9.46406C6.34631 9.27414 6.19659 9.04792 6.096 8.79889C5.99541 8.54985 5.94601 8.28311 5.95075 8.01457C5.95549 7.74602 6.01426 7.48119 6.12358 7.23586C6.23289 6.99053 6.39049 6.76972 6.58699 6.58663M0.666992 0.666626L15.3337 15.3333"
                    stroke="#666666"
                    stroke-width="1.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3141_74103">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span>Show hidden columns</span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 6L8 10L12 6"
                  stroke="#666666"
                  stroke-width="1.6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </v-btn>
            <v-menu activator="#show-hidden-columns">
              <v-list>
                <v-list-item
                  v-for="(item, index) in hidden_account_headers"
                  :key="index"
                  :value="index"
                >
                  <v-list-item-title
                    class="d-flex align-center ga-2"
                    @click="showHiddenAccountColumn(item.key)"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_3141_74103)">
                        <path
                          d="M11.9603 11.96C10.8207 12.8286 9.43306 13.3099 8.00033 13.3333C3.33366 13.3333 0.666992 7.99996 0.666992 7.99996C1.49625 6.45456 2.64642 5.10436 4.04033 4.03996M6.60032 2.82663C7.05921 2.71921 7.52903 2.66552 8.00033 2.66663C12.667 2.66663 15.3337 7.99996 15.3337 7.99996C14.929 8.75703 14.4464 9.46978 13.8937 10.1266M9.41366 9.41329C9.23056 9.60979 9.00976 9.7674 8.76443 9.87671C8.51909 9.98602 8.25426 10.0448 7.98572 10.0495C7.71718 10.0543 7.45043 10.0049 7.2014 9.90429C6.95236 9.8037 6.72614 9.65398 6.53622 9.46406C6.34631 9.27414 6.19659 9.04792 6.096 8.79889C5.99541 8.54985 5.94601 8.28311 5.95075 8.01457C5.95549 7.74602 6.01426 7.48119 6.12358 7.23586C6.23289 6.99053 6.39049 6.76972 6.58699 6.58663M0.666992 0.666626L15.3337 15.3333"
                          stroke="#666666"
                          stroke-width="1.6"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3141_74103">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    {{ item.title }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
        <RelatedAccount :removeSelect="true" :accounts="task.account" />
      </div>
    </v-col>
    <v-col cols="12" md="12" class="">
      <div class="task-card h-100">
        <div class="task-title d-flex align-center justify-space-between">
          <span> Related Contacts </span>
          <div>
            <v-btn
              id="show-hidden-related-tasks-columns"
              variant="text"
              class="show-hidden-columns pa-0"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_3141_74103)">
                  <path
                    d="M11.9603 11.96C10.8207 12.8286 9.43306 13.3099 8.00033 13.3333C3.33366 13.3333 0.666992 7.99996 0.666992 7.99996C1.49625 6.45456 2.64642 5.10436 4.04033 4.03996M6.60032 2.82663C7.05921 2.71921 7.52903 2.66552 8.00033 2.66663C12.667 2.66663 15.3337 7.99996 15.3337 7.99996C14.929 8.75703 14.4464 9.46978 13.8937 10.1266M9.41366 9.41329C9.23056 9.60979 9.00976 9.7674 8.76443 9.87671C8.51909 9.98602 8.25426 10.0448 7.98572 10.0495C7.71718 10.0543 7.45043 10.0049 7.2014 9.90429C6.95236 9.8037 6.72614 9.65398 6.53622 9.46406C6.34631 9.27414 6.19659 9.04792 6.096 8.79889C5.99541 8.54985 5.94601 8.28311 5.95075 8.01457C5.95549 7.74602 6.01426 7.48119 6.12358 7.23586C6.23289 6.99053 6.39049 6.76972 6.58699 6.58663M0.666992 0.666626L15.3337 15.3333"
                    stroke="#666666"
                    stroke-width="1.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3141_74103">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span>Show hidden columns</span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 6L8 10L12 6"
                  stroke="#666666"
                  stroke-width="1.6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </v-btn>
            <v-menu activator="#show-hidden-related-tasks-columns">
              <v-list>
                <v-list-item
                  v-for="(item, index) in hidden_contacts_headers"
                  :key="index"
                  :value="index"
                >
                  <v-list-item-title
                    class="d-flex align-center ga-2"
                    @click="showHiddenContactColumn(item.key)"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_3141_74103)">
                        <path
                          d="M11.9603 11.96C10.8207 12.8286 9.43306 13.3099 8.00033 13.3333C3.33366 13.3333 0.666992 7.99996 0.666992 7.99996C1.49625 6.45456 2.64642 5.10436 4.04033 4.03996M6.60032 2.82663C7.05921 2.71921 7.52903 2.66552 8.00033 2.66663C12.667 2.66663 15.3337 7.99996 15.3337 7.99996C14.929 8.75703 14.4464 9.46978 13.8937 10.1266M9.41366 9.41329C9.23056 9.60979 9.00976 9.7674 8.76443 9.87671C8.51909 9.98602 8.25426 10.0448 7.98572 10.0495C7.71718 10.0543 7.45043 10.0049 7.2014 9.90429C6.95236 9.8037 6.72614 9.65398 6.53622 9.46406C6.34631 9.27414 6.19659 9.04792 6.096 8.79889C5.99541 8.54985 5.94601 8.28311 5.95075 8.01457C5.95549 7.74602 6.01426 7.48119 6.12358 7.23586C6.23289 6.99053 6.39049 6.76972 6.58699 6.58663M0.666992 0.666626L15.3337 15.3333"
                          stroke="#666666"
                          stroke-width="1.6"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3141_74103">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    {{ item.title }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
        <RelatedContact :removeSelect="true" :contacts="task.contacts" />
      </div>
    </v-col>
    <v-col cols="12" md="12" class="">
      <div class="account-card h-100">
        <div class="contact-title d-flex align-center justify-space-between">
          <span> Related Projects </span>
          <div>
            <v-btn
              id="show-hidden-columns"
              variant="text"
              class="show-hidden-columns pa-0"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_3141_74103)">
                  <path
                    d="M11.9603 11.96C10.8207 12.8286 9.43306 13.3099 8.00033 13.3333C3.33366 13.3333 0.666992 7.99996 0.666992 7.99996C1.49625 6.45456 2.64642 5.10436 4.04033 4.03996M6.60032 2.82663C7.05921 2.71921 7.52903 2.66552 8.00033 2.66663C12.667 2.66663 15.3337 7.99996 15.3337 7.99996C14.929 8.75703 14.4464 9.46978 13.8937 10.1266M9.41366 9.41329C9.23056 9.60979 9.00976 9.7674 8.76443 9.87671C8.51909 9.98602 8.25426 10.0448 7.98572 10.0495C7.71718 10.0543 7.45043 10.0049 7.2014 9.90429C6.95236 9.8037 6.72614 9.65398 6.53622 9.46406C6.34631 9.27414 6.19659 9.04792 6.096 8.79889C5.99541 8.54985 5.94601 8.28311 5.95075 8.01457C5.95549 7.74602 6.01426 7.48119 6.12358 7.23586C6.23289 6.99053 6.39049 6.76972 6.58699 6.58663M0.666992 0.666626L15.3337 15.3333"
                    stroke="#666666"
                    stroke-width="1.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3141_74103">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span>Show hidden columns</span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 6L8 10L12 6"
                  stroke="#666666"
                  stroke-width="1.6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </v-btn>
            <v-menu activator="#show-hidden-columns">
              <v-list>
                <v-list-item
                  v-for="(item, index) in hidden_projects_headers"
                  :key="index"
                  :value="index"
                >
                  <v-list-item-title
                    class="d-flex align-center ga-2"
                    @click="showHiddenRelatedComsColumn(item.key)"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_3141_74103)">
                        <path
                          d="M11.9603 11.96C10.8207 12.8286 9.43306 13.3099 8.00033 13.3333C3.33366 13.3333 0.666992 7.99996 0.666992 7.99996C1.49625 6.45456 2.64642 5.10436 4.04033 4.03996M6.60032 2.82663C7.05921 2.71921 7.52903 2.66552 8.00033 2.66663C12.667 2.66663 15.3337 7.99996 15.3337 7.99996C14.929 8.75703 14.4464 9.46978 13.8937 10.1266M9.41366 9.41329C9.23056 9.60979 9.00976 9.7674 8.76443 9.87671C8.51909 9.98602 8.25426 10.0448 7.98572 10.0495C7.71718 10.0543 7.45043 10.0049 7.2014 9.90429C6.95236 9.8037 6.72614 9.65398 6.53622 9.46406C6.34631 9.27414 6.19659 9.04792 6.096 8.79889C5.99541 8.54985 5.94601 8.28311 5.95075 8.01457C5.95549 7.74602 6.01426 7.48119 6.12358 7.23586C6.23289 6.99053 6.39049 6.76972 6.58699 6.58663M0.666992 0.666626L15.3337 15.3333"
                          stroke="#666666"
                          stroke-width="1.6"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3141_74103">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    {{ item.title }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>

        <RelatedProject :removeSelect="true" :projects="task.projects" />
      </div>
    </v-col>
    <v-col cols="12" md="12" class="">
      <div class="contact-card h-100">
        <div class="contact-title d-flex align-center justify-space-between">
          <span> Related Deals </span>
          <div>
            <v-btn
              id="show-hidden-related-deals-columns"
              variant="text"
              class="show-hidden-columns pa-0"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_3141_74103)">
                  <path
                    d="M11.9603 11.96C10.8207 12.8286 9.43306 13.3099 8.00033 13.3333C3.33366 13.3333 0.666992 7.99996 0.666992 7.99996C1.49625 6.45456 2.64642 5.10436 4.04033 4.03996M6.60032 2.82663C7.05921 2.71921 7.52903 2.66552 8.00033 2.66663C12.667 2.66663 15.3337 7.99996 15.3337 7.99996C14.929 8.75703 14.4464 9.46978 13.8937 10.1266M9.41366 9.41329C9.23056 9.60979 9.00976 9.7674 8.76443 9.87671C8.51909 9.98602 8.25426 10.0448 7.98572 10.0495C7.71718 10.0543 7.45043 10.0049 7.2014 9.90429C6.95236 9.8037 6.72614 9.65398 6.53622 9.46406C6.34631 9.27414 6.19659 9.04792 6.096 8.79889C5.99541 8.54985 5.94601 8.28311 5.95075 8.01457C5.95549 7.74602 6.01426 7.48119 6.12358 7.23586C6.23289 6.99053 6.39049 6.76972 6.58699 6.58663M0.666992 0.666626L15.3337 15.3333"
                    stroke="#666666"
                    stroke-width="1.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3141_74103">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span>Show hidden columns</span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 6L8 10L12 6"
                  stroke="#666666"
                  stroke-width="1.6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </v-btn>
            <v-menu activator="#show-hidden-related-deals-columns">
              <v-list>
                <v-list-item
                  v-for="(item, index) in hidden_deals_headers"
                  :key="index"
                  :value="index"
                >
                  <v-list-item-title
                    class="d-flex align-center ga-2"
                    @click="showHiddenDealsColumn(item.key)"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_3141_74103)">
                        <path
                          d="M11.9603 11.96C10.8207 12.8286 9.43306 13.3099 8.00033 13.3333C3.33366 13.3333 0.666992 7.99996 0.666992 7.99996C1.49625 6.45456 2.64642 5.10436 4.04033 4.03996M6.60032 2.82663C7.05921 2.71921 7.52903 2.66552 8.00033 2.66663C12.667 2.66663 15.3337 7.99996 15.3337 7.99996C14.929 8.75703 14.4464 9.46978 13.8937 10.1266M9.41366 9.41329C9.23056 9.60979 9.00976 9.7674 8.76443 9.87671C8.51909 9.98602 8.25426 10.0448 7.98572 10.0495C7.71718 10.0543 7.45043 10.0049 7.2014 9.90429C6.95236 9.8037 6.72614 9.65398 6.53622 9.46406C6.34631 9.27414 6.19659 9.04792 6.096 8.79889C5.99541 8.54985 5.94601 8.28311 5.95075 8.01457C5.95549 7.74602 6.01426 7.48119 6.12358 7.23586C6.23289 6.99053 6.39049 6.76972 6.58699 6.58663M0.666992 0.666626L15.3337 15.3333"
                          stroke="#666666"
                          stroke-width="1.6"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3141_74103">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    {{ item.title }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
        <RelatedDeals :removeSelect="true" :deals="task.deals" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import RelatedAccount from "@/components/crm/accounts/table.vue";
import RelatedContact from "@/components/crm/contacts/table.vue";
import RelatedDeals from "@/components/crm/deals/table.vue";
import RelatedProject from "@/components/crm/projects/table.vue";

import { useTasksStore } from "@/store/crm/useTasksStore";
import { useAccountsStore } from "@/store/crm/useAccountsStore";
import { useContactsStore } from "@/store/crm/useContactsStore";
import { useProjectsStore } from "@/store/crm/useProjectsStore";
import { useDealsStore } from "@/store/crm/useDealsStore";
import apiClient from "@/core/buildRequest";

export default {
  name: "TaskCard",
  components: {
    RelatedAccount,
    RelatedContact,
    RelatedDeals,
    RelatedProject,
  },
  data() {
    return {
      task: {
        id: null,
        task_number: null,
        name: null,
        description: null,
        add_to_calendar: null,
        status: null,
        estimated_bouns: null,
        incentive: null,
        due_date: null,
        weight: null,
        start_date: null,
        account: [],
        projects: [],
        contacts: [],
        members: [],
        deals: [],
      },
      task_store: null,
      account_store: null,
      contact_store: null,
      projects_store: null,
      deals_store: null,
    };
  },
  computed: {
    tasks() {
      return this.task_store?.tasks || [];
    },
    accounts() {
      return this.account_store?.accounts || [];
    },
    contacts() {
      return this.contact_store?.contacts || [];
    },
    hidden_task_headers() {
      const headers = this.task_store?.headers.filter((header) => !header.show);
      return headers;
    },
    hidden_account_headers() {
      const headers = this.account_store?.headers.filter(
        (header) => !header.show
      );
      return headers;
    },
    hidden_contacts_headers() {
      const headers = this.contact_store?.headers.filter(
        (header) => !header.show
      );
      return headers;
    },
    hidden_projects_headers() {
      const headers = this.projects_store?.getHeaders.filter(
        (header) => !header.show
      );
      return headers;
    },
    hidden_deals_headers() {
      const headers = this.deals_store?.getHeaders.filter(
        (header) => !header.show
      );
      return headers;
    },
  },
  created() {
    this.task_store = useTasksStore();
    this.account_store = useAccountsStore();
    this.contact_store = useContactsStore();

    this.projects_store = useProjectsStore();
    this.deals_store = useDealsStore();
  },
  methods: {
    showHiddenRelatedComsColumn(key) {
      const header = this.projects_store?.headers.find((h) => h.key === key);
      if (header) {
        header.show = true;
      }
    },
    showHiddenDealsColumn(key) {
      const header = this.deals_store?.headers.find((h) => h.key === key);
      if (header) {
        header.show = true;
      }
    },
    showHiddenTaskColumn(key) {
      const header = this.task_store?.headers.find((h) => h.key === key);
      if (header) {
        header.show = true;
      }
    },
    showHiddenAccountColumn(key) {
      const header = this.account_store?.headers.find((h) => h.key === key);
      if (header) {
        header.show = true;
      }
    },
    showHiddenContactColumn(key) {
      const header = this.contact_store?.headers.find((h) => h.key === key);
      if (header) {
        header.show = true;
      }
    },
    async getTask(id) {
      try {
        await apiClient.get(`/leads-managment/tasks/${id}`).then((response) => {
          this.task = response.data.data;
          this.task.account = response.data.data.account
            ? [response.data.data.account]
            : [];
          this.task.projects = response.data.data.project
            ? [response.data.data.project]
            : [];
          this.task.deals = response.data.data.deal
            ? [response.data.data.deal]
            : [];
        });
      } catch (error) {
        console.error("Failed to Get Deal:", error);
      }
    },
  },
  mounted() {
    this.getTask(this.$route.params.id);
  },
};
</script>

<style scoped>
.task-card {
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 10px 60px 0px #dedede80;
}
.task-card .data .element {
  height: 40px;
  padding: 0px 24px 0px 16px;
  background: #f7f8f8;
  border-radius: 8px;
}
.task-card .data .element .title {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  color: #0d0d0d;
}
.task-card .data .element .value {
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  color: #666666;
}
.task-title {
  color: #0d0d0d;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.36px;
  margin-bottom: 30px;
}
</style>
