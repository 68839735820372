<template>
  <div class="search-input">
    <span class="search-icon">
        <img style="width: 15px; height: 15px" src="@/assets/tiktokImage/transparency.svg" alt="">
    </span>
    <input
      type="text"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      @click="toggleSearchContant"
      placeholder="Search or select interests & behaviors "
    />
  </div>
</template>

<script>
export default {
  name: "SearchInput",
  props: {
    modelValue: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue"],
  methods:{
    toggleSearchContant(){
        this.$emit('toggleSearch')
    }
  }
};
</script>

<style scoped>
.search-input {
  position: relative;
  padding: 0;
  
}

input {
  width: 51%;
  padding: 4px 8px 4px 32px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.5;
  color: #606266;
  transition: border-color 0.2s;
    background-color: #f2f2f3;
}

input:focus {
  outline: none;
  border-color: #009995;
}

input::placeholder {
  color: #c0c4cc;
}

.search-icon {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  color: #c0c4cc;
  font-size: 14px;
}
</style>
