<template>
  <v-layout class="position-relative">
    <router-view />
  </v-layout>
  <v-snackbar
    v-model="store.snackbar.show"
    :timeout="store.snackbar.timeout"
    :color="store.snackbar.color"
    location="top"
    close-on-content-click
  >
    {{ store.snackbar.message }}
  </v-snackbar>
</template>

<script>
import { useGeneralStore } from "@/store/General";

export default {
  data() {
    return {
      store: useGeneralStore(),
    };
  },
  mounted() {},
};
</script>

<style scoped>
.mt-20 {
  margin-top: 90px;
}
</style>
