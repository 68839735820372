<template>
  <Layout :linksData="linksData">
    <div class="leads-managements-link d-flex align-center ga-2 my-3">
      <div
        @click="$router.push({ name: 'Dealsmanagement' })"
        class="link cursor-pointer hoverd-style-line d-flex align-center ga-2"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.825 13L13.425 18.6L12 20L4 12L12 4L13.425 5.4L7.825 11H20V13H7.825Z"
            fill="#1F666A"
          />
        </svg>
        <span>Deal Details</span>
      </div>
    </div>
    <div class="">
      <DealBody />
    </div>
  </Layout>
</template>
<script>
import Layout from "@/components/crm/layouts/Layout.vue";
import DealBody from "@/components/crm/deals/deal/DealBody.vue";
import "@/assets/css/crm/crm-moduls.css";
export default {
  components: {
    Layout,
    DealBody,
  },

  data() {
    return {
      linksData: [
        { name: "Home", link: "/home-page" },
        { name: "Deals", link: "" },
      ],
    };
  },
};
</script>
