<template>
    <div class="cropper-container">
      <vue-cropper
        ref="cropper"
        :src="src"
        :aspect-ratio="1"
        :view-mode="2"
        :auto-crop-area="1"
        :background="true"
        :movable="true"
        :rotatable="true"
        :scalable="true"
        :zoomable="true"
        :center="true"
        :highlight="true"
        :crop-box-movable="true"
        :crop-box-resizable="true"
        :guides="true"
        :modal="true"
      />
    </div>
  </template>
  
  <script>
  import VueCropper from "vue-cropperjs";
  import "cropperjs/dist/cropper.css";
  
  export default {
    name: 'ImageCropper',
    components: {
      VueCropper
    },
    props: {
      src: {
        type: String,
        required: true
      }
    },
    methods: {
      async getCroppedImage() {
        const cropper = this.$refs.cropper;
        if (!cropper) return null;
        
        const canvas = cropper.getCroppedCanvas();
        if (!canvas) return null;
        
        return new Promise(resolve => {
          canvas.toBlob(blob => {
            resolve({
              blob,
              url: URL.createObjectURL(blob)
            });
          }, 'image/jpeg', 0.9);
        });
      }
    }
  }
  </script>
  
  <style scoped>
  .cropper-container {
    height: 400px;
    width: 100%;
    background: #f0f0f0;
  }
  </style>