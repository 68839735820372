<template>
  <v-container fluid class="pa-0 ma-0 h-100">
    <v-row class="ma-0 pa-0 h-100">
      <v-col
        cols="2"
        style="
          background: linear-gradient(
            90deg,
            rgba(250, 240, 240, 1) 0%,
            rgba(233, 245, 242, 1) 100%
          );
        "
      >
        <div class="dialog-side-bar">
          <h4>Set up your media</h4>
          <v-tabs v-model="tab" color="primary" direction="vertical">
            <v-tab
              v-for="item in side_bar_title_media"
              prepend-icon="mdi-circle"
              :value="item.value"
              :key="item.id"
            >
              {{ truncateText(item.title, 10) }}
            </v-tab>
          </v-tabs>
        </div>
      </v-col>
      <v-col cols="10 select-container meta-upload-image position-relative">
        <v-tabs-window v-model="tab" style="border-radius: 0 !important">
          <v-tabs-window-item
            v-for="item in side_bar_title_media"
            :value="item.value"
          >
            <v-card flat class="pa-0 card-pattern">
              <v-card-text
                v-if="item.value === 'option-1'"
                class="pa-0 ma-0 w-75"
              >
                <div class="set-up-mdia mb-5">
                  <label class="roboto-bold" for="">Text</label>
                  <p>
                    Add multiple text options and we'll show the one that we
                    predict will perform best when your ad is delivered.
                  </p>
                </div>
                <div class="primary-text mb-3">
                  <p class="roboto-bold">
                    Primary text ({{ textInputs.length }} of 5)
                  </p>
                  <div
                    v-for="(input, index) in textInputs"
                    :key="index"
                    class="input-container d-flex align-center ga-1 mb-2"
                  >
                    <input
                      type="text"
                      v-model="textInputs[index]"
                      @input="primary_text[index] = textInputs[index]"
                      :placeholder="'Primary text ' + (index + 1)"
                    />
                    <button
                      v-if="index > 0"
                      @click="removeTextInput(index)"
                      class="remove-btn"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="20px"
                        viewBox="0 -960 960 960"
                        width="20px"
                        fill="#434343"
                      >
                        <path
                          d="m291-240-51-51 189-189-189-189 51-51 189 189 189-189 51 51-189 189 189 189-51 51-189-189-189 189Z"
                        />
                      </svg>
                    </button>
                  </div>
                  <button
                    class="add-text"
                    @click="addTextInput"
                    :disabled="textInputs.length >= 5"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="20px"
                      viewBox="0 -960 960 960"
                      width="20px"
                      fill="#434343"
                    >
                      <path
                        d="M444-288h72v-156h156v-72H516v-156h-72v156H288v72h156v156Zm36.28 192Q401-96 331-126t-122.5-82.5Q156-261 126-330.96t-30-149.5Q96-560 126-629.5q30-69.5 82.5-122T330.96-834q69.96-30 149.5-30t149.04 30q69.5 30 122 82.5T834-629.28q30 69.73 30 149Q864-401 834-331t-82.5 122.5Q699-156 629.28-126q-69.73 30-149 30Zm-.28-72q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z"
                      />
                    </svg>
                    Add text option
                  </button>
                </div>
                <label class="checkbox">
                  <input type="checkbox" disabled="true" checked />
                  <span class="checkmark" style="opacity: 0.5"></span>
                  <span class="text d-block" style="font-size: 14px"
                    >Multi-advertiser ads</span
                  >
                  <span
                    style="font-size: 12px; margin-left: 30px"
                    class="d-block"
                  >
                    Your ads can appear alongside other ads in the same ad unit
                    to help people discover products and services from
                    businesses that are personalised to them. Your ad creative
                    may be resized or cropped to fit the ad unit.
                  </span>
                </label>
                <div class="headline primary-text mb-3">
                  <div
                    class="headline-header d-flex align-center justify-space-between"
                  >
                    <p class="roboto-bold">Headline</p>
                    <span>{{ headlines.length }} of 5</span>
                  </div>
                  <div
                    v-for="(input, index) in headlines"
                    :key="index"
                    class="input-container d-flex align-center ga-1 mb-2"
                  >
                    <input
                      type="text"
                      v-model="headlines[index]"
                      @input="headlines_text[index] = textInputs[index]"
                      :placeholder="'Headline ' + (index + 1)"
                    />
                    <button
                      v-if="index > 0"
                      @click="removeHeadline(index)"
                      class="remove-btn"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="20px"
                        viewBox="0 -960 960 960"
                        width="20px"
                        fill="#434343"
                      >
                        <path
                          d="m291-240-51-51 189-189-189-189 51-51 189 189 189-189 51 51-189 189 189 189-51 51-189-189-189 189Z"
                        />
                      </svg>
                    </button>
                  </div>
                  <button
                    class="add-text"
                    @click="addHeadlineInput"
                    :disabled="headlines.length >= 5"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="20px"
                      viewBox="0 -960 960 960"
                      width="20px"
                      fill="#434343"
                    >
                      <path
                        d="M444-288h72v-156h156v-72H516v-156h-72v156H288v72h156v156Zm36.28 192Q401-96 331-126t-122.5-82.5Q156-261 126-330.96t-30-149.5Q96-560 126-629.5q30-69.5 82.5-122T330.96-834q69.96-30 149.5-30t149.04 30q69.5 30 122 82.5T834-629.28q30 69.73 30 149Q864-401 834-331t-82.5 122.5Q699-156 629.28-126q-69.73 30-149 30Zm-.28-72q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z"
                      />
                    </svg>
                    Add headline option
                  </button>
                </div>
                <div class="headline primary-text mb-3">
                  <div
                    class="headline-header d-flex align-center justify-space-between"
                  >
                    <p class="roboto-bold">Description</p>
                    <span>{{ description.length }} of 5</span>
                  </div>
                  <div
                    v-for="(input, index) in headlines"
                    :key="index"
                    class="input-container d-flex align-center ga-1 mb-2"
                  >
                    <input
                      type="text"
                      v-model="headlines[index]"
                      @input="headlines_text[index] = textInputs[index]"
                      placeholder="Include additional details"
                    />
                    <button
                      v-if="index > 0"
                      @click="removeDescription(index)"
                      class="remove-btn"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="20px"
                        viewBox="0 -960 960 960"
                        width="20px"
                        fill="#434343"
                      >
                        <path
                          d="m291-240-51-51 189-189-189-189 51-51 189 189 189-189 51 51-189 189 189 189-51 51-189-189-189 189Z"
                        />
                      </svg>
                    </button>
                  </div>
                  <button
                    class="add-text"
                    @click="addDescriptionInput"
                    :disabled="headlines.length >= 5"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="20px"
                      viewBox="0 -960 960 960"
                      width="20px"
                      fill="#434343"
                    >
                      <path
                        d="M444-288h72v-156h156v-72H516v-156h-72v156H288v72h156v156Zm36.28 192Q401-96 331-126t-122.5-82.5Q156-261 126-330.96t-30-149.5Q96-560 126-629.5q30-69.5 82.5-122T330.96-834q69.96-30 149.5-30t149.04 30q69.5 30 122 82.5T834-629.28q30 69.73 30 149Q864-401 834-331t-82.5 122.5Q699-156 629.28-126q-69.73 30-149 30Zm-.28-72q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z"
                      />
                    </svg>
                    Add description option
                  </button>
                </div>

                <div class="call-to-actions">
                  <label class="roboto-bold" for="">Call to action</label>
                  <v-select
                    class="w-50"
                    v-model="store.selectedCallToActions"
                    :items="callToActionsArray"
                    density="compact"
                    variant="outlined"
                    return-object
                    item-value="id"
                    item-title="title"
                    hide-details
                  >
                    <template v-slot:selection="{ item }">
                      {{ item?.title || "" }}
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item @click="setCAllToActions(item)">
                        <div class="my-form">
                          <div class="ga-3">
                            <input
                              :checked="
                                store.selectedCallToActions &&
                                store.selectedCallToActions.id === item.raw.id
                              "
                              :id="item.raw.id"
                              type="radio"
                              name="option"
                              :value="item"
                            />
                            <div class="d-flex align-center ga-1">
                              <label
                                :for="item.raw.id"
                                class="d-flex"
                                style="font-size: 14px"
                                >{{ item.title }}</label
                              >
                            </div>
                          </div>
                        </div>
                      </v-list-item>
                    </template>
                  </v-select>
                </div>
              </v-card-text>
              <!-- Media tab content -->
              <v-card-text v-if="item.value === 'option-2'" class="pa-0 ma-0">
                <div class="select-media">
                  <div class="select-media-header mb-3">
                    <label for="" class="roboto-bold"> Select media </label>
                  </div>
                  <div class="select-media-body">
                    <v-card-text>
                      <v-tabs v-model="tabs">
                        <v-tab value="all">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            width="20px"
                            fill="#434343"
                            height="20px"
                          >
                            <path
                              d="M64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-288-128 0c-17.7 0-32-14.3-32-32L224 0 64 0zM256 0l0 128 128 0L256 0zM64 256a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm152 32c5.3 0 10.2 2.6 13.2 6.9l88 128c3.4 4.9 3.7 11.3 1 16.5s-8.2 8.6-14.2 8.6l-88 0-40 0-48 0-48 0c-5.8 0-11.1-3.1-13.9-8.1s-2.8-11.2 .2-16.1l48-80c2.9-4.8 8.1-7.8 13.7-7.8s10.8 2.9 13.7 7.8l12.8 21.4 48.3-70.2c3-4.3 7.9-6.9 13.2-6.9z"
                            />
                          </svg>
                          <span>All</span>
                        </v-tab>
                        <v-tab value="account">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="20px"
                            viewBox="0 -960 960 960"
                            width="20px"
                            fill="#434343"
                          >
                            <path
                              d="M192-96v-72h576v72H192Zm168-144v-288H219l261-336 261 336H600v288H360Zm72-72h96v-288h66L480-747 366-600h66v288Zm48-288Z"
                            />
                          </svg>
                          <span> Account </span>
                        </v-tab>
                        <v-tab value="instagram">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            width="20px"
                            fill="#434343"
                            height="20px"
                          >
                            <path
                              d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                            />
                          </svg>
                          <span>Instagram</span>
                        </v-tab>
                        <v-tab value="page">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="20px"
                            viewBox="0 -960 960 960"
                            width="20px"
                            fill="#434343"
                          >
                            <path
                              d="M168-192q-29.7 0-50.85-21.16Q96-234.32 96-264.04v-432.24Q96-726 117.15-747T168-768h624q29.7 0 50.85 21.16Q864-725.68 864-695.96v432.24Q864-234 842.85-213T792-192H168Zm0-72h408v-120H168v120Zm480 0h144v-312H648v312ZM168-456h408v-120H168v120Z"
                            />
                          </svg>
                          <span>Page</span>
                        </v-tab>
                      </v-tabs>
                      <MediaSearch
                        @search="handleSearch"
                        @upload="handleImageUpload"
                      />
                      <v-tabs-window v-model="tabs">
                        <v-tabs-window-item value="all">
                          <v-container class="pa-0 ma-0">
                            <!-- Image Grid -->
                            <v-row no-gutters>
                              <v-col cols="12" class="mb-2">
                                <p class="d-flex align-center ga-1">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 384 512"
                                    width="20px"
                                    fill="#434343"
                                    height="20px"
                                  >
                                    <path
                                      d="M64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-288-128 0c-17.7 0-32-14.3-32-32L224 0 64 0zM256 0l0 128 128 0L256 0zM64 256a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm152 32c5.3 0 10.2 2.6 13.2 6.9l88 128c3.4 4.9 3.7 11.3 1 16.5s-8.2 8.6-14.2 8.6l-88 0-40 0-48 0-48 0c-5.8 0-11.1-3.1-13.9-8.1s-2.8-11.2 .2-16.1l48-80c2.9-4.8 8.1-7.8 13.7-7.8s10.8 2.9 13.7 7.8l12.8 21.4 48.3-70.2c3-4.3 7.9-6.9 13.2-6.9z"
                                    />
                                  </svg>
                                  <span>Account images</span>
                                </p>
                              </v-col>
                              <v-col
                                v-for="(image, index) in filteredImages"
                                :key="index"
                                cols="1"
                                class="pa-2 mb-2"
                              >
                                <v-card
                                  class="image-card"
                                  :class="{
                                    selected: selectedImages.includes(image.id),
                                  }"
                                  @click="toggleImageSelection(image)"
                                >
                                  <v-img
                                    :src="image.url"
                                    :aspect-ratio="1"
                                    cover
                                    class="image-preview"
                                  >
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height ma-0 align-center justify-center"
                                      >
                                        <v-progress-circular
                                          indeterminate
                                          color="primary"
                                        ></v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                  <div
                                    class="image-overlay"
                                    v-if="selectedImages.includes(image.id)"
                                  >
                                    <v-icon color="white">mdi-check</v-icon>
                                  </div>
                                </v-card>
                              </v-col>
                              <v-col cols="12">
                                <p class="d-flex align-center ga-1">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512"
                                    width="20px"
                                    fill="#434343"
                                    height="20px"
                                  >
                                    <path
                                      d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                                    />
                                  </svg>
                                  <span> Instagram images </span>
                                </p>
                              </v-col>
                              <v-col
                                v-for="(image, index) in filteredInstagram"
                                :key="index"
                                cols="1"
                                class="pa-2 mb-2"
                              >
                                <v-card
                                  class="image-card"
                                  :class="{
                                    selected: selectedImages.includes(image.id),
                                  }"
                                  @click="toggleImageSelection(image)"
                                >
                                  <v-img
                                    :src="image.url"
                                    :aspect-ratio="1"
                                    cover
                                    class="image-preview"
                                  >
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height ma-0 align-center justify-center"
                                      >
                                        <v-progress-circular
                                          indeterminate
                                          color="primary"
                                        ></v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                  <div
                                    class="image-overlay"
                                    v-if="selectedImages.includes(image.id)"
                                  >
                                    <v-icon color="white">mdi-check</v-icon>
                                  </div>
                                </v-card>
                              </v-col>
                              <v-col
                                class="d-flex align-center justify-center mb-2 select-media-page"
                                cols="12"
                              >
                                <p class="d-flex align-center ga-1">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="20px"
                                    viewBox="0 -960 960 960"
                                    width="20px"
                                    fill="#434343"
                                  >
                                    <path
                                      d="M168-192q-29.7 0-50.85-21.16Q96-234.32 96-264.04v-432.24Q96-726 117.15-747T168-768h624q29.7 0 50.85 21.16Q864-725.68 864-695.96v432.24Q864-234 842.85-213T792-192H168Zm0-72h408v-120H168v120Zm480 0h144v-312H648v312ZM168-456h408v-120H168v120Z"
                                    />
                                  </svg>
                                </p>
                                <v-select
                                  class="w-50"
                                  v-model="selectedPageMediaType"
                                  :items="selectedPageMediaTypeArray"
                                  density="compact"
                                  variant="outlined"
                                  return-object
                                  item-value="id"
                                  item-title="title"
                                  hide-details
                                >
                                  <template v-slot:selection="{ item }">
                                    <img
                                      style="
                                        width: 20px;
                                        height: 20px;
                                        border-radius: 50%;
                                      "
                                      :src="item.raw.iamge"
                                      alt=""
                                    />
                                    {{ item?.title || "" }}
                                  </template>
                                  <template v-slot:item="{ item }">
                                    <v-list-item @click="setmediaType(item)">
                                      <div class="my-form">
                                        <div class="ga-3">
                                          <input
                                            :checked="
                                              selectedPageMediaType &&
                                              selectedPageMediaType.id ===
                                                item.raw.id
                                            "
                                            :id="item.raw.id"
                                            type="radio"
                                            name="option"
                                            :value="item"
                                          />
                                          <div class="d-flex align-center ga-1">
                                            <img
                                              style="
                                                width: 20px;
                                                height: 20px;
                                                border-radius: 50%;
                                              "
                                              :src="item.raw.iamge"
                                              alt=""
                                            />
                                            <label
                                              :for="item.raw.id"
                                              class="d-flex"
                                              style="font-size: 14px"
                                              >{{ item.title }}</label
                                            >
                                          </div>
                                        </div>
                                      </div>
                                    </v-list-item>
                                  </template>
                                </v-select>
                              </v-col>
                              <v-col
                                v-for="(image, index) in filteredPages"
                                :key="index"
                                cols="1"
                                class="pa-2 mb-2"
                              >
                                <v-card
                                  class="image-card"
                                  :class="{
                                    selected: selectedImages.includes(image.id),
                                  }"
                                  @click="toggleImageSelection(image)"
                                >
                                  <v-img
                                    :src="image.url"
                                    :aspect-ratio="1"
                                    cover
                                    class="image-preview"
                                  >
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height ma-0 align-center justify-center"
                                      >
                                        <v-progress-circular
                                          indeterminate
                                          color="primary"
                                        ></v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                  <div
                                    class="image-overlay"
                                    v-if="selectedImages.includes(image.id)"
                                  >
                                    <v-icon color="white">mdi-check</v-icon>
                                  </div>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-tabs-window-item>
                        <v-tabs-window-item value="account">
                          <v-container>
                            <v-row>
                              <v-col cols="12" class="mb-2">
                                <p class="d-flex align-center ga-1">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 384 512"
                                    width="20px"
                                    fill="#434343"
                                    height="20px"
                                  >
                                    <path
                                      d="M64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-288-128 0c-17.7 0-32-14.3-32-32L224 0 64 0zM256 0l0 128 128 0L256 0zM64 256a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm152 32c5.3 0 10.2 2.6 13.2 6.9l88 128c3.4 4.9 3.7 11.3 1 16.5s-8.2 8.6-14.2 8.6l-88 0-40 0-48 0-48 0c-5.8 0-11.1-3.1-13.9-8.1s-2.8-11.2 .2-16.1l48-80c2.9-4.8 8.1-7.8 13.7-7.8s10.8 2.9 13.7 7.8l12.8 21.4 48.3-70.2c3-4.3 7.9-6.9 13.2-6.9z"
                                    />
                                  </svg>
                                  <span>Account images</span>
                                </p>
                              </v-col>
                              <v-col
                                v-for="(image, index) in filteredImages"
                                :key="index"
                                cols="1"
                                class="pa-2 mb-2"
                              >
                                <v-card
                                  class="image-card"
                                  :class="{
                                    selected: selectedImages.includes(image.id),
                                  }"
                                  @click="toggleImageSelection(image)"
                                >
                                  <v-img
                                    :src="image.url"
                                    :aspect-ratio="1"
                                    cover
                                    class="image-preview"
                                  >
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height ma-0 align-center justify-center"
                                      >
                                        <v-progress-circular
                                          indeterminate
                                          color="primary"
                                        ></v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                  <div
                                    class="image-overlay"
                                    v-if="selectedImages.includes(image.id)"
                                  >
                                    <v-icon color="white">mdi-check</v-icon>
                                  </div>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-tabs-window-item>
                        <v-tabs-window-item value="instagram">
                          <v-container>
                            <v-row no-gutters>
                              <v-col cols="12">
                                <p class="d-flex align-center ga-1">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512"
                                    width="20px"
                                    fill="#434343"
                                    height="20px"
                                  >
                                    <path
                                      d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                                    />
                                  </svg>
                                  <span> Instagram images </span>
                                </p>
                              </v-col>
                              <v-col
                                v-for="(image, index) in filteredInstagram"
                                :key="index"
                                cols="1"
                                class="pa-2 mb-2"
                              >
                                <v-card
                                  class="image-card"
                                  :class="{
                                    selected: selectedImages.includes(image.id),
                                  }"
                                  @click="toggleImageSelection(image)"
                                >
                                  <v-img
                                    :src="image.url"
                                    :aspect-ratio="1"
                                    cover
                                    class="image-preview"
                                  >
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height ma-0 align-center justify-center"
                                      >
                                        <v-progress-circular
                                          indeterminate
                                          color="primary"
                                        ></v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                  <div
                                    class="image-overlay"
                                    v-if="selectedImages.includes(image.id)"
                                  >
                                    <v-icon color="white">mdi-check</v-icon>
                                  </div>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-tabs-window-item>
                        <v-tabs-window-item value="page">
                          <v-container>
                            <v-row no-gutters>
                              <v-col
                                class="d-flex align-center justify-center mb-2 select-media-page"
                                cols="12"
                              >
                                <p class="d-flex align-center ga-1">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="20px"
                                    viewBox="0 -960 960 960"
                                    width="20px"
                                    fill="#434343"
                                  >
                                    <path
                                      d="M168-192q-29.7 0-50.85-21.16Q96-234.32 96-264.04v-432.24Q96-726 117.15-747T168-768h624q29.7 0 50.85 21.16Q864-725.68 864-695.96v432.24Q864-234 842.85-213T792-192H168Zm0-72h408v-120H168v120Zm480 0h144v-312H648v312ZM168-456h408v-120H168v120Z"
                                    />
                                  </svg>
                                </p>
                                <v-select
                                  class="w-50"
                                  v-model="selectedPageMediaType"
                                  :items="selectedPageMediaTypeArray"
                                  density="compact"
                                  variant="outlined"
                                  return-object
                                  item-value="id"
                                  item-title="title"
                                  hide-details
                                >
                                  <template v-slot:selection="{ item }">
                                    <img
                                      style="
                                        width: 20px;
                                        height: 20px;
                                        border-radius: 50%;
                                      "
                                      :src="item.raw.iamge"
                                      alt=""
                                    />
                                    {{ item?.title || "" }}
                                  </template>
                                  <template v-slot:item="{ item }">
                                    <v-list-item @click="setmediaType(item)">
                                      <div class="my-form">
                                        <div class="ga-3">
                                          <input
                                            :checked="
                                              selectedPageMediaType &&
                                              selectedPageMediaType.id ===
                                                item.raw.id
                                            "
                                            :id="item.raw.id"
                                            type="radio"
                                            name="option"
                                            :value="item"
                                          />
                                          <div class="d-flex align-center ga-1">
                                            <img
                                              style="
                                                width: 20px;
                                                height: 20px;
                                                border-radius: 50%;
                                              "
                                              :src="item.raw.iamge"
                                              alt=""
                                            />
                                            <label
                                              :for="item.raw.id"
                                              class="d-flex"
                                              style="font-size: 14px"
                                              >{{ item.title }}</label
                                            >
                                          </div>
                                        </div>
                                      </div>
                                    </v-list-item>
                                  </template>
                                </v-select>
                              </v-col>
                              <v-col
                                v-for="(image, index) in filteredImages"
                                :key="index"
                                cols="1"
                                class="pa-2 mb-2"
                              >
                                <v-card
                                  class="image-card"
                                  :class="{
                                    selected: selectedImages.includes(image.id),
                                  }"
                                  @click="toggleImageSelection(image)"
                                >
                                  <v-img
                                    :src="image.url"
                                    :aspect-ratio="1"
                                    cover
                                    class="image-preview"
                                  >
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height ma-0 align-center justify-center"
                                      >
                                        <v-progress-circular
                                          indeterminate
                                          color="primary"
                                        ></v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                  <div
                                    class="image-overlay"
                                    v-if="selectedImages.includes(image.id)"
                                  >
                                    <v-icon color="white">mdi-check</v-icon>
                                  </div>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-tabs-window-item>
                      </v-tabs-window>
                    </v-card-text>
                  </div>
                </div>
              </v-card-text>
              <!-- Crope Image -->
              <v-card-text class="pa-0" v-if="item.value === 'option-3'">
                <ImageCropper
                  :selected-image-ids="selectedImages"
                  :all-images="[...images, ...instagramImages]"
                  @crop-applied="handleCrop"
                />
              </v-card-text>
            </v-card>
          </v-tabs-window-item>
        </v-tabs-window>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { meta } from "@/store/meta/meta.js";
import { mapActions, mapState } from "pinia";
import MediaSearch from "./MediaSearch.vue";
import ImageCropper from './ImageCropper.vue';




export default {
  components: { MediaSearch,ImageCropper },
  data() {
    return {
      tab: "option-1",
      textInputs: [""],
      headlines: [""],
      description: [""],
      store: meta(),
      tabs: null,
      mediaTypeArray: [
        {
          id: 0,
          checked: false,
          title: "Image ad",
          value: "image_ad",
          icon: `<svg width="20px" height="20px" fill="#5f6368" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M160 32c-35.3 0-64 28.7-64 64l0 224c0 35.3 28.7 64 64 64l352 0c35.3 0 64-28.7 64-64l0-224c0-35.3-28.7-64-64-64L160 32zM396 138.7l96 144c4.9 7.4 5.4 16.8 1.2 24.6S480.9 320 472 320l-144 0-48 0-80 0c-9.2 0-17.6-5.3-21.6-13.6s-2.9-18.2 2.9-25.4l64-80c4.6-5.7 11.4-9 18.7-9s14.2 3.3 18.7 9l17.3 21.6 56-84C360.5 132 368 128 376 128s15.5 4 20 10.7zM192 128a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM48 120c0-13.3-10.7-24-24-24S0 106.7 0 120L0 344c0 75.1 60.9 136 136 136l320 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-320 0c-48.6 0-88-39.4-88-88l0-224z"/></svg>`,
        },
        {
          id: 1,
          checked: false,
          title: "Video ad",
          value: "video_ad",
          icon: `<svg width="20px" height="20px" fill="#5f6368" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.--><path d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z"/></svg>`,
        },
      ],
      selectedMediaType: {
        id: 3,
        title: "Set up creative",
        icon: ``,
      },
      selectedImages: [],
      images: [
        {
          id: 1,
          url: "https://picsum.photos/200/200?random=1",
          type: "account",
        },
        {
          id: 2,
          url: "https://picsum.photos/200/200?random=2",
          type: "instagram",
        },
        {
          id: 3,
          url: "https://picsum.photos/200/200?random=3",
          type: "account",
        },
        {
          id: 4,
          url: "https://picsum.photos/200/200?random=4",
          type: "instagram",
        },
        { id: 5, url: "https://picsum.photos/200/200?random=5", type: "page" },
        { id: 6, url: "https://picsum.photos/200/200?random=6", type: "page" },
      ],
      instagramImages: [
        {
          id: 7,
          url: "https://picsum.photos/200/200?random=1",
          type: "account",
        },
        {
          id: 8,
          url: "https://picsum.photos/200/200?random=2",
          type: "instagram",
        },
        {
          id: 9,
          url: "https://picsum.photos/200/200?random=3",
          type: "account",
        },
        {
          id: 10,
          url: "https://picsum.photos/200/200?random=4",
          type: "instagram",
        },
      ],
      instagramImagesSelected: [],
      pagesImages:[
      {
          id: 11,
          url: "https://picsum.photos/200/200?random=1",
          type: "account",
        },
        {
          id: 12,
          url: "https://picsum.photos/200/200?random=2",
          type: "instagram",
        },
        {
          id: 13,
          url: "https://picsum.photos/200/200?random=3",
          type: "account",
        },
        {
          id: 14,
          url: "https://picsum.photos/200/200?random=4",
          type: "instagram",
        },
      ],
      pagesImagesSelected:[],
      selectedPageMediaType: {
        id: 0,
        title: "مستشفى النخبة - Elite Hospital",
        iamge: "https://picsum.photos/200/200?random=5",
        value: "مستشفى النخبة - Elite Hospital",
      },
      selectedPageMediaTypeArray: [
        {
          id: 0,
          title: "مستشفى النخبة - Elite Hospital",
          iamge: "https://picsum.photos/200/200?random=5",
          value: "مستشفى النخبة - Elite Hospital",
        },
        {
          id: 1,
          title: "مستشفى النخبة - Elite Hospital",
          iamge: "https://picsum.photos/200/200?random=6",
          value: "مستشفى النخبة - Elite Hospital",
        },
      ],
      searchQuery: "",
    };
  },
  methods: {
    ...mapActions(meta, ["truncateText"]),
    addTextInput() {
      if (this.textInputs.length < 5) {
        this.textInputs.push("");
      }
    },
    removeTextInput(index) {
      this.textInputs.splice(index, 1);
    },
    removeHeadline(index) {
      this.headlines.splice(index, 1);
    },
    addHeadlineInput() {
      if (this.headlines.length < 5) {
        this.headlines.push("");
      }
    },
    removeDescription(index) {
      this.headlines.splice(index, 1);
    },
    addDescriptionInput() {
      if (this.description.length < 5) {
        this.headlines.push("");
      }
    },
    setCAllToActions(item) {
      this.store.selectedCallToActions = item.raw;
      localStorage.setItem("selected_call_to_actions", item.raw.value);
    },
    setmediaType(item) {
      this.selectedPageMediaType = item.raw;
      localStorage.setItem("selected_page_type", item.raw.value);
    },
    toggleImageSelection(image) {
      if (this.selectedImages.includes(image.id)) {
        this.selectedImages = [];
      } else {
        // Replace any existing selection with the new image
        this.selectedImages = [image.id];
      }
      localStorage.setItem("selectedImages", JSON.stringify(this.selectedImages));
    },
    
    handleSearch(query) {
      this.searchQuery = query;
    },
    handleImageUpload(newImage) {
      this.images.push(newImage);
      localStorage.setItem("images", JSON.stringify(this.images));
    },
    handleCrop(cropData) {
      console.log('Cropped image:', cropData);
      localStorage.setItem('cropped_images_preview', JSON.stringify(cropData.dataUrl));
    }
  },
  computed: {
    ...mapState(meta, [
      "side_bar_title_media",
      "primary_text",
      "headlines_text",
      "description_text",
      "callToActionsArray",
    ]),
    filteredImages() {
      let filtered = this.images;
      if (this.tabs !== "all") {
        filtered = filtered.filter((image) => image.type === this.tabs);
      }
      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase();
        filtered = filtered.filter(
          (image) =>
            image.type.toLowerCase().includes(query) ||
            image.url.toLowerCase().includes(query)
        );
      }

      return filtered;
    },
    filteredInstagram() {
      let filtered = this.instagramImages;

      if (this.tabs !== "all") {
        filtered = filtered.filter((image) => image.type === this.tabs);
      }
      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase();
        filtered = filtered.filter(
          (image) =>
            image.type.toLowerCase().includes(query) ||
            image.url.toLowerCase().includes(query)
        );
      }
      return filtered;
    },
    filteredPages() {
      let filtered = this.pagesImages;
      if (this.tabs !== "all") {
        filtered = filtered.filter((image) => image.type === this.tabs);
      }
      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase();
        filtered = filtered.filter(
          (image) =>
            image.type.toLowerCase().includes(query) ||
            image.url.toLowerCase().includes(query)
        );
      }
      return filtered;
    }
  },
  created() {
    const savedImagesList = localStorage.getItem("images");
    if (savedImagesList) {
      this.images = JSON.parse(savedImagesList);
    }
    const savedImages = localStorage.getItem("selectedImages");
    if (savedImages) {
      this.selectedImages = JSON.parse(savedImages);
    }
  },
};
</script>

<style scoped>
.image-card {
  position: relative;
  cursor: pointer;
  border: 2px solid transparent;
  transition: all 0.2s ease;
  border-radius: 8px;
  overflow: hidden;
}

.image-card.selected {
  border-color: #1976d2;
}

.image-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(25, 118, 210, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Container styles */
.select-container {
  height: 100vh;
  overflow-y: auto;
}

/* Dialog sidebar styles */
.dialog-side-bar {
  padding: 1rem;
}

/* Form styles */
.my-form input[type="radio"] {
  margin-right: 8px;
}

/* Card pattern */
.card-pattern {
  padding: 1.5rem;
}

/* Input container */
.input-container {
  gap: 8px;
}

.input-container input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* Button styles */
.add-text {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border: none;
  background: transparent;
  color: #434343;
  cursor: pointer;
}

.add-text:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.remove-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 4px;
}

/* Checkbox styles */
.checkbox {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  user-select: none;
}

.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 4px;
}

.checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

.checkbox input:checked ~ .checkmark {
  background-color: #2196f3;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox input:checked ~ .checkmark:after {
  display: block;
}

.checkbox .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
</style>
