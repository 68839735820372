<template>
    <div class="comments-container">
      <div @click="activityStore.toggleComments" class="toggle-comments">
        <span v-if="activityStore.showComments" class="d-flex align-center ga-1">
          <svg
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 5L5 1L1 5"
              stroke="#666666"
              stroke-width="1.6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Hide
        </span>
        <span v-else class="d-flex align-center ga-1">
          <svg
            style="transform: rotate(180deg)"
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 5L5 1L1 5"
              stroke="#666666"
              stroke-width="1.6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Show
        </span>
      </div>
      
      <div class="comments" v-if="activityStore.showComments">
        <TaskComment
          v-for="(comment, index) in comments"
          :key="index"
          :comment="comment"
        />
      </div>
      
      <div class="create-comments">
        <TaskNewComment @comment-added="handleNewComment" />
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue';
  import { useActivityStore } from '@/store/activityStore';
  import TaskComment from './TaskComment.vue';
  import TaskNewComment from './TaskNewComment.vue';
  
  export default {
    name: 'TaskComments',
    components: {
      TaskComment,
      TaskNewComment
    },
    setup() {
      const activityStore = useActivityStore();
      const comments = ref([
        {
          user: {
            name: 'John Doe',
            avatar: 'https://ui-avatars.com/api/?length=1?name=John'
          },
          time: 'Yesterday at 12:21 pm',
          content: 'I just tried this recipe and it was amazing! The instructions were clear and easy to follow, and the end result was delicious.'
        }
      ]);
  
      const handleNewComment = (comment) => {
        comments.value.push({
          user: {
            name: 'Current User',
            avatar: 'https://ui-avatars.com/api/?length=1?name=User'
          },
          time: 'Just now',
          content: comment
        });
        activityStore.addActivity('You added a new comment');
      };
  
      return {
        activityStore,
        comments,
        handleNewComment
      };
    }
  };
  </script>
  
  <style scoped>
  .toggle-comments {
    cursor: pointer;
    padding: 0.5rem;
    color: #666;
    font-size: 0.9rem;
    display: flex;
    justify-content: flex-end;
  }
  
  .comments {
    margin-top: 1rem;
  }
  </style>