<template>
  <div class="d-flex flex-column ga-3 justify-center updates-container">
    <NewUpdate :item="item" @update:added="updateAdded" />
    <div class="updates">
      <Updates :item="item" :call="call" />
    </div>
  </div>
</template>
<script>
import NewUpdate from "@/components/crm/leads/comments/newUpdates/NewUpdate.vue";
import Updates from "@/components/crm/leads/comments/newUpdates/updates.vue";
export default {
  name: "UpdatesComp",
  props: ["item"],
  components: {
    NewUpdate,
    Updates,
  },
  data() {
    return {
      call: false,
    };
  },
  methods: {
    updateAdded() {
      this.call = true;
    },
  },
};
</script>

<style scoped>
.updates-container {
  padding: 30px;
  min-height: 50vh;
}
</style>
