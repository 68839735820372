import { defineStore } from "pinia";
import { ref } from "vue";

export const useActivityStore = defineStore("activity", () => {
  const activities = ref([
    {
      name: "You created this task",
      time: "1 hour ago",
    },
    {
      name: "You change the status to to do",
      time: "2 hour ago",
    },
    {
      name: "You changed the status from to do to complete",
      time: "4 hour ago",
    },
    {
      name: "You uploaded an image",
      time: "5 hour ago",
    },
  ]);

  const showComments = ref(true);

  function addActivity(activity) {
    activities.value.unshift({
      name: activity,
      time: "just now",
    });
  }

  function toggleComments() {
    showComments.value = !showComments.value;
  }

  return {
    activities,
    showComments,
    addActivity,
    toggleComments,
  };
});
