<template>
  soooooooooon
  <!-- <div class="header">
    <img :src="TikTokStoryHeader" alt="" />
  </div>
  <div class="image position-relative" style="width: 100%; height: 640px">
    <div
      class="position-absolute top-0 left-0"
      style="width: 100%; height: 100%"
    >
      <img :src="TikTokStoryLoaderTop" alt="" />
      <img :src="TikTokStoryLoaderBottom" alt="" />
    </div>
    <img :src="post?.file?.preview" width="100%" height="100%" alt="" />
  </div>
  <div class="footer">
    <img :src="TikTokStoryActions" alt="" />
    <img :src="TikTokStoryMobile" alt="" />
  </div> -->
</template>
<script>
import { publishPost } from "@/store/publishPost/publishPost.js";
import TikTokStoryHeader from "@/components/publish_post_components/reviews/svgs/TikTokStoryHeader.svg";
import TikTokStoryLoaderTop from "@/components/publish_post_components/reviews/svgs/TikTokStoryLoaderTop.svg";
import TikTokStoryLoaderBottom from "@/components/publish_post_components/reviews/svgs/TikTokStoryLoaderBottom.svg";
import TikTokStoryActions from "@/components/publish_post_components/reviews/svgs/TikTokStoryActions.svg";
import TikTokStoryMobile from "@/components/publish_post_components/reviews/svgs/TikTokStoryMobile.svg";
export default {
  name: "TikTokStoryReview",
  components: {},
  data() {
    return {
      TikTokStoryHeader,
      TikTokStoryLoaderTop,
      TikTokStoryLoaderBottom,
      TikTokStoryActions,
      TikTokStoryMobile,
      store: publishPost(),
      post: {},
    };
  },
  watch: {
    "store.getCarouselSlider": {
      handler() {
        this.post = this.store.post["snapchat"].find(
          (item) => item.type === "story"
        );
      },
      immediate: true,
    },
  },
};
</script>

<style></style>
