<template>
  <v-container
    fluid
    style="margin-top: 0%; padding: 0% 0% 0 0; min-height: calc(100vh - 58px)"
  >
    <v-row style="height: 100%; margin: 0; padding: 0; position: relative">
      <v-col cols="12" class="pa-0">
        <GlobalNav />
      </v-col>
      <v-col
        cols="12"
        style="height: 100%"
        class="pa-0 d-flex align-start ga-6"
      >
        <SideBar />
        <div class="w-100 pa-3 is-light-mode" style="height: 87vh">
          <BreadCrumb :linksData="linksData" class="mb-4 mt-4" />
          <div
            class="leads-managements-link d-flex align-center mt-5 ga-2 my-3"
          >
            <div
              @click="$router.push({ name: 'MyAccount' })"
              class="link cursor-pointer hoverd-style-line d-flex align-center ga-2"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.825 13L13.425 18.6L12 20L4 12L12 4L13.425 5.4L7.825 11H20V13H7.825Z"
                  fill="#1F666A"
                />
              </svg>
              <span>My Account</span>
            </div>
          </div>
          <div class="mt-5">
            <div class="methods d-flex align-center ga-3">
              <div
                :class="[
                  'method cursor-pointer d-flex flex-column align-center justify-center ga-2',
                  active_method === method.name ? 'active' : '',
                ]"
                v-for="method in methods"
                :key="method.name"
                @click="active_method = method.name"
              >
                <img
                  :src="
                    active_method === method.name
                      ? method.svgActive
                      : method.svgInActive
                  "
                  alt=""
                />
                <span>{{ method.name }}</span>
              </div>
            </div>
            <div class="payment-methods-forms">
              <CreditCardForm v-if="active_method === 'Credit Card'" />
              <PayPalForm v-if="active_method === 'PayPal'" />
              <WalletFrom v-if="active_method === 'Wallet Prepay'" />
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import GlobalNav from "@/components/layout/GlobalNav.vue";
import SideBar from "@/components/layout/SideBar.vue";
import BreadCrumb from "@/components/layout/BreadCrumb.vue";
import CreditCardActive from "@/assets/images/icons/credit-card-active.svg";
import PaypalActive from "@/assets/images/icons/paypal-active.svg";
import WalletActive from "@/assets/images/icons/wallet-active.svg";
import CreditCardInActive from "@/assets/images/icons/credit-card-inactive.svg";
import PaypalInActive from "@/assets/images/icons/paypal-inactive.svg";
import WalletInActive from "@/assets/images/icons/wallet-inactive.svg";
import CreditCardForm from "@/components/myAccount/payemtnForms/CreditCardForm.vue";
import PayPalForm from "@/components/myAccount/payemtnForms/PayPalForm.vue";
import WalletFrom from "@/components/myAccount/payemtnForms/WalletFrom.vue";
import "@/assets/css/my_account/style.css";
export default {
  name: "MyAccountIndex",
  components: {
    GlobalNav,
    SideBar,
    BreadCrumb,
    CreditCardForm,
    PayPalForm,
    WalletFrom,
  },
  data() {
    return {
      CreditCardActive,
      PaypalActive,
      WalletActive,
      CreditCardInActive,
      PaypalInActive,
      WalletInActive,
      active_method: "Credit Card",
      linksData: [
        { name: "Home", link: "/" },
        { name: "My Account", link: "/my-account" },
        { name: "New Payment Method", link: "" },
      ],
      methods: [
        {
          name: "Credit Card",
          svgActive: CreditCardActive,
          svgInActive: CreditCardInActive,
        },
        {
          name: "PayPal",
          svgActive: PaypalActive,
          svgInActive: PaypalInActive,
        },
        {
          name: "Wallet Prepay",
          svgActive: WalletActive,
          svgInActive: WalletInActive,
        },
      ],
    };
  },
  beforeCreate() {},
  methods: {},
};
</script>
