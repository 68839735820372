<template>
  <div class="selected-objective-dialog">
    <div class="selected-objective-header mb-2">
      <v-container fluid>
        <v-row no-gutters>
          <v-col cols="4">
            <p class="roboto-bold">Buying type</p>
            <SelectedType />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="selected-objective-body">
      <v-container fluid class="selected-objective-container">
        <v-row no-gutters>
          <v-col cols="12">
            <p class="roboto-black mb-3">Choose a campaign objective</p>
          </v-col>
          <v-col cols="6" lg="6" md="6" sm="12" class="px-2">
            <form class="my-form">
              <div v-for="item in metaSelectedObjective" :key="item.id">
                <input
                  @change="setSelectedValue(item.value, item)"
                  :id="`radio-1+${item.id}`"
                  :checked="item.checked"
                  :value="item.value"
                  type="radio"
                  name="selectedOpjectiveType"
                />
                <label :for="`radio-1+${item.id}`" class="radio-button-label">
                  <span v-html="item.svgIcon" class="object-icon"> </span>
                  <span style="color: #1c2b33"> {{ item.title }} </span>
                </label>
              </div>
            </form>
          </v-col>
          <v-col cols="6" lg="6" md="6" sm="6" class="object-description px-2">
            <section class="object-description-sections" v-if="selectedObjective">
              <div class="object-description">
                <img
                  v-if="selectedObjective.title === 'Awareness'"
                  src="@/assets/images/meta/2.png"
                  alt=""
                />
                <img
                  v-else-if="selectedObjective.title === 'Traffic'"
                  src="@/assets/images/meta/8.png"
                  alt=""
                />

                <img
                  v-else-if="selectedObjective.title === 'Engagement'"
                  src="@/assets/images/meta/3.png"
                  alt=""
                />

                <img
                  v-else-if="selectedObjective.title === 'Leads'"
                  src="@/assets/images/meta/6.png"
                  alt=""
                />

                <img
                  v-else-if="selectedObjective.title === 'App promotion'"
                  src="@/assets/images/meta/1.png"
                  alt=""
                />

                <img
                  v-else-if="selectedObjective.title === 'Sales'"
                  src="@/assets/images/meta/7.png"
                  alt=""
                />

                <p class="roboto-black title">{{ selectedObjective.title }}</p>
                <span class="description">{{
                  selectedObjective.description
                }}</span>
                <h4 class="">Good for:</h4>
                <p
                  class="good-for"
                  v-for="benefit in selectedObjective.goodFor"
                  :key="benefit"
                >
                  {{ benefit }}
                </p>
              </div>
            </section>

            <section  class="default-description" v-else>
              <img
                src="@/assets/images/meta/d8cb2930-4fb1-4286-9ffb-f97a35da2e1e.png"
                alt=""
              />
              <p class="">
                Your campaign objective is the business goal you hope to achieve
                by running your ads. Hover over each one for more information.
              </p>
            </section>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { meta } from "@/store/meta/meta.js";
import { mapActions, mapState } from "pinia";
import SelectedType from "./SelectedType.vue";
export default {
  components: {
    SelectedType
  },
  name: "SelectedObject",
  data() {
    return {
     
    };
  },
  computed: {
    ...mapState(meta, ["metaSelectedObjective"]),
    selectedObjective() {
      return this.metaSelectedObjective.find((item) => item.checked);
    },
  },
  methods: {
    ...mapActions(meta, ["setSelectedValue"]),
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.roboto-thin {
  font-family: "Roboto", serif;
  font-weight: 100;
  font-style: normal;
}
.roboto-light {
  font-family: "Roboto", serif;
  font-weight: 300;
  font-style: normal;
}
.roboto-regular {
  font-family: "Roboto", serif;
  font-weight: 400;
  font-style: normal;
}
.roboto-medium {
  font-family: "Roboto", serif;
  font-weight: 500;
  font-style: normal;
}
.roboto-bold {
  font-family: "Roboto", serif;
  font-weight: 700;
  font-style: normal;
}
.roboto-black {
  font-family: "Roboto", serif;
  font-weight: 900;
  font-style: normal;
}
.roboto-thin-italic {
  font-family: "Roboto", serif;
  font-weight: 100;
  font-style: italic;
}
.roboto-light-italic {
  font-family: "Roboto", serif;
  font-weight: 300;
  font-style: italic;
}
.roboto-regular-italic {
  font-family: "Roboto", serif;
  font-weight: 400;
  font-style: italic;
}
.roboto-medium-italic {
  font-family: "Roboto", serif;
  font-weight: 500;
  font-style: italic;
}
.roboto-bold-italic {
  font-family: "Roboto", serif;
  font-weight: 700;
  font-style: italic;
}
.roboto-black-italic {
  font-family: "Roboto", serif;
  font-weight: 900;
  font-style: italic;
}
</style>
