<template>
  <div class="ios-dedicated-card setting-card">
    <div class="card-header">
      <h3 class="card-titl">iOS 14 dedicated campaign</h3>
    </div>
    <div class="card-body">
      <div class="checked-label smart-campaign mb-1 d-flex align-center ga-1">
        <label class="toggle-switch">
          <input
            @change="dedicatedCampaignAction"
            v-model="useiOS"
            type="checkbox"
            id="use-iOS"
          />
          <div class="toggle-switch-background">
            <div class="toggle-switch-handle"></div>
            <svg
              class="closemark"
              xmlns="http://www.w3.org/2000/svg"
              height="30px"
              viewBox="0 -960 960 960"
              width="30px"
              fill=""
            >
              <path
                d="M291-253.85 253.85-291l189-189-189-189L291-706.15l189 189 189-189L706.15-669l-189 189 189 189L669-253.85l-189-189-189 189Z"
              />
            </svg>
            <svg
              class="checkmark"
              xmlns="http://www.w3.org/2000/svg"
              height="30px"
              viewBox="0 -960 960 960"
              width="30px"
              fill=""
            >
              <path
                d="M389-259.35 187.35-460l58.89-59.89L389-377.13l324.76-323.76L772.65-642 389-259.35Z"
              />
            </svg>
          </div>
        </label>
        <label for="use-iOS" class="label-for-input">
          Use iOS 14 dedicated campaign
        </label>
      </div>
      <div class="select-app" v-if="useiOS">
        <label for="">App</label>
        <v-select
          hide-details
          no-data-text=""
          :items="items"
          density="compact"
          label=""
          variant="outlined"
          placeholder="Select app"
        >
          <template #append-item >
            <div class="empty-state pa-4 text-center">
              <div class="icone-create" v-if="!items.length">
                <svg
                  width="38"
                  height="37"
                  viewBox="0 0 250 249"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class=""
                  elementtiming="element-timing"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M19.514 26.898C19.514 12.3 31.348.467 45.945.467h158.123c14.597 0 26.431 11.834 26.431 26.431v120.873h-17.621V26.898a8.81 8.81 0 00-8.81-8.81H45.945a8.81 8.81 0 00-8.81 8.81V145.88H19.514V26.898z"
                    fill="#D6D6D6"
                    elementtiming="element-timing"
                  ></path>
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M63.325 61.116a8.81 8.81 0 018.81-8.81h105.727a8.81 8.81 0 010 17.62H72.136a8.81 8.81 0 01-8.81-8.81zM63.325 97.976a8.81 8.81 0 018.81-8.81h105.727a8.81 8.81 0 010 17.621H72.136a8.81 8.81 0 01-8.81-8.811zM89.757 200.837a8.81 8.81 0 018.81-8.811h52.863a8.811 8.811 0 010 17.621H98.567a8.81 8.81 0 01-8.81-8.81z"
                    fill="#D6D6D6"
                    elementtiming="element-timing"
                  ></path>
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0 149.609c0-9.732 7.89-17.621 17.621-17.621h55.76c10.596 0 19.535 7.097 22.32 16.798h58.597c2.786-9.701 11.725-16.798 22.321-16.798h55.76c9.732 0 17.621 7.889 17.621 17.621V230.8c0 9.732-7.889 17.621-17.621 17.621H17.621C7.889 248.421 0 240.532 0 230.8v-81.191zm73.38 0c3.093 0 5.6 2.506 5.6 5.599 0 6.185 5.014 11.199 11.2 11.199h69.641c6.185 0 11.199-5.014 11.199-11.199a5.599 5.599 0 015.599-5.599h55.76V230.8H17.621v-81.191h55.76z"
                    fill="#D6D6D6"
                    elementtiming="element-timing"
                  ></path>
                </svg>
                <p>You haven't added any apps. <button @click="createApp">Create</button></p>
              </div>
              <div class="actions-btn">
                <button @click="createApp">
                  <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#009995"><path d="M454-454H260v-52h194v-194h52v194h194v52H506v194h-52v-194Z"/></svg>
                  <span>Create</span>
                </button>
                <button>
                  <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#009995"><path d="M480-202q-115.77 0-198.38-82.31Q199-366.62 202-486v-24.23l-67 67-37.15-37.15L228-610.54l130.15 130.16L321-443.23l-67-67V-486q-2 98.23 64.88 165.12Q385.77-254 480-254q20.85 0 40.65-3.42 19.81-3.43 38.43-10.27l38 38q-25.77 12.15-55.23 19.92Q512.38-202 480-202Zm252-147.46L601.85-479.62 639-516.77l67 67V-474q2-98.23-64.88-165.12Q574.23-706 480-706q-19.85 0-40.15 3.42-20.31 3.43-38.93 10.27l-38-38q25.77-12.15 55.73-19.92Q448.62-758 480-758q116.77 0 198.88 82.31Q761-593.38 758-474v24.23l67-67 37.15 37.15L732-349.46Z"/></svg>
                  <span>Refresh</span>
                </button>
              </div>
            </div>
          </template>
        </v-select>
      </div>
      <div class="ios-description" v-else>
        <span>
          <svg
            width="18"
            height="18"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            elementtiming="element-timing"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.5373 11.5372C10.3421 12.7325 8.72453 13.4166 7.00007 13.4166C5.27561 13.4166 3.65802 12.7325 2.4628 11.5372C1.26759 10.342 0.583405 8.72444 0.583405 6.99998C0.583405 5.27552 1.26759 3.65792 2.4628 2.46271C3.65802 1.2675 5.27561 0.583313 7.00007 0.583313C8.72453 0.583313 10.3421 1.2675 11.5373 2.46271C12.7326 3.65792 13.4167 5.27552 13.4167 6.99998C13.4167 8.72444 12.7326 10.342 11.5373 11.5372Z"
              fill="#9EE1DD"
              elementtiming="element-timing"
              style="
                fill: color(display-p3 0.6196 0.8824 0.8667);
                fill-opacity: 1;
              "
            ></path>
            <path
              d="M6.5627 9.33333H6.1252C5.80304 9.33333 5.54187 9.5945 5.54187 9.91667C5.54187 10.2388 5.80304 10.5 6.1252 10.5H8.16687C8.48904 10.5 8.7502 10.2388 8.7502 9.91667C8.7502 9.5945 8.48904 9.33333 8.16687 9.33333H7.72937V5.83333C7.72937 5.51117 7.4682 5.25 7.14604 5.25H6.5627C6.24054 5.25 5.97937 5.51117 5.97937 5.83333C5.97937 6.1555 6.24054 6.41667 6.5627 6.41667V9.33333Z"
              fill="#002C2B"
              elementtiming="element-timing"
              style="fill: color(display-p3 0 0.1725 0.1686); fill-opacity: 1"
            ></path>
            <path
              d="M7.00013 3.20834C7.40284 3.20834 7.7293 3.5348 7.7293 3.93751C7.7293 4.34022 7.40284 4.66668 7.00013 4.66668C6.59742 4.66668 6.27097 4.34022 6.27097 3.93751C6.27097 3.5348 6.59742 3.20834 7.00013 3.20834Z"
              fill="#002C2B"
              elementtiming="element-timing"
              style="fill: color(display-p3 0 0.1725 0.1686); fill-opacity: 1"
            ></path>
          </svg>
        </span>
        <p>
          Devices using iOS 14.5 or later iOS versions make up a large part of
          traffic. Turn on the toggle to ensure you're able to reach these
          users. If the toggle is off, your ads will not reach devices using iOS
          14.5 or later iOS versions
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// import Treeselect from "vue3-treeselect";

export default {
  data() {
    return {
      useiOS: localStorage.getItem("campaign_type") === "true",
      items: [],
      menuOpen: false,
      options: [],
    };
  },
  mounted() {
    if (localStorage.getItem("campaign_type") === "true") {
      this.useiOS = localStorage.getItem("campaign_type");
    } else {
      localStorage.setItem("campaign_type", this.useiOS);
    }
  },
  methods: {
    dedicatedCampaignAction() {
      localStorage.setItem("campaign_type", this.useiOS);
    },
    handleAddNew() {
      // Handle adding new app
      console.log("Adding new app...");
    },
    createApp() {
      this.$router.push({ name: "CreateApp" });
    }
  },
};
</script>

<style>
.empty-state {
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 40px;
  align-items: center;
  height: 100%;
  width: 100%;
}
.empty-state .icone-create p {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #a9abac;
}
.empty-state .icone-create p button {
  background-color: transparent;
  color: #017976;
  padding: 1px 10px;
  border-radius: 5px;
  margin: 0;
  font-size: 12px;
}
.empty-state .actions-btn{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.empty-state .actions-btn button{
  background-color: transparent;
  color: #017976;
  padding: 1px 10px;
  border-radius: 5px;
  margin: 0;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
</style>