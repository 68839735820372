<template>
  <v-select
    v-model="select"
    :items="items"
    density="compact"
    variant="outlined"
    item-value="id"
    item-title="title"
    return-object
    hide-details
  >
    <template v-slot:selection="{ item }">
      <v-chip size="small">
        {{ item.raw.title }}
      </v-chip>
    </template>
    <template v-slot:item="{ item, props: itemProps }">
      <v-list-item @click="selectItem(item)">
        <v-list-item-title v-bind="itemProps">
          <div class="my-form">
            <div>
              <input
                :checked="
                  select && select.raw && select.raw.id === item.raw?.id
                "
                :id="item.raw.id"
                type="radio"
                name="option"
              />
              <label :for="item.raw.id">{{ item.raw.title }}</label>
            </div>
          </div>
        </v-list-item-title>
      </v-list-item>
    </template>
  </v-select>
</template>

<script>
export default {
  data() {
    return {
      select: {
        title: "Auction",
        id: 1,
        value: "AUCTION",
      },
      items: [
        {
          title: "Auction",
          id: 1,
          value: "AUCTION",
        },
        {
          title: "Reservation",
          id: 2,
          value: "RESERVED",
        },
      ],
    };
  },
  methods: {
    selectItem(item) {
      this.select = item;
      localStorage.setItem("buying_type_meta", item.raw.value);
    },
  },
};
</script>
