<template>
  <div class="placement-card targeting-card">
    <div class="card-header">
      <p class="card-title">Targeting</p>
    </div>
    <div class="card-body">
      <div class="saved-audience">
        <label for="" class="audience-label"
          >Saved audience <span>(optional)</span>
        </label>
        <treeselect
          :options="options"
          :multiple="true"
          :flatten-search-results="true"
          placeholder="Select saved audience"
        />
      </div>
      <div class="demographics">
        <v-expansion-panels class="my-4">
          <v-expansion-panel title="Demographics">
            <v-expansion-panel-text>
              <div class="location mb-10">
                <p class="card-title">Location</p>

                <CountrySearch />
              </div>
              <div class="age mb-10">
                <div class="age-form my-3">
                  <label for="age" class="mb-1 age-label d-block"> Age </label>
                  <p class="label-description">
                    In some regions, ad delivery may be subject to additional
                    age targeting restrictions.
                  </p>
                  <div class="mydict">
                    <div>
                      <label>
                        <input
                          type="checkbox"
                          value="All"
                          :checked="isAllSelected"
                          @change="handleAllChecked"
                        />
                        <span>All</span>
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          value="AGE_13_17"
                          v-model="selectedValues"
                          @change="handleOptionChecked"
                        />
                        <span>13-17</span>
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          value="AGE_18_24"
                          v-model="selectedValues"
                          @change="handleOptionChecked"
                        />
                        <span>18-24</span>
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          value="AGE_25_34"
                          v-model="selectedValues"
                          @change="handleOptionChecked"
                        />
                        <span>25-34</span>
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          value="AGE_35_44"
                          v-model="selectedValues"
                          @change="handleOptionChecked"
                        />
                        <span>35-44</span>
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          value="AGE_45_54"
                          v-model="selectedValues"
                          @change="handleOptionChecked"
                        />
                        <span>45-54</span>
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          value="AGE_55_100"
                          v-model="selectedValues"
                          @change="handleOptionChecked"
                        />
                        <span>+55</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="age mb-10">
                <div class="age-form my-3">
                  <label for="gender" class="mb-1 age-label d-block">
                    Gender
                  </label>
                  <div class="mydict">
                    <div>
                      <label>
                        <input
                          type="radio"
                          value="GENDER_UNLIMITED"
                          v-model="gender"
                          @change="updateGender"
                          name="gender"
                        />
                        <span>All</span>
                      </label>
                      <label>
                        <input
                          type="radio"
                          v-model="gender"
                          @change="updateGender"
                          value="GENDER_MALE"
                          name="gender"
                        />
                        <span>Male</span>
                      </label>
                      <label>
                        <input
                          type="radio"
                          v-model="gender"
                          @change="updateGender"
                          value="GENDER_FEMALE"
                          name="gender"
                        />
                        <span>female</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="language mb-10">
                <label for="">Languages</label>
                <div>
                  <v-select
                    density="compact"
                    multiple
                    v-model="selectedLanguages"
                    item-title="name"
                    item-value="code"
                    :items="languagesFromApi"
                    variant="outlined"
                    clearable
                    @update:modelValue="updateLanguages"
                  ></v-select>
                </div>
              </div>
              <div class="age spending-power">
                <label for="">Spending power</label>
                <div class="mydict">
                  <div>
                    <label>
                      <input
                        type="radio"
                        value="ALL"
                        v-model="spendPower"
                        @change="updateSpendPower"
                        name="spendPower"
                      />
                      <span>All</span>
                    </label>
                    <label>
                      <input
                        type="radio"
                        v-model="spendPower"
                        @change="updateSpendPower"
                        value="HIGH"
                        name="spendPower"
                      />
                      <span> High spending power </span>
                    </label>
                  </div>
                </div>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <div class="demographics audience">
        <v-expansion-panels class="my-4">
          <v-expansion-panel title="Audience">
            <v-expansion-panel-text>
              <div class="language mb-10">
                <label for="">Include</label>
                <div>
                  <treeselect
                    :options="languages"
                    :value="value"
                    :multiple="multiple"
                  >
                  </treeselect>
                </div>
              </div>
              <div class="language mb-10">
                <label for="">Exclude</label>
                <div>
                  <treeselect
                    :options="languages"
                    :value="value"
                    :multiple="multiple"
                  >
                  </treeselect>
                </div>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <div class="demographics interests-behaviors">
        <v-expansion-panels class="my-4">
          <v-expansion-panel title="Interests & behaviors">
            <v-expansion-panel-text>
              <p class="interests-behaviors">
                Target an audience based on their long-term interests and
                interactions with content on TikTok.
              </p>
              <SelectedPreview
                v-if="selectedItemsList.length"
                :selected-items="selectedItemsList"
                @remove="toggleSelect"
                @clear="clearSelection"
              />
              <SearchInput v-model="searchQuery" @toggleSearch="toggleSearch" />

              <div class="tree-dropdown" v-if="treeNodeSelect">
                <div class="tabs-container">
                  <div class="tabs">
                    <div
                      v-for="tab in tabs"
                      :key="tab.id"
                      :class="['tab', { active: activeTab === tab.id }]"
                      @click="activeTab = tab.id"
                    >
                      {{ tab.name }}
                    </div>
                  </div>
                </div>
                <div class="tree-container">
                  <div class="scrollable">
                    <div v-if="filteredItems.length" class="tree">
                      <TreeNode
                        v-for="item in filteredItems"
                        :key="item.id"
                        :item="item"
                        :selected="selectedItems"
                        @toggle-select="toggleSelect"
                      />
                    </div>
                    <div v-else class="no-results">No results found</div>
                  </div>
                </div>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <div class="demographics devices interests-behaviors">
        <v-expansion-panels class="my-4">
          <v-expansion-panel title="Device">
            <v-expansion-panel-text>
              <div class="age mt-5 mb-10">
                <label for="" class="operating-label">Operating system </label>
                <div class="age-form my-3">
                  <div class="mydict">
                    <div>
                      <label>
                        <input
                          type="radio"
                          value="All"
                          name="operatingSystem"
                          v-model="operatingSystem"
                          @change="operateAllChecked('All')"
                        />
                        <span>All</span>
                      </label>
                      <label>
                        <input
                          v-model="operatingSystem"
                          type="radio"
                          value="ANDROID"
                          name="operatingSystem"
                          @change="operateAllChecked('ANDROID')"
                        />
                        <span>Androied</span>
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="IOS"
                          name="operatingSystem"
                          v-model="operatingSystem"
                          @change="operateAllChecked('IOS')"
                        />
                        <span>iOS</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="os-versions mb-8">
                <label class="d-block operating-label" for=""
                  >OS versions
                </label>
                <v-select
                  :items="all_os_versions"
                  return-object
                  item-title="name"
                  v-model="selectedOsVersion"
                  variant="outlined"
                  density="compact"
                  @update:modelValue="updateOsVersion"
                ></v-select>
              </div>
              <div class="device-model os-versions mb-8">
                <label class="d-block operating-label" for=""
                  >Device model
                </label>
                <v-select
                  :items="os_versions"
                  item-title="label"
                  v-model="device_model_seleceted"
                  variant="outlined"
                  density="compact"
                  return-object
                  @update:modelValue="updateOsVersion"
                ></v-select>
                <!-- <treeselect
                  :multiple="false"
                  :options="os_versions"
                  placeholder="Select your favourite(s)..."
                  v-model="value"
                /> -->
              </div>
              <div class="age mb-8 connection-type">
                <div class="age-form my-3">
                  <label for="age" class="mb-1 age-label d-block">
                    Connection type
                  </label>
                  <div class="mydict">
                    <div>
                      <label>
                        <input
                          type="checkbox"
                          value="All"
                          :checked="conectionTypeSelected"
                          @change="handleAllCheckedConnection"
                        />
                        <span>All</span>
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          value="WIFI"
                          v-model="selectedConectionType"
                          @change="selecteConectionType"
                        />
                        <span>Wi-Fi</span>
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          value="2G"
                          v-model="selectedConectionType"
                          @change="selecteConectionType"
                        />
                        <span>2G</span>
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          value="3G"
                          v-model="selectedConectionType"
                          @change="selecteConectionType"
                        />
                        <span>3G</span>
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          value="4G"
                          v-model="selectedConectionType"
                          @change="selecteConectionType"
                        />
                        <span>4G</span>
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          value="5G"
                          v-model="selectedConectionType"
                          @change="selecteConectionType"
                        />
                        <span>5G</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="os-versions carriers mb-8">
                <label for="" class="d-block operating-label">Carriers</label>
                <treeselect
                  :multiple="true"
                  :options="carriers"
                  clearable="false"
                  placeholder="All"
                  v-model="carrier_selected.label"
                />
              </div>
              <div class="internet-service os-versions carriers mb-8">
                <label class="d-block operating-label" for=""
                  >Internet service provider
                </label>
                <treeselect
                  :multiple="true"
                  :options="internetServiceProvider"
                  placeholder=""
                  v-model="internet_service_provider_selected"
                />
              </div>
              <div class="device-price">
                <label class="d-block device-price-label" for=""
                  >Device price</label
                >
                <div class="radio-buttons-container">
                  <div class="radio-button">
                    <input
                      @change="updatePrice()"
                      name="radio-group"
                      id="radio2"
                      class="radio-button__input"
                      type="radio"
                      value="any"
                      v-model="price"
                    />
                    <label for="radio2" class="radio-button__label">
                      <span class="radio-button__custom"></span>
                      Any price
                    </label>
                  </div>
                  <div class="radio-button">
                    <input
                      name="radio-group"
                      id="radio1"
                      @change="updatePrice()"
                      class="radio-button__input"
                      type="radio"
                      value="specific"
                      v-model="price"
                    />
                    <label for="radio1" class="radio-button__label">
                      <span class="radio-button__custom"></span>
                      Specific range
                    </label>
                  </div>
                </div>
                <div class="price-range" v-if="price === 'specific'">
                  <div class="d-1-0-1 ga-2 w-75 d-flex">
                    <v-select
                      class="v-select-style"
                      v-model="age_restrictions_required_start_age"
                      :items="spacefic_price_range_start"
                      variant="solo"
                      @change="updatePriceRange"
                    ></v-select>

                    <span class="d-flex align-center justify-center">-</span>
                    <v-select
                      @change="updatePriceRange"
                      variant="solo"
                      class="v-select-style"
                      v-model="age_restrictions_required_start_end_age"
                      :items="spacefic_price_range_end"
                    ></v-select>
                    <span class="d-flex align-center justify-center">USD</span>
                  </div>
                  <div class="price-range-slider"></div>
                </div>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
  </div>
</template>

<script>
import Treeselect from "vue3-treeselect";
import TreeNode from "@/components/tiktok/SecoundStep/TreeNode.vue";
import SearchInput from "./SearchInput.vue";
import SelectedPreview from "./SelectedPreview.vue";
import apiClient from "@/core/buildRequest";
import CountrySearch from "./CountrySearch.vue";

export default {
  components: {
    Treeselect,
    TreeNode,
    SearchInput,
    SelectedPreview,
    CountrySearch,
  },
  data() {
    return {
      internetServiceProvider: [
        {
          id: "eg_etisalat_misr",
          label: "EG :Etisalat Misr",
        },
        {
          id: "eg_telecom_egypt",
          label: "EG :Telecom Egypt",
        },
        {
          id: "eg_vodafone_egypt",
          label: "EG :Vodafone Egypt",
        },
        {
          id: "eg_other",
          label: "EG :other ",
        },
      ],
      internet_service_provider_selected: [],
      carriers: [
        {
          id: "eg_orange",
          label: "EG :Orange",
        },
        {
          id: "eg_vodafone",
          label: "EG :Vodafone",
        },
        {
          id: "eg_etisalat",
          label: "EG :Etisalat",
        },
      ],
      carrier_selected: [
        {
          id: "eg_orange",
          label: "EG :Orange",
        },
      ],
      options: [
        {
          id: "m",
          label: "Audience 1",
        },
        {
          id: "kfc",
          label: "Audience 2",
        },
        {
          id: "bk",
          label: "Audience 3",
        },
      ],
      os_versions: [
        {
          id: "all",
          label: "All",
        },
        {
          id: "Android 14.0 and above",
          label: "Android 14.0 and above",
        },
        {
          id: "Android 13.0 and above",
          label: "Android 13.0 and above",
        },
        {
          id: "Android 12.0 and above",
          label: "Android 12.0 and above",
        },
      ],
      countries: [
        {
          id: "africa",
          label: "Africa",
          children: [
            { id: "eg", label: "Egypt" },
            { id: "ng", label: "Nigeria" },
            { id: "za", label: "South Africa" },
            { id: "ke", label: "Kenya" },
          ],
        },
        {
          id: "asia",
          label: "Asia",
          children: [
            { id: "cn", label: "China" },
            { id: "jp", label: "Japan" },
            { id: "in", label: "India" },
            { id: "kr", label: "South Korea" },
          ],
        },
        {
          id: "europe",
          label: "Europe",
          children: [
            { id: "fr", label: "France" },
            { id: "de", label: "Germany" },
            { id: "it", label: "Italy" },
            { id: "uk", label: "United Kingdom" },
          ],
        },
        {
          id: "north-america",
          label: "North America",
          children: [
            { id: "us", label: "United States" },
            { id: "ca", label: "Canada" },
            { id: "mx", label: "Mexico" },
          ],
        },
        {
          id: "south-america",
          label: "South America",
          children: [
            { id: "br", label: "Brazil" },
            { id: "ar", label: "Argentina" },
            { id: "cl", label: "Chile" },
          ],
        },
        {
          id: "oceania",
          label: "Oceania",
          children: [
            { id: "au", label: "Australia" },
            { id: "nz", label: "New Zealand" },
            { id: "fj", label: "Fiji" },
          ],
        },
      ],
      selectedValues: ["All"],
      selectedConectionType: ["All"],
      gender: "GENDER_UNLIMITED",
      multiple: true,
      device_model_seleceted: [
        {
          id: "all",
          label: "All",
        },
      ],
      languages: [1, 2, 3].map((i) => ({
        id: i,
        label: `Label ${i}`,
        customLabel: `Custom Label ${i}`,
      })),
      spendPower: "ALL",
      activeTab: "interests",
      selectedItems: new Set(),
      searchQuery: "",
      tabs: [
        { id: "interests", name: "Interests" },
        { id: "video", name: "Video interactions" },
        { id: "creator", name: "Creator interactions" },
        { id: "hashtag", name: "Hashtag interactions" },
      ],
      interests: [
        {
          id: "1",
          name: "Baby, Kids & Maternity",
          children: [
            { id: "1-1", name: "Baby Care" },
            { id: "1-2", name: "Kids Clothing" },
            { id: "1-3", name: "Maternity Wear" },
          ],
        },
        {
          id: "2",
          name: "Beauty & Personal Care",
          children: [
            { id: "2-1", name: "Skincare" },
            { id: "2-2", name: "Makeup" },
            { id: "2-3", name: "Hair Care" },
          ],
        },
        {
          id: "3",
          name: "Household Products",
          children: [
            { id: "3-1", name: "Cleaning" },
            { id: "3-2", name: "Organization" },
          ],
        },
        // Keep other items, adding sample children to demonstrate structure
        { id: "4", name: "Pets", children: [] },
        { id: "5", name: "Appliances", children: [] },
        { id: "6", name: "Games", children: [] },
        { id: "7", name: "Education", children: [] },
        { id: "8", name: "Travel", children: [] },
        { id: "9", name: "Business Services", children: [] },
        { id: "10", name: "E-Commerce (Non-app)", children: [] },
        { id: "11", name: "Food & Beverage", children: [] },
        { id: "12", name: "Financial Services", children: [] },
        { id: "13", name: "Vehicles & Transportation", children: [] },
        { id: "14", name: "Tech & Electronics", children: [] },
        { id: "15", name: "Life Services", children: [] },
        { id: "16", name: "Apps", children: [] },
        { id: "17", name: "Apparel & Accessories", children: [] },
        { id: "18", name: "Home Improvement", children: [] },
        { id: "19", name: "Sports & Outdoors", children: [] },
        { id: "20", name: "News & Entertainment", children: [] },
      ],
      treeNodeSelect: false,
      operatingSystem: "All",
      price: [],
      age_restrictions_required_start_age: "",
      spacefic_price_range_start: [
        "0",
        "50",
        "100",
        "150",
        "200",
        "250",
        "300",
        "350",
        "400",
        "450",
        "500",
        "550",
        "600",
        "650",
        "700",
        "750",
        "800",
        "850",
        "900",
        "950",
        "1000",
      ],
      age_restrictions_required_start_end_age: "",
      spacefic_price_range_end: [
        "50",
        "100",
        "150",
        "200",
        "250",
        "300",
        "350",
        "400",
        "450",
        "500",
        "550",
        "600",
        "650",
        "700",
        "750",
        "800",
        "850",
        "900",
        "950",
        "1000",
        "1000+",
      ],
      languagesFromApi: [],
      selectedLanguages: [
        {
          code: "ar",
          name: "Arabic",
        },
      ],
      advertise_id: "",
      android_os_versions: [],
      ios_os_versions: [],
      all_os_versions: [],
      selectedOsVersion: { id: "NONE", name: "All" },
    };
  },
  mounted() {
    this.advertise_id = localStorage.getItem("selected_advertise_id");
    const storedGender = localStorage.getItem("gender");
    if (storedGender) {
      this.gender = storedGender;
    }
    if (localStorage.getItem("operatingSelect")) {
      this.operatingSystem = localStorage.getItem("operatingSelect");
    }
    if (localStorage.getItem("price")) {
      this.price = localStorage.getItem("price");
    }
    localStorage.setItem("spendPower", this.spendPower);

    localStorage.setItem(
      "connectionType",
      JSON.stringify(this.selectedConectionType)
    );
    apiClient
      .get(`/campaign-ads/tiktok/get/language/code/${this.advertise_id}`)
      .then((response) => {
        this.languagesFromApi = response.data.data.data.languages;
        console.log(response.data.data.data.languages);
      });

    if (localStorage.getItem("selectedValues")) {
      this.selectedValues = JSON.parse(localStorage.getItem("selectedValues"));
    } else {
      this.selectedValues = ["All"];
    }
    if (localStorage.getItem("gender")) {
      this.gender = localStorage.getItem("gender");
    } else {
      localStorage.setItem("gender", this.gender);
    }
    if (localStorage.getItem("languages")) {
      this.selectedLanguages = JSON.parse(localStorage.getItem("languages"));
    } else {
      localStorage.setItem("languages", JSON.stringify(this.selectedLanguages));
    }
    Promise.all([
      apiClient.get(
        `/campaign-ads/tiktok/get/os/versions/${this.advertise_id}/ANDROID`
      ),
      apiClient.get(
        `/campaign-ads/tiktok/get/os/versions/${this.advertise_id}/IOS`
      ),
    ])
      .then(([androidResponse, iosResponse]) => {
        // Process responses
        this.android_os_versions =
          androidResponse?.data?.data?.data?.os_versions || [];
        this.ios_os_versions = iosResponse?.data?.data?.data?.os_versions || [];
        // Combine all OS versions
        this.all_os_versions = this.android_os_versions.concat(
          this.ios_os_versions
        );
        this.all_os_versions.unshift({
          id: "NONE",
          name: "All",
        });
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
    localStorage.setItem(
      "connectionType",
      JSON.stringify(this.selectedConectionType)
    );
  },
  computed: {
    isAllSelected() {
      return this.selectedValues.includes("All");
    },
    conectionTypeSelected() {
      return this.selectedConectionType.includes("All");
    },
    currentItems() {
      return this.interests;
    },
    filteredItems() {
      if (!this.searchQuery) {
        return this.currentItems;
      }

      const query = this.searchQuery.toLowerCase();
      return this.currentItems.filter((item) =>
        item.name.toLowerCase().includes(query)
      );
    },
    selectedItemsList() {
      const findItem = (items, id) => {
        for (const item of items) {
          if (item.id === id) return item;
          if (item.children) {
            const found = findItem(item.children, id);
            if (found) return found;
          }
        }
        return null;
      };

      return Array.from(this.selectedItems)
        .map((id) => findItem(this.interests, id))
        .filter((item) => item !== null);
    },
  },
  methods: {
    handleAllChecked(event) {
      if (event.target.checked) {
        // If "All" is checked, clear other selections and add "All"
        this.selectedValues = ["All"];
        localStorage.setItem(
          "selectedValues",
          JSON.stringify(this.selectedValues)
        );
      } else {
        // If "All" is unchecked, clear the array
        this.selectedValues = [];
        localStorage.setItem(
          "selectedValues",
          JSON.stringify(this.selectedValues)
        );
      }
    },
    handleOptionChecked() {
      if (this.isAllSelected) {
        // Remove "All" if any other option is selected
        this.selectedValues = this.selectedValues.filter(
          (val) => val !== "All"
        );
      }
      localStorage.setItem(
        "selectedValues",
        JSON.stringify(this.selectedValues)
      );
    },
    selecteConectionType() {
      if (this.selectedConectionType) {
        this.selectedConectionType = this.selectedConectionType.filter(
          (val) => val !== "All"
        );
      }
      localStorage.setItem(
        "connectionType",
        JSON.stringify(this.selectedConectionType)
      );
    },
    handleAllCheckedConnection(event) {
      if (event.target.checked) {
        // If "All" is checked, clear other selections and add "All"
        this.selectedConectionType = ["All"];
        localStorage.setItem(
          "connectionType",
          JSON.stringify(this.selectedConectionType)
        );
      } else {
        // If "All" is unchecked, clear the array
        this.selectedConectionType = [];
        localStorage.setItem(
          "connectionType",
          JSON.stringify(this.selectedConectionType)
        );
      }
    },
    updateGender() {
      localStorage.setItem("gender", this.gender);
    },
    updateSpendPower() {
      localStorage.setItem("spendPower", this.spendPower);
    },
    toggleSelect(itemId) {
      if (this.selectedItems.has(itemId)) {
        this.selectedItems.delete(itemId);
      } else {
        this.selectedItems.add(itemId);
      }
    },
    clearSelection() {
      this.selectedItems.clear();
    },
    toggleSearch() {
      this.treeNodeSelect = !this.treeNodeSelect;
    },
    operateAllChecked(type) {
  localStorage.setItem("operatingSelect", this.operatingSystem);

  let baseArray = [];
  if (type === "All") {
    baseArray = this.android_os_versions.concat(this.ios_os_versions);
  } else if (type === "ANDROID") {
    baseArray = [...this.android_os_versions];
  } else if (type === "IOS") {
    baseArray = [...this.ios_os_versions];
  }

  // Add the "All" option at the beginning
  this.all_os_versions = [
    { id: "NONE", name: "All" },
    ...baseArray,
  ];
},
    updatePrice() {
      localStorage.setItem("price", this.price);
    },
    updatePriceRange() {
      const startAge = Number(this.age_restrictions_required_start_age);
      const endAge = Number(this.age_restrictions_required_start_end_age);

      // Ensure ranges are valid
      if (startAge > endAge) {
        this.age_restrictions_required_start_end_age = startAge;
      }

      // Update available options for both selectors
      this.spacefic_price_range_start = this.generateRange(18, endAge);
      this.spacefic_price_range_end = this.generateRange(startAge, 100);
    },
    generateRange(min, max) {
      return Array.from({ length: max - min + 1 }, (_, i) => i + min);
    },
    updateLanguages() {
      console.log(this.selectedLanguages);
      localStorage.setItem("languages", JSON.stringify(this.selectedLanguages));
    },
    updateOsVersion() {
      localStorage.setItem("osVersion", this.selectedOsVersion.version);
    },
  },
  watch: {
    age_restrictions_required_start_age(newValue) {
      if (
        Number(newValue) > Number(this.age_restrictions_required_start_end_age)
      ) {
        this.age_restrictions_required_start_end_age = newValue;
      }
      if (this.age_restrictions_required) {
        this.show_age_restrictions_required_validate = Number(newValue) < 18;
      }
      localStorage.setItem("Specific_price_range", newValue);
    },
    age_restrictions_required_start_end_age(newValue) {
      if (Number(newValue) < Number(this.age_restrictions_required_start_age)) {
        this.age_restrictions_required_start_age = newValue;
      }
    },
  },
};
</script>

<style scoped>
.tree-dropdown {
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  background: white;
  display: flex;
  justify-content: space-between;
  width: 51%;
}
.tabs-container {
  border-bottom: 1px solid #e4e7ed;
}
.tabs {
  display: flex;
  padding: 0 0;
  flex-direction: column;
}
.tab {
  padding: 12px 16px;
  cursor: pointer;
  color: #606266;
  font-size: 14px;
}
.tab.active {
  color: #017976;
}
.tree-container {
  height: 300px;
  overflow: hidden;
}
.scrollable {
  height: 100%;
  overflow-y: auto;
  padding: 8px 0;
}
.tree {
  padding: 0 16px;
}
</style>
