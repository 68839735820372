<template>
  <div class="placement-card Bidding-optimization">
    <div class="card-header">
      <p class="card-title">Bidding & optimization</p>
    </div>
    <div class="card-body">
      <div class="optimization-goal mb-5">
        <label for="" class="optimization-label">Optimization goal </label>
        <p>{{ optimization_goal }}</p>
      </div>
      <div class="frequency-cap mb-5">
        <label for="" class="frequency-label">Frequency cap</label>
        <div class="radio-buttons-container">
          <div class="radio-button">
            <input
              type="radio"
              id="moreThanTimes"
              value="Show ads no more than 3 times every 0 days"
              v-model="frequency_cap"
              class="radio-button__input"
              name="frequency_cap"
            />
            <label for="moreThanTimes" class="radio-button__label">
              <span class="radio-button__custom"></span>
              Show ads no more than 3 times every 0 days
            </label>
          </div>
          <div class="radio-button">
            <input
              type="radio"
              id="moreThanOnce"
              value="Show ads no more than once a day"
              v-model="frequency_cap"
              class="radio-button__input"
              name="frequency_cap"
            />
            <label for="moreThanOnce" class="radio-button__label">
              <span class="radio-button__custom"></span>
              Show ads no more than once a day
            </label>
          </div>
          <div class="radio-button">
            <input
              type="radio"
              id="customFrequency"
              value="Custom frequency cap"
              v-model="frequency_cap"
              class="radio-button__input"
              name="frequency_cap"
            />
            <label for="customFrequency" class="radio-button__label">
              <span class="radio-button__custom"></span>
              Custom frequency cap
            </label>
          </div>
        </div>
        <div
          class="frequancy-cup-create"
          v-if="frequency_cap === 'Custom frequency cap'"
        >
          <label for="" class="mb-2">Frequency cap</label>
          <div class="frequancy-inputs">
            <input type="text" name="" id="" />
            <span>impressions /</span>
            <input type="text" name="" id="" />
            <span>day</span>
          </div>
        </div>
      </div>
      <div class="pid-controll mb-5">
        <label for="" class="pid-label d-block mb-2">
          Bid control <span>(optional)</span>
        </label>
        <div class="pid-number">
          <input type="number" name="" id="" />
          <label for="">EGP/Thousand Impressions </label>
        </div>
        <p class="description">
          We suggest a bid of 12.56EGP to ensure your cost stays at or below
          this amount. For maximum delivery bidding, remove this amount so
          bidding can be done at any price.
        </p>
      </div>
      <div class="advanced-setting">
        <button @click="toggleAdvanced" class="mb-5">
          <p v-if="!advanced_setting">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="20px"
              viewBox="0 -960 960 960"
              width="20px"
              fill="#6d6e70"
            >
              <path
                d="M480-192 240-432l51-51 189 189 189-189 51 51-240 240Zm0-285L240-717l51-51 189 189 189-189 51 51-240 240Z"
              />
            </svg>
            <span> Advanced settings </span>
          </p>
          <p v-else>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="20px"
              viewBox="0 -960 960 960"
              width="20px"
              fill="#6d6e70"
            >
              <path
                d="m291-192-51-51 240-240 240 240-51 51-189-189-189 189Zm0-285-51-51 240-240 240 240-51 51-189-189-189 189Z"
              />
            </svg>
            <span> Hide Advanced settings </span>
          </p>
        </button>
        <div v-if="advanced_setting" class="billing-delivery">
          <div class="mb-8">
            <label class="mb-2 d-block" for="">Billing event</label>
            <p>Impression (CPM)</p>
          </div>
          <div class="mb-8">
            <label class="mb-2 d-block" for="">Delivery type</label>
            <p>Standard</p>
            <span
              >Your budget will be used as evenly as possible depending on
              market demand and peaktime rates. This delivery type is suitable
              for advertisers who prefer steady spending.
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      optimization_goal: "Reach",
      frequency_cap: "Show ads no more than 3 times every 0 days",
      advanced_setting: false,
    };
  },
  methods: {
    toggleAdvanced() {
      this.advanced_setting = !this.advanced_setting;
    },
  },
};
</script>
