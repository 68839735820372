<template>
  <v-col :cols="12" :lg="6" class="d-flex ga-5 align-center">
    <div class="d-flex ga-1 align-center input-data-text">
      <TagIcon />
      <label class="calender-input-label text-capitalize" for="tags"
        >Tags</label
      >
    </div>
    <div class="tags-input">
      <inputTags
        style="width: 200px"
        :tags="store.tags"
        placeholder="empty"
        @on-tags-changed="handleChangeTag"
      />
    </div>
  </v-col>
</template>

<script>
import { useTaskStore } from "@/store/taskStore";
import inputTags from "vue3-tags-input";
import TagIcon from "./icons/TagIcon.vue";

export default {
  name: "TaskTags",
  components: {
    inputTags,
    TagIcon,
  },
  data() {
    return {
      store: useTaskStore(),
    };
  },
  methods: {
    handleChangeTag(tags) {
      this.store.updateTags(tags);
    },
  },
};
</script>
