
export const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes'
    const k = 1024
    const sizes = ['Bytes', 'KB', 'MB', 'GB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
  }
  
  export const validateVideoFile = (file) => {
    const maxSize = 500 * 1024 * 1024 // 500MB in bytes
    const allowedTypes = [
      'video/mp4',
      'video/quicktime',
      'video/mpeg',
      'video/x-msvideo'
    ]
  
    if (!allowedTypes.includes(file.type)) {
      return {
        isValid: false,
        error: 'Invalid file format. Please upload .mp4, .mov, .mpeg, or .avi files only.'
      }
    }
  
    if (file.size > maxSize) {
      return {
        isValid: false,
        error: 'File size exceeds 500MB limit.'
      }
    }
  
    return {
      isValid: true,
      error: null
    }

  }