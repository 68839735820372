<template>
  <div class="advanced-filter">
    <button id="menu-activator" class="filter-btn" title="filter">
      <img :src="filterIcon" alt="filter" />
      <span>Filter</span>
      <div class="d-flex align-center justify-center w-20px">
        <svg
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 3L5 7L9 3"
            stroke="#666666"
            stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </button>

    <v-menu activator="#menu-activator" :close-on-content-click="false">
      <div class="advanced-filter-menu d-flex flex-column ga-5">
        <div class="d-flex justify-space-between align-center mb-4">
          <div class="filter-title">Advanced Filter</div>
          <div class="d-flex align-center ga-4">
            <span class="clear-all" @click="clearAllFilters">Clear all</span>
            <span class="save-as">Save as new view</span>
          </div>
        </div>
        <div class="filter-rows">
          <template v-for="(item, index) in filterItems" :key="index">
            <!-- Regular Filter Row -->
            <div v-if="!item.isGroup" class="filter-row mb-4">
              <div
                class="conditions-container d-flex ga-2 align-center justify-space-between"
              >
                <div class="where" v-if="index === 0">Where</div>
                <div
                  class="where columns"
                  style="min-width: 60px; max-width: 60px"
                  v-else-if="index === 1"
                >
                  <v-autocomplete
                    style="width: 60px"
                    v-model="item.operator"
                    :items="['And', 'Or']"
                    variant="underlined"
                    hide-details
                  ></v-autocomplete>
                </div>
                <div class="where" v-else>{{ filterItems[1].operator }}</div>

                <div class="columns">
                  <v-autocomplete
                    v-model="item.column"
                    :items="columns"
                    variant="underlined"
                    @change="handleColumnChange(index)"
                    hide-details
                  ></v-autocomplete>
                </div>

                <div class="columns">
                  <v-autocomplete
                    v-model="item.condition"
                    label="Select Condition"
                    :items="getConditionsForColumn(item.column)"
                    variant="underlined"
                    hide-details
                  ></v-autocomplete>
                </div>

                <div class="columns">
                  <v-autocomplete
                    v-model="item.value"
                    label="Select Value"
                    :items="getValuesForColumn(item.column)"
                    variant="underlined"
                    hide-details
                  ></v-autocomplete>
                </div>

                <v-btn
                  v-if="filterItems.length > 1"
                  icon="mdi-close"
                  size="small"
                  variant="text"
                  @click="removeItem(index)"
                ></v-btn>
              </div>
            </div>

            <!-- Group Filter -->
            <div v-else class="filter-group mb-4">
              <div>
                <div class="where" v-if="index === 0">Where</div>
                <div
                  class="where columns"
                  style="min-width: 60px; max-width: 60px"
                  v-else-if="index === 1"
                >
                  <v-autocomplete
                    style="width: 60px"
                    v-model="item.operator"
                    :items="['And', 'Or']"
                    variant="underlined"
                    hide-details
                  ></v-autocomplete>
                </div>
                <div class="where" v-else>{{ filterItems[1].operator }}</div>
              </div>

              <div class="group-content">
                <div
                  v-for="(groupRow, groupIndex) in item.rows"
                  :key="groupIndex"
                  class="filter-row mb-4"
                >
                  <div
                    class="conditions-container d-flex ga-2 align-center justify-space-between"
                  >
                    <div class="where" v-if="groupIndex === 0">where</div>
                    <div class="where columns" v-else-if="groupIndex === 1">
                      <v-autocomplete
                        style="width: 60px"
                        v-model="groupRow.operator"
                        :items="['And', 'Or']"
                        variant="underlined"
                        hide-details
                      ></v-autocomplete>
                    </div>
                    <div class="where" v-else>{{ groupIndex[1].operator }}</div>

                    <div class="columns">
                      <v-autocomplete
                        v-model="groupRow.column"
                        :items="columns"
                        variant="underlined"
                        @change="handleGroupColumnChange(index, groupIndex)"
                        hide-details
                      ></v-autocomplete>
                    </div>

                    <div class="columns">
                      <v-autocomplete
                        v-model="groupRow.condition"
                        label="Select Condition"
                        :items="getConditionsForColumn(groupRow.column)"
                        variant="underlined"
                        hide-details
                      ></v-autocomplete>
                    </div>

                    <div class="columns">
                      <v-autocomplete
                        v-model="groupRow.value"
                        label="Select Value"
                        :items="getValuesForColumn(groupRow.column)"
                        variant="underlined"
                        hide-details
                      ></v-autocomplete>
                    </div>

                    <v-btn
                      icon="mdi-close"
                      size="small"
                      variant="text"
                      @click="removeGroupRow(index, groupIndex)"
                    ></v-btn>
                  </div>
                </div>
                <v-btn
                  prepend-icon="mdi-plus"
                  variant="text"
                  class="mt-2"
                  @click="addGroupRow(index)"
                  :disabled="
                    !isGroupRowComplete(item.rows[item.rows.length - 1])
                  "
                >
                  Add condition
                </v-btn>
              </div>
            </div>
          </template>
        </div>
        <div class="">
          <v-btn
            prepend-icon="mdi-plus"
            variant="text"
            class="mt-2"
            @click="addNewRow"
            :disabled="!isLastItemComplete"
          >
            Add new row
          </v-btn>
          <v-btn
            prepend-icon="mdi-plus"
            variant="text"
            class="mt-2 ml-2"
            @click="addNewGroup"
            :disabled="!isLastItemComplete"
          >
            Add group
          </v-btn>
        </div>
        <div class="d-flex justify-end mt-4 gap-2">
          <v-btn
            class="apply-btn"
            @click="applyFilter"
            :disabled="!hasValidFilters"
            >Apply</v-btn
          >
        </div>
      </div>
    </v-menu>
  </div>
</template>

<script>
import filterIcon from "@/assets/images/icons/filter.svg";

export default {
  name: "AdvancedFilter",

  data() {
    return {
      filterIcon,
      filterItems: [
        {
          isGroup: false,
          column: "",
          condition: "",
          value: "",
          operator: "And",
        },
      ],
      filters: [
        {
          id: "time",
          label: "Time",
          conditions: ["is", "is not", "is before", "is after"],
          values: ["Today", "This week", "This month", "This year"],
        },
        {
          id: "status",
          label: "Status",
          conditions: ["is", "is not", "contains", "not contains"],
          values: ["Active", "Inactive", "Pending", "Completed"],
        },
        {
          id: "priority",
          label: "Priority",
          conditions: ["is", "is not"],
          values: ["High", "Medium", "Low"],
        },
      ],
    };
  },

  computed: {
    columns() {
      return this.filters.map((filter) => filter.label);
    },

    isLastItemComplete() {
      const lastItem = this.filterItems[this.filterItems.length - 1];
      if (lastItem.isGroup) {
        return this.isGroupComplete(lastItem);
      }
      return this.isRowComplete(lastItem);
    },

    hasValidFilters() {
      return this.filterItems.some((item) => {
        if (item.isGroup) {
          return item.rows.some((row) => this.isRowComplete(row));
        }
        return this.isRowComplete(item);
      });
    },
  },

  methods: {
    isRowComplete(row) {
      return row.column && row.condition && row.value;
    },

    isGroupRowComplete(row) {
      return row && row.column && row.condition && row.value;
    },

    isGroupComplete(group) {
      return (
        group.rows &&
        group.rows.length > 0 &&
        group.rows.some((row) => this.isRowComplete(row))
      );
    },

    getFilterConfig(columnName) {
      return this.filters.find((filter) => filter.label === columnName);
    },

    getConditionsForColumn(columnName) {
      const filter = this.getFilterConfig(columnName);
      return filter ? filter.conditions : [];
    },

    getValuesForColumn(columnName) {
      const filter = this.getFilterConfig(columnName);
      return filter ? filter.values : [];
    },

    handleColumnChange(index) {
      this.filterItems[index].condition = "";
      this.filterItems[index].value = "";
    },

    handleGroupColumnChange(groupIndex, rowIndex) {
      this.filterItems[groupIndex].rows[rowIndex].condition = "";
      this.filterItems[groupIndex].rows[rowIndex].value = "";
    },

    addNewRow() {
      if (this.isLastItemComplete) {
        this.filterItems.push({
          isGroup: false,
          column: "",
          condition: "",
          value: "",
          operator: "And",
        });
      }
    },

    addNewGroup() {
      if (this.isLastItemComplete) {
        this.filterItems.push({
          isGroup: true,
          operator: "And",
          rows: [
            {
              column: "",
              condition: "",
              value: "",
              operator: "And",
            },
          ],
        });
      }
    },

    addGroupRow(groupIndex) {
      const group = this.filterItems[groupIndex];
      if (group && this.isGroupRowComplete(group.rows[group.rows.length - 1])) {
        group.rows.push({
          column: "",
          condition: "",
          value: "",
          operator: group.rows[0].operator,
        });
      }
    },

    removeItem(index) {
      this.filterItems.splice(index, 1);
      if (this.filterItems.length === 0) {
        this.filterItems.push({
          isGroup: false,
          column: "",
          condition: "",
          value: "",
          operator: "And",
        });
      }
    },

    removeGroupRow(groupIndex, rowIndex) {
      const group = this.filterItems[groupIndex];
      if (group && group.rows.length > 1) {
        group.rows.splice(rowIndex, 1);
      }

      if (group && group.rows.length === 1) {
        this.filterItems.splice(groupIndex, 1);
        if (this.filterItems.length === 0) {
          this.filterItems.push({
            isGroup: false,
            column: "",
            condition: "",
            value: "",
            operator: "And",
          });
        }
      }
    },

    clearAllFilters() {
      this.filterItems = [
        {
          isGroup: false,
          column: "",
          condition: "",
          value: "",
          operator: "And",
        },
      ];
    },

    cancelFilter() {
      this.clearAllFilters();
      this.$emit("close");
    },

    applyFilter() {
      const validFilters = this.filterItems
        .map((item) => {
          if (item.isGroup) {
            return {
              type: "group",
              operator: item.operator,
              conditions: item.rows.filter((row) => this.isRowComplete(row)),
            };
          }
          return {
            type: "single",
            ...item,
          };
        })
        .filter((item) => {
          if (item.type === "group") {
            return item.conditions.length > 0;
          }
          return this.isRowComplete(item);
        });

      this.$emit("filter-applied", validFilters);
    },
  },
};
</script>

<style scoped>
.filter-btn {
  all: unset;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px 12px;
  gap: 10px;
  border: unset;
  background-color: unset;
  border-radius: 8px !important;
}

.filter-btn:hover {
  background-color: #f5f5f5;
}

.w-20px {
  width: 20px;
}

.advanced-filter-menu {
  width: 50vw;
  min-height: 250px;
  padding: 20px 12px 20px 12px;
  border-radius: 12px;
  background: #ffffff;
  border: solid 1px #dddddd;
}

.filter-row {
  position: relative;
}

.filter-group {
  display: flex;
  gap: 10px;
}

.group-header {
  background-color: #f5f5f5;
  padding: 8px;
  border-radius: 4px;
}

.group-content {
  padding: 7px;
  background-color: #fafafa;
  border-radius: 4px;
  margin-top: 8px;
}

.filter-title {
  color: #1a1a1a;
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
}

.clear-all {
  cursor: pointer;
  color: #666666;
  font-size: 14px;
  margin-right: 12px;
}

.clear-all:hover {
  color: #333333;
}

.save-as {
  height: 36px;
  border-radius: 8px;
  border: 1px solid #b3b3b3;
  padding: 0px 10px;
  align-items: center;
  display: flex;
  font-weight: 400;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.save-as:hover {
  background-color: #b3b3b3;
  color: white;
}

.where {
  min-width: 60px;
  color: #666666;
}
.filter-group .columns {
  flex: 1;
  min-width: 140px;
  max-width: 100px;
}
.columns {
  flex: 1;
  min-width: 150px;
  max-width: 200px;
}

.ga-2 {
  gap: 8px;
}

.ga-4 {
  gap: 16px;
}

@media (max-width: 600px) {
  .advanced-filter-menu {
    width: 100vw;
  }

  .conditions-container {
    flex-direction: column;
    align-items: stretch;
  }

  .columns {
    max-width: 100%;
  }
}

@media (min-width: 600px) {
  .advanced-filter-menu {
    width: 35vw;
  }
}
</style>

<style>
.filter-row .conditions-container .columns .v-input {
  height: 36px;
}
.filter-row
  .conditions-container
  .columns
  .v-input
  .v-input__control
  .v-field
  .v-field__outline {
  display: none !important;
}

.filter-row .conditions-container .columns .v-input .v-input__control .v-field,
.filter-row
  .conditions-container
  .columns
  .v-input
  .v-input__control
  .v-field
  .v-field__field,
.filter-row
  .conditions-container
  .columns
  .v-input
  .v-input__control
  .v-field
  .v-field__input,
.filter-row
  .conditions-container
  .columns
  .v-input
  .v-input__control
  .v-field
  .v-field__append-inner {
  height: 36px;
  padding: 0px;
  min-height: unset;
  padding: 0px 1px;
}

.filter-row .conditions-container .columns .v-input .v-input__control .v-field {
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 8px;
}
.filter-row
  .conditions-container
  .columns
  .v-input
  .v-input__control
  .v-field
  .v-field__append-inner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.filter-row
  .conditions-container
  .columns
  .v-input
  .v-input__control
  .v-field.v-field--focused {
  border: 1px solid #237276;
}
.apply-btn {
  background-color: #237276;
}
.apply-btn .v-btn__content {
  color: white !important;
}
</style>
