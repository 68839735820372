<template>
  <div class="placement-card">
    <div class="card-header">
      <p class="card-title">Placements</p>
      <div
        v-if="!optimizeLocations"
        class="tiktok-feed-head d-flex align-center ga-5"
      >
        <img src="@/assets/TiktokMedia/logo.png" alt="" />
        <div>
          <label for="" class="d-block mb-1">TikTok</label>
          <div class="in-feed-input d-flex ga-4 align-center">
            <div class="d-flex align-center">
              <input
                id="inFeed"
                disabled
                v-model="inFeed"
                type="checkbox"
                class="ui-checkbox"
                @change="changeInfeedValue"
              />
              <label class="feed-label" for="inFeed">in-feed</label>
            </div>
            <div class="d-flex ga-2 align-center">
              <input
                id="searchFeed"
                v-model="searchFeed"
                type="checkbox"
                @change="searchFeedValue"
                class="ui-checkbox"
              />
              <label class="feed-search-label" for="searchFeed"
                >Search Feed</label
              >
            </div>
          </div>
        </div>
      </div>
      <TrafficPlacement v-else />
      <div class="card-body">
        <button
          @click="advancedSetting = !advancedSetting"
          class="advanced-setting"
        >
          <div v-if="advancedSetting">
            <svg
              width="12"
              height="12"
              fill="#6d6e70"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                d="M246.6 470.6c-12.5 12.5-32.8 12.5-45.3 0l-160-160c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L224 402.7 361.4 265.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3l-160 160zm160-352l-160 160c-12.5 12.5-32.8 12.5-45.3 0l-160-160c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L224 210.7 361.4 73.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3z"
              />
            </svg>
            <span> Advanced settings </span>
          </div>
          <div v-else>
            <svg
              width="12"
              fill="#6d6e70"
              height="12"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                d="M246.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L224 109.3 361.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160zm160 352l-160-160c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L224 301.3 361.4 438.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3z"
              />
            </svg>
            <span> Hide advanced settings </span>
          </div>
        </button>
        <div class="advanced-setting-contant mt-4" v-if="!advancedSetting">
          <div
            class="checked-label smart-campaign mb-1 d-flex align-center ga-1"
          >
            <label class="toggle-switch">
              <input
                @change="userCommentStatus"
                type="checkbox"
                v-model="userComment"
                id="user-comment"
              />
              <div class="toggle-switch-background">
                <div class="toggle-switch-handle"></div>
                <svg
                  class="closemark"
                  xmlns="http://www.w3.org/2000/svg"
                  height="30px"
                  viewBox="0 -960 960 960"
                  width="30px"
                  fill=""
                >
                  <path
                    d="M291-253.85 253.85-291l189-189-189-189L291-706.15l189 189 189-189L706.15-669l-189 189 189 189L669-253.85l-189-189-189 189Z"
                  />
                </svg>
                <svg
                  class="checkmark"
                  xmlns="http://www.w3.org/2000/svg"
                  height="30px"
                  viewBox="0 -960 960 960"
                  width="30px"
                  fill=""
                >
                  <path
                    d="M389-259.35 187.35-460l58.89-59.89L389-377.13l324.76-323.76L772.65-642 389-259.35Z"
                  />
                </svg>
              </div>
            </label>
            <label for="user-comment" class="label-for-input">
              User comment
            </label>
          </div>
          <div class="user-comment-description">
            <p>
              We recommend keeping user comments on to help your ads achieve
              more impressions and conversions. You can hide, pin, and reply to
              comments using our comment management tools.
            </p>
          </div>
          <div
            class="checked-label smart-campaign mt-6 mb-1 d-flex align-center ga-1"
          >
            <label class="toggle-switch">
              <input
                @change="videoDownloadStatus"
                type="checkbox"
                v-model="videoDownload"
                id="video-download"
              />
              <div class="toggle-switch-background">
                <div class="toggle-switch-handle"></div>
                <svg
                  class="closemark"
                  xmlns="http://www.w3.org/2000/svg"
                  height="30px"
                  viewBox="0 -960 960 960"
                  width="30px"
                  fill=""
                >
                  <path
                    d="M291-253.85 253.85-291l189-189-189-189L291-706.15l189 189 189-189L706.15-669l-189 189 189 189L669-253.85l-189-189-189 189Z"
                  />
                </svg>
                <svg
                  class="checkmark"
                  xmlns="http://www.w3.org/2000/svg"
                  height="30px"
                  viewBox="0 -960 960 960"
                  width="30px"
                  fill=""
                >
                  <path
                    d="M389-259.35 187.35-460l58.89-59.89L389-377.13l324.76-323.76L772.65-642 389-259.35Z"
                  />
                </svg>
              </div>
            </label>
            <label for="video-download" class="label-for-input">
              Allow video download
            </label>
          </div>
          <div
            class="checked-label smart-campaign mt-6 mb-1 d-flex align-center ga-1"
          >
            <label class="toggle-switch">
              <input
                @change="videoSharingStatus"
                type="checkbox"
                v-model="videoSharing"
                id="video-shar"
              />
              <div class="toggle-switch-background">
                <div class="toggle-switch-handle"></div>
                <svg
                  class="closemark"
                  xmlns="http://www.w3.org/2000/svg"
                  height="30px"
                  viewBox="0 -960 960 960"
                  width="30px"
                  fill=""
                >
                  <path
                    d="M291-253.85 253.85-291l189-189-189-189L291-706.15l189 189 189-189L706.15-669l-189 189 189 189L669-253.85l-189-189-189 189Z"
                  />
                </svg>
                <svg
                  class="checkmark"
                  xmlns="http://www.w3.org/2000/svg"
                  height="30px"
                  viewBox="0 -960 960 960"
                  width="30px"
                  fill=""
                >
                  <path
                    d="M389-259.35 187.35-460l58.89-59.89L389-377.13l324.76-323.76L772.65-642 389-259.35Z"
                  />
                </svg>
              </div>
            </label>
            <label for="video-shar" class="label-for-input">
              Allow video sharing
            </label>
          </div>
          <div v-if="optimizeLocations">
            <div
              class="checked-label smart-campaign mt-6 mb-1 d-flex align-center ga-1"
            >
              <label class="toggle-switch">
                <input
                  @change="userCommentStatus"
                  type="checkbox"
                  v-model="pangleBlockList"
                  id="pangleBlockList"
                />
                <div class="toggle-switch-background">
                  <div class="toggle-switch-handle"></div>
                  <svg
                    class="closemark"
                    xmlns="http://www.w3.org/2000/svg"
                    height="30px"
                    viewBox="0 -960 960 960"
                    width="30px"
                    fill=""
                  >
                    <path
                      d="M291-253.85 253.85-291l189-189-189-189L291-706.15l189 189 189-189L706.15-669l-189 189 189 189L669-253.85l-189-189-189 189Z"
                    />
                  </svg>
                  <svg
                    class="checkmark"
                    xmlns="http://www.w3.org/2000/svg"
                    height="30px"
                    viewBox="0 -960 960 960"
                    width="30px"
                    fill=""
                  >
                    <path
                      d="M389-259.35 187.35-460l58.89-59.89L389-377.13l324.76-323.76L772.65-642 389-259.35Z"
                    />
                  </svg>
                </div>
              </label>
              <label for="pangleBlockList" class="label-for-input">
                Pangle block list
              </label>
            </div>
            <div class="user-comment-description">
              <p>
                To ensure brand safety, undesired media placements on Pangle can
                be added to block list to help prevent your ads from appearing.
                However, this will decrease the reach of your ads.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TrafficPlacement from "./TrafficPlacement.vue";

export default {
  components: {
    TrafficPlacement,
  },
  data() {
    return {
      inFeed: true,
      searchFeed: true,
      advancedSetting: true,
      userComment: true,
      videoDownload: true,
      videoSharing: true,
      optimizeLocations: false,
      pangleBlockList: false,
    };
  },
  mounted() {
    localStorage.setItem("infeed", this.inFeed);
    localStorage.setItem("searchFeed", this.searchFeed);
    localStorage.setItem("user_comment", this.userComment);
    localStorage.setItem("video_downloads", this.videoDownload);
    localStorage.setItem("video_sharing", this.videoSharing);
    const selectedObject = localStorage.getItem("selected_objective");
    if (
      selectedObject === "TRAFFIC" ||
      selectedObject === "APP_PROMOTION" ||
      selectedObject === "WEBSITE_CONVERSIONS" ||
      selectedObject === "PRODUCT_SALES" ||
      selectedObject === "LEAD_GENERATION"
    ) {
      this.optimizeLocations = true;
    } else {
      this.optimizeLocations = false;
    }
  },
  methods: {
    changeInfeedValue() {
      const currentInfeedValue = localStorage.getItem("infeed");
      if (currentInfeedValue === "true") {
        localStorage.setItem("infeed", "false");
      } else {
        localStorage.setItem("infeed", "true");
      }
    },
    searchFeedValue() {
      const currentSerchFeed = localStorage.getItem("searchFeed");
      if (currentSerchFeed === "true") {
        localStorage.setItem("searchFeed", false);
      } else {
        localStorage.setItem("searchFeed", true);
      }
    },
    userCommentStatus() {
      if (localStorage.getItem("user_comment") === "true") {
        localStorage.setItem("user_comment", false);
      } else {
        localStorage.setItem("user_comment", true);
      }
    },
    videoDownloadStatus() {
      if (localStorage.getItem("video_downloads") === "true") {
        localStorage.setItem("video_downloads", false);
      } else {
        localStorage.setItem("video_downloads", true);
      }
    },
    videoSharingStatus() {
      if (localStorage.getItem("video_sharing") === "true") {
        localStorage.setItem("video_sharing", false);
      } else {
        localStorage.setItem("video_sharing", true);
      }
    },
  },
};
</script>
