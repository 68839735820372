<template>
  <div class="d-flex justify-space-between align-center mt-5">
    <div class="d-flex align-center ga-2">
      <span>per page</span>
      <v-select
        label=""
        :items="perPageOptions"
        v-model="store.items_per_page"
        @update:model-value="onItemsPerPageChange"
        hide-details
        class="pagination-per-page"
      ></v-select>
    </div>
    <div class="d-flex align-center ga-2">
      <div class="d-flex align-center ga-2">
        <v-select
          label=""
          :items="pageNumbers"
          v-model="store.current_page"
          @update:model-value="onPageChange"
          hide-details
          class="pagination-per-page"
        ></v-select>
        <span>of {{ totalPages }} Pages</span>
      </div>
      <div class="d-flex align-center ga-2">
        <button
          class="d-flex align-center justify-center"
          :disabled="!hasPreviousPage"
          @click="previousPage"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14.7905 5.94451C15.0421 6.16016 15.0712 6.53894 14.8556 6.79053L10.3902 12.0001L14.8556 17.2096C15.0712 17.4612 15.0421 17.84 14.7905 18.0556C14.5389 18.2713 14.1601 18.2421 13.9444 17.9905L9.14445 12.3905C8.95185 12.1658 8.95185 11.8343 9.14445 11.6096L13.9444 6.00958C14.1601 5.75799 14.5389 5.72885 14.7905 5.94451Z"
              fill="#333333"
              fill-opacity="0.8"
            />
          </svg>
        </button>

        <button
          :disabled="!hasNextPage"
          class="d-flex align-center justify-center"
          @click="nextPage"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.20954 5.94451C9.46113 5.72885 9.83991 5.75799 10.0556 6.00958L14.8556 11.6096C15.0482 11.8343 15.0482 12.1658 14.8556 12.3905L10.0556 17.9905C9.83991 18.2421 9.46113 18.2713 9.20954 18.0556C8.95794 17.84 8.9288 17.4612 9.14446 17.2096L13.6098 12.0001L9.14446 6.79053C8.9288 6.53894 8.95794 6.16016 9.20954 5.94451Z"
              fill="#333333"
              fill-opacity="0.8"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaginationComponent",
  props: {
    store: Object,
  },
  data() {
    return {
      // store: null,
      perPageOptions: [1, 5, 10, 20, 50, 100],
    };
  },
  computed: {
    totalPages() {
      return this.store.total_pages;
    },
    pageNumbers() {
      return Array.from({ length: this.totalPages }, (_, index) => index + 1);
    },
    hasPreviousPage() {
      return this.store?.current_page > 1;
    },
    hasNextPage() {
      return this.store?.current_page < this.totalPages;
    },
  },
  created() {},
  methods: {
    onPageChange() {
      this.store?.onPageChange(this.store.current_page);
    },
    onItemsPerPageChange() {
      this.store?.onItemsPerPageChange();
    },
    previousPage() {
      if (this.hasPreviousPage) {
        this.store.current_page -= 1;
        this.onPageChange();
      }
    },
    nextPage() {
      if (this.hasNextPage) {
        this.store.current_page += 1;
        this.onPageChange();
      }
    },
  },
};
</script>

<style scoped>
.pagination-per-page {
  width: 80px;
}
.ga-2 {
  gap: 8px;
}
button {
  cursor: pointer;
  background: none;
  border: none;
  padding: 4px;
}
button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
