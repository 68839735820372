<template>
  <v-col :cols="12" :lg="9">
    <div class="bold-text mb-2">Set your post to</div>
    <v-radio-group v-model="store.postType" row>
      <v-radio
        color="rgba(35, 114, 118, 1)"
        label="Organic"
        value="organic"
      ></v-radio>
      <v-radio
        v-if="store.postType !== 'sponsored'"
        color="rgba(35, 114, 118, 1)"
        label="Sponsored"
        value="sponsored"
      ></v-radio>
    </v-radio-group>

    <div v-if="store.postType === 'sponsored'" class="sponsored-details">
      <v-radio-group v-model="store.postType">
        <v-radio
          color="rgba(35, 114, 118, 1)"
          label="Sponsored"
          value="sponsored"
        ></v-radio>
      </v-radio-group>

      <div class="body p-3">
        <div class="d-lg-flex d-md-block justify-space-between mb-2">
          <div class="d-flex ga-2 align-center">
            <div class="bold-text muted-text">Budget</div>
            <v-radio-group class="row-radio-group">
              <v-radio
                label="Daily"
                color="rgba(35, 114, 118, 1)"
                value="daily"
              ></v-radio>
              <v-radio
                label="Total"
                color="rgba(35, 114, 118, 1)"
                value="total"
              ></v-radio>
            </v-radio-group>
          </div>
          <div class="d-flex ga-2 align-center">
            <div class="bold-text muted-text">Budget</div>
            <input
              type="number"
              class="calender-input w-100"
              placeholder="USD"
            />
          </div>
        </div>

        <div class="d-lg-flex d-md-block justify-space-between">
          <div class="d-flex ga-2 align-center">
            <div class="bold-text muted-text">Duration</div>
            <input
              type="number"
              class="calender-input w-100"
              placeholder="Days"
            />
          </div>
          <v-switch
            v-model="store.setEndDate"
            hide-details
            inset
            color="rgba(35, 114, 118, 1)"
          ></v-switch>
          <div class="d-flex ga-2 align-center">
            <div class="bold-text muted-text">End Date</div>
            <flat-pickr
              :disabled="!store.setEndDate"
              class="calender-input"
              v-model="store.sponsoredEndDate"
            />
          </div>
        </div>
      </div>
    </div>
  </v-col>
</template>

<script>
import { useTaskStore } from "@/store/taskStore";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  name: "TaskPostType",
  components: {
    flatPickr,
  },
  data() {
    return {
      store: useTaskStore(),
    };
  },
};
</script>
