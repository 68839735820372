<template>
  <v-container fluid style="min-height: calc(100vh - 58px); position: relative">
    <v-row style="height: 100%; margin: 0; padding: 0; position: relative">
      <v-col cols="12" class="pa-0">
        <GlobalNav />
      </v-col>
      <v-col cols="12" class="pa-0 d-flex align-start ga-6">
        <SideBar />
        <div
          class="w-100 pa-3 is-light-mode position-relative"
          style="height: 87vh"
        >
          <BreadCrumb :linksData="linksData" class="mb-4" />
          <v-row>
            <v-col :lg="6" :md="12" :sm="12">
              <span class="profile-box-main-text mb-5">My Account</span>
            </v-col>
          </v-row>
          <div class="d-flex flex-column w-100 ga-5 mb-5">
            <ProfilePicture
              :profile="profile"
              :errors="errors"
              @change="handleImageChange"
            />
            <BasicInfo
              :profile="profile"
              :errors="errors"
              @change:firstName="handleFirstNameChange"
              @change:lastName="handleLastNameChange"
              @change:dateOfBirth="handleDateOfBirthChange"
            />
            <ContactInfo
              :profile="profile"
              :errors="errors"
              @change:email="handleEmailChange"
              @change:phone="handlePhoneChange"
            />
            <Password
              :profile="profile"
              :errors="errors"
              @change:currentPassword="handleCurrentPasswordChange"
              @change:newPassword="handleNewPasswordChange"
              @change:confirmPassword="handleConfirmPasswordChange"
            />
            <General
              :profile="profile"
              :errors="errors"
              @change:language="handleLanguageChange"
            />
          </div>
          <div
            class="d-flex justify-end position-fixed bottom-0 right-0 w-100 pa-5"
          >
            <button class="choose-btn" @click="updateProfile">Update</button>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
      location="top"
      close-on-content-click
    >
      {{ snackbar.message }}
    </v-snackbar>
  </v-container>
</template>
 
<script>
import GlobalNav from "@/components/layout/GlobalNav.vue";
import SideBar from "@/components/layout/SideBar.vue";
import BreadCrumb from "@/components/layout/BreadCrumb.vue";
import ProfilePicture from "@/components/myAccount/edit/ProfilePicture.vue";
import General from "@/components/myAccount/edit/General.vue";
import Password from "@/components/myAccount/edit/Password.vue";
import BasicInfo from "@/components/myAccount/edit/BasicInfo.vue";
import ContactInfo from "@/components/myAccount/edit/ContactInfo.vue";
import apiClient from "@/core/buildRequest";

export default {
  name: "EditMyAccount",

  components: {
    GlobalNav,
    SideBar,
    BreadCrumb,
    ProfilePicture,
    General,
    Password,
    BasicInfo,
    ContactInfo,
  },

  data() {
    return {
      linksData: [
        { name: "Home", link: "/" },
        { name: "My Account", link: "" },
      ],
      form: {
        image: null,
        first_name: "",
        last_name: "",
        email: "",
        mobile: "",
        old_password: "",
        password: "",
        password_confirmation: "",
        lang: "",
        date_of_birth: "",
      },
      snackbar: {
        color: "",
        show: false,
        message: "",
        timeout: 3000,
      },
      errors: {},
      profile: [],
    };
  },

  methods: {
    // Form field handlers
    handleImageChange(value) {
      this.form.image = value;
    },
    handleFirstNameChange(value) {
      this.form.first_name = value;
    },
    handleLastNameChange(value) {
      this.form.last_name = value;
    },
    handleDateOfBirthChange(value) {
      this.form.date_of_birth = value;
    },
    handleEmailChange(value) {
      this.form.email = value;
    },
    handlePhoneChange(value) {
      this.form.mobile = value;
    },
    handleCurrentPasswordChange(value) {
      this.form.old_password = value;
    },
    handleNewPasswordChange(value) {
      this.form.password = value;
    },
    handleConfirmPasswordChange(value) {
      this.form.password_confirmation = value;
    },
    handleLanguageChange(value) {
      this.form.lang = value;
    },

    validateForm() {
      const rules = {
        image: (value) => (value ? true : "Please upload a valid image."),
        first_name: (value) =>
          value?.trim().length > 0 && value.length <= 255
            ? true
            : "First name is required and must be under 255 characters.",
        last_name: (value) =>
          value?.trim().length > 0 && value.length <= 255
            ? true
            : "Last name is required and must be under 255 characters.",
        email: (value) =>
          /^\S+@\S+\.\S+$/.test(value)
            ? true
            : "Please enter a valid email address.",
        mobile: (value) =>
          /^\d{10,15}$/.test(value)
            ? true
            : "Mobile number must be 10-15 digits long.",
        old_password: (value) =>
          value?.length === 0 ? "Old Password is required." : true,
        password: (value) =>
          value?.length > 0 && value.length < 6
            ? "Password must be at least 6 characters."
            : true,
        password_confirmation: (value) =>
          value != this.form.password ? "Passwords do not match." : true,
        lang: (value) =>
          ["en", "ar"].includes(value)
            ? true
            : 'Language must be "en" or "ar".',
        date_of_birth: (value) =>
          !isNaN(Date.parse(value)) || "Please enter a valid date.",
      };

      this.errors = {};
      Object.entries(this.form).forEach(([key, value]) => {
        const rule = rules[key];
        if (rule) {
          const result = rule(value);
          if (result !== true) this.errors[key] = result;
        }
      });

      return Object.keys(this.errors).length === 0;
    },

    showSnackbar(message, color) {
      this.snackbar = { message, color, show: true, timeout: 3000 };
    },
    getProfile() {
      apiClient
        .get("/my-account/get/account")
        .then((response) => {
          this.profile = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateProfile() {
      if (this.validateForm()) {
        setTimeout(() => {
          apiClient
            .post("/my-account/update/account", {
              image: this.form.image,
              first_name: this.form.first_name,
              last_name: this.form.last_name,
              email: this.form.email,
              mobile: this.form.mobile,
              old_password: this.form.old_password,
              password: this.form.password,
              password_confirmation: this.form.password_confirmation,
              lang: this.form.lang,
              date_of_birth: this.form.date_of_birth,
            })
            .then((response) => {
              this.showSnackbar("Profile updated successfully!", "success");
            })
            .catch((error) => {
              this.showSnackbar(
                "Something went wrong please try again later !",
                "error"
              );
            });
        }, 1000);
      } else {
        this.showSnackbar("Please fix the errors and try again.", "error");
      }
    },
  },
  mounted() {
    this.getProfile();
  },
};
</script>
