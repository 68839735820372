<template>
  <v-navigation-drawer
    width="1200"
    location="right"
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <v-list-item style="background-color: #fafafa" title="Music builder">
      <button @click="$emit('update:modelValue', false)">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="20px"
          viewBox="0 -960 960 960"
          width="20px"
          fill="#000"
        >
          <path
            d="m291-240-51-51 189-189-189-189 51-51 189 189 189-189 51 51-189 189 189 189-51 51-189-189-189 189Z"
          />
        </svg>
      </button>
    </v-list-item>

    <v-divider></v-divider>

    <v-container class="music-upload">
      <v-row no-gutters>
        <v-col cols="5">
          <div class="search-container mb-5">
            <svg width="1em" height="1em" viewBox="0 0 48 48" fill="none">
              <path
                d="M21 38C30.3888 38 38 30.3888 38 21C38 11.6112 30.3888 4 21 4C11.6112 4 4 11.6112 4 21C4 30.3888 11.6112 38 21 38Z"
                fill="none"
                stroke="currentColor"
                stroke-width="4"
                stroke-linejoin="round"
              ></path>
              <path
                d="M26.6568 14.3431C25.2091 12.8954 23.2091 12 21 12C18.7909 12 16.7909 12.8954 15.3431 14.3431"
                stroke="currentColor"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M33.2218 33.2218L41.7071 41.7071"
                stroke="currentColor"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
            <input type="search" name="" id="" />
          </div>
          <div class="media-type-upload">
            <v-card elevation="0">
              <v-tabs v-model="tab">
                <v-tab value="one" disabled>For you</v-tab>
                <v-tab value="two">History</v-tab>
                <v-tab value="three">Liked </v-tab>
                <v-tab value="four">Upload </v-tab>
              </v-tabs>

              <v-card-text class="pa-0">
                <v-tabs-window v-model="tab">
                  <v-tabs-window-item value="one"> One </v-tabs-window-item>

                  <v-tabs-window-item value="two"> Two </v-tabs-window-item>

                  <v-tabs-window-item value="three"> Three </v-tabs-window-item>
                  <v-tabs-window-item value="four">
                    <div
                      class="mb-4"
                      @drop.prevent="handleDrop"
                      @dragover.prevent="handleDragOver"
                      @dragleave.prevent="handleDragLeave"
                      :class="{ 'drag-over': isDragging }"
                    >
                      <input
                        type="file"
                        ref="fileInput"
                        @change="handleFileSelect"
                        accept="audio/*"
                        style="display: none"
                      />
                      <div class="upload-placeholder">
                        <button @click="$refs.fileInput.click()" class="mt-2 mb-2">
                          <img
                            src="@/assets/tiktokImage/cloud-upload-svgrepo-com.svg"
                            alt=""
                          />
                          <span> Upload </span>
                        </button>
                        <span>File size: Up to 10M; Music format: Mp3 </span>
                      </div>
                    </div>
                    <div v-if="musicFile" class="selected-music">
                      <v-card class="mb-4">
                        <v-card-title
                          class="d-flex justify-space-between align-center"
                        >
                          {{ musicFile.name }}
                          <v-btn
                            icon="mdi-close"
                            size="small"
                            variant="text"
                            @click="removeMusicFile"
                            :disabled="isUploading"
                          ></v-btn>
                        </v-card-title>
                        <v-card-text>
                          <div class="d-flex justify-space-between mb-2">
                            <span class="text-caption">{{
                              formatFileSize(musicFile.size)
                            }}</span>
                          </div>

                          <AudioPreview
                            v-if="audioUrl"
                            :src="audioUrl"
                            class="mb-4"
                          />

                          <v-progress-linear
                            v-if="uploadProgress > 0 && uploadProgress < 100"
                            :model-value="uploadProgress"
                            color="primary"
                            height="20"
                          >
                            <template v-slot:default="{ value }">
                              <strong>{{ Math.ceil(value) }}%</strong>
                            </template>
                          </v-progress-linear>
                        </v-card-text>
                      </v-card>

                      <div class="d-flex justify-end">
                        <v-btn
                          :disabled="isUploading"
                          :loading="isUploading"
                          @click="uploadMusic"
                          color="primary"
                        >
                          Upload Music
                        </v-btn>
                      </div>
                    </div>
                  </v-tabs-window-item>
                </v-tabs-window>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
        <v-col cols="7"></v-col>
        <v-col cols="12">
          <div class="upload-container pa-4">
            <v-alert
              v-if="error"
              type="error"
              class="mb-4"
              closable
              @click:close="error = ''"
            >
              {{ error }}
            </v-alert>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import { formatFileSize } from "@/core/fileUtils";
import AudioPreview from "./AudioPreview.vue";
export default {
  name: "MusicUploadDrawer",
  components: {
    AudioPreview,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  data: () => ({
    tab: null,
    isDragging: false,
    error: "",
    musicFile: null,
    audioUrl: null,
    isUploading: false,
    uploadProgress: 0,
  }),
  methods: {
    formatFileSize,
    handleDragOver() {
      this.isDragging = true;
    },
    handleDragLeave() {
      this.isDragging = false;
    },
    handleDrop(e) {
      this.isDragging = false;
      const file = e.dataTransfer.files[0];
      this.validateAndSetFile(file);
    },
    handleFileSelect(e) {
      const file = e.target.files[0];
      this.validateAndSetFile(file);
    },
    validateAndSetFile(file) {
      if (!file) return;
      const maxSize = 50 * 1024 * 1024;
      const allowedTypes = ["audio/mpeg", "audio/wav", "audio/ogg"];
      if (!allowedTypes.includes(file.type)) {
        this.error =
          "Invalid file type. Only MP3, WAV, and OGG files are allowed.";
        return;
      }
      if (file.size > maxSize) {
        this.error = "File size exceeds 50MB limit.";
        return;
      }
      if (this.audioUrl) {
        URL.revokeObjectURL(this.audioUrl);
      }
      this.musicFile = file;
      this.audioUrl = URL.createObjectURL(file);
      this.error = "";
    },
    removeMusicFile() {
      if (this.audioUrl) {
        URL.revokeObjectURL(this.audioUrl);
      }
      this.musicFile = null;
      this.audioUrl = null;
      this.uploadProgress = 0;
      this.error = "";
      // Reset the file input
      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = "";
      }
    },
    async uploadMusic() {
      if (!this.musicFile) return;

      this.isUploading = true;
      this.uploadProgress = 0;

      try {
        // Simulate upload progress
        for (let i = 0; i <= 100; i += 10) {
          this.uploadProgress = i;
          await new Promise((resolve) => setTimeout(resolve, 200));
        }

        // Here you would implement your actual upload logic
        await new Promise((resolve) => setTimeout(resolve, 500));

        this.$emit("upload-complete", {
          name: this.musicFile.name,
          size: this.musicFile.size,
          type: this.musicFile.type,
        });

        this.isUploading = false;
        this.uploadProgress = 100;
      } catch (error) {
        this.error = "Upload failed. Please try again.";
        this.isUploading = false;
      }
    },
  },
  beforeUnmount() {
    if (this.audioUrl) {
      URL.revokeObjectURL(this.audioUrl);
    }
  },
};
</script>

<style scoped>
.upload-container {
  width: 100%;
}

.upload-area {
  border: 2px dashed #ccc;
  border-radius: 8px;
  padding: 40px;
  text-align: center;
  transition: all 0.3s ease;
  background-color: #f5f5f5;
}

.upload-area.drag-over {
  border-color: #2196f3;
  background-color: #e3f2fd;
}

.upload-placeholder button {
  display: flex;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
  height: 34px;
  line-height: 22px;
  min-width: 100px;
  padding: 5px 16px;
  background-color: rgb(242, 243, 243);
  color: rgb(18, 20, 21);
}
.upload-placeholder button:hover {
  background-color: rgb(229, 229, 230);
  border-color: rgb(229, 229, 230);
  color: rgb(18, 20, 21);
}
.upload-placeholder button img {
  width: 18px;
  height: 18px;
}
.selected-music {
  margin-top: 20px;
}
</style>
