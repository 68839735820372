<template>
  <v-dialog v-model="dialogModel" max-width="800" persistent>
    <v-card class="pa-4">
      <v-card-title>Edit An Account</v-card-title>

      <v-card-text>
        <div class="d-flex flex-column ga-5">
          <span class="error-message" v-for="e in errors" :key="e">{{
            e
          }}</span>

          <!-- Form Fields -->
          <template v-for="field in formFields" :key="field.id">
            <div
              :class="[
                field.type === 'autocomplete' ? 'filed-autocomplete' : 'field',
                'd-flex flex-column ga-2',
              ]"
            >
              <label :ref="field.ref" :for="field.id">
                {{ field.label }}
                <span v-if="field.required" class="required">*</span>
              </label>

              <!-- Regular Input -->
              <input
                v-if="field.type === 'input'"
                :id="field.id"
                v-model="formData[field.id]"
                :type="field.inputType || 'text'"
              />

              <!-- Contacts Autocomplete -->
              <v-autocomplete
                v-else-if="field.id === 'contact_id'"
                v-model="formData[field.id]"
                :items="all_contacts"
                item-title="contact_number"
                item-value="id"
                multiple
                chips
                closable-chips
                hide-details
              />

              <!-- Potentiality Autocomplete -->
              <v-autocomplete
                v-else-if="field.id === 'potentiality'"
                v-model="formData[field.id]"
                :items="field.items"
                hide-details
              >
                <template v-slot:item="{ item, props }">
                  <v-list-item
                    v-bind="props"
                    :title="item.raw"
                    :class="{
                      'contact-v-select-prime': item.raw === 'VIP',
                      'contact-v-select-blue': item.raw === 'A',
                      'contact-v-select-orange': item.raw === 'B',
                      'contact-v-select-red': item.raw === 'C',
                    }"
                  >
                    <template v-slot:prepend></template>
                  </v-list-item>
                </template>
              </v-autocomplete>

              <!-- Regular Autocomplete -->
              <v-autocomplete
                v-else-if="field.type === 'autocomplete'"
                v-model="formData[field.id]"
                :items="field.items"
                :item-value="field.itemValue"
                :item-title="field.itemTitle"
                @click="field.onClick"
                @update:search="field.onClick"
                hide-details
              />
            </div>
          </template>
        </div>
      </v-card-text>

      <v-card-actions class="sticky-footer-dialog">
        <v-spacer></v-spacer>
        <span class="cancel-btn cursor-pointer" @click="cancelDialog"
          >Cancel</span
        >
        <span class="choose-btn cursor-pointer" @click="saveAccount">Save</span>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { useContactsStore } from "@/store/crm/useContactsStore";
import { useHelperStore } from "@/store/crm/useHelperStore";
import { getFieldError } from "@/utils/formValdiation.js";
import apiClient from "@/core/buildRequest";

export default {
  name: "AccountDialog",

  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    contacts: {
      type: Array,
      default: () => [],
    },
    id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      contacts_store: useContactsStore(),
      helper_store: null,
      formData: {
        name: "",
        phone: "",
        email: "",
        contact_id: [],
        business_type_id: "",
        potentiality: "",
        country_id: "",
        city_id: "",
      },
      business_type_items: [],
      countries: [],
      cities: [],
      errors: [],
    };
  },

  computed: {
    dialogModel: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("update:dialog", value);
      },
    },
    all_contacts() {
      if (this.contacts_store?.getContacts?.length === 0) {
        this.contacts_store?.getAllContacts();
      }
      return this.contacts_store?.getContacts || [];
    },
    formFields() {
      return [
        {
          id: "name",
          label: "Account Name",
          type: "input",
          required: true,
          ref: "accountNameInput",
        },
        {
          id: "phone",
          label: "Phone Number",
          type: "input",
          required: true,
          ref: "phoneInput",
        },
        {
          id: "email",
          label: "Email Address",
          type: "input",
          inputType: "email",
          required: true,
          ref: "emailInput",
        },
        {
          id: "contact_id",
          label: "Contacts",
          type: "autocomplete",
          required: true,
          ref: "contactsInput",
        },
        {
          id: "business_type_id",
          label: "Business Type",
          type: "autocomplete",
          required: true,
          ref: "businessTypeInput",
          items: this.business_type_items,
          itemValue: "id",
          itemTitle: "name",
        },
        {
          id: "potentiality",
          label: "Potentiality",
          type: "autocomplete",
          required: true,
          ref: "potentialityInput",
          items: ["VIP", "A", "B", "C"],
        },
        {
          id: "country_id",
          label: "Country",
          type: "autocomplete",
          required: true,
          ref: "countryInput",
          items: this.countries,
          itemValue: "id",
          itemTitle: "name.en",
          onClick: this.onCountryAutocompleteOpen,
        },
        {
          id: "city_id",
          label: "City",
          type: "autocomplete",
          required: true,
          ref: "cityInput",
          items: this.cities,
          itemValue: "id",
          itemTitle: "name.en",
        },
      ];
    },
  },

  watch: {
    contacts: {
      handler(newVal) {
        this.formData.contact_id = newVal;
      },
      deep: true,
    },
    "formData.name"(value) {
      this.updateValidationClass("accountNameInput", value);
    },
    "formData.phone"(value) {
      this.updateValidationClass("phoneInput", value);
    },
    "formData.email"(value) {
      this.updateValidationClass("emailInput", value);
    },
    "formData.business_type_id"(value) {
      this.updateValidationClass("businessTypeInput", value);
    },
    "formData.country_id"(value) {
      this.updateValidationClass("countryInput", value);
    },
    "formData.city_id"(value) {
      this.updateValidationClass("cityInput", value);
    },
    "helper_store.bussiness_types": {
      handler(newBusinessTypes) {
        this.business_type_items = newBusinessTypes;
      },
      deep: true,
    },
    "helper_store.cities": {
      handler(newCities) {
        this.cities = newCities;
      },
      deep: true,
    },
    "helper_store.countries": {
      handler(newCountries) {
        this.countries = newCountries;
      },
      deep: true,
    },
  },

  created() {
    this.helper_store = useHelperStore();
    if (this.contacts.length > 0) {
      this.formData.contact_id = this.contacts.map((contact) => contact.id);
    }
  },

  methods: {
    async getAccount() {
      await apiClient
        .get(`/leads-managment/accounts/${this.id}`)
        .then((res) => {
          const this_account = res.data.data;
          this.formData.name = this_account.name;
          this.formData.phone = this_account.phone;
          this.formData.email = this_account.email;
          this.formData.country_id = this_account.country.id;
          this.formData.city_id = this_account.city.id;
          this.formData.contact_id = this_account.contacts.map(
            (contact) => contact.id
          );
          this.formData.business_type_id = this_account.business_type.id;
          this.formData.potentiality = this_account.potentiality;
          this.onCityAutocompleteOpen(this_account.country.id);
        });
    },
    updateValidationClass(ref, value) {
      const el = this.$refs[ref]?.[0];
      if (el) {
        el.classList[!value ? "add" : "remove"]("error-validation");
      }
    },
    async onCountryAutocompleteOpen() {
      if (this.helper_store?.getCountries.length === 0) {
        await this.helper_store?.fetchCountries();
        this.countries = this.helper_store?.getCountries;
      }
    },
    async onBusinessTypeAutocompleteOpen() {
      if (this.helper_store?.getBussinessTypes.length === 0) {
        await this.helper_store?.fetchBussinessTypes();
      }
      this.business_type_items = this.helper_store?.getBussinessTypes;
    },
    async onCityAutocompleteOpen(country_id) {
      if (country_id && this.helper_store.selectedCountry !== country_id) {
        this.helper_store.setSelectedCountry(country_id);
        await this.helper_store?.fetchCities();
        this.cities = this.helper_store?.getCities;
      }
    },
    cancelDialog() {
      this.$emit("update:dialog", false);
    },
    validateForm() {
      this.errors = [];
      let isValid = true;

      for (const field of this.formFields) {
        if (!field.required) continue;

        const value = this.formData[field.id];
        const error = getFieldError(field.label, value);

        if (error) {
          isValid = false;
          const el = this.$refs[field.ref]?.[0];
          if (el) {
            el.classList.add("error-validation");
          }
        }
      }

      return isValid;
    },
    resetForm() {
      Object.keys(this.formData).forEach((key) => {
        this.formData[key] = Array.isArray(this.formData[key]) ? [] : "";
      });
    },
    async saveAccount() {
      if (!this.validateForm()) return;
      try {
        await apiClient
          .post(`/leads-managment/accounts/${this.id}`, this.formData)
          .then((response) => {
            const contact_store = useContactsStore();
            contact_store?.updateContact(response.data.data);
            this.$emit("update:dialog", false);
          });
      } catch (error) {
        this.errors.push(error.response.data.msg);
        console.error(error.response.data.msg);
      }
    },
  },
  mounted() {
    this.getAccount();
    this.onBusinessTypeAutocompleteOpen();
  },
};
</script>
