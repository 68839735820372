import axios from "axios";
import config from "@/../config";
import { toast } from "vue3-toastify";

const token = localStorage.getItem("user_token");

const apiClient = axios.create({
  baseURL: config.api_base_url_stg,

  headers: {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    token: "Bearer " + token,
  },
  params: {
    time: new Date().getTime(),
  }, 
});

apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("user_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers.Lang = "en";
    return config;
  },
  (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.data) {
      toast(`${error.response.data.msg}`, {
        type: "error",
      });
    } else {
      toast("An unexpected error occurred. Please try again later.", {
        type: "error",
      });
    }
    return Promise.reject(error);
  }
);

export default apiClient;
