<template>
  <div class="show-more-options-container">
    <section class="mb-4">
      <label for="" class="d-block roboto-black">When you are charged </label>
      <v-select
        v-model="chargedSelectedItem"
        :items="chargedItems"
        density="compact"
        variant="outlined"
        return-object
        item-value="id"
        item-title="title"
        hide-details
      >
        <template v-slot:selection="{ item }">
          {{ item?.title || "" }}
        </template>
        <template v-slot:item="{ item }">
          <v-list-item @click="setCahrgedItem(item)">
            <div class="my-form">
              <div class="ga-3">
                <input
                  :checked="
                    chargedSelectedItem &&
                    chargedSelectedItem.id === item.raw.id
                  "
                  :id="item.raw.id"
                  type="radio"
                  name="website_conversion_option_5"
                  :value="item"
                />
                <div class="d-flex flex-column ga-1">
                  <label
                    :for="item.raw.id"
                    class="d-block"
                    style="font-size: 14px"
                    >{{ item.title }}</label
                  >
                </div>
              </div>
            </div>
          </v-list-item>
        </template>
      </v-select>
    </section>
    <section class="delivery-type-container">
      <div class="delivery-type">
        <div
          class="delivery-type-header d-flex align-center justify-space-between"
        >
          <label class="mb-1 roboto-black d-block" for="">Delivery type</label>
          <button disabled>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="20px"
              viewBox="0 -960 960 960"
              width="20px"
              fill="#000"
            >
              <path
                d="M216-216h51l375-375-51-51-375 375v51Zm-72 72v-153l498-498q11-11 23.84-16 12.83-5 27-5 14.16 0 27.16 5t24 16l51 51q11 11 16 24t5 26.54q0 14.45-5.02 27.54T795-642L297-144H144Zm600-549-51-51 51 51Zm-127.95 76.95L591-642l51 51-25.95-25.05Z"
              />
            </svg>
            <span>Edit</span>
          </button>
        </div>
        <p>Standard</p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      chargedSelectedItem: {
        id: 555,
        title: "Impressions",
        value: "IMPRESSIONS",
      },
      chargedItems: [
        {
          id: 555,
          title: "Impressions",
          value: "IMPRESSIONS",
        },
        {
          id: 666,
          title: "Link clicks (CPC)",
          value: "LINK_CLICKS",
        },
      ],
    };
  },
  methods:{
    setCahrgedItem(item) {
      this.chargedSelectedItem = item.raw;
      localStorage.setItem("charged_item", item.raw.value);
    },
  }
};
</script>
