<template>
  <v-row
    class="overview-row"
    style="display: flex; justify-content: space-between"
  >
    <v-col cols="12" :lg="8" class="overview-body"
      ><OverviewBody :item="item" :activities="activities"
    /></v-col>
    <v-col cols="12" :lg="4" class="lead-static-data d-flex flex-column ga-3">
      <StaticLeadData :sitem="item" />
    </v-col>
  </v-row>
</template>

<script>
import StaticLeadData from "@/components/crm/leads/comments/StaticLeadData.vue";
import OverviewBody from "@/components/crm/leads/comments/OverviewBody.vue";
export default {
  components: { StaticLeadData, OverviewBody },
  props: ["item", "activities"],
  name: "OverviewComp",
  date() {
    return {};
  },
  mounted() {},
  computed: {},
};
</script>
<style scoped>
@media (min-width: 1280px) {
  .overview-row .v-col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 64.666667%;
  }
}
.overview-body {
  padding: 0px !important;
}
.lead-static-data,
.overview-body {
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid #cccccc;
  height: 100%;
  overflow: auto;
}
.lead-static-data {
  padding: 15px !important;
  position: sticky;
}
</style>
