<template>
  <v-col :cols="12" :lg="6" class="d-flex ga-5 align-center">
    <div class="d-flex ga-1 align-center input-data-text">
      <DateIcon />
      <label class="calender-input-label text-capitalize"
        >Start Date & Time</label
      >
    </div>
    <div class="flat-pickr-input">
      <flat-pickr v-model="store.startDateTime" :config="dateTimeConfig" />
    </div>
  </v-col>
  <v-col :cols="12" :lg="6" class="d-flex ga-5 align-center">
    <div class="d-flex ga-1 align-center input-data-text">
      <DateIcon />
      <label class="calender-input-label text-capitalize"
        >End Date & Time</label
      >
    </div>
    <div class="flat-pickr-input">
      <flat-pickr v-model="store.endDateTime" :config="dateTimeConfig" />
    </div>
  </v-col>
</template>

<script>
import { useTaskStore } from "@/store/taskStore";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import DateIcon from "./icons/DateIcon.vue";

export default {
  name: "TaskDates",
  components: {
    flatPickr,
    DateIcon,
  },
  data() {
    return {
      store: useTaskStore(),
      dateTimeConfig: {
        altInput: true,
        altFormat: "F j, Y h:i K",
        dateFormat: "Y-m-d H:i",
        enableTime: true,
        time_24hr: false,
      },
    };
  },
};
</script>
