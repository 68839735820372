<template>
  <div class="map-container">
    <div id="map" style="height: 500px; width: 100%"></div>
    <div class="selected-regions d-none">
      <h3>Selected Regions</h3>
      <div v-if="selectedLocations.length === 0" class="no-regions">
        No regions selected. Draw a polygon on the map to select a region.
      </div>
      <div
        v-for="(location, index) in selectedLocations"
        :key="index"
        class="region-item"
      >
        <div class="location-info">
          <p><strong>Region:</strong> {{ location.region }}</p>
          <p><strong>Country:</strong> {{ location.country }}</p>
          <p><strong>Continent:</strong> {{ location.continent }}</p>
          <p><strong>Area:</strong> {{ location.area }} km²</p>
        </div>
        <button @click="removeLocation(index)" class="remove-btn">
          Remove
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";
import { reverseGeocode } from "@/utils/geocoding.js";

export default {
  name: "MapRegionSelector",
  data() {
    return {
      map: null,
      drawingManager: null,
      selectedLocations: [],
      selectedShape: null,
    };
  },
  mounted() {
    this.initializeMap();
  },
  methods: {
    async initializeMap() {
      try {
        const loader = new Loader({
          apiKey: "AIzaSyAYugMkEQTvSJa9xsKlA2ixqZD7UFCeOtU",
          version: "weekly",
          libraries: ["drawing", "geometry"],
        });

        const google = await loader.load();

        this.map = new google.maps.Map(document.getElementById("map"), {
          center: { lat: 20, lng: 0 },
          zoom: 2,
          mapTypeId: "terrain",
          mapTypeControl: true,
          fullscreenControl: true,
          streetViewControl: false,
        });

        this.drawingManager = new google.maps.drawing.DrawingManager({
          drawingMode: google.maps.drawing.OverlayType.POLYGON,
          drawingControl: true,
          drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [google.maps.drawing.OverlayType.POLYGON],
          },
          polygonOptions: {
            fillColor: "#FF0000",
            fillOpacity: 0.3,
            strokeWeight: 2,
            strokeColor: "#FF0000",
            editable: true,
            draggable: true,
          },
        });

        this.drawingManager.setMap(this.map);

        google.maps.event.addListener(
          this.drawingManager,
          "polygoncomplete",
          async (polygon) => {
            try {
              const bounds = new google.maps.LatLngBounds();
              polygon.getPath().forEach((coord) => bounds.extend(coord));

              const center = bounds.getCenter();
              const area = google.maps.geometry.spherical.computeArea(
                polygon.getPath()
              );
              const areaInKm = (area / 1000000).toFixed(2);

              const locationInfo = await reverseGeocode(
                center.lat(),
                center.lng()
              );

              this.selectedLocations.push({
                region: locationInfo.region,
                country: locationInfo.country,
                continent: locationInfo.continent,
                area: areaInKm,
                bounds: bounds,
                polygon: polygon,
              });

              let data = {
                region: locationInfo.region,
                country: locationInfo.country,
                continent: locationInfo.continent,
                area: areaInKm,
                bounds: bounds,
                polygon: polygon,
              };

              this.$emit("region-selected", data);

              google.maps.event.addListener(polygon.getPath(), "set_at", () =>
                this.updatePolygonArea(
                  polygon,
                  this.selectedLocations.length - 1
                )
              );
              google.maps.event.addListener(
                polygon.getPath(),
                "insert_at",
                () =>
                  this.updatePolygonArea(
                    polygon,
                    this.selectedLocations.length - 1
                  )
              );

              let selectedRegions = localStorage.getItem("selectedRegions");

              if (!selectedRegions) {
                selectedRegions = [];
              } else {
                selectedRegions = JSON.parse(selectedRegions);
              }

              selectedRegions.push({
                id: 1,
                label: locationInfo.region,
                code: locationInfo.region,
              });

              localStorage.setItem(
                "selectedRegions",
                JSON.stringify(selectedRegions)
              );
            } catch (error) {
              console.error("Error processing polygon:", error);
              alert("Error processing selected region. Please try again.");
            }
          }
        );
      } catch (error) {
        console.error("Error initializing map:", error);
        alert(
          "Error loading Google Maps. Please check your internet connection and try again."
        );
      }
    },
    updatePolygonArea(polygon, index) {
      const loader = new Loader({
        apiKey: "AIzaSyAYugMkEQTvSJa9xsKlA2ixqZD7UFCeOtU",
        version: "weekly",
        libraries: ["drawing", "geometry"],
      });
      const google = loader.load();
      const area = google.maps.geometry.spherical.computeArea(
        polygon.getPath()
      );
      const areaInKm = (area / 1000000).toFixed(2);
      this.selectedLocations[index].area = areaInKm;
    },
    removeLocation(index) {
      const location = this.selectedLocations[index];
      if (location.polygon) {
        location.polygon.setMap(null);
      }
      this.selectedLocations.splice(index, 1);
    },
  },
};
</script>

<style scoped>
.map-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.selected-regions {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.no-regions {
  text-align: center;
  color: #666;
  padding: 20px;
}

.region-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #eee;
  transition: background-color 0.2s;
}

.location-info p {
  margin: 5px 0;
}

.remove-btn {
  background-color: #ff4444;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.remove-btn:hover {
  background-color: #cc0000;
}

h3 {
  margin-top: 0;
  color: #333;
  font-size: 1.5em;
  margin-bottom: 20px;
}
</style>
