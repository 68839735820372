<template>
  <Layout :linksData="linksData">
    <v-row class="leads-managements w-100">
      <v-col cols="12" :lg="6" class="header-line-title"> tasks </v-col>
      <v-col cols="12" :lg="6" class="d-flex align-center justify-end ga-2">
        <Top :tasks="tasks" />
      </v-col>
    </v-row>
    <FilterSection
      :selected_rows="selected_tasks"
      @update:selected_rows="selected_tasks = $task"
      :menu="[]"
      :assign_to_account_menu="false"
      :parent="'tasks'"
      :dashboard_path_name="'TasksDashboard'"
    />
    <div class="table mt-3">
      <DataTable
        @update:selected_tasks="selected_tasks = $task"
        :tasks="tasks"
      />
    </div>
  </Layout>
</template>
<script>
import Layout from "@/components/crm/layouts/Layout.vue";
import activityLogIcon from "@/assets/images/icons/activity-log.svg";
import discussionsIcon from "@/assets/images/icons/discussions.svg";
import importIcon from "@/assets/images/icons/import.svg";
import FilterSection from "@/components/crm/assets/Filter.vue";
import Top from "@/components/crm/assets/Top.vue";
import DataTable from "@/components/crm/tasks/table.vue";
import "@/assets/css/crm/crm-moduls.css";
import { useTasksStore } from "@/store/crm/useTasksStore";

export default {
  components: {
    Layout,
    FilterSection,
    Top,
    DataTable,
  },

  data() {
    return {
      selected_tasks: [],
      activityLogIcon,
      discussionsIcon,
      importIcon,
      linksData: [
        { name: "Home", link: "/home-page" },
        { name: "tasks Management", link: "" },
      ],
      store: null,
    };
  },
  computed: {
    tasks() {
      if (this.store?.getTasks.length === 0) {
        this.store.getAllTasks();
      }
      return this.store?.getTasks || [];
    },
  },
  created() {
    this.store = useTasksStore();
    console.log(this.store.tasks);
  },
};
</script>
