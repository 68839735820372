<template>
  <div class="card-pattern audience-controls-card">
    <div class="card-header mb-2 d-flex align-center">
      <label for="" class="">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#007e59"
        >
          <path
            d="m423.23-309.85 268.92-268.92L650-620.92 423.23-394.15l-114-114L267.08-466l156.15 156.15ZM480.07-100q-78.84 0-148.21-29.92t-120.68-81.21q-51.31-51.29-81.25-120.63Q100-401.1 100-479.93q0-78.84 29.92-148.21t81.21-120.68q51.29-51.31 120.63-81.25Q401.1-860 479.93-860q78.84 0 148.21 29.92t120.68 81.21q51.31 51.29 81.25 120.63Q860-558.9 860-480.07q0 78.84-29.92 148.21t-81.21 120.68q-51.29 51.31-120.63 81.25Q558.9-100 480.07-100Zm-.07-60q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
          />
        </svg>
        <span class="roboto-black">Audience controls </span>
      </label>
    </div>
    <div class="card-body">
      <p>Set criteria for where ads for this campaign can be delivered.</p>

      <div class="alert-card">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="20px"
          viewBox="0 -960 960 960"
          width="20px"
          fill="#0a7abf"
        >
          <path
            d="M444-288h72v-240h-72v240Zm35.79-312q15.21 0 25.71-10.29t10.5-25.5q0-15.21-10.29-25.71t-25.5-10.5q-15.21 0-25.71 10.29t-10.5 25.5q0 15.21 10.29 25.71t25.5 10.5Zm.49 504Q401-96 331-126t-122.5-82.5Q156-261 126-330.96t-30-149.5Q96-560 126-629.5q30-69.5 82.5-122T330.96-834q69.96-30 149.5-30t149.04 30q69.5 30 122 82.5T834-629.28q30 69.73 30 149Q864-401 834-331t-82.5 122.5Q699-156 629.28-126q-69.73 30-149 30Zm-.28-72q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z"
          />
        </svg>
        <div class="alert-card-body">
          <p>
            You can set audience controls for this ad account to apply to all
            campaigns.
          </p>
          <a
            href="https://adsmanager.facebook.com/adsmanager/manage/advertising_settings/account_control?act=398124749382046&nav_entry_point=ads_asc_audience_location_section&nav_source=ads_manager"
          >
            See audience controls in Advertising settings
          </a>
        </div>
      </div>
    </div>

    <v-divider class="my-4"></v-divider>

    <MetaLocation />

    <v-divider class="my-4"></v-divider>

    <button class="show-more" @click="toggleShowMoreOptions">
      <div class="d-flex align-center" v-if="!moreOptions">
        <span>Show more options</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="20px"
          viewBox="0 -960 960 960"
          width="20px"
          fill="#0a7abf"
        >
          <path d="M480-384 288-576h384L480-384Z" />
        </svg>
      </div>
      <div class="d-flex align-center" v-else>
        <span>Hide options</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="20px"
          viewBox="0 -960 960 960"
          width="20px"
          fill="#0a7abf"
        >
          <path d="m288-384 192-192 192 192H288Z" />
        </svg>
      </div>
    </button>

    <AudienceAge v-if="moreOptions" />

  </div>
</template>

<script>
import MetaLocation from "./MetaLocation.vue";
import AudienceAge from "./AudienceAge.vue"
export default {
  components: {
    MetaLocation,
    AudienceAge
  },
  data() {
    return {
      moreOptions: false,
    };
  },
  methods: {
    toggleShowMoreOptions() {
      this.moreOptions = !this.moreOptions;
    },
  },
};
</script>
