<template>
  <v-app>
    <!-- Header -->
    <v-app-bar
      app
      elevation="0"
      class="px-3"
      color="transparent"
      :class="{ 'blur-bg': scrolled }"
    >
      <v-app-bar-title class="font-weight-bold">
        <span class="text-primary">Promxa</span> System
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn
        v-for="item in menuItems"
        :key="item.path"
        variant="text"
        :to="item.path"
        class="text-secondary font-weight-medium"
      >
        {{ item.name }}
      </v-btn>
    </v-app-bar>

    <!-- Main Content -->
    <v-main>
      <v-container class="py-16">
        <v-row>
          <v-col cols="12" md="8" offset-md="2">
            <div class="text-center mb-12">
              <span
                class="text-primary text-uppercase font-weight-medium"
                data-aos="fade-up"
                >Legal</span
              >
              <h1
                class="text-h3 font-weight-bold mt-2"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                Terms of Service
              </h1>
            </div>

            <v-card
              class="pa-8"
              elevation="4"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div class="terms-section">
                <h2 class="text-h5 font-weight-bold mb-4 text-primary">
                  1. Acceptance of Terms
                </h2>
                <p class="mb-6 text-medium-emphasis">
                  By accessing and using this website, you accept and agree to
                  be bound by the terms and provision of this agreement.
                </p>

                <h2 class="text-h5 font-weight-bold mb-4 text-primary">
                  2. Use License
                </h2>
                <p class="mb-6 text-medium-emphasis">
                  Permission is granted to temporarily download one copy of the
                  materials (information or software) on Promxa System's website
                  for personal, non-commercial transitory viewing only.
                </p>

                <h2 class="text-h5 font-weight-bold mb-4 text-primary">
                  3. Disclaimer
                </h2>
                <p class="mb-6 text-medium-emphasis">
                  The materials on Promxa System's website are provided on an
                  'as is' basis. Promxa System makes no warranties, expressed or
                  implied, and hereby disclaims and negates all other
                  warranties.
                </p>

                <h2 class="text-h5 font-weight-bold mb-4 text-primary">
                  4. Limitations
                </h2>
                <p class="mb-6 text-medium-emphasis">
                  In no event shall Promxa System or its suppliers be liable for
                  any damages arising out of the use or inability to use the
                  materials on Promxa System's website.
                </p>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <!-- Footer -->
    <v-footer app class="d-flex flex-column bg-primary">
      <div class="px-4 py-2 text-center w-100 text-white">
        {{ new Date().getFullYear() }} — <strong>Promxa System</strong>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "TermsView",
  data() {
    return {
      scrolled: false,
      menuItems: [
        { name: "Home", path: "/" },
        { name: "Terms", path: "/terms" },
        { name: "Policy", path: "/privacy" },
      ],
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrolled = window.scrollY > 50;
    },
  },
};
</script>

<style scoped>
.terms-section h2 {
  position: relative;
  padding-left: 20px;
}

.terms-section h2::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  height: 24px;
  background-color: #2e989e;
  border-radius: 2px;
}

.blur-bg {
  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.8) !important;
}
</style>
