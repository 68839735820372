import axios from "axios";

const GOOGLE_MAPS_API_KEY = "AIzaSyAYugMkEQTvSJa9xsKlA2ixqZD7UFCeOtU";

const continentMapping = {
  AF: "Africa",
  AS: "Asia",
  EU: "Europe",
  NA: "North America",
  SA: "South America",
  OC: "Oceania",
  AN: "Antarctica",
};

const countryToContinentCode = {
  AF: "AS", // Afghanistan
  AL: "EU", // Albania
  DZ: "AF", // Algeria
  AD: "EU", // Andorra
  AO: "AF", // Angola
  AG: "NA", // Antigua and Barbuda
  AR: "SA", // Argentina
  AM: "AS", // Armenia
  AU: "OC", // Australia
  AT: "EU", // Austria
  AZ: "AS", // Azerbaijan
  BS: "NA", // Bahamas
  BH: "AS", // Bahrain
  BD: "AS", // Bangladesh
  BB: "NA", // Barbados
  BY: "EU", // Belarus
  BE: "EU", // Belgium
  BZ: "NA", // Belize
  BJ: "AF", // Benin
  BT: "AS", // Bhutan
  BO: "SA", // Bolivia
  BA: "EU", // Bosnia and Herzegovina
  BW: "AF", // Botswana
  BR: "SA", // Brazil
  BN: "AS", // Brunei
  BG: "EU", // Bulgaria
  BF: "AF", // Burkina Faso
  BI: "AF", // Burundi
  KH: "AS", // Cambodia
  CM: "AF", // Cameroon
  CA: "NA", // Canada
  CV: "AF", // Cape Verde
  CF: "AF", // Central African Republic
  TD: "AF", // Chad
  CL: "SA", // Chile
  CN: "AS", // China
  CO: "SA", // Colombia
  KM: "AF", // Comoros
  CG: "AF", // Congo
  CR: "NA", // Costa Rica
  CI: "AF", // Côte d'Ivoire
  HR: "EU", // Croatia
  CU: "NA", // Cuba
  CY: "EU", // Cyprus
  CZ: "EU", // Czech Republic
  DK: "EU", // Denmark
  DJ: "AF", // Djibouti
  DM: "NA", // Dominica
  DO: "NA", // Dominican Republic
  EC: "SA", // Ecuador
  EG: "AF", // Egypt
  SV: "NA", // El Salvador
  GQ: "AF", // Equatorial Guinea
  ER: "AF", // Eritrea
  EE: "EU", // Estonia
  SZ: "AF", // Eswatini
  ET: "AF", // Ethiopia
  FJ: "OC", // Fiji
  FI: "EU", // Finland
  FR: "EU", // France
  GA: "AF", // Gabon
  GM: "AF", // Gambia
  GE: "AS", // Georgia
  DE: "EU", // Germany
  GH: "AF", // Ghana
  GR: "EU", // Greece
  GD: "NA", // Grenada
  GT: "NA", // Guatemala
  GN: "AF", // Guinea
  GW: "AF", // Guinea-Bissau
  GY: "SA", // Guyana
  HT: "NA", // Haiti
  HN: "NA", // Honduras
  HU: "EU", // Hungary
  IS: "EU", // Iceland
  IN: "AS", // India
  ID: "AS", // Indonesia
  IR: "AS", // Iran
  IQ: "AS", // Iraq
  IE: "EU", // Ireland
  IL: "AS", // Israel
  IT: "EU", // Italy
  JM: "NA", // Jamaica
  JP: "AS", // Japan
  JO: "AS", // Jordan
  KZ: "AS", // Kazakhstan
  KE: "AF", // Kenya
  KI: "OC", // Kiribati
  KP: "AS", // North Korea
  KR: "AS", // South Korea
  KW: "AS", // Kuwait
  KG: "AS", // Kyrgyzstan
  LA: "AS", // Laos
  LV: "EU", // Latvia
  LB: "AS", // Lebanon
  LS: "AF", // Lesotho
  LR: "AF", // Liberia
  LY: "AF", // Libya
  LI: "EU", // Liechtenstein
  LT: "EU", // Lithuania
  LU: "EU", // Luxembourg
  MG: "AF", // Madagascar
  MW: "AF", // Malawi
  MY: "AS", // Malaysia
  MV: "AS", // Maldives
  ML: "AF", // Mali
  MT: "EU", // Malta
  MH: "OC", // Marshall Islands
  MR: "AF", // Mauritania
  MU: "AF", // Mauritius
  MX: "NA", // Mexico
  FM: "OC", // Micronesia
  MD: "EU", // Moldova
  MC: "EU", // Monaco
  MN: "AS", // Mongolia
  ME: "EU", // Montenegro
  MA: "AF", // Morocco
  MZ: "AF", // Mozambique
  MM: "AS", // Myanmar
  NA: "AF", // Namibia
  NR: "OC", // Nauru
  NP: "AS", // Nepal
  NL: "EU", // Netherlands
  NZ: "OC", // New Zealand
  NI: "NA", // Nicaragua
  NE: "AF", // Niger
  NG: "AF", // Nigeria
  NO: "EU", // Norway
  OM: "AS", // Oman
  PK: "AS", // Pakistan
  PW: "OC", // Palau
  PS: "AS", // Palestine
  PA: "NA", // Panama
  PG: "OC", // Papua New Guinea
  PY: "SA", // Paraguay
  PE: "SA", // Peru
  PH: "AS", // Philippines
  PL: "EU", // Poland
  PT: "EU", // Portugal
  QA: "AS", // Qatar
  RO: "EU", // Romania
  RU: "EU", // Russia
  RW: "AF", // Rwanda
  KN: "NA", // Saint Kitts and Nevis
  LC: "NA", // Saint Lucia
  VC: "NA", // Saint Vincent and the Grenadines
  WS: "OC", // Samoa
  SM: "EU", // San Marino
  ST: "AF", // São Tomé and Príncipe
  SA: "AS", // Saudi Arabia
  SN: "AF", // Senegal
  RS: "EU", // Serbia
  SC: "AF", // Seychelles
  SL: "AF", // Sierra Leone
  SG: "AS", // Singapore
  SK: "EU", // Slovakia
  SI: "EU", // Slovenia
  SB: "OC", // Solomon Islands
  SO: "AF", // Somalia
  ZA: "AF", // South Africa
  SS: "AF", // South Sudan
  ES: "EU", // Spain
  LK: "AS", // Sri Lanka
  SD: "AF", // Sudan
  SR: "SA", // Suriname
  SE: "EU", // Sweden
  CH: "EU", // Switzerland
  SY: "AS", // Syria
  TW: "AS", // Taiwan
  TJ: "AS", // Tajikistan
  TZ: "AF", // Tanzania
  TH: "AS", // Thailand
  TL: "AS", // Timor-Leste
  TG: "AF", // Togo
  TO: "OC", // Tonga
  TT: "NA", // Trinidad and Tobago
  TN: "AF", // Tunisia
  TR: "AS", // Turkey
  TM: "AS", // Turkmenistan
  TV: "OC", // Tuvalu
  UG: "AF", // Uganda
  UA: "EU", // Ukraine
  AE: "AS", // United Arab Emirates
  GB: "EU", // United Kingdom
  US: "NA", // United States
  UY: "SA", // Uruguay
  UZ: "AS", // Uzbekistan
  VU: "OC", // Vanuatu
  VE: "SA", // Venezuela
  VN: "AS", // Vietnam
  YE: "AS", // Yemen
  ZM: "AF", // Zambia
  ZW: "AF", // Zimbabwe
  // Antarctica (research stations, no countries)
  AQ: "AN", // Antarctica
};

export async function reverseGeocode(lat, lng) {
  try {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_MAPS_API_KEY}`
    );

    if (response.data.status !== "OK") {
      throw new Error(`Geocoding failed: ${response.data.status}`);
    }

    const results = response.data.results;
    if (!results || results.length === 0) {
      throw new Error("No results found");
    }

    let locationInfo = {
      region: "Unknown",
      country: "Unknown",
      countryCode: "",
      continent: "Unknown",
    };

    // Process all results to find the most specific information
    for (const result of results) {
      for (const component of result.address_components) {
        // Get region (state/province)
        if (
          component.types.includes("administrative_area_level_1") &&
          locationInfo.region === "Unknown"
        ) {
          locationInfo.region = component.long_name;
        }
        // Get country
        if (component.types.includes("country")) {
          locationInfo.country = component.long_name;
          locationInfo.countryCode = component.short_name;
        }
      }
    }

    // Determine continent based on country code
    if (locationInfo.countryCode) {
      const continentCode = countryToContinentCode[locationInfo.countryCode];
      if (continentCode) {
        locationInfo.continent = continentMapping[continentCode];
      }
    }

    return locationInfo;
  } catch (error) {
    console.error("Error in reverse geocoding:", error);
    return {
      region: "Unknown",
      country: "Unknown",
      continent: "Unknown",
    };
  }
}
