import CalenderIndex from "@/views/calender/CalenderIndex.vue";
import CreateTask from "@/views/calender/CreateTask.vue";
const CalenderRoutes = [
  {
    path: "/calender",
    name: "Calender",
    component: CalenderIndex,
    meta: {
      title: "calender",
      description: "",
    },
  },
  {
    path: "/create-task",
    name: "AddTask",
    component: CreateTask,
    meta: {
      title: "add task",
      description: "",
    },
  },
  
  
];


export default CalenderRoutes;
