<template>
  <div class="profile-box-container w-100">
    <div class="mb-3">
      <label for="" class="profile-box-title">Profile Picture</label>
    </div>
    <div class="d-flex align-items-center justify-space-between py-5">
      <div class="profile-box-second-text">Name</div>
      <div class="profile-box-second-text">
        {{ profile.first_name + " " + profile.last_name }}
      </div>
    </div>
    <v-divider></v-divider>
    <div class="d-flex align-items-center justify-space-between py-5">
      <div class="profile-box-second-text">Date of Birth</div>
      <div class="profile-box-second-text">{{ profile.date_of_birth }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "BasicInfo",
  components: {},
  props: ["profile"],
  data() {
    return {};
  },
  beforeCreate() {},
  methods: {},
};
</script>
