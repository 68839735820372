<template>
  <div class="d-flex align-center justify-space-between folder-header">
    <div class="icon-name d-flex align-center ga-2">
      <div class="icon"><img :src="folder.iconLink" alt="" /></div>
      <div
        @click="
          () =>
            $router.push({
              name: 'AllFolderFiles',
              params: { folderId: folder.id },
            })
        "
        v-if="!rename"
        class="name cursor-pointer"
      >
        {{ folder.file_name }}
      </div>
      <div v-if="rename" class="new-form-input d-flex ga-2">
        <Input v-model="file_name" type="text" placeholder="File Name" />
        <v-btn
          variant="text"
          :color="'white'"
          class="upload-btn"
          @click="RenameFile()"
          >rename</v-btn
        >
      </div>
    </div>
    <div class="options">
      <div class="menu">
        <v-menu>
          <template v-slot:activator="{ props }">
            <v-btn
              :ripple="false"
              style="width: 10px"
              icon="mdi-dots-vertical"
              variant="text"
              v-bind="props"
            ></v-btn>
          </template>

          <v-list>
            <v-list-item @click="rename = !rename">
              <div class="d-flex ga-2 align-center">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 14.6584H15M11.5 1.82505C11.8094 1.51563 12.2291 1.3418 12.6667 1.3418C12.8833 1.3418 13.0979 1.38447 13.2981 1.46739C13.4982 1.55031 13.6801 1.67184 13.8333 1.82505C13.9865 1.97825 14.1081 2.16014 14.191 2.36032C14.2739 2.56049 14.3166 2.77504 14.3166 2.99171C14.3166 3.20838 14.2739 3.42293 14.191 3.62311C14.1081 3.82329 13.9865 4.00517 13.8333 4.15838L4.11111 13.8806L1 14.6584L1.77778 11.5473L11.5 1.82505Z"
                    stroke="#4D4D4D"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span>{{ rename ? "Close" : "Rename" }}</span>
              </div>
            </v-list-item>
            <v-list-item @click="Download">
              <div class="d-flex ga-2 align-center">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 10.3333V13.4444C15 13.857 14.8361 14.2527 14.5444 14.5444C14.2527 14.8361 13.857 15 13.4444 15H2.55556C2.143 15 1.74733 14.8361 1.45561 14.5444C1.16389 14.2527 1 13.857 1 13.4444V10.3333M4.11111 6.44444L8 10.3333M8 10.3333L11.8889 6.44444M8 10.3333V1"
                    stroke="#4D4D4D"
                    stroke-width="1.4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <span>Download</span>
              </div>
            </v-list-item>
            <v-list-item @click="deleteDialog = true">
              <div class="d-flex ga-2 align-center">
                <svg
                  width="16"
                  height="18"
                  viewBox="0 0 16 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.4 4.2V3.56C11.4 2.66392 11.4 2.21587 11.2256 1.87362C11.0722 1.57256 10.8274 1.32779 10.5264 1.17439C10.1841 1 9.73608 1 8.84 1H7.56C6.66392 1 6.21587 1 5.87361 1.17439C5.57256 1.32779 5.32779 1.57256 5.17439 1.87362C5 2.21587 5 2.66392 5 3.56V4.2M6.6 8.6V12.6M9.8 8.6V12.6M1 4.2H15.4M13.8 4.2V13.16C13.8 14.5041 13.8 15.1762 13.5384 15.6896C13.3083 16.1412 12.9412 16.5083 12.4896 16.7384C11.9762 17 11.3041 17 9.96 17H6.44C5.09587 17 4.42381 17 3.91042 16.7384C3.45883 16.5083 3.09168 16.1412 2.86158 15.6896C2.6 15.1762 2.6 14.5041 2.6 13.16V4.2"
                    stroke="#CC0000"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span class="text-red-2">Delete</span>
              </div>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </div>
  <div class="d-flex align-center justify-space-between folder-footer">
    <div class="number">{{ folder?.fileCount + " Files" }}</div>
    <div class="size">{{ folder?.totalSize + " MB" }}</div>
  </div>
  <v-snackbar
    v-model="snackbar.show"
    :timeout="snackbar.timeout"
    :color="snackbar.color"
    location="top"
    :close-on-content-click="true"
  >
    {{ snackbar.message }}
  </v-snackbar>
  <v-dialog v-model="deleteDialog" width="auto">
    <v-card max-width="550">
      <template v-slot:text>
        <div>
          <div class="d-flex justify-space-between align-center mb-8">
            <svg
              width="56"
              height="57"
              viewBox="0 0 56 57"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="4"
                y="4.5"
                width="48"
                height="48"
                rx="24"
                fill="#FEE4E2"
              />
              <rect
                x="4"
                y="4.5"
                width="48"
                height="48"
                rx="24"
                stroke="#FEF3F2"
                stroke-width="8"
              />
              <path
                d="M32 22.5V21.7C32 20.5799 32 20.0198 31.782 19.592C31.5903 19.2157 31.2843 18.9097 30.908 18.718C30.4802 18.5 29.9201 18.5 28.8 18.5H27.2C26.0799 18.5 25.5198 18.5 25.092 18.718C24.7157 18.9097 24.4097 19.2157 24.218 19.592C24 20.0198 24 20.5799 24 21.7V22.5M26 28V33M30 28V33M19 22.5H37M35 22.5V33.7C35 35.3802 35 36.2202 34.673 36.862C34.3854 37.4265 33.9265 37.8854 33.362 38.173C32.7202 38.5 31.8802 38.5 30.2 38.5H25.8C24.1198 38.5 23.2798 38.5 22.638 38.173C22.0735 37.8854 21.6146 37.4265 21.327 36.862C21 36.2202 21 35.3802 21 33.7V22.5"
                stroke="#CC0000"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <svg
              @click="deleteDialog = false"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="cursor-pointer hoverd"
            >
              <path
                d="M18 6L6 18M6 6L18 18"
                stroke="#717680"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="delete-dialog-body">
            <div class="main mb-2">
              Are you sure you want to delete this item
            </div>
            <div class="sub">
              If you deleted this item you won’t be able to restore it again
            </div>
          </div>
        </div>
      </template>
      <template v-slot:actions>
        <div class="pa-4 d-flex align-center ga-3">
          <v-btn
            class="cancel-btn"
            text="Cancel"
            @click="deleteDialog = false"
          ></v-btn>
          <v-btn
            class="delete-btn text-capitalize"
            color="white"
            text="Delete"
            @click="confirmDelete"
          ></v-btn>
        </div>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import Input from "@/components/crm/assets/Input.vue";
import apiClient from "@/core/buildRequest";
import { DriveStore } from "@/store/drive/DriveStore.js";

export default {
  props: ["folder"],
  name: "FolderBox",
  components: { Input },
  data() {
    return {
      rename: false,
      file_name: "",
      snackbar: {
        color: "",
        show: false,
        message: "",
        timeout: 3000,
      },
      store: DriveStore(),
      deleteDialog: false,
    };
  },
  methods: {
    RenameFile() {
      if (this.file_name === "") {
        this.showSnackbar("Folder name is required", "error");
        return;
      }
      apiClient
        .post(`/my-drive/rename-file/${this.folder.id}`, {
          name: this.file_name,
        })
        .then((response) => {
          this.showSnackbar(
            `Name update From "${this.folder.name}" To "${this.file_name}"`,
            "success"
          );
          this.store?.updateItemName(this.folder.file_id, this.file_name);
          this.file_name = "";
          this.rename = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    Download() {
      const folder = this.folder;
      window.open(folder.webViewLink, "_blank");
    },
    showSnackbar(message, color) {
      this.snackbar.message = message;
      this.snackbar.color = color;
      this.snackbar.show = true;
    },
    confirmDelete() {
      apiClient
        .get(`/my-drive/delete-file/${this.folder.file_id}`)
        .then((response) => {
          this.showSnackbar("File deleted successfully", "success");
          this.store.deleteItem(this.folder.file_id);
          this.deleteDialog = false;
        })
        .catch((error) => {
          console.error(error);
          this.showSnackbar("Failed to delete the file", "error");
        });
    },
  },
};
</script>
