<template>
  <div class="profile-box-container w-100">
    <div class="d-flex justify-space-between align-center">
      <label for="" class="profile-box-title">Payment Methods</label>
      <span
        class="profile-box-link d-flex ga-2 align-center"
        @click="$router.push('/my-account/new-payment-method')"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.33398 8.66536H3.33398V7.33203H7.33398V3.33203H8.66732V7.33203H12.6673V8.66536H8.66732V12.6654H7.33398V8.66536Z"
            fill="#237276"
          />
        </svg>
        <span>New Payment Method</span>
      </span>
    </div>
    <div
      class="d-flex align-center justify-space-between py-5 single-payment-method"
    >
      <div class="info d-flex align-center ga-4">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_5334_123240)">
            <path
              d="M24 18.6769C24 19.1689 23.6016 19.5672 23.1097 19.5672H0.890124C0.398495 19.5672 0 19.1689 0 18.6769V5.32386C0 4.83223 0.398495 4.43359 0.890124 4.43359H23.1097C23.6017 4.43359 24 4.83223 24 5.32386L24 18.6769Z"
              fill="#21246E"
            />
            <path
              d="M9.12272 8.81508L6.45235 15.1872H4.70939L3.39622 10.1024C3.31536 9.78883 3.24626 9.67475 3.00387 9.5416C2.60682 9.3269 1.95366 9.1255 1.37695 9.00008L1.41729 8.81512H4.22146C4.5791 8.81512 4.90063 9.05368 4.98149 9.46491L5.67583 13.153L7.39087 8.81512L9.12272 8.81508ZM15.9499 13.1058C15.9576 11.4249 13.6241 11.3334 13.6409 10.5815C13.6454 10.3519 13.863 10.109 14.3394 10.0477C14.576 10.0162 15.2271 9.99318 15.9651 10.3319L16.2543 8.98134C15.8573 8.83724 15.3469 8.69922 14.7126 8.69922C13.0834 8.69922 11.9365 9.56578 11.9268 10.8057C11.9161 11.7226 12.7451 12.2342 13.3703 12.5396C14.0124 12.8521 14.2271 13.0516 14.2248 13.3318C14.2206 13.7594 13.7125 13.9464 13.2387 13.9547C12.4097 13.9677 11.9289 13.7307 11.5449 13.5519L11.2465 14.949C11.6311 15.1255 12.3432 15.2789 13.0802 15.2879C14.8116 15.2879 15.9444 14.4305 15.9499 13.1058ZM20.2522 15.1871H21.7771L20.4465 8.81503H19.0388C18.7224 8.81503 18.4558 8.99952 18.337 9.28211L15.8649 15.1871H17.5952L17.9386 14.2355H20.0536L20.2522 15.1871ZM18.4128 12.9295L19.2808 10.5369L19.7802 12.9295H18.4128ZM11.4784 8.81508L10.1146 15.1871H8.46586L9.83004 8.81503H11.4784V8.81508Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_5334_123240">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <span class="d-flex flex-column ga-1">
          <span class="profile-box-main-text">Visa ending in 4912</span>
          <span class="profile-box-second-text">Expires 02/2026</span>
        </span>
      </div>
      <div class="">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 12L10 8L6 4"
            stroke="#1E1E1E"
            stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
    <v-divider></v-divider>
    <div
      class="d-flex align-center justify-space-between py-5 single-payment-method"
    >
      <div class="info d-flex align-center ga-4">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_5334_123253)">
            <path
              d="M0.371094 12.0008C0.371094 5.57891 5.57891 0.371094 12.0008 0.371094C18.4227 0.371094 23.6305 5.57891 23.6305 12.0008C23.6305 18.4227 18.4227 23.6305 12.0008 23.6305C5.57891 23.6305 0.371094 18.4227 0.371094 12.0008Z"
              fill="#3C80AD"
            />
            <path
              d="M8.19961 6.35547C5.26523 6.37422 2.88867 8.76484 2.88867 11.7133C2.88867 14.6711 5.28398 17.0664 8.23242 17.0664C9.61523 17.0664 10.8762 16.5367 11.8277 15.6695C12.0246 15.4914 12.2027 15.2992 12.3715 15.0977H11.2746C11.1293 14.9195 10.9934 14.7367 10.8762 14.5445H12.7652C12.8824 14.3617 12.9855 14.1695 13.0746 13.9727H10.5621C10.4777 13.7898 10.4027 13.6023 10.3371 13.4102H13.2949C13.473 12.8758 13.5715 12.3039 13.5715 11.7133C13.5715 11.3195 13.5293 10.9352 13.4496 10.5648H10.1824C10.2246 10.3727 10.2762 10.1852 10.3324 10.0023H13.2902C13.2246 9.80547 13.1449 9.61328 13.0605 9.43047H10.5574C10.6465 9.23828 10.7496 9.05078 10.8621 8.86797H12.7512C12.6246 8.66641 12.4887 8.47422 12.3387 8.29609H11.2887C11.4527 8.10391 11.6309 7.92109 11.823 7.75703C10.8715 6.88984 9.61055 6.36016 8.22773 6.36016C8.22305 6.35547 8.21367 6.35547 8.19961 6.35547Z"
              fill="#931B1D"
            />
            <path
              d="M20.748 11.7125C20.748 14.6703 18.3574 17.0703 15.4043 17.0703C12.4512 17.0703 10.0605 14.675 10.0605 11.7172C10.0605 8.75938 12.4512 6.35938 15.4043 6.35938C18.3527 6.35469 20.748 8.75001 20.748 11.7125Z"
              fill="#F16622"
            />
            <path
              d="M20.748 11.7758C20.748 14.7336 18.3574 17.1336 15.4043 17.1336C12.4512 17.1336 10.0605 14.7383 10.0605 11.7805V11.7758C10.0605 8.81797 12.4512 6.41797 15.4043 6.41797C18.3527 6.41797 20.748 8.81328 20.748 11.7758C20.748 11.7711 20.748 11.7711 20.748 11.7758Z"
              fill="#F7981D"
            />
            <path
              d="M8.19961 6.41797C5.26523 6.43672 2.88867 8.82734 2.88867 11.7758C2.88867 14.7336 5.28398 17.1336 8.23242 17.1336C9.61523 17.1336 10.8762 16.6039 11.8277 15.7367C12.0246 15.5586 12.2027 15.3664 12.3715 15.1648H11.2746C11.1293 14.9867 10.9934 14.8039 10.8762 14.6117H12.7652C12.8824 14.4289 12.9855 14.2367 13.0746 14.0398H10.5621C10.4777 13.857 10.4027 13.6695 10.3371 13.4773H13.2949C13.473 12.943 13.5715 12.3711 13.5715 11.7805C13.5715 11.3867 13.5293 11.0023 13.4496 10.632H10.1824C10.2246 10.4398 10.2762 10.2523 10.3324 10.0695H13.2902C13.2246 9.87266 13.1449 9.68047 13.0605 9.49766H10.5574C10.6465 9.30547 10.7496 9.11797 10.8621 8.93516H12.7512C12.6246 8.73359 12.4887 8.54141 12.3387 8.36328H11.2887C11.4527 8.17109 11.6309 7.98828 11.823 7.82422C10.8715 6.95703 9.61055 6.42734 8.22773 6.42734C8.22305 6.41797 8.21367 6.41797 8.19961 6.41797Z"
              fill="#CB2026"
            />
            <path
              d="M10.1547 13.1398L10.225 12.657C10.1875 12.657 10.1266 12.6758 10.0797 12.6758C9.88281 12.6758 9.85938 12.5727 9.87344 12.493L10.0328 11.5039H10.3328L10.4031 10.9695H10.1172L10.1734 10.6367H9.61094C9.59687 10.6508 9.27344 12.5117 9.27344 12.7367C9.27344 13.0742 9.46094 13.2242 9.72812 13.2195C9.93906 13.2102 10.0984 13.1539 10.1547 13.1398Z"
              fill="white"
            />
            <path
              d="M10.3379 12.2109C10.3379 13.0172 10.8676 13.2094 11.3223 13.2094C11.7395 13.2094 11.9223 13.1156 11.9223 13.1156L12.0207 12.5672C12.0207 12.5672 11.702 12.7078 11.416 12.7078C10.8066 12.7078 10.9145 12.2531 10.9145 12.2531H12.0723C12.0723 12.2531 12.1473 11.8828 12.1473 11.7328C12.1473 11.3578 11.9598 10.8984 11.3363 10.8984C10.7598 10.8984 10.3379 11.5125 10.3379 12.2109ZM11.3363 11.4094C11.6551 11.4094 11.5988 11.7703 11.5988 11.7984H10.966C10.966 11.7609 11.027 11.4094 11.3363 11.4094Z"
              fill="white"
            />
            <path
              d="M14.9355 13.1383L15.0387 12.5148C15.0387 12.5148 14.7574 12.6555 14.5699 12.6555C14.1668 12.6555 14.0027 12.3461 14.0027 12.0133C14.0027 11.3383 14.3496 10.968 14.7387 10.968C15.0293 10.968 15.259 11.132 15.259 11.132L15.3527 10.5273C15.3527 10.5273 15.0059 10.3867 14.7105 10.3867C14.0543 10.3867 13.4121 10.9586 13.4121 12.032C13.4121 12.7445 13.759 13.2133 14.4387 13.2133C14.6309 13.2133 14.9355 13.1383 14.9355 13.1383Z"
              fill="white"
            />
            <path
              d="M7.02812 10.9062C6.63906 10.9062 6.33906 11.0328 6.33906 11.0328L6.25469 11.525C6.25469 11.525 6.50313 11.4266 6.87344 11.4266C7.08438 11.4266 7.23906 11.45 7.23906 11.6234C7.23906 11.7266 7.22031 11.7688 7.22031 11.7688C7.22031 11.7688 7.05156 11.7547 6.97656 11.7547C6.48438 11.7547 5.96875 11.9656 5.96875 12.5984C5.96875 13.1 6.30625 13.2125 6.51719 13.2125C6.92031 13.2125 7.08906 12.95 7.10312 12.95L7.08438 13.1703H7.58594L7.81094 11.6C7.81094 10.925 7.22969 10.9062 7.02812 10.9062ZM7.15 12.1812C7.15937 12.2797 7.08906 12.725 6.74687 12.725C6.56875 12.725 6.52188 12.5891 6.52188 12.5094C6.52188 12.3547 6.60625 12.1672 7.02344 12.1672C7.12188 12.1672 7.13125 12.1812 7.15 12.1812Z"
              fill="white"
            />
            <path
              d="M8.34062 13.193C8.46719 13.193 9.20312 13.2258 9.20312 12.4664C9.20312 11.7586 8.52344 11.8992 8.52344 11.6133C8.52344 11.4727 8.63594 11.4258 8.8375 11.4258C8.91719 11.4258 9.22656 11.4539 9.22656 11.4539L9.29688 10.9523C9.29688 10.9523 9.09531 10.9102 8.77188 10.9102C8.35 10.9102 7.92344 11.0789 7.92344 11.6555C7.92344 12.307 8.63594 12.2414 8.63594 12.518C8.63594 12.7008 8.43906 12.7148 8.28437 12.7148C8.01719 12.7148 7.77812 12.6258 7.77812 12.6258L7.70312 13.1227C7.71719 13.1273 7.8625 13.193 8.34062 13.193Z"
              fill="white"
            />
            <path
              d="M19.652 10.4531L19.5301 11.2172C19.5301 11.2172 19.3145 10.9219 18.9816 10.9219C18.4613 10.9219 18.0254 11.55 18.0254 12.2766C18.0254 12.7453 18.2551 13.2 18.7332 13.2C19.0754 13.2 19.2629 12.9609 19.2629 12.9609L19.2395 13.1625H19.7926L20.2285 10.4531H19.652ZM19.3895 11.9438C19.3895 12.2438 19.2395 12.6469 18.9301 12.6469C18.7238 12.6469 18.6301 12.4734 18.6301 12.2062C18.6301 11.7656 18.827 11.4703 19.0801 11.4703C19.2816 11.4703 19.3895 11.6109 19.3895 11.9438Z"
              fill="white"
            />
            <path
              d="M3.94883 13.1672L4.2957 11.1187L4.34727 13.1672H4.74102L5.47227 11.1187L5.14883 13.1672H5.73008L6.18008 10.4531H5.28008L4.71758 12.1172L4.68477 10.4531H3.85508L3.40039 13.1672H3.94883Z"
              fill="white"
            />
            <path
              d="M12.7148 13.1703C12.8789 12.2281 12.9117 11.4594 13.3055 11.6C13.3758 11.2344 13.4414 11.0937 13.5164 10.9391C13.5164 10.9391 13.4789 10.9297 13.4086 10.9297C13.1555 10.9297 12.9633 11.2766 12.9633 11.2766L13.0148 10.9578H12.4852L12.1289 13.1656H12.7148V13.1703Z"
              fill="white"
            />
            <path
              d="M16.2527 10.9062C15.8637 10.9062 15.5637 11.0328 15.5637 11.0328L15.4793 11.525C15.4793 11.525 15.7277 11.4266 16.098 11.4266C16.309 11.4266 16.4637 11.45 16.4637 11.6234C16.4637 11.7266 16.4449 11.7688 16.4449 11.7688C16.4449 11.7688 16.2762 11.7547 16.2012 11.7547C15.709 11.7547 15.1934 11.9656 15.1934 12.5984C15.1934 13.1 15.5309 13.2125 15.7418 13.2125C16.1449 13.2125 16.3184 12.95 16.323 12.95L16.3043 13.1703H16.8059L17.0309 11.6C17.0309 10.925 16.4543 10.9062 16.2527 10.9062ZM16.3746 12.1812C16.384 12.2797 16.3137 12.725 15.9715 12.725C15.7934 12.725 15.7512 12.5891 15.7512 12.5094C15.7512 12.3547 15.8355 12.1672 16.2527 12.1672C16.3465 12.1672 16.3559 12.1812 16.3746 12.1812Z"
              fill="white"
            />
            <path
              d="M17.4902 13.1703C17.6543 12.2281 17.6871 11.4594 18.0809 11.6C18.1512 11.2344 18.2168 11.0937 18.2918 10.9391C18.2918 10.9391 18.2543 10.9297 18.184 10.9297C17.9309 10.9297 17.7387 11.2766 17.7387 11.2766L17.7902 10.9578H17.2605L16.9043 13.1656H17.4902V13.1703Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_5334_123253">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>

        <span class="d-flex flex-column ga-1">
          <span class="profile-box-main-text">Visa ending in 4912</span>
          <span class="profile-box-second-text">Expires 02/2026</span>
        </span>
      </div>
      <div class="">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 12L10 8L6 4"
            stroke="#1E1E1E"
            stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
    <v-divider></v-divider>
    <div
      class="d-flex align-center justify-space-between py-5 single-payment-method"
    >
      <div class="info d-flex align-center ga-4">
        <svg
          width="21"
          height="24"
          viewBox="0 0 21 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.3327 6.09766C18.3327 6.09766 17.7689 6.39803 17.7389 6.54822C16.7777 11.5945 13.9172 12.979 9.62187 12.979L7.42915 12.9443C6.8885 12.9443 6.14932 13.6972 6.05923 14.2078L4.9258 20.807L5.23639 21.3267L4.93601 23.3692C4.87592 23.6696 5.0862 23.9699 5.41662 24C5.44667 24 5.47672 24 5.50672 24H9.38154C9.8321 24 10.2226 23.6696 10.3127 23.219L10.3428 23.0087C10.3428 23.0087 11.0627 18.3894 11.0636 18.383C11.1482 17.8406 11.4476 17.3617 12.0549 17.3617H12.6256C16.3802 17.3617 19.3239 15.8298 20.195 11.4143C20.5555 9.58196 20.3753 8.02 19.414 6.93869C19.1137 6.57822 18.7532 6.30789 18.3327 6.09766Z"
            fill="#009BD9"
          />
          <path
            d="M17.3869 5.12262C16.4404 4.81747 15.4127 4.77734 14.4257 4.77734H8.56837C8.11781 4.77734 7.32975 5.37008 7.23961 5.82064L5.91211 13.9685L6.37561 14.2088C6.4657 13.6981 6.91626 13.3076 7.42692 13.3076H9.61963C13.915 13.3076 17.2603 11.5715 18.2515 6.52517C18.2816 6.37498 18.2998 6.26234 18.3305 6.09865C18.3305 6.09865 18.0159 5.39084 17.7592 5.26001C17.6398 5.20972 17.5149 5.16392 17.3869 5.12262Z"
            fill="#192A67"
          />
          <path
            d="M7.6688 6.12763C7.72889 5.67707 8.11936 5.34665 8.59997 5.34665H14.4272C15.1181 5.34665 15.7789 5.40674 16.3496 5.49683C16.7401 5.55693 17.1306 5.64702 17.5211 5.76716C17.8214 5.85725 18.0918 5.97744 18.3321 6.09758C18.6325 4.23524 18.3321 2.9737 17.3108 1.8022C16.2295 0.540655 14.2169 0 11.6337 0H4.18445C3.67379 0 3.22323 0.390468 3.13314 0.901123L0.00920451 20.6057C-0.0508891 20.9662 0.189439 21.2965 0.54986 21.3266C0.579906 21.3266 0.609953 21.3266 0.639953 21.3266H5.23571L6.40716 13.9975L7.6688 6.12763Z"
            fill="#0A3B82"
          />
        </svg>

        <span class="d-flex flex-column ga-1">
          <span class="profile-box-main-text">Visa ending in 4912</span>
          <span class="profile-box-second-text">Expires 02/2026</span>
        </span>
      </div>
      <div class="">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 12L10 8L6 4"
            stroke="#1E1E1E"
            stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "BasicInfo",
  components: {},
  props: ["profile"],
  data() {
    return {};
  },
  beforeCreate() {},
  methods: {},
};
</script>
