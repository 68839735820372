<template>
  <div class="meta-ad-creative-body-content-media">
    <div class="header">
      <label for="" class="roboto-bold">* Media </label>
      <div class="d-flex ga-2 align-center">
        <v-select
          v-model="selectedStatusMediaTypeArray"
          :items="statusMediaTypeArray"
          density="compact"
          variant="outlined"
          return-object
          item-value="id"
          item-title="title"
          hide-details
        >
        </v-select>
        <button class="remove-btn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="20px"
            viewBox="0 -960 960 960"
            width="20px"
            fill="#434343"
          >
            <path
              d="M312-144q-29.7 0-50.85-21.15Q240-186.3 240-216v-480h-48v-72h192v-48h192v48h192v72h-48v479.57Q720-186 698.85-165T648-144H312Zm336-552H312v480h336v-480ZM384-288h72v-336h-72v336Zm120 0h72v-336h-72v336ZM312-696v480-480Z"
            />
          </svg>
        </button>
      </div>
    </div>
    <div class="ad-creative-body-media">
      <p>7 placements</p>

      <div>
        <img :src="mediaPreviewImage" alt="" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props:{
    mediaPreviewImage: {
      type: String,
      required: true,
    }
  },
    data() {

    return {
      selectedStatusMediaTypeArray: {
        id: 111,
        title: "Edit media",
        value: "Edit",
      },
      statusMediaTypeArray: [
        {
          id: 111,
          title: "Edit media",
          value: "Edit",
        },
        {
          id: 2222,
          title: "Turn into video",
          value: "turn_into_video",
        },
      ],

    };
  },
  methods: {
    setAdSelected_m(item) {
      this.selectedStatusMediaTypeArray = item.raw;
      localStorage.setItem("selectedStatusMediaTypeArray", item.raw.value);
    },
  },
  mounted() {
    console.log(this.mediaPreviewImage);
    
  }

};
</script>
