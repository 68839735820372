<template>
  <div>
    <div class="campaign-name">
      <label for="" class="d-block">{{ this.labeltext }}</label>
      <input
        :type="type"
        :value="campaignName"
        :v-model="campaignName"
        name=""
        id=""
        :placeholder="placeHolder"
        @input="updateValue"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    campaignName: {
      // type: String,
      // required: true,
    },
    labeltext: {
      // type: String,
      // required: true,
    },
    type: {
      // type: Number,
      // required: true,
    },
    placeHolder: {
      // type: String,
    },
  },
  data() {
    return {
      label: "",
    };
  },
  methods: {
    updateValue(event) {
      this.$emit("update:campaignName", event.target.value);
    },
  },
};
</script>

<style scoped>
.campaign-name label {
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 600;
}
.campaign-name input {
  background-color: #f9f9fa;
  margin: 0;
  border-radius: 8px;
  font-size: 14px;
}
.campaign-name input:focus {
  outline: 1px solid var(--main-color);
}
</style>
