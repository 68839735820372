<template>
  <div
    v-if="idChanged != file.id"
    :class="['file-name', file.type === 'folder' ? 'cursor-pointer' : '']"
  >
    {{ truncatedFileName }}
  </div>
  <div v-if="idChanged === file.id" class="new-form-input d-flex ga-2">
    <Input v-model="file_name" type="text" placeholder="File Name" />
    <v-btn variant="text" color="white" class="upload-btn" @click="RenameFile">
      Rename
    </v-btn>
  </div>
  <v-snackbar
    v-model="snackbar.show"
    :timeout="snackbar.timeout"
    :color="snackbar.color"
    location="top"
    close-on-content-click
  >
    {{ snackbar.message }}
  </v-snackbar>
</template>

<script>
import Input from "@/components/crm/assets/Input.vue";
import { DriveStore } from "@/store/drive/DriveStore";
import apiClient from "@/core/buildRequest";

export default {
  name: "NameComnponent",
  data() {
    return {
      file_name: "",
      snackbar: {
        color: "",
        show: false,
        message: "",
        timeout: 3000,
      },
      store: DriveStore(),
    };
  },
  props: ["file", "idChanged"],
  components: { Input },
  computed: {
    truncatedFileName() {
      return this.file?.file_name.length > 20
        ? `${this.file.file_name.slice(0, 20)}...`
        : this.file.file_name;
    },
  },
  methods: {
    RenameFile() {
      if (this.file_name === "") {
        this.showSnackbar("Folder name is required", "error");
        return;
      }
      apiClient
        .post(`/my-drive/rename-file/${this.file.file_id}`, {
          name: this.file_name,
        })
        .then((response) => {
          this.store.updateItemName(this.file.file_id, this.file_name);
          this.showSnackbar(
            `Name updated from "${this.file.file_name}" to "${this.file_name}"`,
            "success"
          );
          this.$emit("close:rename", null);
          this.file_name = "";
        })
        .catch((error) => {
          console.error(error);
          this.showSnackbar("Failed to rename the file", "error");
        });
    },
    showSnackbar(message, color) {
      Object.assign(this.snackbar, { message, color, show: true });
    },
  },
  watch: {},
};
</script>
