<template>
  <div class="card-pattern a-b-test">
    <div class="card-header d-flex align-center justify-space-between">
      <label for="">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#007e59"
        >
          <path
            d="m423.23-309.85 268.92-268.92L650-620.92 423.23-394.15l-114-114L267.08-466l156.15 156.15ZM480.07-100q-78.84 0-148.21-29.92t-120.68-81.21q-51.31-51.29-81.25-120.63Q100-401.1 100-479.93q0-78.84 29.92-148.21t81.21-120.68q51.29-51.31 120.63-81.25Q401.1-860 479.93-860q78.84 0 148.21 29.92t120.68 81.21q51.31 51.29 81.25 120.63Q860-558.9 860-480.07q0 78.84-29.92 148.21t-81.21 120.68q-51.29 51.31-120.63 81.25Q558.9-100 480.07-100Zm-.07-60q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
          />
        </svg>
        <span class="roboto-black">A/B test</span>
      </label>
      <div class="d-flex align-center ga-2">
        <span class="roboto-regular" style="font-size: 12px"
          >Create A/B test</span
        >
        <label class="toggle-switch">
          <input type="checkbox" v-model="testStatus" />
          <div class="toggle-switch-background">
            <div class="toggle-switch-handle"></div>
          </div>
        </label>
      </div>
    </div>
    <div class="card-body">
      <p class="roboto-regular mb-4">
        To help improve ad performance, test versions with different images,
        text, audiences or placements. For accuracy, each one will be shown to
        separate groups of your audience.
      </p>

      <div class="mb-3" v-if="testStatus" >
        <label for="" class="roboto-bold d-block mb-1"
          >What would you like to test?</label
        >
        <v-select
          v-model="selectedTest"
          :items="testedItems"
          density="compact"
          variant="outlined"
          return-object
          item-value="id"
          item-title="title"
          hide-details
        >
          <!-- Custom selected item display -->
          <template v-slot:selection="{ item }">
            {{ item?.title || "" }}
          </template>
          <template v-slot:item="{ item }">
            <v-list-item @click="selectItem(item)">
              <div class="my-form">
                <div class="ga-3">
                  <!-- Bind radio checked state properly -->
                  <input
                    :checked="selectedTest && selectedTest.id === item.raw.id"
                    :id="item.raw.id"
                    type="radio"
                    name="option"
                    :value="item"
                  />
                  <div class="d-flex flex-column ga-1">
                    <label
                      :for="item.raw.id"
                      class="d-block"
                      style="font-size: 14px"
                      >{{ item.title }}</label
                    >
                    <p class="description" style="font-size: 12px">
                      {{ item.raw.descriptions }}
                    </p>
                  </div>
                </div>
              </div>
            </v-list-item>
          </template>
        </v-select>
      </div>
      <div class="test-duration mb-3" v-if="testStatus" >
        <label class="roboto-bold d-block mb-1" for=""> Test duration</label>
        <div class="input-duration" ref="durationInput">
          <input
            v-model="testDuration"
            type="number"
            class="no-controls"
            name=""
            id=""
          />
          <div class="d-flex flex-column">
            <svg
              @click="increaseDuration"
              xmlns="http://www.w3.org/2000/svg"
              height="20px"
              viewBox="0 -960 960 960"
              width="20px"
              fill="#000"
            >
              <path d="m288-384 192-192 192 192H288Z" />
            </svg>
            <svg
              @click="decreaseDuration"
              xmlns="http://www.w3.org/2000/svg"
              height="20px"
              viewBox="0 -960 960 960"
              width="20px"
              fill="#000"
            >
              <path d="M480-384 288-576h384L480-384Z" />
            </svg>
          </div>
          <div class="validaation-icon">
            <svg
              class="d-block"
              v-if="validationIcon"
              xmlns="http://www.w3.org/2000/svg"
              height="20px"
              viewBox="0 -960 960 960"
              width="20px"
              fill="#007e59"
            >
              <path
                d="m423.23-309.85 268.92-268.92L650-620.92 423.23-394.15l-114-114L267.08-466l156.15 156.15ZM480.07-100q-78.84 0-148.21-29.92t-120.68-81.21q-51.31-51.29-81.25-120.63Q100-401.1 100-479.93q0-78.84 29.92-148.21t81.21-120.68q51.29-51.31 120.63-81.25Q401.1-860 479.93-860q78.84 0 148.21 29.92t120.68 81.21q51.31 51.29 81.25 120.63Q860-558.9 860-480.07q0 78.84-29.92 148.21t-81.21 120.68q-51.29 51.31-120.63 81.25Q558.9-100 480.07-100Zm-.07-60q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
              />
            </svg>
            <svg
              class="d-block"
              v-else
              xmlns="http://www.w3.org/2000/svg"
              height="20px"
              viewBox="0 -960 960 960"
              width="20px"
              fill="#db373c"
            >
              <path
                d="m48-144 432-720 432 720H48Zm127-72h610L480-724 175-216Zm304.79-48q15.21 0 25.71-10.29t10.5-25.5q0-15.21-10.29-25.71t-25.5-10.5q-15.21 0-25.71 10.29t-10.5 25.5q0 15.21 10.29 25.71t25.5 10.5ZM444-384h72v-192h-72v192Zm36-86Z"
              />
            </svg>
          </div>
        </div>
      </div>
      <div class="find-winner" v-if="testStatus" >
        <label for="" class="roboto-bold d-block mb-1">What do you want to use to find a winner?</label>
        <v-select
          v-model="findWinnerItemsSelected"
          :items="findWinnerItems"
          density="compact"
          variant="outlined"
          return-object
          item-value="id"
          item-title="title"
          hide-details
        >
          <!-- Custom selected item display -->
          <template v-slot:selection="{ item }">
            {{ item?.title || "" }}
          </template>
          <template v-slot:item="{ item }">
            <v-list-item @click="selectItemWinner(item)">
              <div class="my-form">
                <div class="ga-3">
                  <!-- Bind radio checked state properly -->
                  <input
                    :checked="selectedTest && selectedTest.id === item.raw.id"
                    :id="item.raw.id"
                    type="radio"
                    name="option"
                    :value="item"
                  />
                  <div class="d-flex flex-column ga-1">
                    <label
                      :for="item.raw.id"
                      class="d-block"
                      style="font-size: 14px"
                      >{{ item.title }}</label
                    >
                  </div>
                </div>
              </div>
            </v-list-item>
          </template>
        </v-select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      testStatus: false,
      selectedTest: {
        id: 0,
        title: "Creative",
        value: "CREATIVE",
        descriptions: "Find out which images, videos or ad text work best.",
      },
      testedItems: [
        {
          id: 0,
          title: "Creative",
          value: "CREATIVE",
          descriptions: "Find out which images, videos or ad text work best.",
        },
        {
          id: 1,
          title: "Audience",
          value: "AUDIENCE",
          descriptions:
            "See how targeting a new audience can affect performance.",
        },
        {
          id: 2,
          title: "Placement",
          value: "PLACEMENT",
          descriptions: "Discover the most effective places to show your ads.",
        },
        {
          id: 3,
          title: "Custom",
          value: "CUSTOME",
          descriptions:
            "Learn how changing multiple variables can affect results.",
        },
      ],
      testDuration: 1,
      validationIcon: true,
      findWinnerItemsSelected: {
        id: 0,
        title: "Cost per result",
        value: "COST_PER_RESULT",
      },
      findWinnerItems: [
        {
          id: 0,
          title: "Cost per result",
          value: "COST_PER_RESULT",
        },
        {
          id: 1,
          title: "CPC (cost per link click)",
          value: "CPC",
        },
        {
          id: 2,
          title: "Cost per 1,000 Accounts Centre accounts reached",
          value: "COST_PER_1000_ACCOUNTS_CENTRE_ACCOUNTS_REACHED",
        },
        {
          id: 3,
          title: "Cost per purchase",
          value: "COST_PER_PURCHASE",
        },
      ],
    };
  },
  methods: {
    selectItem(item) {
      this.selectedTest = item.raw;
      localStorage.setItem("like_to_test", item.raw.value);
    },
    selectItemWinner(item) {
        this.findWinnerItemsSelected = item.raw;
        localStorage.setItem("meta_find_winner", item.raw.value);
    },
    increaseDuration() {
      this.testDuration += 1;
    },
    decreaseDuration() {
      this.testDuration -= 1;
    },
  },
  watch: {
    testDuration(val) {
      if (val < 1) {
        this.$refs.durationInput.style.boxShadow = "0 0 0 2px #f7d7d8 inset";
        this.$refs.durationInput.style.border = "1px solid #db373c";
        this.validationIcon = false;
      } else if (val > 1) {
        this.$refs.durationInput.style.boxShadow = "0 0 0 2px #cce6de inset";
        this.$refs.durationInput.style.border = "1px solid #007d57";
        this.validationIcon = true;
        localStorage.setItem("meta_test_duration", this.testDuration);
      } else {
        this.$refs.durationInput.style.border = "1px solid #007d57";
      }
    },
  },
};
</script>
