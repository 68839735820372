<template>
  <v-menu transition="scale-transition">
    <template v-slot:activator="{ props }">
      <span v-bind="props" class="group-by-button"
        ><img :src="GroupByIcon" alt="Group By" />
        <span>Group By</span>
        <svg
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 3L5 7L9 3"
            stroke="#666666"
            stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
    </template>

    <v-list>
      <v-list-item v-for="(item, i) in groupByItems" :key="i">
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import GroupByIcon from "@/assets/images/icons/group-by.svg";

export default {
  name: "FilterSection",
  components: {},
  data() {
    return {
      GroupByIcon: GroupByIcon,

      groupByItems: [
        { title: "Marketing Source", value: "marketing_source" },
        { title: "Status", value: "status" },
        { title: "Reachability", value: "reachability" },
        { title: "Confirmation", value: "confirmation" },
      ],
    };
  },
  methods: {},
};
</script>
<style scoped>
.group-by-button {
  height: 36px;
  padding: 6px 8px 6px 8px;
  border-radius: 8px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>
