<template>
  <div class="">
    <LatestFolder @open:NewFolder="$emit('open:NewFolder', true)" />
    <LatestImages v-if="store?.getImages.length" @edit:file="editFile" />
    <LatestVideos v-if="store?.getVideos.length" @edit:file="editFile" />
  </div>
</template>

<script>
import { DriveStore } from "@/store/drive/DriveStore";
import LatestFolder from "@/components/drive/LatestFolder.vue";
import LatestImages from "@/components/drive/LatestImages.vue";
import LatestVideos from "@/components/drive/LatestVideos.vue";
export default {
  name: "CustomBoxView",
  props: {},
  components: { LatestFolder, LatestImages, LatestVideos },

  data() {
    return {
      store: null,
    };
  },
  watch: {},
  computed: {},

  methods: {
    formatDate(date) {
      return format(new Date(date), "MMM dd, yyyy HH:mm");
    },
    editFile(v) {
      this.$emit("edit:file", v);
    },
  },

  created() {
    this.store = DriveStore();
  },
  mounted() {},
};
</script>
