<template>
  <div class="w-100">
    <GlobalNav />
    <v-container>
      <div class="policy-container">
        <header>
          <h1>Privacy Policy Of Promxa</h1>
        </header>
        <section>
          <p>
            At Promxa, accessible from
            <a href="https://promxa.com" target="_blank">promxa.com</a>, one of
            our main priorities is the privacy of our visitors. This Privacy
            Policy document contains types of information that is collected and
            recorded by Promxa and how we use it. If you have additional
            questions or require more information about our Privacy Policy, do
            not hesitate to contact us.
          </p>
          <p>
            This Privacy Policy applies only to our online activities and is
            valid for visitors to our website with regards to the information
            that they shared and/or collect in Promxa. This policy is not
            applicable to any information collected offline or via channels
            other than this website.
          </p>
        </section>

        <section>
          <h2>Consent</h2>
          <p>
            By using our website, you hereby consent to our Privacy Policy and
            agree to its terms.
          </p>
        </section>

        <section>
          <h2>Information We Collect</h2>
          <p>
            The personal information that you are asked to provide, and the
            reasons why you are asked to provide it, will be made clear to you
            at the point we ask you to provide your personal information.
          </p>
          <p>
            If you contact us directly, we may receive additional information
            about you such as your name, email address, phone number, the
            contents of the message and/or attachments you may send us, and any
            other information you may choose to provide.
          </p>
          <p>
            When you register for an Account, we may ask for your contact
            information, including items such as name, company name, address,
            email address, and telephone number.
          </p>
        </section>

        <section>
          <h2>How We Use Your Information</h2>
          <ul>
            <li>Provide, operate, and maintain our website</li>
            <li>Improve, personalize, and expand our website</li>
            <li>Understand and analyze how you use our website</li>
            <li>Develop new products, services, features, and functionality</li>
            <li>
              Communicate with you, either directly or through one of our
              partners
            </li>
            <li>Send you emails</li>
            <li>Find and prevent fraud</li>
          </ul>
        </section>

        <section>
          <h2>Log Files</h2>
          <p>
            Promxa follows a standard procedure of using log files. These files
            log visitors when they visit websites. All hosting companies do this
            and a part of hosting services’ analytics. The information collected
            by log files includes internet protocol (IP) addresses, browser
            type, Internet Service Provider (ISP), date and time stamp,
            referring/exit pages, and possibly the number of clicks.
          </p>
        </section>

        <section>
          <h2>Cookies and Web Beacons</h2>
          <p>
            Like any other website, Promxa uses “cookies”. These cookies are
            used to store information including visitors’ preferences, and the
            pages on the website that the visitor accessed or visited. The
            information is used to optimize the users’ experience by customizing
            our web page content based on visitors’ browser type and/or other
            information.
          </p>
        </section>

        <!-- Add remaining sections similarly -->
        <footer>
          <p>
            If you have any questions or suggestions about our Privacy Policy,
            do not hesitate to contact us at
            <a href="mailto:support@promxa.com">support@promxa.com</a>.
          </p>
        </footer>
      </div>
    </v-container>
  </div>
</template>

<script>
import GlobalNav from "@/components/layout/GlobalNav.vue";
import "../assets/css/style.css";

export default {
  components: { GlobalNav },
  data() {
    return {
      sections: [
        {
          title: "Our Privacy Policy",
          content: `At Promxa, accessible from promxa.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Promxa and how we use it. If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.

This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Promxa. This policy is not applicable to any information collected offline or via channels other than this website.`,
        },
        {
          title: "Consent",
          content:
            "By using our website, you hereby consent to our Privacy Policy and agree to its terms.",
        },
        {
          title: "Information We Collect",
          content: `The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.

If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.

When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.`,
        },
        {
          title: "How We Use Your Information",
          list: [
            "Provide, operate, and maintain our website",
            "Improve, personalize, and expand our website",
            "Understand and analyze how you use our website",
            "Develop new products, services, features, and functionality",
            "Communicate with you, either directly or through one of our partners",
            "Send you emails",
            "Find and prevent fraud",
          ],
        },
        {
          title: "Log Files",
          content:
            "Promxa follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks.",
        },
        {
          title: "Cookies and Web Beacons",
          content: `Like any other website, Promxa uses "cookies". These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.`,
        },
      ],
    };
  },
  methods: {},
};
</script>
<style scoped>
body {
  background: #e2e2e2;
}
.policy-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background: #fff;
}

header {
  text-align: center;
  margin-bottom: 20px;
}

header h1 {
  margin: 0;
  font-size: 2.4rem;
  color: #3e9faf;
}

header .date {
  color: #666;
  font-size: 0.9rem;
}

section {
  margin-bottom: 20px;
}

section h2 {
  font-size: 1.6rem;
  color: #333;
  margin-bottom: 10px;
}

ul {
  list-style-type: disc;
  margin: 10px 0 10px 20px;
  padding: 0;
}

footer {
  text-align: center;
  margin-top: 20px;
}

footer a {
  color: #0056b3;
  text-decoration: none;
}
</style>
