import { defineStore } from "pinia";

export const advancedData = defineStore("advancedData", {
  state: () => ({
    startAgeRangeItems: [
      {
        id: 0,
        title: 18,
        value: 18,
      },
      {
        id: 1,
        title: 19,
        value: 19,
      },
      {
        id: 2,
        title: 20,
        value: 20,
      },
      {
        id: 3,
        title: 21,
        value: 21,
      },
      {
        id: 4,
        title: 22,
        value: 22,
      },
      {
        id: 5,
        title: 23,
        value: 23,
      },
      {
        id: 6,
        title: 24,
        value: 24,
      },
      {
        id: 7,
        title: 25,
        value: 25,
      },
      {
        id: 8,
        title: 26,
        value: 26,
      },
      {
        id: 9,
        title: 27,
        value: 27,
      },
      {
        id: 10,
        title: 28,
        value: 28,
      },
      {
        id: 11,
        title: 29,
        value: 29,
      },
      {
        id: 12,
        title: 30,
        value: 30,
      },
      {
        id: 13,
        title: 31,
        value: 31,
      },
      {
        id: 14,
        title: 32,
        value: 32,
      },
      {
        id: 15,
        title: 33,
        value: 33,
      },
      {
        id: 16,
        title: 34,
        value: 34,
      },
      {
        id: 17,
        title: 35,
        value: 35,
      },
      {
        id: 18,
        title: 36,
        value: 36,
      },
      {
        id: 19,
        title: 37,
        value: 37,
      },
      {
        id: 20,
        title: 38,
        value: 38,
      },
      {
        id: 21,
        title: 39,
        value: 39,
      },
      {
        id: 22,
        title: 40,
        value: 40,
      },
      {
        id: 23,
        title: 41,
        value: 41,
      },
      {
        id: 24,
        title: 42,
        value: 42,
      },
      {
        id: 25,
        title: 43,
        value: 43,
      },
      {
        id: 26,
        title: 44,
        value: 44,
      },
      {
        id: 27,
        title: 45,
        value: 45,
      },
      {
        id: 28,
        title: 46,
        value: 46,
      },
      {
        id: 29,
        title: 47,
        value: 47,
      },
      {
        id: 30,
        title: 48,
        value: 48,
      },
      {
        id: 31,
        title: 49,
        value: 49,
      },
      {
        id: 32,
        title: 50,
        value: 50,
      },
      {
        id: 33,
        title: 51,
        value: 51,
      },
      {
        id: 34,
        title: 52,
        value: 52,
      },
      {
        id: 35,
        title: 53,
        value: 53,
      },
      {
        id: 36,
        title: 54,
        value: 54,
      },
      {
        id: 37,
        title: 55,
        value: 55,
      },
      {
        id: 38,
        title: 56,
        value: 56,
      },
      {
        id: 39,
        title: 57,
        value: 57,
      },
      {
        id: 40,
        title: 58,
        value: 58,
      },
      {
        id: 41,
        title: 59,
        value: 59,
      },
      {
        id: 42,
        title: 60,
        value: 60,
      },
      {
        id: 43,
        title: 61,
        value: 61,
      },
      {
        id: 44,
        title: 62,
        value: 62,
      },
      {
        id: 45,
        title: 63,
        value: 63,
      },
      {
        id: 46,
        title: 64,
        value: 64,
      },
      {
        id: 47,
        title: 65,
        value: 65,
      },
    ],
    
    
  }),
});
