<template>
  <div class="placement-card tracking-card">
    <div class="card-header">
      <p class="card-title">Tracking <span>(optional) </span></p>
    </div>
    <div class="card-body">
      <h4 v-if="avilableEvents" class="mb-2">TikTok events tracking</h4>
      <div class="event-tracking mb-4" ref="eventTracking">
        <div class="event-tracking-header">
          <h4 v-if="!avilableEvents">TikTok events tracking</h4>
          <button v-if="!avilableEvents" @click="showTeckingDetails">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 -960 960 960"
              height="18px"
              width="18px"
              fill="#5f6368"
            >
              <path
                d="M96 0v-192h768V0H96Zm168-360h51l279-279-26-27-25-24-279 279v51Zm-72 72v-152.92L594-843q11-11 23.84-16 12.83-5 27-5 14.16 0 27.16 5t24.1 15.94L747-792q11 11 16 24t5 27.4q0 13.49-4.95 26.54-4.95 13.05-15.75 23.85L345-288H192Zm503-455-51-49 51 49ZM594-639l-26-27-25-24 51 51Z"
              />
            </svg>
          </button>
        </div>
        <div class="event-tracking-body">
          <div class="mb-2">
            <p ref="websiteEvent">Website events:</p>
            <span v-if="avilableEvents">No pixel available </span>
          </div>
          <div class="mb-2">
            <p ref="appEvent">App events:</p>
            <span v-if="avilableEvents">No app available </span>
          </div>
          <div class="mb-2">
            <p ref="offlineEvent">Offline events:</p>
            <span v-if="avilableEvents">No offline event available </span>
          </div>
        </div>
      </div>
      <div class="third-party-tracking-settings">
        <h4>Third-party tracking settings</h4>
        <div class="tracking-setting-contant">
          <div class="mb-4">
            <label for="">Impression tracking URL </label>
            <input
              type="url"
              name=""
              placeholder="Enter impression tracking URL"
              id=""
            />
          </div>
          <div class="mb-4">
            <label for="">Click tracking URL </label>
            <input
              type="url"
              placeholder="Enter click tracking URL"
              name=""
              id=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TrackingComponent",

  data() {
    return {
      avilableEvents: false,
    };
  },

  methods: {
    showTeckingDetails() {
      this.avilableEvents = !this.avilableEvents;
      this.$refs.eventTracking.classList.toggle("active");
      this.$refs.websiteEvent.classList.toggle("active");
      this.$refs.appEvent.classList.toggle("active");
      this.$refs.offlineEvent.classList.toggle("active");
    },
  },
};
</script>
