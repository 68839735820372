<template>
  <v-select
    label="Select"
    v-model="selected_potentiality"
    :items="potentiality"
    :class="['contact-v-select', potentialityClass]"
    @update:modelValue="updatePotentiality()"
    variant=""
    no-details
  >
    <template v-slot:item="{ item, props }">
      <v-list-item
        v-bind="props"
        hide-details
        :title="item.raw"
        :style="{
          color:
            item.raw === 'VIP'
              ? '#1c6572'
              : item.raw === 'A'
              ? '#0464bc'
              : item.raw === 'B'
              ? '#c14a05'
              : item.raw === 'C'
              ? '#d91313'
              : '',
          background:
            item.raw === 'VIP'
              ? '#e9f8fc'
              : item.raw === 'A'
              ? '#f5fafe'
              : item.raw === 'B'
              ? '#fefbf1'
              : item.raw === 'C'
              ? '#fef3f2'
              : '',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }"
      >
      </v-list-item>
    </template>
  </v-select>
</template>
<script>
import apiClient from "@/core/buildRequest";
import { useAccountsStore } from "@/store/crm/useAccountsStore";

export default {
  name: "PotentialityCol",
  props: ["item", "id"],
  data() {
    return {
      selected_potentiality: "",
      potentiality: ["VIP", "A", "B", "C"],
    };
  },
  mounted() {
    this.selected_potentiality = this.item.potentiality;
  },
  computed: {
    potentialityClass() {
      switch (this.selected_potentiality) {
        case "VIP":
          return "contact-v-select-prime";
        case "A":
          return "contact-v-select-blue";
        case "B":
          return "contact-v-select-orange";
        case "C":
          return "contact-v-select-red";
        default:
          return ""; // Return empty if no class is applicable
      }
    },
  },
  methods: {
    async updatePotentiality() {
      console.log(this.id, this.selected_potentiality);

      await apiClient
        .post(`/leads-managment/accounts/potentiality/${this.id}`, {
          potentiality: this.selected_potentiality,
        })
        .then((res) => {
          console.log(res);

          const account_store = useAccountsStore();
          account_store?.updatePotentiality(
            this.id,
            this.selected_potentiality
          );
        });
    },
  },
};
</script>
