<template>
  <v-col :cols="12" :lg="6" class="d-flex ga-5 align-center">
    <div class="d-flex ga-1 align-center input-data-text">
      <PriorityIcon />
      <label class="calender-input-label text-capitalize">Priority</label>
    </div>
    <div>
      <v-menu>
        <template v-slot:activator="{ props }">
          <span
            v-bind="props"
            class="priority-list-selected-option d-flex align-center ga-2"
          >
            <PriorityIcon
              v-if="store.priority.title !== 'clear'"
              :color="store.priority.color"
            />
            <ClearIcon v-else />
            <span class="text-capitalize small-text">{{
              store.priority.title
            }}</span>
          </span>
        </template>
        <v-list class="priority-list">
          <v-list-item
            v-for="(priority, i) in priorityList"
            :key="i"
            @click="selectPriority(priority)"
          >
            <span class="priority-list-option d-flex align-center ga-2">
              <PriorityIcon
                v-if="priority.title !== 'clear'"
                :color="priority.color"
              />
              <ClearIcon v-else />
              <span class="text-capitalize">{{ priority.title }}</span>
            </span>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-col>
</template>

<script>
import { useTaskStore } from "@/store/taskStore";
import PriorityIcon from "./icons/PriorityIcon.vue";
import ClearIcon from "./icons/ClearIcon.vue";

export default {
  name: "TaskPriority",
  components: {
    PriorityIcon,
    ClearIcon,
  },
  data() {
    return {
      store: useTaskStore(),
      priorityList: [
        { title: "urgent", color: "rgba(233, 44, 44, 1)" },
        { title: "high", color: "rgba(229, 160, 0, 1)" },
        { title: "normal", color: "rgba(0, 133, 255, 1)" },
        { title: "law", color: "rgba(83, 100, 113, 1)" },
        { title: "clear", color: "rgba(102, 102, 102, 1)" },
      ],
    };
  },
  methods: {
    selectPriority(priority) {
      this.store.updatePriority(priority);
    },
  },
};
</script>
