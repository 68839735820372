<template>
  <v-container fluid style="padding: 0; min-height: 100vh">
    <v-row no-gutters>
      <v-col cols="12">
        <GlobalNav></GlobalNav>
      </v-col>
      <v-col cols="12">
        <div class="d-flex h-100">
          <SideBar></SideBar>
          <div class="pa-5 w-100">
            <BreadCrumb :linksData="linksData" class="mb-5" />
            <div>
              <div
                class="leads-managements-link d-flex align-center ga-2 my-3 py-3"
              >
                <div
                  @click="$router.push({ name: 'EngagementAndInsights' })"
                  class="link cursor-pointer hoverd-style-line d-flex align-center ga-2"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.825 13L13.425 18.6L12 20L4 12L12 4L13.425 5.4L7.825 11H20V13H7.825Z"
                      fill="#1F666A"
                    />
                  </svg>
                  <span>Engagement & Insights</span>
                </div>
              </div>
              <v-row>
                <v-col cols="12" md="7">
                  <div class="post">
                    <div class="d-flex flex-wrap align-center ga-3 data">
                      <div class="element d-flex align-center ga-2">
                        <span class="title">Post Status :</span
                        ><span class="value">Location</span>
                      </div>
                      <div class="element d-flex align-center ga-2">
                        <span class="title">Platform :</span
                        ><span class="value">Location</span>
                      </div>
                      <div class="element d-flex align-center ga-2">
                        <span class="title">Date of publish :</span
                        ><span class="value">Location</span>
                      </div>
                    </div>
                  </div>
                  <div class="post w-100 mt-5">
                    <div
                      class="element d-flex flex-column ga-3 pa-4"
                      style="height: auto"
                    >
                      <div
                        class="d-flex justify-space-between align-center title"
                      >
                        <span>Caption</span>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_4993_149817)">
                            <path
                              d="M11.334 1.99955C11.5091 1.82445 11.7169 1.68556 11.9457 1.5908C12.1745 1.49604 12.4197 1.44727 12.6673 1.44727C12.9149 1.44727 13.1601 1.49604 13.3889 1.5908C13.6177 1.68556 13.8256 1.82445 14.0007 1.99955C14.1757 2.17465 14.3146 2.38252 14.4094 2.61129C14.5042 2.84006 14.5529 3.08526 14.5529 3.33288C14.5529 3.58051 14.5042 3.8257 14.4094 4.05448C14.3146 4.28325 14.1757 4.49112 14.0007 4.66622L5.00065 13.6662L1.33398 14.6662L2.33398 10.9996L11.334 1.99955Z"
                              stroke="#666666"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_4993_149817">
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <div class="value">capppppppppppppppppiton</div>
                    </div>
                  </div>
                  <div class="post w-100 mt-5">
                    <div
                      class="element d-flex flex-column ga-4 pa-4"
                      style="height: auto"
                    >
                      <div
                        class="d-flex justify-space-between align-center w-100"
                      >
                        <span class="d-flex align-center ga-2 title">
                          <svg
                            width="42"
                            height="43"
                            viewBox="0 0 42 43"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <ellipse
                              cx="20.7571"
                              cy="21.058"
                              rx="20.7571"
                              ry="21.058"
                              fill="#DDECED"
                              fill-opacity="0.4"
                            />
                            <path
                              d="M24.6875 28.0625H20.9318L24.8777 22.7469C25.6733 21.6863 26.0938 20.423 26.0938 19.0938C26.0938 15.7337 23.3601 13 20 13C16.6399 13 13.9062 15.7337 13.9062 19.0938C13.9062 20.423 14.3268 21.6862 15.1209 22.7451L19.0683 28.0625H15.3125C15.0536 28.0625 14.8438 28.2724 14.8438 28.5312C14.8438 28.7901 15.0536 29 15.3125 29H24.6875C24.9464 29 25.1562 28.7901 25.1562 28.5312C25.1562 28.2724 24.9464 28.0625 24.6875 28.0625ZM15.8723 22.1844C15.1994 21.2873 14.8438 20.2186 14.8438 19.0938C14.8438 16.2506 17.1568 13.9375 20 13.9375C22.8432 13.9375 25.1562 16.2506 25.1562 19.0938C25.1562 20.2186 24.8006 21.2873 24.1264 22.1863L20 27.7448L15.8723 22.1844Z"
                              fill="#237276"
                            />
                            <path
                              d="M20 14.875C17.6738 14.875 15.7812 16.7675 15.7812 19.0938C15.7812 21.4228 17.6774 23.3125 20 23.3125C22.3144 23.3125 24.2188 21.4313 24.2188 19.0938C24.2188 16.7675 22.3262 14.875 20 14.875ZM20 22.375C19.3372 22.375 18.7199 22.1773 18.2034 21.838C18.4369 21.0591 19.166 20.5 20 20.5C20.834 20.5 21.5631 21.0591 21.7966 21.838C21.2801 22.1773 20.6628 22.375 20 22.375ZM19.0625 18.625C19.0625 18.1081 19.4831 17.6875 20 17.6875C20.5169 17.6875 20.9375 18.1081 20.9375 18.625C20.9375 19.1419 20.5169 19.5625 20 19.5625C19.4831 19.5625 19.0625 19.1419 19.0625 18.625ZM22.5403 21.1683C22.2963 20.6554 21.8948 20.2092 21.3601 19.9137C21.6788 19.5774 21.875 19.1238 21.875 18.625C21.875 17.5911 21.0339 16.75 20 16.75C18.9661 16.75 18.125 17.5911 18.125 18.625C18.125 19.1238 18.3212 19.5774 18.6399 19.9137C18.1056 20.2089 17.7038 20.655 17.4597 21.1683C16.9968 20.6025 16.7188 19.88 16.7188 19.0938C16.7188 17.2845 18.1907 15.8125 20 15.8125C21.8093 15.8125 23.2812 17.2845 23.2812 19.0938C23.2812 19.88 23.0032 20.6025 22.5403 21.1683Z"
                              fill="#237276"
                            />
                          </svg>
                          <span>Total Impressions</span>
                        </span>
                        <span class="value">340</span>
                      </div>
                      <div
                        class="d-flex justify-space-between align-center w-100"
                      >
                        <span class="d-flex align-center ga-2 title">
                          <svg
                            width="42"
                            height="43"
                            viewBox="0 0 42 43"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <ellipse
                              cx="20.7571"
                              cy="21.1732"
                              rx="20.7571"
                              ry="21.058"
                              fill="#DDECED"
                              fill-opacity="0.4"
                            />
                            <path
                              d="M10.3078 13.1152H29.4552H30.097V13.757V29.4734V30.1152H29.4552H10.3078H9.66602V29.4734V13.757V13.1152H10.3078ZM28.8134 14.3989H10.9496V28.8316H28.8134V14.3989Z"
                              fill="#237276"
                            />
                            <path
                              d="M10.3078 17.4434H29.4552H30.097V18.0852V29.4733V30.1151H29.4552H10.3078H9.66602V29.4733V18.0852V17.4434H10.3078ZM28.8134 18.727H10.9496V28.8315H28.8134V18.727Z"
                              fill="#237276"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M12.0176 15.2793H13.3012V16.5629H12.0176V15.2793Z"
                              fill="#237276"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M14.4805 15.2793H15.7641V16.5629H14.4805V15.2793Z"
                              fill="#237276"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M16.9434 15.2793H18.227V16.5629H16.9434V15.2793Z"
                              fill="#237276"
                            />
                            <path
                              d="M23.2285 23.7795C22.4193 22.8418 19.9341 20.6123 16.6206 23.7795C19.9342 26.9466 22.4193 24.7172 23.2285 23.7795ZM15.2529 23.3432C20.6488 17.5346 24.5575 23.4018 24.5689 23.4185L24.8155 23.7795L24.5689 24.1405C24.5575 24.1572 20.6488 30.0243 15.2529 24.2157L14.8477 23.7795L15.2529 23.3432Z"
                              fill="#237276"
                            />
                            <path
                              d="M19.8801 24.4223C20.2346 24.4223 20.5219 24.1349 20.5219 23.7805C20.5219 23.426 20.2346 23.1387 19.8801 23.1387C19.5256 23.1387 19.2383 23.426 19.2383 23.7805C19.2383 24.1349 19.5256 24.4223 19.8801 24.4223Z"
                              fill="#237276"
                            />
                          </svg>

                          <span>Total Engagements</span>
                        </span>
                        <span class="value">340</span>
                      </div>
                      <div
                        class="d-flex justify-space-between align-center w-100"
                      >
                        <span class="d-flex align-center ga-2 title">
                          <svg
                            width="42"
                            height="43"
                            viewBox="0 0 42 43"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <ellipse
                              cx="20.7571"
                              cy="21.2904"
                              rx="20.7571"
                              ry="21.058"
                              fill="#DDECED"
                              fill-opacity="0.4"
                            />
                            <path
                              d="M13.228 18.99C13.3998 19.1341 13.5742 19.2678 13.7508 19.3909C13.2193 18.3982 13.2194 17.0319 13.7508 16.0391C13.5742 16.1622 13.3998 16.2959 13.228 16.44C12.6427 16.9303 12.2295 17.4269 12.0117 17.715C12.2295 18.0034 12.6427 18.5 13.228 18.99Z"
                              fill="#237276"
                            />
                            <path
                              d="M20.8646 18.99C21.4499 18.5 21.863 18.0034 22.0809 17.715C21.863 17.4269 21.4499 16.9303 20.8646 16.44C20.6927 16.2959 20.5184 16.1622 20.3418 16.0391C20.8732 17.0319 20.8732 18.3982 20.3418 19.3909C20.5184 19.2678 20.6927 19.1341 20.8646 18.99Z"
                              fill="#237276"
                            />
                            <path
                              d="M16.9639 20.4736C18.4666 20.538 19.8249 19.2708 19.8077 17.7145C19.656 14.053 14.4383 14.054 14.2871 17.7145C14.2871 19.2086 15.4806 20.4291 16.9639 20.4736ZM17.0474 16.171C17.8987 16.171 18.5909 16.8635 18.5909 17.7145C18.5062 19.7625 15.5883 19.7619 15.504 17.7145C15.504 16.8635 16.1962 16.171 17.0474 16.171Z"
                              fill="#237276"
                            />
                            <path
                              d="M25.2339 16.0078V16.9453H26.4868C23.2116 21.5488 17.6789 24.1496 12 23.8895V24.827C12.4751 24.8257 13.2225 24.8341 13.6882 24.8102V27.4962H14.6257V24.7446C14.9644 24.7118 15.3013 24.6697 15.6358 24.6177V27.4961H16.5733V24.4458C16.913 24.3741 17.2499 24.2924 17.5836 24.2011V27.4962H18.5211V23.9166C18.8618 23.8027 19.1987 23.6785 19.5312 23.5443V27.4962H20.4688V23.1341C20.811 22.9723 21.1478 22.7994 21.4789 22.616V27.4962H22.4164V22.0582C22.7607 21.8387 23.0978 21.6072 23.4267 21.3638V27.4962H24.3642V20.6194C24.7119 20.3235 25.049 20.0136 25.3743 19.69V27.4962H26.3118V18.6819C26.5717 18.3795 26.822 18.067 27.0625 17.7453V18.7739H28V16.0078H25.2339Z"
                              fill="#237276"
                            />
                            <path
                              d="M17.0473 18.3204C17.3814 18.3204 17.6533 18.0485 17.6533 17.7141C17.6199 16.9103 16.4747 16.9105 16.4414 17.7141C16.4414 18.0485 16.7133 18.3204 17.0473 18.3204Z"
                              fill="#237276"
                            />
                          </svg>

                          <span>Total Clicks</span>
                        </span>
                        <span class="value">340</span>
                      </div>
                    </div>
                  </div>
                  <v-row class="mt-5">
                    <v-col cols="4" class="post">
                      <div
                        class="element rectangle d-flex flex-column align-center justify-center ga-3"
                      >
                        <div class="title">Reacts</div>
                        <svg
                          width="51"
                          height="53"
                          viewBox="0 0 51 53"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <ellipse
                            cx="25.5004"
                            cy="26.3222"
                            rx="25.436"
                            ry="25.8046"
                            fill="#DDECED"
                            fill-opacity="0.4"
                          />
                          <path
                            d="M24.0513 36.6437H25.5065L25.5143 34.8293C28.7885 34.5793 30.6616 32.6279 30.6616 30.0151C30.6616 27.0395 28.115 25.8864 26.1025 25.3703L25.5375 25.2171L25.553 20.04C27.0004 20.2416 27.9912 21.0803 28.1383 22.4028H30.4449C30.383 19.9433 28.4324 18.1047 25.5607 17.8628L25.5684 16H24.1132L24.1055 17.8789C21.3267 18.1692 19.291 19.9755 19.291 22.5802C19.291 24.8784 20.87 26.2251 23.4166 26.9428L24.0823 27.1282L24.0668 32.6198C22.5574 32.4263 21.3886 31.5715 21.2648 30.0151H18.8652C19.0123 32.7972 20.9397 34.5874 24.0591 34.8293L24.0513 36.6437ZM25.522 32.6198L25.5375 27.5476C27.1088 27.9992 28.2931 28.5959 28.2931 29.9909C28.2931 31.4021 27.163 32.394 25.522 32.6198ZM24.09 24.8139C22.9367 24.4591 21.6673 23.822 21.675 22.435C21.675 21.2254 22.5961 20.282 24.0978 20.0481L24.09 24.8139Z"
                            fill="#237276"
                          />
                        </svg>

                        <div class="value">10000</div>
                      </div>
                    </v-col>
                    <v-col cols="4" class="post">
                      <div
                        class="element rectangle d-flex flex-column align-center justify-center ga-3"
                      >
                        <div class="title">Comments</div>
                        <svg
                          width="51"
                          height="53"
                          viewBox="0 0 51 53"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <ellipse
                            cx="25.5004"
                            cy="26.3222"
                            rx="25.436"
                            ry="25.8046"
                            fill="#DDECED"
                            fill-opacity="0.4"
                          />
                          <path
                            d="M24.0513 36.6437H25.5065L25.5143 34.8293C28.7885 34.5793 30.6616 32.6279 30.6616 30.0151C30.6616 27.0395 28.115 25.8864 26.1025 25.3703L25.5375 25.2171L25.553 20.04C27.0004 20.2416 27.9912 21.0803 28.1383 22.4028H30.4449C30.383 19.9433 28.4324 18.1047 25.5607 17.8628L25.5684 16H24.1132L24.1055 17.8789C21.3267 18.1692 19.291 19.9755 19.291 22.5802C19.291 24.8784 20.87 26.2251 23.4166 26.9428L24.0823 27.1282L24.0668 32.6198C22.5574 32.4263 21.3886 31.5715 21.2648 30.0151H18.8652C19.0123 32.7972 20.9397 34.5874 24.0591 34.8293L24.0513 36.6437ZM25.522 32.6198L25.5375 27.5476C27.1088 27.9992 28.2931 28.5959 28.2931 29.9909C28.2931 31.4021 27.163 32.394 25.522 32.6198ZM24.09 24.8139C22.9367 24.4591 21.6673 23.822 21.675 22.435C21.675 21.2254 22.5961 20.282 24.0978 20.0481L24.09 24.8139Z"
                            fill="#237276"
                          />
                        </svg>

                        <div class="value">10000</div>
                      </div>
                    </v-col>
                    <v-col cols="4" class="post">
                      <div
                        class="element rectangle d-flex flex-column align-center justify-center ga-3"
                      >
                        <div class="title">Share</div>
                        <svg
                          width="51"
                          height="53"
                          viewBox="0 0 51 53"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <ellipse
                            cx="25.5004"
                            cy="26.3222"
                            rx="25.436"
                            ry="25.8046"
                            fill="#DDECED"
                            fill-opacity="0.4"
                          />
                          <path
                            d="M24.0513 36.6437H25.5065L25.5143 34.8293C28.7885 34.5793 30.6616 32.6279 30.6616 30.0151C30.6616 27.0395 28.115 25.8864 26.1025 25.3703L25.5375 25.2171L25.553 20.04C27.0004 20.2416 27.9912 21.0803 28.1383 22.4028H30.4449C30.383 19.9433 28.4324 18.1047 25.5607 17.8628L25.5684 16H24.1132L24.1055 17.8789C21.3267 18.1692 19.291 19.9755 19.291 22.5802C19.291 24.8784 20.87 26.2251 23.4166 26.9428L24.0823 27.1282L24.0668 32.6198C22.5574 32.4263 21.3886 31.5715 21.2648 30.0151H18.8652C19.0123 32.7972 20.9397 34.5874 24.0591 34.8293L24.0513 36.6437ZM25.522 32.6198L25.5375 27.5476C27.1088 27.9992 28.2931 28.5959 28.2931 29.9909C28.2931 31.4021 27.163 32.394 25.522 32.6198ZM24.09 24.8139C22.9367 24.4591 21.6673 23.822 21.675 22.435C21.675 21.2254 22.5961 20.282 24.0978 20.0481L24.09 24.8139Z"
                            fill="#237276"
                          />
                        </svg>

                        <div class="value">10000</div>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="4">
                  <img
                    width="75%"
                    src="https://promxa-app.me:8080/img/capture.f522df09.png"
                    alt=""
                  />
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import BreadCrumb from "@/components/layout/BreadCrumb.vue";
import GlobalNav from "@/components/layout/GlobalNav.vue";
import SideBar from "@/components/layout/SideBar.vue";

export default {
  name: "EngagementAndInsights",
  components: { BreadCrumb, GlobalNav, SideBar },
  data() {
    return {
      linksData: [
        { name: "Home", link: "/home-page" },
        { name: "Engagement and Insights", link: "" },
      ],
    };
  },
  mounted() {},
};
</script>

<style scoped>
.post .element {
  height: 40px;
  padding: 0px 24px 0px 16px;
  background: #ffffff;
  border-radius: 8px;
}
.post .element .title {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  color: #0d0d0d;
}
.post .element .value {
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  color: #666666;
}

.post .rectangle {
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 7.37px 44.24px 0px #dedede80;
  background: #ffffff;
  height: auto !important;
}
</style>
