<template>
  <base-dialog
    :model-value="show"
    max-width="800px"
    persistent
    @update:model-value="$emit('close')"
  >
    <v-card>
      <v-card-title class="text-h5">Crop Image</v-card-title>
      <v-card-text>
        <image-cropper ref="imageCropper" :src="imageUrl" />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey-darken-1" variant="text" @click="handleCancel">
          Cancel
        </v-btn>
        <v-btn color="primary" variant="text" @click="handleCrop"> Crop </v-btn>
      </v-card-actions>
    </v-card>
  </base-dialog>
</template>

<script>
import BaseDialog from "./dialog/BaseDialog.vue";
import ImageCropper from "./cropper/ImageCropper.vue";

export default {
  name: "ImageCropDialog",

  components: {
    BaseDialog,
    ImageCropper,
  },

  props: {
    show: {
      type: Boolean,
      required: true,
    },
    imageUrl: {
      type: String,
      required: true,
    },
  },

  emits: ["close", "cancel", "crop"],

  methods: {
    handleCancel() {
      this.$emit("close");
      this.$emit("cancel");
    },

    async handleCrop() {
      const imageCropper = this.$refs.imageCropper;
      if (!imageCropper) return;

      try {
        const croppedImage = await imageCropper.getCroppedImage();
        if (croppedImage) {
          this.$emit("crop", croppedImage);
          this.$emit("close");
        }
      } catch (error) {
        console.error("Error cropping image:", error);
      }
    },
  },
};
</script>
