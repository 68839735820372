<template>
  <v-col :cols="12" :lg="9">
    <div class="bold-text mb-2">Ad / Post Caption</div>
    <textarea
      v-model="store.caption"
      rows="4"
      class="calender-input w-100 d-block"
      placeholder="Task Caption"
      style="height: auto"
    ></textarea>
  </v-col>
</template>

<script>
import { useTaskStore } from '@/store/taskStore';

export default {
  name: 'TaskCaption',
  data() {
    return {
      store: useTaskStore(),
    };
  }
};
</script>
