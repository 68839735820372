<template>
  <v-navigation-drawer
    v-model="localDrawer"
    :rail="rail"
    @click="rail = false"
    class="crm-sidebar"
  >
    <v-list-item
      prepend-avatar="https://randomuser.me/api/portraits/men/85.jpg"
      :title="rail ? '' : 'John Doe'"
      :subtitle="rail ? '' : 'Admin'"
      class="user-data-sidebar"
    >
    </v-list-item>

    <v-list density="compact" nav>
      <v-list-item
        v-for="item in menuItems"
        :key="item.title"
        link
        class="menu-item-sidebar"
        :active="active(item)"
        :to="item.path"
      >
        <v-list-img>
          <img
            :src="active(item) ? item.activeIcon : item.icon"
            :alt="item.title"
          />
        </v-list-img>
        <v-list-title>
          <span class="text-capitalize">{{ item.title }}</span>
        </v-list-title>
      </v-list-item>
      <v-list-item button @click="clearChache()" class="menu-item-sidebar">
        <v-list-img> </v-list-img>
        <v-list-title>
          <span class="text-capitalize">Clear Cach</span>
        </v-list-title>
      </v-list-item>
      <v-list-item button @click="FreshDatabase()" class="menu-item-sidebar">
        <v-list-img> </v-list-img>
        <v-list-title>
          <span class="text-capitalize">Fresh Database</span>
        </v-list-title>
      </v-list-item>
    </v-list>
    <div class="sidebar-bottom">
      <a href="/home-page" class="d-flex align-center ga-2"
        ><svg
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.19414 8.7085L10.6275 4.27516L9.49935 3.16683L3.16602 9.50016L9.49935 15.8335L10.6275 14.7252L6.19414 10.2918H15.8327V8.7085H6.19414Z"
            fill="#999999"
          />
        </svg>
        <span>Back To Modules</span></a
      >
    </div>
  </v-navigation-drawer>
</template>

<script>
import "@/assets/css/crm/style.css";
import leadsIcon from "@/assets/images/icons/sidebar/leads.svg";
import contactsIcon from "@/assets/images/icons/sidebar/contacts.svg";
import accountIcon from "@/assets/images/icons/sidebar/accounts.svg";
import projectsIcon from "@/assets/images/icons/sidebar/projects.svg";
import dealsIcon from "@/assets/images/icons/sidebar/deals.svg";
import eventsIcon from "@/assets/images/icons/sidebar/events.svg";
import tasksIcon from "@/assets/images/icons/sidebar/tasks.svg";

import ActiveLeadsIcon from "@/assets/images/icons/sidebar/active-leads.svg";
import ActiveContactsIcon from "@/assets/images/icons/sidebar/active-contacts.svg";
import ActiveAccountIcon from "@/assets/images/icons/sidebar/active-accounts.svg";
import ActiveProjectsIcon from "@/assets/images/icons/sidebar/active-projects.svg";
import ActiveDealsIcon from "@/assets/images/icons/sidebar/active-deals.svg";
import ActiveEventsIcon from "@/assets/images/icons/sidebar/active-events.svg";
import ActiveTasksIcon from "@/assets/images/icons/sidebar/active-tasks.svg";
import apiClient from "@/core/buildRequest";

export default {
  name: "SidebarNavigation",
  props: {
    drawer: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      rail: false,
      menuItems: [
        {
          title: "Leads",
          single: "lead",
          activeIcon: ActiveLeadsIcon,
          icon: leadsIcon,
          path: "/leads-management",
        },
        {
          title: "Contacts",
          single: "contact",
          activeIcon: ActiveContactsIcon,
          icon: contactsIcon,
          path: "/contacts-management",
        },
        {
          title: "Account",
          single: "account",
          activeIcon: ActiveAccountIcon,
          icon: accountIcon,
          path: "/accounts-management",
        },
        {
          title: "Projects",
          single: "project",
          activeIcon: ActiveProjectsIcon,
          icon: projectsIcon,
          path: "/projects-management",
        },
        {
          title: "Deals",
          single: "deal",
          activeIcon: ActiveDealsIcon,
          icon: dealsIcon,
          path: "/deals-management",
        },
        {
          title: "Events",
          single: "event",
          activeIcon: ActiveEventsIcon,
          icon: eventsIcon,
          path: "/events-management",
        },
        {
          title: "Tasks",
          single: "task",
          activeIcon: ActiveTasksIcon,
          icon: tasksIcon,
          path: "/tasks-management",
        },
      ],
    };
  },
  computed: {
    localDrawer: {
      get() {
        return this.drawer;
      },
      set(value) {
        this.$emit("update:drawer", value);
      },
    },
  },
  methods: {
    active(item) {
      return this.$route.path.includes(item.single);
    },
    async clearChache() {
      try {
        await apiClient
          .get("https://promxa.com/backend/public/clear-cache")
          .then((response) => {
            if (response.status === 200) {
              window.location.reload();
            }
          });
      } catch (error) {
        console.error("Error Clearing Cache:", error);
      }
    },
    async FreshDatabase() {
      try {
        await apiClient
          .get("https://promxa.com/backend/public/refresh-db")
          .then((response) => {
            if (response.status === 200) {
              window.location.reload();
            }
          });
      } catch (error) {
        console.error("Error Clearing Cache:", error);
      }
    },
  },
};
</script>
