<template>
  <v-select
    label="Select"
    v-model="selected_type"
    :items="status"
    :class="['contact-v-select', statusClass]"
    @update:modelValue="updateType()"
    variant=""
    no-details
  >
    <template v-slot:item="{ item, props }">
      <v-list-item
        v-bind="props"
        :title="item.raw"
        :style="{
          color:
            item.raw === 'phone_call'
              ? '#0464BC'
              : item.raw === 'virtual_meeting'
              ? '#2E6B30'
              : item.raw === 'f2f_meeting'
              ? '#D91313'
              : '',
          background:
            item.raw === 'phone_call'
              ? '#F5FAFE'
              : item.raw === 'virtual_meeting'
              ? '#E3F9EB'
              : item.raw === 'f2f_meeting'
              ? '#FEF3F2'
              : '',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '5px 0px',
        }"
      >
      </v-list-item>
    </template>
  </v-select>
</template>
<script>
import apiClient from "@/core/buildRequest";
import { useEventsStore } from "@/store/crm/useEventsStore";

export default {
  name: "EventType",
  props: ["item"],
  data() {
    return {
      selected_type: "",
      status: ["phone_call", "virtual_meeting", "f2f_meeting"],
    };
  },
  mounted() {
    this.selected_type = this.item.type;
  },
  computed: {
    statusClass() {
      switch (this.selected_type) {
        case "virtual_meeting":
          return "contact-v-select-green";
        case "phone_call":
          return "contact-v-select-blue";
        case "f2f_meeting":
          return "contact-v-select-red";
        default:
          return ""; // Return empty if no class is applicable
      }
    },
  },
  methods: {
    async updateType() {
      await apiClient
        .post(`/leads-managment/events/type/${this.id}`, {
          type: this.selected_type,
        })
        .then((res) => {
          console.log(res);
          const deal_store = useEventsStore();
          deal_store?.updateType(this.id, this.selected_type);
        });
    },
  },
};
</script>
