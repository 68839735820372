<template>
  <div class="d-flex justify-end align-center">
    <span
      @click="$emit('HideUploadedFiles')"
      class="cursor-pointer upload-file"
    >
      Upload File
    </span>
  </div>
  <div class="file-list-container pa-4">
    <v-table>
      <thead>
        <tr>
          <th class="text-left">File Name</th>
          <th class="text-left">Uploaded By</th>
          <th class="text-left">Date</th>
          <th class="text-left">Status</th>
          <th class="text-left">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(file, index) in files" :key="file.id">
          <td>
            <div class="d-flex ga-2">
              <img :src="getFileIcon(file.file_type)" alt="" />
              <div class="file-name d-flex flex-column gap-1">
                <div class="name">
                  {{ file.description || "No description" }}
                </div>
                <div class="size text-muted">{{ file.size || "24kb" }}</div>
              </div>
            </div>
          </td>
          <td>
            {{ file.user.salutation }}/{{ file.user.first_name }}
            {{ file.user.last_name }}
          </td>
          <td>{{ formatTimestamp(file.created_at) }}</td>
          <td>
            <v-progress-linear
              v-if="file.status === 'uploading'"
              :model-value="file.progress"
              color="primary"
              height="20"
            >
              <template v-slot:default="{ value }">
                <strong>{{ Math.ceil(value) }}%</strong>
              </template>
            </v-progress-linear>
            <span v-else-if="file.status === 'completed'" class="text-success">
              100%
            </span>
          </td>
          <td>
            <div class="d-flex gap-2">
              <!-- Menu Component -->
              <v-menu
                v-model="menu[file.id]"
                :close-on-content-click="true"
                location="end"
                offset-y
              >
                <template v-slot:activator="{ props }">
                  <v-btn variant="text" v-bind="props">
                    <svg
                      width="19"
                      height="36"
                      viewBox="0 0 19 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="19" height="36" rx="4" fill="white" />
                      <path
                        d="M11 12C11 12.8284 10.3284 13.5 9.5 13.5C8.67157 13.5 8 12.8284 8 12C8 11.1716 8.67157 10.5 9.5 10.5C10.3284 10.5 11 11.1716 11 12Z"
                        fill="#666666"
                      />
                      <path
                        d="M11 18C11 18.8284 10.3284 19.5 9.5 19.5C8.67157 19.5 8 18.8284 8 18C8 17.1716 8.67157 16.5 9.5 16.5C10.3284 16.5 11 17.1716 11 18Z"
                        fill="#666666"
                      />
                      <path
                        d="M11 24C11 24.8284 10.3284 25.5 9.5 25.5C8.67157 25.5 8 24.8284 8 24C8 23.1716 8.67157 22.5 9.5 22.5C10.3284 22.5 11 23.1716 11 24Z"
                        fill="#666666"
                      />
                    </svg>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    @click="downloadFile(file.file_path, file.description)"
                    class="d-flex align-center"
                  >
                    <v-icon left>mdi-download</v-icon>
                    <span>Download</span>
                  </v-list-item>
                  <v-list-item
                    @click="removeFile(index)"
                    class="d-flex align-center"
                  >
                    <v-icon left color="error">mdi-delete</v-icon>
                    <span>Delete</span>
                  </v-list-item>
                  <v-list-item
                    @click="retryUpload(index)"
                    class="d-flex align-center"
                  >
                    <v-icon left color="error">mdi-refresh</v-icon>
                    <span>Retry</span>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-btn
                v-if="file.status === 'completed'"
                color="error"
                variant="text"
                density="comfortable"
                @click="confirmRemove(index)"
              >
                <v-icon left color="error">mdi-close</v-icon>
                Remove
              </v-btn>
            </div>
          </td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<script>
import apiClient from "@/core/buildRequest";
import imgIcon from "@/assets/images/icons/imgIcon.svg";
import globalICon from "@/assets/images/icons/globalICon.svg";
import fileIcon from "@/assets/images/icons/fileIcon.svg";
import videoIcon from "@/assets/images/icons/videoIcon.svg";

export default {
  name: "FileListComponent",
  props: {
    itemId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      files: [],
      menu: {},
      imgIcon,
      globalICon,
      fileIcon,
      videoIcon,
    };
  },
  methods: {
    formatTimestamp(timestamp) {
      return new Date(timestamp).toLocaleString();
    },

    async removeFile(index) {
      await apiClient
        .get(
          `/leads-managment/delete-file/${this.itemId}/file=${this.files[index].id}`
        )
        .then(() => {
          this.$emit("remove-file", index);
          this.getFiles();
        });
    },

    confirmRemove(index) {
      this.fileToRemove = index;
      this.showConfirmDialog = true;
    },

    async handleConfirmRemove() {
      if (this.fileToRemove !== null) {
        this.$emit("remove-file", this.fileToRemove);
      }
      this.showConfirmDialog = false;
      this.fileToRemove = null;
    },

    async uploadFiles() {
      this.isUploading = true;
      const pendingFiles = this.files.filter(
        (file) => file.status === "pending"
      );

      for (const file of pendingFiles) {
        try {
          file.status = "uploading";
          file.progress = 0;
          for (let i = 0; i <= 100; i += 10) {
            file.progress = i;
            await new Promise((resolve) => setTimeout(resolve, 200));
          }
          await new Promise((resolve) => setTimeout(resolve, 500));
          file.status = "completed";
          file.progress = 100;
        } catch (error) {
          file.status = "failed";
          file.error = "Upload failed";
        }
      }
      this.isUploading = false;
    },

    async retryUpload(index) {
      const file = this.files[index];
      file.status = "pending";
      file.progress = 0;
      file.error = null;
      await this.uploadFiles();
    },
    async getFiles() {
      await apiClient
        .get(`/leads-managment/get-files/${this.itemId}`)
        .then((res) => {
          this.files = res.data.data;
        });
    },
    downloadFile(url, title = "promxa") {
      apiClient
        .get(url, { responseType: "arraybuffer" })
        .then((res) => {
          const contentType = res.headers["content-type"];
          this.forceFileDownload(res.data, title, contentType);
        })
        .catch((error) => {
          console.error("File download failed:", error);
        });
    },
    forceFileDownload(data, title, mimeType = "application/octet-stream") {
      try {
        const blob = new Blob([data], { type: mimeType });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", title);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error in forceFileDownload:", error);
      }
    },
  },
  mounted() {
    this.getFiles();
  },
  computed: {
    getFileIcon() {
      return (fileType) => {
        const fileTypeMapping = {
          svg: imgIcon,
          png: imgIcon,
          jpg: imgIcon,
          jpeg: imgIcon,
          pdf: fileIcon,
          doc: fileIcon,
          docx: fileIcon,
          mp4: videoIcon,
          mov: videoIcon,
        };
        return fileTypeMapping[fileType] || globalICon;
      };
    },
    hasUploadableFiles() {
      return this.files.some((file) => file.status === "pending");
    },
  },
};
</script>

<style scoped>
.upload-file {
  height: 36px;
  padding: 6px 18px;
  border-radius: 8px;
  background: #237276;
  color: white;
}
.file-name .name {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  color: #333333;
}
.file-name .size {
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  color: #4d4d4d;
}
</style>
