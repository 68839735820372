<template>
  <v-dialog v-model="dialogModel" max-width="800" persistent>
    <v-card class="pa-4">
      <v-card-title>Edit A Deal</v-card-title>

      <v-card-text>
        <div class="d-flex flex-column ga-5">
          <span class="error-message" v-for="e in errors" :key="e">{{
            e
          }}</span>

          <!-- Form Fields -->
          <template v-for="field in formFields" :key="field.id">
            <div
              :class="[
                field.type === 'autocomplete' ? 'filed-autocomplete' : 'field',
                'd-flex flex-column ga-2',
              ]"
            >
              <label
                v-if="field.type !== 'switch'"
                :ref="field.ref"
                :for="field.id"
              >
                {{ field.label }}
                <span v-if="field.required" class="required">*</span>
              </label>

              <!-- Regular Input -->
              <input
                v-if="field.type === 'input'"
                :id="field.id"
                v-model="formData[field.id]"
                :type="field.inputType || 'text'"
                @input="updateValidationClass(field.ref, formData[field.id])"
              />

              <!-- Date Input -->
              <input
                v-else-if="field.type === 'date'"
                :id="field.id"
                v-model="formData[field.id]"
                type="text"
                :ref="field.id"
                @input="updateValidationClass(field.ref, formData[field.id])"
              />

              <!-- Autocomplete -->
              <v-autocomplete
                v-else-if="field.type === 'autocomplete'"
                v-model="formData[field.id]"
                :items="field.items"
                :multiple="field.multiple"
                :item-value="field.itemValue"
                :item-title="field.itemTitle"
                hide-details
                chips
                @change="updateValidationClass(field.ref, formData[field.id])"
              />

              <!-- Switch -->
              <div
                v-else-if="field.type === 'switch'"
                class="d-flex justify-end align-center ga-2 custom-switch"
              >
                <label :for="field.id">{{ field.label }}</label>
                <v-switch
                  color="green"
                  v-model="formData[field.id]"
                  hide-details
                  inset
                  :true-value="1"
                  :false-value="0"
                ></v-switch>
              </div>

              <!-- Readonly Input -->
              <input
                v-else-if="field.type === 'readonly'"
                :id="field.id"
                :value="computedValues[field.id]"
                type="number"
                readonly
              />
            </div>
          </template>
        </div>
      </v-card-text>

      <v-card-actions class="sticky-footer-dialog">
        <v-spacer></v-spacer>
        <span class="cancel-btn cursor-pointer" @click="cancelDialog"
          >Cancel</span
        >
        <span class="choose-btn cursor-pointer" @click="saveDeal">Save</span>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import { getFieldError } from "@/utils/formValdiation.js";
import apiClient from "@/core/buildRequest";
import { useContactsStore } from "@/store/crm/useContactsStore";
import { useAccountsStore } from "@/store/crm/useAccountsStore";
import { useProjectsStore } from "@/store/crm/useProjectsStore";
import { useDealsStore } from "@/store/crm/useDealsStore";

export default {
  name: "DealDialog",

  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    contacts: {
      type: Array,
      default: () => [],
    },
    accounts: {
      type: Array,
      default: () => [],
    },
    id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      formData: {
        name: "",
        description: "",
        due_date: "",
        add_to_calendar: 1,
        assigness: [],
        contact_id: [],
        account_id: [],
        status: null,
        project_id: null,
        value: 0,
        total_cost: 0,
        incentive_percentage: 0,
        stage: null,
        incentive_value: null,
      },
      errors: [],
      fieldErrors: {},
      flatpickrInstances: {},
      contacts_store: useContactsStore(),
      accounts_store: useAccountsStore(),
      projects_store: useProjectsStore(),
      deals_store: useDealsStore(),
    };
  },

  computed: {
    dialogModel: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("update:dialog", value);
      },
    },

    computedValues() {
      return {
        incentive_value: this.calculateIncentiveValue(),
        profit: this.calculateProfit(),
      };
    },

    formFields() {
      return [
        {
          id: "name",
          label: "Deal Name",
          type: "input",
          required: true,
          ref: "dealNameInput",
        },
        {
          id: "description",
          label: "Description",
          type: "input",
          required: true,
          ref: "descriptionInput",
        },
        {
          id: "due_date",
          label: "Due Date",
          type: "date",
          required: true,
          ref: "dueDateInput",
        },
        {
          id: "add_to_calendar",
          label: "Add To Calendar",
          type: "switch",
        },
        {
          id: "assigness",
          label: "Assignees",
          type: "autocomplete",
          items: ["a1", "a2"],
          multiple: true,
          required: true,
          ref: "assigneesInput",
        },
        {
          id: "contact_id",
          label: "Contacts",
          type: "autocomplete",
          items: this.all_contacts,
          itemTitle: "contact_number",
          itemValue: "id",
          multiple: true,
          required: true,
          ref: "contactsInput",
        },
        {
          id: "stage",
          label: "Stage",
          type: "autocomplete",
          items: [
            "follow_up",
            "invoicing",
            "paid",
            "orientation",
            "negotation",
            "postponed",
          ],
          multiple: false,
          required: true,
          ref: "stagesInput",
        },
        {
          id: "account_id",
          label: "Accounts",
          type: "autocomplete",
          items: this.all_accounts,
          itemTitle: "account_number",
          itemValue: "id",
          multiple: false,
          required: true,
          ref: "accountsInput",
        },
        {
          id: "status",
          label: "Status",
          type: "autocomplete",
          items: ["active", "won", "lost"],
          required: true,
          ref: "statusInput",
        },
        {
          id: "project_id",
          label: "Project",
          type: "autocomplete",
          items: this.all_projects,
          itemTitle: "project_number",
          itemValue: "id",
          required: true,
          ref: "projectInput",
        },
        {
          id: "value",
          label: "Deal Value",
          type: "input",
          inputType: "number",
          required: true,
          ref: "dealValueInput",
        },
        {
          id: "total_cost",
          label: "Total Cost",
          type: "input",
          inputType: "number",
          required: true,
          ref: "totalCostInput",
        },
        {
          id: "incentive_percentage",
          label: "Incentive Percentage",
          type: "input",
          inputType: "number",
          required: true,
          ref: "incentivePercentageInput",
        },
        {
          id: "incentive_value",
          label: "Incentive Value",
          type: "readonly",
        },
        {
          id: "profit",
          label: "Profit",
          type: "readonly",
        },
        {
          id: "feedback",
          label: "Feedback",
          type: "input",
          required: false,
          ref: "dealFeedbackInput",
        },
      ];
    },
    all_contacts() {
      if (this.contacts_store?.getContacts.length == 0) {
        this.contacts_store.getAllContacts();
      }
      return this.contacts_store?.getContacts || [];
    },
    all_accounts() {
      if (this.accounts_store?.getAccounts.length == 0) {
        this.accounts_store.getAllAccounts();
      }
      return this.accounts_store?.getAccounts || [];
    },
    all_projects() {
      if (this.projects_store?.getProjects.length == 0) {
        this.projects_store.getAllProjects();
      }
      return this.projects_store?.getProjects || [];
    },
  },

  watch: {
    dialog(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.initializeFlatPickr();
        });
      } else {
        this.destroyFlatPickr();
      }
    },

    contacts: {
      handler(newVal) {
        this.formData.contact_id = newVal;
      },
      deep: true,
    },

    accounts: {
      handler(newVal) {
        this.formData.accounts = newVal;
      },
      deep: true,
    },
  },

  mounted() {
    this.getDeal();
    if (this.dialog) {
      this.initializeFlatPickr();
    }
  },

  beforeUnmount() {
    this.destroyFlatPickr();
  },
  created() {
    if (this.contacts.length > 0) {
      this.formData.contacts = this.contacts;
    }
    if (this.accounts.length > 0) {
      this.formData.accounts = this.accounts;
    }
  },
  methods: {
    async getDeal() {
      await apiClient.get(`/leads-managment/deals/${this.id}`).then((res) => {
        const this_deal = res.data.data;
        console.log(this_deal);
        this.formData.name = this_deal.name;
        this.formData.description = this_deal.description;
        this.formData.due_date = this_deal.due_date;
        this.formData.add_to_calendar = this_deal.add_to_calendar;
        this.formData.assigness = this_deal.members?.map((item) => {
          return item.id;
        });
        this.formData.contact_id = this_deal.contacts?.map((item) => {
          return item.id;
        });
        this.formData.account_id = this_deal.account[0]?.id;
        this.formData.status = this_deal.status;
        this.formData.project_id = this_deal.project?.id;
        this.formData.value = this_deal.value;
        this.formData.total_cost = this_deal.total_cost;
        this.formData.incentive_percentage = this_deal.incentive_percentage;
        this.formData.incentive_value = this_deal.incentive_value;
        this.formData.profit = this_deal.profit;
        this.formData.stage = this_deal.stage;
      });
    },
    initializeFlatPickr() {
      this.destroyFlatPickr();

      const dueDateEl = this.$refs.due_date?.[0];
      if (dueDateEl) {
        this.flatpickrInstances.dueDate = flatpickr(dueDateEl, {
          enableTime: false,
          dateFormat: "Y-m-d",
          onChange: (selectedDates) => {
            this.formData.due_date = selectedDates[0]
              ? selectedDates[0].toISOString().split("T")[0]
              : "";
            this.updateValidationClass("dueDateInput", this.formData.due_date);
          },
        });
      }
    },

    destroyFlatPickr() {
      Object.values(this.flatpickrInstances).forEach((instance) => {
        if (instance) {
          instance.destroy();
        }
      });
      this.flatpickrInstances = {};
    },

    calculateIncentiveValue() {
      if (this.formData.value) {
        return (
          ((Number(this.formData.value) - Number(this.formData.total_cost)) *
            Number(this.formData.incentive_percentage)) /
          100
        );
      }
      return 0;
    },

    calculateProfit() {
      if (this.formData.value) {
        return (
          Number(this.formData.value) -
          (Number(this.formData.total_cost) - this.calculateIncentiveValue())
        );
      }
      return 0;
    },

    validateForm() {
      this.errors = [];
      this.fieldErrors = {};
      let isValid = true;

      for (const field of this.formFields) {
        if (!field.required) continue;

        const value = this.formData[field.id];
        const error = getFieldError(field.label, value);

        if (error) {
          isValid = false;
          this.fieldErrors[field.id] = error;
          const el = this.$refs[field.ref]?.[0];
          if (el) {
            el.classList.add("error-validation");
          }
        }
      }

      return isValid;
    },

    updateValidationClass(ref, value) {
      const el = this.$refs[ref]?.[0];
      if (el) {
        el.classList[!value ? "add" : "remove"]("error-validation");
      }
    },

    resetForm() {
      Object.keys(this.formData).forEach((key) => {
        this.formData[key] = Array.isArray(this.formData[key]) ? [] : "";
      });
      this.formData.add_to_calendar = true;
      this.errors = [];
      this.fieldErrors = {};
    },

    cancelDialog() {
      this.$emit("update:dialog", false);
    },

    async saveDeal() {
      console.log(this.formData);

      if (!this.validateForm()) return;

      try {
        await apiClient
          .post(`/leads-managment/deals/${this.id}`, this.formData)
          .then((res) => {
            this.store?.updateDeal(res.data.data);
            this.$emit("update:dialog", false);
          });
      } catch (error) {
        this.errors.push(error.response?.data?.msg || "An error occurred");
        console.error(error);
      }
    },
  },
};
</script>
