<template>
  <div class="profile-box-container w-100">
    <div class="d-flex justify-space-between align-items-center w-100">
      <div class="d-flex flex-column ga-4">
        <label for="" class="profile-box-title">Profile Picture</label>
        <label for="" class="profile-box-desc"
          >A picture helps people to recognize you
        </label>
      </div>
      <div class="d-flex align-center ga-4">
        <span class="profile-picture position-relative">
          <img :src="profile.image" :alt="profile.first_name" />
          <span class="new-profile">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 14.5C11 14.5 11.8333 14.1597 12.5 13.4792C13.1667 12.7986 13.5 11.9722 13.5 11C13.5 10.0417 13.1667 9.21875 12.5 8.53125C11.8333 7.84375 11 7.5 10 7.5C9 7.5 8.16667 7.84375 7.5 8.53125C6.83333 9.21875 6.5 10.0451 6.5 11.0104C6.5 11.9757 6.83333 12.7986 7.5 13.4792C8.16667 14.1597 9 14.5 10 14.5ZM10 13C9.41667 13 8.9375 12.8047 8.5625 12.414C8.1875 12.0233 8 11.545 8 10.9792C8 10.425 8.1875 9.9566 8.5625 9.57396C8.9375 9.19132 9.41667 9 10 9C10.5833 9 11.0625 9.19132 11.4375 9.57396C11.8125 9.9566 12 10.425 12 10.9792C12 11.545 11.8125 12.0233 11.4375 12.414C11.0625 12.8047 10.5833 13 10 13ZM3.5 17C3.09722 17 2.74653 16.8507 2.44792 16.5521C2.14931 16.2535 2 15.9028 2 15.5V6.5C2 6.09722 2.14931 5.74653 2.44792 5.44792C2.74653 5.14931 3.09722 5 3.5 5H6L7.5 3H12.5L14 5H16.5C16.9125 5 17.2656 5.14931 17.5594 5.44792C17.8531 5.74653 18 6.09722 18 6.5V15.5C18 15.9028 17.8531 16.2535 17.5594 16.5521C17.2656 16.8507 16.9125 17 16.5 17H3.5ZM3.5 15.5H16.5V6.5H13.25L11.7479 4.5H8.25L6.75 6.5H3.5V15.5Z"
                fill="white"
              />
            </svg>
          </span>
        </span>
        <span class="d-flex flex-column ga-2">
          <span class="profile-box-main-text">{{
            profile.first_name + " " + profile.last_name
          }}</span>
          <span class="profile-box-second-text">{{ profile?.role }}</span>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProfilePicture",
  components: {},
  props: ["profile"],
  data() {
    return {};
  },
  beforeCreate() {},
  methods: {},
};
</script>
