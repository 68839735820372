<template>
  <div
    :class="[
      'd-flex flex-column justify-space-between align-center h-100 position-relative video-background',
    ]"
    :style="{
      backgroundImage: `url(${post?.file?.preview})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }"
  >
    <video width="100%" :src="post?.file?.preview" autoplay muted loop></video>
    <div class="header d-flex flex-column ga-2 z-index-2">
      <img :src="HeaderOne" alt="" />
      <img :src="HeaderTwo" alt="" />
    </div>
    <div class="footer z-index-2">
      <img :src="Footer" alt="" />
    </div>
  </div>
</template>
<script>
import { publishPost } from "@/store/publishPost/publishPost.js";
import HeaderOne from "@/components/publish_post_components/reviews/svgs/facebookReelHeader1.svg";
import HeaderTwo from "@/components/publish_post_components/reviews/svgs/facebookReelHeader2.svg";
import Footer from "@/components/publish_post_components/reviews/svgs/facebookReelFooter.svg";
export default {
  name: "FacebookReelReview",
  components: {},
  data() {
    return {
      HeaderOne,
      HeaderTwo,
      Footer,
      store: publishPost(),
      post: {},
    };
  },
  watch: {
    "store.getCarouselSlider": {
      handler() {
        this.post = this.store.post["facebook"].find(
          (item) => item.type === "reel"
        );
      },
      immediate: true,
    },
  },
};
</script>

<style></style>
