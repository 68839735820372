<template>
  <div class="choose-opjectives">
    <div class="choose-opjectives-header mb-3">
      <span class="title"> Create new </span>
    </div>
    <div class="advertising-objective-card">
      <div class="card-left-side">
        <p class="">Advertising objective</p>
        <div
          v-for="(object, i) in saveData"
          :key="i"
          class="options-group radio-button"
        >
          <p class="type-objective">{{ object.objecttype }}</p>
          <input
            name="radio-group"
            :id="object.id"
            v-model="objectiveInputs"
            class="radio-button__input"
            @change="updateObjectiveInputs(i)"
            type="radio"
            :checked="object.id === selectedObjectiveId ? true : false"
            :aria-checked="object.id === selectedObjectiveId ? true : false"
          />
          <label
            :for="object.id"
            class="radio-button__label"
            :class="{ active: object.checked }"
          >
            <span class="radio-button__custom"></span>
            {{ object.title }}
          </label>
        </div>
      </div>
      <div class="card-right-side">
        <v-card
          v-if="selectedObjective"
          style="min-height: 100%"
          class="pa-4"
          elevation="2"
        >
          <div class="d-flex align-center ga-5 card-header mb-6">
            <img :src="selectedObjective.imgSrc" alt="" />
            <div class="objective-type-text">
              <h3 class="">{{ selectedObjective.title }}</h3>
              <p class="">{{ selectedObjective.description }}</p>
            </div>
          </div>
          <div class="card-body">
            <div v-if="selectedObjective.objectiveTo" class="objective-details">
              <h4 class="mb-4">Choose this objective to:</h4>
              <p
                class="d-flex ga-1 align-center"
                v-for="(objective, i) in selectedObjective.objectiveTo"
                :key="i"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                  fill="#121415"
                >
                  <path
                    d="M389-294.69 224.23-458.46 246.77-482 389-339.77 713.23-663l22.54 22.54L389-294.69Z"
                  />
                </svg>
                <span> {{ objective }} </span>
              </p>
            </div>
            <div
              v-else-if="selectedObjective.CampaignType"
              class="campaign-type"
            >
              <p>Campaign type</p>
              <div class="options-group radio-button mb-2 w-100">
                <input
                  name="app-promotion-select"
                  @change="updateCampaignType"
                  id="app-install"
                  class="radio-button__input"
                  type="radio"
                  v-model="selectedOption"
                  value="APP_INSTALL"
                />
                <label for="app-install" class="radio-button__label">
                  <span class="radio-button__custom"></span>
                  <p>
                    <span class="d-block mb-1">App install</span>
                    <span class="d-block"
                      >Get people to install and use your app.
                    </span>
                  </p>
                </label>
              </div>
              <div class="options-group radio-button w-100">
                <input
                  name="app-promotion-select"
                  id="app-retargeting"
                  class="radio-button__input"
                  type="radio"
                  @change="updateCampaignType"
                  v-model="selectedOption"
                  value="APP_RETARGETING"
                />
                <label for="app-retargeting" class="radio-button__label">
                  <span class="radio-button__custom"></span>
                  <p>
                    <span class="d-block mb-1"> App retargeting </span>
                    <span class="d-block">
                      Re-engage existing app users to take action in your app.
                    </span>
                  </p>
                </label>
              </div>
              <div class="hr"></div>
            </div>
            <div class="smart-campaign" v-if="selectedObjective.smartCampaign">
              <p class="title" v-if="!selectedObjective.CampaignType">
                Campaign type
              </p>
              <div class="checked-label">
                <label class="toggle-switch">
                  <input
                    @change="updateSmartCampaign"
                    type="checkbox"
                    v-model="smartCampaign"
                    id="smart-campaign"
                  />
                  <div class="toggle-switch-background">
                    <div class="toggle-switch-handle"></div>
                    <svg
                      class="closemark"
                      xmlns="http://www.w3.org/2000/svg"
                      height="30px"
                      viewBox="0 -960 960 960"
                      width="30px"
                      fill=""
                    >
                      <path
                        d="M291-253.85 253.85-291l189-189-189-189L291-706.15l189 189 189-189L706.15-669l-189 189 189 189L669-253.85l-189-189-189 189Z"
                      />
                    </svg>
                    <svg
                      class="checkmark"
                      xmlns="http://www.w3.org/2000/svg"
                      height="30px"
                      viewBox="0 -960 960 960"
                      width="30px"
                      fill=""
                    >
                      <path
                        d="M389-259.35 187.35-460l58.89-59.89L389-377.13l324.76-323.76L772.65-642 389-259.35Z"
                      />
                    </svg>
                  </div>
                </label>
                <label for="smart-campaign" class="label-for-input">
                  smart+ campaign
                </label>
              </div>
              <p class="description-select">
                Improve ad performance with automated campaign management and
                smart optimization (placement selection, audience targeting, and
                more).
              </p>
            </div>
            <div
              class="productSource campaign-type"
              v-if="selectedObjective.productSource"
            >
              <p class="title">Product source</p>
              <div class="options-group radio-button">
                <input
                  name="catalog"
                  id="catalog"
                  class="radio-button__input"
                  type="radio"
                  v-model="catalog"
                  checked="true"
                  aria-checked="true"
                />
                <label for="catalog" class="radio-button__label">
                  <span class="radio-button__custom"></span>
                  <p>
                    <span class="d-block mb-1"> Catalog </span>
                    <span class="d-block">
                      Promote your products from a catalog using Video Shopping
                      Ads.
                    </span>
                  </p>
                </label>
              </div>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/tiktok/index.css";
import { tiktokData } from "@/store/tiktokStore/tiktokData.js";
import { mapActions, mapState } from "pinia";

export default {
  data() {
    return {
      saveData: this.objectiveArr,
      selectedObjectiveId: null,
      objectiveInputs: this.objectiveInput,
      smartCampaign: null,
      selectedOption: "APP_INSTALL",
      catalog: true,
    };
  },
  computed: {
    ...mapState(tiktokData, ["objectiveArr", "objectiveInput"]),
    groupedObjectives() {
      return this.objectiveArr.reduce((acc, objective) => {
        if (!acc[objective.objecttype]) {
          acc[objective.objecttype] = [];
        }
        acc[objective.objecttype].push(objective);
        return acc;
      }, {});
    },
    selectedObjective() {
      return this.objectiveArr.find(
        (obj) => obj.id === this.selectedObjectiveId
      );
    },
  },
  mounted() {
    this.saveData = this.objectiveArr;
    if (localStorage.getItem("selected_object")) {
      this.selectedObjectiveId = Number(
        localStorage.getItem("selected_object")
      );
    } else {
      this.selectedObjectiveId = this.saveData[0].id;
      this.saveData[0].checked = true;
    }
    if (localStorage.getItem("smart_campaign")) {
      this.smartCampaign = localStorage.getItem("smart_campaign") === "true";
    } else {
      localStorage.setItem("smart_campaign", false);
      this.smartCampaign = false;
    }
    if (localStorage.getItem("app_promotion_type")) {
      this.selectedOption = localStorage.getItem("app_promotion_type");
    } else {
      localStorage.setItem("app_promotion_type", "appInstall");
    }
    localStorage.setItem(
      "selected_objective",
      this.selectedObjective.objective_type
    );
  },
  methods: {
    ...mapActions(tiktokData, ["setAllInputsStatus"]),
    updateObjectiveInputs(index) {
      this.selectedObjectiveId = this.saveData[index].id;
      this.saveData = this.saveData.map((item, i) => ({
        ...item,
        checked: i === index,
      }));
      this.$emit("dedicatedCampaign", this.selectedObjectiveId);
      localStorage.setItem("selected_object", this.selectedObjectiveId);
      localStorage.setItem(
        "selected_objective",
        this.selectedObjective.objective_type
      );
      if (this.selectedObjective.objective_type === "PRODUCT_SALES") {
        localStorage.setItem("campaign_product_source", "CATALOG");
      } else {
        localStorage.setItem("campaign_product_source", null);
      }
    },
    updateSmartCampaign() {
      localStorage.setItem("smart_campaign", this.smartCampaign);
      this.setAllInputsStatus(this.smartCampaign);
    },
    updateCampaignType() {
      this.$emit("iosDedicatedStatusAction", this.selectedOption);
      localStorage.setItem("app_promotion_type", this.selectedOption);
      localStorage.setItem("campaign_type", false);
    },
  },
};
</script>
