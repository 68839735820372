<template>
  <div class="profile-box-container w-100">
    <div class="mb-3">
      <label for="" class="profile-box-title">General preference</label>
    </div>
    <div
      class="d-flex flex-lg-row flex-sm-column flex-md-column align-items-center justify-space-between py-5"
    >
      <div class="d-flex align-center ga-1">Language</div>
      <div class="profile-box-second-text">{{ profile.lang }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "General",
  components: {},
  props: ["profile"],
  data() {
    return {};
  },
  beforeCreate() {},
  methods: {},
};
</script>
