<template>
  <div class="pa-4">
    <v-row no-gutters>
      <v-col cols="12">
        <FileListComponent
          v-if="showUploadFile"
          :files="files"
          @remove-file="handleRemoveFile"
          @HideUploadedFiles="showUploadFile = false"
          :itemId="item.id"
        />
        <FileUploadComponent
          v-if="!showUploadFile"
          @ShowUploadedFiles="showUploadFile = true"
          @files-added="handleFilesAdded"
          :itemId="item.id"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FileUploadComponent from "@/components/crm/leads/comments/FileUploadComponent.vue";
import FileListComponent from "@/components/crm/leads/comments/FileListComponent.vue";

export default {
  name: "FileManagerParent",
  components: {
    FileUploadComponent,
    FileListComponent,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      files: [],
      showUploadFile: false,
    };
  },
  mounted() {
    if (this.item && this.item.files) {
      this.files = [...this.item.files];
    }

    if (this.files.length === 0) {
      this.showUploadFile = true;
    } else {
      this.showUploadFile = false;
    }
  },
  methods: {
    handleFilesAdded(newFiles) {
      this.files.push(...newFiles);
    },
    handleRemoveFile(index) {
      this.files.splice(index, 1);
    },
  },
};
</script>
