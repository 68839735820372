export const getStatusColor = (status) => {
    const colors = {
      pending: 'grey',
      uploading: 'primary',
      completed: 'success',
      failed: 'error'
    }
    return colors[status] || 'grey'
  }
  
  export const createVideoEntry = (file) => {
    return {
      id: Date.now().toString(36) + Math.random().toString(36).substr(2),
      file,
      status: 'pending',
      progress: 0,
      error: null,
      timestamp: new Date().toISOString()
    }

  }