<template>
  <div class="publisher-list-container my-3 px-1 ">
    <div class="publisher-list-header">
      <label for="" class="roboto-bold">Publisher block lists</label>
      <button class="d-flex align-center ga-1">
        <span>Edit</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="20px"
          viewBox="0 -960 960 960"
          width="20px"
          fill="#0a7abf"
        >
          <path
            d="M216-216h51l375-375-51-51-375 375v51Zm-72 72v-153l498-498q11-11 23.84-16 12.83-5 27-5 14.16 0 27.16 5t24 16l51 51q11 11 16 24t5 26.54q0 14.45-5.02 27.54T795-642L297-144H144Zm600-549-51-51 51 51Zm-127.95 76.95L591-642l51 51-25.95-25.05Z"
          />
        </svg>
      </button>
    </div>
    <div class="publisher-body">
        <p>{{ publisher }}</p>
        
    </div>
  </div>
</template>

<script>
export default{
    data() {    
        return {
            publisher: "Publisher"
        }
    }
}

</script>