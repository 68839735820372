<template>
  <div class="placement-card card-pattern">
    <div class="card-header mb-4">
      <label for="">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#007e59"
        >
          <path
            d="m423.23-309.85 268.92-268.92L650-620.92 423.23-394.15l-114-114L267.08-466l156.15 156.15ZM480.07-100q-78.84 0-148.21-29.92t-120.68-81.21q-51.31-51.29-81.25-120.63Q100-401.1 100-479.93q0-78.84 29.92-148.21t81.21-120.68q51.29-51.31 120.63-81.25Q401.1-860 479.93-860q78.84 0 148.21 29.92t120.68 81.21q51.31 51.29 81.25 120.63Q860-558.9 860-480.07q0 78.84-29.92 148.21t-81.21 120.68q-51.29 51.31-120.63 81.25Q558.9-100 480.07-100Zm-.07-60q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
          />
        </svg>
        <span class="roboto-black">Placements </span>
      </label>
      <p class="roboto-regular">
        Choose where your ad appears across Meta technologies.
      </p>
    </div>
    <div class="card-body">
      <div class="advantage-placements">
        <div
          class="advantage-placements-header mb-2 d-flex align-center justify-space-between"
        >
          <p class="roboto-bold" v-if="!multiplePlacement">
            Advantage+ placements
          </p>
          <button @click="multiplePlacementStatus()" v-if="!multiplePlacement">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="20px"
              viewBox="0 -960 960 960"
              width="20px"
              fill="#0a7abf"
            >
              <path
                d="M216-216h51l375-375-51-51-375 375v51Zm-72 72v-153l498-498q11-11 23.84-16 12.83-5 27-5 14.16 0 27.16 5t24 16l51 51q11 11 16 24t5 26.54q0 14.45-5.02 27.54T795-642L297-144H144Zm600-549-51-51 51 51Zm-127.95 76.95L591-642l51 51-25.95-25.05Z"
              />
            </svg>
            <span>Edit</span>
          </button>
        </div>
        <div class="advantage-placements-body">
          <p class="roboto-reguler" v-if="!multiplePlacement">
            Your budget will be allocated by Meta's delivery system across
            multiple placements based on where it's likely to perform best.
          </p>
          <div class="my-3" v-else>
            <div
              class="my-form"
              v-for="select in placementsType"
              :key="select.id"
            >
              <div class="ga-3 align-start px-0">
                <input
                  :v-model="select.model"
                  :id="select.id"
                  type="radio"
                  name="select_placement_type"
                  :value="select.value"
                  :checked="select.checked"
                />
                <div class="d-flex flex-column ga-1">
                  <label
                    @click="setPlaceMentStorage(select.model)"
                    :for="select.id"
                    class="d-block"
                    style="font-size: 14px; color: #1c2b33"
                  >
                    <span class="d-block">
                      {{ select.title }}
                    </span>
                    <p>
                      {{ select.descriptions }}
                    </p>
                  </label>
                </div>
              </div>
            </div>

            <div
              class="manual-placement-select my-2"
              v-if="manualPlacementSelect"
            >
              <label class="checkbox">
                <input
                  type="checkbox"
                  @click="setValueTestStorage()"
                  v-model="runTestCompaire"
                />
                <span class="checkmark"></span>
                <span class="text d-flex align-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="20px"
                    viewBox="0 -960 960 960"
                    width="20px"
                    fill="#261E47"
                  >
                    <path
                      d="M216-144q-45 0-65-40.5t8-75.5l201-244v-240h-35.79q-15.21 0-25.71-10.29t-10.5-25.5q0-15.21 10.35-25.71T324-816h312q15.3 0 25.65 10.29Q672-795.42 672-780.21t-10.35 25.71Q651.3-744 636-744h-36v240l201.02 243.79Q830-225 810-184.5 790-144 744-144H216Zm102-120h324L518-416h-76L318-264Zm-102 48h528L528-480v-264h-96v264L216-216Zm264-264Z"
                    />
                  </svg>
                  Run a 4-day A/B test to compare manual against Advantage+
                  placements</span
                >
              </label>
              <div class="devices mb-2">
                <p class="roboto-bold">Devices</p>
                <v-select
                  hide-details
                  v-model="deviceSelection"
                  :items="['All devices (recommended)', ...devices]"
                  multiple
                  density="compact"
                ></v-select>
              </div>
              <div class="platforms mb-2">
                <p class="roboto-bold mb-2">Platforms</p>
                <div class="platforms-container">
                  <label
                    class="checkbox"
                    v-for="platform in platforms"
                    :key="platform.id"
                  >
                    <input
                      type="checkbox"
                      :checked="selectedPlatforms.includes(platform.model)"
                      @change="
                        setPlatformStorage(
                          platform.model,
                          $event.target.checked
                        )
                      "
                      :v-model="platform.model"
                    />
                    <span class="checkmark"></span>
                    <span class="text"> {{ platform.platformName }}</span>
                  </label>
                </div>
              </div>
              <PlacemenSelect />
            </div>
          </div>
          <v-divider class="my-3"></v-divider>
          <button
            class="show-hide-options"
            @click="toggleShowMoreOptionsPlacement"
          >
            <div v-if="!hideMoreOptionsPlacement">
              <span>Show more options</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="20px"
                viewBox="0 -960 960 960"
                width="20px"
                fill="#0a7abf"
              >
                <path d="M480-384 288-576h384L480-384Z" />
              </svg>
            </div>
            <div v-else>
              <span>Hide options</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="20px"
                viewBox="0 -960 960 960"
                width="20px"
                fill="#0a7abf"
              >
                <path d="m288-384 192-192 192 192H288Z" />
              </svg>
            </div>
          </button>
          <PlacementMoreOptions v-if="hideMoreOptionsPlacement"  />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PlacemenSelect from "@/components/meta/secound-page-componet/placement/PlacementSelect.vue";
import PlacementMoreOptions from "./placement/PlacementMoreOptions.vue";
export default {
  components: { PlacemenSelect, PlacementMoreOptions },
  data() {
    return {
      multiplePlacement: false,
      placementsType: [
        {
          id: 0,
          model: "advantage_placement",
          title: "Advantage+ placements (Recommended)",
          descriptions:
            "Use Advantage+ placements to maximise your budget and help show your ads to more ",
          checked: true,
        },
        {
          id: 1,
          model: "manual_placement",
          title: "Manual placements",
          descriptions:
            "Manually choose the places to show your ad. The more placements you select, the more opportunities you'll have to reach your target audience and achieve your business goals.",
          checked: false,
        },
      ],
      manualPlacementSelect: false,
      runTestCompaire: false,
      devices: ["Mobile", "Desktop"],
      selectedDevices: [],
      platforms: [
        {
          id: 0,
          platformName: "Facebook",
          model: "facebook",
        },
        {
          id: 1,
          platformName: "Instagram",
          model: "instagram",
        },
        {
          id: 2,
          platformName: "Audience Network",
          model: "audience_network",
        },
        {
          id: 3,
          platformName: "Messenger",
          model: "messenger",
        },
      ],
      selectedPlatforms: [],
      hideMoreOptionsPlacement: false,
    };
  },
  mounted() {
    localStorage.setItem("meta_placemet_type", this.placementsType[0].model);
    this.selectedDevices = [...this.devices];
    localStorage.setItem("meta_devices_type", [...this.devices]);
    this.selectedPlatforms = ["facebook", "audience_network", "messenger"];
    localStorage.setItem(
      "meta_platform_type",
      JSON.stringify(this.selectedPlatforms)
    );
  },
  computed: {
    deviceSelection: {
      get() {
        localStorage.setItem("meta_devices_type", this.selectedDevices);
        return this.selectedDevices.length === this.devices.length
          ? ["All devices (recommended)"]
          : this.selectedDevices;
      },
      set(value) {
        if (value.includes("All devices (recommended)")) {
          this.selectedDevices = [...this.devices];
        } else {
          this.selectedDevices = value;
        }
      },
    },
  },
  methods: {
    multiplePlacementStatus() {
      this.multiplePlacement = !this.multiplePlacement;
    },
    setPlaceMentStorage(value) {
      console.log(value);
      localStorage.setItem("meta_manual_placement", value);
      if (value === "manual_placement") {
        this.manualPlacementSelect = true;
      } else {
        this.manualPlacementSelect = false;
      }
    },
    setValueTestStorage() {
      localStorage.setItem("run_test_compaire", this.runTestCompaire);
    },
    setPlatformStorage(value, isChecked) {
      if (isChecked) {
        if (!this.selectedPlatforms.includes(value)) {
          this.selectedPlatforms.push(value);
        }
      } else {
        this.selectedPlatforms = this.selectedPlatforms.filter(
          (item) => item !== value
        );
      }
      console.log(this.selectedPlatforms);
      localStorage.setItem(
        "meta_platform_type",
        JSON.stringify(this.selectedPlatforms)
      );
    },
    toggleShowMoreOptionsPlacement() {
      this.hideMoreOptionsPlacement = !this.hideMoreOptionsPlacement;
    },
  },
};
</script>
