import { defineStore } from "pinia";
import apiClient from "@/core/buildRequest";

export const sendRequest = defineStore("sendRequest", {
  state: () => ({
    ad_account_id: localStorage.getItem("meta_ad_account_id"),
  }),
  actions: {
    getAdSet() {},
    async createCampaign() {
      // const start_date = JSON.parse(localStorage.getItem("setArrayStartDate"));
      // const end_date = JSON.parse(localStorage.getItem("setArrayEndtDate"));
      const FormData = require("form-data");

      // First Object
      const dataPart1 = new FormData();
      dataPart1.append("name", localStorage.getItem("meta_campaign_name"));
      dataPart1.append(
        "objective",
        localStorage.getItem("meta_selected_objective")
      );
      dataPart1.append("status", "ACTIVE");
      dataPart1.append(
        "special_ad_categories",
        localStorage.getItem("selected_ad_categories_meta")
      );
      dataPart1.append("optimization_goal", "OTHER_VALID_GOALS");
      dataPart1.append(
        "daily_budget",
        Number(localStorage.getItem("value_meta_Budget")) || 100
      );

      dataPart1.append("billing_event", "IMPRESSIONS");

      const metaSelectedCountryCodes = JSON.parse(localStorage.getItem("metaSelectedCountryCodes"));

      
      const countriesArray = Array.isArray(metaSelectedCountryCodes)
        ? metaSelectedCountryCodes
        : [metaSelectedCountryCodes]; // Wrap in an array if it's a single object
      


        dataPart1.append(
          "targeting[geo_locations][countries]",
          JSON.stringify(countriesArray) // Convert the array to a JSON string
        );

      console.log( "type", typeof countriesArray);
      

      dataPart1.append("targeting[interests][0][id]", ["6003139266461"]);
      dataPart1.append("targeting[interests][0][name]", "Movies");
      dataPart1.append("promoted_object", "");
      dataPart1.append(
        "bid_strategy",
        [localStorage.getItem("bid_strategy_type")] || [
          "LOWEST_COST_WITHOUT_CAP",
        ]
      );
      dataPart1.append(
        "buying_type",
        localStorage.getItem("meta_buying_type") || "AUCTION"
      );
      dataPart1.append(
        "dynamic_creative_optimization_mode",
        localStorage.getItem("dynamic_creative_status") === "false"
          ? "OFF"
          : "ON" || "OFF"
      );
      dataPart1.append(
        "stop_time",
        localStorage.getItem("meta_end_date") || ""
      );
      dataPart1.append(
        "start_time",
        localStorage.getItem("meta_start_date") ||
          new Date().toISOString().split("T")[0]
      );
      let fromLS = JSON.parse(localStorage.getItem("budgetSchedulingPanels"));
      console.log("asd", fromLS);

      if (Array.isArray(fromLS)) {
        const transformedArray = fromLS.map((e) => ({
          time_start: e.startDateTime,
          time_end: e.endDateTime,
          budget_value: e.budgetValue,
          budget_value_type: e.daily_budget_type_selected.value,
          recurrence_type: "ONE_TIME",
        }));

        dataPart1.append(
          "budget_schedule_specs",
          JSON.stringify(transformedArray)
        );
      } else {
        console.error("The retrieved data is not an array:", fromLS);
      }

      dataPart1.append("campaign_optimization_type", "NONE");
      dataPart1.append(
        "is_skadnetwork_attribution",
        localStorage.getItem("is_skadnetwork_attribution") === "true"
          ? "true"
          : "false" || "false"
      );
      dataPart1.append("is_using_l3_schedule", "false");
      dataPart1.append("topline_id", "");
      dataPart1.append(
        "spend_cap",
        Number(localStorage.getItem("setLimitValue")) || 100
      );
      dataPart1.append("special_ad_category_country[0]", "EG");
      // dataPart1.append("source_campaign_id", "");

      // Second Object
      const dataPart2 = new FormData();
      dataPart2.append("adgroup_name", localStorage.getItem("metaAdSetName"));
      dataPart2.append(
        "adgroup_daily_budget",
        Number(localStorage.getItem("value_meta_Budget")) || 100
      );
      dataPart2.append(
        "adgroup_bid_amount",
        localStorage.getItem("value_meta_Budget_max")
      );
      dataPart2.append(
        "adgroup_billing_event",
        localStorage.getItem("charged_item") || "IMPRESSIONS"
      );
      dataPart2.append(
        "adgroup_optimization_goal",
        localStorage.getItem("meta_find_winner")
      );
      // dataPart2.append("campaign_id", "120212114758460358");
      dataPart2.append(
        "adgroup_promoted_object[page_id]",
        localStorage.getItem("facebook_page_id")
      );
      // cant-send it
      dataPart2.append(
        "adgroup_targeting[facebook_positions][0]",
        localStorage.getItem("checked_placements")
      );

      dataPart2.append(
        "adgroup_targeting[geo_locations][countries]",
        JSON.parse([localStorage.getItem("metaSelectedCountryCodes") ])
      );

      dataPart2.append(
        "adgroup_targeting[geo_locations][regions][0][key]",
        "3847"
      );

      dataPart2.append(
        "adgroup_targeting[geo_locations][cities][0][key]",
        "777934"
      );

      dataPart2.append(
        "adgroup_targeting[geo_locations][cities][0][radius]",
        localStorage.getItem("metaLocationIRadious")
      );

      dataPart2.append(
        "adgroup_targeting[geo_locations][cities][0][distance_unit]",
        "mile"
      );

      dataPart2.append(
        "adgroup_targeting[genders][0]",
        localStorage.getItem("meta_gender")
      );
      dataPart2.append(
        "adgroup_targeting[age_max]",
        localStorage.getItem("set_end_date")
      );
      dataPart2.append(
        "adgroup_targeting[age_min]",
        localStorage.getItem("set_start_date")
      );
      //
      dataPart2.append(
        "adgroup_targeting[publisher_platforms]",
        localStorage.getItem("meta_platform_type") || ["All"]
      );
      dataPart2.append(
        "adgroup_targeting[device_platforms]",
        localStorage.getItem("device_platforms") || ["All"]
      );
      dataPart2.append(
        "adgroup_targeting[flexible_spec][0][interests][0][id]",
        "6002925969459"
      );
      dataPart2.append(
        "adgroup_targeting[flexible_spec][0][interests][0][name]",
        "watching movies"
      );
      dataPart2.append(
        "adgroup_targeting[flexible_spec][0][interests][1][id]",
        "6002839660079"
      );
      dataPart2.append(
        "adgroup_targeting[flexible_spec][0][interests][1][name]",
        "Cosmetics (personal care)"
      );
      dataPart2.append("adgroup_status", "ACTIVE");
      dataPart2.append("adgroup_dsa_beneficiary", "brandifyuae");

      if (Array.isArray(fromLS)) {
        const transformedArray = fromLS.map((e) => ({
          time_start: e.startDateTime,
          time_end: e.endDateTime,
          budget_value: e.budgetValue,
          budget_value_type: e.daily_budget_type_selected.value,
          recurrence_type: "ONE_TIME",
        }));
        

        dataPart2.append(
          "adgroup_budget_schedule_specs",
          JSON.stringify(transformedArray)
        );
      } else {
        console.error("The retrieved data is not an array:", fromLS);
      }

      dataPart2.append(
        "adgroup_start_time",
        localStorage.getItem("meta_start_date") ||
          new Date().toISOString().split("T")[0]
      );
      dataPart2.append(
        "adgroup_end_time",
        localStorage.getItem("meta_end_date") || ""
      );
      dataPart2.append(
        "adgroup_tune_for_category",
        localStorage.getItem("selected_ad_categories_meta") || ["NONE"]
      );
      dataPart2.append("adgroup_source_adset_id", "");
      dataPart2.append("adgroup_rf_prediction_id", "");

      // can't find input for this
      dataPart2.append("adgroup_multi_optimization_goal_weight", "BALANCED");

      dataPart2.append(
        "adgroup_min_budget_spend_percentage",
        localStorage.getItem("meta_daily_minimum")
      );
      dataPart2.append(
        "adgroup_max_budget_spend_percentage",
        localStorage.getItem("meta_daily_maximum")
      );
      dataPart2.append(
        "adgroup_lifetime_spend_cap",
        localStorage.getItem("setLimitValue")
      );
      dataPart2.append(
        "adgroup_lifetime_min_spend_target",
        localStorage.getItem("meta_daily_minimum")
      );
      // can't find input for this
      dataPart2.append("adgroup_lifetime_imps", "1000000");
      dataPart2.append(
        "adgroup_lifetime_budget",
        localStorage.getItem("meta_budget_amount") || 350
      );
      dataPart2.append("adgroup_is_dynamic_creative", "false");
      dataPart2.append("adgroup_existing_customer_budget_percentage", "50");
      dataPart2.append("adgroup_destination_type", "WEBSITE");
      dataPart2.append("adgroup_daily_spend_cap", "5000");
      dataPart2.append(
        "adgroup_daily_min_spend_target",
        localStorage.getItem("meta_daily_minimum")
      );
      dataPart2.append("adgroup_daily_imps", "10000");
      dataPart2.append("adgroup_adset_schedule[0][start_minute]", "480");
      dataPart2.append("adgroup_adset_schedule[0][end_minute]", "1020");
      dataPart2.append("adgroup_adset_schedule[0][days][0]", "1");
      dataPart2.append("adgroup_adset_schedule[0][timezone_type]", "USER");

      const dataPart3 = new FormData();

      dataPart3.append("ad_name", "first_ad_Ad");

      dataPart3.append("ad_creative[creative_id]", "120213238868130358");
      dataPart3.append("ad_status", "ACTIVE");
      try {
        const combinedData = new FormData();
        for (const [key, value] of dataPart1.entries()) {
          combinedData.append(`${key}`, value);
        }

        for (const [key, value] of dataPart2.entries()) {
          combinedData.append(`${key}`, value);
        }

        for (const [key, value] of dataPart3.entries()) {
          combinedData.append(`${key}`, value);
        }

        for (const [key, value] of combinedData.entries()) {
          console.log(`${key}: ${value}`);
        }

        const response = await apiClient.post(
          `/campaign-ads/facebook/create/campaign/${this.ad_account_id}`,
          combinedData
        );

        console.log("Campaign creation response:", response);
        return response;
      } catch (error) {
        console.error("Campaign creation failed:", error);
        throw error;
      }
    },
  },
});
