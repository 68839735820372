<template>
  <v-col :cols="12" :lg="9">
    <div class="bold-text mb-2">Attachments</div>
    <file-pond
      ref="pond"
      name="files"
      :allow-multiple="true"
      :max-files="5"
      accepted-file-types="image/*,video/*,audio/*,application/pdf"
      label-idle="Drop your files here or <span class='filepond--label-action'>upload</span>"
      v-bind:files="store.attachments"
      v-on:init="handleFilePondInit"
      imagePreviewHeight="50"
    />
  </v-col>
</template>

<script> 
import { useTaskStore } from '@/store/taskStore';
import vueFilePond from 'vue-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
);

export default {
  name: 'TaskAttachments',
  components: {
    FilePond
  },
  data() {
    return {
      store: useTaskStore(),
    };
  },
  methods: {
    handleFilePondInit() {
    }
  }
};
</script>
