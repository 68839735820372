<script>
import "cropperjs/dist/cropper.css";
import Cropper from "cropperjs";

export default {
  name: "ImageCropper",
  props: {
    selectedImageIds: {
      type: Array,
      required: true,
      default: () => [],
    },
    allImages: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      currentImage: null,
      cropper: null,
      aspectRatio: 1,
      croppedImageUrl: null,
      croppedImages: {},
    };
  },
  computed: {
    images() {
      return this.allImages.filter((img) =>
        this.selectedImageIds.includes(img.id)
      );
    },
    displayImageUrl() {
      if (!this.currentImage) return null;
      return this.croppedImages[this.currentImage.id] || this.currentImage.url;
    },
  },
  created() {
    // Load cropped images from localStorage
    const savedImages = localStorage.getItem("croppedImages");
    if (savedImages) {
      this.croppedImages = JSON.parse(savedImages);
    }
  },
  methods: {
    selectImageForCrop(image) {
      this.currentImage = image;
      this.croppedImageUrl = this.croppedImages[image.id] || null;

      this.$nextTick(() => {
        if (this.cropper) {
          this.cropper.destroy();
        }
        const imageElement = this.$refs.cropImage;
        if (imageElement) {
          this.cropper = new Cropper(imageElement, {
            aspectRatio: this.aspectRatio,
            viewMode: 1,
            dragMode: "move",
            autoCropArea: 1,
            restore: false,
            guides: true,
            center: true,
            highlight: false,
            cropBoxMovable: true,
            cropBoxResizable: true,
            toggleDragModeOnDblclick: false,
          });
        }
      });
    },
    applyCrop() {
      
      if (this.cropper) {
        const canvas = this.cropper.getCroppedCanvas();
        if (canvas) {
          const croppedDataUrl = canvas.toDataURL("image/jpeg");
          this.croppedImageUrl = croppedDataUrl;

          // Store the cropped image in the component state
          this.croppedImages[this.currentImage.id] = croppedDataUrl;

          // Save to localStorage
          localStorage.setItem(
            "croppedImages",
            JSON.stringify(this.croppedImages)
          );

          this.$emit("crop-applied", {
            imageId: this.currentImage.id,
            dataUrl: croppedDataUrl,
            cropData: this.cropper.getData(),
          });

          // Reinitialize cropper with the new cropped image
          this.$nextTick(() => {
            if (this.cropper) {
              this.cropper.destroy();
            }
            const imageElement = this.$refs.cropImage;
            if (imageElement) {
              this.cropper = new Cropper(imageElement, {
                aspectRatio: this.aspectRatio,
                viewMode: 1,
                dragMode: "move",
                autoCropArea: 1,
                restore: false,
                guides: true,
                center: true,
                highlight: false,
                cropBoxMovable: true,
                cropBoxResizable: true,
                toggleDragModeOnDblclick: false,
              });
            }
          });
        }
      }
    },
    resetCrop() {
      if (this.cropper) {
        this.cropper.reset();
        this.croppedImageUrl = null;

        // Remove the cropped image from storage
        if (this.currentImage) {
          delete this.croppedImages[this.currentImage.id];
          localStorage.setItem(
            "croppedImages",
            JSON.stringify(this.croppedImages)
          );
        }
      }
    },
    getImageUrl(image) {
      // Return cropped image if available, otherwise return original
      return this.croppedImages[image.id] || image.url;
    },
  },
  beforeUnmount() {
    if (this.cropper) {
      this.cropper.destroy();
    }
  },
  watch: {
    currentImage(newVal) {
      if (!newVal && this.cropper) {
        this.cropper.destroy();
        this.cropper = null;
      }
    },
  },
};
</script>

<template>
  <div class="image-cropper">
    <div class="image-cropper-header">
      <p class="image-cropper-title mb-4">Select media crop for placements</p>
      <span>
        We evaluated your image and cropped it to fill the placement when we
        think it may perform better. You can edit these crops or upload new
        media for each placement by hovering over the image.
      </span>
    </div>

    <div v-if="images.length === 0" class="no-images">
      <p>No images selected for cropping</p>
    </div>

    <div v-else class="cropper-container">
      <div class="selected-images">
        <div v-for="image in images" :key="image.id" class="image-item">
          <div class="card">
            <p>Feeds, In-stream ads for videos and reels</p>
            <img
              :src="getImageUrl(image)"
              :alt="'Image ' + image.id"
              @click="selectImageForCrop(image)"
            />
          </div>
        </div>

        <div v-for="image in images" :key="image.id" class="image-item">
          <div class="card">
            <p>Stories and Reels, Apps and sites</p>
            <div class="image-stories-container">
              <img
                :src="getImageUrl(image)"
                :alt="'Image ' + image.id"
                @click="selectImageForCrop(image)"
              />
            </div>
          </div>
        </div>

        <div v-for="image in images" :key="image.id" class="image-item">
          <div class="card">
            <p>Search results</p>
            <div class="image-search-container">
              <img
                :src="getImageUrl(image)"
                :alt="'Image ' + image.id"
                @click="selectImageForCrop(image)"
              />
            </div>
          </div>
        </div>
      </div>

      <div v-if="currentImage" class="crop-area">
        <div class="crop-preview">
          <img
            :src="displayImageUrl"
            :alt="'Cropping ' + currentImage.id"
            ref="cropImage"
          />
        </div>
        <div class="crop-controls">
          <button type="button" class="crop-btn" @click="applyCrop">
            Apply Crop
          </button>
          <button type="button" class="reset-btn" @click="resetCrop">
            Reset
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.image-cropper {
  padding: 1rem;
}

.image-cropper .image-cropper-header .image-cropper-title {
  font-size: 14px;
  color: #1c2b33;
}

.no-images {
  text-align: center;
  padding: 2rem;
  background: #f5f5f5;
  border-radius: 8px;
}

.cropper-container {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  flex-direction: column;
}

.selected-images {
  display: flex;
  align-items: start;
  gap: 27px;
}

.image-item {
  margin-bottom: 1rem;
  cursor: pointer;
}

.card {
  padding: 1rem;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card p {
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  color: #000;
  line-height: normal;
}

.image-item img {
  width: 100%;
  height: auto;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.image-item img:hover {
  transform: scale(1.05);
}

.crop-area {
  padding: 1rem;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.crop-preview {
  margin-bottom: 1rem;
  max-height: 500px;
  overflow: hidden;
}

.crop-preview img {
  max-width: 100%;
  display: block;
}

.crop-controls {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  padding-top: 1rem;
  border-top: 1px solid #eee;
}

.crop-btn,
.reset-btn {
  padding: 0.5rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 500;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  font: 14px/1.42857143 "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

.crop-btn {
  background-color: #1976d2;
  color: white;
  border: 1px solid #1976d2;
}

.crop-btn:hover {
  background-color: #1565c0;
  border-color: #1565c0;
}

.reset-btn {
  background-color: #f5f5f5;
  color: #333;
  border: 1px solid #ddd;
}

.reset-btn:hover {
  background-color: #e0e0e0;
  border-color: #ccc;
}

.image-item .card .image-stories-container {
  height: 300px;
  background-color: #999999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-item .card .image-stories-container img {
  height: 50%;
  width: 100%;
  object-fit: cover;
  margin: 0 auto;
  display: block;
}

.image-item .card .image-search-container {
  background-color: #999999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-item .card .image-search-container img {
  width: 50%;
  margin: 0 auto;
}
</style>
