import { defineStore } from "pinia";
import apiClient from "@/core/buildRequest";

export const useHelperStore = defineStore("useHelperStore", {
  state: () => ({
    countries: [],
    cities: [],
    job_titles: [],
    selectedCountry: null,
    bussiness_types: [],
  }),
  getters: {
    getCountries() {
      return this.countries;
    },
    getCities() {
      return this.cities;
    },
    getJobTitles() {
      return this.job_titles;
    },
    getBussinessTypes() {
      return this.bussiness_types;
    },
  },
  actions: {
    async fetchCountries() {
      try {
        const response = await apiClient.get(`/leads-managment/countries`);
        this.countries = response.data.data;
        return response.data;
      } catch (err) {
        console.error("Error fetching countries:", err);
        return [];
      }
    },
    async fetchCities() {
      try {
        const response = await apiClient.get(
          `/leads-managment/cities/${this.selectedCountry}`
        );
        this.cities = response.data.data;
        return response.data;
      } catch (err) {
        console.error("Error fetching cities:", err);
        return [];
      }
    },
    async fetchJobTitles() {
      try {
        const response = await apiClient.get(`/leads-managment/job-titles`);
        this.job_titles = response.data.data;
        return response.data;
      } catch (err) {
        console.error("Error fetching job titles:", err);
        return [];
      }
    },
    async fetchBussinessTypes() {
      try {
        const response = await apiClient.get(`/leads-managment/business-types`);
        this.bussiness_types = response.data.data;
        return response.data;
      } catch (err) {
        console.error("Error fetching bussiness types:", err);
        return [];
      }
    },
    pushJobTitle(jobTitle) {
      this.job_titles.push(jobTitle);
    },
    setSelectedCountry(countryId) {
      this.selectedCountry = countryId;
    },
  },
});
