<template>
  <v-navigation-drawer width="1200" location="right" v-model="drawer">
    <v-list-item style="background-color: #fafafa" title="Upload images">
      <button @click="closeDrawer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="20px"
          viewBox="0 -960 960 960"
          width="20px"
          fill="#000"
        >
          <path
            d="m291-240-51-51 189-189-189-189 51-51 189 189 189-189 51 51-189 189 189 189-51 51-189-189-189 189Z"
          />
        </svg>
      </button>
    </v-list-item>

    <v-divider></v-divider>
    <v-container>
      <v-row no-gutters>
        <v-col cols="12">
          <div class="upload-container pa-4">
            <div
              class="upload-area mb-4"
              @drop.prevent="handleDrop"
              @dragover.prevent="handleDragOver"
              @dragleave.prevent="handleDragLeave"
              :class="{ 'drag-over': isDragging }"
            >
              <input
                type="file"
                ref="fileInput"
                @change="handleFileSelect"
                accept="image/*"
                multiple
                style="display: none"
              />
              <div class="upload-placeholder">
                <v-icon size="48" color="grey">mdi-upload</v-icon>
                <p class="text-h6 mt-4">Drag and drop your images here</p>
                <p class="text-body-2 text-grey">or</p>
                <v-btn
                  elevation="0"
                  @click="$refs.fileInput.click()"
                  class="mt-2"
                >
                  Browse Files
                </v-btn>
                <p class="text-caption mt-4 text-grey">
                  Supported formats: .jpg, .jpeg, .png, .webp
                  <br />Maximum file size: 50 MB per file <br />You can upload
                  up to 35 images
                </p>
              </div>
            </div>

            <v-alert
              v-if="error"
              type="error"
              class="mb-4"
              closable
              @click:close="error = ''"
            >
              {{ error }}
            </v-alert>

            <div v-if="images.length > 0">
              <v-table>
                <thead>
                  <tr>
                    <th class="text-left">Preview</th>
                    <th class="text-left">File Name</th>
                    <th class="text-left">Size</th>
                    <th class="text-left">Added</th>
                    <th class="text-left">Status</th>
                    <th class="text-left">Progress</th>
                    <th class="text-left">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(image, index) in images" :key="image.id">
                    <td>
                      <div class="preview-container">
                        <img
                          :src="image.previewUrl"
                          alt="Preview"
                          style="width: 50px; height: 50px; object-fit: cover"
                        />
                        <v-btn
                          v-if="image.status === 'pending'"
                          icon="mdi-crop"
                          size="small"
                          class="crop-button"
                          @click="openCropDialog(index)"
                        >
                          <v-icon>mdi-crop</v-icon>
                        </v-btn>
                      </div>
                    </td>
                    <td>{{ image.file.name }}</td>
                    <td>{{ formatFileSize(image.file.size) }}</td>
                    <td>{{ formatTimestamp(image.timestamp) }}</td>
                    <td>
                      <v-chip
                        :color="getStatusColor(image.status)"
                        size="small"
                      >
                        {{ image.status }}
                      </v-chip>
                    </td>
                    <td>
                      <v-progress-linear
                        v-if="image.status === 'uploading'"
                        :model-value="image.progress"
                        color="primary"
                        height="20"
                      >
                        <template v-slot:default="{ value }">
                          <strong>{{ Math.ceil(value) }}%</strong>
                        </template>
                      </v-progress-linear>
                      <span
                        v-else-if="image.status === 'completed'"
                        class="text-success"
                      >
                        100%
                      </span>
                    </td>
                    <td>
                      <div class="d-flex gap-2">
                        <v-btn
                          v-if="image.status === 'pending'"
                          color="#017976"
                          variant="text"
                          density="comfortable"
                          @click="removeImage(index)"
                        >
                          <div class="d-flex">
                            <span>
                              <svg
                                width="15"
                                fill="#017976"
                                height="15"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                              >
                                <path
                                  d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0L284.2 0c12.1 0 23.2 6.8 28.6 17.7L320 32l96 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 96C14.3 96 0 81.7 0 64S14.3 32 32 32l96 0 7.2-14.3zM32 128l384 0 0 320c0 35.3-28.7 64-64 64L96 512c-35.3 0-64-28.7-64-64l0-320zm96 64c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16z"
                                />
                              </svg>
                            </span>
                            <span style="color: #017976">Delete</span>
                          </div>
                        </v-btn>
                        <v-btn
                          v-else-if="image.status === 'failed'"
                          color="warning"
                          variant="text"
                          density="comfortable"
                          @click="retryUpload(index)"
                        >
                          Retry
                        </v-btn>
                        <v-btn
                          v-if="image.status === 'completed'"
                          color="error"
                          variant="text"
                          density="comfortable"
                          @click="confirmRemove(index)"
                        >
                          Remove
                        </v-btn>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </v-table>

              <div class="d-flex justify-end mt-4">
                <v-btn
                  :disabled="!hasUploadableImages || isUploading"
                  :loading="isUploading"
                  @click="uploadImages"
                >
                  Upload All Images
                </v-btn>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <!-- Confirmation Dialog -->
    <v-dialog v-model="showConfirmDialog" max-width="400">
      <v-card>
        <v-card-title class="text-h5">Remove Image</v-card-title>
        <v-card-text>
          Are you sure you want to remove this uploaded image? This action
          cannot be undone.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey-darken-1"
            variant="text"
            @click="showConfirmDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn color="error" variant="text" @click="handleConfirmRemove">
            Remove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <image-crop-dialog
  v-if="selectedImage"
  :show="showCropDialog"
  :image-url="selectedImage.previewUrl"
  @close="showCropDialog = false"
  @crop="handleCrop"
  @cancel="closeCropDialog"
/>
  
  </v-navigation-drawer>
</template>

<script>
import ImageCropDialog from "./ImageCropDialog.vue";
import { formatFileSize } from "@/core/fileUtils.js";
import { getStatusColor } from "@/core/videoUtils";
import { tiktokData } from "@/store/tiktokStore/tiktokData";
import { mapState } from "pinia";

export default {
  name: "ImageUploadDrawer",
  components: { ImageCropDialog },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isDragging: false,
      error: "",
      images: [],
      isUploading: false,
      showConfirmDialog: false,
      imageToRemove: null,
      showCropDialog: false,
      selectedImageIndex: null,
    };
  },
  mounted() {
    this.drawer = this.modelValue;
  },
  watch: {
    modelValue() {
      this.drawer = this.modelValue;
    },
  },
  computed: {
    selectedImage() {
      return this.selectedImageIndex !== null ? this.images[this.selectedImageIndex] : null;
    },
    hasUploadableImages() {
      return this.images.some((image) => image.status === "pending");
    },
    ...mapState(tiktokData, ["images_arr"]),
  },
  methods: {
    formatFileSize,
    getStatusColor,
    formatTimestamp(timestamp) {
      return new Date(timestamp).toLocaleString();
    },
    closeDrawer() {
      this.$emit("closeDrawer", false);
    },
    handleDragOver() {
      this.isDragging = true;
    },
    handleDragLeave() {
      this.isDragging = false;
    },
    handleDrop(e) {
      this.isDragging = false;
      const files = Array.from(e.dataTransfer.files);
      this.processFiles(files);
    },
    handleFileSelect(e) {
      const files = Array.from(e.target.files);
      this.processFiles(files);
    },
    validateImageFile(file) {
      const maxSize = 50 * 1024 * 1024; // 50MB
      const allowedTypes = ["image/jpeg", "image/png", "image/webp"];

      if (!allowedTypes.includes(file.type)) {
        return {
          isValid: false,
          error:
            "Invalid file type. Only JPG, PNG, and WebP images are allowed.",
        };
      }

      if (file.size > maxSize) {
        return {
          isValid: false,
          error: "File size exceeds 50MB limit.",
        };
      }

      if (this.images.length >= 35) {
        return {
          isValid: false,
          error: "Maximum number of images (35) reached.",
        };
      }

      return { isValid: true };
    },
    createImageEntry(file) {
      const previewUrl = URL.createObjectURL(file);
      return {
        id: Date.now().toString(),
        file,
        previewUrl,
        timestamp: Date.now(),
        status: "pending",
        progress: 0,
        error: null,
      };
    },
    processFiles(files) {
      files.forEach((file) => {
        const validation = this.validateImageFile(file);
        if (validation.isValid) {
          this.images.push(this.createImageEntry(file));
        } else {
          this.error = `${file.name}: ${validation.error}`;
        }
      });
    },
    removeImage(index) {
      URL.revokeObjectURL(this.images[index].previewUrl);
      this.images.splice(index, 1);
    },
    confirmRemove(index) {
      this.imageToRemove = index;
      this.showConfirmDialog = true;
    },
    async handleConfirmRemove() {
      if (this.imageToRemove !== null) {
        await new Promise((resolve) => setTimeout(resolve, 500));
        this.removeImage(this.imageToRemove);
      }
      this.showConfirmDialog = false;
      this.imageToRemove = null;
    },
    async uploadImages() {
      this.isUploading = true;
      console.log(this.images);
      const pendingImages = this.images.filter(
        (image) => image.status === "pending"
      );
      for (const image of pendingImages) {
        try {
          image.status = "uploading";
          image.progress = 0;
          for (let i = 0; i <= 100; i += 10) {
            image.progress = i;
            await new Promise((resolve) => setTimeout(resolve, 200));
          }
          await new Promise((resolve) => setTimeout(resolve, 500));
          image.status = "completed";
          image.progress = 100;
          const store = tiktokData();
          if (Array.isArray(store.videoPreview)) {
            store.images_arr.push({
              url: image.previewUrl,
              fileName: image.file.name,
              fileType: image.file.type,
            });
          }
        } catch (error) {
          image.status = "failed";
          image.error = "Upload failed";
        }
      }
      this.isUploading = false;
    },
    async retryUpload(index) {
      const image = this.images[index];
      image.status = "pending";
      image.progress = 0;
      image.error = null;
      await this.uploadImages();
    },
    openCropDialog(index) {
      this.selectedImageIndex = index;
      this.showCropDialog = true;
    },
    closeCropDialog() {
      this.selectedImageIndex = null;
      this.showCropDialog = false;
    },
    async handleCrop(croppedImage) {
      if (this.selectedImageIndex !== null) {
        URL.revokeObjectURL(this.images[this.selectedImageIndex].previewUrl);
        this.images[this.selectedImageIndex].file = croppedImage.blob;
        this.images[this.selectedImageIndex].previewUrl = croppedImage.url;
      }
      this.closeCropDialog();
    },
  },
  beforeUnmount() {
    // Clean up object URLs to prevent memory leaks
    this.images.forEach((image) => {
      URL.revokeObjectURL(image.previewUrl);
    });
  },
};
</script>

<style scoped>
.upload-container {
  width: 100%;
}

.upload-area {
  border: 2px dashed #ccc;
  border-radius: 8px;
  padding: 40px;
  text-align: center;
  transition: all 0.3s ease;
  background-color: #f5f5f5;
}

.upload-area.drag-over {
  border-color: #2196f3;
  background-color: #e3f2fd;
}

.upload-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

button:hover {
  background-color: #f5f5f5;
}
.v-list-item__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
