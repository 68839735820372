<template>
  <div class="reel-card">
    <div class="card-header">
      <span>
        <svg
          width="11"
          height="17"
          viewBox="0 0 11 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.75 1L2.25 8.5L9.75 16"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      </span>
      <span>Reels</span>
      <span>
        <svg
          width="25"
          height="23"
          viewBox="0 0 25 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.75 4L17.6617 3.73509C17.1172 2.10172 15.5887 1 13.867 1H11.633C9.91131 1 8.38276 2.10172 7.8383 3.73509L7.75 4H5.75C3.54086 4 1.75 5.79086 1.75 8V18C1.75 20.2091 3.54086 22 5.75 22H19.75C21.9591 22 23.75 20.2091 23.75 18V8C23.75 5.79086 21.9591 4 19.75 4H17.75Z"
            stroke="white"
            stroke-width="2"
          />
          <path
            d="M17.75 13C17.75 15.7614 15.5114 18 12.75 18C9.98858 18 7.75 15.7614 7.75 13C7.75 10.2386 9.98858 8 12.75 8C15.5114 8 17.75 10.2386 17.75 13Z"
            stroke="white"
            stroke-width="2"
          />
        </svg>
      </span>
    </div>
    <img
      class="media-type"
      v-if="
        post.file.file &&
        post.file.file.type &&
        post.file.file.type.startsWith('image/')
      "
      :src="post.file.preview"
      alt="Post Image"
    />
    <video
      v-if="
        post.file.file &&
        post.file.file.type &&
        post.file.file.type.startsWith('video/')
      "
      height="100%"
      :src="post.file.preview"
      autoplay
      muted
      loop
      class="media-type"
    ></video>
    <div class="card-body pl-4 pr-2 d-flex align-end justify-space-between">
      <div class="caption-name-reel">
        <div class="image-name-user d-flex align-center ga-2">
          <img
            src="@/assets/soft-ware-img/be2e93b49a6624b62b6a5bb8c17a2d08.jpeg"
            alt=""
          />
          <span>your.name</span>
        </div>
        <p class="caption-reel-text">
          Lorem metus porttitor purus enim. Non et m...
        </p>
        <div class="music-place">
          <span>
            <svg
              width="10"
              height="12"
              viewBox="0 0 10 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 0.75C7.5 1.25 2.4 2.25 2 2.25V7.25C0.895431 7.25 0 8.14543 0 9.25C0 10.3546 0.895431 11.25 2 11.25C3.10457 11.25 4 10.3546 4 9.25V4.875L8 4.125V6.25C6.89543 6.25 6 7.14543 6 8.25C6 9.35457 6.89543 10.25 8 10.25C9.10457 10.25 10 9.35457 10 8.25V0.75Z"
                fill="white"
              />
            </svg>
          </span>
          <span>Lorem metus porttitor purus</span>
        </div>
      </div>
      <div class="reel-reactions position-relative">
        <img
          class="d-block mb-3"
          src="@/assets/soft-ware-img/Reactions-instagram-reel.svg"
          alt=""
        />
        <img class="d-block" src="@/assets/soft-ware-img/Music.svg" alt="" />
      </div>
    </div>
  </div>
  <div class="reel-card-footer">
    <div class="d-flex align-center justify-space-between">
      <img
        style="width: calc(100% - 50px)"
        src="@/assets/soft-ware-img/Icons-instagram-footer.svg"
        alt=""
      />
      <div class="user-image">
        <img
          src="@/assets/soft-ware-img/be2e93b49a6624b62b6a5bb8c17a2d08.jpeg"
          alt=""
        />
      </div>
    </div>
    <div class="close-reel mt-5">
      <span></span>
    </div>
  </div>
</template>
<script>
import { publishPost } from "@/store/publishPost/publishPost.js";
export default {
  name: "InstgramReelReview",
  components: {},
  data() {
    return {
      store: publishPost(),
      post: {},
    };
  },
  watch: {
    "store.getCarouselSlider": {
      handler() {
        this.post = this.store.post["instagram"].find(
          (item) => item.type === "reel"
        );
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
video {
  object-fit: cover;
}
</style>