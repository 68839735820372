import { defineStore } from "pinia";
import apiClient from "@/core/buildRequest";

export const publishPost = defineStore("publishPost", {
  state: () => ({
    storyContant: false,
    mediaFilsPost: [],
    facebookPostMedia: [],
    instagramPostMedia: [],
    tikTokPostMedia: [],
    snapChatPostMedia: [],
    facebookStoryMedia: [],
    facebookReelMedia: [],
    instagramStoryMedia: [],
    instagramReelMedia: [],
    tikTokStoryMedia: [],
    snapchatStoryMedia: [],
    snapChatReelMedia: [],
    xPostMedia: [],
    xPostMedia: [],
    allMediaFils: [],
    post: {
      facebook: [],
      instagram: [],
      tiktok: [],
      snapchat: [],
      x: [],
    },
    errors: [],
    comment: "",
    commentImages: [],
    boost: {
      facebook: {
        state: false,
        budget: "",
        duration: "",
        currency: "EG",
      },
      tiktok: {
        state: false,
        budget: "",
        duration: "",
        currency: "EG",
      },
      instagram: {
        state: false,
        budget: "",
        duration: "",
        currency: "EG",
      },
      snapchat: {
        state: false,
        budget: "",
        duration: "",
        currency: "EG",
      },
      x: {
        state: false,
        budget: "",
        duration: "",
        currency: "EG",
      },
    },
    pagesIds: [],
    pages: [],
  }),
  getters: {
    getSelectedTypes() {
      let selectedTypes = [];
      Object.keys(this.post).forEach((key) => {
        if (Array.isArray(this.post[key])) {
          selectedTypes[key] = [];
          this.post[key].forEach((entry) => {
            selectedTypes[key].push(entry.type);
          });
        }
      });
      return selectedTypes;
    },
    getPost() {
      return this.post;
    },
    getCarouselSlider() {
      return Object.entries(this.post).flatMap(([platform, features]) => {
        return features.map((item) => ({
          platform,
          type: item.type,
        }));
      });
    },
  },
  actions: {
    updateStoryContant() {
      this.storyContant = !this.storyContant;
    },
    sendPostDate() {},
    UpdatePlatformTypes(platformsfor, platform, typeKeys) {
      if (this.post[platform] && Array.isArray(this.post[platform])) {
        const existingTypes = new Map(
          this.post[platform].map((item) => [item.type, item])
        );

        const updatedEntries = typeKeys.map((type) => {
          if (existingTypes.has(type)) {
            return existingTypes.get(type);
          } else {
            if (platformsfor === "customize") {
              return { type, content: "", url: "", comment: "", file: "" };
            } else if (platformsfor === "all") {
              let firstValidEntry = null;
              for (const key in this.post) {
                if (this.post[key] && Array.isArray(this.post[key])) {
                  firstValidEntry = this.post[key].find(
                    (item) => item.content && item.url
                  );
                  if (firstValidEntry) break;
                }
              }
              if (firstValidEntry) {
                return {
                  type,
                  content: firstValidEntry.content,
                  url: firstValidEntry.url,
                };
              } else {
                return { type, content: "", url: "", comment: "", file: "" };
              }
            }
          }
        });

        this.post[platform] = updatedEntries;
      } else {
        console.error(`Invalid platform: ${platform}`);
      }
    },

    UpdatePlatformContents(
      platformsfor,
      platform = null,
      typeKey = null,
      content
    ) {
      if (platformsfor === "customize") {
        if (platform) {
          if (this.post[platform] && Array.isArray(this.post[platform])) {
            if (typeKey) {
              const entry = this.post[platform].find(
                (item) => item.type === typeKey
              );
              if (entry) {
                entry.content = content;
              } else {
                alert(
                  `No entry found with type '${typeKey}' in platform '${platform}'`
                );
              }
            } else {
              this.post[platform].forEach((entry) => {
                entry.content = content;
              });
            }
          } else {
            console.error(`Invalid platform: ${platform}`);
          }
        } else if (typeKey) {
          Object.keys(this.post).forEach((key) => {
            if (Array.isArray(this.post[key])) {
              this.post[key].forEach((entry) => {
                if (entry.type === typeKey) {
                  entry.content = content;
                }
              });
            }
          });
        }
      } else {
        Object.keys(this.post).forEach((key) => {
          if (Array.isArray(this.post[key])) {
            this.post[key].forEach((entry) => {
              entry.content = content;
            });
          }
        });
      }
    },
    UpdatePlatformLinks(
      platformsfor,
      platform = null,
      typeKey = null,
      url,
      name = null
    ) {
      if (platformsfor === "customize") {
        if (platform) {
          if (this.post[platform] && Array.isArray(this.post[platform])) {
            if (typeKey) {
              const entry = this.post[platform].find(
                (item) => item.type === typeKey
              );
              if (entry) {
                entry.url = url;
                entry.url_name = name;
              } else {
              }
            } else {
              this.post[platform].forEach((entry) => {
                entry.url = url;
                entry.url_name = name;
              });
            }
          } else {
            console.error(`Invalid platform: ${platform}`);
          }
        } else if (typeKey) {
          Object.keys(this.post).forEach((key) => {
            if (Array.isArray(this.post[key])) {
              this.post[key].forEach((entry) => {
                if (entry.type === typeKey) {
                  entry.url = url;
                  entry.url_name = name;
                }
              });
            }
          });
        }
      } else {
        Object.keys(this.post).forEach((key) => {
          if (Array.isArray(this.post[key])) {
            this.post[key].forEach((entry) => {
              entry.url = url;
              entry.url_name = name;
            });
          }
        });
      }
      console.log(this.post);
    },
    RemovePlatformLinks(platformsfor, platform = null, typeKey = null) {
      if (platformsfor === "customize") {
        if (platform) {
          if (this.post[platform] && Array.isArray(this.post[platform])) {
            if (typeKey) {
              const entry = this.post[platform].find(
                (item) => item.type === typeKey
              );
              if (entry) {
                entry.url = "";
              } else {
              }
            } else {
              this.post[platform].forEach((entry) => {
                entry.url = "";
              });
            }
          } else {
            console.error(`Invalid platform: ${platform}`);
          }
        } else if (typeKey) {
          Object.keys(this.post).forEach((key) => {
            if (Array.isArray(this.post[key])) {
              this.post[key].forEach((entry) => {
                if (entry.type === typeKey) {
                  entry.url = "";
                }
              });
            }
          });
        }
      } else {
        Object.keys(this.post).forEach((key) => {
          if (Array.isArray(this.post[key])) {
            this.post[key].forEach((entry) => {
              entry.url = "";
            });
          }
        });
      }
    },
    updatePlatformFile(platformsfor, platform = null, typeKey = null, file) {
      if (platformsfor === "customize") {
        if (platform) {
          if (this.post[platform] && Array.isArray(this.post[platform])) {
            if (typeKey) {
              const entry = this.post[platform].find(
                (item) => item.type === typeKey
              );
              if (entry) {
                entry.file = file;
              } else {
              }
            } else {
              this.post[platform].forEach((entry) => {
                entry.file = file;
              });
            }
          } else {
            console.error(`Invalid platform: ${platform}`);
          }
        } else if (typeKey) {
          Object.keys(this.post).forEach((key) => {
            if (Array.isArray(this.post[key])) {
              this.post[key].forEach((entry) => {
                if (entry.type === typeKey) {
                  entry.file = file;
                }
              });
            }
          });
        }
      } else {
        const allEmpty = Object.keys(this.post).every(
          (key) => Array.isArray(this.post[key]) && this.post[key].length === 0
        );
        if (allEmpty) {
          this.post["facebook"].push({
            type: "",
            content: "",
            url: "",
            file: file,
            comment: "",
          });
        } else {
          Object.keys(this.post).forEach((key) => {
            if (Array.isArray(this.post[key])) {
              this.post[key].forEach((entry) => {
                entry.file = file;
              });
            }
          });
        }
      }
    },
    async SendPostToPublish() {
      alert("Sending post to publish...");
      this.validation();
      if (this.errors.length > 0) return;
      apiClient
        .post("/publish-posts/create/post", {
          post: await this.formattedRequestToSend(),
        })
        .then((response) => {
          console.log(response);
        });
    },

    // async formattedRequestToSend() {
    //   try {
    //     let request = {};
    //     Object.keys(this.boost).forEach((platform) => {
    //       if (this.boost[platform].state) {
    //         this.post[platform].forEach((post) => {
    //           post.boost = true;
    //           post.duration = this.boost[platform].duration || "";
    //           post.date = this.boost[platform].date || null;
    //           post.budget = this.boost[platform].budget || "";
    //         });
    //       }
    //     });

    //     Object.keys(this.post).forEach((platform) => {
    //       const platformPages = this.pagesIds.filter(
    //         (page) => page.platform === platform
    //       );

    //       request[platform] = this.post[platform].flatMap((item) => {
    //         return platformPages
    //           .map((page) => {
    //             const baseData = {
    //               type: item.type,
    //               page_id: page.id,
    //               comment: this.comment,
    //               comment_images: this.commentImages[0] || null,
    //               boost: true,
    //               duration: item.duration,
    //               date: item.date,
    //               budget: item.budget,
    //             };
    //             switch (platform) {
    //               case "facebook":
    //                 return {
    //                   ...baseData,
    //                   message:
    //                     item.type === "post" ? item.content || null : null,
    //                   link: item.url || null,
    //                   url: item.type === "post" ? item.file.file || null : null,
    //                   description:
    //                     item.type === "reel" || item.type === "story"
    //                       ? item.content || null
    //                       : null,
    //                   file:
    //                     item.type !== "post" ? item.file.file || null : null,
    //                 };

    //               case "instagram":
    //                 return {
    //                   ...baseData,
    //                   caption: item.content || null,
    //                   image_url:
    //                     item.type === "post"
    //                       ? await this.uploadFile(item.file.file) || null
    //                       : null,
    //                   video_url:
    //                     item.type !== "post" ? item.file.file || null : null,
    //                 };

    //               case "tiktok":
    //                 return {
    //                   ...baseData,
    //                   link: item.url || null,
    //                   file: item.file.file || null,
    //                 };

    //               case "x":
    //                 if (item.type === "post") {
    //                   return {
    //                     ...baseData,
    //                     link: item.url || null,
    //                     file: item.file.file || null,
    //                   };
    //                 }
    //                 return null;

    //               case "snapchat":
    //                 if (item.type === "story") {
    //                   return {
    //                     ...baseData,
    //                     link: item.url || null,
    //                     file: item.file.file || null,
    //                   };
    //                 }
    //                 return null;

    //               default:
    //                 return null;
    //             }
    //           })
    //           .filter(Boolean);
    //       });
    //     });

    //     console.log("Formatted request:", request);
    //     const formData = new FormData();

    //     if (request && typeof request === "object") {
    //       for (const platform of Object.keys(request)) {
    //         if (Array.isArray(request[platform])) {
    //           for (const [index, item] of request[platform].entries()) {
    //             if (item && typeof item === "object") {
    //               for (const key of Object.keys(item)) {
    //                 // if (
    //                 //   key === "file" ||
    //                 //   key === "image_url" ||
    //                 //   key === "video_url" ||
    //                 //   key === "comment_images"
    //                 // ) {
    //                 //   if (item[key]?.file) {
    //                 //     let file_url = await this.uploadFile(item[key].file);
    //                 //     formData.append(
    //                 //       `${platform}[${index}][${key}]`,
    //                 //       file_url
    //                 //     );
    //                 //   } else {
    //                 //     formData.append(`${platform}[${index}][${key}]`, null);
    //                 //   }
    //                 // } else {
    //                 formData.append(
    //                   `${platform}[${index}][${key}]`,
    //                   item[key] || ""
    //                 );
    //                 // }
    //               }
    //             }
    //           }
    //         }
    //       }
    //     } else {
    //       console.error("Invalid request object:", request);
    //     }

    //     for (const pair of formData.entries()) {
    //       console.log(`${pair[0]}:`, pair[1]);
    //     }

    //     return request;
    //   } catch (error) {
    //     console.error("Error in formattedRequestToSend:", error);
    //     throw error;
    //   }
    // },

    // async uploadFile(file) {
    //   try {
    //     let response = await apiClient.post("/publish-posts/upload/file", {
    //       file: file,
    //     });
    //     console.log(response.data);

    //     return response.data;
    //   } catch (error) {
    //     console.error("Error uploading file:", error);
    //     throw error;
    //   }
    // },

    validation() {
      this.errors = [];

      if (this.post.facebook) {
        this.post.facebook.forEach((item, index) => {
          if (!["post", "reel", "story"].includes(item.type)) {
            this.errors.push(
              `Facebook post ${
                index + 1
              }: Type must be one of post, reel, or story.`
            );
          }
          if (
            item.type === "post" &&
            (!item.content ||
              item.content.length < 2 ||
              item.content.length > 255)
          ) {
            this.errors.push(
              `Facebook post ${
                index + 1
              }: Message must be between 2 and 255 characters.`
            );
          }
          if (
            item.type === "post" &&
            (!item.url || item.url.length < 2 || item.url.length > 255)
          ) {
            this.errors.push(
              `Facebook post ${
                index + 1
              }: Link must be between 2 and 255 characters.`
            );
          }
          if (item.type === "reel" && !item.file) {
            this.errors.push(
              `Facebook reel ${index + 1}: File must be a valid MP4 file.`
            );
          }
          if (
            item.type === "reel" &&
            (!item.content ||
              item.content.length < 2 ||
              item.content.length > 255)
          ) {
            this.errors.push(
              `Facebook reel ${
                index + 1
              }: Description must be between 2 and 255 characters.`
            );
          }
          // if (item.type && !item.page_id) {
          //   this.errors.push(`Facebook Page ID is required.`);
          // }
        });
      }

      if (this.post.instagram) {
        this.post.instagram.forEach((item, index) => {
          if (!["post", "reel", "story"].includes(item.type)) {
            this.errors.push(
              `Instagram post ${
                index + 1
              }: Type must be one of post, reel, or story.`
            );
          }
          if (
            item.type === "post" &&
            (!item.content ||
              item.content.length < 2 ||
              item.content.length > 255)
          ) {
            this.errors.push(
              `Instagram post ${
                index + 1
              }: Caption must be between 2 and 255 characters.`
            );
          }
          if (
            item.type === "post" &&
            (!item.file || item.file.length < 2 || item.file.length > 255)
          ) {
            this.errors.push(
              `Instagram post ${
                index + 1
              }: Image URL must be between 2 and 255 characters.`
            );
          }
          if (item.type === "reel") {
            // this.errors.push(
            //   `Instagram reel ${index + 1}: Video URL must be a valid MP4 file.`
            // );
          }
          if (
            item.type === "reel" &&
            (!item.content ||
              item.content.length < 2 ||
              item.content.length > 255)
          ) {
            this.errors.push(
              `Instagram reel ${
                index + 1
              }: Caption must be between 2 and 255 characters.`
            );
          }
        });
      }
      if (this.errors.length > 0) {
        console.error("Validation Errors:", this.errors);
      } else {
        console.log("Validation passed!");
      }
    },
    getLink(platform, typeKey) {
      if (platform) {
        if (typeKey === "story_and_reel") {
          let links = [];
          let entryreel = this.post[platform].find(
            (item) => item.type === "reel"
          );
          if (entry) {
            entry.url
              ? links.push({ href: entry.url, name: entry.url_name })
              : null;
          }
          let entrystory = this.post[platform].find(
            (item) => item.type === "story"
          );
          if (entrystory) {
            entry.url
              ? links.push({ href: entry.url, name: entry.url_name })
              : null;
          }
          return links;
        } else {
          if (typeKey) {
            console.log(this.post);

            const entry = this.post[platform].find(
              (item) => item.type === typeKey
            );
            if (entry) {
              return entry.url
                ? [{ href: entry.url, name: entry.url_name }]
                : [];
            }
          } else {
            this.post[platform].forEach((entry) => {
              return entry.url
                ? [{ href: entry.url, name: entry.url_name }]
                : [];
            });
          }
        }
      } else if (typeKey) {
        let links = [];
        Object.keys(this.post).forEach((key) => {
          if (Array.isArray(this.post[key])) {
            this.post[key].forEach((entry) => {
              if (entry.type === typeKey) {
                entry.url
                  ? links.push({ href: entry.url, name: entry.url_name })
                  : null;
              }
            });
          }
        });
        return links;
      }
    },
    getPages() {
      apiClient.get("/all-platfroms-pages").then((response) => {
        this.pages = response.data.data;
      });
    },
    uploadFile(file) {
      return new Promise((resolve, reject) => {
        apiClient
          .post("/publish-posts/upload/file", {
            file: file,
          })
          .then((response) => {
            console.log(response.data);
            resolve(response.data);
          })
          .catch((error) => {
            console.error("Error uploading file:", error);
            reject(error);
          });
      });
    },

    formattedRequestToSend() {
      let request = {};

      // Handle boost settings
      Object.keys(this.boost).forEach((platform) => {
        if (this.boost[platform].state) {
          this.post[platform].forEach((post) => {
            post.boost = true;
            post.duration = this.boost[platform].duration || "";
            post.date = this.boost[platform].date || null;
            post.budget = this.boost[platform].budget || "";
          });
        }
      });

      return new Promise((resolve, reject) => {
        const processFiles = () => {
          const promises = [];

          // Process each platform's posts
          for (const platform of Object.keys(this.post)) {
            const platformPages = this.pagesIds.filter(
              (page) => page.platform === platform
            );

            request[platform] = [];

            for (const item of this.post[platform]) {
              for (const page of platformPages) {
                const baseData = {
                  type: item.type,
                  page_id: page.id,
                  comment: this.comment,
                  boost: true,
                  duration: item.duration,
                  date: item.date,
                  budget: item.budget,
                };

                // Upload comment images first if they exist
                if (this.commentImages[0]) {
                  promises.push(
                    this.uploadFile(this.commentImages[0]).then(
                      (commentImageUrl) => {
                        baseData.comment_images = commentImageUrl;
                      }
                    )
                  );
                }

                let platformPromise;
                switch (platform) {
                  case "facebook": {
                    if (item.file?.file) {
                      platformPromise = this.uploadFile(item.file.file).then(
                        (fileUrl) => {
                          const platformData = {
                            ...baseData,
                            message:
                              item.type === "post"
                                ? item.content || null
                                : null,
                            link: item.url || null,
                            url: item.type === "post" ? fileUrl : null,
                            description:
                              item.type === "reel" || item.type === "story"
                                ? item.content || null
                                : null,
                            file: item.type !== "post" ? fileUrl : null,
                          };
                          request[platform].push(platformData);
                        }
                      );
                      promises.push(platformPromise);
                    } else {
                      const platformData = {
                        ...baseData,
                        message:
                          item.type === "post" ? item.content || null : null,
                        link: item.url || null,
                        url: null,
                        description:
                          item.type === "reel" || item.type === "story"
                            ? item.content || null
                            : null,
                        file: null,
                      };
                      request[platform].push(platformData);
                    }
                    break;
                  }

                  case "instagram": {
                    if (item.file?.file) {
                      platformPromise = this.uploadFile(item.file.file).then(
                        (fileUrl) => {
                          const platformData = {
                            ...baseData,
                            caption: item.content || null,
                            image_url: item.type === "post" ? fileUrl : null,
                            video_url: item.type !== "post" ? fileUrl : null,
                          };
                          request[platform].push(platformData);
                        }
                      );
                      promises.push(platformPromise);
                    } else {
                      const platformData = {
                        ...baseData,
                        caption: item.content || null,
                        image_url: null,
                        video_url: null,
                      };
                      request[platform].push(platformData);
                    }
                    break;
                  }

                  case "tiktok": {
                    if (item.file?.file) {
                      platformPromise = this.uploadFile(item.file.file).then(
                        (fileUrl) => {
                          const platformData = {
                            ...baseData,
                            link: item.url || null,
                            file: fileUrl,
                          };
                          request[platform].push(platformData);
                        }
                      );
                      promises.push(platformPromise);
                    } else {
                      const platformData = {
                        ...baseData,
                        link: item.url || null,
                        file: null,
                      };
                      request[platform].push(platformData);
                    }
                    break;
                  }

                  case "x": {
                    if (item.type === "post") {
                      if (item.file?.file) {
                        platformPromise = this.uploadFile(item.file.file).then(
                          (fileUrl) => {
                            const platformData = {
                              ...baseData,
                              link: item.url || null,
                              file: fileUrl,
                            };
                            request[platform].push(platformData);
                          }
                        );
                        promises.push(platformPromise);
                      } else {
                        const platformData = {
                          ...baseData,
                          link: item.url || null,
                          file: null,
                        };
                        request[platform].push(platformData);
                      }
                    }
                    break;
                  }

                  case "snapchat": {
                    if (item.type === "story") {
                      if (item.file?.file) {
                        platformPromise = this.uploadFile(item.file.file).then(
                          (fileUrl) => {
                            const platformData = {
                              ...baseData,
                              link: item.url || null,
                              file: fileUrl,
                            };
                            request[platform].push(platformData);
                          }
                        );
                        promises.push(platformPromise);
                      } else {
                        const platformData = {
                          ...baseData,
                          link: item.url || null,
                          file: null,
                        };
                        request[platform].push(platformData);
                      }
                    }
                    break;
                  }
                }
              }
            }
          }

          Promise.all(promises)
            .then(() => {
              console.log("Formatted request:", request);
              const formData = new FormData();

              // Build FormData from the request object with uploaded file URLs
              if (request && typeof request === "object") {
                for (const platform of Object.keys(request)) {
                  if (Array.isArray(request[platform])) {
                    for (const [index, item] of request[platform].entries()) {
                      if (item && typeof item === "object") {
                        for (const [key, value] of Object.entries(item)) {
                          formData.append(
                            `${platform}[${index}][${key}]`,
                            value ?? ""
                          );
                        }
                      }
                    }
                  }
                }
              } else {
                console.error("Invalid request object:", request);
              }

              // Log the final FormData entries
              for (const pair of formData.entries()) {
                console.log(`${pair[0]}:`, pair[1]);
              }

              resolve(request);
            })
            .catch((error) => {
              console.error("Error in formattedRequestToSend:", error);
              reject(error);
            });
        };

        processFiles();
      });
    },
  },
});
