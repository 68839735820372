export const locationData  ={
  "DMA": [
    {
      "id": 662,
      "level": 2,
      "name": "Abilene-Sweetwater",
      "parent_id": 6252001
    },
    {
      "id": 525,
      "level": 2,
      "name": "Albany, GA",
      "parent_id": 6252001
    },
    {
      "id": 532,
      "level": 2,
      "name": "Albany-Schenectady-Troy",
      "parent_id": 6252001
    },
    {
      "id": 790,
      "level": 2,
      "name": "Albuquerque-Santa Fe",
      "parent_id": 6252001
    },
    {
      "id": 644,
      "level": 2,
      "name": "Alexandria, LA",
      "parent_id": 6252001
    },
    {
      "id": 583,
      "level": 2,
      "name": "Alpena",
      "parent_id": 6252001
    },
    {
      "id": 634,
      "level": 2,
      "name": "Amarillo",
      "parent_id": 6252001
    },
    {
      "id": 743,
      "level": 2,
      "name": "Anchorage",
      "parent_id": 6252001
    },
    {
      "id": 524,
      "level": 2,
      "name": "Atlanta",
      "parent_id": 6252001
    },
    {
      "id": 520,
      "level": 2,
      "name": "Augusta-Aiken",
      "parent_id": 6252001
    },
    {
      "id": 635,
      "level": 2,
      "name": "Austin",
      "parent_id": 6252001
    },
    {
      "id": 800,
      "level": 2,
      "name": "Bakersfield",
      "parent_id": 6252001
    },
    {
      "id": 512,
      "level": 2,
      "name": "Baltimore",
      "parent_id": 6252001
    },
    {
      "id": 537,
      "level": 2,
      "name": "Bangor",
      "parent_id": 6252001
    },
    {
      "id": 716,
      "level": 2,
      "name": "Baton Rouge",
      "parent_id": 6252001
    },
    {
      "id": 692,
      "level": 2,
      "name": "Beaumont-Port Arthur",
      "parent_id": 6252001
    },
    {
      "id": 821,
      "level": 2,
      "name": "Bend, OR",
      "parent_id": 6252001
    },
    {
      "id": 756,
      "level": 2,
      "name": "Billings",
      "parent_id": 6252001
    },
    {
      "id": 746,
      "level": 2,
      "name": "Biloxi-Gulfport",
      "parent_id": 6252001
    },
    {
      "id": 502,
      "level": 2,
      "name": "Binghamton",
      "parent_id": 6252001
    },
    {
      "id": 630,
      "level": 2,
      "name": "Birmingham (Anniston-Tuscaloosa)",
      "parent_id": 6252001
    },
    {
      "id": 559,
      "level": 2,
      "name": "Bluefield-Beckley-Oak Hill",
      "parent_id": 6252001
    },
    {
      "id": 757,
      "level": 2,
      "name": "Boise",
      "parent_id": 6252001
    },
    {
      "id": 506,
      "level": 2,
      "name": "Boston (Manchester)",
      "parent_id": 6252001
    },
    {
      "id": 736,
      "level": 2,
      "name": "Bowling Green",
      "parent_id": 6252001
    },
    {
      "id": 514,
      "level": 2,
      "name": "Buffalo",
      "parent_id": 6252001
    },
    {
      "id": 523,
      "level": 2,
      "name": "Burlington-Plattsburgh",
      "parent_id": 6252001
    },
    {
      "id": 754,
      "level": 2,
      "name": "Butte-Bozeman",
      "parent_id": 6252001
    },
    {
      "id": 767,
      "level": 2,
      "name": "Casper-Riverton",
      "parent_id": 6252001
    },
    {
      "id": 637,
      "level": 2,
      "name": "Cedar Rapids-Waterloo-Iowa City-Dubuque",
      "parent_id": 6252001
    },
    {
      "id": 648,
      "level": 2,
      "name": "Champaign-Springfield-Decatur",
      "parent_id": 6252001
    },
    {
      "id": 519,
      "level": 2,
      "name": "Charleston, SC",
      "parent_id": 6252001
    },
    {
      "id": 564,
      "level": 2,
      "name": "Charleston-Huntington",
      "parent_id": 6252001
    },
    {
      "id": 517,
      "level": 2,
      "name": "Charlotte",
      "parent_id": 6252001
    },
    {
      "id": 584,
      "level": 2,
      "name": "Charlottesville",
      "parent_id": 6252001
    },
    {
      "id": 575,
      "level": 2,
      "name": "Chattanooga",
      "parent_id": 6252001
    },
    {
      "id": 759,
      "level": 2,
      "name": "Cheyenne-Scottsbluff",
      "parent_id": 6252001
    },
    {
      "id": 602,
      "level": 2,
      "name": "Chicago",
      "parent_id": 6252001
    },
    {
      "id": 868,
      "level": 2,
      "name": "Chico-Redding",
      "parent_id": 6252001
    },
    {
      "id": 515,
      "level": 2,
      "name": "Cincinnati",
      "parent_id": 6252001
    },
    {
      "id": 598,
      "level": 2,
      "name": "Clarksburg-Weston",
      "parent_id": 6252001
    },
    {
      "id": 510,
      "level": 2,
      "name": "Cleveland-Akron (Canton)",
      "parent_id": 6252001
    },
    {
      "id": 752,
      "level": 2,
      "name": "Colorado Springs-Pueblo",
      "parent_id": 6252001
    },
    {
      "id": 546,
      "level": 2,
      "name": "Columbia, SC",
      "parent_id": 6252001
    },
    {
      "id": 604,
      "level": 2,
      "name": "Columbia-Jefferson City",
      "parent_id": 6252001
    },
    {
      "id": 522,
      "level": 2,
      "name": "Columbus, GA (Opelika, AL)",
      "parent_id": 6252001
    },
    {
      "id": 535,
      "level": 2,
      "name": "Columbus, OH",
      "parent_id": 6252001
    },
    {
      "id": 673,
      "level": 2,
      "name": "Columbus-Tupelo-West Point-Houston",
      "parent_id": 6252001
    },
    {
      "id": 600,
      "level": 2,
      "name": "Corpus Christi",
      "parent_id": 6252001
    },
    {
      "id": 623,
      "level": 2,
      "name": "Dallas-Fort Worth",
      "parent_id": 6252001
    },
    {
      "id": 682,
      "level": 2,
      "name": "Davenport-Rock Island-Moline",
      "parent_id": 6252001
    },
    {
      "id": 542,
      "level": 2,
      "name": "Dayton",
      "parent_id": 6252001
    },
    {
      "id": 751,
      "level": 2,
      "name": "Denver",
      "parent_id": 6252001
    },
    {
      "id": 679,
      "level": 2,
      "name": "Des Moines-Ames",
      "parent_id": 6252001
    },
    {
      "id": 505,
      "level": 2,
      "name": "Detroit",
      "parent_id": 6252001
    },
    {
      "id": 606,
      "level": 2,
      "name": "Dothan",
      "parent_id": 6252001
    },
    {
      "id": 676,
      "level": 2,
      "name": "Duluth-Superior, WI",
      "parent_id": 6252001
    },
    {
      "id": 765,
      "level": 2,
      "name": "El Paso (Las Cruces)",
      "parent_id": 6252001
    },
    {
      "id": 565,
      "level": 2,
      "name": "Elmira (Corning)",
      "parent_id": 6252001
    },
    {
      "id": 516,
      "level": 2,
      "name": "Erie",
      "parent_id": 6252001
    },
    {
      "id": 801,
      "level": 2,
      "name": "Eugene",
      "parent_id": 6252001
    },
    {
      "id": 802,
      "level": 2,
      "name": "Eureka",
      "parent_id": 6252001
    },
    {
      "id": 649,
      "level": 2,
      "name": "Evansville",
      "parent_id": 6252001
    },
    {
      "id": 745,
      "level": 2,
      "name": "Fairbanks",
      "parent_id": 6252001
    },
    {
      "id": 724,
      "level": 2,
      "name": "Fargo-Moorhead-Grand Forks",
      "parent_id": 6252001
    },
    {
      "id": 513,
      "level": 2,
      "name": "Flint-Saginaw-Bay City",
      "parent_id": 6252001
    },
    {
      "id": 571,
      "level": 2,
      "name": "Fort Myers-Naples",
      "parent_id": 6252001
    },
    {
      "id": 670,
      "level": 2,
      "name": "Fort Smith-Fayetteville-Springdale-Rogers",
      "parent_id": 6252001
    },
    {
      "id": 509,
      "level": 2,
      "name": "Fort Wayne",
      "parent_id": 6252001
    },
    {
      "id": 866,
      "level": 2,
      "name": "Fresno-Visalia",
      "parent_id": 6252001
    },
    {
      "id": 592,
      "level": 2,
      "name": "Gainesville",
      "parent_id": 6252001
    },
    {
      "id": 798,
      "level": 2,
      "name": "Glendive",
      "parent_id": 6252001
    },
    {
      "id": 773,
      "level": 2,
      "name": "Grand Junction-Montrose",
      "parent_id": 6252001
    },
    {
      "id": 563,
      "level": 2,
      "name": "Grand Rapids-Kalamazoo-Battle Creek",
      "parent_id": 6252001
    },
    {
      "id": 755,
      "level": 2,
      "name": "Great Falls",
      "parent_id": 6252001
    },
    {
      "id": 658,
      "level": 2,
      "name": "Green Bay-Appleton",
      "parent_id": 6252001
    },
    {
      "id": 518,
      "level": 2,
      "name": "Greensboro–High Point–Winston-Salem",
      "parent_id": 6252001
    },
    {
      "id": 545,
      "level": 2,
      "name": "Greenville-New Bern-Washington",
      "parent_id": 6252001
    },
    {
      "id": 567,
      "level": 2,
      "name": "Greenville-Spartanburg-Asheville-Anderson",
      "parent_id": 6252001
    },
    {
      "id": 647,
      "level": 2,
      "name": "Greenwood-Greenville",
      "parent_id": 6252001
    },
    {
      "id": 636,
      "level": 2,
      "name": "Harlingen-Weslaco-Brownsville-McAllen",
      "parent_id": 6252001
    },
    {
      "id": 566,
      "level": 2,
      "name": "Harrisburg-Lancaster-Lebanon-York",
      "parent_id": 6252001
    },
    {
      "id": 569,
      "level": 2,
      "name": "Harrisonburg",
      "parent_id": 6252001
    },
    {
      "id": 533,
      "level": 2,
      "name": "Hartford-New Haven",
      "parent_id": 6252001
    },
    {
      "id": 710,
      "level": 2,
      "name": "Hattiesburg-Laurel",
      "parent_id": 6252001
    },
    {
      "id": 766,
      "level": 2,
      "name": "Helena",
      "parent_id": 6252001
    },
    {
      "id": 744,
      "level": 2,
      "name": "Honolulu",
      "parent_id": 6252001
    },
    {
      "id": 618,
      "level": 2,
      "name": "Houston",
      "parent_id": 6252001
    },
    {
      "id": 691,
      "level": 2,
      "name": "Huntsville-Decatur (Florence)",
      "parent_id": 6252001
    },
    {
      "id": 758,
      "level": 2,
      "name": "Idaho Falls-Pocatello (Jackson)",
      "parent_id": 6252001
    },
    {
      "id": 527,
      "level": 2,
      "name": "Indianapolis",
      "parent_id": 6252001
    },
    {
      "id": 718,
      "level": 2,
      "name": "Jackson, MS",
      "parent_id": 6252001
    },
    {
      "id": 639,
      "level": 2,
      "name": "Jackson, TN",
      "parent_id": 6252001
    },
    {
      "id": 561,
      "level": 2,
      "name": "Jacksonville",
      "parent_id": 6252001
    },
    {
      "id": 574,
      "level": 2,
      "name": "Johnstown-Altoona-State College",
      "parent_id": 6252001
    },
    {
      "id": 734,
      "level": 2,
      "name": "Jonesboro",
      "parent_id": 6252001
    },
    {
      "id": 603,
      "level": 2,
      "name": "Joplin-Pittsburg",
      "parent_id": 6252001
    },
    {
      "id": 747,
      "level": 2,
      "name": "Juneau",
      "parent_id": 6252001
    },
    {
      "id": 616,
      "level": 2,
      "name": "Kansas City",
      "parent_id": 6252001
    },
    {
      "id": 557,
      "level": 2,
      "name": "Knoxville",
      "parent_id": 6252001
    },
    {
      "id": 702,
      "level": 2,
      "name": "La Crosse-Eau Claire",
      "parent_id": 6252001
    },
    {
      "id": 582,
      "level": 2,
      "name": "Lafayette, IN",
      "parent_id": 6252001
    },
    {
      "id": 642,
      "level": 2,
      "name": "Lafayette, LA",
      "parent_id": 6252001
    },
    {
      "id": 643,
      "level": 2,
      "name": "Lake Charles",
      "parent_id": 6252001
    },
    {
      "id": 551,
      "level": 2,
      "name": "Lansing",
      "parent_id": 6252001
    },
    {
      "id": 749,
      "level": 2,
      "name": "Laredo",
      "parent_id": 6252001
    },
    {
      "id": 839,
      "level": 2,
      "name": "Las Vegas",
      "parent_id": 6252001
    },
    {
      "id": 541,
      "level": 2,
      "name": "Lexington",
      "parent_id": 6252001
    },
    {
      "id": 558,
      "level": 2,
      "name": "Lima",
      "parent_id": 6252001
    },
    {
      "id": 722,
      "level": 2,
      "name": "Lincoln-Hastings-Kearney",
      "parent_id": 6252001
    },
    {
      "id": 693,
      "level": 2,
      "name": "Little Rock-Pine Bluff",
      "parent_id": 6252001
    },
    {
      "id": 803,
      "level": 2,
      "name": "Los Angeles",
      "parent_id": 6252001
    },
    {
      "id": 529,
      "level": 2,
      "name": "Louisville",
      "parent_id": 6252001
    },
    {
      "id": 651,
      "level": 2,
      "name": "Lubbock",
      "parent_id": 6252001
    },
    {
      "id": 503,
      "level": 2,
      "name": "Macon",
      "parent_id": 6252001
    },
    {
      "id": 669,
      "level": 2,
      "name": "Madison",
      "parent_id": 6252001
    },
    {
      "id": 737,
      "level": 2,
      "name": "Mankato",
      "parent_id": 6252001
    },
    {
      "id": 553,
      "level": 2,
      "name": "Marquette",
      "parent_id": 6252001
    },
    {
      "id": 813,
      "level": 2,
      "name": "Medford-Klamath Falls",
      "parent_id": 6252001
    },
    {
      "id": 640,
      "level": 2,
      "name": "Memphis",
      "parent_id": 6252001
    },
    {
      "id": 711,
      "level": 2,
      "name": "Meridian",
      "parent_id": 6252001
    },
    {
      "id": 528,
      "level": 2,
      "name": "Miami-Fort Lauderdale",
      "parent_id": 6252001
    },
    {
      "id": 617,
      "level": 2,
      "name": "Milwaukee",
      "parent_id": 6252001
    },
    {
      "id": 613,
      "level": 2,
      "name": "Minneapolis-St. Paul",
      "parent_id": 6252001
    },
    {
      "id": 687,
      "level": 2,
      "name": "Minot-Bismarck-Dickinson (Williston)",
      "parent_id": 6252001
    },
    {
      "id": 762,
      "level": 2,
      "name": "Missoula",
      "parent_id": 6252001
    },
    {
      "id": 686,
      "level": 2,
      "name": "Mobile-Pensacola (Navarre)",
      "parent_id": 6252001
    },
    {
      "id": 628,
      "level": 2,
      "name": "Monroe-El Dorado",
      "parent_id": 6252001
    },
    {
      "id": 828,
      "level": 2,
      "name": "Monterey-Salinas",
      "parent_id": 6252001
    },
    {
      "id": 698,
      "level": 2,
      "name": "Montgomery-Selma",
      "parent_id": 6252001
    },
    {
      "id": 570,
      "level": 2,
      "name": "Myrtle Beach-Florence",
      "parent_id": 6252001
    },
    {
      "id": 659,
      "level": 2,
      "name": "Nashville",
      "parent_id": 6252001
    },
    {
      "id": 622,
      "level": 2,
      "name": "New Orleans",
      "parent_id": 6252001
    },
    {
      "id": 501,
      "level": 2,
      "name": "New York",
      "parent_id": 6252001
    },
    {
      "id": 544,
      "level": 2,
      "name": "Norfolk-Portsmouth-Newport News",
      "parent_id": 6252001
    },
    {
      "id": 740,
      "level": 2,
      "name": "North Platte",
      "parent_id": 6252001
    },
    {
      "id": 633,
      "level": 2,
      "name": "Odessa-Midland",
      "parent_id": 6252001
    },
    {
      "id": 650,
      "level": 2,
      "name": "Oklahoma City",
      "parent_id": 6252001
    },
    {
      "id": 652,
      "level": 2,
      "name": "Omaha",
      "parent_id": 6252001
    },
    {
      "id": 534,
      "level": 2,
      "name": "Orlando-Daytona Beach-Melbourne",
      "parent_id": 6252001
    },
    {
      "id": 631,
      "level": 2,
      "name": "Ottumwa-Kirksville",
      "parent_id": 6252001
    },
    {
      "id": 632,
      "level": 2,
      "name": "Paducah-Cape Girardeau-Harrisburg",
      "parent_id": 6252001
    },
    {
      "id": 804,
      "level": 2,
      "name": "Palm Springs",
      "parent_id": 6252001
    },
    {
      "id": 656,
      "level": 2,
      "name": "Panama City",
      "parent_id": 6252001
    },
    {
      "id": 597,
      "level": 2,
      "name": "Parkersburg",
      "parent_id": 6252001
    },
    {
      "id": 675,
      "level": 2,
      "name": "Peoria-Bloomington",
      "parent_id": 6252001
    },
    {
      "id": 504,
      "level": 2,
      "name": "Philadelphia",
      "parent_id": 6252001
    },
    {
      "id": 753,
      "level": 2,
      "name": "Phoenix (Prescott)",
      "parent_id": 6252001
    },
    {
      "id": 508,
      "level": 2,
      "name": "Pittsburgh",
      "parent_id": 6252001
    },
    {
      "id": 820,
      "level": 2,
      "name": "Portland, OR",
      "parent_id": 6252001
    },
    {
      "id": 500,
      "level": 2,
      "name": "Portland-Auburn",
      "parent_id": 6252001
    },
    {
      "id": 552,
      "level": 2,
      "name": "Presque Isle",
      "parent_id": 6252001
    },
    {
      "id": 521,
      "level": 2,
      "name": "Providence-New Bedford",
      "parent_id": 6252001
    },
    {
      "id": 717,
      "level": 2,
      "name": "Quincy-Hannibal-Keokuk",
      "parent_id": 6252001
    },
    {
      "id": 560,
      "level": 2,
      "name": "Raleigh-Durham (Fayetteville)",
      "parent_id": 6252001
    },
    {
      "id": 764,
      "level": 2,
      "name": "Rapid City",
      "parent_id": 6252001
    },
    {
      "id": 811,
      "level": 2,
      "name": "Reno",
      "parent_id": 6252001
    },
    {
      "id": 556,
      "level": 2,
      "name": "Richmond-Petersburg",
      "parent_id": 6252001
    },
    {
      "id": 573,
      "level": 2,
      "name": "Roanoke-Lynchburg",
      "parent_id": 6252001
    },
    {
      "id": 538,
      "level": 2,
      "name": "Rochester, NY",
      "parent_id": 6252001
    },
    {
      "id": 611,
      "level": 2,
      "name": "Rochestr-Mason City-Austin",
      "parent_id": 6252001
    },
    {
      "id": 610,
      "level": 2,
      "name": "Rockford",
      "parent_id": 6252001
    },
    {
      "id": 862,
      "level": 2,
      "name": "Sacramento-Stockton-Modesto",
      "parent_id": 6252001
    },
    {
      "id": 576,
      "level": 2,
      "name": "Salisbury",
      "parent_id": 6252001
    },
    {
      "id": 770,
      "level": 2,
      "name": "Salt Lake City",
      "parent_id": 6252001
    },
    {
      "id": 661,
      "level": 2,
      "name": "San Angelo",
      "parent_id": 6252001
    },
    {
      "id": 641,
      "level": 2,
      "name": "San Antonio",
      "parent_id": 6252001
    },
    {
      "id": 825,
      "level": 2,
      "name": "San Diego",
      "parent_id": 6252001
    },
    {
      "id": 807,
      "level": 2,
      "name": "San Francisco-Oakland-San Jose",
      "parent_id": 6252001
    },
    {
      "id": 855,
      "level": 2,
      "name": "Santa Barbara-Santa Maria-San Luis Obispo",
      "parent_id": 6252001
    },
    {
      "id": 507,
      "level": 2,
      "name": "Savannah",
      "parent_id": 6252001
    },
    {
      "id": 819,
      "level": 2,
      "name": "Seattle-Tacoma",
      "parent_id": 6252001
    },
    {
      "id": 657,
      "level": 2,
      "name": "Sherman-Ada",
      "parent_id": 6252001
    },
    {
      "id": 612,
      "level": 2,
      "name": "Shreveport",
      "parent_id": 6252001
    },
    {
      "id": 624,
      "level": 2,
      "name": "Sioux City",
      "parent_id": 6252001
    },
    {
      "id": 725,
      "level": 2,
      "name": "Sioux Falls(Mitchell)",
      "parent_id": 6252001
    },
    {
      "id": 588,
      "level": 2,
      "name": "South Bend-Elkhart",
      "parent_id": 6252001
    },
    {
      "id": 881,
      "level": 2,
      "name": "Spokane",
      "parent_id": 6252001
    },
    {
      "id": 619,
      "level": 2,
      "name": "Springfield, MO",
      "parent_id": 6252001
    },
    {
      "id": 543,
      "level": 2,
      "name": "Springfield-Holyoke",
      "parent_id": 6252001
    },
    {
      "id": 638,
      "level": 2,
      "name": "St. Joseph",
      "parent_id": 6252001
    },
    {
      "id": 609,
      "level": 2,
      "name": "St. Louis",
      "parent_id": 6252001
    },
    {
      "id": 555,
      "level": 2,
      "name": "Syracuse",
      "parent_id": 6252001
    },
    {
      "id": 530,
      "level": 2,
      "name": "Tallahassee-Thomasville",
      "parent_id": 6252001
    },
    {
      "id": 539,
      "level": 2,
      "name": "Tampa-St. Petersburg (Sarasota)",
      "parent_id": 6252001
    },
    {
      "id": 581,
      "level": 2,
      "name": "Terre Haute",
      "parent_id": 6252001
    },
    {
      "id": 547,
      "level": 2,
      "name": "Toledo",
      "parent_id": 6252001
    },
    {
      "id": 605,
      "level": 2,
      "name": "Topeka",
      "parent_id": 6252001
    },
    {
      "id": 540,
      "level": 2,
      "name": "Traverse City-Cadillac",
      "parent_id": 6252001
    },
    {
      "id": 531,
      "level": 2,
      "name": "Tri-Cities, TN-VA",
      "parent_id": 6252001
    },
    {
      "id": 789,
      "level": 2,
      "name": "Tucson (Sierra Vista)",
      "parent_id": 6252001
    },
    {
      "id": 671,
      "level": 2,
      "name": "Tulsa",
      "parent_id": 6252001
    },
    {
      "id": 760,
      "level": 2,
      "name": "Twin Falls",
      "parent_id": 6252001
    },
    {
      "id": 709,
      "level": 2,
      "name": "Tyler-Longview (Lufkin-Nacogdoches)",
      "parent_id": 6252001
    },
    {
      "id": 526,
      "level": 2,
      "name": "Utica",
      "parent_id": 6252001
    },
    {
      "id": 626,
      "level": 2,
      "name": "Victoria",
      "parent_id": 6252001
    },
    {
      "id": 625,
      "level": 2,
      "name": "Waco-Temple-Bryan",
      "parent_id": 6252001
    },
    {
      "id": 511,
      "level": 2,
      "name": "Washington (Hagerstown)",
      "parent_id": 6252001
    },
    {
      "id": 549,
      "level": 2,
      "name": "Watertown",
      "parent_id": 6252001
    },
    {
      "id": 705,
      "level": 2,
      "name": "Wausau-Rhinelander",
      "parent_id": 6252001
    },
    {
      "id": 548,
      "level": 2,
      "name": "West Palm Beach-Fort Pierce",
      "parent_id": 6252001
    },
    {
      "id": 554,
      "level": 2,
      "name": "Wheeling-Steubenville, OH",
      "parent_id": 6252001
    },
    {
      "id": 627,
      "level": 2,
      "name": "Wichita Falls & Lawton",
      "parent_id": 6252001
    },
    {
      "id": 678,
      "level": 2,
      "name": "Wichita-Hutchinson",
      "parent_id": 6252001
    },
    {
      "id": 577,
      "level": 2,
      "name": "Wilkes-Barre–Scranton–Hazleton",
      "parent_id": 6252001
    },
    {
      "id": 550,
      "level": 2,
      "name": "Wilmington",
      "parent_id": 6252001
    },
    {
      "id": 810,
      "level": 2,
      "name": "Yakima-Pasco-Richland-Kennewick",
      "parent_id": 6252001
    },
    {
      "id": 536,
      "level": 2,
      "name": "Youngstown",
      "parent_id": 6252001
    },
    {
      "id": 771,
      "level": 2,
      "name": "Yuma-El Centro",
      "parent_id": 6252001
    },
    {
      "id": 596,
      "level": 2,
      "name": "Zanesville",
      "parent_id": 6252001
    }
  ],
  "cities": [
    {
      "country_code": "IN",
      "id": 1253880,
      "level": 3,
      "name": "Uluberia",
      "parent_id": 1252881
    },
    {
      "country_code": "IN",
      "id": 1255783,
      "level": 3,
      "name": "Rajpur Sonarpur",
      "parent_id": 1252881
    },
    {
      "country_code": "IN",
      "id": 1256525,
      "level": 3,
      "name": "Siliguri",
      "parent_id": 1252881
    },
    {
      "country_code": "IN",
      "id": 1259009,
      "level": 3,
      "name": "Raiganj",
      "parent_id": 1252881
    },
    {
      "country_code": "IN",
      "id": 1260482,
      "level": 3,
      "name": "Panihati",
      "parent_id": 1252881
    },
    {
      "country_code": "IN",
      "id": 1262131,
      "level": 3,
      "name": "Naihati",
      "parent_id": 1252881
    },
    {
      "country_code": "IN",
      "id": 1264543,
      "level": 3,
      "name": "Madhyamgram",
      "parent_id": 1252881
    },
    {
      "country_code": "IN",
      "id": 1265711,
      "level": 3,
      "name": "Kulti",
      "parent_id": 1252881
    },
    {
      "country_code": "IN",
      "id": 1266976,
      "level": 3,
      "name": "Kharagpur",
      "parent_id": 1252881
    },
    {
      "country_code": "IN",
      "id": 1268257,
      "level": 3,
      "name": "Kamarhati",
      "parent_id": 1252881
    },
    {
      "country_code": "IN",
      "id": 1269723,
      "level": 3,
      "name": "English Bazar",
      "parent_id": 1252881
    },
    {
      "country_code": "IN",
      "id": 1270396,
      "level": 3,
      "name": "Howrah",
      "parent_id": 1252881
    },
    {
      "country_code": "IN",
      "id": 1272175,
      "level": 3,
      "name": "Durgapur",
      "parent_id": 1252881
    },
    {
      "country_code": "IN",
      "id": 1275004,
      "level": 3,
      "name": "Kolkata",
      "parent_id": 1252881
    },
    {
      "country_code": "IN",
      "id": 1276058,
      "level": 3,
      "name": "Bhatpara",
      "parent_id": 1252881
    },
    {
      "country_code": "IN",
      "id": 1277029,
      "level": 3,
      "name": "Bardhaman",
      "parent_id": 1252881
    },
    {
      "country_code": "IN",
      "id": 1277065,
      "level": 3,
      "name": "Barasat",
      "parent_id": 1252881
    },
    {
      "country_code": "IN",
      "id": 1277082,
      "level": 3,
      "name": "Baranagar",
      "parent_id": 1252881
    },
    {
      "country_code": "IN",
      "id": 1277820,
      "level": 3,
      "name": "Baharampur",
      "parent_id": 1252881
    },
    {
      "country_code": "IN",
      "id": 1344377,
      "level": 3,
      "name": "Haldia",
      "parent_id": 1252881
    },
    {
      "country_code": "IN",
      "id": 1348597,
      "level": 3,
      "name": "Kanpur",
      "parent_id": 1252881
    },
    {
      "country_code": "IN",
      "id": 1349195,
      "level": 3,
      "name": "Chapra",
      "parent_id": 1252881
    },
    {
      "country_code": "IN",
      "id": 11861508,
      "level": 3,
      "name": "Bally",
      "parent_id": 1252881
    },
    {
      "country_code": "IN",
      "id": 11861599,
      "level": 3,
      "name": "Serampore",
      "parent_id": 1252881
    },
    {
      "country_code": "IN",
      "id": 1269388,
      "level": 3,
      "name": "Jalpaiguri",
      "parent_id": 1252881
    },
    {
      "country_code": "IN",
      "id": 8335041,
      "level": 3,
      "name": "Rajpur Sonarpur",
      "parent_id": 1252881
    },
    {
      "country_code": "IN",
      "id": 1273467,
      "level": 3,
      "name": "Siliguri",
      "parent_id": 1252881
    },
    {
      "country_code": "IN",
      "id": 8335142,
      "level": 3,
      "name": "Raiganj",
      "parent_id": 1252881
    },
    {
      "country_code": "IN",
      "id": 1264121,
      "level": 3,
      "name": "English Bazar",
      "parent_id": 1252881
    },
    {
      "country_code": "IN",
      "id": 1262412,
      "level": 3,
      "name": "Baharampur",
      "parent_id": 1252881
    },
    {
      "country_code": "IN",
      "id": 8335139,
      "level": 3,
      "name": "Haldia",
      "parent_id": 1252881
    },
    {
      "country_code": "IN",
      "id": 1262293,
      "level": 3,
      "name": "Chapra",
      "parent_id": 1252881
    },
    {
      "country_code": "IN",
      "id": 1269910,
      "level": 3,
      "name": "Serampore",
      "parent_id": 1252881
    },
    {
      "country_code": "IN",
      "id": 8335144,
      "level": 3,
      "name": "24 Paraganas North",
      "parent_id": 1252881
    },
    {
      "country_code": "IN",
      "id": 8335137,
      "level": 3,
      "name": "Medinipur West",
      "parent_id": 1252881
    },
    {
      "country_code": "IN",
      "id": 1253405,
      "level": 3,
      "name": "Varanasi",
      "parent_id": 1253626
    },
    {
      "country_code": "IN",
      "id": 1253747,
      "level": 3,
      "name": "Unnao",
      "parent_id": 1253626
    },
    {
      "country_code": "IN",
      "id": 1257540,
      "level": 3,
      "name": "Sambhal",
      "parent_id": 1253626
    },
    {
      "country_code": "IN",
      "id": 1257806,
      "level": 3,
      "name": "Saharanpur",
      "parent_id": 1253626
    },
    {
      "country_code": "IN",
      "id": 1259065,
      "level": 3,
      "name": "Rae Bareli",
      "parent_id": 1253626
    },
    {
      "country_code": "IN",
      "id": 1261039,
      "level": 3,
      "name": "Orai",
      "parent_id": 1253626
    },
    {
      "country_code": "IN",
      "id": 1262332,
      "level": 3,
      "name": "Muzaffarnagar",
      "parent_id": 1253626
    },
    {
      "country_code": "IN",
      "id": 1262995,
      "level": 3,
      "name": "Mirzapur",
      "parent_id": 1253626
    },
    {
      "country_code": "IN",
      "id": 1263214,
      "level": 3,
      "name": "Meerut",
      "parent_id": 1253626
    },
    {
      "country_code": "IN",
      "id": 1263364,
      "level": 3,
      "name": "Mathura",
      "parent_id": 1253626
    },
    {
      "country_code": "IN",
      "id": 1264733,
      "level": 3,
      "name": "Lucknow",
      "parent_id": 1253626
    },
    {
      "country_code": "IN",
      "id": 1264773,
      "level": 3,
      "name": "Loni",
      "parent_id": 1253626
    },
    {
      "country_code": "IN",
      "id": 1269006,
      "level": 3,
      "name": "Jhansi",
      "parent_id": 1253626
    },
    {
      "country_code": "IN",
      "id": 1269135,
      "level": 3,
      "name": "Jaunpur",
      "parent_id": 1253626
    },
    {
      "country_code": "IN",
      "id": 1270393,
      "level": 3,
      "name": "Hapur",
      "parent_id": 1253626
    },
    {
      "country_code": "IN",
      "id": 1270927,
      "level": 3,
      "name": "Gorakhpur",
      "parent_id": 1253626
    },
    {
      "country_code": "IN",
      "id": 1271308,
      "level": 3,
      "name": "Ghaziabad",
      "parent_id": 1253626
    },
    {
      "country_code": "IN",
      "id": 1271885,
      "level": 3,
      "name": "Firozabad",
      "parent_id": 1253626
    },
    {
      "country_code": "IN",
      "id": 1271942,
      "level": 3,
      "name": "Farrukhabad",
      "parent_id": 1253626
    },
    {
      "country_code": "IN",
      "id": 1271987,
      "level": 3,
      "name": "Etawah",
      "parent_id": 1253626
    },
    {
      "country_code": "IN",
      "id": 1275120,
      "level": 3,
      "name": "Bulandshahr",
      "parent_id": 1253626
    },
    {
      "country_code": "IN",
      "id": 1275634,
      "level": 3,
      "name": "Bilaspur",
      "parent_id": 1253626
    },
    {
      "country_code": "IN",
      "id": 1277013,
      "level": 3,
      "name": "Bareilly",
      "parent_id": 1253626
    },
    {
      "country_code": "IN",
      "id": 1278708,
      "level": 3,
      "name": "Amroha",
      "parent_id": 1253626
    },
    {
      "country_code": "IN",
      "id": 1278994,
      "level": 3,
      "name": "Allahabad",
      "parent_id": 1253626
    },
    {
      "country_code": "IN",
      "id": 1279017,
      "level": 3,
      "name": "Aligarh",
      "parent_id": 1253626
    },
    {
      "country_code": "IN",
      "id": 1279259,
      "level": 3,
      "name": "Agra",
      "parent_id": 1253626
    },
    {
      "country_code": "IN",
      "id": 7279746,
      "level": 3,
      "name": "Noida",
      "parent_id": 1253626
    },
    {
      "country_code": "IN",
      "id": 10168017,
      "level": 3,
      "name": "Khora",
      "parent_id": 1253626
    },
    {
      "country_code": "IN",
      "id": 10668764,
      "level": 3,
      "name": "Bahraich",
      "parent_id": 1253626
    },
    {
      "country_code": "IN",
      "id": 1262801,
      "level": 3,
      "name": "Moradabad",
      "parent_id": 1253626
    },
    {
      "country_code": "IN",
      "id": 1278189,
      "level": 3,
      "name": "Atrauli",
      "parent_id": 1253626
    },
    {
      "country_code": "IN",
      "id": 1269423,
      "level": 3,
      "name": "Orai",
      "parent_id": 1253626
    },
    {
      "country_code": "IN",
      "id": 1258599,
      "level": 3,
      "name": "Bilaspur",
      "parent_id": 1253626
    },
    {
      "country_code": "IN",
      "id": 9072784,
      "level": 3,
      "name": "Noida",
      "parent_id": 1253626
    },
    {
      "country_code": "IN",
      "id": 1277799,
      "level": 3,
      "name": "Bahraich",
      "parent_id": 1253626
    },
    {
      "country_code": "IN",
      "id": 7729743,
      "level": 3,
      "name": "Ambedkar Nagar",
      "parent_id": 1253626
    },
    {
      "country_code": "IN",
      "id": 1256729,
      "level": 3,
      "name": "Shahjahanpur",
      "parent_id": 1253626
    },
    {
      "country_code": "IN",
      "id": 1267997,
      "level": 3,
      "name": "Kanpur",
      "parent_id": 1253626
    },
    {
      "country_code": "IN",
      "id": 1253987,
      "level": 3,
      "name": "Udaipur",
      "parent_id": 1254169
    },
    {
      "country_code": "IN",
      "id": 1279290,
      "level": 3,
      "name": "Agartala",
      "parent_id": 1254169
    },
    {
      "country_code": "IN",
      "id": 1252864,
      "level": 3,
      "name": "Agartala",
      "parent_id": 1254169
    },
    {
      "country_code": "IN",
      "id": 1252948,
      "level": 3,
      "name": "Warangal",
      "parent_id": 1254788
    },
    {
      "country_code": "IN",
      "id": 1256922,
      "level": 3,
      "name": "Secunderabad",
      "parent_id": 1254788
    },
    {
      "country_code": "IN",
      "id": 1258662,
      "level": 3,
      "name": "Ramagundam",
      "parent_id": 1254788
    },
    {
      "country_code": "IN",
      "id": 1261258,
      "level": 3,
      "name": "Nizamabad",
      "parent_id": 1254788
    },
    {
      "country_code": "IN",
      "id": 1267076,
      "level": 3,
      "name": "Khammam",
      "parent_id": 1254788
    },
    {
      "country_code": "IN",
      "id": 1267755,
      "level": 3,
      "name": "Karimnagar",
      "parent_id": 1254788
    },
    {
      "country_code": "IN",
      "id": 1269843,
      "level": 3,
      "name": "Hyderabad",
      "parent_id": 1254788
    },
    {
      "country_code": "IN",
      "id": 11860725,
      "level": 3,
      "name": "Miryalaguda",
      "parent_id": 1254788
    },
    {
      "country_code": "IN",
      "id": 11875541,
      "level": 3,
      "name": "Suryapet",
      "parent_id": 1254788
    },
    {
      "country_code": "IN",
      "id": 1262067,
      "level": 3,
      "name": "Miryalaguda",
      "parent_id": 1254788
    },
    {
      "country_code": "IN",
      "id": 1253286,
      "level": 3,
      "name": "Vellore",
      "parent_id": 1255053
    },
    {
      "country_code": "IN",
      "id": 1254348,
      "level": 3,
      "name": "Tiruppur",
      "parent_id": 1255053
    },
    {
      "country_code": "IN",
      "id": 1254361,
      "level": 3,
      "name": "Tirunelveli",
      "parent_id": 1255053
    },
    {
      "country_code": "IN",
      "id": 1254388,
      "level": 3,
      "name": "Tiruchirappalli",
      "parent_id": 1255053
    },
    {
      "country_code": "IN",
      "id": 1257629,
      "level": 3,
      "name": "Salem",
      "parent_id": 1255053
    },
    {
      "country_code": "IN",
      "id": 1260692,
      "level": 3,
      "name": "Pallavaram",
      "parent_id": 1255053
    },
    {
      "country_code": "IN",
      "id": 1262204,
      "level": 3,
      "name": "Nagercoil",
      "parent_id": 1255053
    },
    {
      "country_code": "IN",
      "id": 1264521,
      "level": 3,
      "name": "Madurai",
      "parent_id": 1255053
    },
    {
      "country_code": "IN",
      "id": 1264527,
      "level": 3,
      "name": "Chennai",
      "parent_id": 1255053
    },
    {
      "country_code": "IN",
      "id": 1265683,
      "level": 3,
      "name": "Kumbakonam",
      "parent_id": 1255053
    },
    {
      "country_code": "IN",
      "id": 1272013,
      "level": 3,
      "name": "Erode",
      "parent_id": 1255053
    },
    {
      "country_code": "IN",
      "id": 1272543,
      "level": 3,
      "name": "Dindigul",
      "parent_id": 1255053
    },
    {
      "country_code": "IN",
      "id": 1273865,
      "level": 3,
      "name": "Coimbatore",
      "parent_id": 1255053
    },
    {
      "country_code": "IN",
      "id": 1278130,
      "level": 3,
      "name": "Avadi",
      "parent_id": 1255053
    },
    {
      "country_code": "IN",
      "id": 1278840,
      "level": 3,
      "name": "Ambattur",
      "parent_id": 1255053
    },
    {
      "country_code": "IN",
      "id": 8629640,
      "level": 3,
      "name": "Thoothukudi",
      "parent_id": 1255053
    },
    {
      "country_code": "IN",
      "id": 11661079,
      "level": 3,
      "name": "Karaikudi",
      "parent_id": 1255053
    },
    {
      "country_code": "IN",
      "id": 11858126,
      "level": 3,
      "name": "Tiruvottiyur",
      "parent_id": 1255053
    },
    {
      "country_code": "IN",
      "id": 11859814,
      "level": 3,
      "name": "Thanjavur",
      "parent_id": 1255053
    },
    {
      "country_code": "IN",
      "id": 1262039,
      "level": 3,
      "name": "Namakkal",
      "parent_id": 1255053
    },
    {
      "country_code": "IN",
      "id": 1269934,
      "level": 3,
      "name": "Hosur",
      "parent_id": 1255053
    },
    {
      "country_code": "IN",
      "id": 1268159,
      "level": 3,
      "name": "Kanchipuram",
      "parent_id": 1255053
    },
    {
      "country_code": "IN",
      "id": 1268008,
      "level": 3,
      "name": "Nagercoil",
      "parent_id": 1255053
    },
    {
      "country_code": "IN",
      "id": 7646149,
      "level": 3,
      "name": "Kanchipuram",
      "parent_id": 1255053
    },
    {
      "country_code": "IN",
      "id": 1265863,
      "level": 3,
      "name": "Hosur",
      "parent_id": 1255053
    },
    {
      "country_code": "IN",
      "id": 1254331,
      "level": 3,
      "name": "Avadi",
      "parent_id": 1255053
    },
    {
      "country_code": "IN",
      "id": 1255953,
      "level": 3,
      "name": "Karaikudi",
      "parent_id": 1255053
    },
    {
      "country_code": "IN",
      "id": 1254649,
      "level": 3,
      "name": "Thanjavur",
      "parent_id": 1255053
    },
    {
      "country_code": "IN",
      "id": 1271631,
      "level": 3,
      "name": "Gangtok",
      "parent_id": 1256312
    },
    {
      "country_code": "IN",
      "id": 1272129,
      "level": 3,
      "name": "Gangtok",
      "parent_id": 1256312
    },
    {
      "country_code": "IN",
      "id": 1256320,
      "level": 3,
      "name": "Sikar",
      "parent_id": 1258899
    },
    {
      "country_code": "IN",
      "id": 1256727,
      "level": 3,
      "name": "Shahjahanpur",
      "parent_id": 1258899
    },
    {
      "country_code": "IN",
      "id": 1260716,
      "level": 3,
      "name": "Pali",
      "parent_id": 1258899
    },
    {
      "country_code": "IN",
      "id": 1266049,
      "level": 3,
      "name": "Kota",
      "parent_id": 1258899
    },
    {
      "country_code": "IN",
      "id": 1268865,
      "level": 3,
      "name": "Jodhpur",
      "parent_id": 1258899
    },
    {
      "country_code": "IN",
      "id": 1269515,
      "level": 3,
      "name": "Jaipur",
      "parent_id": 1258899
    },
    {
      "country_code": "IN",
      "id": 1271685,
      "level": 3,
      "name": "Sri Ganganagar",
      "parent_id": 1258899
    },
    {
      "country_code": "IN",
      "id": 1275665,
      "level": 3,
      "name": "Bikaner",
      "parent_id": 1258899
    },
    {
      "country_code": "IN",
      "id": 1275960,
      "level": 3,
      "name": "Bhilwara",
      "parent_id": 1258899
    },
    {
      "country_code": "IN",
      "id": 1276128,
      "level": 3,
      "name": "Bharatpur",
      "parent_id": 1258899
    },
    {
      "country_code": "IN",
      "id": 1278946,
      "level": 3,
      "name": "Alwar",
      "parent_id": 1258899
    },
    {
      "country_code": "IN",
      "id": 1279159,
      "level": 3,
      "name": "Ajmer",
      "parent_id": 1258899
    },
    {
      "country_code": "IN",
      "id": 1259827,
      "level": 3,
      "name": "Phagwara",
      "parent_id": 1259223
    },
    {
      "country_code": "IN",
      "id": 1260107,
      "level": 3,
      "name": "Patiala",
      "parent_id": 1259223
    },
    {
      "country_code": "IN",
      "id": 1264728,
      "level": 3,
      "name": "Ludhiana",
      "parent_id": 1259223
    },
    {
      "country_code": "IN",
      "id": 1268782,
      "level": 3,
      "name": "Jalandhar",
      "parent_id": 1259223
    },
    {
      "country_code": "IN",
      "id": 1276070,
      "level": 3,
      "name": "Bathinda",
      "parent_id": 1259223
    },
    {
      "country_code": "IN",
      "id": 1278710,
      "level": 3,
      "name": "Amritsar",
      "parent_id": 1259223
    },
    {
      "country_code": "IN",
      "id": 6992326,
      "level": 3,
      "name": "Mohali",
      "parent_id": 1259223
    },
    {
      "country_code": "IN",
      "id": 1267911,
      "level": 3,
      "name": "Kapurthala",
      "parent_id": 1259223
    },
    {
      "country_code": "IN",
      "id": 11859753,
      "level": 3,
      "name": "Pondicherry",
      "parent_id": 1259424
    },
    {
      "country_code": "IN",
      "id": 1259425,
      "level": 3,
      "name": "Pondicherry",
      "parent_id": 1259424
    },
    {
      "country_code": "IN",
      "id": 1257542,
      "level": 3,
      "name": "Sambalpur",
      "parent_id": 1261029
    },
    {
      "country_code": "IN",
      "id": 1270961,
      "level": 3,
      "name": "Gopalpur",
      "parent_id": 1261029
    },
    {
      "country_code": "IN",
      "id": 1273780,
      "level": 3,
      "name": "Cuttack",
      "parent_id": 1261029
    },
    {
      "country_code": "IN",
      "id": 1275198,
      "level": 3,
      "name": "Berhampur",
      "parent_id": 1261029
    },
    {
      "country_code": "IN",
      "id": 1275817,
      "level": 3,
      "name": "Bhubaneswar",
      "parent_id": 1261029
    },
    {
      "country_code": "IN",
      "id": 11861748,
      "level": 3,
      "name": "Rourkela",
      "parent_id": 1261029
    },
    {
      "country_code": "IN",
      "id": 7644927,
      "level": 3,
      "name": "Bhubaneswar",
      "parent_id": 1261029
    },
    {
      "country_code": "IN",
      "id": 1255437,
      "level": 3,
      "name": "Rourkela",
      "parent_id": 1261029
    },
    {
      "country_code": "IN",
      "id": 1271622,
      "level": 3,
      "name": "Ganjam",
      "parent_id": 1261029
    },
    {
      "country_code": "IN",
      "id": 1272552,
      "level": 3,
      "name": "Dimapur",
      "parent_id": 1262271
    },
    {
      "country_code": "IN",
      "id": 1279186,
      "level": 3,
      "name": "Aizawl",
      "parent_id": 1262963
    },
    {
      "country_code": "IN",
      "id": 1256523,
      "level": 3,
      "name": "Shillong",
      "parent_id": 1263207
    },
    {
      "country_code": "IN",
      "id": 1266857,
      "level": 3,
      "name": "Shillong",
      "parent_id": 1263207
    },
    {
      "country_code": "IN",
      "id": 1269771,
      "level": 3,
      "name": "Imphal",
      "parent_id": 1263706
    },
    {
      "country_code": "IN",
      "id": 9747977,
      "level": 3,
      "name": "Imphal",
      "parent_id": 1263706
    },
    {
      "country_code": "IN",
      "id": 1253894,
      "level": 3,
      "name": "Ulhasnagar",
      "parent_id": 1264418
    },
    {
      "country_code": "IN",
      "id": 1254661,
      "level": 3,
      "name": "Thane",
      "parent_id": 1264418
    },
    {
      "country_code": "IN",
      "id": 1256436,
      "level": 3,
      "name": "Solapur",
      "parent_id": 1264418
    },
    {
      "country_code": "IN",
      "id": 1257055,
      "level": 3,
      "name": "Satara",
      "parent_id": 1264418
    },
    {
      "country_code": "IN",
      "id": 1259229,
      "level": 3,
      "name": "Pune",
      "parent_id": 1264418
    },
    {
      "country_code": "IN",
      "id": 1260341,
      "level": 3,
      "name": "Parbhani",
      "parent_id": 1264418
    },
    {
      "country_code": "IN",
      "id": 1260434,
      "level": 3,
      "name": "Panvel",
      "parent_id": 1264418
    },
    {
      "country_code": "IN",
      "id": 1261731,
      "level": 3,
      "name": "Nashik",
      "parent_id": 1264418
    },
    {
      "country_code": "IN",
      "id": 1261977,
      "level": 3,
      "name": "Nanded",
      "parent_id": 1264418
    },
    {
      "country_code": "IN",
      "id": 1262180,
      "level": 3,
      "name": "Nagpur",
      "parent_id": 1264418
    },
    {
      "country_code": "IN",
      "id": 1264115,
      "level": 3,
      "name": "Malegaon",
      "parent_id": 1264418
    },
    {
      "country_code": "IN",
      "id": 1265014,
      "level": 3,
      "name": "Latur",
      "parent_id": 1264418
    },
    {
      "country_code": "IN",
      "id": 1266285,
      "level": 3,
      "name": "Kolhapur",
      "parent_id": 1264418
    },
    {
      "country_code": "IN",
      "id": 1269395,
      "level": 3,
      "name": "Jalna",
      "parent_id": 1264418
    },
    {
      "country_code": "IN",
      "id": 1269407,
      "level": 3,
      "name": "Jalgaon",
      "parent_id": 1264418
    },
    {
      "country_code": "IN",
      "id": 1269834,
      "level": 3,
      "name": "Ichalkaranji",
      "parent_id": 1264418
    },
    {
      "country_code": "IN",
      "id": 1272423,
      "level": 3,
      "name": "Kalyan-Dombivali",
      "parent_id": 1264418
    },
    {
      "country_code": "IN",
      "id": 1275339,
      "level": 3,
      "name": "Mumbai",
      "parent_id": 1264418
    },
    {
      "country_code": "IN",
      "id": 1275778,
      "level": 3,
      "name": "Bhusawal",
      "parent_id": 1264418
    },
    {
      "country_code": "IN",
      "id": 1275901,
      "level": 3,
      "name": "Bhiwandi",
      "parent_id": 1264418
    },
    {
      "country_code": "IN",
      "id": 1278149,
      "level": 3,
      "name": "Aurangabad",
      "parent_id": 1264418
    },
    {
      "country_code": "IN",
      "id": 1278718,
      "level": 3,
      "name": "Amravati",
      "parent_id": 1264418
    },
    {
      "country_code": "IN",
      "id": 1278901,
      "level": 3,
      "name": "Ambernath",
      "parent_id": 1264418
    },
    {
      "country_code": "IN",
      "id": 1279105,
      "level": 3,
      "name": "Akola",
      "parent_id": 1264418
    },
    {
      "country_code": "IN",
      "id": 6619347,
      "level": 3,
      "name": "Navi Mumbai",
      "parent_id": 1264418
    },
    {
      "country_code": "IN",
      "id": 7282521,
      "level": 3,
      "name": "Chandrapur",
      "parent_id": 1264418
    },
    {
      "country_code": "IN",
      "id": 7626690,
      "level": 3,
      "name": "Pimpri-Chinchwad",
      "parent_id": 1264418
    },
    {
      "country_code": "IN",
      "id": 10431788,
      "level": 3,
      "name": "Dhule",
      "parent_id": 1264418
    },
    {
      "country_code": "IN",
      "id": 10752528,
      "level": 3,
      "name": "Ahmednagar",
      "parent_id": 1264418
    },
    {
      "country_code": "IN",
      "id": 1279228,
      "level": 3,
      "name": "Ahmadnagar",
      "parent_id": 1264418
    },
    {
      "country_code": "IN",
      "id": 7626540,
      "level": 3,
      "name": "Panvel",
      "parent_id": 1264418
    },
    {
      "country_code": "IN",
      "id": 1270836,
      "level": 3,
      "name": "Navi Mumbai",
      "parent_id": 1264418
    },
    {
      "country_code": "IN",
      "id": 1274693,
      "level": 3,
      "name": "Chandrapur",
      "parent_id": 1264418
    },
    {
      "country_code": "IN",
      "id": 1272691,
      "level": 3,
      "name": "Dhule",
      "parent_id": 1264418
    },
    {
      "country_code": "IN",
      "id": 1269411,
      "level": 3,
      "name": "Jalgaon",
      "parent_id": 1264418
    },
    {
      "country_code": "IN",
      "id": 1253914,
      "level": 3,
      "name": "Ujjain",
      "parent_id": 1264542
    },
    {
      "country_code": "IN",
      "id": 1256451,
      "level": 3,
      "name": "Shivpuri",
      "parent_id": 1264542
    },
    {
      "country_code": "IN",
      "id": 1257022,
      "level": 3,
      "name": "Satna",
      "parent_id": 1264542
    },
    {
      "country_code": "IN",
      "id": 1258182,
      "level": 3,
      "name": "Rewa",
      "parent_id": 1264542
    },
    {
      "country_code": "IN",
      "id": 1258342,
      "level": 3,
      "name": "Ratlam",
      "parent_id": 1264542
    },
    {
      "country_code": "IN",
      "id": 1262395,
      "level": 3,
      "name": "Murwara",
      "parent_id": 1264542
    },
    {
      "country_code": "IN",
      "id": 1262771,
      "level": 3,
      "name": "Morena",
      "parent_id": 1264542
    },
    {
      "country_code": "IN",
      "id": 1263309,
      "level": 3,
      "name": "Mau",
      "parent_id": 1264542
    },
    {
      "country_code": "IN",
      "id": 1267031,
      "level": 3,
      "name": "Khandwa",
      "parent_id": 1264542
    },
    {
      "country_code": "IN",
      "id": 1269633,
      "level": 3,
      "name": "Jabalpur",
      "parent_id": 1264542
    },
    {
      "country_code": "IN",
      "id": 1269743,
      "level": 3,
      "name": "Indore",
      "parent_id": 1264542
    },
    {
      "country_code": "IN",
      "id": 1270583,
      "level": 3,
      "name": "Gwalior",
      "parent_id": 1264542
    },
    {
      "country_code": "IN",
      "id": 1270711,
      "level": 3,
      "name": "Guna",
      "parent_id": 1264542
    },
    {
      "country_code": "IN",
      "id": 1273066,
      "level": 3,
      "name": "Dewas",
      "parent_id": 1264542
    },
    {
      "country_code": "IN",
      "id": 1275068,
      "level": 3,
      "name": "Burhanpur",
      "parent_id": 1264542
    },
    {
      "country_code": "IN",
      "id": 1275841,
      "level": 3,
      "name": "Bhopal",
      "parent_id": 1264542
    },
    {
      "country_code": "IN",
      "id": 1275926,
      "level": 3,
      "name": "Bhind",
      "parent_id": 1264542
    },
    {
      "country_code": "IN",
      "id": 7279754,
      "level": 3,
      "name": "Singrauli",
      "parent_id": 1264542
    },
    {
      "country_code": "IN",
      "id": 8740806,
      "level": 3,
      "name": "Murwara",
      "parent_id": 1264542
    },
    {
      "country_code": "IN",
      "id": 1263311,
      "level": 3,
      "name": "Mau",
      "parent_id": 1264542
    },
    {
      "country_code": "IN",
      "id": 1254163,
      "level": 3,
      "name": "Thiruvananthapuram",
      "parent_id": 1267254
    },
    {
      "country_code": "IN",
      "id": 1254188,
      "level": 3,
      "name": "Thrissur",
      "parent_id": 1267254
    },
    {
      "country_code": "IN",
      "id": 1259091,
      "level": 3,
      "name": "Kollam",
      "parent_id": 1267254
    },
    {
      "country_code": "IN",
      "id": 1265873,
      "level": 3,
      "name": "Kozhikode",
      "parent_id": 1267254
    },
    {
      "country_code": "IN",
      "id": 1265911,
      "level": 3,
      "name": "Kottayam",
      "parent_id": 1267254
    },
    {
      "country_code": "IN",
      "id": 1278986,
      "level": 3,
      "name": "Alappuzha",
      "parent_id": 1267254
    },
    {
      "country_code": "IN",
      "id": 11886634,
      "level": 3,
      "name": "Kochi",
      "parent_id": 1267254
    },
    {
      "country_code": "IN",
      "id": 1272018,
      "level": 3,
      "name": "Ernakulam",
      "parent_id": 1267254
    },
    {
      "country_code": "IN",
      "id": 1264154,
      "level": 3,
      "name": "Malappuram",
      "parent_id": 1267254
    },
    {
      "country_code": "IN",
      "id": 1254346,
      "level": 3,
      "name": "Tirur",
      "parent_id": 1267254
    },
    {
      "country_code": "IN",
      "id": 1260728,
      "level": 3,
      "name": "Palghat",
      "parent_id": 1267254
    },
    {
      "country_code": "IN",
      "id": 1274987,
      "level": 3,
      "name": "Kannur",
      "parent_id": 1267254
    },
    {
      "country_code": "IN",
      "id": 1254187,
      "level": 3,
      "name": "Thrissur",
      "parent_id": 1267254
    },
    {
      "country_code": "IN",
      "id": 1278985,
      "level": 3,
      "name": "Alappuzha",
      "parent_id": 1267254
    },
    {
      "country_code": "IN",
      "id": 1253952,
      "level": 3,
      "name": "Udupi",
      "parent_id": 1267701
    },
    {
      "country_code": "IN",
      "id": 1254089,
      "level": 3,
      "name": "Tumkur",
      "parent_id": 1267701
    },
    {
      "country_code": "IN",
      "id": 1256515,
      "level": 3,
      "name": "Shivamogga",
      "parent_id": 1267701
    },
    {
      "country_code": "IN",
      "id": 1257851,
      "level": 3,
      "name": "Sagar",
      "parent_id": 1267701
    },
    {
      "country_code": "IN",
      "id": 1259012,
      "level": 3,
      "name": "Raichur",
      "parent_id": 1267701
    },
    {
      "country_code": "IN",
      "id": 1262321,
      "level": 3,
      "name": "Mysore",
      "parent_id": 1267701
    },
    {
      "country_code": "IN",
      "id": 1263780,
      "level": 3,
      "name": "Mangalore",
      "parent_id": 1267701
    },
    {
      "country_code": "IN",
      "id": 1269935,
      "level": 3,
      "name": "Hospet",
      "parent_id": 1267701
    },
    {
      "country_code": "IN",
      "id": 1270752,
      "level": 3,
      "name": "Gulbarga",
      "parent_id": 1267701
    },
    {
      "country_code": "IN",
      "id": 1275701,
      "level": 3,
      "name": "Bijapur",
      "parent_id": 1267701
    },
    {
      "country_code": "IN",
      "id": 1275738,
      "level": 3,
      "name": "Bidar",
      "parent_id": 1267701
    },
    {
      "country_code": "IN",
      "id": 1276509,
      "level": 3,
      "name": "Bellary",
      "parent_id": 1267701
    },
    {
      "country_code": "IN",
      "id": 1276533,
      "level": 3,
      "name": "Belgaum",
      "parent_id": 1267701
    },
    {
      "country_code": "IN",
      "id": 1277333,
      "level": 3,
      "name": "Bangalore",
      "parent_id": 1267701
    },
    {
      "country_code": "IN",
      "id": 7646984,
      "level": 3,
      "name": "Davanagere",
      "parent_id": 1267701
    },
    {
      "country_code": "IN",
      "id": 1269920,
      "level": 3,
      "name": "Hubli",
      "parent_id": 1267701
    },
    {
      "country_code": "IN",
      "id": 1273664,
      "level": 3,
      "name": "Mangalore",
      "parent_id": 1267701
    },
    {
      "country_code": "IN",
      "id": 1272818,
      "level": 3,
      "name": "Hubli",
      "parent_id": 1267701
    },
    {
      "country_code": "IN",
      "id": 1269321,
      "level": 3,
      "name": "Jammu",
      "parent_id": 1269320
    },
    {
      "country_code": "IN",
      "id": 1255634,
      "level": 3,
      "name": "Srinagar",
      "parent_id": 1269320
    },
    {
      "country_code": "IN",
      "id": 1256237,
      "level": 3,
      "name": "Shimla",
      "parent_id": 1270101
    },
    {
      "country_code": "IN",
      "id": 1258596,
      "level": 3,
      "name": "Rampur",
      "parent_id": 1270101
    },
    {
      "country_code": "IN",
      "id": 1252797,
      "level": 3,
      "name": "Yamunanagar",
      "parent_id": 1270260
    },
    {
      "country_code": "IN",
      "id": 1255744,
      "level": 3,
      "name": "Sonipat",
      "parent_id": 1270260
    },
    {
      "country_code": "IN",
      "id": 1256052,
      "level": 3,
      "name": "Sirsa",
      "parent_id": 1270260
    },
    {
      "country_code": "IN",
      "id": 1258076,
      "level": 3,
      "name": "Rohtak",
      "parent_id": 1270260
    },
    {
      "country_code": "IN",
      "id": 1260476,
      "level": 3,
      "name": "Panipat",
      "parent_id": 1270260
    },
    {
      "country_code": "IN",
      "id": 1267708,
      "level": 3,
      "name": "Karnal",
      "parent_id": 1270260
    },
    {
      "country_code": "IN",
      "id": 1270642,
      "level": 3,
      "name": "Gurgaon",
      "parent_id": 1270260
    },
    {
      "country_code": "IN",
      "id": 1271951,
      "level": 3,
      "name": "Faridabad",
      "parent_id": 1270260
    },
    {
      "country_code": "IN",
      "id": 1275899,
      "level": 3,
      "name": "Bhiwani",
      "parent_id": 1270260
    },
    {
      "country_code": "IN",
      "id": 1278860,
      "level": 3,
      "name": "Ambala",
      "parent_id": 1270260
    },
    {
      "country_code": "IN",
      "id": 1436802,
      "level": 3,
      "name": "Fatehpur",
      "parent_id": 1270260
    },
    {
      "country_code": "IN",
      "id": 6690108,
      "level": 3,
      "name": "Panchkula",
      "parent_id": 1270260
    },
    {
      "country_code": "IN",
      "id": 1270022,
      "level": 3,
      "name": "Hisar",
      "parent_id": 1270260
    },
    {
      "country_code": "IN",
      "id": 1268593,
      "level": 3,
      "name": "Fatehpur",
      "parent_id": 1270260
    },
    {
      "country_code": "IN",
      "id": 1253573,
      "level": 3,
      "name": "Vadodara",
      "parent_id": 1270770
    },
    {
      "country_code": "IN",
      "id": 1255364,
      "level": 3,
      "name": "Surat",
      "parent_id": 1270770
    },
    {
      "country_code": "IN",
      "id": 1258847,
      "level": 3,
      "name": "Rajkot",
      "parent_id": 1270770
    },
    {
      "country_code": "IN",
      "id": 1262292,
      "level": 3,
      "name": "Nadiad",
      "parent_id": 1270770
    },
    {
      "country_code": "IN",
      "id": 1262775,
      "level": 3,
      "name": "Morbi",
      "parent_id": 1270770
    },
    {
      "country_code": "IN",
      "id": 1264389,
      "level": 3,
      "name": "Mahesana",
      "parent_id": 1270770
    },
    {
      "country_code": "IN",
      "id": 1268773,
      "level": 3,
      "name": "Junagadh",
      "parent_id": 1270770
    },
    {
      "country_code": "IN",
      "id": 1269317,
      "level": 3,
      "name": "Jamnagar",
      "parent_id": 1270770
    },
    {
      "country_code": "IN",
      "id": 1271715,
      "level": 3,
      "name": "Gandhinagar",
      "parent_id": 1270770
    },
    {
      "country_code": "IN",
      "id": 1271717,
      "level": 3,
      "name": "Gandhidham",
      "parent_id": 1270770
    },
    {
      "country_code": "IN",
      "id": 1276032,
      "level": 3,
      "name": "Bhavnagar",
      "parent_id": 1270770
    },
    {
      "country_code": "IN",
      "id": 1278685,
      "level": 3,
      "name": "Anand",
      "parent_id": 1270770
    },
    {
      "country_code": "IN",
      "id": 1279233,
      "level": 3,
      "name": "Ahmedabad",
      "parent_id": 1270770
    },
    {
      "country_code": "IN",
      "id": 1269439,
      "level": 3,
      "name": "Jalalpur",
      "parent_id": 1270770
    },
    {
      "country_code": "IN",
      "id": 1276100,
      "level": 3,
      "name": "Bharuch",
      "parent_id": 1270770
    },
    {
      "country_code": "IN",
      "id": 1255365,
      "level": 3,
      "name": "Surat",
      "parent_id": 1270770
    },
    {
      "country_code": "IN",
      "id": 1266809,
      "level": 3,
      "name": "Nadiad",
      "parent_id": 1270770
    },
    {
      "country_code": "IN",
      "id": 1268731,
      "level": 3,
      "name": "Gandhidham",
      "parent_id": 1270770
    },
    {
      "country_code": "IN",
      "id": 1260607,
      "level": 3,
      "name": "Panaji",
      "parent_id": 1271157
    },
    {
      "country_code": "IN",
      "id": 9255562,
      "level": 3,
      "name": "Panaji",
      "parent_id": 1271157
    },
    {
      "country_code": "IN",
      "id": 1261913,
      "level": 3,
      "name": "Nangloi Jat",
      "parent_id": 1273293
    },
    {
      "country_code": "IN",
      "id": 1273294,
      "level": 3,
      "name": "Delhi",
      "parent_id": 1273293
    },
    {
      "country_code": "IN",
      "id": 1274746,
      "level": 3,
      "name": "Chandigarh",
      "parent_id": 1274744
    },
    {
      "country_code": "IN",
      "id": 1255927,
      "level": 3,
      "name": "Siwan",
      "parent_id": 1275715
    },
    {
      "country_code": "IN",
      "id": 1257086,
      "level": 3,
      "name": "Sasaram",
      "parent_id": 1275715
    },
    {
      "country_code": "IN",
      "id": 1257804,
      "level": 3,
      "name": "Saharsa",
      "parent_id": 1275715
    },
    {
      "country_code": "IN",
      "id": 1259166,
      "level": 3,
      "name": "Purnia",
      "parent_id": 1275715
    },
    {
      "country_code": "IN",
      "id": 1260086,
      "level": 3,
      "name": "Patna",
      "parent_id": 1275715
    },
    {
      "country_code": "IN",
      "id": 1262330,
      "level": 3,
      "name": "Muzaffarpur",
      "parent_id": 1275715
    },
    {
      "country_code": "IN",
      "id": 1262483,
      "level": 3,
      "name": "Munger",
      "parent_id": 1275715
    },
    {
      "country_code": "IN",
      "id": 1262710,
      "level": 3,
      "name": "Motihari",
      "parent_id": 1275715
    },
    {
      "country_code": "IN",
      "id": 1266489,
      "level": 3,
      "name": "Kishanganj",
      "parent_id": 1275715
    },
    {
      "country_code": "IN",
      "id": 1267480,
      "level": 3,
      "name": "Katihar",
      "parent_id": 1275715
    },
    {
      "country_code": "IN",
      "id": 1269374,
      "level": 3,
      "name": "Jamalpur",
      "parent_id": 1275715
    },
    {
      "country_code": "IN",
      "id": 1270525,
      "level": 3,
      "name": "Hajipur",
      "parent_id": 1275715
    },
    {
      "country_code": "IN",
      "id": 1271439,
      "level": 3,
      "name": "Gaya",
      "parent_id": 1275715
    },
    {
      "country_code": "IN",
      "id": 1273309,
      "level": 3,
      "name": "Dehri",
      "parent_id": 1275715
    },
    {
      "country_code": "IN",
      "id": 1273491,
      "level": 3,
      "name": "Darbhanga",
      "parent_id": 1275715
    },
    {
      "country_code": "IN",
      "id": 1275019,
      "level": 3,
      "name": "Buxar",
      "parent_id": 1275715
    },
    {
      "country_code": "IN",
      "id": 1275716,
      "level": 3,
      "name": "Bihar Sharif",
      "parent_id": 1275715
    },
    {
      "country_code": "IN",
      "id": 1276300,
      "level": 3,
      "name": "Bhagalpur",
      "parent_id": 1275715
    },
    {
      "country_code": "IN",
      "id": 1276393,
      "level": 3,
      "name": "Bettiah",
      "parent_id": 1275715
    },
    {
      "country_code": "IN",
      "id": 1276609,
      "level": 3,
      "name": "Begusarai",
      "parent_id": 1275715
    },
    {
      "country_code": "IN",
      "id": 1277939,
      "level": 3,
      "name": "Bagaha",
      "parent_id": 1275715
    },
    {
      "country_code": "IN",
      "id": 1278483,
      "level": 3,
      "name": "Arrah",
      "parent_id": 1275715
    },
    {
      "country_code": "IN",
      "id": 7627222,
      "level": 3,
      "name": "Jehanabad",
      "parent_id": 1275715
    },
    {
      "country_code": "IN",
      "id": 10540249,
      "level": 3,
      "name": "Danapur",
      "parent_id": 1275715
    },
    {
      "country_code": "IN",
      "id": 1257551,
      "level": 3,
      "name": "Samastipur",
      "parent_id": 1275715
    },
    {
      "country_code": "IN",
      "id": 1253533,
      "level": 3,
      "name": "Hajipur",
      "parent_id": 1275715
    },
    {
      "country_code": "IN",
      "id": 1262081,
      "level": 3,
      "name": "Bihar Sharif",
      "parent_id": 1275715
    },
    {
      "country_code": "IN",
      "id": 1257247,
      "level": 3,
      "name": "Saran",
      "parent_id": 1275715
    },
    {
      "country_code": "IN",
      "id": 1262482,
      "level": 3,
      "name": "Munger",
      "parent_id": 1275715
    },
    {
      "country_code": "IN",
      "id": 1260208,
      "level": 3,
      "name": "Pashchim Champaran",
      "parent_id": 1275715
    },
    {
      "country_code": "IN",
      "id": 1258074,
      "level": 3,
      "name": "Rohtas",
      "parent_id": 1275715
    },
    {
      "country_code": "IN",
      "id": 1271476,
      "level": 3,
      "name": "Guwahati",
      "parent_id": 1278253
    },
    {
      "country_code": "IN",
      "id": 1268820,
      "level": 3,
      "name": "Jorhat",
      "parent_id": 1278253
    },
    {
      "country_code": "IN",
      "id": 1272648,
      "level": 3,
      "name": "Dibrugarh",
      "parent_id": 1278253
    },
    {
      "country_code": "IN",
      "id": 1256287,
      "level": 3,
      "name": "Silchar",
      "parent_id": 1278253
    },
    {
      "country_code": "IN",
      "id": 1275009,
      "level": 3,
      "name": "Silchar",
      "parent_id": 1278253
    },
    {
      "country_code": "IN",
      "id": 1268209,
      "level": 3,
      "name": "Guwahati",
      "parent_id": 1278253
    },
    {
      "country_code": "IN",
      "id": 1253102,
      "level": 3,
      "name": "Visakhapatnam",
      "parent_id": 1278629
    },
    {
      "country_code": "IN",
      "id": 1253184,
      "level": 3,
      "name": "Vijayawada",
      "parent_id": 1278629
    },
    {
      "country_code": "IN",
      "id": 1254360,
      "level": 3,
      "name": "Tirupati",
      "parent_id": 1278629
    },
    {
      "country_code": "IN",
      "id": 1254757,
      "level": 3,
      "name": "Tenali",
      "parent_id": 1278629
    },
    {
      "country_code": "IN",
      "id": 1255253,
      "level": 3,
      "name": "Tadipatri",
      "parent_id": 1278629
    },
    {
      "country_code": "IN",
      "id": 1255648,
      "level": 3,
      "name": "Srikakulam",
      "parent_id": 1278629
    },
    {
      "country_code": "IN",
      "id": 1258932,
      "level": 3,
      "name": "Rajahmundry",
      "parent_id": 1278629
    },
    {
      "country_code": "IN",
      "id": 1259312,
      "level": 3,
      "name": "Proddatur",
      "parent_id": 1278629
    },
    {
      "country_code": "IN",
      "id": 1261045,
      "level": 3,
      "name": "Ongole",
      "parent_id": 1278629
    },
    {
      "country_code": "IN",
      "id": 1261529,
      "level": 3,
      "name": "Nellore",
      "parent_id": 1278629
    },
    {
      "country_code": "IN",
      "id": 1261848,
      "level": 3,
      "name": "Narasaraopet",
      "parent_id": 1278629
    },
    {
      "country_code": "IN",
      "id": 1261927,
      "level": 3,
      "name": "Nandyal",
      "parent_id": 1278629
    },
    {
      "country_code": "IN",
      "id": 1264621,
      "level": 3,
      "name": "Madanapalle",
      "parent_id": 1278629
    },
    {
      "country_code": "IN",
      "id": 1264637,
      "level": 3,
      "name": "Machilipatnam",
      "parent_id": 1278629
    },
    {
      "country_code": "IN",
      "id": 1265491,
      "level": 3,
      "name": "Kurnool",
      "parent_id": 1278629
    },
    {
      "country_code": "IN",
      "id": 1267394,
      "level": 3,
      "name": "Kavali",
      "parent_id": 1278629
    },
    {
      "country_code": "IN",
      "id": 1268561,
      "level": 3,
      "name": "Kakinada",
      "parent_id": 1278629
    },
    {
      "country_code": "IN",
      "id": 1270079,
      "level": 3,
      "name": "Hindupur",
      "parent_id": 1278629
    },
    {
      "country_code": "IN",
      "id": 1270668,
      "level": 3,
      "name": "Guntur",
      "parent_id": 1278629
    },
    {
      "country_code": "IN",
      "id": 1270801,
      "level": 3,
      "name": "Gudivada",
      "parent_id": 1278629
    },
    {
      "country_code": "IN",
      "id": 1272842,
      "level": 3,
      "name": "Dharmavaram",
      "parent_id": 1278629
    },
    {
      "country_code": "IN",
      "id": 1273800,
      "level": 3,
      "name": "Kadapa",
      "parent_id": 1278629
    },
    {
      "country_code": "IN",
      "id": 1274033,
      "level": 3,
      "name": "Chittoor",
      "parent_id": 1278629
    },
    {
      "country_code": "IN",
      "id": 1275947,
      "level": 3,
      "name": "Bhimavaram",
      "parent_id": 1278629
    },
    {
      "country_code": "IN",
      "id": 1278672,
      "level": 3,
      "name": "Anantapur",
      "parent_id": 1278629
    },
    {
      "country_code": "IN",
      "id": 1279335,
      "level": 3,
      "name": "Adoni",
      "parent_id": 1278629
    },
    {
      "country_code": "IN",
      "id": 10522100,
      "level": 3,
      "name": "Amaravati",
      "parent_id": 1278629
    },
    {
      "country_code": "IN",
      "id": 10767758,
      "level": 3,
      "name": "Eluru",
      "parent_id": 1278629
    },
    {
      "country_code": "IN",
      "id": 10776151,
      "level": 3,
      "name": "Vijayanagaram",
      "parent_id": 1278629
    },
    {
      "country_code": "IN",
      "id": 11861910,
      "level": 3,
      "name": "Tadepalligudem",
      "parent_id": 1278629
    },
    {
      "country_code": "IN",
      "id": 1255649,
      "level": 3,
      "name": "Srikakulam",
      "parent_id": 1278629
    },
    {
      "country_code": "IN",
      "id": 1259346,
      "level": 3,
      "name": "Ongole",
      "parent_id": 1278629
    },
    {
      "country_code": "IN",
      "id": 1253084,
      "level": 3,
      "name": "Vijayanagaram",
      "parent_id": 1278629
    },
    {
      "country_code": "IN",
      "id": 1273801,
      "level": 3,
      "name": "Kadapa",
      "parent_id": 1278629
    },
    {
      "country_code": "IN",
      "id": 1272123,
      "level": 3,
      "name": "East Godavari",
      "parent_id": 1278629
    },
    {
      "country_code": "IN",
      "id": 1265869,
      "level": 3,
      "name": "Krishna",
      "parent_id": 1278629
    },
    {
      "country_code": "IN",
      "id": 1252873,
      "level": 3,
      "name": "West Godavari",
      "parent_id": 1278629
    },
    {
      "country_code": "IN",
      "id": 1258980,
      "level": 3,
      "name": "Raipur",
      "parent_id": 1444364
    },
    {
      "country_code": "IN",
      "id": 1266122,
      "level": 3,
      "name": "Korba",
      "parent_id": 1444364
    },
    {
      "country_code": "IN",
      "id": 1272181,
      "level": 3,
      "name": "Durg",
      "parent_id": 1444364
    },
    {
      "country_code": "IN",
      "id": 1275971,
      "level": 3,
      "name": "Bhilai",
      "parent_id": 1444364
    },
    {
      "country_code": "IN",
      "id": 1258526,
      "level": 3,
      "name": "Ranchi",
      "parent_id": 1444365
    },
    {
      "country_code": "IN",
      "id": 1269300,
      "level": 3,
      "name": "Jamshedpur",
      "parent_id": 1444365
    },
    {
      "country_code": "IN",
      "id": 1272979,
      "level": 3,
      "name": "Dhanbad",
      "parent_id": 1444365
    },
    {
      "country_code": "IN",
      "id": 1273241,
      "level": 3,
      "name": "Deoghar",
      "parent_id": 1444365
    },
    {
      "country_code": "IN",
      "id": 1275362,
      "level": 3,
      "name": "Bokaro",
      "parent_id": 1444365
    },
    {
      "country_code": "IN",
      "id": 1278313,
      "level": 3,
      "name": "Asansol",
      "parent_id": 1444365
    },
    {
      "country_code": "IN",
      "id": 6301075,
      "level": 3,
      "name": "Mango",
      "parent_id": 1444365
    },
    {
      "country_code": "IN",
      "id": 7701426,
      "level": 3,
      "name": "East Singhbum",
      "parent_id": 1444365
    },
    {
      "country_code": "IN",
      "id": 1255635,
      "level": 3,
      "name": "Srinagar",
      "parent_id": 1444366
    },
    {
      "country_code": "IN",
      "id": 1270351,
      "level": 3,
      "name": "Haridwar",
      "parent_id": 1444366
    },
    {
      "country_code": "IN",
      "id": 6954188,
      "level": 3,
      "name": "Dehradun",
      "parent_id": 1444366
    },
    {
      "country_code": "IN",
      "id": 1258044,
      "level": 3,
      "name": "Roorkee",
      "parent_id": 1444366
    }
  ],
  "countries": [
    {
      "country_code": "US",
      "id": 6252001,
      "level": 1,
      "name": "United States"
    },
    {
      "country_code": "BR",
      "id": 3469034,
      "level": 1,
      "name": "Brazil"
    },
    {
      "country_code": "JP",
      "id": 1861060,
      "level": 1,
      "name": "Japan"
    },
    {
      "country_code": "IN",
      "id": 1269750,
      "level": 1,
      "name": "India"
    },
    {
      "country_code": "GB",
      "id": 2635167,
      "level": 1,
      "name": "United Kingdom"
    },
    {
      "country_code": "DE",
      "id": 2921044,
      "level": 1,
      "name": "Germany"
    },
    {
      "country_code": "FR",
      "id": 3017382,
      "level": 1,
      "name": "France"
    },
    {
      "country_code": "IT",
      "id": 3175395,
      "level": 1,
      "name": "Italy"
    },
    {
      "country_code": "ES",
      "id": 2510769,
      "level": 1,
      "name": "Spain"
    },
    {
      "country_code": "RU",
      "id": 2017370,
      "level": 1,
      "name": "Russia"
    },
    {
      "country_code": "FI",
      "id": 660013,
      "level": 1,
      "name": "Finland"
    },
    {
      "country_code": "SE",
      "id": 2661886,
      "level": 1,
      "name": "Sweden"
    },
    {
      "country_code": "MX",
      "id": 3996063,
      "level": 1,
      "name": "Mexico"
    },
    {
      "country_code": "PL",
      "id": 798544,
      "level": 1,
      "name": "Poland"
    },
    {
      "country_code": "AR",
      "id": 3865483,
      "level": 1,
      "name": "Argentina"
    },
    {
      "country_code": "CA",
      "id": 6251999,
      "level": 1,
      "name": "Canada"
    },
    {
      "country_code": "EG",
      "id": 357994,
      "level": 1,
      "name": "Egypt"
    },
    {
      "country_code": "NL",
      "id": 2750405,
      "level": 1,
      "name": "Netherlands"
    },
    {
      "country_code": "TR",
      "id": 298795,
      "level": 1,
      "name": "Turkey"
    },
    {
      "country_code": "AU",
      "id": 2077456,
      "level": 1,
      "name": "Australia"
    },
    {
      "country_code": "CO",
      "id": 3686110,
      "level": 1,
      "name": "Colombia"
    },
    {
      "country_code": "SA",
      "id": 102358,
      "level": 1,
      "name": "Saudi Arabia"
    },
    {
      "country_code": "AE",
      "id": 290557,
      "level": 1,
      "name": "United Arab Emirates"
    },
    {
      "country_code": "ZA",
      "id": 953987,
      "level": 1,
      "name": "South Africa"
    },
    {
      "country_code": "DZ",
      "id": 2589581,
      "level": 1,
      "name": "Algeria"
    },
    {
      "country_code": "MA",
      "id": 2542007,
      "level": 1,
      "name": "Morocco"
    },
    {
      "country_code": "KW",
      "id": 285570,
      "level": 1,
      "name": "Kuwait"
    },
    {
      "country_code": "TH",
      "id": 1605651,
      "level": 1,
      "name": "Thailand"
    },
    {
      "country_code": "VN",
      "id": 1562822,
      "level": 1,
      "name": "Vietnam"
    },
    {
      "country_code": "KR",
      "id": 1835841,
      "level": 1,
      "name": "Korea"
    },
    {
      "country_code": "ID",
      "id": 1643084,
      "level": 1,
      "name": "Indonesia"
    },
    {
      "country_code": "TW",
      "id": 1668284,
      "level": 1,
      "name": "Taiwan"
    },
    {
      "country_code": "PH",
      "id": 1694008,
      "level": 1,
      "name": "Philippines"
    },
    {
      "country_code": "MY",
      "id": 1733045,
      "level": 1,
      "name": "Malaysia"
    },
    {
      "country_code": "HK",
      "id": 1819730,
      "level": 1,
      "name": "Hong Kong"
    },
    {
      "country_code": "MO",
      "id": 1821275,
      "level": 1,
      "name": "Macao"
    },
    {
      "country_code": "KH",
      "id": 1831722,
      "level": 1,
      "name": "Cambodia"
    },
    {
      "country_code": "SG",
      "id": 1880251,
      "level": 1,
      "name": "Singapore"
    },
    {
      "country_code": "PK",
      "id": 1168579,
      "level": 1,
      "name": "Pakistan"
    },
    {
      "country_code": "SY",
      "id": 163843,
      "level": 1,
      "name": "Syria"
    },
    {
      "country_code": "BM",
      "id": 3573345,
      "level": 1,
      "name": "Bermuda"
    },
    {
      "country_code": "KY",
      "id": 3580718,
      "level": 1,
      "name": "Cayman Islands"
    },
    {
      "country_code": "PM",
      "id": 3424932,
      "level": 1,
      "name": "Saint Pierre and Miquelon"
    },
    {
      "country_code": "GL",
      "id": 3425505,
      "level": 1,
      "name": "Greenland"
    },
    {
      "country_code": "TT",
      "id": 3573591,
      "level": 1,
      "name": "Trinidad and Tobago"
    },
    {
      "country_code": "LC",
      "id": 3576468,
      "level": 1,
      "name": "Saint Lucia"
    },
    {
      "country_code": "AG",
      "id": 3576396,
      "level": 1,
      "name": "Antigua & Barbuda"
    },
    {
      "country_code": "DM",
      "id": 3575830,
      "level": 1,
      "name": "Dominica"
    },
    {
      "country_code": "VI",
      "id": 4796775,
      "level": 1,
      "name": "United States Virgin Islands"
    },
    {
      "country_code": "BL",
      "id": 3578476,
      "level": 1,
      "name": "Saint Barthélemy"
    },
    {
      "country_code": "KN",
      "id": 3575174,
      "level": 1,
      "name": "St. Kitts & Nevis"
    },
    {
      "country_code": "MS",
      "id": 3578097,
      "level": 1,
      "name": "Montserrat"
    },
    {
      "country_code": "BZ",
      "id": 3582678,
      "level": 1,
      "name": "Belize"
    },
    {
      "country_code": "GP",
      "id": 3579143,
      "level": 1,
      "name": "Guadeloupe"
    },
    {
      "country_code": "HT",
      "id": 3723988,
      "level": 1,
      "name": "Haiti"
    },
    {
      "country_code": "VC",
      "id": 3577815,
      "level": 1,
      "name": "St. Vincent & the Grenadines"
    },
    {
      "country_code": "BQ",
      "id": 7626844,
      "level": 1,
      "name": "Caribbean Netherlands"
    },
    {
      "country_code": "BS",
      "id": 3572887,
      "level": 1,
      "name": "The Bahamas"
    },
    {
      "country_code": "AI",
      "id": 3573511,
      "level": 1,
      "name": "Anguilla"
    },
    {
      "country_code": "JM",
      "id": 3489940,
      "level": 1,
      "name": "Jamaica"
    },
    {
      "country_code": "MF",
      "id": 3578421,
      "level": 1,
      "name": "Saint Martin (France)"
    },
    {
      "country_code": "AW",
      "id": 3577279,
      "level": 1,
      "name": "Aruba"
    },
    {
      "country_code": "SX",
      "id": 7609695,
      "level": 1,
      "name": "Sint Maarten (Dutch part)"
    },
    {
      "country_code": "TC",
      "id": 3576916,
      "level": 1,
      "name": "Turks and Caicos Islands"
    },
    {
      "country_code": "GD",
      "id": 3580239,
      "level": 1,
      "name": "Grenada"
    },
    {
      "country_code": "BB",
      "id": 3374084,
      "level": 1,
      "name": "Barbados"
    },
    {
      "country_code": "MQ",
      "id": 3570311,
      "level": 1,
      "name": "Martinique"
    },
    {
      "country_code": "VG",
      "id": 3577718,
      "level": 1,
      "name": "Virgin Islands (British)"
    },
    {
      "country_code": "CW",
      "id": 7626836,
      "level": 1,
      "name": "Curaçao"
    },
    {
      "country_code": "CH",
      "id": 2658434,
      "level": 1,
      "name": "Switzerland"
    },
    {
      "country_code": "LU",
      "id": 2960313,
      "level": 1,
      "name": "Luxembourg"
    },
    {
      "country_code": "LI",
      "id": 3042058,
      "level": 1,
      "name": "Liechtenstein"
    },
    {
      "country_code": "AT",
      "id": 2782113,
      "level": 1,
      "name": "Austria"
    },
    {
      "country_code": "CZ",
      "id": 3077311,
      "level": 1,
      "name": "Czechia"
    },
    {
      "country_code": "SK",
      "id": 3057568,
      "level": 1,
      "name": "Slovakia"
    },
    {
      "country_code": "SI",
      "id": 3190538,
      "level": 1,
      "name": "Slovenia"
    },
    {
      "country_code": "HR",
      "id": 3202326,
      "level": 1,
      "name": "Croatia"
    },
    {
      "country_code": "HU",
      "id": 719819,
      "level": 1,
      "name": "Hungary"
    },
    {
      "country_code": "BG",
      "id": 732800,
      "level": 1,
      "name": "Bulgaria"
    },
    {
      "country_code": "EC",
      "id": 3658394,
      "level": 1,
      "name": "Ecuador"
    },
    {
      "country_code": "CL",
      "id": 3895114,
      "level": 1,
      "name": "Chile"
    },
    {
      "country_code": "VE",
      "id": 3625428,
      "level": 1,
      "name": "Venezuela (Bolivarian Republic of)"
    },
    {
      "country_code": "PR",
      "id": 4566966,
      "level": 1,
      "name": "Puerto Rico"
    },
    {
      "country_code": "GN",
      "id": 2420477,
      "level": 1,
      "name": "Guinea"
    },
    {
      "country_code": "HN",
      "id": 3608932,
      "level": 1,
      "name": "Honduras"
    },
    {
      "country_code": "GT",
      "id": 3595528,
      "level": 1,
      "name": "Guatemala"
    },
    {
      "country_code": "BO",
      "id": 3923057,
      "level": 1,
      "name": "Bolivia (Plurinational State of)"
    },
    {
      "country_code": "NI",
      "id": 3617476,
      "level": 1,
      "name": "Nicaragua"
    },
    {
      "country_code": "SV",
      "id": 3585968,
      "level": 1,
      "name": "El Salvador"
    },
    {
      "country_code": "CU",
      "id": 3562981,
      "level": 1,
      "name": "Cuba"
    },
    {
      "country_code": "PY",
      "id": 3437598,
      "level": 1,
      "name": "Paraguay"
    },
    {
      "country_code": "PE",
      "id": 3932488,
      "level": 1,
      "name": "Peru"
    },
    {
      "country_code": "CR",
      "id": 3624060,
      "level": 1,
      "name": "Costa Rica"
    },
    {
      "country_code": "DO",
      "id": 3508796,
      "level": 1,
      "name": "Dominican Republic"
    },
    {
      "country_code": "PA",
      "id": 3703430,
      "level": 1,
      "name": "Panama"
    },
    {
      "country_code": "UY",
      "id": 3439705,
      "level": 1,
      "name": "Uruguay"
    },
    {
      "country_code": "GE",
      "id": 614540,
      "level": 1,
      "name": "Georgia"
    },
    {
      "country_code": "IL",
      "id": 294640,
      "level": 1,
      "name": "Israel"
    },
    {
      "country_code": "SM",
      "id": 3168068,
      "level": 1,
      "name": "San Marino"
    },
    {
      "country_code": "MK",
      "id": 718075,
      "level": 1,
      "name": "Macedonia (the former Yugoslav Republic of)"
    },
    {
      "country_code": "JE",
      "id": 3042142,
      "level": 1,
      "name": "Jersey"
    },
    {
      "country_code": "IM",
      "id": 3042225,
      "level": 1,
      "name": "Isle of Man"
    },
    {
      "country_code": "ME",
      "id": 3194884,
      "level": 1,
      "name": "Montenegro"
    },
    {
      "country_code": "GG",
      "id": 3042362,
      "level": 1,
      "name": "Guernsey"
    },
    {
      "country_code": "CD",
      "id": 203312,
      "level": 1,
      "name": "Congo (Democratic Republic of the)"
    },
    {
      "country_code": "MT",
      "id": 2562770,
      "level": 1,
      "name": "Malta"
    },
    {
      "country_code": "AL",
      "id": 783754,
      "level": 1,
      "name": "Albania"
    },
    {
      "country_code": "LV",
      "id": 458258,
      "level": 1,
      "name": "Latvia"
    },
    {
      "country_code": "BA",
      "id": 3277605,
      "level": 1,
      "name": "Bosnia and Herzegovina"
    },
    {
      "country_code": "AD",
      "id": 3041565,
      "level": 1,
      "name": "Andorra"
    },
    {
      "country_code": "RS",
      "id": 6290252,
      "level": 1,
      "name": "Serbia"
    },
    {
      "country_code": "AZ",
      "id": 587116,
      "level": 1,
      "name": "Azerbaijan"
    },
    {
      "country_code": "FO",
      "id": 2622320,
      "level": 1,
      "name": "Faroe Islands"
    },
    {
      "country_code": "GI",
      "id": 2411586,
      "level": 1,
      "name": "Gibraltar"
    },
    {
      "country_code": "EE",
      "id": 453733,
      "level": 1,
      "name": "Estonia"
    },
    {
      "country_code": "LT",
      "id": 597427,
      "level": 1,
      "name": "Lithuania"
    },
    {
      "country_code": "SJ",
      "id": 607072,
      "level": 1,
      "name": "Svalbard and Jan Mayen"
    },
    {
      "country_code": "GR",
      "id": 390903,
      "level": 1,
      "name": "Greece"
    },
    {
      "country_code": "IE",
      "id": 2963597,
      "level": 1,
      "name": "Ireland"
    },
    {
      "country_code": "GW",
      "id": 2372248,
      "level": 1,
      "name": "Guinea-Bissau"
    },
    {
      "country_code": "MZ",
      "id": 1036973,
      "level": 1,
      "name": "Mozambique"
    },
    {
      "country_code": "GQ",
      "id": 2309096,
      "level": 1,
      "name": "Equatorial Guinea"
    },
    {
      "country_code": "AO",
      "id": 3351879,
      "level": 1,
      "name": "Angola"
    },
    {
      "country_code": "CV",
      "id": 3374766,
      "level": 1,
      "name": "Cabo Verde"
    },
    {
      "country_code": "PT",
      "id": 2264397,
      "level": 1,
      "name": "Portugal"
    },
    {
      "country_code": "ST",
      "id": 2410758,
      "level": 1,
      "name": "Sao Tome and Principe"
    },
    {
      "country_code": "LK",
      "id": 1227603,
      "level": 1,
      "name": "Sri Lanka"
    },
    {
      "country_code": "BD",
      "id": 1210997,
      "level": 1,
      "name": "Bangladesh"
    },
    {
      "country_code": "BE",
      "id": 2802361,
      "level": 1,
      "name": "Belgium"
    },
    {
      "country_code": "NO",
      "id": 3144096,
      "level": 1,
      "name": "Norway"
    },
    {
      "country_code": "DK",
      "id": 2623032,
      "level": 1,
      "name": "Denmark"
    },
    {
      "country_code": "IS",
      "id": 2629691,
      "level": 1,
      "name": "Iceland"
    },
    {
      "country_code": "PF",
      "id": 4030656,
      "level": 1,
      "name": "French Polynesia"
    },
    {
      "country_code": "GF",
      "id": 3381670,
      "level": 1,
      "name": "French Guiana"
    },
    {
      "country_code": "MC",
      "id": 2993457,
      "level": 1,
      "name": "Monaco"
    },
    {
      "country_code": "TF",
      "id": 1546748,
      "level": 1,
      "name": "French Southern Territories"
    },
    {
      "country_code": "UZ",
      "id": 1512440,
      "level": 1,
      "name": "Uzbekistan"
    },
    {
      "country_code": "KZ",
      "id": 1522867,
      "level": 1,
      "name": "Kazakhstan"
    },
    {
      "country_code": "MD",
      "id": 617790,
      "level": 1,
      "name": "Moldova (Republic of)"
    },
    {
      "country_code": "BY",
      "id": 630336,
      "level": 1,
      "name": "Belarus"
    },
    {
      "country_code": "UA",
      "id": 690791,
      "level": 1,
      "name": "Ukraine"
    },
    {
      "country_code": "TN",
      "id": 2464461,
      "level": 1,
      "name": "Tunisia"
    },
    {
      "country_code": "LB",
      "id": 272103,
      "level": 1,
      "name": "Lebanon"
    },
    {
      "country_code": "DJ",
      "id": 223816,
      "level": 1,
      "name": "Djibouti"
    },
    {
      "country_code": "OM",
      "id": 286963,
      "level": 1,
      "name": "Oman"
    },
    {
      "country_code": "IQ",
      "id": 99237,
      "level": 1,
      "name": "Iraq"
    },
    {
      "country_code": "MR",
      "id": 2378080,
      "level": 1,
      "name": "Mauritania"
    },
    {
      "country_code": "LY",
      "id": 2215636,
      "level": 1,
      "name": "Libya"
    },
    {
      "country_code": "TD",
      "id": 2434508,
      "level": 1,
      "name": "Chad"
    },
    {
      "country_code": "YE",
      "id": 69543,
      "level": 1,
      "name": "Yemen"
    },
    {
      "country_code": "QA",
      "id": 289688,
      "level": 1,
      "name": "Qatar"
    },
    {
      "country_code": "KM",
      "id": 921929,
      "level": 1,
      "name": "Comoros"
    },
    {
      "country_code": "ER",
      "id": 338010,
      "level": 1,
      "name": "Eritrea"
    },
    {
      "country_code": "JO",
      "id": 248816,
      "level": 1,
      "name": "Jordan"
    },
    {
      "country_code": "BH",
      "id": 290291,
      "level": 1,
      "name": "Bahrain"
    },
    {
      "country_code": "SD",
      "id": 366755,
      "level": 1,
      "name": "Sudan"
    },
    {
      "country_code": "SO",
      "id": 51537,
      "level": 1,
      "name": "Somalia"
    },
    {
      "country_code": "SS",
      "id": 7909807,
      "level": 1,
      "name": "South Sudan"
    },
    {
      "country_code": "PS",
      "id": 6254930,
      "level": 1,
      "name": "Palestine, State of"
    },
    {
      "country_code": "RO",
      "id": 798549,
      "level": 1,
      "name": "Romania"
    },
    {
      "country_code": "NG",
      "id": 2328926,
      "level": 1,
      "name": "Nigeria"
    },
    {
      "country_code": "KE",
      "id": 192950,
      "level": 1,
      "name": "Kenya"
    },
    {
      "country_code": "NZ",
      "id": 2186224,
      "level": 1,
      "name": "New Zealand"
    },
    {
      "country_code": "MM",
      "id": 1327865,
      "level": 1,
      "name": "Myanmar"
    }
  ],
  "provinces": [
    {
      "country_code": "SA",
      "id": 103628,
      "level": 2,
      "name": "Najran",
      "parent_id": 102358
    },
    {
      "country_code": "SA",
      "id": 109579,
      "level": 2,
      "name": "Northern Borders Province",
      "parent_id": 102358
    },
    {
      "country_code": "SA",
      "id": 109470,
      "level": 2,
      "name": "Al Jowf",
      "parent_id": 102358
    },
    {
      "country_code": "SA",
      "id": 109954,
      "level": 2,
      "name": "Al Bahah",
      "parent_id": 102358
    },
    {
      "country_code": "SA",
      "id": 106280,
      "level": 2,
      "name": "Hai'l Region",
      "parent_id": 102358
    },
    {
      "country_code": "SA",
      "id": 108933,
      "level": 2,
      "name": "Al Quassim",
      "parent_id": 102358
    },
    {
      "country_code": "SA",
      "id": 101627,
      "level": 2,
      "name": "Tabuk",
      "parent_id": 102358
    },
    {
      "country_code": "SA",
      "id": 108179,
      "level": 2,
      "name": "`Aseer Province",
      "parent_id": 102358
    },
    {
      "country_code": "SA",
      "id": 105298,
      "level": 2,
      "name": "Jazan",
      "parent_id": 102358
    },
    {
      "country_code": "SA",
      "id": 109224,
      "level": 2,
      "name": "Medina",
      "parent_id": 102358
    },
    {
      "country_code": "SA",
      "id": 108241,
      "level": 2,
      "name": "Eastern Province",
      "parent_id": 102358
    },
    {
      "country_code": "SA",
      "id": 104514,
      "level": 2,
      "name": "Mecca",
      "parent_id": 102358
    },
    {
      "country_code": "SA",
      "id": 108411,
      "level": 2,
      "name": "Ar Riyad",
      "parent_id": 102358
    },
    {
      "country_code": "AE",
      "id": 292224,
      "level": 2,
      "name": "Dubai",
      "parent_id": 290557
    },
    {
      "country_code": "AE",
      "id": 292673,
      "level": 2,
      "name": "Sharjah",
      "parent_id": 290557
    },
    {
      "country_code": "AE",
      "id": 291075,
      "level": 2,
      "name": "Ras Al Khaimah",
      "parent_id": 290557
    },
    {
      "country_code": "AE",
      "id": 292933,
      "level": 2,
      "name": "Ajman",
      "parent_id": 290557
    },
    {
      "country_code": "AE",
      "id": 292969,
      "level": 2,
      "name": "Emirate of Abu Dhabi",
      "parent_id": 290557
    },
    {
      "country_code": "AE",
      "id": 292879,
      "level": 2,
      "name": "Fujairah",
      "parent_id": 290557
    },
    {
      "country_code": "AE",
      "id": 290595,
      "level": 2,
      "name": "Umm Al Quwain",
      "parent_id": 290557
    },
    {
      "country_code": "IL",
      "id": 294904,
      "level": 2,
      "name": "Central District",
      "parent_id": 294640
    },
    {
      "country_code": "IL",
      "id": 294800,
      "level": 2,
      "name": "Haifa",
      "parent_id": 294640
    },
    {
      "country_code": "IL",
      "id": 293198,
      "level": 2,
      "name": "Jerusalem",
      "parent_id": 294640
    },
    {
      "country_code": "IL",
      "id": 294824,
      "level": 2,
      "name": "Northern District",
      "parent_id": 294640
    },
    {
      "country_code": "IL",
      "id": 294952,
      "level": 2,
      "name": "Southern District",
      "parent_id": 294640
    },
    {
      "country_code": "IL",
      "id": 293396,
      "level": 2,
      "name": "Tel Aviv",
      "parent_id": 294640
    },
    {
      "country_code": "TR",
      "id": 862470,
      "level": 2,
      "name": "Ardahan",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 862471,
      "level": 2,
      "name": "Bayburt",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 320390,
      "level": 2,
      "name": "Burdur",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 746423,
      "level": 2,
      "name": "Gumushane",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 304041,
      "level": 2,
      "name": "Mus",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 443184,
      "level": 2,
      "name": "Igdir",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 312888,
      "level": 2,
      "name": "Hakkari",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 321079,
      "level": 2,
      "name": "Bingol",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 300821,
      "level": 2,
      "name": "Siirt",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 743942,
      "level": 2,
      "name": "Kars",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 862467,
      "level": 2,
      "name": "Bartin",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 321122,
      "level": 2,
      "name": "Bilecik",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 743165,
      "level": 2,
      "name": "Kirklareli",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 307513,
      "level": 2,
      "name": "kirsehir",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 315372,
      "level": 2,
      "name": "Erzincan",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 443187,
      "level": 2,
      "name": "Karaman",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 325163,
      "level": 2,
      "name": "Agri",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 298845,
      "level": 2,
      "name": "Tunceli",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 739598,
      "level": 2,
      "name": "Sinop",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 321022,
      "level": 2,
      "name": "Bitlis",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 747711,
      "level": 2,
      "name": "Edirne",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 865521,
      "level": 2,
      "name": "Duzce",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 862469,
      "level": 2,
      "name": "Yalova",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 862468,
      "level": 2,
      "name": "Karabuk",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 751816,
      "level": 2,
      "name": "Artvin",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 443188,
      "level": 2,
      "name": "Kirikkale",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 743881,
      "level": 2,
      "name": "Kastamonu",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 752014,
      "level": 2,
      "name": "Amasya",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 298298,
      "level": 2,
      "name": "Usak",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 325329,
      "level": 2,
      "name": "Adiyaman",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 296560,
      "level": 2,
      "name": "Yozgat",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 303830,
      "level": 2,
      "name": "Nevsehir",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 443213,
      "level": 2,
      "name": "Kilis",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 303826,
      "level": 2,
      "name": "Nigde",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 740481,
      "level": 2,
      "name": "Rize",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 750510,
      "level": 2,
      "name": "Bolu",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 311071,
      "level": 2,
      "name": "Isparta",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 305267,
      "level": 2,
      "name": "Kutahya",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 749747,
      "level": 2,
      "name": "Cankiri",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 315807,
      "level": 2,
      "name": "Elazig",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 741098,
      "level": 2,
      "name": "Ordu",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 746878,
      "level": 2,
      "name": "Giresun",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 443183,
      "level": 2,
      "name": "Osmaniye",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 738742,
      "level": 2,
      "name": "Slap",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 300617,
      "level": 2,
      "name": "Sivas",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 443185,
      "level": 2,
      "name": "Aksaray",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 443189,
      "level": 2,
      "name": "Sirnak",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 740352,
      "level": 2,
      "name": "Sakarya",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 748877,
      "level": 2,
      "name": "Corum",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 322164,
      "level": 2,
      "name": "Balikesir",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 325302,
      "level": 2,
      "name": "Afyon",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 749778,
      "level": 2,
      "name": "Canakkale",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 443186,
      "level": 2,
      "name": "Batman",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 737021,
      "level": 2,
      "name": "Zonguldak ",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 298113,
      "level": 2,
      "name": "Van",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 304919,
      "level": 2,
      "name": "Malatya",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 738926,
      "level": 2,
      "name": "Tekirdag",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 315367,
      "level": 2,
      "name": "Erzurum",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 304794,
      "level": 2,
      "name": "Mardin",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 738647,
      "level": 2,
      "name": "Trabzon",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 304825,
      "level": 2,
      "name": "Manisa",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 310858,
      "level": 2,
      "name": "K. Maras",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 317106,
      "level": 2,
      "name": "Denizli",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 315201,
      "level": 2,
      "name": "Eskisehir",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 322819,
      "level": 2,
      "name": "Aydin",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 304183,
      "level": 2,
      "name": "Mugla",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 742865,
      "level": 2,
      "name": "Kocaeli",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 316540,
      "level": 2,
      "name": "Diyarbakir",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 298332,
      "level": 2,
      "name": "Sanliurfa",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 740263,
      "level": 2,
      "name": "Samsun",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 308463,
      "level": 2,
      "name": "Kayseri",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 306569,
      "level": 2,
      "name": "Konya",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 312394,
      "level": 2,
      "name": "Hatay",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 314829,
      "level": 2,
      "name": "Gaziantep",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 325361,
      "level": 2,
      "name": "Adana",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 750268,
      "level": 2,
      "name": "Bursa",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 311728,
      "level": 2,
      "name": "Mersin",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 323776,
      "level": 2,
      "name": "Antalya",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 311044,
      "level": 2,
      "name": "Izmir",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 323784,
      "level": 2,
      "name": "Ankara",
      "parent_id": 298795
    },
    {
      "country_code": "TR",
      "id": 745042,
      "level": 2,
      "name": "Istanbul",
      "parent_id": 298795
    },
    {
      "country_code": "EG",
      "id": 361323,
      "level": 2,
      "name": "Faiyum",
      "parent_id": 357994
    },
    {
      "country_code": "EG",
      "id": 347794,
      "level": 2,
      "name": "Sohag",
      "parent_id": 357994
    },
    {
      "country_code": "EG",
      "id": 359171,
      "level": 2,
      "name": "Beni Suef",
      "parent_id": 357994
    },
    {
      "country_code": "EG",
      "id": 349401,
      "level": 2,
      "name": "North Sinai Governorate",
      "parent_id": 357994
    },
    {
      "country_code": "EG",
      "id": 361370,
      "level": 2,
      "name": "El Beheira Governorate",
      "parent_id": 357994
    },
    {
      "country_code": "EG",
      "id": 358044,
      "level": 2,
      "name": "Damietta Governorate",
      "parent_id": 357994
    },
    {
      "country_code": "EG",
      "id": 360483,
      "level": 2,
      "name": "New Valley Governorate",
      "parent_id": 357994
    },
    {
      "country_code": "EG",
      "id": 360997,
      "level": 2,
      "name": "Giza Governorate",
      "parent_id": 357994
    },
    {
      "country_code": "EG",
      "id": 360621,
      "level": 2,
      "name": "Al Qalyubia Governorate",
      "parent_id": 357994
    },
    {
      "country_code": "EG",
      "id": 360631,
      "level": 2,
      "name": "Cairo",
      "parent_id": 357994
    },
    {
      "country_code": "EG",
      "id": 7603259,
      "level": 2,
      "name": "Luxor",
      "parent_id": 357994
    },
    {
      "country_code": "EG",
      "id": 354500,
      "level": 2,
      "name": "Kafr el-Sheikh",
      "parent_id": 357994
    },
    {
      "country_code": "EG",
      "id": 361294,
      "level": 2,
      "name": "Gharbia Governorate",
      "parent_id": 357994
    },
    {
      "country_code": "EG",
      "id": 360016,
      "level": 2,
      "name": "Ash Sharqia Governorate",
      "parent_id": 357994
    },
    {
      "country_code": "EG",
      "id": 350546,
      "level": 2,
      "name": "Qena Governorate",
      "parent_id": 357994
    },
    {
      "country_code": "EG",
      "id": 358617,
      "level": 2,
      "name": "Port Said",
      "parent_id": 357994
    },
    {
      "country_code": "EG",
      "id": 352603,
      "level": 2,
      "name": "Marsa Matruh",
      "parent_id": 357994
    },
    {
      "country_code": "EG",
      "id": 361056,
      "level": 2,
      "name": "Ismailia Governorate",
      "parent_id": 357994
    },
    {
      "country_code": "EG",
      "id": 361059,
      "level": 2,
      "name": "Alexandria",
      "parent_id": 357994
    },
    {
      "country_code": "EG",
      "id": 359781,
      "level": 2,
      "name": "Asyut",
      "parent_id": 357994
    },
    {
      "country_code": "EG",
      "id": 359787,
      "level": 2,
      "name": "Aswan Governorate",
      "parent_id": 357994
    },
    {
      "country_code": "EG",
      "id": 355182,
      "level": 2,
      "name": "South Sinai Governorate",
      "parent_id": 357994
    },
    {
      "country_code": "EG",
      "id": 360688,
      "level": 2,
      "name": "Minya",
      "parent_id": 357994
    },
    {
      "country_code": "EG",
      "id": 360689,
      "level": 2,
      "name": "Menofia Governorate",
      "parent_id": 357994
    },
    {
      "country_code": "EG",
      "id": 359797,
      "level": 2,
      "name": "Suez",
      "parent_id": 357994
    },
    {
      "country_code": "EG",
      "id": 361849,
      "level": 2,
      "name": "Dakahlia Governorate",
      "parent_id": 357994
    },
    {
      "country_code": "EG",
      "id": 361468,
      "level": 2,
      "name": "Red Sea Governorate",
      "parent_id": 357994
    },
    {
      "country_code": "PL",
      "id": 3337492,
      "level": 2,
      "name": "Lower Silesian",
      "parent_id": 798544
    },
    {
      "country_code": "PL",
      "id": 3337493,
      "level": 2,
      "name": "Łódź",
      "parent_id": 798544
    },
    {
      "country_code": "PL",
      "id": 3337494,
      "level": 2,
      "name": "Lubusz",
      "parent_id": 798544
    },
    {
      "country_code": "PL",
      "id": 3337495,
      "level": 2,
      "name": "Opole",
      "parent_id": 798544
    },
    {
      "country_code": "PL",
      "id": 3337496,
      "level": 2,
      "name": "Pomeranian",
      "parent_id": 798544
    },
    {
      "country_code": "PL",
      "id": 3337497,
      "level": 2,
      "name": "Silesian",
      "parent_id": 798544
    },
    {
      "country_code": "PL",
      "id": 3337498,
      "level": 2,
      "name": "Greater Poland",
      "parent_id": 798544
    },
    {
      "country_code": "PL",
      "id": 3337499,
      "level": 2,
      "name": "West Pomeranian",
      "parent_id": 798544
    },
    {
      "country_code": "PL",
      "id": 3337500,
      "level": 2,
      "name": "Kuyavian-Pomeranian",
      "parent_id": 798544
    },
    {
      "country_code": "PL",
      "id": 858785,
      "level": 2,
      "name": "Lublin",
      "parent_id": 798544
    },
    {
      "country_code": "PL",
      "id": 858786,
      "level": 2,
      "name": "Lesser Poland",
      "parent_id": 798544
    },
    {
      "country_code": "PL",
      "id": 858787,
      "level": 2,
      "name": "Masovian",
      "parent_id": 798544
    },
    {
      "country_code": "PL",
      "id": 858788,
      "level": 2,
      "name": "Subcarpathian",
      "parent_id": 798544
    },
    {
      "country_code": "PL",
      "id": 858789,
      "level": 2,
      "name": "Podlaskie",
      "parent_id": 798544
    },
    {
      "country_code": "PL",
      "id": 858790,
      "level": 2,
      "name": "Holy Cross",
      "parent_id": 798544
    },
    {
      "country_code": "PL",
      "id": 858791,
      "level": 2,
      "name": "Warmian-Masurian",
      "parent_id": 798544
    },
    {
      "id": 1085593,
      "level": 2,
      "name": "Eastern Cape",
      "parent_id": 953987
    },
    {
      "id": 1085594,
      "level": 2,
      "name": "Gauteng'",
      "parent_id": 953987
    },
    {
      "id": 972062,
      "level": 2,
      "name": "KwaZulu-Natal",
      "parent_id": 953987
    },
    {
      "id": 1085597,
      "level": 2,
      "name": "Limpopo",
      "parent_id": 953987
    },
    {
      "id": 1085595,
      "level": 2,
      "name": "Mpumalanga",
      "parent_id": 953987
    },
    {
      "id": 1085596,
      "level": 2,
      "name": "Northern Cape",
      "parent_id": 953987
    },
    {
      "id": 967573,
      "level": 2,
      "name": "Orange Free State",
      "parent_id": 953987
    },
    {
      "id": 1085598,
      "level": 2,
      "name": "Province of North West",
      "parent_id": 953987
    },
    {
      "id": 1085599,
      "level": 2,
      "name": "Western Cape",
      "parent_id": 953987
    },
    {
      "id": 1184196,
      "level": 2,
      "name": "Azad Kashmir",
      "parent_id": 1168579
    },
    {
      "id": 1183606,
      "level": 2,
      "name": "Balochistan",
      "parent_id": 1168579
    },
    {
      "id": 1179245,
      "level": 2,
      "name": "Federally Administered Tribal Areas",
      "parent_id": 1168579
    },
    {
      "id": 1162015,
      "level": 2,
      "name": "Islamabad",
      "parent_id": 1168579
    },
    {
      "id": 1168873,
      "level": 2,
      "name": "Khyber Pakhtunkhwa",
      "parent_id": 1168579
    },
    {
      "id": 1167710,
      "level": 2,
      "name": "Punjab",
      "parent_id": 1168579
    },
    {
      "id": 1164807,
      "level": 2,
      "name": "Sindh",
      "parent_id": 1168579
    },
    {
      "country_code": "IN",
      "id": 1278253,
      "level": 2,
      "name": "Assam",
      "parent_id": 1269750
    },
    {
      "country_code": "IN",
      "id": 1271157,
      "level": 2,
      "name": "Goa",
      "parent_id": 1269750
    },
    {
      "country_code": "IN",
      "id": 1264542,
      "level": 2,
      "name": "Madhya Pradesh",
      "parent_id": 1269750
    },
    {
      "country_code": "IN",
      "id": 1263706,
      "level": 2,
      "name": "Manipur",
      "parent_id": 1269750
    },
    {
      "country_code": "IN",
      "id": 1263207,
      "level": 2,
      "name": "Meghalaya",
      "parent_id": 1269750
    },
    {
      "country_code": "IN",
      "id": 1262963,
      "level": 2,
      "name": "Mizoram",
      "parent_id": 1269750
    },
    {
      "country_code": "IN",
      "id": 1273293,
      "level": 2,
      "name": "National Capital Territory of Delhi",
      "parent_id": 1269750
    },
    {
      "country_code": "IN",
      "id": 1256312,
      "level": 2,
      "name": "Sikkim",
      "parent_id": 1269750
    },
    {
      "country_code": "IN",
      "id": 1278629,
      "level": 2,
      "name": "State of Andhra Pradesh",
      "parent_id": 1269750
    },
    {
      "country_code": "IN",
      "id": 1278341,
      "level": 2,
      "name": "State of Arunachal Pradesh",
      "parent_id": 1269750
    },
    {
      "country_code": "IN",
      "id": 1275715,
      "level": 2,
      "name": "State of Bihar",
      "parent_id": 1269750
    },
    {
      "country_code": "IN",
      "id": 1444364,
      "level": 2,
      "name": "State of Chhattisgarh",
      "parent_id": 1269750
    },
    {
      "country_code": "IN",
      "id": 1270770,
      "level": 2,
      "name": "State of Gujarat",
      "parent_id": 1269750
    },
    {
      "country_code": "IN",
      "id": 1270260,
      "level": 2,
      "name": "State of Haryana",
      "parent_id": 1269750
    },
    {
      "country_code": "IN",
      "id": 1270101,
      "level": 2,
      "name": "State of Himachal Pradesh",
      "parent_id": 1269750
    },
    {
      "country_code": "IN",
      "id": 1269320,
      "level": 2,
      "name": "State of Jammu and Kashmir",
      "parent_id": 1269750
    },
    {
      "country_code": "IN",
      "id": 1444365,
      "level": 2,
      "name": "State of Jharkhand",
      "parent_id": 1269750
    },
    {
      "country_code": "IN",
      "id": 1267701,
      "level": 2,
      "name": "State of Karnataka",
      "parent_id": 1269750
    },
    {
      "country_code": "IN",
      "id": 1267254,
      "level": 2,
      "name": "State of Kerala",
      "parent_id": 1269750
    },
    {
      "country_code": "IN",
      "id": 1264418,
      "level": 2,
      "name": "State of Maharashtra",
      "parent_id": 1269750
    },
    {
      "country_code": "IN",
      "id": 1262271,
      "level": 2,
      "name": "State of Nagaland",
      "parent_id": 1269750
    },
    {
      "country_code": "IN",
      "id": 1261029,
      "level": 2,
      "name": "State of Odisha",
      "parent_id": 1269750
    },
    {
      "country_code": "IN",
      "id": 1259223,
      "level": 2,
      "name": "State of Punjab",
      "parent_id": 1269750
    },
    {
      "country_code": "IN",
      "id": 1258899,
      "level": 2,
      "name": "State of Rajasthan",
      "parent_id": 1269750
    },
    {
      "country_code": "IN",
      "id": 1255053,
      "level": 2,
      "name": "State of Tamil Nadu",
      "parent_id": 1269750
    },
    {
      "country_code": "IN",
      "id": 1444366,
      "level": 2,
      "name": "State of Uttarakhand",
      "parent_id": 1269750
    },
    {
      "country_code": "IN",
      "id": 1254788,
      "level": 2,
      "name": "Telangana",
      "parent_id": 1269750
    },
    {
      "country_code": "IN",
      "id": 1254169,
      "level": 2,
      "name": "Tripura",
      "parent_id": 1269750
    },
    {
      "country_code": "IN",
      "id": 1278647,
      "level": 2,
      "name": "Union Territory of Andaman and Nicobar Islands",
      "parent_id": 1269750
    },
    {
      "country_code": "IN",
      "id": 1274744,
      "level": 2,
      "name": "Union Territory of Chandigarh",
      "parent_id": 1269750
    },
    {
      "country_code": "IN",
      "id": 1273726,
      "level": 2,
      "name": "Union Territory of Dadra and Nagar Haveli",
      "parent_id": 1269750
    },
    {
      "country_code": "IN",
      "id": 1271155,
      "level": 2,
      "name": "Union Territory of Daman and Diu",
      "parent_id": 1269750
    },
    {
      "country_code": "IN",
      "id": 1265206,
      "level": 2,
      "name": "Union Territory of Lakshadweep",
      "parent_id": 1269750
    },
    {
      "country_code": "IN",
      "id": 1259424,
      "level": 2,
      "name": "Union Territory of Puducherry",
      "parent_id": 1269750
    },
    {
      "country_code": "IN",
      "id": 1253626,
      "level": 2,
      "name": "Uttar Pradesh",
      "parent_id": 1269750
    },
    {
      "country_code": "IN",
      "id": 1252881,
      "level": 2,
      "name": "West Bengal",
      "parent_id": 1269750
    },
    {
      "country_code": "VN",
      "id": 1905637,
      "level": 2,
      "name": "Ha Nam Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1905669,
      "level": 2,
      "name": "Bac Kan Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1586182,
      "level": 2,
      "name": "Cao Bang Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1577954,
      "level": 2,
      "name": "Lai Chau Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1905099,
      "level": 2,
      "name": "Ðien Bien Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1905480,
      "level": 2,
      "name": "Binh Phuoc Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1904987,
      "level": 2,
      "name": "Ðak Nong Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1905856,
      "level": 2,
      "name": "Vinh Phuc Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1565088,
      "level": 2,
      "name": "Kon Tum Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1581030,
      "level": 2,
      "name": "Ha Giang Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 7506719,
      "level": 2,
      "name": "Hau Giang Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1568758,
      "level": 2,
      "name": "Quang Ninh Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1572594,
      "level": 2,
      "name": "Hoa Binh Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1559976,
      "level": 2,
      "name": "Tuyen Quang Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1569805,
      "level": 2,
      "name": "Phu Yen Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1567643,
      "level": 2,
      "name": "Son La Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1559971,
      "level": 2,
      "name": "Ninh Thuan Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1568733,
      "level": 2,
      "name": "Quang Tri Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1562412,
      "level": 2,
      "name": "Lao Cai Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1559978,
      "level": 2,
      "name": "Yen Bai Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1905675,
      "level": 2,
      "name": "Bac Lieu Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1566338,
      "level": 2,
      "name": "Thai Binh Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1905699,
      "level": 2,
      "name": "Hung Yen Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1579008,
      "level": 2,
      "name": "Kien Giang Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1559970,
      "level": 2,
      "name": "Ninh Binh Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1905626,
      "level": 2,
      "name": "Nam Dinh Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1576632,
      "level": 2,
      "name": "Lang Son Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1905577,
      "level": 2,
      "name": "Phu Tho Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1582562,
      "level": 2,
      "name": "Dong Thap Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1581088,
      "level": 2,
      "name": "Gia Lai Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1568839,
      "level": 2,
      "name": "Quang Binh Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1587974,
      "level": 2,
      "name": "Ben Tre Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1564676,
      "level": 2,
      "name": "Tien Giang Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1905412,
      "level": 2,
      "name": "Bac Ninh Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1565033,
      "level": 2,
      "name": "Thua Thien – Hue Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1577882,
      "level": 2,
      "name": "Lam Dong Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1905419,
      "level": 2,
      "name": "Bac Giang Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1905678,
      "level": 2,
      "name": "Ca Mau Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1559972,
      "level": 2,
      "name": "Soc Trang Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1905475,
      "level": 2,
      "name": "Binh Duong Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1581882,
      "level": 2,
      "name": "Binh Thuan Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1905686,
      "level": 2,
      "name": "Hai Duong Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1559969,
      "level": 2,
      "name": "Nghe An Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1568769,
      "level": 2,
      "name": "Quang Ngai Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1566557,
      "level": 2,
      "name": "Tay Ninh Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1559977,
      "level": 2,
      "name": "Vinh Long Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1575788,
      "level": 2,
      "name": "Long An Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1584169,
      "level": 2,
      "name": "Dak Lak Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1559975,
      "level": 2,
      "name": "Tra Vinh Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1587871,
      "level": 2,
      "name": "Binh Dinh Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1579634,
      "level": 2,
      "name": "Khanh Hoa Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1566165,
      "level": 2,
      "name": "Thanh Hoa Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1905497,
      "level": 2,
      "name": "Thai Nguyen Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1584534,
      "level": 2,
      "name": "Ba Ria – Vung Tau Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1580700,
      "level": 2,
      "name": "Ha Tinh Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1581297,
      "level": 2,
      "name": "Hai Phong City",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1594446,
      "level": 2,
      "name": "An Giang Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1582720,
      "level": 2,
      "name": "Dong Nai Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1581188,
      "level": 2,
      "name": "Can Tho City",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1905468,
      "level": 2,
      "name": "Da Nang City",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1905516,
      "level": 2,
      "name": "Quang Nam Province",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1580578,
      "level": 2,
      "name": "Ho Chi Minh City",
      "parent_id": 1562822
    },
    {
      "country_code": "VN",
      "id": 1581129,
      "level": 2,
      "name": "Ha Noi City",
      "parent_id": 1562822
    },
    {
      "country_code": "TH",
      "id": 1152221,
      "level": 2,
      "name": "Mae Hong Son Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1605277,
      "level": 2,
      "name": "Trat Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1906690,
      "level": 2,
      "name": "Nong Bua Lamphu Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1606269,
      "level": 2,
      "name": "Sing Buri Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1606375,
      "level": 2,
      "name": "Satun Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1608595,
      "level": 2,
      "name": "Mukdahan Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1607758,
      "level": 2,
      "name": "Phayao Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1149965,
      "level": 2,
      "name": "Uthai Thani Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1608538,
      "level": 2,
      "name": "Nakhon Nayok Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1607551,
      "level": 2,
      "name": "Phrae Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1607778,
      "level": 2,
      "name": "Phatthalung Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1604767,
      "level": 2,
      "name": "Yasothon Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1608451,
      "level": 2,
      "name": "Nan Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1906689,
      "level": 2,
      "name": "Amnat Charoen Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1152467,
      "level": 2,
      "name": "Lamphun Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1608899,
      "level": 2,
      "name": "Maha Sarakham Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1609070,
      "level": 2,
      "name": "Loei Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1153089,
      "level": 2,
      "name": "Kamphaeng Phet Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1605214,
      "level": 2,
      "name": "Uttaradit Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1604869,
      "level": 2,
      "name": "Yala Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1151462,
      "level": 2,
      "name": "Phang Nga Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1906691,
      "level": 2,
      "name": "Sa Kaeo Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1607736,
      "level": 2,
      "name": "Mueang Phetchabun District",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1906687,
      "level": 2,
      "name": "Prachinburi Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1150964,
      "level": 2,
      "name": "Ranong Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 8133594,
      "level": 2,
      "name": "Bueng Kan",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1606238,
      "level": 2,
      "name": "Sisaket Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1150006,
      "level": 2,
      "name": "Trang Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1153555,
      "level": 2,
      "name": "Chumphon Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1153080,
      "level": 2,
      "name": "Mueang Kanchanaburi District",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1610468,
      "level": 2,
      "name": "Kalasin Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1607976,
      "level": 2,
      "name": "Pattani Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1608408,
      "level": 2,
      "name": "Narathiwat Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1606032,
      "level": 2,
      "name": "Suphan Buri Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1608231,
      "level": 2,
      "name": "Nong Khai Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1151416,
      "level": 2,
      "name": "Phetchaburi Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1611406,
      "level": 2,
      "name": "Chainat Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1609031,
      "level": 2,
      "name": "Lop Buri",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1611452,
      "level": 2,
      "name": "Buriram Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1621034,
      "level": 2,
      "name": "Ang Thong Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1152631,
      "level": 2,
      "name": "Krabi Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1152472,
      "level": 2,
      "name": "Lampang Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1611438,
      "level": 2,
      "name": "Chachoengsao Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1150532,
      "level": 2,
      "name": "Sukhothai Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1606789,
      "level": 2,
      "name": "Sakon Nakhon Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1611415,
      "level": 2,
      "name": "Chainat Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1150489,
      "level": 2,
      "name": "Tak Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1608530,
      "level": 2,
      "name": "Nakhon Phanom Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1607000,
      "level": 2,
      "name": "Roi Et",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1608525,
      "level": 2,
      "name": "Nakhon Sri Thammarat Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1151073,
      "level": 2,
      "name": "Prachuap Khiri Khan Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1150953,
      "level": 2,
      "name": "Ratchaburi Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1607724,
      "level": 2,
      "name": "Pichit Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1606585,
      "level": 2,
      "name": "Samut Songkhram Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1153668,
      "level": 2,
      "name": "Chiang Rai Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1611268,
      "level": 2,
      "name": "Chanthaburi",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1606417,
      "level": 2,
      "name": "Saraburi Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1608526,
      "level": 2,
      "name": "Nakhon Sawan Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1606029,
      "level": 2,
      "name": "Surin Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1606589,
      "level": 2,
      "name": "Samut Prakan Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1150514,
      "level": 2,
      "name": "Surat Thani Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1606587,
      "level": 2,
      "name": "Samut Sakhon Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1906688,
      "level": 2,
      "name": "Ubon Ratchathani Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1607707,
      "level": 2,
      "name": "Phitsanulok Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1608533,
      "level": 2,
      "name": "Mueang Nakhon Pathom District",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1151253,
      "level": 2,
      "name": "Phuket Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1607530,
      "level": 2,
      "name": "Phra Nakhon Si Ayutthaya Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1607016,
      "level": 2,
      "name": "Rayong Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1609775,
      "level": 2,
      "name": "Khon Kaen Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1906686,
      "level": 2,
      "name": "Udon Thani Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1606146,
      "level": 2,
      "name": "Songkhla Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1608528,
      "level": 2,
      "name": "Nakhon Ratchasima Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1607982,
      "level": 2,
      "name": "Pathum Thani Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1608132,
      "level": 2,
      "name": "Nonthaburi Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1611108,
      "level": 2,
      "name": "Chonburi Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1153670,
      "level": 2,
      "name": "Chiang Mai Province",
      "parent_id": 1605651
    },
    {
      "country_code": "TH",
      "id": 1609348,
      "level": 2,
      "name": "Bangkok",
      "parent_id": 1605651
    },
    {
      "country_code": "ID",
      "id": 1213642,
      "level": 2,
      "name": "North Sumatra",
      "parent_id": 1643084
    },
    {
      "country_code": "ID",
      "id": 1215638,
      "level": 2,
      "name": "Aceh",
      "parent_id": 1643084
    },
    {
      "country_code": "ID",
      "id": 1621176,
      "level": 2,
      "name": "Yogyakarta",
      "parent_id": 1643084
    },
    {
      "country_code": "ID",
      "id": 1626196,
      "level": 2,
      "name": "South Sumatra",
      "parent_id": 1643084
    },
    {
      "country_code": "ID",
      "id": 1626197,
      "level": 2,
      "name": "West Sumatra",
      "parent_id": 1643084
    },
    {
      "country_code": "ID",
      "id": 1626229,
      "level": 2,
      "name": "North Sulawesi",
      "parent_id": 1643084
    },
    {
      "country_code": "ID",
      "id": 1626230,
      "level": 2,
      "name": "Southeast Sulawesi",
      "parent_id": 1643084
    },
    {
      "country_code": "ID",
      "id": 1626231,
      "level": 2,
      "name": "Central Sulawesi",
      "parent_id": 1643084
    },
    {
      "country_code": "ID",
      "id": 1626232,
      "level": 2,
      "name": "South Sulawesi",
      "parent_id": 1643084
    },
    {
      "country_code": "ID",
      "id": 1629652,
      "level": 2,
      "name": "Riau",
      "parent_id": 1643084
    },
    {
      "country_code": "ID",
      "id": 1633791,
      "level": 2,
      "name": "East Nusa Tenggara",
      "parent_id": 1643084
    },
    {
      "country_code": "ID",
      "id": 1633792,
      "level": 2,
      "name": "West Nusa Tenggara",
      "parent_id": 1643084
    },
    {
      "country_code": "ID",
      "id": 1636627,
      "level": 2,
      "name": "Maluku",
      "parent_id": 1643084
    },
    {
      "country_code": "ID",
      "id": 1638535,
      "level": 2,
      "name": "Lampung",
      "parent_id": 1643084
    },
    {
      "country_code": "ID",
      "id": 1641897,
      "level": 2,
      "name": "East Kalimantan",
      "parent_id": 1643084
    },
    {
      "country_code": "ID",
      "id": 1641898,
      "level": 2,
      "name": "Central Kalimantan",
      "parent_id": 1643084
    },
    {
      "country_code": "ID",
      "id": 1641899,
      "level": 2,
      "name": "South Kalimantan",
      "parent_id": 1643084
    },
    {
      "country_code": "ID",
      "id": 1641900,
      "level": 2,
      "name": "West Kalimantan",
      "parent_id": 1643084
    },
    {
      "country_code": "ID",
      "id": 1642668,
      "level": 2,
      "name": "East Java",
      "parent_id": 1643084
    },
    {
      "country_code": "ID",
      "id": 1642669,
      "level": 2,
      "name": "Central Java",
      "parent_id": 1643084
    },
    {
      "country_code": "ID",
      "id": 1642672,
      "level": 2,
      "name": "West Java",
      "parent_id": 1643084
    },
    {
      "country_code": "ID",
      "id": 1642856,
      "level": 2,
      "name": "Jambi",
      "parent_id": 1643084
    },
    {
      "country_code": "ID",
      "id": 1642907,
      "level": 2,
      "name": "Jakarta Raya",
      "parent_id": 1643084
    },
    {
      "country_code": "ID",
      "id": 1643012,
      "level": 2,
      "name": "Papua",
      "parent_id": 1643084
    },
    {
      "country_code": "ID",
      "id": 1649147,
      "level": 2,
      "name": "Bengkulu",
      "parent_id": 1643084
    },
    {
      "country_code": "ID",
      "id": 1650535,
      "level": 2,
      "name": "Bali",
      "parent_id": 1643084
    },
    {
      "country_code": "ID",
      "id": 1923045,
      "level": 2,
      "name": "Banten",
      "parent_id": 1643084
    },
    {
      "country_code": "ID",
      "id": 1923046,
      "level": 2,
      "name": "Gorontalo",
      "parent_id": 1643084
    },
    {
      "country_code": "ID",
      "id": 1923047,
      "level": 2,
      "name": "Bangka Belitung",
      "parent_id": 1643084
    },
    {
      "country_code": "ID",
      "id": 1958070,
      "level": 2,
      "name": "North Maluku",
      "parent_id": 1643084
    },
    {
      "country_code": "ID",
      "id": 1996549,
      "level": 2,
      "name": "West Papua",
      "parent_id": 1643084
    },
    {
      "country_code": "ID",
      "id": 1996550,
      "level": 2,
      "name": "West Sulawesi",
      "parent_id": 1643084
    },
    {
      "country_code": "ID",
      "id": 1996551,
      "level": 2,
      "name": "Riau islands",
      "parent_id": 1643084
    },
    {
      "country_code": "ID",
      "id": 8604684,
      "level": 2,
      "name": "North Kalimantan",
      "parent_id": 1643084
    },
    {
      "country_code": "MY",
      "id": 1734240,
      "level": 2,
      "name": "Labuan",
      "parent_id": 1733045
    },
    {
      "country_code": "MY",
      "id": 1733040,
      "level": 2,
      "name": "Perlis",
      "parent_id": 1733045
    },
    {
      "country_code": "MY",
      "id": 1996552,
      "level": 2,
      "name": "Putrajaya ",
      "parent_id": 1733045
    },
    {
      "country_code": "MY",
      "id": 1733036,
      "level": 2,
      "name": "Trengganu",
      "parent_id": 1733045
    },
    {
      "country_code": "MY",
      "id": 1733044,
      "level": 2,
      "name": "Kelantan",
      "parent_id": 1733045
    },
    {
      "country_code": "MY",
      "id": 1733042,
      "level": 2,
      "name": "Pahang",
      "parent_id": 1733045
    },
    {
      "country_code": "MY",
      "id": 1733048,
      "level": 2,
      "name": "Kedah",
      "parent_id": 1733045
    },
    {
      "country_code": "MY",
      "id": 1733035,
      "level": 2,
      "name": "Malacca",
      "parent_id": 1733045
    },
    {
      "country_code": "MY",
      "id": 1733038,
      "level": 2,
      "name": "Sarawak",
      "parent_id": 1733045
    },
    {
      "country_code": "MY",
      "id": 1733039,
      "level": 2,
      "name": "Sabah",
      "parent_id": 1733045
    },
    {
      "country_code": "MY",
      "id": 1733041,
      "level": 2,
      "name": "Perak",
      "parent_id": 1733045
    },
    {
      "country_code": "MY",
      "id": 1733047,
      "level": 2,
      "name": "Penang",
      "parent_id": 1733045
    },
    {
      "country_code": "MY",
      "id": 1733043,
      "level": 2,
      "name": "Negeri Sembilan",
      "parent_id": 1733045
    },
    {
      "country_code": "MY",
      "id": 1733049,
      "level": 2,
      "name": "Johor",
      "parent_id": 1733045
    },
    {
      "country_code": "MY",
      "id": 1733037,
      "level": 2,
      "name": "Selangor ",
      "parent_id": 1733045
    },
    {
      "country_code": "MY",
      "id": 1733046,
      "level": 2,
      "name": "Kuala Lumpur",
      "parent_id": 1733045
    },
    {
      "country_code": "KR",
      "id": 1833742,
      "level": 2,
      "name": "Ulsan",
      "parent_id": 1835841
    },
    {
      "country_code": "KR",
      "id": 1835224,
      "level": 2,
      "name": "Daegu",
      "parent_id": 1835841
    },
    {
      "country_code": "KR",
      "id": 1835327,
      "level": 2,
      "name": "Daegu",
      "parent_id": 1835841
    },
    {
      "country_code": "KR",
      "id": 1835847,
      "level": 2,
      "name": "Seoul",
      "parent_id": 1835841
    },
    {
      "country_code": "KR",
      "id": 1838519,
      "level": 2,
      "name": "Busan",
      "parent_id": 1835841
    },
    {
      "country_code": "KR",
      "id": 1841597,
      "level": 2,
      "name": "Gyeongsangbuk-do",
      "parent_id": 1835841
    },
    {
      "country_code": "KR",
      "id": 1841610,
      "level": 2,
      "name": "Gyeonggi-do",
      "parent_id": 1835841
    },
    {
      "country_code": "KR",
      "id": 1841808,
      "level": 2,
      "name": "Gwangju",
      "parent_id": 1835841
    },
    {
      "country_code": "KR",
      "id": 1843125,
      "level": 2,
      "name": "Gangwon-do",
      "parent_id": 1835841
    },
    {
      "country_code": "KR",
      "id": 1843561,
      "level": 2,
      "name": "Incheon",
      "parent_id": 1835841
    },
    {
      "country_code": "KR",
      "id": 1845105,
      "level": 2,
      "name": "Chungcheongnam-do",
      "parent_id": 1835841
    },
    {
      "country_code": "KR",
      "id": 1845106,
      "level": 2,
      "name": "Chungcheongbuk-do",
      "parent_id": 1835841
    },
    {
      "country_code": "KR",
      "id": 1845788,
      "level": 2,
      "name": "Jeonnam-do",
      "parent_id": 1835841
    },
    {
      "country_code": "KR",
      "id": 1845789,
      "level": 2,
      "name": "Jeollabuk-do",
      "parent_id": 1835841
    },
    {
      "country_code": "KR",
      "id": 1846265,
      "level": 2,
      "name": "Jeju-do",
      "parent_id": 1835841
    },
    {
      "country_code": "KR",
      "id": 1902028,
      "level": 2,
      "name": "Gyeongsangnam-do",
      "parent_id": 1835841
    },
    {
      "country_code": "KR",
      "id": 8394437,
      "level": 2,
      "name": "Sejong City",
      "parent_id": 1835841
    },
    {
      "country_code": "JP",
      "id": 2130037,
      "level": 2,
      "name": "Hokkaido",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 1854345,
      "level": 2,
      "name": "Okinawa Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 1850144,
      "level": 2,
      "name": "Tokyo Metropolis",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 1855429,
      "level": 2,
      "name": "Niigata Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 1856156,
      "level": 2,
      "name": "Nagasaki Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 1860825,
      "level": 2,
      "name": "Kagoshima Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 2110554,
      "level": 2,
      "name": "Yamagata Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 2111888,
      "level": 2,
      "name": "Miyagi Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 2112518,
      "level": 2,
      "name": "Iwate Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 2112669,
      "level": 2,
      "name": "Ibaraki Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 2112922,
      "level": 2,
      "name": "Fukushima Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 2113014,
      "level": 2,
      "name": "Chiba Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 2113124,
      "level": 2,
      "name": "Akita Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 2130656,
      "level": 2,
      "name": "Aomori Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 1853226,
      "level": 2,
      "name": "Saitama Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 1853299,
      "level": 2,
      "name": "Saga Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 1853904,
      "level": 2,
      "name": "Osaka Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 1854381,
      "level": 2,
      "name": "Okayama Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 1854484,
      "level": 2,
      "name": "Oita Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 1855608,
      "level": 2,
      "name": "Nara Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 1856210,
      "level": 2,
      "name": "Nagano Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 1856710,
      "level": 2,
      "name": "Miyazaki Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 1849872,
      "level": 2,
      "name": "Toyama Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 1849890,
      "level": 2,
      "name": "Tottori Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 1850157,
      "level": 2,
      "name": "Tokushima Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 1850310,
      "level": 2,
      "name": "Tochigi Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 1851715,
      "level": 2,
      "name": "Shizuoka Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 1852442,
      "level": 2,
      "name": "Shimane Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 1852553,
      "level": 2,
      "name": "Shiga Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 1865694,
      "level": 2,
      "name": "Aichi Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 1848649,
      "level": 2,
      "name": "Yamanashi Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 1848681,
      "level": 2,
      "name": "Yamaguchi Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 1848938,
      "level": 2,
      "name": "Wakayama Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 1857352,
      "level": 2,
      "name": "Mie Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 1857907,
      "level": 2,
      "name": "Kyoto Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 1858419,
      "level": 2,
      "name": "Kumamoto Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 1859133,
      "level": 2,
      "name": "Kochi Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 1860291,
      "level": 2,
      "name": "Kanagawa Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 1860834,
      "level": 2,
      "name": "Kagawa Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 1861387,
      "level": 2,
      "name": "Ishikawa Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 1862047,
      "level": 2,
      "name": "Hyogo Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 1862413,
      "level": 2,
      "name": "Hiroshima Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 1863501,
      "level": 2,
      "name": "Gunma Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 1863640,
      "level": 2,
      "name": "Gifu Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 1863958,
      "level": 2,
      "name": "Fukuoka Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 1863983,
      "level": 2,
      "name": "Fukui Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "JP",
      "id": 1864226,
      "level": 2,
      "name": "Ehime Prefecture",
      "parent_id": 1861060
    },
    {
      "country_code": "RU",
      "id": 2013162,
      "level": 2,
      "name": "Sakha Republic",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 2027748,
      "level": 2,
      "name": "Amur Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 2017623,
      "level": 2,
      "name": "Primorsky Krai",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 2050915,
      "level": 2,
      "name": "Buryatia",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 2121529,
      "level": 2,
      "name": "Sakhalin Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 2022888,
      "level": 2,
      "name": "Khabarovsk Krai",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 2023468,
      "level": 2,
      "name": "Irkutsk Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 2123627,
      "level": 2,
      "name": "Magadan Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 2125072,
      "level": 2,
      "name": "Kamchatka Krai",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 2126099,
      "level": 2,
      "name": "Chukotka Autonomous Okrug",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 1502020,
      "level": 2,
      "name": "Krasnoyarsk Krai",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 1503773,
      "level": 2,
      "name": "Khanty-Mansi Autonomous Okrug",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 1503900,
      "level": 2,
      "name": "Kemerovo Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 1496152,
      "level": 2,
      "name": "Omsk Oblast ",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 1496745,
      "level": 2,
      "name": "Novosibirsk Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 1506272,
      "level": 2,
      "name": "Altai Republic",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 1508290,
      "level": 2,
      "name": "Chelyabinsk Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 1489421,
      "level": 2,
      "name": "Tomsk Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 1490542,
      "level": 2,
      "name": "Sverdlovsk Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 1486462,
      "level": 2,
      "name": "Yamalo-Nenets Autonomous Okrug",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 1488747,
      "level": 2,
      "name": "Tyumen Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 1488873,
      "level": 2,
      "name": "Tuva",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 1511732,
      "level": 2,
      "name": "Altai Krai",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 472454,
      "level": 2,
      "name": "Vologda Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 472755,
      "level": 2,
      "name": "Volgograd Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 501165,
      "level": 2,
      "name": "Rostov Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 498671,
      "level": 2,
      "name": "Saratov Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 480041,
      "level": 2,
      "name": "Tver Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 511180,
      "level": 2,
      "name": "Perm Krai",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 522652,
      "level": 2,
      "name": "Nenets Autonomous Okrug",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 553972,
      "level": 2,
      "name": "Republic of Kalmykia",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 515001,
      "level": 2,
      "name": "Orenburg Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 524304,
      "level": 2,
      "name": "Murmansk Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 545854,
      "level": 2,
      "name": "Komi Republic",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 548389,
      "level": 2,
      "name": "Kirov Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 552548,
      "level": 2,
      "name": "Republic of Karelia",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 578853,
      "level": 2,
      "name": "Republic of Bashkortostan",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 581043,
      "level": 2,
      "name": "Arkhangelsk Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 7779061,
      "level": 2,
      "name": "Zabaykalsky Krai",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 2026639,
      "level": 2,
      "name": "Jewish Autonomous Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 1501312,
      "level": 2,
      "name": "Kurgan Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 1503834,
      "level": 2,
      "name": "Republic of Khakassia",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 487839,
      "level": 2,
      "name": "Stavropol Krai",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 472039,
      "level": 2,
      "name": "Voronezh Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 499068,
      "level": 2,
      "name": "Samara Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 500059,
      "level": 2,
      "name": "Ryazan Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 504338,
      "level": 2,
      "name": "Pskov Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 491684,
      "level": 2,
      "name": "Smolensk Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 484048,
      "level": 2,
      "name": "Tatarstan",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 484638,
      "level": 2,
      "name": "Tambov Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 479119,
      "level": 2,
      "name": "Ulyanovsk Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 479613,
      "level": 2,
      "name": "Udmurt Republic",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 468898,
      "level": 2,
      "name": "Yaroslavl Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 542415,
      "level": 2,
      "name": "Krasnodar Krai",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 543871,
      "level": 2,
      "name": "Kostroma Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 529352,
      "level": 2,
      "name": "Mari El Republic",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 511555,
      "level": 2,
      "name": "Penza Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 559838,
      "level": 2,
      "name": "Nizhny Novgorod Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 553899,
      "level": 2,
      "name": "Kaluga Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 571473,
      "level": 2,
      "name": "Bryansk Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 514801,
      "level": 2,
      "name": "Oryol Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 567293,
      "level": 2,
      "name": "Republic of Dagestan",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 519324,
      "level": 2,
      "name": "Novgorod Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 524925,
      "level": 2,
      "name": "Moscow city",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 525369,
      "level": 2,
      "name": "Mordovia",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 536199,
      "level": 2,
      "name": "Leningrad Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 538555,
      "level": 2,
      "name": "Kursk Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 578071,
      "level": 2,
      "name": "Belgorod Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 580491,
      "level": 2,
      "name": "Astrakhan Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 826294,
      "level": 2,
      "name": "Vladimir Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 480508,
      "level": 2,
      "name": "Tula Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 519969,
      "level": 2,
      "name": "North Ossetia–Alania",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 552927,
      "level": 2,
      "name": "Karachay-Cherkessia",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 554230,
      "level": 2,
      "name": "Kaliningrad Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 554667,
      "level": 2,
      "name": "Kabardino-Balkaria",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 555235,
      "level": 2,
      "name": "Ivanovo Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 556349,
      "level": 2,
      "name": "Ingushetia",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 567395,
      "level": 2,
      "name": "Chuvashia Republic",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 569665,
      "level": 2,
      "name": "Chechnya",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 524894,
      "level": 2,
      "name": "Moscow Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 535120,
      "level": 2,
      "name": "Lipetsk Oblast",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 536203,
      "level": 2,
      "name": "St. Petersburg (City Council)",
      "parent_id": 2017370
    },
    {
      "country_code": "RU",
      "id": 584222,
      "level": 2,
      "name": "Adygea",
      "parent_id": 2017370
    },
    {
      "country_code": "AU",
      "id": 2058645,
      "level": 2,
      "name": "Western Australia",
      "parent_id": 2077456
    },
    {
      "country_code": "AU",
      "id": 2061327,
      "level": 2,
      "name": "South Australia",
      "parent_id": 2077456
    },
    {
      "country_code": "AU",
      "id": 2064513,
      "level": 2,
      "name": "Northern Territory",
      "parent_id": 2077456
    },
    {
      "country_code": "AU",
      "id": 2145234,
      "level": 2,
      "name": "Victoria",
      "parent_id": 2077456
    },
    {
      "country_code": "AU",
      "id": 2147291,
      "level": 2,
      "name": "Tasmania",
      "parent_id": 2077456
    },
    {
      "country_code": "AU",
      "id": 2152274,
      "level": 2,
      "name": "Queensland",
      "parent_id": 2077456
    },
    {
      "country_code": "AU",
      "id": 2155400,
      "level": 2,
      "name": "New South Wales",
      "parent_id": 2077456
    },
    {
      "country_code": "AU",
      "id": 2177478,
      "level": 2,
      "name": "Australian Capital Territory",
      "parent_id": 2077456
    },
    {
      "country_code": "ES",
      "id": 2593109,
      "level": 2,
      "name": "Andalusia",
      "parent_id": 2510769
    },
    {
      "country_code": "ES",
      "id": 2593111,
      "level": 2,
      "name": "Castile-La Mancha",
      "parent_id": 2510769
    },
    {
      "country_code": "ES",
      "id": 2593110,
      "level": 2,
      "name": "Canary Islands",
      "parent_id": 2510769
    },
    {
      "country_code": "ES",
      "id": 2593112,
      "level": 2,
      "name": "Extremadura",
      "parent_id": 2510769
    },
    {
      "country_code": "ES",
      "id": 2593113,
      "level": 2,
      "name": "Valencian Community",
      "parent_id": 2510769
    },
    {
      "country_code": "ES",
      "id": 2521383,
      "level": 2,
      "name": "Balearic Islands",
      "parent_id": 2510769
    },
    {
      "country_code": "ES",
      "id": 3336899,
      "level": 2,
      "name": "Aragon",
      "parent_id": 2510769
    },
    {
      "country_code": "ES",
      "id": 3336900,
      "level": 2,
      "name": "Castile and León",
      "parent_id": 2510769
    },
    {
      "country_code": "ES",
      "id": 3336901,
      "level": 2,
      "name": "Catalonia",
      "parent_id": 2510769
    },
    {
      "country_code": "ES",
      "id": 3336902,
      "level": 2,
      "name": "Galicia",
      "parent_id": 2510769
    },
    {
      "country_code": "ES",
      "id": 2513413,
      "level": 2,
      "name": "Region of Murcia",
      "parent_id": 2510769
    },
    {
      "country_code": "ES",
      "id": 2519582,
      "level": 2,
      "name": "Ceuta",
      "parent_id": 2510769
    },
    {
      "country_code": "ES",
      "id": 3336897,
      "level": 2,
      "name": "La Rioja",
      "parent_id": 2510769
    },
    {
      "country_code": "ES",
      "id": 3336898,
      "level": 2,
      "name": "Cantabria",
      "parent_id": 2510769
    },
    {
      "country_code": "ES",
      "id": 3336903,
      "level": 2,
      "name": "Basque Country",
      "parent_id": 2510769
    },
    {
      "country_code": "ES",
      "id": 3117732,
      "level": 2,
      "name": "Community of Madrid",
      "parent_id": 2510769
    },
    {
      "country_code": "ES",
      "id": 3114710,
      "level": 2,
      "name": "Principality of Asturias",
      "parent_id": 2510769
    },
    {
      "country_code": "ES",
      "id": 3115609,
      "level": 2,
      "name": "Navarre",
      "parent_id": 2510769
    },
    {
      "country_code": "ES",
      "id": 6362988,
      "level": 2,
      "name": "Melilla",
      "parent_id": 2510769
    },
    {
      "country_code": "GB",
      "id": 2638360,
      "level": 2,
      "name": "Scotland",
      "parent_id": 2635167
    },
    {
      "country_code": "GB",
      "id": 6269131,
      "level": 2,
      "name": "England",
      "parent_id": 2635167
    },
    {
      "country_code": "GB",
      "id": 2634895,
      "level": 2,
      "name": "Wales",
      "parent_id": 2635167
    },
    {
      "country_code": "GB",
      "id": 2641364,
      "level": 2,
      "name": "Northern Ireland",
      "parent_id": 2635167
    },
    {
      "country_code": "DE",
      "id": 2905330,
      "level": 2,
      "name": "Hessen",
      "parent_id": 2921044
    },
    {
      "country_code": "DE",
      "id": 2838632,
      "level": 2,
      "name": "Schleswig-Holstein",
      "parent_id": 2921044
    },
    {
      "country_code": "DE",
      "id": 2842565,
      "level": 2,
      "name": "Saxony-Anhalt",
      "parent_id": 2921044
    },
    {
      "country_code": "DE",
      "id": 2861876,
      "level": 2,
      "name": "Nordrhein-Westfalen",
      "parent_id": 2921044
    },
    {
      "country_code": "DE",
      "id": 2862926,
      "level": 2,
      "name": "Lower Saxony",
      "parent_id": 2921044
    },
    {
      "country_code": "DE",
      "id": 2872567,
      "level": 2,
      "name": "Mecklenburg-Western Pomerania",
      "parent_id": 2921044
    },
    {
      "country_code": "DE",
      "id": 2945356,
      "level": 2,
      "name": "Brandenburg",
      "parent_id": 2921044
    },
    {
      "country_code": "DE",
      "id": 2951839,
      "level": 2,
      "name": "Bavaria",
      "parent_id": 2921044
    },
    {
      "country_code": "DE",
      "id": 2953481,
      "level": 2,
      "name": "Baden-Wuerttemberg Region",
      "parent_id": 2921044
    },
    {
      "country_code": "DE",
      "id": 2911297,
      "level": 2,
      "name": "Free and Hanseatic City of Hamburg",
      "parent_id": 2921044
    },
    {
      "country_code": "DE",
      "id": 2847618,
      "level": 2,
      "name": "Rheinland-Pfalz",
      "parent_id": 2921044
    },
    {
      "country_code": "DE",
      "id": 2842566,
      "level": 2,
      "name": "Saxony",
      "parent_id": 2921044
    },
    {
      "country_code": "DE",
      "id": 2842635,
      "level": 2,
      "name": "Saarland",
      "parent_id": 2921044
    },
    {
      "country_code": "DE",
      "id": 2822542,
      "level": 2,
      "name": "Thuringia",
      "parent_id": 2921044
    },
    {
      "country_code": "DE",
      "id": 2944387,
      "level": 2,
      "name": "Bremen",
      "parent_id": 2921044
    },
    {
      "country_code": "DE",
      "id": 2950157,
      "level": 2,
      "name": "Land Berlin",
      "parent_id": 2921044
    },
    {
      "country_code": "FR",
      "id": 11071620,
      "level": 2,
      "name": "Nouvelle-Aquitaine",
      "parent_id": 3017382
    },
    {
      "country_code": "FR",
      "id": 2988289,
      "level": 2,
      "name": "Pays de la Loire",
      "parent_id": 3017382
    },
    {
      "country_code": "FR",
      "id": 2985244,
      "level": 2,
      "name": "Provence-Alpes-Côte d'Azur",
      "parent_id": 3017382
    },
    {
      "country_code": "FR",
      "id": 3027939,
      "level": 2,
      "name": "Centre-Loire Valley",
      "parent_id": 3017382
    },
    {
      "country_code": "FR",
      "id": 3030293,
      "level": 2,
      "name": "Brittany",
      "parent_id": 3017382
    },
    {
      "country_code": "FR",
      "id": 11071619,
      "level": 2,
      "name": "Bourgogne-Franche-Comté",
      "parent_id": 3017382
    },
    {
      "country_code": "FR",
      "id": 11071621,
      "level": 2,
      "name": "Normandy",
      "parent_id": 3017382
    },
    {
      "country_code": "FR",
      "id": 11071622,
      "level": 2,
      "name": "Great East",
      "parent_id": 3017382
    },
    {
      "country_code": "FR",
      "id": 11071623,
      "level": 2,
      "name": "Occitanie",
      "parent_id": 3017382
    },
    {
      "country_code": "FR",
      "id": 11071624,
      "level": 2,
      "name": "Hauts-de-France",
      "parent_id": 3017382
    },
    {
      "country_code": "FR",
      "id": 11071625,
      "level": 2,
      "name": "Auvergne-Rhône-Alpes",
      "parent_id": 3017382
    },
    {
      "country_code": "FR",
      "id": 3023519,
      "level": 2,
      "name": "Corse",
      "parent_id": 3017382
    },
    {
      "country_code": "FR",
      "id": 3012874,
      "level": 2,
      "name": "Ile-de-France",
      "parent_id": 3017382
    },
    {
      "country_code": "IT",
      "id": 2523119,
      "level": 2,
      "name": "Sicily",
      "parent_id": 3175395
    },
    {
      "country_code": "IT",
      "id": 3165361,
      "level": 2,
      "name": "Tuscany",
      "parent_id": 3175395
    },
    {
      "country_code": "IT",
      "id": 3169778,
      "level": 2,
      "name": "Apulia",
      "parent_id": 3175395
    },
    {
      "country_code": "IT",
      "id": 3170831,
      "level": 2,
      "name": "Piedmont",
      "parent_id": 3175395
    },
    {
      "country_code": "IT",
      "id": 3174618,
      "level": 2,
      "name": "Lombardy",
      "parent_id": 3175395
    },
    {
      "country_code": "IT",
      "id": 3174976,
      "level": 2,
      "name": "Lazio",
      "parent_id": 3175395
    },
    {
      "country_code": "IT",
      "id": 3164604,
      "level": 2,
      "name": "Veneto",
      "parent_id": 3175395
    },
    {
      "country_code": "IT",
      "id": 3177401,
      "level": 2,
      "name": "Emilia Romagna",
      "parent_id": 3175395
    },
    {
      "country_code": "IT",
      "id": 2525468,
      "level": 2,
      "name": "Calabria",
      "parent_id": 3175395
    },
    {
      "country_code": "IT",
      "id": 2523228,
      "level": 2,
      "name": "Sardinia",
      "parent_id": 3175395
    },
    {
      "country_code": "IT",
      "id": 3183560,
      "level": 2,
      "name": "Abruzzo",
      "parent_id": 3175395
    },
    {
      "country_code": "IT",
      "id": 3164857,
      "level": 2,
      "name": "Aosta Valley",
      "parent_id": 3175395
    },
    {
      "country_code": "IT",
      "id": 3165048,
      "level": 2,
      "name": "Umbria",
      "parent_id": 3175395
    },
    {
      "country_code": "IT",
      "id": 3165244,
      "level": 2,
      "name": "Trentino Alto Adige",
      "parent_id": 3175395
    },
    {
      "country_code": "IT",
      "id": 3181042,
      "level": 2,
      "name": "Campania",
      "parent_id": 3175395
    },
    {
      "country_code": "IT",
      "id": 3182306,
      "level": 2,
      "name": "Basilicata",
      "parent_id": 3175395
    },
    {
      "country_code": "IT",
      "id": 3173222,
      "level": 2,
      "name": "Molise",
      "parent_id": 3175395
    },
    {
      "country_code": "IT",
      "id": 3174004,
      "level": 2,
      "name": "Marche",
      "parent_id": 3175395
    },
    {
      "country_code": "IT",
      "id": 3174725,
      "level": 2,
      "name": "Liguria",
      "parent_id": 3175395
    },
    {
      "country_code": "IT",
      "id": 3176525,
      "level": 2,
      "name": "Friuli Venezia Giulia",
      "parent_id": 3175395
    },
    {
      "country_code": "BR",
      "id": 3665474,
      "level": 2,
      "name": "Acre",
      "parent_id": 3469034
    },
    {
      "country_code": "BR",
      "id": 3408096,
      "level": 2,
      "name": "Alagoas",
      "parent_id": 3469034
    },
    {
      "country_code": "BR",
      "id": 3407762,
      "level": 2,
      "name": "Amapa",
      "parent_id": 3469034
    },
    {
      "country_code": "BR",
      "id": 3665361,
      "level": 2,
      "name": "Amazonas",
      "parent_id": 3469034
    },
    {
      "country_code": "BR",
      "id": 3471168,
      "level": 2,
      "name": "Bahia",
      "parent_id": 3469034
    },
    {
      "country_code": "BR",
      "id": 3402362,
      "level": 2,
      "name": "Ceara",
      "parent_id": 3469034
    },
    {
      "country_code": "BR",
      "id": 3463930,
      "level": 2,
      "name": "Espirito Santo",
      "parent_id": 3469034
    },
    {
      "country_code": "BR",
      "id": 3462372,
      "level": 2,
      "name": "Goias",
      "parent_id": 3469034
    },
    {
      "country_code": "BR",
      "id": 3395443,
      "level": 2,
      "name": "Maranhao",
      "parent_id": 3469034
    },
    {
      "country_code": "BR",
      "id": 3457419,
      "level": 2,
      "name": "Mato Grosso",
      "parent_id": 3469034
    },
    {
      "country_code": "BR",
      "id": 3457415,
      "level": 2,
      "name": "Mato Grosso do Sul",
      "parent_id": 3469034
    },
    {
      "country_code": "BR",
      "id": 3457153,
      "level": 2,
      "name": "Minas Gerais",
      "parent_id": 3469034
    },
    {
      "country_code": "BR",
      "id": 3393129,
      "level": 2,
      "name": "Para",
      "parent_id": 3469034
    },
    {
      "country_code": "BR",
      "id": 3393098,
      "level": 2,
      "name": "Paraiba",
      "parent_id": 3469034
    },
    {
      "country_code": "BR",
      "id": 3455077,
      "level": 2,
      "name": "Parana",
      "parent_id": 3469034
    },
    {
      "country_code": "BR",
      "id": 3392268,
      "level": 2,
      "name": "Pernambuco",
      "parent_id": 3469034
    },
    {
      "country_code": "BR",
      "id": 3392213,
      "level": 2,
      "name": "Piaui",
      "parent_id": 3469034
    },
    {
      "country_code": "BR",
      "id": 3451189,
      "level": 2,
      "name": "Rio de Janeiro",
      "parent_id": 3469034
    },
    {
      "country_code": "BR",
      "id": 3390290,
      "level": 2,
      "name": "Rio Grande do Norte",
      "parent_id": 3469034
    },
    {
      "country_code": "BR",
      "id": 3451133,
      "level": 2,
      "name": "Rio Grande do Sul",
      "parent_id": 3469034
    },
    {
      "country_code": "BR",
      "id": 3924825,
      "level": 2,
      "name": "Rondonia",
      "parent_id": 3469034
    },
    {
      "country_code": "BR",
      "id": 3662560,
      "level": 2,
      "name": "Roraima",
      "parent_id": 3469034
    },
    {
      "country_code": "BR",
      "id": 3450387,
      "level": 2,
      "name": "Santa Catarina",
      "parent_id": 3469034
    },
    {
      "country_code": "BR",
      "id": 3448433,
      "level": 2,
      "name": "Sao Paulo",
      "parent_id": 3469034
    },
    {
      "country_code": "BR",
      "id": 3447799,
      "level": 2,
      "name": "Sergipe",
      "parent_id": 3469034
    },
    {
      "country_code": "BR",
      "id": 3474575,
      "level": 2,
      "name": "Tocantins",
      "parent_id": 3469034
    },
    {
      "country_code": "BR",
      "id": 3463504,
      "level": 2,
      "name": "Distrito Federal",
      "parent_id": 3469034
    },
    {
      "country_code": "CA",
      "id": 5883102,
      "level": 2,
      "name": "Alberta",
      "parent_id": 6251999
    },
    {
      "country_code": "CA",
      "id": 5909050,
      "level": 2,
      "name": "British Columbia",
      "parent_id": 6251999
    },
    {
      "country_code": "CA",
      "id": 6065171,
      "level": 2,
      "name": "Manitoba",
      "parent_id": 6251999
    },
    {
      "country_code": "CA",
      "id": 6087430,
      "level": 2,
      "name": "New Brunswick",
      "parent_id": 6251999
    },
    {
      "country_code": "CA",
      "id": 6091069,
      "level": 2,
      "name": "Northwest Territories",
      "parent_id": 6251999
    },
    {
      "country_code": "CA",
      "id": 6091530,
      "level": 2,
      "name": "Nova Scotia",
      "parent_id": 6251999
    },
    {
      "country_code": "CA",
      "id": 6091732,
      "level": 2,
      "name": "Nunavut",
      "parent_id": 6251999
    },
    {
      "country_code": "CA",
      "id": 6093943,
      "level": 2,
      "name": "Ontario",
      "parent_id": 6251999
    },
    {
      "country_code": "CA",
      "id": 6113358,
      "level": 2,
      "name": "Prince Edward Island",
      "parent_id": 6251999
    },
    {
      "country_code": "CA",
      "id": 6115047,
      "level": 2,
      "name": "Quebec",
      "parent_id": 6251999
    },
    {
      "country_code": "CA",
      "id": 6141242,
      "level": 2,
      "name": "Saskatchewan",
      "parent_id": 6251999
    },
    {
      "country_code": "CA",
      "id": 6185811,
      "level": 2,
      "name": "Yukon",
      "parent_id": 6251999
    },
    {
      "country_code": "CA",
      "id": 6354959,
      "level": 2,
      "name": "Newfoundland and Labrador",
      "parent_id": 6251999
    },
    {
      "country_code": "US",
      "id": 6254925,
      "level": 2,
      "name": "Kentucky",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 6254928,
      "level": 2,
      "name": "Virginia",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 5879092,
      "level": 2,
      "name": "Alaska",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 5843591,
      "level": 2,
      "name": "Wyoming",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 5815135,
      "level": 2,
      "name": "Washington",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 5744337,
      "level": 2,
      "name": "Oregon",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 5769223,
      "level": 2,
      "name": "South Dakota",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 5855797,
      "level": 2,
      "name": "Hawaii",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 4436296,
      "level": 2,
      "name": "Mississippi",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 4544379,
      "level": 2,
      "name": "Oklahoma",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 4482348,
      "level": 2,
      "name": "North Carolina",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 4398678,
      "level": 2,
      "name": "Missouri",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 4331987,
      "level": 2,
      "name": "Louisiana",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 4155751,
      "level": 2,
      "name": "Florida",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 4273857,
      "level": 2,
      "name": "Kansas",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 4197000,
      "level": 2,
      "name": "Georgia",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 4862182,
      "level": 2,
      "name": "Iowa",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 5073708,
      "level": 2,
      "name": "Nebraska",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 4736286,
      "level": 2,
      "name": "Texas",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 4971068,
      "level": 2,
      "name": "Maine",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 5128638,
      "level": 2,
      "name": "New York",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 4896861,
      "level": 2,
      "name": "Illinois",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 5037779,
      "level": 2,
      "name": "Minnesota",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 5001836,
      "level": 2,
      "name": "Michigan",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 4826850,
      "level": 2,
      "name": "West Virginia",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 4829764,
      "level": 2,
      "name": "Alabama",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 4099753,
      "level": 2,
      "name": "Arkansas",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 5481136,
      "level": 2,
      "name": "New Mexico",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 5417618,
      "level": 2,
      "name": "Colorado",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 5332921,
      "level": 2,
      "name": "California",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 5667009,
      "level": 2,
      "name": "Montana",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 5690763,
      "level": 2,
      "name": "North Dakota",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 5551752,
      "level": 2,
      "name": "Arizona",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 5596512,
      "level": 2,
      "name": "Idaho",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 5279468,
      "level": 2,
      "name": "Wisconsin",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 5549030,
      "level": 2,
      "name": "Utah",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 5509151,
      "level": 2,
      "name": "Nevada",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 6254926,
      "level": 2,
      "name": "Massachusetts",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 6254927,
      "level": 2,
      "name": "Pennsylvania",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 4597040,
      "level": 2,
      "name": "South Carolina",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 4662168,
      "level": 2,
      "name": "Tennessee",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 4361885,
      "level": 2,
      "name": "Maryland",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 5165418,
      "level": 2,
      "name": "Ohio",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 4921868,
      "level": 2,
      "name": "Indiana",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 5090174,
      "level": 2,
      "name": "New Hampshire",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 5101760,
      "level": 2,
      "name": "New Jersey",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 5242283,
      "level": 2,
      "name": "Vermont",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 4831725,
      "level": 2,
      "name": "Connecticut",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 4138106,
      "level": 2,
      "name": "Washington, D.C.",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 4142224,
      "level": 2,
      "name": "Delaware",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 5224323,
      "level": 2,
      "name": "Rhode Island",
      "parent_id": 6252001
    },
    {
      "country_code": "US",
      "id": 4041685,
      "level": 2,
      "name": "Wake Island",
      "parent_id": 6252001
    }
  ]
}
