<template>
  <v-container fluid style="padding: 0; min-height: 100vh">
    <v-row no-gutters>
      <v-col cols="12">
        <GlobalNav></GlobalNav>
      </v-col>
      <v-col cols="12">
        <div class="d-flex h-100">
          <SideBar></SideBar>
          <div class="pa-5 w-100">
            <BreadCrumb :linksData="linksData" class="mb-5" />
            <div class="leads-managements-link d-flex align-center ga-2 my-3">
              <div
                @click="$router.push({ name: 'integrations' })"
                class="link cursor-pointer hoverd-style-line d-flex align-center ga-2"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.825 13L13.425 18.6L12 20L4 12L12 4L13.425 5.4L7.825 11H20V13H7.825Z"
                    fill="#1F666A"
                  />
                </svg>
                <span>Integrations</span>
              </div>
            </div>
            <div class="d-flex ga-2 align-center">
              <div class="page-image">
                <img
                  width="68"
                  height="68"
                  class="rounded-circle"
                  src="https://s3-alpha-sig.figma.com/img/e4a2/5cd3/cee97fa96562ade6511f633af5ffe684?Expires=1738540800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=EE91eWDrbooe2dnG9qhwf8yalyKgOqp3SfIgtzjw3x-HpgE4hM5P0jbnDwzaM8wSXXyWfNuOYvLuzlXvtXf37Hew~mWNuzfMNd-wRWJT5DtqjYqLxTyuIcb2tYsT8VKfEOq0WYTFsNSyvfFmrr3ecOQrqr12uW-QsR6PyalZHYb4w-~~f71DCJgQoPU4N5QsIeomN1d1mt16phBHTdemkeqx5CW8IGTQ1OCff~8z0F5fiWDhbKnTXBW2g~zu7bbKhOZG8RivpdtgQBjYFa2YmHEW6hFAa24Md-UZlFLaHFPVF6N8VgetRFUyiQsVtx1HJ4e2EV8cEoKYXmc4gq81tg__"
                  alt=""
                />
              </div>
              <div class="page-data d-flex flex-column ga-2">
                <span class="f-s-20 c-dark f-w-600">Qareacompany</span>
                <div class="d-flex ga-3 align-center">
                  <div class="d-flex align-center ga-2">
                    <span class="f-s-16 c-dark f-w-600">60K</span>
                    <span class="f-s-14 c-dark f-w-400">Followers</span>
                  </div>
                  <div class="d-flex align-center ga-2">
                    <span class="f-s-16 c-dark f-w-600">4</span>
                    <span class="f-s-14 c-dark f-w-400">Following</span>
                  </div>
                  <div class="d-flex align-center ga-2">
                    <span class="f-s-16 c-dark f-w-600">1.234</span>
                    <span class="f-s-14 c-dark f-w-400">Posts</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-5">
              <v-row>
                <v-col cols="12" :lg="6">
                  <v-sheet class="pa-5 ma-2 border rounded-lg">
                    <div class="f-s-20 c-dark f-w-600">Last 24 hours</div>
                    <v-row>
                      <v-col cols="12" :lg="6">
                        <v-sheet class="pa-2 ma-2 d-flex align-center ga-3">
                          <span>
                            <svg
                              width="42"
                              height="43"
                              viewBox="0 0 42 43"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <ellipse
                                cx="20.7571"
                                cy="21.1088"
                                rx="20.7571"
                                ry="21.058"
                                fill="#DDECED"
                                fill-opacity="0.4"
                              />
                              <g clip-path="url(#clip0_5445_122950)">
                                <path
                                  d="M24.6875 28.1133H20.9318L24.8777 22.7977C25.6733 21.737 26.0938 20.4738 26.0938 19.1445C26.0938 15.7844 23.3601 13.0508 20 13.0508C16.6399 13.0508 13.9062 15.7844 13.9062 19.1445C13.9062 20.4738 14.3268 21.737 15.1209 22.7959L19.0683 28.1133H15.3125C15.0536 28.1133 14.8438 28.3232 14.8438 28.582C14.8438 28.8409 15.0536 29.0508 15.3125 29.0508H24.6875C24.9464 29.0508 25.1562 28.8409 25.1562 28.582C25.1562 28.3232 24.9464 28.1133 24.6875 28.1133ZM15.8723 22.2352C15.1994 21.3381 14.8438 20.2693 14.8438 19.1445C14.8438 16.3014 17.1568 13.9883 20 13.9883C22.8432 13.9883 25.1562 16.3014 25.1562 19.1445C25.1562 20.2693 24.8006 21.3381 24.1264 22.2371L20 27.7956L15.8723 22.2352Z"
                                  fill="#237276"
                                />
                                <path
                                  d="M20 14.9258C17.6738 14.9258 15.7812 16.8183 15.7812 19.1445C15.7812 21.4736 17.6774 23.3633 20 23.3633C22.3144 23.3633 24.2188 21.4821 24.2188 19.1445C24.2188 16.8183 22.3262 14.9258 20 14.9258ZM20 22.4258C19.3372 22.4258 18.7199 22.2281 18.2034 21.8888C18.4369 21.1098 19.166 20.5508 20 20.5508C20.834 20.5508 21.5631 21.1098 21.7966 21.8888C21.2801 22.2281 20.6628 22.4258 20 22.4258ZM19.0625 18.6758C19.0625 18.1588 19.4831 17.7383 20 17.7383C20.5169 17.7383 20.9375 18.1588 20.9375 18.6758C20.9375 19.1927 20.5169 19.6133 20 19.6133C19.4831 19.6133 19.0625 19.1927 19.0625 18.6758ZM22.5403 21.219C22.2963 20.7062 21.8948 20.2599 21.3601 19.9644C21.6788 19.6282 21.875 19.1746 21.875 18.6758C21.875 17.6419 21.0339 16.8008 20 16.8008C18.9661 16.8008 18.125 17.6419 18.125 18.6758C18.125 19.1746 18.3212 19.6282 18.6399 19.9644C18.1056 20.2597 17.7038 20.7058 17.4597 21.219C16.9968 20.6533 16.7188 19.9308 16.7188 19.1445C16.7188 17.3352 18.1907 15.8633 20 15.8633C21.8093 15.8633 23.2812 17.3352 23.2812 19.1445C23.2812 19.9308 23.0032 20.6533 22.5403 21.219Z"
                                  fill="#237276"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_5445_122950">
                                  <rect
                                    width="16"
                                    height="16"
                                    fill="white"
                                    transform="translate(12 13.0508)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </span>
                          <span class="d-flex ga-1 flex-column">
                            <span class="f-s-16 c-dark f-w-600">100,000</span>
                            <span class="f-s-14 c-gray f-w-400"
                              >Profile visits</span
                            >
                          </span>
                        </v-sheet>
                      </v-col>
                      <v-col cols="12" :lg="6">
                        <v-sheet class="pa-2 ma-2 d-flex align-center ga-3">
                          <span>
                            <svg
                              width="42"
                              height="43"
                              viewBox="0 0 42 43"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <ellipse
                                cx="20.7571"
                                cy="21.1088"
                                rx="20.7571"
                                ry="21.058"
                                fill="#DDECED"
                                fill-opacity="0.4"
                              />
                              <g clip-path="url(#clip0_5445_122950)">
                                <path
                                  d="M24.6875 28.1133H20.9318L24.8777 22.7977C25.6733 21.737 26.0938 20.4738 26.0938 19.1445C26.0938 15.7844 23.3601 13.0508 20 13.0508C16.6399 13.0508 13.9062 15.7844 13.9062 19.1445C13.9062 20.4738 14.3268 21.737 15.1209 22.7959L19.0683 28.1133H15.3125C15.0536 28.1133 14.8438 28.3232 14.8438 28.582C14.8438 28.8409 15.0536 29.0508 15.3125 29.0508H24.6875C24.9464 29.0508 25.1562 28.8409 25.1562 28.582C25.1562 28.3232 24.9464 28.1133 24.6875 28.1133ZM15.8723 22.2352C15.1994 21.3381 14.8438 20.2693 14.8438 19.1445C14.8438 16.3014 17.1568 13.9883 20 13.9883C22.8432 13.9883 25.1562 16.3014 25.1562 19.1445C25.1562 20.2693 24.8006 21.3381 24.1264 22.2371L20 27.7956L15.8723 22.2352Z"
                                  fill="#237276"
                                />
                                <path
                                  d="M20 14.9258C17.6738 14.9258 15.7812 16.8183 15.7812 19.1445C15.7812 21.4736 17.6774 23.3633 20 23.3633C22.3144 23.3633 24.2188 21.4821 24.2188 19.1445C24.2188 16.8183 22.3262 14.9258 20 14.9258ZM20 22.4258C19.3372 22.4258 18.7199 22.2281 18.2034 21.8888C18.4369 21.1098 19.166 20.5508 20 20.5508C20.834 20.5508 21.5631 21.1098 21.7966 21.8888C21.2801 22.2281 20.6628 22.4258 20 22.4258ZM19.0625 18.6758C19.0625 18.1588 19.4831 17.7383 20 17.7383C20.5169 17.7383 20.9375 18.1588 20.9375 18.6758C20.9375 19.1927 20.5169 19.6133 20 19.6133C19.4831 19.6133 19.0625 19.1927 19.0625 18.6758ZM22.5403 21.219C22.2963 20.7062 21.8948 20.2599 21.3601 19.9644C21.6788 19.6282 21.875 19.1746 21.875 18.6758C21.875 17.6419 21.0339 16.8008 20 16.8008C18.9661 16.8008 18.125 17.6419 18.125 18.6758C18.125 19.1746 18.3212 19.6282 18.6399 19.9644C18.1056 20.2597 17.7038 20.7058 17.4597 21.219C16.9968 20.6533 16.7188 19.9308 16.7188 19.1445C16.7188 17.3352 18.1907 15.8633 20 15.8633C21.8093 15.8633 23.2812 17.3352 23.2812 19.1445C23.2812 19.9308 23.0032 20.6533 22.5403 21.219Z"
                                  fill="#237276"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_5445_122950">
                                  <rect
                                    width="16"
                                    height="16"
                                    fill="white"
                                    transform="translate(12 13.0508)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </span>
                          <span class="d-flex ga-1 flex-column">
                            <span class="f-s-16 c-dark f-w-600">100,000</span>
                            <span class="f-s-14 c-gray f-w-400"
                              >Videos Views</span
                            >
                          </span>
                        </v-sheet>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-col>
                <v-col cols="12" :lg="6">
                  <v-sheet class="pa-5 ma-2 border rounded-lg">
                    <div class="f-s-20 c-dark f-w-600">
                      Average daily results
                    </div>
                    <v-row>
                      <v-col cols="12" :lg="4">
                        <v-sheet class="pa-2 ma-2 d-flex align-center ga-3">
                          <span>
                            <svg
                              width="42"
                              height="43"
                              viewBox="0 0 42 43"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <ellipse
                                cx="20.7571"
                                cy="21.1088"
                                rx="20.7571"
                                ry="21.058"
                                fill="#DDECED"
                                fill-opacity="0.4"
                              />
                              <g clip-path="url(#clip0_5445_122950)">
                                <path
                                  d="M24.6875 28.1133H20.9318L24.8777 22.7977C25.6733 21.737 26.0938 20.4738 26.0938 19.1445C26.0938 15.7844 23.3601 13.0508 20 13.0508C16.6399 13.0508 13.9062 15.7844 13.9062 19.1445C13.9062 20.4738 14.3268 21.737 15.1209 22.7959L19.0683 28.1133H15.3125C15.0536 28.1133 14.8438 28.3232 14.8438 28.582C14.8438 28.8409 15.0536 29.0508 15.3125 29.0508H24.6875C24.9464 29.0508 25.1562 28.8409 25.1562 28.582C25.1562 28.3232 24.9464 28.1133 24.6875 28.1133ZM15.8723 22.2352C15.1994 21.3381 14.8438 20.2693 14.8438 19.1445C14.8438 16.3014 17.1568 13.9883 20 13.9883C22.8432 13.9883 25.1562 16.3014 25.1562 19.1445C25.1562 20.2693 24.8006 21.3381 24.1264 22.2371L20 27.7956L15.8723 22.2352Z"
                                  fill="#237276"
                                />
                                <path
                                  d="M20 14.9258C17.6738 14.9258 15.7812 16.8183 15.7812 19.1445C15.7812 21.4736 17.6774 23.3633 20 23.3633C22.3144 23.3633 24.2188 21.4821 24.2188 19.1445C24.2188 16.8183 22.3262 14.9258 20 14.9258ZM20 22.4258C19.3372 22.4258 18.7199 22.2281 18.2034 21.8888C18.4369 21.1098 19.166 20.5508 20 20.5508C20.834 20.5508 21.5631 21.1098 21.7966 21.8888C21.2801 22.2281 20.6628 22.4258 20 22.4258ZM19.0625 18.6758C19.0625 18.1588 19.4831 17.7383 20 17.7383C20.5169 17.7383 20.9375 18.1588 20.9375 18.6758C20.9375 19.1927 20.5169 19.6133 20 19.6133C19.4831 19.6133 19.0625 19.1927 19.0625 18.6758ZM22.5403 21.219C22.2963 20.7062 21.8948 20.2599 21.3601 19.9644C21.6788 19.6282 21.875 19.1746 21.875 18.6758C21.875 17.6419 21.0339 16.8008 20 16.8008C18.9661 16.8008 18.125 17.6419 18.125 18.6758C18.125 19.1746 18.3212 19.6282 18.6399 19.9644C18.1056 20.2597 17.7038 20.7058 17.4597 21.219C16.9968 20.6533 16.7188 19.9308 16.7188 19.1445C16.7188 17.3352 18.1907 15.8633 20 15.8633C21.8093 15.8633 23.2812 17.3352 23.2812 19.1445C23.2812 19.9308 23.0032 20.6533 22.5403 21.219Z"
                                  fill="#237276"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_5445_122950">
                                  <rect
                                    width="16"
                                    height="16"
                                    fill="white"
                                    transform="translate(12 13.0508)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </span>
                          <span class="d-flex ga-1 flex-column">
                            <span class="f-s-16 c-dark f-w-600">100,000</span>
                            <span class="f-s-14 c-gray f-w-400"
                              >Profile visits</span
                            >
                          </span>
                        </v-sheet>
                      </v-col>
                      <v-col cols="12" :lg="4">
                        <v-sheet class="pa-2 ma-2 d-flex align-center ga-3">
                          <span>
                            <svg
                              width="42"
                              height="43"
                              viewBox="0 0 42 43"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <ellipse
                                cx="20.7571"
                                cy="21.1088"
                                rx="20.7571"
                                ry="21.058"
                                fill="#DDECED"
                                fill-opacity="0.4"
                              />
                              <g clip-path="url(#clip0_5445_122950)">
                                <path
                                  d="M24.6875 28.1133H20.9318L24.8777 22.7977C25.6733 21.737 26.0938 20.4738 26.0938 19.1445C26.0938 15.7844 23.3601 13.0508 20 13.0508C16.6399 13.0508 13.9062 15.7844 13.9062 19.1445C13.9062 20.4738 14.3268 21.737 15.1209 22.7959L19.0683 28.1133H15.3125C15.0536 28.1133 14.8438 28.3232 14.8438 28.582C14.8438 28.8409 15.0536 29.0508 15.3125 29.0508H24.6875C24.9464 29.0508 25.1562 28.8409 25.1562 28.582C25.1562 28.3232 24.9464 28.1133 24.6875 28.1133ZM15.8723 22.2352C15.1994 21.3381 14.8438 20.2693 14.8438 19.1445C14.8438 16.3014 17.1568 13.9883 20 13.9883C22.8432 13.9883 25.1562 16.3014 25.1562 19.1445C25.1562 20.2693 24.8006 21.3381 24.1264 22.2371L20 27.7956L15.8723 22.2352Z"
                                  fill="#237276"
                                />
                                <path
                                  d="M20 14.9258C17.6738 14.9258 15.7812 16.8183 15.7812 19.1445C15.7812 21.4736 17.6774 23.3633 20 23.3633C22.3144 23.3633 24.2188 21.4821 24.2188 19.1445C24.2188 16.8183 22.3262 14.9258 20 14.9258ZM20 22.4258C19.3372 22.4258 18.7199 22.2281 18.2034 21.8888C18.4369 21.1098 19.166 20.5508 20 20.5508C20.834 20.5508 21.5631 21.1098 21.7966 21.8888C21.2801 22.2281 20.6628 22.4258 20 22.4258ZM19.0625 18.6758C19.0625 18.1588 19.4831 17.7383 20 17.7383C20.5169 17.7383 20.9375 18.1588 20.9375 18.6758C20.9375 19.1927 20.5169 19.6133 20 19.6133C19.4831 19.6133 19.0625 19.1927 19.0625 18.6758ZM22.5403 21.219C22.2963 20.7062 21.8948 20.2599 21.3601 19.9644C21.6788 19.6282 21.875 19.1746 21.875 18.6758C21.875 17.6419 21.0339 16.8008 20 16.8008C18.9661 16.8008 18.125 17.6419 18.125 18.6758C18.125 19.1746 18.3212 19.6282 18.6399 19.9644C18.1056 20.2597 17.7038 20.7058 17.4597 21.219C16.9968 20.6533 16.7188 19.9308 16.7188 19.1445C16.7188 17.3352 18.1907 15.8633 20 15.8633C21.8093 15.8633 23.2812 17.3352 23.2812 19.1445C23.2812 19.9308 23.0032 20.6533 22.5403 21.219Z"
                                  fill="#237276"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_5445_122950">
                                  <rect
                                    width="16"
                                    height="16"
                                    fill="white"
                                    transform="translate(12 13.0508)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </span>
                          <span class="d-flex ga-1 flex-column">
                            <span class="f-s-16 c-dark f-w-600">100,000</span>
                            <span class="f-s-14 c-gray f-w-400"
                              >Videos Views</span
                            >
                          </span>
                        </v-sheet>
                      </v-col>
                      <v-col cols="12" :lg="4">
                        <v-sheet class="pa-2 ma-2 d-flex align-center ga-3">
                          <span>
                            <svg
                              width="42"
                              height="43"
                              viewBox="0 0 42 43"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <ellipse
                                cx="20.7571"
                                cy="21.1088"
                                rx="20.7571"
                                ry="21.058"
                                fill="#DDECED"
                                fill-opacity="0.4"
                              />
                              <g clip-path="url(#clip0_5445_122950)">
                                <path
                                  d="M24.6875 28.1133H20.9318L24.8777 22.7977C25.6733 21.737 26.0938 20.4738 26.0938 19.1445C26.0938 15.7844 23.3601 13.0508 20 13.0508C16.6399 13.0508 13.9062 15.7844 13.9062 19.1445C13.9062 20.4738 14.3268 21.737 15.1209 22.7959L19.0683 28.1133H15.3125C15.0536 28.1133 14.8438 28.3232 14.8438 28.582C14.8438 28.8409 15.0536 29.0508 15.3125 29.0508H24.6875C24.9464 29.0508 25.1562 28.8409 25.1562 28.582C25.1562 28.3232 24.9464 28.1133 24.6875 28.1133ZM15.8723 22.2352C15.1994 21.3381 14.8438 20.2693 14.8438 19.1445C14.8438 16.3014 17.1568 13.9883 20 13.9883C22.8432 13.9883 25.1562 16.3014 25.1562 19.1445C25.1562 20.2693 24.8006 21.3381 24.1264 22.2371L20 27.7956L15.8723 22.2352Z"
                                  fill="#237276"
                                />
                                <path
                                  d="M20 14.9258C17.6738 14.9258 15.7812 16.8183 15.7812 19.1445C15.7812 21.4736 17.6774 23.3633 20 23.3633C22.3144 23.3633 24.2188 21.4821 24.2188 19.1445C24.2188 16.8183 22.3262 14.9258 20 14.9258ZM20 22.4258C19.3372 22.4258 18.7199 22.2281 18.2034 21.8888C18.4369 21.1098 19.166 20.5508 20 20.5508C20.834 20.5508 21.5631 21.1098 21.7966 21.8888C21.2801 22.2281 20.6628 22.4258 20 22.4258ZM19.0625 18.6758C19.0625 18.1588 19.4831 17.7383 20 17.7383C20.5169 17.7383 20.9375 18.1588 20.9375 18.6758C20.9375 19.1927 20.5169 19.6133 20 19.6133C19.4831 19.6133 19.0625 19.1927 19.0625 18.6758ZM22.5403 21.219C22.2963 20.7062 21.8948 20.2599 21.3601 19.9644C21.6788 19.6282 21.875 19.1746 21.875 18.6758C21.875 17.6419 21.0339 16.8008 20 16.8008C18.9661 16.8008 18.125 17.6419 18.125 18.6758C18.125 19.1746 18.3212 19.6282 18.6399 19.9644C18.1056 20.2597 17.7038 20.7058 17.4597 21.219C16.9968 20.6533 16.7188 19.9308 16.7188 19.1445C16.7188 17.3352 18.1907 15.8633 20 15.8633C21.8093 15.8633 23.2812 17.3352 23.2812 19.1445C23.2812 19.9308 23.0032 20.6533 22.5403 21.219Z"
                                  fill="#237276"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_5445_122950">
                                  <rect
                                    width="16"
                                    height="16"
                                    fill="white"
                                    transform="translate(12 13.0508)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </span>
                          <span class="d-flex ga-1 flex-column">
                            <span class="f-s-16 c-dark f-w-600">100,000</span>
                            <span class="f-s-14 c-gray f-w-400"
                              >Interactions</span
                            >
                          </span>
                        </v-sheet>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-col>
                <v-col cols="12">
                  <v-sheet class="pa-5 ma-2 border rounded-lg">
                    <div
                      class="f-s-20 c-dark f-w-600 d-flex align-center justify-space-between"
                    >
                      <span>Follower growth</span>
                      <span class="d-flex align-center ga-2 dashboard-card">
                        <v-select
                          v-model="follower_growth"
                          :items="filter_days"
                          item-title="title"
                          item-value="value"
                        ></v-select>
                        <v-date-input
                          prepend-icon=""
                          v-model="follower_growth_from"
                          clearable
                          label="From"
                          variant="outlined"
                        ></v-date-input>
                        <v-date-input
                          prepend-icon=""
                          v-model="follower_growth_to"
                          clearable
                          label="To"
                          variant="outlined"
                        ></v-date-input>
                      </span>
                    </div>
                    <v-row>
                      <v-col cols="12">
                        <v-sheet
                          class="pa-2 ma-2 d-flex align-center ga-3"
                          style="height: 50vh"
                        >
                          <Line :data="chartData" :options="chartOptions" />
                        </v-sheet>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-col>
                <v-col cols="12">
                  <v-sheet class="pa-5 ma-2 border rounded-lg">
                    <div
                      class="f-s-20 c-dark f-w-600 d-flex align-center justify-space-between"
                    >
                      <span>Day activity</span>
                      <span class="d-flex align-center ga-2 dashboard-card">
                        <v-date-input
                          v-model="day_activity_date"
                          clearable
                          label="From"
                          variant="outlined"
                          multiple
                        ></v-date-input>
                      </span>
                    </div>
                    <v-row>
                      <v-col cols="12">
                        <v-sheet class="pa-2 ma-2 d-flex align-center ga-3">
                          <VerticalBarChart
                            :height="'50vh'"
                            :chartData="data"
                            :chartOptions="options"
                          />
                        </v-sheet>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BreadCrumb from "@/components/layout/BreadCrumb.vue";
import GlobalNav from "@/components/layout/GlobalNav.vue";
import SideBar from "@/components/layout/SideBar.vue";
import { VDateInput } from "vuetify/labs/VDateInput";
import { Line } from "vue-chartjs";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import VerticalBarChart from "@/components/VerticalBarChart.vue";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default {
  name: "InstagramInsights",
  props: {},
  components: {
    BreadCrumb,
    GlobalNav,
    SideBar,
    VDateInput,
    Line,
    VerticalBarChart,
  },

  data() {
    return {
      follower_growth_from: null,
      follower_growth_to: null,
      filter_days: [
        { title: "Last Day", value: "last_day" },
        { title: "Last Week", value: "last_week" },
        { title: "Last Month", value: "last_month" },
        { title: "Last Year", value: "last_year" },
      ],
      day_activity_date: null,
      chartData: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        datasets: [
          {
            label: "Followers",
            data: [
              10000, 20000, 15000, 25000, 30000, 40000, 10000, 20000, 15000,
              25000, 30000, 40000,
            ],
            borderColor: "#B0E5E8",
            backgroundColor: "#B0E5E8",
            pointBackgroundColor: "white",
            pointBorderColor: "black",
            pointBorderWidth: 5,
            pointRadius: 7,
            pointHoverRadius: 9,
            borderWidth: 2,
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: "Follower Growth Trends",
            font: { size: 18 },
          },
          legend: {
            position: "top",
          },
          tooltip: {
            enabled: true,
            callbacks: {
              label: (context) =>
                `${context.dataset.label}: ${context.parsed.y}`,
            },
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: "Months",
            },
            grid: {
              display: false,
            },
          },
          y: {
            title: {
              display: true,
              text: "Number of Followers",
            },
            beginAtZero: true,
            grid: {
              color: "rgba(0, 0, 0, 0.1)",
            },
          },
        },
      },
      data: {
        labels: [
          "10 Sept",
          "11 Sept",
          "12 Sept",
          "13 Sept",
          "14 Sept",
          "15 Sept",
          "16 Sept",
          "17 Sept",
        ], // Days
        datasets: [
          {
            label: "Likes",
            data: [30, 40, 50, 30, 40, 30, 45, 52], // Number of likes each day
            backgroundColor: "#E6E6E6",
            borderColor: "transparent",
            borderWidth: 1,
            borderRadius: 15,
          },
          {
            label: "Followers",
            data: [35, 45, 35, 45, 35, 30, 45, 52], // Number of followers each day
            backgroundColor: "#237276", // Different color for clarity
            borderColor: "transparent",
            borderWidth: 1,
            borderRadius: 15,
          },
          {
            label: "Comments",
            data: [50, 55, 50, 55, 50, 30, 45, 52], // Number of comments each day
            backgroundColor: "#61CBD1", // Different color for clarity
            borderColor: "transparent",
            borderWidth: 1,
            borderRadius: 15,
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: false,
          },
        },
      },
    };
  },
  watch: {},
  computed: {},

  methods: {},

  created() {},
  mounted() {},
};
</script>
<style scoped>
.c-dark {
  color: #0d0d0d;
}
.c-gray {
  color: #666666;
}
.f-w-600 {
  font-weight: 600;
}
.f-w-400 {
  font-weight: 400;
}
.f-s-20 {
  font-size: 20px;
}
.f-s-16 {
  font-size: 16px;
}
.f-s-14 {
  font-size: 14px;
}
</style>
