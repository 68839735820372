<template>
  <div class="card-pattern meta-ad-creative">
    <div class="card-header mb-2">
      <label for="">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#007e59"
        >
          <path
            d="m423.23-309.85 268.92-268.92L650-620.92 423.23-394.15l-114-114L267.08-466l156.15 156.15ZM480.07-100q-78.84 0-148.21-29.92t-120.68-81.21q-51.31-51.29-81.25-120.63Q100-401.1 100-479.93q0-78.84 29.92-148.21t81.21-120.68q51.29-51.31 120.63-81.25Q401.1-860 479.93-860q78.84 0 148.21 29.92t120.68 81.21q51.31 51.29 81.25 120.63Q860-558.9 860-480.07q0 78.84-29.92 148.21t-81.21 120.68q-51.29 51.31-120.63 81.25Q558.9-100 480.07-100Zm-.07-60q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
          />
        </svg>
        <span class="roboto-black">Ad creative</span>
      </label>
    </div>
    <div class="card-body meta-ad-creative-body">
      <p class="roboto-regular mb-4">
        Select and optimise your ad text, media and enhancements.
      </p>
      <MediaPreview v-if="mediaPreview" :mediaPreviewImage="mediaPreviewImage"  />

      <v-select
        v-else
        class="w-50"
        v-model="selectedMediaType"
        :items="mediaTypeArray"
        density="compact"
        variant="outlined"
        return-object
        item-value="id"
        item-title="title"
        hide-details
      >
        <template v-slot:selection="{ item }">
          {{ item?.title || "" }}
        </template>
        <template v-slot:item="{ item }">
          <v-list-item @click="setmediaType(item)">
            <div class="my-form">
              <div class="ga-3">
                <input
                  :checked="
                    selectedMediaType && selectedMediaType.id === item.raw.id
                  "
                  :id="item.raw.id"
                  type="radio"
                  name="option"
                  :value="item"
                />
                <div class="d-flex align-center ga-1">
                  <span v-html="item.raw.icon" class="d-flex"> </span>
                  <label
                    :for="item.raw.id"
                    class="d-flex"
                    style="font-size: 14px"
                    >{{ item.title }}</label
                  >
                </div>
              </div>
            </div>
          </v-list-item>
        </template>
      </v-select>

      <v-dialog
        v-model="dialogUploadImage"
        class="categorise-dialog metaupload-media-dialog"
        width="1200"
      >
        <v-card max-width="1200">
          <v-card-text class="pa-0">
            <UploadImageComponent />
          </v-card-text>
          <template v-slot:actions>
            <v-btn class="ms-auto" text="Ok" @click="changeStatus"></v-btn>
          </template>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="dialogUploadVideo"
        class="categorise-dialog"
        width="1200"
      >
        <v-card max-width="1200" title="Update in progress">
          <v-card-text>
            <UploadVideoComponent />
          </v-card-text>
          <template v-slot:actions>
            <v-btn
              class="ms-auto"
              text="Ok"
              @click="dialogUploadVideo = false"
            ></v-btn>
          </template>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import MediaPreview from "./upload_media/MediaPreview.vue";
import UploadImageComponent from "./upload_media/UploadImageComponent.vue";
import UploadVideoComponent from "./upload_media/UploadVideoComponent.vue";
export default {
  components: {
    UploadImageComponent,
    UploadVideoComponent,
    MediaPreview,
  },
  name: "AdCreative",
  data() {
    return {
      selectedMediaType: {
        id: 3,
        title: "Set up creative",
        icon: ``,
      },
      dialogUploadImage: false,
      dialogUploadVideo: false,
      mediaTypeArray: [
        {
          id: 0,
          checked: false,
          title: "Image ad",
          value: "image_ad",
          icon: `<svg width="20px" height="20px" fill="#5f6368" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M160 32c-35.3 0-64 28.7-64 64l0 224c0 35.3 28.7 64 64 64l352 0c35.3 0 64-28.7 64-64l0-224c0-35.3-28.7-64-64-64L160 32zM396 138.7l96 144c4.9 7.4 5.4 16.8 1.2 24.6S480.9 320 472 320l-144 0-48 0-80 0c-9.2 0-17.6-5.3-21.6-13.6s-2.9-18.2 2.9-25.4l64-80c4.6-5.7 11.4-9 18.7-9s14.2 3.3 18.7 9l17.3 21.6 56-84C360.5 132 368 128 376 128s15.5 4 20 10.7zM192 128a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM48 120c0-13.3-10.7-24-24-24S0 106.7 0 120L0 344c0 75.1 60.9 136 136 136l320 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-320 0c-48.6 0-88-39.4-88-88l0-224z"/></svg>`,
        },
        {
          id: 1,
          checked: false,
          title: "Video ad",
          value: "video_ad",
          icon: `<svg width="20px" height="20px" fill="#5f6368" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.--><path d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z"/></svg>`,
        },
      ],
      mediaPreview: false,
      mediaPreviewImage: "",
    };
  },
  methods: {
    setmediaType(item) {
      this.selectedMediaType = item.raw;
      localStorage.setItem("selected_media_type", item.raw.value);
      if (item.raw.value === "image_ad") {
        this.dialogUploadImage = true;
        this.dialogUploadVideo = false;
      } else if (item.raw.value === "video_ad") {
        this.dialogUploadImage = false;
        this.dialogUploadVideo = true;
      }
    },
    changeStatus() {
      this.dialogUploadImage = false;
      this.mediaPreview = true;
      this.mediaPreviewImage = localStorage.getItem("cropped_images_preview");
    },
  },
};
</script>
