<template>
  <v-container class="select-account-id">
    <v-row>
      <v-col cols="12" class="header-account-id">
        <img src="@/assets/images/meta/CnOoIyhtLSO.svg" alt="" />
        <h2 style="color: #2270d4">Select Account ID</h2>
      </v-col>
      <v-col cols="12">
        <button v-if="!adAccounts.length"  @click="redirectUrl" class="login-btn" >Login</button>
      </v-col>
      <v-col cols="12">
        <div
          class="card"
          @click="setAdAccountId(adAccount.account_id)"
          v-for="adAccount in adAccounts"
          :key="adAccount.id"
        >
          <div class="card-left-side">
            <img src="@/assets/images/meta/user.png" alt="" />
          </div>
          <div class="card-right-side">
            <p class="account-name">{{ adAccount.name }}</p>
            <p class="account-id">{{ adAccount.account_id }}</p>
            <p class="business-name">{{ adAccount.business?.name }}</p>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiClient from "@/core/buildRequest";
export default {
  data() {
    return {
      adAccounts: [],
    };
  },
  async mounted() {
    if (localStorage.getItem("facebook_token")) {
      await apiClient
        .get("/campaign-ads/facebook/ad-account")
        .then((response) => {
          this.adAccounts = response.data.data;
          console.log(this.adAccounts);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  methods: {
    redirectUrl() {
      console.log(":asd");
      if (localStorage.getItem("facebook_token")) {
        this.$router.push({ name: "SelectAcountId" });
      } else {
        apiClient.get("/auth/facebook").then((response) => {
          window.open(response.data.data.redirect_url);
        });
      }
    },
    setAdAccountId(accountId) {
      localStorage.setItem("meta_ad_account_id", accountId);
      this.$router.push("/meta");
    },
  },
};
</script>

<style scoped>
.select-account-id .v-row .v-col .card {
  display: flex;
  gap: 10px;
  padding: 10px;
  border: 1px solid transparent;
  margin-bottom: 10px;
  border-radius: 12px;
  background-color: #fff;
  cursor: pointer;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 50%;
  margin: 0 auto;
  margin-bottom: 10px;
}
.select-account-id .v-row .v-col .card .card-left-side img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.select-account-id .v-row .header-account-id {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  justify-content: center;
}
.select-account-id .v-row .header-account-id img {
  width: 50px;
  height: 50px;
}
.select-account-id .v-row .v-col .card .card-right-side p.account-name {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 10px;
}
.select-account-id .v-row .v-col .login-btn{
  background-color: #2270d4;
  color: #fff;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 6px;
  border: 1px solid transparent;
  cursor: pointer;
  margin: 0 auto;
  display: block;
}
</style>
