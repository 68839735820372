<template>
  <Layout :linksData="linksData">
    <v-row class="leads-managements w-100">
      <v-col cols="12" :lg="6" class="header-line-title"> Projects </v-col>
      <v-col cols="12" :lg="6" class="d-flex align-center justify-end ga-2">
        <Top :projects="projects" />
      </v-col>
    </v-row>
    <FilterSection
      :selected_rows="selected_projects"
      @update:selected_rows="selected_projects = $event"
      :menu="['Event', 'Deals', 'Task']"
      :assign_to_account_menu="false"
      :parent="'projects'"
      :dashboard_path_name="'ProjectsDashboard'"
    />
    <div class="table mt-3">
      <DataTable
        @update:selected_projects="selected_projects = $event"
        :projects="projects"
      />
    </div>
  </Layout>
</template>
<script>
import Layout from "@/components/crm/layouts/Layout.vue";
import activityLogIcon from "@/assets/images/icons/activity-log.svg";
import discussionsIcon from "@/assets/images/icons/discussions.svg";
import importIcon from "@/assets/images/icons/import.svg";
import FilterSection from "@/components/crm/assets/Filter.vue";
import Top from "@/components/crm/assets/Top.vue";
import DataTable from "@/components/crm/projects/table.vue";
import "@/assets/css/crm/crm-moduls.css";
import { useProjectsStore } from "@/store/crm/useProjectsStore";

export default {
  components: {
    Layout,
    FilterSection,
    Top,
    DataTable,
  },

  data() {
    return {
      selected_projects: [],
      activityLogIcon,
      discussionsIcon,
      importIcon,
      linksData: [
        { name: "Home", link: "/home-page" },
        { name: "Projects Management", link: "" },
      ],
      store: null,
    };
  },
  computed: {
    projects() {
      if (this.store?.getProjects.length === 0) {
        this.store?.getAllProjects();
      }
      return this.store?.getProjects || [];
    },
  },
  created() {
    this.store = useProjectsStore();
    console.log(this.store.projects);
  },
};
</script>
