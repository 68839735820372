<template>
  <svg
    width="17"
    height="16"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.35675 0.964416C5.4955 1.15731 3.9219 1.87812 2.63933 3.12685C1.42444 4.3079 0.707008 5.68523 0.395671 7.44157C0.28738 8.05409 0.28738 9.19792 0.395671 9.81044C0.696856 11.4957 1.35337 12.8087 2.48366 13.9695C3.47181 14.9847 4.6664 15.6886 5.99973 16.0406C6.76454 16.2402 7.15032 16.2876 7.98281 16.2876C8.8153 16.2876 9.20109 16.2402 9.96589 16.0406C12.0606 15.489 13.8745 14.0101 14.8458 12.0575C15.2011 11.3502 15.4143 10.6802 15.57 9.81044C15.6342 9.45511 15.6444 9.26222 15.6444 8.62601C15.6444 7.9898 15.6342 7.7969 15.57 7.44157C15.2688 5.75629 14.6123 4.44327 13.482 3.28252C12.0471 1.80367 10.1859 0.998257 8.1351 0.961032C7.78315 0.954264 7.4312 0.957647 7.35675 0.964416ZM8.65286 2.19961C11.1909 2.47372 13.3364 4.20299 14.1216 6.6057C15.059 9.46188 13.8982 12.5956 11.3297 14.1556C8.78823 15.6954 5.51919 15.2961 3.41428 13.1945C0.889749 10.6666 0.889749 6.5854 3.41428 4.05748C4.79161 2.68354 6.74085 1.9898 8.65286 2.19961Z"
      fill="#666666"
    />
    <path
      d="M7.42197 3.98962C6.35598 4.14191 5.43889 4.59199 4.69439 5.32972C4.05141 5.96932 3.64532 6.70705 3.42874 7.63429C3.34414 7.98962 3.33398 8.09791 3.33398 8.62583C3.33398 9.15375 3.34414 9.26204 3.42874 9.61737C3.4795 9.83734 3.55733 10.1148 3.60471 10.2333C4.44058 12.4634 6.81283 13.7257 9.08356 13.1571C9.94312 12.9406 10.647 12.5446 11.2697 11.9253C11.9127 11.2891 12.3221 10.5412 12.5387 9.61737C12.6233 9.26204 12.6335 9.15375 12.6335 8.62583C12.6335 8.09791 12.6233 7.98962 12.5387 7.63429C12.3221 6.70705 11.916 5.96932 11.2731 5.32972C10.4034 4.46678 9.33398 4.00654 8.11909 3.9727C7.84837 3.96593 7.53703 3.9727 7.42197 3.98962ZM8.91436 4.95071C9.5641 5.11653 10.3018 5.54969 10.735 6.02008C11.1614 6.48032 11.5032 7.09622 11.6622 7.69521C11.7435 7.99978 11.7502 8.09115 11.7502 8.62583C11.7502 9.16052 11.7435 9.25189 11.6622 9.55646C11.4863 10.213 11.0565 10.9473 10.5895 11.3771C10.1292 11.8035 9.51334 12.1453 8.91436 12.3043C8.60979 12.3856 8.51842 12.3923 7.98373 12.3923C7.44904 12.3923 7.35767 12.3856 7.0531 12.3043C6.45412 12.1453 5.83821 11.8035 5.37798 11.3771C4.91097 10.9473 4.48119 10.213 4.30522 9.55646C4.224 9.25189 4.21723 9.16052 4.21723 8.62583C4.21723 8.09115 4.224 7.99978 4.30522 7.69521C4.46427 7.09622 4.80607 6.48032 5.23246 6.02008C5.76038 5.44817 6.62671 4.9947 7.42535 4.86949C7.79084 4.81534 8.53534 4.85257 8.91436 4.95071Z"
      fill="#666666"
    />
  </svg>
</template>

<script>
export default {
  name: "StatusIcon",
};
</script>
