<template>
  <v-container
    fluid
    style="margin-top: 0%; padding: 0% 0% 0 0; min-height: calc(100vh - 58px)"
  >
    <v-row style="height: 100%; margin: 0; padding: 0; position: relative">
      <v-col cols="12" class="pa-0">
        <GlobalNav />
      </v-col>
      <v-col
        cols="12"
        style="height: 100%"
        class="pa-0 d-flex align-start ga-6"
      >
        <SideBar />
        <div class="w-100 pa-3 is-light-mode" style="height: 87vh">
          <BreadCrumb :linksData="linksData" class="mb-3" />
          <div class="add-task">
            <a @click="this.$router.push('/create-task')">Add Task</a>
          </div>
          <Qalendar :events="events" :config="config"> </Qalendar>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { Qalendar } from "qalendar";
import "qalendar/dist/style.css";
import GlobalNav from "@/components/layout/GlobalNav.vue";
import SideBar from "@/components/layout/SideBar.vue";
import BreadCrumb from "@/components/layout/BreadCrumb.vue";
import "@/assets/css/calender/style.css";

export default {
  components: {
    Qalendar,
    GlobalNav,
    SideBar,
    BreadCrumb,
  },

  data() {
    return {
      linksData: [
        { name: "Home", link: "/" },
        { name: "Your Calender", link: "" },
      ],
      events: [
        {
          title: "Facebook Campaign Kickoff",
          with: "Marketing Team",
          time: { start: "2024-12-04 09:00", end: "2024-12-04 10:30" },
          color: "green",
          isEditable: true,
          id: "1a2b3c4d5e6f",
          description:
            "Launching the new holiday season campaign targeting ages 25-40. Includes A/B testing for ad creatives and initial performance tracking.",
        },
        {
          title: "Instagram Post Schedule",
          with: "Content Creator Team",
          time: { start: "2024-12-05 14:00", end: "2024-12-05 15:00" },
          color: "blue",

          isEditable: true,
          id: "2b3c4d5e6f7g",
          description:
            "Publishing carousel post featuring holiday deals. Finalizing captions, hashtags, and story highlights.",
        },
        {
          title: "Google Ads Campaign Launch",
          with: "Digital Ads Specialist",
          time: { start: "2024-12-05 10:00", end: "2024-12-05 11:00" },
          color: "red",
          isEditable: true,
          id: "3c4d5e6f7g8h",
          description:
            "Starting PPC campaign for winter clearance sale. Review of target keywords and demographic segmentation.",
        },
        {
          title: "Email Newsletter Blast",
          with: "Email Marketing Team",
          time: { start: "2024-12-05 08:00", end: "2024-12-05 08:30" },
          color: "orange",
          isEditable: true,
          id: "4d5e6f7g8h9i",
          description:
            "Sending out a newsletter to the subscriber list with exclusive holiday discounts and early-bird deals.",
        },
        {
          title: "Twitter Space: Marketing AMA",
          with: "Social Media Manager",
          time: { start: "2024-12-08 17:00", end: "2024-12-08 18:00" },
          color: "cyan",
          isEditable: true,
          id: "5e6f7g8h9i0j",
          description:
            "Hosting a Twitter Space to discuss our marketing strategies and answer live questions from followers.",
        },
        {
          title: "TikTok Video Campaign",
          with: "Creative Video Team",
          time: { start: "2024-12-09 15:00", end: "2024-12-09 16:30" },
          color: "pink",
          isEditable: true,
          id: "6f7g8h9i0j1k",
          description:
            "Uploading a series of engaging videos to TikTok featuring brand collaborations and holiday promotions.",
        },
        {
          title: "LinkedIn Sponsored Post",
          with: "B2B Marketing Team",
          time: { start: "2024-12-10 11:00", end: "2024-12-10 11:45" },
          color: "teal",
          isEditable: true,
          id: "7g8h9i0j1k2l",
          description:
            "Launching a sponsored post aimed at corporate clients highlighting our end-of-year services.",
        },
        {
          title: "YouTube Ad Launch",
          with: "Video Production Team",
          time: { start: "2024-12-11 13:00", end: "2024-12-11 13:45" },
          color: "lime",
          isEditable: true,
          id: "8h9i0j1k2l3m",
          description:
            "Publishing a 30-second ad targeting holiday shoppers. Reviewing analytics setup for performance monitoring.",
        },
        {
          title: "Pinterest Pin Design Review",
          with: "Graphic Design Team",
          time: { start: "2024-12-12 10:00", end: "2024-12-12 11:30" },
          color: "violet",
          isEditable: true,
          id: "9i0j1k2l3m4n",
          description:
            "Finalizing visual assets for Pinterest campaign promoting holiday gift guides.",
        },
      ],
      config: {
        showWeekend: true,
        timeFormat: "12",
        week: {
          startsOn: "monday",
          nDays: 7,
          scrollToHour: 5,
        },
        month: {
          showTrailingAndLeadingDates: false,
        },
        locale: "eg-EG",
        defaultMode: "month",
        isSilent: true,
      },
    };
  },
};
</script>
