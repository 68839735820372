<template>
  <v-container
    fluid
    style="margin-top: 0%; padding: 0% 0% 0 0; min-height: calc(100vh - 58px)"
  >
    <v-row style="height: 100%; margin: 0; padding: 0; position: relative">
      <v-col cols="12" class="pa-0">
        <GlobalNav />
      </v-col>
      <v-col
        cols="12"
        style="height: 100%"
        class="pa-0 d-flex align-start ga-6"
      >
        <SideBar />
        <div class="w-100 pa-3 is-light-mode" style="height: 87vh">
          <BreadCrumb :linksData="linksData" class="mb-4" />
          <v-row class="mb-4">
            <v-col :lg="6" :md="12" :sm="12">
              <span class="profile-box-main-text">My Account</span>
            </v-col>
            <v-col :lg="6" :md="12" :sm="12" class="d-flex justify-end">
              <button
                style="width: auto"
                @click="
                  $router.push({
                    name: 'MyAccountEdit',
                    params: { id: 1 },
                  })
                "
                class="choose-btn d-flex align-center ga-2"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.33333 12.6667H4.28333L10.8 6.15L9.85 5.2L3.33333 11.7167V12.6667ZM2 14V11.1667L10.8 2.38333C10.9333 2.26111 11.0806 2.16667 11.2417 2.1C11.4028 2.03333 11.5722 2 11.75 2C11.9278 2 12.1 2.03333 12.2667 2.1C12.4333 2.16667 12.5778 2.26667 12.7 2.4L13.6167 3.33333C13.75 3.45556 13.8472 3.6 13.9083 3.76667C13.9694 3.93333 14 4.1 14 4.26667C14 4.44444 13.9694 4.61389 13.9083 4.775C13.8472 4.93611 13.75 5.08333 13.6167 5.21667L4.83333 14H2ZM10.3167 5.68333L9.85 5.2L10.8 6.15L10.3167 5.68333Z"
                    fill="#D9D9D9"
                  />
                </svg>

                Edit Account Info
              </button>
            </v-col>
          </v-row>
          <div class="d-flex flex-column w-100 ga-5">
            <ProfilePicture :profile="profile" />
            <BasicInfo :profile="profile" />
            <PaymentMethods :profile="profile" />
            <ContactInfo :profile="profile" />
            <Addresses :profile="profile" />
            <v-row>
              <v-col lg="6" md="12" sm="12">
                <div class="pa-3 w-100 h-100">
                  <Password :profile="profile" />
                </div>
              </v-col>
              <v-col lg="6" md="12" sm="12">
                <div class="pa-3 w-100 h-100">
                  <General :profile="profile" />
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import GlobalNav from "@/components/layout/GlobalNav.vue";
import SideBar from "@/components/layout/SideBar.vue";
import BreadCrumb from "@/components/layout/BreadCrumb.vue";
import ProfilePicture from "@/components/myAccount/index/ProfilePicture.vue";
import Addresses from "@/components/myAccount/index/Addresses.vue";
import PaymentMethods from "@/components/myAccount/index/PaymentMethods.vue";
import General from "@/components/myAccount/index/General.vue";
import Password from "@/components/myAccount/index/Password.vue";
import BasicInfo from "@/components/myAccount/index/BasicInfo.vue";
import ContactInfo from "@/components/myAccount/index/ContactInfo.vue";
import "@/assets/css/my_account/style.css";
import apiClient from "@/core/buildRequest";

export default {
  name: "MyAccountIndex",
  components: {
    GlobalNav,
    SideBar,
    BreadCrumb,
    ProfilePicture,
    Addresses,
    PaymentMethods,
    General,
    Password,
    BasicInfo,
    ContactInfo,
  },
  data() {
    return {
      linksData: [
        { name: "Home", link: "/" },
        { name: "My Account", link: "" },
      ],
      profile: [],
    };
  },
  beforeCreate() {},
  methods: {
    getProfile() {
      apiClient
        .get("/my-account/get/account")
        .then((response) => {
          this.profile = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getProfile();
  },
};
</script>
