<template>
  <div class="d-flex flex-column ga-5 mt-5">
    <span class="profile-box-second-text text-caption d-flex align-center ga-2">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.66732 10.0013L10.0007 13.3346M10.0007 13.3346L13.334 10.0013M10.0007 13.3346L10.0007 5.33464C10.0007 4.62739 9.7197 3.94911 9.2196 3.44902C8.71951 2.94892 8.04123 2.66797 7.33398 2.66797L2.66732 2.66797"
          stroke="#999999"
          stroke-width="1.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span @click="CopyShipping" class="cursor-pointer"
        >Copy Billing Address</span
      ></span
    >
    <div class="d-flex ga-3 flex-column billing-select">
      <label for="Country"
        >Country <span class="text-red-accent-4">*</span></label
      >
      <v-autocomplete
        v-model="country"
        :items="countries"
        item-value="name"
        item-title="name"
        hide-details
        label=""
      >
      </v-autocomplete>
      <span
        class="text-red-accent-4 text-caption small-text mt-2"
        v-if="country_error"
        >{{ country_error }}</span
      >
    </div>
    <div class="d-flex ga-3 flex-column billing-select">
      <label for="Country">City <span class="text-red-accent-4">*</span></label>
      <v-autocomplete
        v-model="city"
        :items="cities"
        item-value="name"
        item-title="name"
        hide-details
        label=""
      >
      </v-autocomplete>
      <span
        class="text-red-accent-4 text-caption small-text mt-2"
        v-if="city_error"
        >{{ city_error }}</span
      >
    </div>
    <div class="d-flex ga-3 flex-column billing-select">
      <label for="Country">State</label>
      <Input v-model="state" type="text" placeholder="State" />
    </div>

    <div class="d-flex ga-3 flex-column billing-select">
      <label for="Country">Street 1</label>
      <Input v-model="street_1" type="text" placeholder="Street 1" />
    </div>
    <div class="d-flex ga-3 flex-column billing-select">
      <label for="Country">Street 2</label>
      <Input v-model="street_2" type="text" placeholder="Street 2" />
    </div>
    <div class="d-flex ga-3 flex-column billing-select">
      <label for="Country">Zip</label>
      <Input v-model="zip" type="text" placeholder="Zip" />
    </div>
    <div class="d-flex justify-end align-center ga-4 mt-3">
      <span class="Cancel-btn cursor-pointer" @click="Cancel()">Cancel</span>
      <span class="choose-btn cursor-pointer" @click="Update()">Update</span>
    </div>
  </div>
  <v-snackbar
    v-model="snackbar.show"
    :timeout="snackbar.timeout"
    :color="snackbar.color"
    location="top"
    close-on-content-click
  >
    {{ snackbar.message }}
  </v-snackbar>
</template>
<script>
import Input from "@/components/crm/assets/Input.vue";
import apiClient from "@/core/buildRequest";

export default {
  name: "ShippingAddress",
  components: { Input },
  props: ["profile"],
  data() {
    return {
      country: "",
      countries: [],
      city: "",
      cities: [],
      state: "",
      street_1: "",
      street_2: "",
      zip: "",
      snackbar: {
        color: "",
        show: false,
        message: "",
        timeout: 3000,
      },
      country_error: "",
      city_error: "",
    };
  },
  methods: {
    showSnackbar(message, color) {
      this.snackbar = { message, color, show: true, timeout: 3000 };
    },
    Cancel() {
      this.$emit("close");
    },
    Update() {
      this.country_error = "";
      this.city_error = "";

      if (!this.country || !this.city) {
        if (!this.country) {
          this.country_error = "Country is required";
        }
        if (!this.city) {
          this.city_error = "City is required";
        }
        this.showSnackbar("Country and City are required", "error");
        return;
      }
      apiClient
        .post("/my-account/update/address", {
          country: this.country,
          city: this.city,
          state: this.state,
          street_1: this.street_1,
          street_2: this.street_2,
          zip: this.zip,
          type: "billing",
        })
        .then((response) => {
          this.showSnackbar("Address Update Successfully", "success");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    CopyShipping() {
      this.country = this.profile?.shipping_address?.country;
      this.city = this.profile?.shipping_address?.city;
      this.state = this.profile?.shipping_address?.state;
      this.street_1 = this.profile?.shipping_address?.street_1;
      this.street_2 = this.profile?.shipping_address?.street_2;
      this.zip = this.profile?.shipping_address?.zip;
    },
    getAllCountries() {
      apiClient
        .get("/leads-managment/countries")
        .then((response) => {
          this.countries = response.data.data.map((item) => {
            return { id: item.id, name: item.name.en };
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAllCities() {
      let country_id =
        this.countries && this.countries.length > 0
          ? this.countries.find((item) => item.name === this.country)?.id
          : null;
      if (!country_id) {
        return;
      }
      apiClient
        .get(`/leads-managment/cities/${country_id}`)
        .then((response) => {
          this.cities = response.data.data.map((item) => {
            return { id: item.id, name: item.name.en };
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    if (this.profile) {
      this.country = this.profile?.billing_address?.country;
      this.city = this.profile?.billing_address?.city;
      this.state = this.profile?.billing_address?.state;
      this.street_1 = this.profile?.billing_address?.street_1;
      this.street_2 = this.profile?.billing_address?.street_2;
      this.zip = this.profile?.billing_address?.zip;
    }
    this.getAllCountries();
  },
  watch: {
    country(val) {
      this.getAllCities();
    },
    profile(val) {
      this.country = this.profile?.billing_address?.country;
      this.city = this.profile?.billing_address?.city;
      this.state = this.profile?.billing_address?.state;
      this.street_1 = this.profile?.billing_address?.street_1;
      this.street_2 = this.profile?.billing_address?.street_2;
      this.zip = this.profile?.billing_address?.zip;
    },
  },
};
</script>
