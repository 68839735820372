<template>
  <div class="header">
    <img :src="xHeader" alt="" />
  </div>
  <div class="">
    <img
      v-if="
        post.file.file &&
        post.file.file.type &&
        post.file.file.type.startsWith('image/')
      "
      :src="post.file.preview"
      alt="Post Image"
      style="border-radius: 24px"
      width="100%"
      height="322px"
    />
    <video
      v-if="
        post.file.file &&
        post.file.file.type &&
        post.file.file.type.startsWith('video/')
      "
      style="border-radius: 24px"
      width="100%"
      height="322px"
      :src="post.file.preview"
      autoplay
      muted
      loop
    ></video>
  </div>
  <div class="footer">
    <svg
      width="339"
      height="4"
      viewBox="0 0 339 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="0.502967"
        y1="1.20704"
        x2="338.503"
        y2="3.21297"
        stroke="#CFDADE"
      />
    </svg>

    <img :src="xFooter" alt="" class="mt-2" />
  </div>
</template>
<script>
import xHeader from "@/components/publish_post_components/reviews/svgs/xHeader.svg";
import xFooter from "@/components/publish_post_components/reviews/svgs/xFooter.svg";
import { publishPost } from "@/store/publishPost/publishPost.js";

export default {
  name: "XPostReview",
  components: {},
  data() {
    return {
      xHeader,
      xFooter,
      store: publishPost(),
      post: {},
    };
  },
  watch: {
    "store.getCarouselSlider": {
      handler() {
        this.post = this.store.post["x"].find((item) => item.type === "post");
      },
      immediate: true,
    },
  },
};
</script>

<style></style>
