<template>
  <div class="placement-card content-exclusions">
    <div class="card-header">
      <p class="card-title">Content exclusions</p>
    </div>
    <div class="card-body">
      <p class="card-description mt-6">
        The brand safety hub will apply any previous account level content
        exclusion settings. To change these settings, visit the
        <a href="#">Brand safety hub.</a>
      </p>
      <div class="inventory-filter mb-8 mt-6">
        <h5>Inventory filter</h5>
        <p>
          Limited inventory
          <v-btn @click="dialog = true" elevation="0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="15px"
              viewBox="0 -960 960 960"
              width="15px"
              fill="#6d6e70"
            >
              <path
                d="M96 0v-112h768V0H96Zm168-326.92h37.92l319-319.77-18.3-20.08-18.85-17.08-319.77 319v37.93Zm-32 32v-82.92l402.77-403.62q6.38-6.39 12.3-8.31 5.91-1.92 11.93-1.92 6.03 0 11.4 1.92t11.09 7.48l37.05 36.44q5.61 6.39 7.92 11.82 2.31 5.44 2.31 11.76 0 5.93-2.38 12.18-2.37 6.25-7.55 11.41L315-294.92h-83Zm463.77-428.85-37.92-35.92 37.92 35.92Zm-74.85 77.08-18.3-20.08-18.85-17.08 37.15 37.16Z"
              />
            </svg>
            <span>Edit</span>
          </v-btn>
        </p>
        <v-dialog class="edit-inventory" v-model="dialog" width="auto">
          <v-card
            max-width="1040"
            text="The tier you select will apply to placements in the For You, Following, and Profile Page feeds, as well as Search feed, where ads appear next to search results being viewed. Choose the tier most suitable for your brand."
            title="Edit inventory tier"
          >
            <div class="card-body px-6 py-2">
              <div class="container">
                <div class="radio-tile-group">
                  <div class="input-container">
                    <input
                      id="walk"
                      class="radio-button"
                      type="radio"
                      name="radio"
                      value="expandedInventory"
                      @change="editInventoryStatus"
                      v-model="editInventory"
                    />
                    <div class="radio-tile">
                      <label for="walk" class="radio-tile-label"
                        >Expanded inventory</label
                      >
                      <p class="description">
                        Your ads will not appear next to explicitly
                        inappropriate content, but they may appear next to
                        content that features mature themes.
                      </p>
                      <h4>Expanded reach</h4>
                      <div class="progress-liner">
                        <span style="width: 75%"></span>
                      </div>
                      <h4 style="color: #6d6e70; font-size: 12px">
                        Exclusion examples:
                      </h4>
                      <ul>
                        <li>Graphic violence</li>
                        <li>Nudity and graphic sexual content</li>
                        <li>Content related to war or terrorism</li>
                        <li>
                          Content disrespecting religion or national culture
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="input-container">
                    <input
                      @change="editInventoryStatus"
                      id="bike"
                      class="radio-button"
                      type="radio"
                      name="radio"
                      value="standardInventory "
                      v-model="editInventory"
                    />
                    <div class="radio-tile">
                      <label for="bike" class="radio-tile-label"
                        >Standard inventory
                      </label>
                      <p class="description">
                        Your ads will appear next to content that is appropriate
                        for most brands and may contain some mature themes.
                      </p>
                      <h4>Normal reach</h4>
                      <div class="progress-liner">
                        <span style="width: 75%"></span>
                      </div>
                      <h4 style="color: #6d6e70; font-size: 12px">
                        Exclusion examples:
                      </h4>
                      <ul>
                        <li>Moderate profanity</li>
                        <li>Moderate sexual scenarios and themes</li>
                        <li>All Expanded inventory exclusions</li>
                      </ul>
                    </div>
                  </div>
                  <div class="input-container">
                    <input
                      id="drive"
                      class="radio-button"
                      type="radio"
                      name="radio"
                      value="limitedInventory"
                      @change="editInventoryStatus"
                      v-model="editInventory"
                    />
                    <div class="radio-tile">
                      <label for="drive" class="radio-tile-label"
                        >Limited inventory
                      </label>
                      <p class="description">
                        Your ads will not appear next to content that contains
                        mature themes.
                      </p>
                      <h4>Limited reach</h4>
                      <div class="progress-liner">
                        <span style="width: 75%"></span>
                      </div>
                      <h4 style="color: #6d6e70; font-size: 12px">
                        Exclusion examples:
                      </h4>
                      <ul>
                        <li>Mild sexual content</li>
                        <li>Mild violence</li>
                        <li>All Expanded and Standard inventory exclusions</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="instractions">
                <p>
                  All inventory within either Expanded, Standard or Limited
                  inventory excludes content that TikTok is aware violates
                  either TikTok's Community Guidelines, Intellectual Property
                  Policy or Terms of Service. Learn more
                </p>
                <p>
                  We'll use reasonable efforts to exclude content consistent
                  with your tier selection but we cannot guarantee the accuracy
                  of our content exclusions.
                </p>
              </div>
            </div>
            <template v-slot:actions>
              <v-btn class="ms-auto" text="Ok" @click="dialog = false"></v-btn>
            </template>
          </v-card>
        </v-dialog>
      </div>
      <div class="inventory-filter-container">
        <label for="">Category exclusions <span>(optional)</span> </label>
        <p class="mb-2">
          Select one or more specific categories of content you want to avoid
          showing your ads next to. Learn more
        </p>
        <treeselect
          :options="options"
          :multiple="true"
          :flatten-search-results="true"
          class="mb-5"
          placeholder="Select saved audience"
        />

        <label for="">Vertical sensitivity <span>(optional)</span> </label>
        <p class="mb-2">
          Select your vertical to exclude sensitive vertical-related content
          that you want to avoid showing your ads next to.
        </p>
        <treeselect
          :options="verticalSensitivity"
          :multiple="false"
          :flatten-search-results="true"
          class="mb-5"
          placeholder="Select saved audience"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Treeselect from "vue3-treeselect";
export default {
  components: {
    Treeselect,
  },
  data() {
    return {
      dialog: false,
      editInventory: [],
      options: [
        {
          id: "Combat",
          label: "Combat sports",
        },
        {
          id: "Gambling",
          label: "Gambling and lotteries",
        },
        {
          id: "Violent",
          label: "Violent video games",
        },
        {
          id: "Youth",
          label: "Youth content",
        },
      ],
      verticalSensitivity: [
        {
          id: "Combat",
          label: "Combat sports",
          description: "Includes boxing, wrestling, and MMA.",
        },
        {
          id: "Gambling",
          label: "Gambling and lotteries",
          description: "Covers online gambling, casinos, and lottery games.",
        },
        {
          id: "Violent",
          label: "Violent video games",
          description: "Games with intense violence or gore.",
        },
        {
          id: "Youth",
          label: "Youth content",
          description: "Content specifically aimed at younger audiences.",
        },
      ],
    };
  },
  mounted() {
    if (localStorage.getItem("editInventory")) {
      this.editInventory = localStorage.getItem("editInventory");
    }
  },
  methods: {
    editInventoryStatus() {
      localStorage.setItem("editInventory", this.editInventory);
    },
    itemProps(item) {
      return {
        title: item.name,
        subtitle: item.department,
      };
    },
  },
};
</script>
