<template>
  <div class="profile-box-container w-100">
    <div class="d-flex justify-space-between align-items-center w-100 mb-3">
      <label for="" class="profile-box-title">Contact Info.</label>
    </div>
    <div class="d-flex align-items-center justify-space-between py-5">
      <div class="profile-box-second-text">Email</div>
      <div class="profile-box-second-text">{{ profile.email }}</div>
    </div>
    <v-divider></v-divider>
    <div class="d-flex align-items-center justify-space-between py-5">
      <div class="profile-box-second-text">Phone number</div>
      <div class="profile-box-second-text">{{ profile.mobile }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Addresses",
  components: {},
  props: ["profile"],
  data() {
    return {};
  },
  beforeCreate() {},
  methods: {},
};
</script>
