<template>
  <div class="pa-4 dashboard-card" elevation="2">
    <div class="d-flex justify-space-between">
      <span class="text-subtitle-1 font-weight-bold">Leads Per Platform</span>
      <div class="d-flex align-center ga-2">
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.5 2.25V5.75M7.5 2.25V5.75M3.125 9.25H18.875M4.875 4H17.125C18.0915 4 18.875 4.7835 18.875 5.75V18C18.875 18.9665 18.0915 19.75 17.125 19.75H4.875C3.9085 19.75 3.125 18.9665 3.125 18V5.75C3.125 4.7835 3.9085 4 4.875 4Z"
            stroke="#1A1A1A"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <v-select
          v-model="platform_filter"
          :class="
            trail_2 === 'replied'
              ? 'green-selection'
              : trail_2 === 'not_replied'
              ? 'red-selection'
              : ''
          "
          :items="filter_days"
          item-title="title"
          item-value="value"
        ></v-select>
      </div>
    </div>
    <div class="">
      <div
        v-for="(item, index) in data"
        :key="index"
        class="d-flex justify-space-between align-center py-3"
      >
        <div class="logo-name d-flex align-center ga-3">
          <img
            width="24px"
            :src="
              item.platform === 'Snapchat'
                ? SnapchatIcon
                : item.platform === 'Instagram'
                ? InstagramIcon
                : item.platform === 'Facebook'
                ? FacebookIcon
                : item.platform === 'TikTok'
                ? TikTokIcon
                : item.platform === 'LinkedIn'
                ? LinkedIn
                : xIcon
            "
            :alt="item.platform"
          />
          <span>{{ item.platform }}</span>
        </div>

        <div
          class="number-chart-percent d-flex align-center justify-end ga-2 w-50"
        >
          <div class="number">{{ item.total }}</div>
          <div class="chart">
            <span
              class="background"
              :style="{ width: item.percentage + '%' }"
            ></span>
          </div>
          <div class="percent">{{ item.percentage }} %</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SnapchatIcon from "@/assets/images/icons/snapcaht.svg";
import xIcon from "@/assets/images/icons/x.svg";
import InstagramIcon from "@/assets/images/icons/instagram.svg";
import FacebookIcon from "@/assets/images/icons/facebook.svg";
import TikTokIcon from "@/assets/images/icons/tiktok.svg";
import LinkedIn from "@/assets/images/icons/linkedIn.svg";
import phoneIcon from "@/assets/images/icons/phone.svg";
export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      SnapchatIcon,
      xIcon,
      InstagramIcon,
      FacebookIcon,
      TikTokIcon,
      LinkedIn,
      phoneIcon,
      platform_filter: "",
    };
  },
  methods: {},
  mounted() {},
};
</script>
