<template>
  <div class="header">
    <img :src="TikTokStoryHeader" alt="" />
  </div>
  <div class="image position-relative" style="width: 100%; height: 640px">
    <div
      class="position-absolute top-0 left-0"
      style="width: 100%; height: 100%"
    >
      <img :src="TikTokStoryLoaderTop" alt="" />
      <img :src="TikTokStoryLoaderBottom" alt="" />
    </div>
    <img
      v-if="
        post.file.file &&
        post.file.file.type &&
        post.file.file.type.startsWith('image/')
      "
      :src="post.file.preview"
      alt="Post Image"
      width="100%"
      height="100%"
    />
    <video
      v-if="
        post.file.file &&
        post.file.file.type &&
        post.file.file.type.startsWith('video/')
      "
      height="100%"
      width="100%"
      :src="post.file.preview"
      autoplay
      muted
      loop
    ></video>
  </div>
  <div class="footer">
    <img :src="TikTokStoryActions" alt="" />
    <img :src="TikTokStoryMobile" alt="" />
  </div>
</template>
<script>
import { publishPost } from "@/store/publishPost/publishPost.js";
import TikTokStoryHeader from "@/components/publish_post_components/reviews/svgs/TikTokStoryHeader.svg";
import TikTokStoryLoaderTop from "@/components/publish_post_components/reviews/svgs/TikTokStoryLoaderTop.svg";
import TikTokStoryLoaderBottom from "@/components/publish_post_components/reviews/svgs/TikTokStoryLoaderBottom.svg";
import TikTokStoryActions from "@/components/publish_post_components/reviews/svgs/TikTokStoryActions.svg";
import TikTokStoryMobile from "@/components/publish_post_components/reviews/svgs/TikTokStoryMobile.svg";
export default {
  name: "TikTokStoryReview",
  components: {},
  data() {
    return {
      TikTokStoryHeader,
      TikTokStoryLoaderTop,
      TikTokStoryLoaderBottom,
      TikTokStoryActions,
      TikTokStoryMobile,
      store: publishPost(),
      post: {},
    };
  },
  watch: {
    "store.getCarouselSlider": {
      handler() {
        this.post = this.store.post["tiktok"].find(
          (item) => item.type === "story"
        );
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
video {
  object-fit: cover;
}
</style>
