<template>
  <v-container
    fluid
    style="background-color: #f6f6f6; min-height: 100vh"
    class="pa-0 ma-0"
  >
    <v-row
      no-gutters=""
      style="position: fixed; top: 0; width: 100%; z-index: 99"
    >
      <v-col cols="12">
        <TiktokNavbar />
      </v-col>
    </v-row>
    <v-row
      class=""
      style="height: calc(100% - 70px); margin-top: 4%"
      no-gutters=""
    >
      <v-col cols="2">
        <TikTokSideBar />
      </v-col>
      <v-col cols="10">
        <div class="all-contain-first-step">
          <SelectObjectives
            @dedicatedCampaign="dedicatedCampaignStatus"
            @iosDedicatedStatusAction="updateCampaignType"
          />
          <IosDedicated v-if="iosDedicatedStatus" />
          <SettingComponent />
          <tiktok-footer></tiktok-footer>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import "@/assets/css/tiktok/index.css";
import IosDedicated from "@/components/tiktok/firststep/IosDedicated.vue";
import SelectObjectives from "@/components/tiktok/firststep/SelectObjectives.vue";
import SettingComponent from "@/components/tiktok/firststep/SettingComponent.vue";
import TiktokNavbar from "@/components/tiktok/TiktokNavbar.vue";
import TikTokSideBar from "@/components/tiktok/TikTokSideBar.vue";
import tiktokFooter from "@/components/tiktok/TiktokFooter.vue"

export default {
  components: {
    TikTokSideBar,
    TiktokNavbar,
    SelectObjectives,
    SettingComponent,
    IosDedicated,
    tiktokFooter
  },
  data() {
    return {
      iosDedicatedStatus: false,
    };
  },
  mounted() {
      const selectedObject = localStorage.getItem("selected_object");
      if (selectedObject === "5") {
        this.iosDedicatedStatus = true;
      } else {
        this.iosDedicatedStatus = false;
      }
      // localStorage.setItem("selected_advertise_id", this.$route.params.id);
    },
  methods: {
    updateCampaignType(data) {
      if (data === "APP_INSTALL") {
        this.iosDedicatedStatus = true;
      } else {
        this.iosDedicatedStatus = false;
      }
      console.log(data);
    },
    
    dedicatedCampaignStatus(data) {
      if (data === 5) {
        this.iosDedicatedStatus = true;
      } else {
        this.iosDedicatedStatus = false;
      }
    },
  },
};
</script>
