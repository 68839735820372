<template>
  <div class="meta-locaions-card">
    <div class="locations-card-header">
      <div class="title mb-1 d-flex align-center justify-space-between">
        <p class="roboto-medium">* Locations</p>
        <button @click="showMap" v-if="!mapView">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="20px"
            viewBox="0 -960 960 960"
            width="20px"
            fill="#0a7abf"
          >
            <path
              d="M216-216h51l375-375-51-51-375 375v51Zm-72 72v-153l498-498q11-11 23.84-16 12.83-5 27-5 14.16 0 27.16 5t24 16l51 51q11 11 16 24t5 26.54q0 14.45-5.02 27.54T795-642L297-144H144Zm600-549-51-51 51 51Zm-127.95 76.95L591-642l51 51-25.95-25.05Z"
            />
          </svg>
          <span>Edit</span>
        </button>
      </div>
      <div class="included-location" v-if="!mapView">
        <p class="roboto-medium">Included location:</p>
        <ul>
          <li v-for="location in selectedLocations" :key="location.id">
            {{ location.address }}
          </li>
        </ul>
      </div>
    </div>
    <div class="card-body" v-if="mapView">
      <div class="search-container">
        <div class="map-controls mb-4">
          <div class="search-box">
            <input
              v-model="searchQuery"
              type="text"
              placeholder="Search for a location or place"
              @input="searchLocation"
              class="map-search-input"
            />
            <!-- Search Results -->
            <div v-if="searchResults.length > 0" class="search-results">
              <div
                v-for="result in searchResults"
                :key="result.id"
                class="search-result-item"
                @click="selectLocation(result)"
              >
                <div class="d-flex align-center">
                  <svg
                    v-if="result.type === 'place'"
                    xmlns="http://www.w3.org/2000/svg"
                    height="20px"
                    viewBox="0 -960 960 960"
                    width="20px"
                    fill="#5f6368"
                    class="mr-2"
                  >
                    <path
                      d="M480-480q33 0 56.5-23.5T560-560q0-33-23.5-56.5T480-640q-33 0-56.5 23.5T400-560q0 33 23.5 56.5T480-480Zm0 294q122-112 181-203.5T720-552q0-109-69.5-178.5T480-800q-101 0-170.5 69.5T240-552q0 71 59 162.5T480-186Zm0 106Q319-217 239.5-334.5T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 100-79.5 217.5T480-80Zm0-480Z"
                    />
                  </svg>
                  <svg
                    v-else
                    xmlns="http://www.w3.org/2000/svg"
                    height="20px"
                    viewBox="0 -960 960 960"
                    width="20px"
                    fill="#5f6368"
                    class="mr-2"
                  >
                    <path
                      d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
                    />
                  </svg>
                  <div>
                    <span class="location-name">{{ result.address }}</span>
                    <span class="location-type">{{
                      result.type === "place" ? "📍 Place" : "🌍 Region"
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="map-mode-toggle">
            <button
              @click="togglePinMode"
              :class="['mode-button', { active: isPinMode }]"
              :title="
                isPinMode
                  ? 'Click on map to drop a pin'
                  : 'Enable pin drop mode'
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
                :fill="isPinMode ? '#ffffff' : '#0a7abf'"
              >
                <path
                  d="M480-120q-17 0-28.5-11.5T440-160v-160q-66-13-113-63.5T280-500q0-83 58.5-141.5T480-700q83 0 141.5 58.5T680-500q0 67-47 117.5T520-320v160q0 17-11.5 28.5T480-120Zm0-340q33 0 56.5-23.5T560-540q0-33-23.5-56.5T480-620q-33 0-56.5 23.5T400-540q0 33 23.5 56.5T480-460Z"
                />
              </svg>
              {{ isPinMode ? "Drop Pin Mode" : "Drop Pin" }}
            </button>
          </div>
        </div>

        <!-- Selected Locations -->
        <div class="selected-locations">
          <div
            v-for="location in selectedLocations"
            :key="location.id"
            class="selected-location-item"
          >
            <div class="d-flex align-center justify-space-between">
              <div class="d-flex align-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="20px"
                  viewBox="0 -960 960 960"
                  width="20px"
                  fill="#07a307"
                  class="mr-2"
                >
                  <path
                    d="M480-480q33 0 56.5-23.5T560-560q0-33-23.5-56.5T480-640q-33 0-56.5 23.5T400-560q0 33 23.5 56.5T480-480Zm0 294q122-112 181-203.5T720-552q0-109-69.5-178.5T480-800q-101 0-170.5 69.5T240-552q0 71 59 162.5T480-186Zm0 106Q319-217 239.5-334.5T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 100-79.5 217.5T480-80Zm0-480Z"
                  />
                </svg>

                <div>
                  <span class="location-name">{{
                    location.address || "Dropped Pin"
                  }}</span>
                  <span class="location-type">{{
                    getLocationType(location)
                  }}</span>
                  <span class="location-city"
                    >{{ location.city }} ({{ location.cityKey }})</span
                  >
                  <div class="radius-control">
                    <label>Radius: {{ formatRadius(location.radius) }}</label>
                    <input
                      type="range"
                      :value="location.radius"
                      @input="updateRadius($event, location)"
                      :min="100"
                      :max="50000"
                      step="100"
                      class="radius-slider"
                    />
                  </div>
                </div>
              </div>
              <button @click="removeLocation(location)" class="remove-btn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="20px"
                  viewBox="0 -960 960 960"
                  width="20px"
                  fill="#5f6368"
                >
                  <path
                    d="m291-240-51-51 189-189-189-189 51-51 189 189 189-189 51 51-189 189 189 189-51 51-189-189-189 189Z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <GMapMap
          :center="mapCenter"
          :zoom="zoomLevel"
          style="width: 100%; height: 500px"
          @click="handleMapClick"
          :options="mapOptions"
        >
          <template v-for="location in selectedLocations" :key="location.id">
            <GMapMarker
              :position="location.position"
              :clickable="true"
              @click="handleMarkerClick(location)"
            />
            <GMapCircle
              :center="location.position"
              :radius="location.radius"
              :options="{
                strokeColor: '#0a7abf',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#0a7abf',
                fillOpacity: 0.15,
              }"
            />
          </template>
        </GMapMap>
      </div>
    </div>
  </div>
</template>

<script>
// import apiClient from "@/core/buildRequest";
export default {
  data() {
    return {
      mapView: false,
      searchQuery: "",
      searchResults: [],
      mapCenter: { lat: 23.4241, lng: 53.8478 },
      zoomLevel: 10,
      markers: [],
      cityKeyMap: [],
      selectedLocations: [
        {
          id: "1",
          address: "United Arab Emirates",
          position: { lat: 23.4241, lng: 53.8478 },
          type: "region",
          countryCode: "AE",
          city: "Dubai",
          cityKey: "777934",
          radius: 5000,
        },
      ],
      markerId: 2,
      isPinMode: false,
      mapOptions: {
        streetViewControl: false,
        mapTypeControl: true,
        fullscreenControl: true,
        gestureHandling: "greedy",
      },
    };
  },
  watch: {
    selectedLocations: {
      handler(newLocations) {
        const locationData = newLocations.map((location) => ({
          id: location.id,
          countryCode: location.countryCode,
          cityKey: location.cityKey,
          radius: location.radius,
        }));
        console.log("Selected Locations:", locationData);
        const countryCodes = newLocations.map(
          (location) => location.countryCode
        );
        const cities = newLocations.map((location) => location.cityKey);
        localStorage.setItem(
          "metaSelectedCountryCodes",
          JSON.stringify(countryCodes)
        );
        localStorage.setItem("metaSelectedCities", JSON.stringify(cities));
      },
      deep: true,
    },
  },
  methods: {
    formatRadius(meters) {
      if (meters >= 1000) {
        return `${(meters / 1000).toFixed(1)} km`;
      }
      return `${meters} m`;
    },
    updateRadius(event, location) {
      const radius = Number(event.target.value);
      const index = this.selectedLocations.findIndex(
        (loc) => loc.id === location.id
      );
      if (index !== -1) {
        this.selectedLocations[index] = {
          ...this.selectedLocations[index],
          radius,
        };
      }
    },
    showMap() {
      this.mapView = !this.mapView;
    },
    async getCityFromComponents(addressComponents) {
      const cityComponent = addressComponents.find(
        (component) =>
          component.types.includes("locality") ||
          component.types.includes("administrative_area_level_1")
      );
      const cityName = cityComponent ? cityComponent.long_name : "Unknown City";
      return {
        name: cityName,
        key: this.cityKeyMap[cityName] || "0", // Default to '0' if city not found in map
      };
    },
    async getLocationInfo(lat, lng) {
      try {
        const apiKey = "AIzaSyAYugMkEQTvSJa9xsKlA2ixqZD7UFCeOtU";
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`
        );
        const data = await response.json();
        if (data.status === "OK" && data.results[0]) {
          const addressComponents = data.results[0].address_components;
          const countryComponent = addressComponents.find((component) =>
            component.types.includes("country")
          );
          const cityInfo = await this.getCityFromComponents(addressComponents);
          return {
            countryCode: countryComponent ? countryComponent.short_name : null,
            city: cityInfo.name,
            cityKey: cityInfo.key,
          };
        }
        return {
          countryCode: null,
          city: "Unknown City",
          cityKey: "UNK",
        };
      } catch (error) {
        console.error("Error getting location info:", error);
        return {
          countryCode: null,
          city: "Unknown City",
          cityKey: "UNK",
        };
      }
    },
    async getCountryCode(lat, lng) {
      try {
        const apiKey = "AIzaSyAYugMkEQTvSJa9xsKlA2ixqZD7UFCeOtU";
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`
        );
        const data = await response.json();
        if (data.status === "OK" && data.results[0]) {
          const addressComponents = data.results[0].address_components;
          const countryComponent = addressComponents.find((component) =>
            component.types.includes("country")
          );
          return countryComponent ? countryComponent.short_name : null;
        }
        return null;
      } catch (error) {
        console.error("Error getting country code:", error);
        return null;
      }
    },
    async searchLocation() {
      if (!this.searchQuery.trim()) {
        this.searchResults = [];
        return;
      }

      try {
        const apiKey = "AIzaSyAYugMkEQTvSJa9xsKlA2ixqZD7UFCeOtU";
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/place/textsearch/json?query=${encodeURIComponent(
            this.searchQuery
          )}&key=${apiKey}`
        );
        const data = await response.json();

        if (data.status === "OK" && data.results.length > 0) {
          const results = await Promise.all(
            data.results.map(async (result) => {
              const locationInfo = await this.getLocationInfo(
                result.geometry.location.lat,
                result.geometry.location.lng
              );
              return {
                id: Math.random().toString(36).substr(2, 9),
                address: result.formatted_address,
                name: result.name,
                position: {
                  lat: result.geometry.location.lat,
                  lng: result.geometry.location.lng,
                },
                type: this.determineLocationType(result.types),
                countryCode: locationInfo.countryCode,
                city: locationInfo.city,
                cityKey: locationInfo.cityKey,
                radius: 5000,
              };
            })
          );
          this.searchResults = results;
        } else {
          this.searchResults = [];
          console.log("Location not found.");
        }
      } catch (error) {
        console.error("Error fetching location:", error);
        alert("An error occurred while searching for the location.");
      }
    },
    determineLocationType(types) {
      const placeTypes = [
        "point_of_interest",
        "establishment",
        "restaurant",
        "cafe",
        "store",
        "shopping_mall",
        "hotel",
        "airport",
      ];

      return types.some((type) => placeTypes.includes(type))
        ? "place"
        : "region";
    },
    selectLocation(location) {
      if (
        !this.selectedLocations.some((loc) => loc.address === location.address)
      ) {
        const cityKey = this.cityKeyMap[location.city] || "0";
        const locationWithKey = {
          ...location,
          cityKey,
        };
        this.selectedLocations.push(locationWithKey);
        this.markers.push({
          id: this.markerId++,
          position: location.position,
        });
        this.mapCenter = location.position;
        this.zoomLevel = location.type === "place" ? 15 : 12;

        console.log("Location selected:", {
          id: location.id,
          countryCode: location.countryCode,
          cityKey: cityKey,
        });

        // Example of formatting for targeting
        const formData = this.formatForTargeting(this.selectedLocations);
        console.log("Formatted for targeting:", formData);
      }
      this.searchQuery = "";
      this.searchResults = [];
    },
    removeLocation(location) {
      this.selectedLocations = this.selectedLocations.filter(
        (loc) => loc.id !== location.id
      );
      this.markers = this.markers.filter(
        (marker) => marker.position !== location.position
      );

      console.log("Location removed:", {
        id: location.id,
        countryCode: location.countryCode,
      });
    },
    togglePinMode() {
      this.isPinMode = !this.isPinMode;
    },
    async handleMapClick(event) {
      if (!this.isPinMode) return;

      const position = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };

      try {
        const apiKey = "AIzaSyAYugMkEQTvSJa9xsKlA2ixqZD7UFCeOtU";
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.lat},${position.lng}&key=${apiKey}`
        );
        const data = await response.json();

        const address = data.results[0]?.formatted_address || "Dropped Pin";
        const locationInfo = await this.getLocationInfo(
          position.lat,
          position.lng
        );
        const cityKey = this.cityKeyMap[locationInfo.city] || "0";

        const newLocation = {
          id: Math.random().toString(36).substr(2, 9),
          position,
          address,
          type: "pin",
          countryCode: locationInfo.countryCode,
          city: locationInfo.city,
          cityKey,
          radius: 5000,
        };

        this.selectedLocations.push(newLocation);
        this.markers.push({
          id: this.markerId++,
          position,
        });

        console.log("Pin dropped:", {
          id: newLocation.id,
          countryCode: newLocation.countryCode,
          cityKey,
        });

        // Example of formatting for targeting
        const formData = this.formatForTargeting(this.selectedLocations);
        console.log("Formatted for targeting:", formData);

        this.isPinMode = false;
      } catch (error) {
        console.error("Error reverse geocoding:", error);
        alert("An error occurred while getting the address for this location.");
      }
    },
    handleMarkerClick(marker) {
      this.mapCenter = marker.position;
      this.zoomLevel = 15;
    },
    getLocationType(location) {
      if (location.type === "pin") return "📍 Dropped Pin";
      return location.type === "place" ? "📍 Place" : "🌍 Region";
    },
  },
  mounted() {
    const country_code = localStorage.getItem("metaSelectedCountryCodes");
    // apiClient
    //   .get("/campaign-ads/facebook/all/cities", {
    //     params: {country_code},
    //   })
    //   .then((response) => {
    //     console.log(response.data.data);
    //   });
  },
};
</script>

<style scoped>
.meta-locaions-card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
}

.locations-card-header {
  margin-bottom: 1rem;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.search-container {
  position: relative;
}

.map-controls {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
}

.search-box {
  position: relative;
  flex: 1;
}

.map-search-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  font-size: 1rem;
  outline: none;
  transition: border-color 0.2s;
  font-size: 14px;
}

.map-search-input:focus {
  border-color: #0a7abf;
}

.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-height: 300px;
  overflow-y: auto;
}

.search-result-item {
  padding: 0.75rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.search-result-item:hover {
  background-color: #f7fafc;
}

.location-name {
  display: block;
  font-weight: 500;
  font-size: 14px;
}

.location-type {
  display: block;
  font-size: 0.875rem;
  color: #666;
  margin-top: 0.25rem;
}

.selected-locations {
  margin-bottom: 1rem;
}

.selected-location-item {
  background-color: #f7fafc;
  border-radius: 0.375rem;
  padding: 5px;
  margin-bottom: 0.5rem;
}

.remove-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remove-btn:hover svg {
  fill: #e53e3e;
}

.mode-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  /* padding: 0.75rem 1rem; */
  border-radius: 0.375rem;
  border: 1px solid #0a7abf;
  background: white;
  color: #0a7abf;
  font-weight: 500;
  transition: all 0.2s;
  font-size: 14px;
}

.mode-button:hover {
  background: #f0f9ff;
}

.mode-button.active {
  background: #0a7abf;
  color: white;
}

.d-flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.justify-space-between {
  justify-content: space-between;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background: none;
  border: none;
  cursor: pointer;
  color: #0a7abf;
}

button:hover {
  opacity: 0.8;
}
</style>
