<template>
  <div class="create-campain-modules">
    <NavBar />
    <v-container
      fluid="false"
      style=" padding: 0% 0 0 0; min-height: calc(100vh - 58px)"
    >
      <v-row style="height: 100%; margin: 0; padding: 0; position: relative">
        <!-- <v-col cols="1"></v-col> -->
        <v-col cols="12" style="" class="taps pa-0 d-flex align-start ga-1">
          <SideBar />
          <v-card
            class="rounded-xl mt-8 bg-transparent mb-5 card-taps"
            style="width: 80% ; margin: 0 auto;"
            elevation="5"
          >
            <v-tabs style="background-color: transparent" v-model="tab">
              <v-tab value="one">
                <img
                  src="@/assets/soft-ware-img/snapchat-logo-svgrepo-com.svg  "
                  alt=""
                />
                SnapChat
              </v-tab>
              <v-tab value="two">
                <img
                  src="@/assets/soft-ware-img/tiktok-svgrepo-com.svg"
                  alt=""
                />
                TikTok
              </v-tab>
              <v-tab value="three">
                <img
                  style="width: 60px; height: 40px"
                  src="@/assets/soft-ware-img/metaIconTaps.svg"
                  alt=""
                />
                Meta
              </v-tab>
              <v-tab value="foure">
                <img
                  src="@/assets/soft-ware-img/google-ads-svgrepo-com.svg"
                  alt=""
                />
                Google Ads
              </v-tab>
              <v-tab value="five">
                <img
                  style="height: 25px"
                  src="@/assets/soft-ware-img/X-Logo.svg"
                  alt=""
                />
              </v-tab>
            </v-tabs>
            <v-card-text
              style="background-color: rgba(234, 248, 248, 1); height: 150vh"
            >
              <v-tabs-window v-model="tab">
                <v-tabs-window-item value="one">
                  <v-card rounded="lg" class="pa-5 iframe-container">
                    <iframe
                      :src="domain + '/snapchat'"
                      style="width: 100%; height: 140vh"
                      frameborder="0"
                    ></iframe>
                  </v-card>
                </v-tabs-window-item>

                <v-tabs-window-item
                  class="rounded-lg bg-transparent"
                  value="two"
                >
                  <v-card elevation="3" class="pa-5 rounded-lg">
                    <iframe
                      :src="domain + '/create-campaign/tiktok'"
                      style="width: 100%; height: 140vh"
                      frameborder="0"
                    ></iframe>
                  </v-card>
                </v-tabs-window-item>

                <v-tabs-window-item value="three">
                  <v-card elevation="3" class="pa-0">
                    <iframe
                      v-if="iframeStatus"
                      :src="domain + '/meta/manage/ads/account_id'"
                      style="width: 100%; height: 140vh"
                      frameborder="0"
                    ></iframe>
                    <!-- <iframe
                      v-else                     
                      :src="domain + '/meta'"
                      style="width: 100%; height: 140vh"
                      frameborder="0"
                    ></iframe> -->
                  </v-card>
                </v-tabs-window-item>

                <v-tabs-window-item value="foure">
                  <v-card elevation="3" class="pa-5">
                    <p>
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Amet rem voluptas ab distinctio, provident harum neque
                      soluta assumenda ad officia magni id nam alias pariatur
                      commodi accusantium fugit temporibus ipsum?
                    </p>
                  </v-card>
                </v-tabs-window-item>

                <v-tabs-window-item value="five">
                  <v-card elevation="3" class="pa-5">
                    <p>
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Amet rem voluptas ab distinctio, provident harum neque
                      soluta assumenda ad officia magni id nam alias pariatur
                      commodi accusantium fugit temporibus ipsum?
                    </p>
                  </v-card>
                </v-tabs-window-item>
              </v-tabs-window>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- <v-col cols="8" lg="8" md="10" sm="10"  style="margin-top: 2%" class="taps">
          
        </v-col>
        <v-col cols="0" lg="2" md="0" sm="0" ></v-col> -->
      </v-row>
    </v-container>
  </div>
</template>

<script>
import NavBar from "@/components/layout/GlobalNav.vue";
import SideBar from "@/components/layout/SideBar.vue";
import "@/assets/css/style.css";
export default {
  components: { NavBar, SideBar },
  data: () => ({
    tab: null,
    domain: "",
    iframeStatus: localStorage.getItem("facebook_token") ? true : false,
  }),
  mounted() {
    this.domain = window.location.origin;
    const savedTab = localStorage.getItem("selectedTab");
    if (savedTab) {
      this.tab = savedTab;
    } else {
      this.tab = "one"; 
    }
  },
  watch: {
    tab(newTab) {
      localStorage.setItem("selectedTab", newTab);
    },
  },
};
</script>
