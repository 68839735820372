<template>
  <div class="pa-4 dashboard-card" elevation="2">
    <div class="d-flex justify-space-between">
      <span class="text-subtitle-1 font-weight-bold"
        >Top Performing Compaign</span
      >
      <div class="d-flex align-center ga-2">
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.5 2.25V5.75M7.5 2.25V5.75M3.125 9.25H18.875M4.875 4H17.125C18.0915 4 18.875 4.7835 18.875 5.75V18C18.875 18.9665 18.0915 19.75 17.125 19.75H4.875C3.9085 19.75 3.125 18.9665 3.125 18V5.75C3.125 4.7835 3.9085 4 4.875 4Z"
            stroke="#1A1A1A"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <v-select
          v-model="filter"
          :items="filter_days"
          item-title="title"
          item-value="value"
        ></v-select>
      </div>
    </div>
    <div class="p-3 mt-4">
      <v-table>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Campaign name</th>
            <th>Leads Generated</th>
            <th>Change</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in data" :key="item.name">
            <td>{{ index + 1 }}</td>
            <td>{{ item.campaign_name }}</td>
            <td>{{ item.total }}</td>
            <td>
              <span :class="item.percentage > 0 ? 'green-status' : 'red-status'"
                >{{ item.percentage > 0 ? "+" : "-"
                }}{{ item.percentage }}</span
              >
            </td>
          </tr>
        </tbody>
      </v-table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
    },
  },
  data() {
    return {
      filter: "",
      filter_days: [
        { title: "Last Day", value: "last_day" },
        { title: "Last Week", value: "last_week" },
        { title: "Last Month", value: "last_month" },
        { title: "Last Year", value: "last_year" },
      ],
    };
  },
  methods: {},
  mounted() {},
  watch: {
    filter(val) {
      this.$emit("filter", val);
    },
  },
};
</script>
