<template>
  <div class="pa-4 dashboard-card" elevation="2">
    <div class="d-flex">
      <span class="text-subtitle-1 font-weight-bold">Leads Status</span>
    </div>
    <div class="d-flex justify-space-around align-center p-3 mt-4">
      <div class="w-100" style="width: 160px !important; height: 160px">
        <Doughnut
          ref="doughnutChart"
          :data="chartData"
          :options="chart2Options"
        />
      </div>
      <div class="data d-flex flex-column ga-2 touched-untouched-labels">
        <div
          v-for="(value, index) in chartData.datasets[0].data"
          :key="index"
          class="d-flex ga-3 align-center"
        >
          <div class="d-flex align-center ga-2">
            <div
              :style="{
                backgroundColor: chartData.datasets[0].backgroundColor[index],
              }"
              class="dot"
            ></div>
            <span style="width: 80px">
              {{ data[index].status }}
            </span>
          </div>
          <span>{{ value }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Doughnut } from "vue-chartjs";

export default {
  components: {
    Doughnut,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chart2Options: {
        responsive: true,
        maintainAspectRatio: false,
        rotation: 0,
        cutout: "60%",
        plugins: {
          legend: {
            position: "top",
          },
          tooltip: {
            callbacks: {
              label: (context) => {
                let label = context.label || "";
                if (label) {
                  label += ": ";
                }
                if (context.raw !== undefined) {
                  label += `${context.raw} %`;
                }
                return label;
              },
            },
          },
        },
        elements: {
          arc: {
            borderRadius: 7,
          },
        },
      },
    };
  },
  watch: {
    data: {
      deep: true,
      handler() {
        const newChartData = {
          datasets: [
            {
              label: "Booked Leads",
              data: this.data.map((el) => el.percentage),
              backgroundColor: this.data.map((el, index) =>
                index === 0 ? "#1F666A" : this.randomColor()
              ),
              hoverBackgroundColor: this.data.map((el, index) =>
                index === 0 ? "#1F666A" : this.randomColor()
              ),
            },
          ],
        };
        if (this.$refs.doughnutChart) {
          this.$refs.doughnutChart.data = newChartData;
        }
      },
    },
  },
  computed: {
    chartData() {
      return {
        datasets: [
          {
            label: "Leads Status",
            data: this.data.map((el) => el.percentage),
            backgroundColor: ["#1F666A", "#F4F5F9"],
            hoverBackgroundColor: ["#1F666A", "#F4F5F9"],
          },
        ],
      };
    },
  },
  methods: {
    randomColor() {
      const colors = ["#F4F5F9", "#3dc2c9", "#336264", "#0eb376"];
      const randomIndex = Math.floor(Math.random() * colors.length);
      return colors[randomIndex];
    },
  },
};
</script>

<style scoped>
.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
</style>
