import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import { createPinia } from "pinia";
import { createI18n } from "vue-i18n";
import EN from "@/locale/en.json";
import AR from "@/locale/ar.json";
import VueGoogleMaps from '@fawmi/vue-google-maps';


// Vuetify
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
const vuetify = createVuetify({
  components,
  directives,
});

// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";

// Add the desired icons to the library
library.add(faUserSecret);

import { faCoffee } from "@fortawesome/free-solid-svg-icons";
library.add(faCoffee);

// Import the brand icon (square-facebook) from the free brands pack
import { faSquareFacebook } from "@fortawesome/free-brands-svg-icons";
library.add(faSquareFacebook);

import "vue3-toastify/dist/index.css";
import apiClient from "@/core/buildRequest";

const i18n = createI18n({
  locale: localStorage.getItem("lang")?.toUpperCase() || "EN",
  fallbackLocale: "EN",
  messages: {
    EN,
    AR,
  },
});


if (
  localStorage.getItem("user_token") &&
  localStorage.getItem("user_token") != ""
) {
  apiClient
    .get("/get-credentials")
    .then((response) => {
      const data = response.data.data;

      if (data.snapchat) {
        localStorage.setItem("snapchat_token", data.snapchat.access_token);
        localStorage.setItem(
          "snapchatTokenExpireAt",
          data.snapchat.expire_date
        );
      }

      if (data.tiktok) {
        localStorage.setItem("tiktok_token", data.tiktok.access_token);
        localStorage.setItem("tiktokTokenExpireAt", data.tiktok.expire_date);
      }

      if (data.facebook) {
        localStorage.setItem("facebook_token", data.facebook.access_token);
        localStorage.setItem(
          "facebookTokenExpireAt",
          data.facebook.expire_date
        );
      }

      if (data.instagram) {
        localStorage.setItem("instagram_token", data.instagram.access_token);
        localStorage.setItem(
          "instagramTokenExpireAt",
          data.instagram.expire_date
        );
      }

      if (data.twitter) {
        localStorage.setItem("x_token", data.twitter.access_token);
        localStorage.setItem("xTokenExpireAt", data.twitter.expire_date);
      }

      if (data.google_ads) {
        localStorage.setItem("google_ads_token", data.google_ads.access_token);
        localStorage.setItem(
          "googleTokenExpireAt",
          data.google_ads.expire_date
        );
      }
      if (data.drive) {
        localStorage.setItem("drive_token", data.drive.access_token);
        localStorage.setItem(
          "googleDriveTokenExpireAt",
          data.drive.expire_date
        );
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

createApp(App)
  .use(vuetify)
  .use(router)
  .use(createPinia())

  .use(i18n)   //pipline
  .use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyAYugMkEQTvSJa9xsKlA2ixqZD7UFCeOtU',
        libraries: 'places', 
    },
  })
  .mount("#app");
