<template>
  <div class="table-wrapper">
    <div class="table-container">
      <v-data-table
        :headers="headers"
        :items="leads"
        item-value="id"
        show-select
        class="data-table-no-border leads-table"
      >
        <!-- platform Column -->
        <template #[`item.platform`]="{ item }">
          <div class="text-center">
            <img
              v-if="item.platform === 'Snapchat'"
              :src="snapchatIcon"
              :alt="item.platform"
            />
            <img
              v-if="item.platform == 'Twitter'"
              :src="xIcon"
              :alt="item.platform"
            />
            <img
              v-if="item.platform == 'Facebook'"
              :src="facebookIcon"
              :alt="item.platform"
            />
            <img
              v-if="item.platform == 'LinkedIn'"
              :src="LinkedInIcon"
              :alt="item.platform"
            />
            <img
              v-if="item.platform == 'Instagram'"
              :src="instagramIcon"
              :alt="item.platform"
            />
            <img
              v-if="item.platform == 'TikTok'"
              :src="tiktokIcon"
              :alt="item.platform"
            />
          </div>
        </template>
        <!-- Phone Number Column -->
        <template #[`item.phoneNumber`]="{ item }">
          <a class="phone-link" :href="`tel:${item.phoneNumber}`">
            <img width="24px" :src="phoneIcon" :alt="item.phoneNumber" />
            <span>{{ item.phoneNumber }}</span>
          </a>
        </template>
        <template #[`item.leadsProgressCol`]="{ item }">
          <ProgressCol :item="item" />
        </template>
        <template #[`item.status`]="{ item }">
          <StatusCol :item="item" />
        </template>
        <!-- Comments Column -->
        <template #[`item.comments`]="{ item }">
          <CommentCol :item="item" />
        </template>
        <!-- Assignee Column -->
        <template #[`item.assignee`]="{ item }">
          <div class="d-flex align-center ga-2">
            <span v-for="(assignee, index) in item.assignee" :key="index">
              <img
                class="circle-img"
                :src="assignee.img"
                :alt="assignee.name"
              />
            </span>
          </div>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { format } from "date-fns";
import snapchatIcon from "@/assets/images/icons/snapcaht.svg";
import xIcon from "@/assets/images/icons/x.svg";
import instagramIcon from "@/assets/images/icons/instagram.svg";
import facebookIcon from "@/assets/images/icons/facebook.svg";
import tiktokIcon from "@/assets/images/icons/tiktok.svg";
import phoneIcon from "@/assets/images/icons/phone.svg";
import LinkedInIcon from "@/assets/images/icons/linkedIn.svg";
import ProgressCol from "@/components/crm/leads/ProgressCol.vue";
import StatusCol from "@/components/crm/leads/StatusCol.vue";
import CommentCol from "@/components/crm/leads/CommentCol.vue";
import { useLeadsStore } from "@/store/crm/useLeadsStore";

export default {
  name: "CustomDataTable",
  components: { ProgressCol, CommentCol, StatusCol },
  props: {
    leads: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      snapchatIcon,
      xIcon,
      instagramIcon,
      facebookIcon,
      tiktokIcon,
      phoneIcon,
      LinkedInIcon,
      headers: [],
      statusOptions: [
        { text: "New", value: "new" },
        { text: "In Progress", value: "in-progress" },
        { text: "Converted", value: "converted" },
        { text: "Lost", value: "lost" },
      ],
      progressOptions: [
        { text: "New Lead", value: "new" },
        { text: "Contacted", value: "contacted" },
        { text: "Qualified", value: "qualified" },
        { text: "Negotiating", value: "negotiating" },
        { text: "Closed", value: "closed" },
      ],
      teamMembers: [
        { text: "Sarah", value: "Sarah" },
        { text: "Mike", value: "Mike" },
        { text: "Emily", value: "Emily" },
      ],
    };
  },

  methods: {
    formatDate(date) {
      return format(new Date(date), "MMM dd, yyyy HH:mm");
    },

    async updateField(item, field, value = null) {
      try {
        const updatedValue = value !== null ? value : item[field];
      } catch (error) {
        console.error(`Error updating ${field} for ${item.firstName}:`, error);
      }
    },
  },
  mounted() {
    const leadStore = useLeadsStore();
    this.headers = leadStore.headers;
    console.log(this.leads);
  },
};
</script>
<style>
.table-container .v-table {
  margin: 0px !important;
}
.table-container .v-table .v-data-table-footer {
  justify-content: space-between !important;
}
.table-container .v-table .v-table__wrapper table thead {
  background: #edf1f1;
  color: #535862;
}
.table-container .v-table .v-table__wrapper table tbody tr:hover {
  background: #adadad27;
}
.table-container .v-table .v-table__wrapper table tbody tr td {
  padding: 16px 24px 16px 24px;
}

.data-table-no-border
  .v-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td,
.data-table-no-border
  .v-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th {
  border: none !important;
}
.data-table-no-border > .v-table__wrapper > table > tbody > tr > th,
.data-table-no-border > .v-table__wrapper > table > thead > tr > th,
.data-table-no-border > .v-table__wrapper > table > tfoot > tr > th {
  border: none !important;
}
</style>

<style scoped>
.table-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: white;
  border-radius: 8px;
}

.table-container {
  width: 100%;
  position: relative;
  overflow-x: auto;
}

.v-data-table {
  width: 100%;
  margin: 1rem 0;
}

:deep(.v-data-table__wrapper) {
  overflow-x: auto;
  width: 100%;
}

:deep(.v-data-table > .v-data-table__wrapper > table) {
  width: 100%;
  white-space: nowrap;
}

:deep(.v-data-table th) {
  white-space: nowrap;
  min-width: 120px;
}

.phone-link {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #666666;
  text-decoration: none;
}

.phone-link:hover {
  text-decoration: underline;
}

/* Ensure text capitalization */
:deep(.v-data-table) {
  text-transform: capitalize;
}
</style>
