<template>
  <v-select
    label="Select"
    v-model="selected_status"
    :items="status"
    :class="['contact-v-select', statusClass]"
    variant=""
    no-details
  >
    <template v-slot:item="{ item, props }">
      <v-list-item
        @click="updateStats(item)"
        v-bind="props"
        :title="item.raw"
        :style="{
          color:
            item.raw === 'active'
              ? '#2E6B30'
              : item.raw === 'won'
              ? '#0464bc'
              : item.raw === 'lost'
              ? '#d91313'
              : '',
          background:
            item.raw === 'active'
              ? '#E3F9EB'
              : item.raw === 'won'
              ? '#f5fafe'
              : item.raw === 'lost'
              ? '#fef3f2'
              : '',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '5px 0px',
        }"
      >
      </v-list-item>
    </template>
  </v-select>
</template>
<script>
import apiClient from "@/core/buildRequest";
import { useDealsStore } from "@/store/crm/useDealsStore";

export default {
  name: "statusCol",
  props: ["item", "id"],
  data() {
    return {
      selected_status: "",
      status: ["active", "won", "lost"],
    };
  },
  mounted() {
    this.selected_status = this.item.status;
  },
  computed: {
    statusClass() {
      switch (this.selected_status) {
        case "active":
          return "contact-v-select-green";
        case "won":
          return "contact-v-select-blue";
        case "lost":
          return "contact-v-select-red";
        default:
          return "";
      }
    },
  }, 
  methods: {
    async updateStats(item) {
      await apiClient
        .post(`/leads-managment/deals/status/${this.id}`, {
          status: item.value,
        })
        .then(() => {
          const deal_store = useDealsStore();
          deal_store?.updateStatus(item.value, this.id);
        });
    },
  },
};
</script>
