<template>
  <Layout :linksData="linksData">
    <div class="leads-managements-link d-flex align-center ga-2 mb-3">
      <div
        @click="$router.push({ name: 'Leadsmanagement' })"
        class="link cursor-pointer hoverd-style-line d-flex align-center ga-2"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.825 13L13.425 18.6L12 20L4 12L12 4L13.425 5.4L7.825 11H20V13H7.825Z"
            fill="#1F666A"
          />
        </svg>
        <span>Leads management</span>
      </div>
    </div>
    <div class="grid-container-1-1-15">
      <NewLeads :data="new_leads_data" @filter="leadsFilter" />
      <ConfirmedLeads
        :data="confirmed_leads_data"
        @filter="ConfirmedLeadsFilter"
      />
      <ConversionRate
        :data="conversion_rate_data"
        @filter="ConversionRateFilter"
      />
    </div>
    <div class="grid-container-1-1 mt-5">
      <TopPerformingCompaign
        :data="top_performing_compaign_data"
        @filter="TopPerformingCompaignFilter"
      />
      <BookedLeads :data="booked_leads_data" />
    </div>
    <div class="grid-container-1-1-1 mt-5">
      <LeadsStatus :data="leads_status_data" @filter="LeadsStatusFilter" />
      <ReachabilityCard
        :data="reachability_data"
        @filter="ReachabilityCardFilter"
      />
      <AttendanceCard :data="attendance_data" />
    </div>
    <div class="mt-4">
      <LeadsPerPlatform
        :data="platforms_data"
        @filter="LeadsPerPlatformFilter"
      />
    </div>
    <div class="mt-4">
      <AgentsAssignedToLeads :data="agents_data" />
    </div>
  </Layout>
</template>
<script>
import Layout from "@/components/crm/layouts/Layout.vue";
import NewLeads from "@/components/crm/leads/dashboard/NewLeads.vue";
import ConfirmedLeads from "@/components/crm/leads/dashboard/ConfirmedLeads.vue";
import ConversionRate from "@/components/crm/leads/dashboard/ConversionRate.vue";
import TopPerformingCompaign from "@/components/crm/leads/dashboard/TopPerformingCompaign.vue";
import BookedLeads from "@/components/crm/leads/dashboard/BookedLeads.vue";
import LeadsStatus from "@/components/crm/leads/dashboard/LeadsStatus.vue";
import ReachabilityCard from "@/components/crm/leads/dashboard/ReachabilityCard.vue";
import AttendanceCard from "@/components/crm/leads/dashboard/AttendanceCard.vue";
import LeadsPerPlatform from "@/components/crm/leads/dashboard/LeadsPerPlatform.vue";
import AgentsAssignedToLeads from "@/components/crm/leads/dashboard/AgentsAssignedToLeads.vue";
import SnapchatIcon from "@/assets/images/icons/snapcaht.svg";
import xIcon from "@/assets/images/icons/x.svg";
import InstagramIcon from "@/assets/images/icons/instagram.svg";
import FacebookIcon from "@/assets/images/icons/facebook.svg";
import TikTokIcon from "@/assets/images/icons/tiktok.svg";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  LinearScale
);
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import apiClient from "@/core/buildRequest";

export default {
  components: {
    Layout,
    NewLeads,
    ConfirmedLeads,
    ConversionRate,
    TopPerformingCompaign,
    BookedLeads,
    LeadsStatus,
    ReachabilityCard,
    AttendanceCard,
    LeadsPerPlatform,
    AgentsAssignedToLeads,
  },

  data() {
    return {
      SnapchatIcon,
      xIcon,
      InstagramIcon,
      FacebookIcon,
      TikTokIcon,
      booked_leads_data: {
        value: 0,
        total: 0,
      },
      leads_status_data: [
        {
          status: "touched",
          total: 7,
          percentage: "70.00",
        },
        {
          status: "untouched",
          total: 3,
          percentage: "30.00",
        },
      ],
      reachability_data: [
        {
          status: "reached",
          total: 7,
          percentage: "70.00",
        },
        {
          status: "unreached",
          total: 3,
          percentage: "30.00",
        },
      ],
      attendance_data: [
        {
          label: "Attended",
          percentage: "70.00",
        },
        {
          label: "Not Attended",
          percentage: "30.00",
        },
      ],

      reachability: {
        datasets: [
          {
            label: "Reachability",
            data: [75.8, 24.2],
            backgroundColor: ["#1F666A", "#F4F5F9"],
            hoverBackgroundColor: ["#1F666A", "#F4F5F9"],
          },
        ],
      },
      attendance: {
        datasets: [
          {
            label: "Attendance",
            data: [75.8, 24.2],
            backgroundColor: ["#1F666A", "#F4F5F9"],
            hoverBackgroundColor: ["#1F666A", "#F4F5F9"],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        rotation: -120,
        cutout: "75%",
        plugins: {
          legend: {
            position: "top",
          },
          centerText: {
            enabled: true,
            text: "60%",
            color: "#1A1A1A",
          },
          tooltip: {
            callbacks: {
              label: (context) => {
                let label = context.label || "";
                if (label) {
                  label += ": ";
                }
                if (context.raw !== undefined) {
                  label += `${context.raw} %`;
                }
                return label;
              },
            },
          },
        },
        elements: {
          arc: {
            borderRadius: 10,
          },
        },
      },
      chart2Options: {
        responsive: true,
        maintainAspectRatio: false,
        rotation: -120,
        cutout: "60%",
        plugins: {
          legend: {
            position: "top",
          },
          tooltip: {
            callbacks: {
              label: (context) => {
                let label = context.label || "";
                if (label) {
                  label += ": ";
                }
                if (context.raw !== undefined) {
                  label += `${context.raw} %`;
                }
                return label;
              },
            },
          },
        },
        elements: {
          arc: {
            borderRadius: 7,
          },
        },
      },
      linksData: [
        { name: "Home", link: "/home-page" },
        { name: "Leads Dashboard", link: "" },
      ],
      filter_days: [
        { title: "Last Day", value: "last_day" },
        { title: "Last Week", value: "last_week" },
        { title: "Last Month", value: "last_month" },
        { title: "Last Year", value: "last_year" },
      ],
      new_leads_filter: "",
      confirmed_leads_filter: "",
      top_performing_campaigns: [
        {
          rank: 1,
          name: "Campaign 1",
          leads: 1000,
          change: 50,
          change_status: "+",
        },
        {
          rank: 2,
          name: "Campaign 2",
          leads: 800,
          change: 62,
          change_status: "-",
        },
        {
          rank: 2,
          name: "Campaign 2",
          leads: 800,
          change: 62,
          change_status: "-",
        },
      ],
      platform_filter: "",
      platforms_data: [
        {
          platform: "Instagram",
          total: 1,
          percentage: 10,
        },
        {
          platform: "Twitter",
          total: 1,
          percentage: 10,
        },
        {
          platform: "Facebook",
          total: 1,
          percentage: 10,
        },
        {
          platform: "LinkedIn",
          total: 1,
          percentage: 10,
        },
      ],
      agents_bar: {
        labels: [
          "Agent 1",
          "Agent 2",
          "Agent 3",
          "Agent 4",
          "Agent 5",
          "Agent 6",
          "Agent 7",
          "Agent 8",
          "Agent 9",
          "Agent 10",
          "Agent 11",
          "Agent 12",
          "Agent 13",
          "Agent 14",
          "Agent 15",
          "Agent 16",
          "Agent 17",
          "Agent 18",
          "Agent 19",
          "Agent 20",
          "Agent 21",
          "Agent 22",
          "Agent 23",
          "Agent 24",
          "Agent 25",
          "Agent 26",
          "Agent 27",
          "Agent 28",
          "Agent 29",
          "Agent 30",
          "Agent 31",
          "Agent 32",
          "Agent 33",
          "Agent 34",
          "Agent 35",
          "Agent 36",
          "Agent 37",
          "Agent 38",
          "Agent 39",
          "Agent 40",
          "Agent 41",
          "Agent 42",
          "Agent 43",
          "Agent 44",
          "Agent 45",
          "Agent 46",
          "Agent 47",
          "Agent 48",
          "Agent 49",
          "Agent 50",
        ],
        datasets: [
          {
            label: "",
            backgroundColor: "#2E989E",
            data: [
              45, 30, 25, 60, 35, 50, 40, 55, 70, 65, 30, 45, 20, 50, 33, 48,
              60, 70, 55, 40, 50, 35, 30, 60, 25, 45, 50, 55, 65, 70, 20, 40,
              45, 35, 50, 60, 65, 30, 55, 25, 70, 65, 50, 60, 45, 40, 35, 30,
              25, 20,
            ],
          },
        ],
      },
      agentsFilter: null,
      dialog: false,
      agentsFilterItems: [
        { title: "Top Five Agents", value: "1" },
        { title: "Less Five Agents", value: "2" },
        { title: "", value: "custom" },
      ],
      agents: [
        {
          id: 1,
          name: "John Doe",
          img: "https://via.placeholder.com/32",
        },
        {
          id: 2,
          name: "Jane Smith",
          img: "https://via.placeholder.com/32",
        },
        {
          id: 3,
          name: "Mike Johnson",
          img: "https://via.placeholder.com/32",
        },
        {
          id: 4,
          name: "Sara Brown",
          img: "https://via.placeholder.com/32",
        },
      ],
      selectedAgents: [],
      new_leads_data: {
        total: 0,
        percentage: 0 + "%",
        growth_rate: "Negative",
      },
      confirmed_leads_data: {
        total: 0,
        percentage: 0 + "%",
        growth_rate: "Negative",
      },
      conversion_rate_data: {
        total: 0,
        percentage: 0 + "%",
        growth_rate: "Negative",
      },
      top_performing_compaign_data: [],
      filters: {
        filter: {
          leads: { filter_by: null },
          top_platform: { filter_by: null },
          top_campaigns: { filter_by: null },
          convertion_status: { filter_by: null },
        },
        status: null,
        reachablitiy: null,
      },
    };
  },
  mounted() {
    this.getData();
    this.initializeFlatPickr();
    ChartJS.register({
      id: "centerText",
      beforeDraw(chart) {
        if (chart.config.options.plugins?.centerText?.enabled) {
          const { width, height } = chart;
          const ctx = chart.ctx;
          ctx.save();
          const fontSize = (height / 150).toFixed(2);
          ctx.font = `${fontSize}em sans-serif`;
          ctx.textBaseline = "middle";

          const text = chart.config.options.plugins.centerText.text || "60%";
          const textX = Math.round((width - ctx.measureText(text).width) / 2);
          const textY = height / 2;

          ctx.fillStyle =
            chart.config.options.plugins.centerText.color || "#000"; // Text color
          ctx.fillText(text, textX, textY);
          ctx.restore();
        }
      },
    });
  },
  methods: {
    handleSelection(value) {
      if (value === "custom") {
        this.openCustomDialog();
      } else {
        this.filterAgents(value);
      }
    },
    openCustomDialog() {
      this.dialog = true;
    },
    filterAgents(value) {
      alert(`Filtering agents by: ${value}`);
    },

    initializeFlatPickr() {
      if (this.$refs.startDate) {
        this.datePickerInstance = flatpickr(this.$refs.startDate, {
          enableTime: false,
          dateFormat: "Y-m-d",
          onChange: (selectedDates) => {
            this.start_date = selectedDates[0]
              ? selectedDates[0].toISOString().split("T")[0]
              : "";
          },
        });
      }
    },
    async getData() {
      try {
        await apiClient
          .get("/leads-managment/leads/get/insights", {
            params: this.filters,
          })
          .then((response) => {
            console.log(this.filters);

            this.new_leads_data = {
              total: response.data.data.totalLeads.totalLeads,
              percentage: `${response.data.data.totalLeads.growth}%`,
              growth_rate:
                response.data.data.totalLeads.growth_rate || "Negative",
            };
            this.confirmed_leads_data = {
              total: response.data.data.confirmedLeads.confirmedLeads,
              percentage: `${response.data.data.confirmedLeads.growth}%`,
              growth_rate:
                response.data.data.confirmedLeads.growth_rate || "Negative",
            };
            this.conversion_rate_data = {
              total: response.data.data.convertionStatus.total,
              percentage: `${response.data.data.convertionStatus.percentage}%`,
              growth_rate:
                response.data.data.convertionStatus.growth_rate || "Negative",
            };
            this.top_performing_compaign_data =
              response.data.data.topPerformingCampaigns;
            this.booked_leads_data.value = response.data.data.bookedPercentage;
            this.booked_leads_data.total = response.data.data.bookedLeads;
            this.booked_leads_data.value = 50;
            this.booked_leads_data.total = 200;
            this.leads_status_data = response.data.data.leadsStatus;
            this.reachability_data = response.data.data.leadReachability;
            this.attendance_data[0].percentage =
              response.data.data.attendentedPercentage;
            this.attendance_data[1].percentage =
              response.data.data.notAttendedPercentage;
            this.platforms_data = response.data.data.topPerformingPlatform;
          });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    leadsFilter(value) {
      this.filters.filter.leads.filter_by = value;
      this.getData();
    },
    ConfirmedLeadsFilter() {
      // this.filters.filter.confirmed_leads.filter_by = value;
      this.getData();
    },
    ConversionRateFilter(value) {
      this.filters.filter.convertion_status.filter_by = value.filter;
      this.filters.filter.status = value.from;
      this.filters.filter.reachablitiy = value.to;
      this.getData();
    },
    TopPerformingCompaignFilter(value) {
      this.filters.filter.top_campaigns.filter_by = value;
      this.getData();
    },
  },
};
</script>

<style>
.number-chart-percent .chart {
  position: relative;
  border-radius: 15px;
  background-color: rgba(240, 240, 240, 0.342);
  width: 85%;
  height: 8px;
}
.number-chart-percent .chart .background {
  background: linear-gradient(90deg, #1f666a 0%, #3f878d 42%, #4f979f 100%);
  border-radius: 15px;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
}
.grid-container-1-1-15 {
  display: grid;
  grid-template-columns: 1fr 1fr 1.5fr;
  gap: 20px;
}
@media (max-width: 768px) {
  .grid-container-1-1-15 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 480px) {
  .grid-container-1-1-15 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.grid-container-1-1 {
  display: grid;
  grid-template-columns: 2fr 1.5fr;
  gap: 20px;
}
@media (max-width: 768px) {
  .grid-container-1-1 {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 480px) {
  .grid-container-1-1 {
    grid-template-columns: 1fr 1fr;
  }
}
.grid-container-1-1-1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}
@media (max-width: 768px) {
  .grid-container-1-1-1 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 480px) {
  .grid-container-1-1-1 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.dashboard-card .v-input .v-input__details {
  display: none !important;
}
.dashboard-card.agents-select .v-input,
.dashboard-card.agents-select .v-input .v-input__control {
  width: 100% !important;
}
.dashboard-card .v-input {
  width: 131px !important;
  height: 32px;
  border-radius: 10px;
}
.dashboard-card .v-input .v-input__control {
  width: 131px !important;
  height: 32px;
  /* padding: 0px 15px !important; */
}
.dashboard-card .v-input {
  border: 1px solid #e8e8e8;
  overflow: hidden;
}
.dashboard-card .v-input .v-input__control .v-field .v-field__overlay {
  background: white;
}
.dashboard-card .v-input .v-input__control .v-field,
.dashboard-card .v-input .v-input__control .v-field .v-field__field,
.dashboard-card
  .v-input
  .v-input__control
  .v-field
  .v-field__field
  .v-field__input {
  width: 131px !important;
  height: 32px;
  padding: 0px;
  min-height: 32px;
}
.dashboard-card
  .v-input
  .v-input__control
  .v-field
  .v-field__field
  .v-field__append-inner {
  padding: 0px;
}
.dashboard-card .v-input .v-input__control .v-field .v-field__outline {
  display: none;
}
.dashboard-card .v-input .v-input__control .v-field,
.dashboard-card .v-input .v-input__control .v-field .v-field__field,
.dashboard-card
  .v-input
  .v-input__control
  .v-field
  .v-field__field
  .v-field__input {
  width: 100% !important;
  flex-wrap: nowrap;
}
thead {
  background: #f2f2f2;
}
thead td {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #1a1a1a;
}
thead td,
tbody td {
  height: 40px;
  padding: 12px;
}
.red-status,
.green-status {
  height: 32px;
  padding: 12px;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.red-status {
  background: #fef3f2;
  color: #d91313;
}
.green-status {
  background: #ecfdf3;
  color: #027a48;
}
.v-table {
  border-radius: 8px !important;
  border: solid 1px rgb(236, 236, 236) !important;
}
.touched-untouched-labels div .dots {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.choose-btn {
  width: 100px;
  height: 32px;
  padding: 2px 8px 2px 8px;
  border-radius: 8px;
  background: #237276;
  color: white;
  text-align: center;
}
.choose-btn:hover {
  background: #144244;
}
.cancel-btn {
  width: 100px;
  height: 32px;
  padding: 2px 8px 2px 8px;
  border-radius: 8px;
  background: #f2f2f2;
  color: rgb(99, 99, 99);
  text-align: center;
}
.cancel-btn:hover {
  background: #aaaaaa;
}
</style>
