<template>
  <v-col :cols="12" :lg="9">
    <div class="mt-4 platforms-select">
      <div class="bold-text mb-2">Platform</div>
      <v-select
        v-model="store.selectedPlatforms"
        :items="platforms"
        label="Select Platforms"
        multiple
        item-title="name"
        item-value="id"
        return-object
      >
        <template v-slot:selection="{ item }">
          <v-icon
            :icon="item.raw.icon"
            :color="item.raw.color"
            size="small"
            class="mr-2"
          ></v-icon>
          {{ item.raw.name }}
        </template>
        <template v-slot:item="{ props, item }">
          <v-list-item v-bind="props" :title="null">
            <template v-slot:prepend>
              <div class="d-flex align-center">
                <v-checkbox-btn
                  :model-value="isPlatformSelected(item.raw)"
                  density="compact"
                  :color="item.raw.color"
                  class="mr-2"
                  @click.stop="togglePlatformSelection(item.raw)"
                ></v-checkbox-btn>
                <v-icon
                  :icon="item.raw.icon"
                  :color="item.raw.color"
                  size="small"
                  class="mr-2"
                ></v-icon>
                {{ item.raw.name }}
              </div>
            </template>
          </v-list-item>
        </template>
      </v-select>
    </div>
  </v-col>
</template>

<script>
import { useTaskStore } from "@/store/taskStore";

export default {
  name: "TaskPlatforms",
  data() {
    return {
      store: useTaskStore(),
      platforms: [
        { id: 1, name: "Facebook", icon: "mdi-facebook", color: "#1877F2" },
        { id: 2, name: "Twitter", icon: "mdi-twitter", color: "#1DA1F2" },
        { id: 3, name: "Instagram", icon: "mdi-instagram", color: "#E4405F" },
        { id: 4, name: "LinkedIn", icon: "mdi-linkedin", color: "#0A66C2" },
        { id: 5, name: "Other", icon: "mdi-information", color: "#757575" }
      ]
    };
  },
  methods: {
    isPlatformSelected(platform) {
      return this.store.selectedPlatforms.some(p => p.id === platform.id);
    },
    togglePlatformSelection(platform) {
      const index = this.store.selectedPlatforms.findIndex(p => p.id === platform.id);
      if (index === -1) {
        this.store.updatePlatforms([...this.store.selectedPlatforms, platform]);
      } else {
        const newPlatforms = [...this.store.selectedPlatforms];
        newPlatforms.splice(index, 1);
        this.store.updatePlatforms(newPlatforms);
      }
    }
  }
};
</script>
