<template>
  <v-container class="container" fluid>
    <v-row gutters>
      <v-col cols="12">
        <div class="nav-bar-tiktok">
          <img src="@/assets/tiktokImage/897234f.png" alt="" />
        </div>
      </v-col>
      <v-container class="select-account mt-4">
        <v-row>
          <v-col cols="2"></v-col>
          <v-col cols="8">
            <h2 class="mb-6">Select an account</h2>
            <div class="ads-manager">
              <h4>
                Ads Manager ( <span> {{ listItemLength.length }} </span> )
              </h4>
              <div
                class="card mb-3"
                @click="setAdvertiseId(item.advertiser_id)"
                v-for="(item, i) in listItemLength"
                :key="i"
              >
                <div class="card-left-side">
                  <img src="@/assets/tiktokImage/ads-manager.png" alt="" />
                </div>
                <div class="card-right-side">
                  <p>{{ item.name }}</p>
                  <p class="id">ID: {{ item.advertiser_id }}</p>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="2"></v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import apiClient from "@/core/buildRequest";

export default {
  data() {
    return {
      listItemLength: "",
    };
  },
  mounted() {
    this.getAdvertiseId();
  },
  methods: {
    async getAdvertiseId() {
      try {
        const response = await apiClient.get("/campaign-ads/tiktok/advertiser");
        this.listItemLength = response.data.data.data.list;
      } catch (error) {
        console.error("Error fetching advertiser data:", error);
      }
    },
    setAdvertiseId(id) {
      this.$router.push({ name: "TikTok" });
      localStorage.setItem("advertiser_id", id);
    },
  },
};
</script>

<style scoped>
.container {
  width: 100%;
}
.container .nav-bar-tiktok img {
  margin: 8px;
  width: 10%;
}
.select-account h2 {
  color: #121212;
  font-size: 24px;
}
.ads-manager h4 {
  color: #121212;
  margin-bottom: 16px;
  font-size: 16px;
}
.ads-manager .card {
  background-color: #f8f8f8;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  gap: 15px;
  width: 50%;
  border-radius: 8px;
  border: 1px solid #fff;
  cursor: pointer;
}
.ads-manager .card:hover {
  border-color: #fa4b74;
}
.ads-manager .card .card-right-side p {
  font-size: 14px;
  color: #121212;
  font-weight: 500;
}
.ads-manager .card .card-right-side p.id {
  font-size: 12px;
  color: #666666;
}
</style>
