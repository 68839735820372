<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.30695 3.13044C7.70289 3.38425 6.38648 4.58899 5.96685 6.18966C5.84841 6.63975 5.81795 7.49254 5.90594 7.97647C6.1056 9.0966 6.76888 10.1084 7.70627 10.7176L7.99731 10.9071L7.74011 11.0018C7.11067 11.2286 6.40001 11.6516 5.82133 12.1389C4.77565 13.0222 4.01761 14.3149 3.73673 15.699C3.62844 16.2337 3.6149 16.5653 3.69951 16.6939C3.8078 16.8597 3.95331 16.9274 4.17328 16.9105C4.34587 16.9003 4.38648 16.8834 4.488 16.7819C4.59629 16.6736 4.60983 16.6364 4.6572 16.325C4.75873 15.6381 4.90086 15.1744 5.17835 14.6194C5.93639 13.1034 7.28665 12.0678 8.96854 11.7057C9.26973 11.6381 9.42539 11.6279 10.0007 11.6279C10.576 11.6279 10.7317 11.6381 11.0328 11.7057C12.7114 12.0678 14.065 13.1068 14.8129 14.6025C15.1005 15.1711 15.2427 15.6313 15.3442 16.3183C15.3746 16.5145 15.4186 16.6804 15.4559 16.7311C15.6487 16.9985 16.1157 16.9782 16.3019 16.6939C16.3865 16.5653 16.3729 16.2337 16.2647 15.699C15.9939 14.3724 15.2833 13.1237 14.2816 12.2269C13.7198 11.7227 12.9279 11.2421 12.2613 11.0018L12.0041 10.9071L12.2951 10.7176C13.2325 10.1084 13.8958 9.0966 14.0954 7.97647C14.1834 7.48916 14.153 6.64313 14.0345 6.1829C13.6149 4.58222 12.2782 3.37071 10.6606 3.12706C10.268 3.06953 9.69274 3.06953 9.30695 3.13044ZM10.8264 4.19305C11.3577 4.34872 11.7943 4.60591 12.1936 4.99846C13.2866 6.07461 13.4626 7.68543 12.6267 8.95447C12.1936 9.61098 11.5337 10.0848 10.7587 10.2946C10.5252 10.3555 10.3865 10.3724 10.0007 10.3724C9.46262 10.3724 9.1479 10.3047 8.71135 10.1017C7.47616 9.53315 6.71135 8.2032 6.86702 6.91048C6.95839 6.16936 7.26634 5.53315 7.79088 5.01538C8.26126 4.55176 8.74181 4.2878 9.37463 4.14567C9.70289 4.07122 10.4982 4.09829 10.8264 4.19305Z"
      fill="#536471"
    />
  </svg>
</template>

<script>
export default {
  name: "AssigneeIcon",
};
</script>
