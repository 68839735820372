<template>
    <div class="input-container">
      <div v-if="icon" class="icon">
        <img class="input-icon" :src="icon" alt="icon" />
      </div>
      <input
        :type="type"
        :placeholder="placeholder"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
      />
    </div>
  </template>
  
  <script>
  export default {
    name: "InputComponent",
    props: {
      type: {
        type: String,
        default: "text",
      },
      icon: {
        type: String,
        default: null,
      },
      placeholder: {
        type: String,
        default: "",
      },
      modelValue: {
        type: [String, Number],
        default: "",
      },
    },
  };
  </script>
  
  <style scoped>
  .input-container {
    display: flex;
    justify-content: start;
    align-items: center;
    height: 36px; /* Fixed height */
    padding: 4px;
    border-radius: 8px;
    border: 1px solid #cccccc;
  }
  .input-container .icon {
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .input-icon {
    width: 18px;
    height: 18px;
  }
  input {
    flex: 1;
    border: none;
    outline: none;
    font-size: 14px;
    color: #999999;
    width: calc(100% - 18px);
  }
  input:focus {
    border: none;
    outline: none;
  }
  </style>