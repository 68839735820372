<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.70295 3.44088C1.17417 3.57724 0.780069 3.97299 0.64704 4.50345L0.605469 4.66807V7.4018C0.605469 9.7431 0.608794 10.1422 0.628749 10.1804C0.67032 10.2586 9.48179 19.0501 9.60485 19.1366C9.74619 19.2363 9.92245 19.3162 10.1004 19.3644C10.3016 19.4176 10.6142 19.4176 10.8154 19.3644C10.99 19.3178 11.1463 19.2413 11.2744 19.1416C11.4406 19.0135 16.2746 14.1613 16.3477 14.0499C16.5273 13.7805 16.6055 13.5311 16.6055 13.2268C16.6055 13.0289 16.5805 12.8959 16.5024 12.6913C16.4076 12.4403 16.5855 12.6232 11.8331 7.8674L7.36498 3.39931H4.61295L1.86092 3.40097L1.70295 3.44088ZM11.2394 8.6689C16.0351 13.4263 15.6493 13.0256 15.6327 13.2351C15.6161 13.4513 15.7757 13.28 13.1135 15.9389C10.8104 18.2403 10.6691 18.3767 10.5859 18.4049C10.4712 18.4448 10.3365 18.4332 10.2218 18.375C10.1536 18.3417 9.27726 17.4671 5.85344 14.0183L1.56993 9.7032V7.22388V4.74456L1.6115 4.65809C1.66471 4.54335 1.75949 4.45356 1.87922 4.40534L1.97732 4.36377H4.43835H6.89938L11.2394 8.6689Z"
      fill="#536471"
    />
    <path
      d="M3.98087 5.39431C3.70816 5.45251 3.52857 5.52734 3.32404 5.67367C3.15277 5.79506 3.06131 5.88153 2.94325 6.03285C2.75535 6.2723 2.64892 6.51009 2.59072 6.81938C2.55081 7.03721 2.56578 7.41135 2.62398 7.62087C2.68551 7.83871 2.74869 7.98171 2.85678 8.14467C3.20764 8.67346 3.78299 8.95947 4.43649 8.93287C5.233 8.89795 5.88318 8.37581 6.08771 7.60591C6.12928 7.44627 6.13261 7.40637 6.13261 7.14031C6.13261 6.87758 6.12762 6.83268 6.08605 6.68302C6.03117 6.48015 5.90812 6.22241 5.79671 6.07442C5.51402 5.70194 5.16316 5.4841 4.68925 5.38766C4.51631 5.35107 4.16378 5.3544 3.98087 5.39431ZM4.58116 6.35877C4.71253 6.39867 4.87216 6.50177 4.96362 6.60487C5.00186 6.6481 5.06339 6.74621 5.10163 6.82437C5.16815 6.96238 5.16981 6.96903 5.16981 7.14031C5.16981 7.31158 5.16815 7.31823 5.09997 7.46124C4.942 7.78882 4.59446 8.00167 4.28018 7.96342C3.99251 7.9285 3.74641 7.74891 3.61005 7.47454L3.54021 7.33486V7.14197C3.54021 6.95407 3.54188 6.94575 3.60174 6.81938C3.78465 6.43692 4.20203 6.2407 4.58116 6.35877Z"
      fill="#536471"
    />
  </svg>
</template>

<script>
export default {
  name: "TagIcon",
};
</script>
