import { defineStore } from "pinia";

export const EngagementAndInsightsStore = defineStore(
  "EngagementAndInsightsStore",
  {
    state: () => ({
      body: [
        {
          reach: 3,
          reactions: 0,
          comments: 0,
          share: 0,
          id: "502451599617869_122113272008649163",
          post: "privategfgdfgfd",
          publish_date: "2025-01-13T12:09:36+0000",
          image:
            "https://external.fcai20-2.fna.fbcdn.net/emg1/v/t13/7166450616193866960?url=http%3A%2F%2Fwww.google.com%2Fimages%2Fbranding%2Fgoogleg%2F1x%2Fgoogleg_standard_color_128dp.png&fb_obo=1&utld=google.com&_nc_oc=AdjMVie1I64TXimwsQV2YLxmrfyg78Hgk3O2ldQN6bbk7y8sS8imZTAcnKA3hBdL12w&ccb=13-1&stp=dst-emg0_fr_q75_tt6&ur=50234c&_nc_sid=64c8fc&oh=06_Q399OdkNwSzWye8RwmTlfg2HMQ9tq4Q7AFLA1btO9n_R-kw&oe=678952A7",
          permalink_url:
            "https://www.facebook.com/122105168168649163/posts/122113272008649163",
          platform: "facebook",
          boosting: false,
        },
        {
          reach: 2,
          reactions: 0,
          comments: 0,
          share: 0,
          id: "502451599617869_122113270232649163",
          post: "I am updating my Page post",
          publish_date: "2025-01-13T11:47:36+0000",
          image:
            "https://external.fcai20-2.fna.fbcdn.net/emg1/v/t13/11945444667474008707?url=https%3A%2F%2Fbrandifygroup.net%2Fimage%2FMask%2520group.png&fb_obo=1&utld=brandifygroup.net&_nc_oc=AdihKPBf1VsbAn5Dy05ZzCMUFnucpElCHQODn0CEz-Am-X2a_r_63tOVEAkItz-yNXE&ccb=13-1&stp=dst-emg0_fr_q75_tt6&ur=50234c&_nc_sid=64c8fc&oh=06_Q399Y78OWBD1ZOZFKiK0VlMWiQCcIv4j2BzfB6H45mKItUM&oe=67897716",
          permalink_url:
            "https://www.facebook.com/122105168168649163/posts/122113270232649163",
          platform: "facebook",
          boosting: true,
        },
      ],
      headers: [
        {
          title: "Post",
          name: "Post",
          align: "start",
          key: "post",
          sortable: true,
        },
        {
          title: "Publish Date",
          name: "PublishDate",
          align: "start",
          key: "publish_date",
          sortable: true,
        },
        {
          title: "Platform",
          name: "Platform",
          align: "start",
          key: "platform",
          sortable: true,
        },
        {
          title: "Reach",
          name: "Reach",
          align: "start",
          key: "reach",
          sortable: true,
        },
        {
          title: "Reactions",
          name: "Reactions",
          align: "start",
          key: "reactions",
          sortable: true,
        },
        {
          title: "Comments",
          name: "Comments",
          align: "start",
          key: "comments",
          sortable: true,
        },
        {
          title: "Share",
          name: "Share",
          align: "start",
          key: "share",
          sortable: true,
        },
        {
          title: "Action",
          name: "Action",
          align: "start",
          key: "action",
          sortable: true,
        },
      ],
      items_per_page: 5,
      current_page: 1,
      total_items: 0,
      total_pages: 0,
      hasNextPage: false,
      hasPreviousPage: false,
      engagements: [
        {
          id: 1,
          user_name: "Henry Dholi",
          user_msg: "I want to make an appointment tomo...",
          user_img:
            "https://images.unsplash.com/photo-1494790108377-be9c29b29330?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          massageFrom: "facebook",
          online: true,
        },
        {
          id: 2,
          user_name: "Henry Dholi",
          user_msg: "I want to make an appointment tomo...",
          user_img:
            "https://plus.unsplash.com/premium_photo-1664541336896-b3d5f7dec9a3?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          massageFrom: "instagram",
          online: false,
        },
        {
          id: 3,
          user_name: "Henry Dholi",
          user_msg: "I want to make an appointment tomo...",
          user_img:
            "https://images.unsplash.com/photo-1640951613773-54706e06851d?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          massageFrom: "tiktok",
          online: true,
        },
        {
          id: 4,
          user_name: "Henry Dholi",
          user_msg: "I want to make an appointment tomo...",
          user_img:
            "https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          massageFrom: "instagram",
          online: false,
        },
        {
          id: 5,
          user_name: "Henry Dholi",
          user_msg: "I want to make an appointment tomo...",
          user_img:
            "https://plus.unsplash.com/premium_photo-1683121366070-5ceb7e007a97?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          massageFrom: "tiktok",
          online: false,
        },
        {
          id: 6,
          user_name: "Henry Dholi",
          user_msg: "I want to make an appointment tomo...",
          user_img:
            "https://images.unsplash.com/photo-1678286742832-26543bb49959?q=80&w=1976&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          massageFrom: "iinstagram",
          online: true,
        },
        {
          id: 1,
          user_name: "Henry Dholi",
          user_msg: "I want to make an appointment tomo...",
          user_img:
            "https://images.unsplash.com/photo-1494790108377-be9c29b29330?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          massageFrom: "facebook",
          online: true,
        },
        {
          id: 2,
          user_name: "Henry Dholi",
          user_msg: "I want to make an appointment tomo...",
          user_img:
            "https://plus.unsplash.com/premium_photo-1664541336896-b3d5f7dec9a3?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          massageFrom: "instagram",
          online: false,
        },
        {
          id: 3,
          user_name: "Henry Dholi",
          user_msg: "I want to make an appointment tomo...",
          user_img:
            "https://images.unsplash.com/photo-1640951613773-54706e06851d?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          massageFrom: "tiktok",
          online: true,
        },
        {
          id: 4,
          user_name: "Henry Dholi",
          user_msg: "I want to make an appointment tomo...",
          user_img:
            "https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          massageFrom: "instagram",
          online: false,
        },
        {
          id: 5,
          user_name: "Henry Dholi",
          user_msg: "I want to make an appointment tomo...",
          user_img:
            "https://plus.unsplash.com/premium_photo-1683121366070-5ceb7e007a97?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          massageFrom: "tiktok",
          online: false,
        },
        {
          id: 6,
          user_name: "Henry Dholi",
          user_msg: "I want to make an appointment tomo...",
          user_img:
            "https://images.unsplash.com/photo-1678286742832-26543bb49959?q=80&w=1976&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          massageFrom: "iinstagram",
          online: true,
        },
      ],
    }),
    getters: {
      getBody: (state) => state.body,
      getPagination: (state) => ({
        currentPage: state.current_page,
        totalPages: state.total_pages,
        itemsPerPage: state.items_per_page,
        totalContacts: state.total_items,
      }),
    },
    actions: {
      async getAllData() {
        try {
          var response = await apiClient.get(
            `/leads-managment/list-contacts?page=${this.current_page}&per_page=${this.items_per_page}`
          );
          let { data, pagination } = response.data.data;
          console.log(response.data.data);
          this.body = data;
          this.current_page = pagination.current_page;
          this.total_items = pagination.total_items;
          this.total_pages = pagination.total_pages;
          this.hasNextPage = pagination.next_page_url !== "" ? true : false;
          this.hasPreviousPage = pagination.perv_page_url !== "" ? true : false;
        } catch (err) {
          console.error("Error fetching contacts:", err);
        }
      },
      onPageChange() {
        return this.getAllData();
      },
      onItemsPerPageChange() {
        this.current_page = 1;
        return this.getAllData();
      },
      previousPage() {
        this.current_page -= 1;
        return this.getAllData();
      },
      nextPage() {
        this.current_page += 1;
        return this.getAllData();
      },
    },
  }
);
