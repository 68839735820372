import { defineStore } from "pinia";
import apiClient from "@/core/buildRequest";

export const useContactsStore = defineStore("useContactsStore", {
  state: () => ({
    contacts: [],
    headers: [
      {
        title: "Contact",
        name: "Contact",
        align: "start",
        key: "contact",
        menu: true,
        show: true,
        sortable: true,
      },
      {
        title: "First Name",
        name: "First Name",
        align: "start",
        show: true,
        menu: true,
        key: "first_name",
        sortable: true,
      },
      {
        title: "Last Name",
        name: "Last Name",
        align: "start",
        show: true,
        menu: true,
        key: "last_name",
      },
      {
        title: "Phone",
        name: "Phone",
        align: "start",
        show: true,
        key: "phone",
        menu: true,
      },
      {
        title: "Email",
        name: "Email",
        align: "start",
        show: true,
        menu: true,
        key: "email",
      },
      {
        title: "City",
        name: "City",
        align: "start",
        show: true,
        menu: true,
        key: "city",
      },
      {
        title: "Country",
        name: "Country",
        align: "start",
        show: true,
        menu: true,
        key: "country",
      },
      {
        title: "Job Title",
        name: "Job Title",
        align: "start",
        show: true,
        menu: true,
        key: "job_title",
      },
      {
        title: "Potentiality",
        name: "Potentiality",
        align: "start",
        show: true,
        menu: true,
        key: "potentiality",
      },
      {
        title: "Actions",
        name: "Actions",
        align: "center",
        sortable: false,
        key: "actions",
        show: true,
        menu: true,
      },
    ],
    items_per_page: 5,
    current_page: 1,
    total_contacts: 0,
    total_pages: 0,
    hasNextPage: false,
    hasPreviousPage: false,
  }),
  getters: {
    getContacts: (state) => state.contacts,
    getHeaders: (state) => state.headers.filter((header) => header.show),
    getPagination: (state) => ({
      currentPage: state.current_page,
      totalPages: state.total_pages,
      itemsPerPage: state.items_per_page,
      totalContacts: state.total_contacts,
    }),
    isLoading: (state) => state.loading,
  },
  actions: {
    hideColumnFromHeader(value) {
      this.headers.map((item) => {
        item.show = !value;
      });
    },
    async getAllContacts() {
      try {
        var response = await apiClient.get(
          `/leads-managment/list-contacts?page=${this.current_page}&per_page=${this.items_per_page}`
        );
        let { data, pagination } = response.data.data;
        console.log( response.data.data);
        this.contacts = data;
        this.current_page = pagination.current_page;
        this.total_contacts = pagination.total_items;
        this.total_pages = pagination.total_pages;
        this.hasNextPage = pagination.next_page_url !== "" ? true : false;
        this.hasPreviousPage = pagination.perv_page_url !== "" ? true : false;
      } catch (err) {
        console.error("Error fetching contacts:", err);
      }
    },
    onPageChange() {
      return this.getAllContacts();
    },
    onItemsPerPageChange() {
      this.current_page = 1;
      return this.getAllContacts();
    },
    previousPage() {
      this.current_page -= 1;
      return this.getAllContacts();
    },
    nextPage() {
      this.current_page += 1;
      return this.getAllContacts();
    },
    pushContact(contact) {
      this.contacts.unshift(contact);
    },
    updateContact(contact) {
      const index = this.contacts.findIndex((item) => item.id === contact.id);
      if (index !== -1) {
        this.contacts.splice(index, 1, contact);
      }
    },
    deleteContact(contact) {
      const index = this.contacts.findIndex((item) => item.id === contact.id);
      if (index !== -1) {
        this.contacts.splice(index, 1);
      }
    },
    updatePotentiality(id,potentiality) {
      const index = this.contacts.findIndex((item) => item.id === id);
      if (index !== -1) {
        this.contacts[index].potentiality = potentiality;
      }
    },
  },
});
 