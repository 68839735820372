<template>
  <div class="snapchat parent w-100 position-relative">
    <header class="">
      <div>
        <span> Snapchat Ads </span>
      </div>
      <div>
        <img src="@/assets/snapchatImage/download.svg" alt="" />
      </div>
      <div style="width: 5%">
        <button type="button">logout</button>
      </div>
    </header>
    <v-container
      fluid
      style="min-height: calc(100vh - 55px); display: grid; padding: 0"
    >
      <v-row class="h-100 ma-0" style="background-color: #f9f9fa">
        <v-col cols="3" class="py-0 px-0" style="position: relative">
          <SideNav firstTile="Traffic" />
        </v-col>
        <v-col cols="8" class="main-contain pa-8">
          <div v-if="validations.length > 0" class="validations">
            <div class="d-flex align-center ga-3 validation-message">
              <svg
                width="24"
                height="24"
                viewBox="0 0 16 16"
                fill="#444546"
                xmlns="http://www.w3.org/2000/svg"
                class="blue-warning-svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13 8C13 10.7614 10.7614 13 8 13V8V3C10.7614 3 13 5.23858 13 8ZM8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2Z"
                ></path>
              </svg>
              <span
                >There Are
                {{
                  validations
                    .map((validation) => validation.data.length)
                    .reduce((a, b) => a + b, 0)
                }}
                Items To Review Before Publishing</span
              >
            </div>
            <div
              v-for="SingleValidation in validations"
              :key="SingleValidation.type"
            >
              <div
                v-for="validation in SingleValidation.data"
                :key="validation.key"
                class="d-flex align-center ga-2 validation-message"
              >
                <span class="text-capitalize key">{{ validation.key }}</span>
                <span>{{ validation.message }}</span>
              </div>
              <div class="d-flex justify-end review-button mt-2">
                <button
                  @click="this.$router.push({ name: SingleValidation.path })"
                >
                  Start Review
                </button>
              </div>
            </div>
          </div>
          <div class="all-contain-form">
            <v-card class="media-card" :variant="plain">
              <v-tabs
                v-model="tab"
                align-tabs="center"
                color="deep-purple-accent-4"
              >
                <v-tab :value="1">
                  <div
                    class="tab-container d-flex flex-column ga-2 align-center justify-center"
                  >
                    <div class="img">
                      <img
                        :src="
                          tab === 1
                            ? require('@/assets/snapchatImage/single-image-or-video-active.svg')
                            : require('@/assets/snapchatImage/single-image-or-video-inactive.svg')
                        "
                        alt=""
                      />
                    </div>
                    <div class="name">
                      <span>Single Image or Video</span>
                    </div>
                  </div>
                </v-tab>
                <v-tab :value="2"
                  ><div
                    class="tab-container d-flex flex-column ga-2 align-center justify-center"
                  >
                    <div class="img">
                      <img
                        :src="
                          tab === 2
                            ? require('@/assets/snapchatImage/story-ad-active.svg')
                            : require('@/assets/snapchatImage/story-ad-inactive.svg')
                        "
                        alt=""
                      />
                    </div>
                    <div class="name">
                      <span>Story Ad</span>
                    </div>
                  </div></v-tab
                >
                <v-tab :value="3"
                  ><div
                    class="tab-container d-flex flex-column ga-2 align-center justify-center"
                  >
                    <div class="img">
                      <img
                        :src="
                          tab === 3
                            ? require('@/assets/snapchatImage/collection-ad-active.svg')
                            : require('@/assets/snapchatImage/collection-ad-inactive.svg')
                        "
                        alt=""
                      />
                    </div>
                    <div class="name">
                      <span>Collection Ad</span>
                    </div>
                  </div></v-tab
                >
                <v-tab :value="4"
                  ><div
                    class="tab-container d-flex flex-column ga-2 align-center justify-center"
                  >
                    <div class="img">
                      <img
                        :src="
                          tab === 4
                            ? require('@/assets/snapchatImage/ar-ads-active.svg')
                            : require('@/assets/snapchatImage/ar-ads-inactive.svg')
                        "
                        alt=""
                      />
                    </div>
                    <div class="name">
                      <span>AR Ads</span>
                    </div>
                  </div></v-tab
                >
              </v-tabs>

              <v-tabs-window v-model="tab">
                <v-tabs-window-item :value="1">
                  <SingleImageOrVideo />
                </v-tabs-window-item>
                <v-tabs-window-item :value="2">
                  <StoryAd />
                </v-tabs-window-item>
                <v-tabs-window-item :value="3">
                  <CollectionAd />
                </v-tabs-window-item>
              </v-tabs-window>
            </v-card>
          </div>
          <div class="footer d-flex align-center justify-center">
            <div
              class="d-flex align-center justify-space-between footer-contain"
            >
              <v-btn
                type="button"
                color="#444546"
                rounded
                class="footer-back-btn"
                variant="outlined"
                @click="backStep"
                >Back</v-btn
              >
              <div class="d-flex align-center">
                <p>
                  <span>
                    <svg
                      viewBox="0 0 16 16"
                      fill="#39CA8E"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      class="sds-icon sds-icon-check-circle styles-15ro776"
                    >
                      <path
                        d="M14.6668 7.99992C14.6668 11.6818 11.6821 14.6666 8.00016 14.6666C4.31826 14.6666 1.3335 11.6818 1.3335 7.99992C1.3335 4.31802 4.31826 1.33325 8.00016 1.33325C11.6821 1.33325 14.6668 4.31802 14.6668 7.99992ZM11.1422 5.2486L6.90277 9.488L4.88826 7.47349L3.94545 8.4163L6.90277 11.3736L12.085 6.19141L11.1422 5.2486Z"
                      ></path>
                    </svg>
                  </span>
                  Draft Saved
                </p>
                <v-btn
                  type="button"
                  color="#444546"
                  rounded
                  @click="finish"
                  class="footer-next-btn"
                  variant="outlined"
                  >Next</v-btn
                >
              </div>
            </div>
          </div>
        </v-col>
        <!-- <v-col cols="2"></v-col> -->
      </v-row>
    </v-container>
    <div v-if="sending" class="loading">
      <div id="loader">
        <svg class="circle-outer" viewBox="0 0 86 86">
          <circle class="back" cx="43" cy="43" r="40"></circle>
          <circle class="front" cx="43" cy="43" r="40"></circle>
          <circle class="new" cx="43" cy="43" r="40"></circle>
        </svg>
        <svg class="circle-inner" viewBox="0 0 34 34">
          <circle class="back" cx="17" cy="17" r="14"></circle>
          <circle class="front" cx="17" cy="17" r="14"></circle>
        </svg>
        <div class="text" data-text="Loading.."></div>
      </div>
    </div>
  </div>
</template>
<script>
import SideNav from "@/components/snapchat/SideNav.vue";
import SingleImageOrVideo from "@/components/snapchat/media/SingleImageOrVideo.vue";
import StoryAd from "@/components/snapchat/media/StoryAd.vue";
import CollectionAd from "@/components/snapchat/media/CollectionAd.vue";

import "@/assets/snapchatStyle/snapchatStyle.css";
import { useAwarenessStore } from "@/store/snapchat/awareness";

export default {
  components: { SideNav, SingleImageOrVideo, StoryAd, CollectionAd },
  data: () => ({
    tab: 1,
    validations: [],
    sending: false,
  }),
  methods: {
    backStep() {
      this.$router.push({ name: "SnapSecoundStep" });
    },
    async finish() {
      this.sending = true;
      const awarenessStore = useAwarenessStore();
      try {
        await awarenessStore.sendCreative();
        this.validations = awarenessStore.validationData;
        this.sending = false;
      } catch (error) {
        this.sending = false;
        console.error("Unexpected error in finish function:", error);
      }
    },
  },
  mounted() {
    const awarenessStore = useAwarenessStore();
    this.validations = awarenessStore.validationData;
  },
  watch: {
    tab() {
      if (this.tab === 1) {
        localStorage.setItem("creaetive_type", "Single Image or Video");
      } else if (this.tab === 2) {
        localStorage.setItem("creaetive_type", "Story Ad");
      } else if (this.tab === 3) {
        localStorage.setItem("creaetive_type", "Collection Ad");
      }
    },
  },
};
</script>
<style>
.media-card {
  background: unset !important;
  box-shadow: unset !important;
}
.media-card .v-tabs,
.media-card .v-tabs .v-slide-group__container .v-slide-group__content button {
  height: auto !important;
  margin-inline-start: unset !important;
}

.media-card .v-tabs .v-slide-group__container .v-slide-group__content button,
.media-card
  .v-tabs
  .v-slide-group__container
  .v-slide-group__content
  button:hover {
  color: rgb(84, 85, 87);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  border-top: none;
  border-right: none;
  border-left: none;
  border-image: initial;
  margin-right: 0px;
  bottom: 0px;
  cursor: pointer;
  list-style: none;
  border-bottom: 2px solid transparent;
  -webkit-box-flex: 1;
  opacity: 1;
  padding: 8px 24px;
  background: rgb(225, 227, 229);
  flex: 1 1 0px;
  margin: 0px;
}
.media-card
  .v-tabs
  .v-slide-group__container
  .v-slide-group__content
  button.v-tab--selected {
  background-color: white;
}
.media-card .v-btn:hover > .v-btn__overlay {
  opacity: 0 !important;
}
.media-card .tab-container .img img {
  margin: auto;
  max-height: 80px;
  width: 100%;
}
.media-card .tab-container .name {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  border-image: initial;
  margin-right: 0px;
  bottom: 0px;
  cursor: pointer;
  list-style: none;
  -webkit-box-flex: 1;
  padding: 0px;
  flex: 1 1 0px;
  color: rgb(68, 69, 70);
  opacity: 1;
  position: relative;
  text-transform: capitalize;
}
.media-card .v-tab__slider {
  top: 0px;
  bottom: unset;
  background-color: yellow !important;
  color: green !important;
}
.media-card .v-btn:focus-visible > .v-btn__overlay {
  opacity: 0 !important;
}
</style>
