<template>
  <div class="audience-setting mt-4">
    <div class="minimum-age mb-3">
      <div class="minimum-age-header d-flex align-center justify-space-between">
        <p class="roboto-bold">Minimum age</p>
        <button @click="toggleSelectedAge" v-if="!showSelectedAge">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="20px"
            viewBox="0 -960 960 960"
            width="20px"
            fill="#0a7abf"
          >
            <path
              d="M216-216h51l375-375-51-51-375 375v51Zm-72 72v-153l498-498q11-11 23.84-16 12.83-5 27-5 14.16 0 27.16 5t24 16l51 51q11 11 16 24t5 26.54q0 14.45-5.02 27.54T795-642L297-144H144Zm600-549-51-51 51 51Zm-127.95 76.95L591-642l51 51-25.95-25.05Z"
            />
          </svg>
          <span>Edit</span>
        </button>
      </div>
      <div class="minimum-age-body">
        <p v-if="!showSelectedAge">{{ selectedItem.value }}</p>
        <v-select
          v-else
          v-model="selectedItem"
          :items="listOfAge"
          density="compact"
          variant="outlined"
          return-object
          item-value="value"
          item-title="title"
          hide-details
        >
          <template v-slot:selection="{ item }">
            {{ item?.title || "" }}
          </template>
          <template v-slot:item="{ item }">
            <v-list-item @click="setMinAge(item)">
              <div class="my-form">
                <div class="ga-3">
                  <input
                    :checked="selectedItem && selectedItem.id === item.raw.id"
                    :id="item.raw.id"
                    type="radio"
                    name="option"
                    :value="item"
                  />
                  <div class="d-flex flex-column ga-1">
                    <label
                      :for="item.raw.id"
                      class="d-block"
                      style="font-size: 14px"
                      >{{ item.title }}</label
                    >
                  </div>
                </div>
              </div>
            </v-list-item>
          </template>
        </v-select>
      </div>
    </div>
    <div class="custom-audiences mb-3">
      <p class="roboto-bold mb-2">Exclude these custom audiences</p>
      <div class="search-input">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="20px"
          viewBox="0 -960 960 960"
          width="20px"
          fill="#5f6368"
        >
          <path
            d="M765-144 526-383q-30 22-65.79 34.5-35.79 12.5-76.18 12.5Q284-336 214-406t-70-170q0-100 70-170t170-70q100 0 170 70t70 170.03q0 40.39-12.5 76.18Q599-464 577-434l239 239-51 51ZM384-408q70 0 119-49t49-119q0-70-49-119t-119-49q-70 0-119 49t-49 119q0 70 49 119t119 49Z"
          />
        </svg>
        <input
          type="text"
          placeholder="Search exiting audience"
          name=""
          id=""
        />
      </div>
    </div>
    <div class="minimum-age">
      <div class="minimum-age-header d-flex align-center justify-space-between">
        <p class="roboto-bold">Languages</p>
        <button @click="toggleSelectedLang" v-if="!showSelectedLang">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="20px"
            viewBox="0 -960 960 960"
            width="20px"
            fill="#0a7abf"
          >
            <path
              d="M216-216h51l375-375-51-51-375 375v51Zm-72 72v-153l498-498q11-11 23.84-16 12.83-5 27-5 14.16 0 27.16 5t24 16l51 51q11 11 16 24t5 26.54q0 14.45-5.02 27.54T795-642L297-144H144Zm600-549-51-51 51 51Zm-127.95 76.95L591-642l51 51-25.95-25.05Z"
            />
          </svg>
          <span>Edit</span>
        </button>
      </div>
      <div class="minimum-age-body all-lang">
        <p v-if="!showSelectedLang">
          {{ itemSelectedLang[0] || "No language selected" }}
        </p>
        <div
          class="selected-lang"
          v-if="
            itemSelectedLang.length && itemSelectedLang.length >1
          "
        >
          <div
            class="lang-item"
            v-for="(i, index) in itemSelectedLang"
            :key="index"
          >
            <p>{{ i }}</p>
            <span class="d-flex" @click="removeLang(i)">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="20px"
                viewBox="0 -960 960 960"
                width="20px"
                fill="#5f6368"
              >
                <path
                  d="m291-240-51-51 189-189-189-189 51-51 189 189 189-189 51 51-189 189 189 189-51 51-189-189-189 189Z"
                />
              </svg>
            </span>
          </div>
        </div>
        <v-autocomplete
          v-if="showSelectedLang"
          hide-details
          v-model="itemSelectedLang"
          :items="listOfLang"
          multiple
          density="compact"
          variant="outlined"
        ></v-autocomplete>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedItem: {
        id: 0,
        value: 18,
        title: 18,
      },
      showSelectedAge: false,
      listOfAge: [
        {
          id: 0,
          value: 18,
          title: 18,
        },
        {
          id: 1,
          value: 19,
          title: 19,
        },
        {
          id: 2,
          value: 20,
          title: 20,
        },
        {
          id: 3,
          value: 21,
          title: 21,
        },
        {
          id: 4,
          value: 22,
          title: 22,
        },
        {
          id: 5,
          value: 23,
          title: 23,
        },
        {
          id: 6,
          value: 24,
          title: 24,
        },
        {
          id: 7,
          value: 25,
          title: 25,
        },
      ],
      showSelectedLang: false,
      itemSelectedLang: ["All languages"],
      listOfLang: [
        "All languages",
        "English",
        "French",
        "Spanish",
        "German",
        "Italian",
      ],
    };
  },
  mounted() {
    const minAge = localStorage.getItem("min_age");
    if (minAge) {
      this.selectedItem.value = Number(minAge);
    } else {
      this.selectedItem.value = 18;
    }
  },
  methods: {
    toggleSelectedAge() {
      this.showSelectedAge = !this.showSelectedAge;
    },
    setMinAge(item) {
      this.selectedItem = item.raw;
      localStorage.setItem("min_age", item.raw.value);
    },
    toggleSelectedLang() {
      this.showSelectedLang = !this.showSelectedLang;
    },
    removeLang(item) {
      this.itemSelectedLang = this.itemSelectedLang.filter((i) => i !== item);
    },
  },
};
</script>
