<template>
  <v-container fluid class="create-campain-modules-meta">
    <v-row>
      <v-col cols="12">
        <div class="nav-bar d-flex align-center justify-space-between">
          <img src="@/assets/images/meta/CnOoIyhtLSO.svg" alt="" />
          <button @click="backToSelectID" type="button">
            <svg
              width="20"
              height="20"
              fill="#000"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 192 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128zM160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 32C43 32 0 75 0 128L0 384c0 53 43 96 96 96l64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l64 0z"
              />
            </svg>
          </button>
        </div>
      </v-col>
      <v-col cols="12">
        <div class="create-new-camp-meta">
          <button>
            <span class="roboto-black">Create new campaign</span>
          </button>
          <SelectedObject />
        </div>
      </v-col>
      <v-col
        cols="12"
        class="d-flex justify-space-between meta-dialog align-center"
      >
        <div class="about-object">
          <p>About campaign objectives</p>
        </div>

        <div class="d-flex align-center ga-2">
          <button class="cancel-btn" @click="dialog = false">Cancel</button>
          <button
            :disabled="disabled_btn_dialog"
            :class="
              disabled_btn_dialog ? 'continue-btn' : 'continue-btn-active'
            "
            @click="goIndexPage"
            class="continue-btn"
          >
            Continue
          </button>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import "@/assets/css/meta/style.css";
import SelectedObject from "@/components/meta/selected-objective/SelectedObject.vue";
import { meta } from "@/store/meta/meta.js";
import { mapState } from "pinia";
// import apiClient from "@/core/buildRequest";

export default {
  components: {
    SelectedObject,
  },
  name: "SelectMetaObjective",
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapState(meta, ["disabled_btn_dialog"]),
  },
  mounted() {},
  methods: {
    goIndexPage() {
      this.$router.push({ name: "MetaIndex" });
    },

    backToSelectID() {
      this.$router.push({ name: "SelectAcountId" });
    },
  },
};
</script>

<style scoped>
.create-campain-modules-meta .nav-bar {
  background-color: #fff;
  border-bottom: 1px solid #c6c9cc;
}
.create-campain-modules-meta .nav-bar img {
  width: 30px;
}
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.roboto-thin {
  font-family: "Roboto", serif;
  font-weight: 100;
  font-style: normal;
}

.roboto-light {
  font-family: "Roboto", serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-regular {
  font-family: "Roboto", serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-medium {
  font-family: "Roboto", serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-bold {
  font-family: "Roboto", serif;
  font-weight: 700;
  font-style: normal;
}

.roboto-black {
  font-family: "Roboto", serif;
  font-weight: 900;
  font-style: normal;
}

.roboto-thin-italic {
  font-family: "Roboto", serif;
  font-weight: 100;
  font-style: italic;
}

.roboto-light-italic {
  font-family: "Roboto", serif;
  font-weight: 300;
  font-style: italic;
}

.roboto-regular-italic {
  font-family: "Roboto", serif;
  font-weight: 400;
  font-style: italic;
}

.roboto-medium-italic {
  font-family: "Roboto", serif;
  font-weight: 500;
  font-style: italic;
}

.roboto-bold-italic {
  font-family: "Roboto", serif;
  font-weight: 700;
  font-style: italic;
}

.roboto-black-italic {
  font-family: "Roboto", serif;
  font-weight: 900;
  font-style: italic;
}
</style>
