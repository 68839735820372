<template>
  <div class="card-pattern card-destinations-meta">
    <div class="card-header mb-2">
      <label for="">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#007e59"
        >
          <path
            d="m423.23-309.85 268.92-268.92L650-620.92 423.23-394.15l-114-114L267.08-466l156.15 156.15ZM480.07-100q-78.84 0-148.21-29.92t-120.68-81.21q-51.31-51.29-81.25-120.63Q100-401.1 100-479.93q0-78.84 29.92-148.21t81.21-120.68q51.29-51.31 120.63-81.25Q401.1-860 479.93-860q78.84 0 148.21 29.92t120.68 81.21q51.31 51.29 81.25 120.63Q860-558.9 860-480.07q0 78.84-29.92 148.21t-81.21 120.68q-51.29 51.31-120.63 81.25Q558.9-100 480.07-100Zm-.07-60q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
          />
        </svg>
        <span class="roboto-black">Destination </span>
      </label>
      <p class="roboto-regular">
        Tell us where to send people immediately after they tap or click your
        ad. Learn more
      </p>
    </div>
    <div class="card-body">
      <div class="my-form" v-for="item in destinationType" :key="item.id">
        <div class="ga-3">
          <input
            :checked="item.checked"
            :id="item.id"
            type="radio"
            name="destination"
            :value="item.value"
            v-model="selectedDestination"
          />
          <div class="d-flex flex-row align-center ga-1">
            <span v-html="item.svgIcon"> </span>
            <label :for="item.id" class="d-block" style="font-size: 14px">
              {{ item.title }}
              <span class="d-block">
                {{ item.descroptions }}
              </span>
            </label>
          </div>
        </div>
        <div v-if="item.contant_1" class="meta-deep-link">
          <p class="">Deferred deep link</p>
          <input
            placeholder="Enter the deferred deep link URL"
            type="url"
            v-model="deepLink"
            value=""
            name=""
            id=""
          />
        </div>
        <div v-if="item.contant_1" class="meta-deep-link">
          <p class="">Custom store listing</p>
          <input
            placeholder="Enter the deferred deep link URL"
            type="url"
            v-model="storeListing"
            value=""
            name=""
            id=""
          />
        </div>

        <div v-if="item.contant_2" class="search-existing-instance">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="20px"
            viewBox="0 -960 960 960"
            width="20px"
            fill="#434343"
          >
            <path
              d="M765-144 526-383q-30 22-65.79 34.5-35.79 12.5-76.18 12.5Q284-336 214-406t-70-170q0-100 70-170t170-70q100 0 170 70t70 170.03q0 40.39-12.5 76.18Q599-464 577-434l239 239-51 51ZM384-408q70 0 119-49t49-119q0-70-49-119t-119-49q-70 0-119 49t-49 119q0 70 49 119t119 49Z"
            />
          </svg>
          <input type="search" name="" id="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DestinationComponent",

  data() {
    return {
      selectedDestination: "",
      destinationType: [
        {
          id: "app_1",
          title: "App",
          value: "app",
          descroptions: "Send people to your app.",
          svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M264-48q-29.7 0-50.85-21.15Q192-90.3 192-120v-720q0-29.7 21.15-50.85Q234.3-912 264-912h432q29.7 0 50.85 21.15Q768-869.7 768-840v720q0 29.7-21.15 50.85Q725.7-48 696-48H264Zm0-120v48h432v-48H264Zm0-72h432v-480H264v480Zm0-552h432v-48H264v48Zm0 0v-48 48Zm0 624v48-48Z"/></svg>`,
          checked: true,
          contant_1: true,
          contant_2: false,
          contant_3: false,
        },
        {
          id: "instant_experience_1",
          title: "Instant Experience",
          value: "instant_experience",
          descroptions:
            "Send people to a fast-loading, mobile-optimised experience.",
          svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M264-48q-29.7 0-50.85-21.15Q192-90.3 192-120v-720q0-29.7 21.15-50.85Q234.3-912 264-912h432q29.7 0 50.85 21.15Q768-869.7 768-840v168h-72v-48H264v480h432v-48h72v168q0 29.7-21.15 50.85Q725.7-48 696-48H264Zm0-120v48h432v-48H264Zm0-624h432v-48H264v48Zm0 0v-48 48Zm0 624v48-48Zm462-276H504v132h-72v-132q0-29.7 21.15-50.85Q474.3-516 504-516h222l-57-57 51-51 144 144-144 144-51-51 57-57Z"/></svg>`,
          checked: false,
          contant_1: false,
          contant_2: false,
          contant_3: false,
        },
        {
          id: "playable_source_3",
          title: "Playable source",
          value: "playable_source",
          descroptions: "Send people to play an interactive demo of your app.",
          svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M336-216v-528l408 264-408 264Zm73-265Zm-1 133 204-132-204-132v264Z"/></svg>`,
          checked: false,
          contant_1: false,
          contant_2: false,
          contant_3: false,
        },
      ],
      deepLink: "",
      storeListing: "",
    };
  },
  watch: {
    selectedDestination(val) {
      console.log(val);
      this.destinationType.forEach((item) => {
        if (item.value === val) {
          if (item.value === "app") {
            item.contant_1 = true;
            item.contant_2 = false;
            item.contant_3 = false;
          } else if (item.value === "instant_experience") {
            item.contant_1 = false;
            item.contant_2 = true;
            item.contant_3 = false;
          } else if (item.value === "playable_source") {
            item.contant_1 = false;
            item.contant_2 = false;
            item.contant_3 = true;
          }
        } else {
          item.contant_1 = false;
          item.contant_2 = false;
          item.contant_3 = false;
        }
      });
    },
    deepLink(val) {
      localStorage.setItem("meta_deep_link", val);
    },
    storeListing(val) {
      localStorage.setItem("storeListingId", val);
    },
  },
};
</script>
