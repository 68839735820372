import { defineStore } from "pinia";
import apiClient from "@/core/buildRequest";

export const useDealsStore = defineStore("useDealsStore", {
  state: () => ({
    deals: [],
    headers: [
      {
        title: "Deal",
        name: "Deal",
        align: "start",
        key: "deal_number",
        menu: true,
        show: true,
        sortable: true,
      },
      {
        title: "Deal Name",
        name: "Deal Name",
        align: "start",
        show: true,
        menu: true,
        key: "name",
        sortable: true,
      },
      {
        title: "Assigness",
        name: "Assigness",
        align: "start",
        show: true,
        key: "members",
        menu: true,
      },
      {
        title: "Status",
        name: "Status",
        align: "start",
        show: true,
        menu: true,
        key: "status",
      },

      {
        title: "Description",
        name: "Description",
        align: "start",
        show: true,
        menu: true,
        key: "description",
      },
      {
        title: "Latest Feedback",
        name: "Latest Feedback",
        align: "start",
        show: true,
        menu: true,
        key: "feedback",
      },
      {
        title: "Stage",
        name: "Stage",
        align: "start",
        show: true,
        menu: true,
        key: "stage",
      },
      {
        title: "Added Time",
        name: "Added Time",
        align: "start",
        show: true,
        menu: true,
        key: "added_date",
      },
      {
        title: "Due Date",
        name: "Due Date",
        align: "start",
        show: true,
        menu: true,
        key: "due_date",
      },
      {
        title: "Add To Calendar",
        name: "Add To Calendar",
        align: "start",
        show: true,
        menu: true,
        key: "add_to_calendar",
      },
      {
        title: "Deal Value",
        name: "Deal Value",
        align: "start",
        show: true,
        menu: true,
        key: "value",
      },
      {
        title: "Total Cost",
        name: "Total Cost",
        align: "start",
        show: true,
        menu: true,
        key: "total_cost",
      },
      {
        title: "Incentive Percentage",
        name: "Incentive Percentage",
        align: "start",
        show: true,
        menu: true,
        key: "incentive_percentage",
      },
      {
        title: "Incentive Value",
        name: "Incentive Value",
        align: "start",
        show: true,
        menu: true,
        key: "incentive_value",
      },
      {
        title: "Profit",
        name: "Profit",
        align: "start",
        show: true,
        menu: true,
        key: "profit",
      },
      {
        title: "Contacts",
        name: "Contacts",
        align: "start",
        show: true,
        menu: true,
        key: "contacts",
      },
      {
        title: "Account",
        name: "Account",
        align: "start",
        show: true,
        menu: true,
        key: "account",
      },

      {
        title: "Description",
        name: "description",
        align: "start",
        show: true,
        menu: true,
        key: "description",
      },
      {
        title: "Actions",
        name: "Actions",
        align: "center",
        sortable: false,
        key: "actions",
        show: true,
        menu: true,
      },
    ],
    items_per_page: 5,
    current_page: 1,
    total_deals: 0,
    total_pages: 0,
    hasNextPage: false,
    hasPreviousPage: false,
  }),
  getters: {
    getDeals: (state) => state.deals,
    getHeaders: (state) => state.headers.filter((header) => header.show),
    getPagination: (state) => ({
      currentPage: state.current_page,
      totalPages: state.total_pages,
      itemsPerPage: state.items_per_page,
      totalDeals: state.total_deals,
    }),
    isLoading: (state) => state.loading,
  },
  actions: {
    hideColumnFromHeader(value) {
      this.headers.map((item) => {
        item.show = !value;
      });
    },
    async getAllDeals() {
      try {
        var response = await apiClient.get(
          `/leads-managment/deals?page=${this.current_page}&per_page=${this.items_per_page}`
        );

        let { data, pagination } = response.data.data;
        this.deals = data;
        
        this.current_page = pagination.current_page;
        this.total_deals = pagination.total_items;
        this.total_pages = pagination.total_pages;
        this.hasNextPage = pagination.next_page_url !== "" ? true : false;
        this.hasPreviousPage = pagination.perv_page_url !== "" ? true : false;
      } catch (err) {
        console.error("Error fetching deals:", err);
      }
    },
    onPageChange() {
      return this.getAllDeals();
    },
    onItemsPerPageChange() {
      this.current_page = 1;
      return this.getAllDeals();
    },
    previousPage() {
      this.current_page -= 1;
      return this.getAllDeals();
    },
    nextPage() {
      this.current_page += 1;
      return this.getAllDeals();
    },
    pushDeals(deals) {
      this.deals.unshift(deals);
    },
    updateDeal(deals) {
      const index = this.deals.findIndex((item) => item.id === deals.id);
      if (index !== -1) {
        this.deals.splice(index, 1, deals);
      }
    },
    deleteDeal(deals) {
      const index = this.deals.findIndex((item) => item.id === deals.id);
      if (index !== -1) {
        this.deals.splice(index, 1);
      }
    },
    updateStatus(id, potentiality) {
      const index = this.deals.findIndex((item) => item.id === id);
      if (index !== -1) {
        this.deals[index].status = potentiality;
      }
    },
    updateAddToCalender(id, value) {
      const index = this.deals.findIndex((item) => item.id === id);
      if (index !== -1) {
        this.deals[index].add_to_calendar = value;
      }
    },
  },
});
