<template>
  <div class="placement-card bidding-optimization-traffic">
    <div class="card-header">
      <p class="card-title">Bidding & optimization</p>
    </div>
    <div class="card-body">
      <div class="optimization-goal mb-5">
        <label for=""> Optimization goal </label>
        <v-select
          v-model="select"
          @update:modelValue="setSelectedValue(select.value)"
          :item-props="itemProps"
          :items="items"
          variant="outlined"
          persistent-hint
          return-object
          style="max-width: 70%"
        ></v-select>
      </div>
      <div class="bid-steratigy">
        <label for="" class="d-block mb-2">Bid strategy </label>
        <div class="radio-buttons-container">
          <div class="radio-button">
            <input
              @change="setBidType"
              type="radio"
              id="BID_TYPE_CUSTOM"
              value="BID_TYPE_CUSTOM"
              v-model="bidSteratgy"
              class="radio-button__input"
              name="selectData"
            />
            <label for="BID_TYPE_CUSTOM" class="radio-button__label">
              <span class="radio-button__custom"></span>
              <div>
                <p>Cost cap</p>
                <span
                  >Keep your average cost around or lower than your bid.</span
                >
              </div>
            </label>
          </div>
          <div
            v-if="bidSteratgy === 'BID_TYPE_CUSTOM'"
            class="keep-average d-flex align-center ga-2 mb-3"
          >
            <input
              type="number"
              v-model="bid_value"
              @input="setBidValue"
              name=""
              class="no-controls"
              id=""
            />
            <label for="">EGP/Follow </label>
          </div>
          <div class="radio-button">
            <input
              @change="setBidType"
              type="radio"
              id="BID_TYPE_NO_BID"
              value="BID_TYPE_NO_BID"
              v-model="bidSteratgy"
              class="radio-button__input"
              name="selectData"
            />
            <label for="BID_TYPE_NO_BID" class="radio-button__label">
              <span class="radio-button__custom"></span>
              <div>
                <p>Maximum delivery</p>
                <span>Maximize your number of results. View details </span>
              </div>
            </label>
          </div>
        </div>
        <div class="advanced-setting">
          <button @click="toggleAdvanced" class="mb-5">
            <p v-if="!advanced_setting">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="18px"
                viewBox="0 -960 960 960"
                width="18px"
                fill="#6d6e70"
              >
                <path
                  d="M480-192 240-432l51-51 189 189 189-189 51 51-240 240Zm0-285L240-717l51-51 189 189 189-189 51 51-240 240Z"
                />
              </svg>
              <span> Advanced settings </span>
            </p>
            <p v-else>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="18px"
                viewBox="0 -960 960 960"
                width="18px"
                fill="#6d6e70"
              >
                <path
                  d="m291-192-51-51 240-240 240 240-51 51-189-189-189 189Zm0-285-51-51 240-240 240 240-51 51-189-189-189 189Z"
                />
              </svg>
              <span> Hide Advanced settings </span>
            </p>
          </button>
          <div v-if="advanced_setting" class="billing-delivery">
            <div class="mb-8">
              <label class="mb-2 d-block" for="">Billing event</label>
              <p>Impression (oCPM)</p>
            </div>
            <div class="mb-8">
              <label class="mb-2 d-block" for="">Delivery type</label>
              <div class="radio-buttons-container">
                <div class="radio-button">
                  <input
                    @change="setDeliveryType"
                    type="radio"
                    id="standerd_bid"
                    value="standerd_bid"
                    v-model="delivery_type"
                    class="radio-button__input"
                    name="selectData"
                  />
                  <label for="standerd_bid" class="radio-button__label">
                    <span class="radio-button__custom"></span>
                    <div>
                      <p>Standard</p>
                      <span
                        >Your budget will be used as evenly as possible
                        depending on market demand and peaktime rates. This
                        delivery type is suitable for advertisers who prefer
                        steady spending.
                      </span>
                    </div>
                  </label>
                </div>
                <div class="radio-button">
                  <input
                    @change="setDeliveryType"
                    type="radio"
                    id="accelerated_bid"
                    value="accelerated_bid"
                    v-model="delivery_type"
                    class="radio-button__input"
                    name="selectData"
                  />
                  <label for="accelerated_bid" class="radio-button__label">
                    <span class="radio-button__custom"></span>
                    <div>
                      <p>Accelerated</p>
                      <span
                        >Your budget will be used as quickly as possible to gain
                        more target customers. This option is suitable for
                        advertisers who want to maximize spending within a short
                        timeframe and are open to potential fluctuations in CPA
                        in the beginning.
                      </span>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    advanced_setting: false,
    select: {
      name: "Follow",
      department: "Get more followers for your TikTok account.",
      value: "FOLLOW",
    },
    delivery_type: "standerd_bid",
    items: [
      {
        name: "Follow",
        department: "Get more followers for your TikTok account.",
        value: "FOLLOW",
      },
      {
        name: "TikTok page visits",
        department: `We ve upgraded "Profile visit" to "TikTok page visits."`,
        value: "LANDING_PAGE_VIEW",
      },
    ],
    bidSteratgy: "BID_TYPE_CUSTOM",
    bid_value: 0,
  }),
  mounted() {
    localStorage.setItem("delivery_type", this.delivery_type);
    localStorage.setItem("bid_type", this.bidSteratgy);
  },
  methods: {
    itemProps(item) {
      return {
        title: item.name,
        subtitle: item.department,
      };
    },
    setSelectedValue(value) {
      localStorage.setItem("optimizationGoal", value);
    },
    setBidType() {
      localStorage.setItem("bid_type", this.bidSteratgy);
    },
    setBidValue() {
      localStorage.setItem("bid_value", this.bid_value);
    },
    toggleAdvanced() {
      this.advanced_setting = !this.advanced_setting;
    },
    setDeliveryType() {
      localStorage.setItem("delivery_type", this.delivery_type);
    },
  },
};
</script>
