<template>
  <v-container fluid style="padding: 0; min-height: 100vh">
    <v-row no-gutters>
      <v-col cols="12">
        <GlobalNav></GlobalNav>
      </v-col>
      <v-col cols="12">
        <div class="d-flex h-100">
          <SideBar></SideBar>
          <div class="pa-5 w-100">
            <BreadCrumb :linksData="linksData" class="mb-5" />
            <div>
              <div
                class="leads-managements-link d-flex align-center ga-2 my-3 py-3"
              >
                <div
                  @click="$router.push({ name: 'EngagementAndInsights' })"
                  class="link cursor-pointer hoverd-style-line d-flex align-center ga-2"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.825 13L13.425 18.6L12 20L4 12L12 4L13.425 5.4L7.825 11H20V13H7.825Z"
                      fill="#1F666A"
                    />
                  </svg>
                  <span>Engagement & Insights</span>
                </div>
              </div>
              <v-row>
                <v-col cols="12" md="7">
                  <div class="post">
                    <div class="d-flex flex-wrap align-center ga-3 data">
                      <div class="element d-flex align-center ga-2">
                        <span class="title">Post Status :</span
                        ><span class="value">Location</span>
                      </div>
                      <div class="element d-flex align-center ga-2">
                        <span class="title">Platform :</span
                        ><span class="value">Location</span>
                      </div>
                      <div class="element d-flex align-center ga-2">
                        <span class="title">Date of publish :</span
                        ><span class="value">Location</span>
                      </div>
                    </div>
                  </div>
                  <div class="post w-100 mt-5">
                    <div
                      class="element d-flex flex-column ga-3 pa-4"
                      style="height: auto"
                    >
                      <div
                        class="d-flex justify-space-between align-center title"
                      >
                        <span>Caption</span>
                        <span></span>
                      </div>
                      <div class="value">
                        <div class="contant-social-post-default mb-4">
                          <div
                            class="card positon-relative"
                            style="position: relative"
                          >
                            <!-- textarea  -->
                            <textarea
                              placeholder="Write the content that you will use as a template on the rest of the platforms"
                              name=""
                              id=""
                              rows="15"
                              maxlength="2500"
                              v-model="new_caption"
                              ref="textarea"
                            ></textarea>
                            <div
                              style="
                                position: absolute;
                                bottom: 12px;
                                left: 0px;
                                width: 100%;
                              "
                              class="textarea-actions d-flex align-center justify-space-between mt-3 px-3"
                            >
                              <div class="btn-groups d-flex align-start ga-3">
                                <button
                                  class="btn-post"
                                  @click="toggleEmojiPicker"
                                >
                                  <img
                                    src="@/assets/soft-ware-img/happy-face_8376596 1.svg"
                                    alt=""
                                  />
                                </button>
                              </div>
                              <div class="word-length">
                                <span> {{ longOfText }} </span> /
                                <span>2500</span>
                              </div>
                            </div>
                          </div>
                          <div
                            v-if="showEmojiPicker"
                            class="emoji-picker-wrapper"
                          >
                            <emoji-picker
                              @emoji-click="onEmojiClick"
                            ></emoji-picker>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex align-center justify-end ga-3 mt-5">
                      <v-btn variant="text" class="cancel-btn">Cancel</v-btn>
                      <v-btn variant="text" class="save-btn">Save</v-btn>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="4">
                  <img
                    width="75%"
                    src="https://promxa-app.me:8080/img/capture.f522df09.png"
                    alt=""
                  />
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import BreadCrumb from "@/components/layout/BreadCrumb.vue";
import GlobalNav from "@/components/layout/GlobalNav.vue";
import SideBar from "@/components/layout/SideBar.vue";
import "emoji-picker-element";

export default {
  name: "EngagementAndInsights",
  components: { BreadCrumb, GlobalNav, SideBar },
  data() {
    return {
      linksData: [
        { name: "Home", link: "/home-page" },
        { name: "Engagement and Insights", link: "" },
      ],
      new_caption: "new",
      longOfText: 0,
      showEmojiPicker: false,
    };
  },
  methods: {
    toggleEmojiPicker() {
      this.showEmojiPicker = !this.showEmojiPicker;
    },
    onEmojiClick(event) {
      const emoji = event.detail.unicode;
      const textarea = this.$refs.textarea;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;

      this.new_caption =
        this.new_caption.substring(0, start) +
        emoji +
        this.new_caption.substring(end);

      this.$nextTick(() => {
        textarea.selectionStart = textarea.selectionEnd = start + emoji.length;
        textarea.focus();
      });

      this.showEmojiPicker = false;
    },
  },
  watch: {
    new_caption(val) {
      this.longOfText = val.length;
    },
  },
  mounted() {},
};
</script>

<style scoped>
.post .element {
  height: 40px;
  padding: 0px 24px 0px 16px;
  background: #ffffff;
  border-radius: 8px;
}
.post .element .title {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  color: #0d0d0d;
}
.post .element .value {
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  color: #666666;
}

.post .rectangle {
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 7.37px 44.24px 0px #dedede80;
  background: #ffffff;
  height: auto !important;
}
textarea {
  width: 100%;
  min-height: 394px;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid #dadada;
  color: #666666;
}
textarea:focus-visible {
  outline: 1px solid #237276;
}
.cancel-btn {
  width: 100px;
  height: 32px;
  padding: 2px 8px 2px 8px;
  border-radius: 8px;
  background: #f2f2f2;
  color: rgb(99, 99, 99);
  text-align: center;
}
.cancel-btn:hover {
  background: #aaaaaa;
}
.save-btn {
  width: 100px;
  height: 32px;
  padding: 2px 8px 2px 8px;
  border-radius: 8px;
  background: #237276;
  color: white;
  text-align: center;
}
.save-btn:hover {
  background: #144244;
}
</style>
