<template>
  <div class="tiktok-card-header">
    <p class="flowwing">Following</p>
    |
    <p class="foryou">For You</p>
  </div>
  <div class="tiktok-card-body">
    <img
      class="post-media"
      v-if="
        post.file.file &&
        post.file.file.type &&
        post.file.file.type.startsWith('image/')
      "
      :src="post.file.preview"
      alt="Post Image"
    />
    <video
      v-if="
        post.file.file &&
        post.file.file.type &&
        post.file.file.type.startsWith('video/')
      "
      height="100%"
      width="100%"
      :src="post.file.preview"
      autoplay
      muted
      loop
      class="post-media"
    ></video>
    <div class="post-contant">
      <div class="post-text">
        <p>@karennne · 1-28</p>
        <p>#avicii #wflove</p>
        <p>
          <span
            ><svg
              width="10"
              height="11"
              viewBox="0 0 10 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.00073 8.47176V2.17636L8.72771 1.30919V7.20436H7.33383C6.53523 7.20436 5.8188 7.69723 5.8188 8.47176C5.8188 9.24629 6.53523 9.73922 7.33383 9.73922H8.30351C9.10208 9.73922 9.81855 9.24629 9.81855 8.47176V7.80473V0.600504C9.81855 0.229519 9.51588 -0.0526716 9.18346 0.0083169L3.36564 1.07561C3.10265 1.12386 2.90989 1.37432 2.90989 1.66779V7.87141H1.51603C0.717412 7.87141 0.000976562 8.36434 0.000976562 9.13887C0.000976562 9.9134 0.717412 10.4062 1.51603 10.4062H2.48567C3.28429 10.4062 4.00073 9.9134 4.00073 9.13887V8.87199V8.47176Z"
                fill="white"
              />
            </svg> </span
          ><span> Avicii - Waiting For Love (ft.</span>
        </p>
      </div>
      <div class="post-actions">
        <img
          class="user-img"
          src="@/assets/soft-ware-img/18583bf15a6bdc083bcee64acc58ed55.png"
          alt=""
        />
        <img src="@/assets/soft-ware-img/Action Buttons.svg" alt="" />
        <img src="@/assets/soft-ware-img/Disc.svg" alt="" />
      </div>
    </div>
  </div>
  <div class="tiktok-card-footer">
    <img src="@/assets/soft-ware-img/Frame 1000004280.svg" alt="" />
  </div>
</template>
<script>
import { publishPost } from "@/store/publishPost/publishPost.js";
export default {
  name: "TikTokPostReview",
  components: {},
  data() {
    return {
      store: publishPost(),
      post: {},
    };
  },
  watch: {
    "store.getCarouselSlider": {
      handler() {
        this.post = this.store.post["tiktok"].find(
          (item) => item.type === "post"
        );
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
video {
  object-fit: cover;
}
</style>
