<template>
  <div class="review-posts-container">
    <div class="review-header">
      <p>Preview</p>

      <Carousel
        :items-to-show="5"
        :wrap-around="false"
        :mouseDrag="true"
        :touchDrag="true"
        :settings="{ snapAlign: 'start' }"
        class="platform-carousel"
        v-model="currentSlide"
      >
        <Slide
          v-for="(slide, index) in sliders"
          :key="index"
          :class="['carousel-slide', { active: currentSlide === index }]"
          @click="currentSlide = index"
        >
          <div class="carousel-item">
            <p
              :class="[
                'w-100 h-100 d-flex align-center justify-center',
                slide.platform,
              ]"
            >
              <span class="platform-icon">
                <img :src="getIcon(slide.platform, slide.type)" alt="" />
              </span>
              <span>{{ slide.type }}</span>
            </p>
          </div>
        </Slide>

        <template #addons>
          <Navigation />
        </template>
      </Carousel>

      <!-- Preview Content -->
      <div
        v-for="(slide, index) in sliders"
        :key="index"
        class="preview-content gallery-image-container"
        v-show="currentSlide === index"
      >
        <div
          v-if="slide.platform === 'facebook' && slide.type === 'post'"
          class="facebook-post-preview"
        >
          <FacebookPostReview />
        </div>
        <div
          v-else-if="slide.platform === 'facebook' && slide.type === 'story'"
          class="facebook-story-card"
        >
          <FacebookStoryReview />
        </div>
        <div
          v-else-if="slide.platform === 'facebook' && slide.type === 'reel'"
          class="facebook-reel-preview d-flex justify-center"
        >
          <FacebookReelReview />
        </div>
        <div
          v-else-if="slide.platform === 'instagram' && slide.type === 'post'"
          class="social-card bg-white"
        >
          <InstgramPostReview />
        </div>
        <div
          v-else-if="slide.platform === 'instagram' && slide.type === 'story'"
          class="instagram-story-preview"
        >
          <InstgramStoryReview />
        </div>
        <div
          v-else-if="slide.platform === 'instagram' && slide.type === 'reel'"
          class="instagram-reels-preview"
        >
          <InstgramReelReview />
        </div>
        <div
          v-else-if="slide.platform === 'x' && slide.type === 'post'"
          class="x-post-preview"
        >
          <XPostReview />
        </div>
        <div
          v-else-if="slide.platform === 'tiktok' && slide.type === 'post'"
          class="tiktok-post-card"
        >
          <TikTokPostReview />
        </div>
        <div
          v-else-if="slide.platform === 'tiktok' && slide.type === 'story'"
          class="tiktok-story-preview"
        >
          <TikTokStoryReview />
        </div>

        <!-- Default Preview -->


        <div
          v-else-if="slide.platform === 'snapchat' && slide.type === 'story'"
          class="snapchat-story-preview"
        >
          <SnapChatStoryReview />
        </div>
      </div>
      <div class="no-sliders" v-if="sliders.length === 0">
        <div class="no-content">
          <div class="icon"><img :src="NoSliders" alt="no-sliders" /></div>
          <div class="title">See your content preview here</div>
          <div class="text">
            Select the type of content you'd like to post from each platform:
            Post, Story, Reel or all.
          </div>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { Carousel, Slide, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import { publishPost } from "@/store/publishPost/publishPost.js";
import { mapState } from "pinia";

import facebookActiveIcon from "@/assets/images/icons/carousel/facebook-active.svg";
import facebookInActiveIcon from "@/assets/images/icons/carousel/facebook-inactive.svg";

import instagramActiveIcon from "@/assets/images/icons/carousel/instagram-active.svg";
import instagramInActiveIcon from "@/assets/images/icons/carousel/instagram-inactive.svg";

import tiktokActiveIcon from "@/assets/images/icons/carousel/tiktok-active.svg";
import tiktokInActiveIcon from "@/assets/images/icons/carousel/tiktok-inactive.svg";

import snapchatActiveIcon from "@/assets/images/icons/carousel/snapchat-active.svg";
import snapchatInActiveIcon from "@/assets/images/icons/carousel/snapchat-inactive.svg";

import xActiveIcon from "@/assets/images/icons/carousel/x-active.svg";
import xInActiveIcon from "@/assets/images/icons/carousel/x-inactive.svg";

import FacebookPostReview from "@/components/publish_post_components/reviews/FacebookPostReview.vue";
import FacebookReelReview from "@/components/publish_post_components/reviews/FacebookReelReview.vue";
import FacebookStoryReview from "@/components/publish_post_components/reviews/FacebookStoryReview.vue";

import InstgramPostReview from "@/components/publish_post_components/reviews/InstgramPostReview.vue";
import InstgramStoryReview from "@/components/publish_post_components/reviews/InstgramStoryReview.vue";
import InstgramReelReview from "@/components/publish_post_components/reviews/InstgramReelReview.vue";

import XPostReview from "@/components/publish_post_components/reviews/XPostReview.vue";

import TikTokPostReview from "@/components/publish_post_components/reviews/TikTokPostReview.vue";
import TikTokStoryReview from "@/components/publish_post_components/reviews/TikTokStoryReview.vue";

import SnapChatStoryReview from "@/components/publish_post_components/reviews/SnapChatStoryReview.vue";
import NoSliders from "@/assets/images/no-sliders.svg";

export default {
  name: "App",
  components: {
    Carousel,
    Slide,
    Navigation,
    FacebookPostReview,
    FacebookReelReview,
    FacebookStoryReview,
    InstgramPostReview,
    InstgramStoryReview,
    InstgramReelReview,
    XPostReview,
    TikTokPostReview,
    TikTokStoryReview,
    SnapChatStoryReview,
  },
  data() {
    return {
      currentSlide: 0,
      selectedPlatform: "facebook",
      selectedFeature: "post",
      store: publishPost(),
      posts: [],
      sliders: [],
      activePlatform: {},
      NoSliders,
    };
  },
  computed: {
    ...mapState(publishPost, [
      "mediaFilsPost",
      "facebookPostMedia",
      "instagramPostMedia",
    ]),
    getIcon() {
      return (platform, type) => {
        const icons = {
          facebook: {
            active: facebookActiveIcon,
            inactive: facebookInActiveIcon,
          },
          instagram: {
            active: instagramActiveIcon,
            inactive: instagramInActiveIcon,
          },
          tiktok: {
            active: tiktokActiveIcon,
            inactive: tiktokInActiveIcon,
          },
          snapchat: {
            active: snapchatActiveIcon,
            inactive: snapchatInActiveIcon,
          },
          x: {
            active: xActiveIcon,
            inactive: xInActiveIcon,
          },
        };

        const isActive =
          this.activePlatform.platform === platform &&
          this.activePlatform.type === type;

        return icons[platform]?.[isActive ? "active" : "inactive"] || "";
      };
    },
  },
  watch: {
    currentSlide(newVal) {
      const currentSlider = this.sliders[newVal];
      if (currentSlider) {
        this.selectedPlatform = currentSlider.platform;
        this.selectedFeature = currentSlider.type;
      }
      this.activePlatform = this.sliders[this.currentSlide];
    },
    "store.getCarouselSlider": {
      handler(newVal) {
        this.sliders = newVal;
      },
      immediate: true,
    },
  },
  mounted() {
    this.posts = this.store.getPost;
    this.sliders = this.store.getCarouselSlider;
  },
};
</script>

<style scoped>
.platform-carousel {
  margin: 20px 0;
}

.carousel-slide {
  cursor: pointer;
  padding: 5px;
  transition: all 0.3s ease;
}

.carousel-item {
  background-color: #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.carousel-slide .carousel-item {
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  color: #1a1a1a;
  text-transform: capitalize;
}

.carousel-item p {
  margin: 0;
  font-size: 12px;
  position: relative;
}

.platform-icon {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.preview-content {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.facebook-post-preview {
  max-width: 500px;
  margin: 0 auto;
}

.facebook-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.user-image img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.user-info p {
  margin: 0;
}

.user-name {
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 5px;
}

.post-type {
  font-size: 12px;
  color: #65676b;
  display: flex;
  align-items: center;
  gap: 5px;
}

.card-body img.media {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
}

.card-actions {
  padding: 12px;
  border-bottom: 1px solid #e4e6eb;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-actions-left p {
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  color: #65676b;
}

.card-actions-left span {
  font-size: 14px;
  color: #050505;
}

.card-actions-right a {
  background: #e4e6eb;
  color: #050505;
  padding: 6px 12px;
  border-radius: 6px;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
}

.card-footer {
  padding: 12px;
  display: flex;
  justify-content: space-between;
}

.card-footer button {
  background: none;
  border: none;
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 6px;
  color: #65676b;
  font-weight: 600;
  cursor: pointer;
}

.card-footer button:hover {
  background: #f2f2f2;
  border-radius: 4px;
}


.carousel__slide .carousel-item p {
  border: 1.5px solid; /* Specify a border size */
  border-radius: 50%;
  border-color: transparent;
}
.carousel__slide.active .carousel-item p.facebook {
  border-color: #0163e0 !important;
}
.carousel__slide.active .carousel-item p.tiktok {
  border-color: #000000 !important;
}
.carousel__slide.active .carousel-item p.instagram {
  border-color: #a335b1 !important;
}
.carousel__slide.active .carousel-item p.snapchat {
  border-color: #fffa37 !important;
}
.carousel__slide.active .carousel-item p.x {
  border-color: #000000 !important;
}
</style>
