<template>
  <div class="create-task-container">
    <v-row>
      <v-col :cols="12" :lg="8" class="task-form">
        <v-row>
          <!-- Name -->
          <v-col cols="12">
            <input
              v-model="taskStore.name"
              class="calender-input calender-input-lg w-100"
              placeholder="Task Name"
            />
          </v-col>

          <!-- Status -->
          <TaskStatus />

          <!-- Tags -->
          <TaskTags />

          <!-- Dates -->
          <TaskDates />

          <!-- Priority -->
          <TaskPriority />

          <!-- Assignee -->
          <TaskAssignee />

          <!-- Description -->
          <TaskDescription />

          <!-- Post Type -->
          <TaskPostType />

          <!-- Platforms -->
          <TaskPlatforms />

          <!-- Branch -->
          <TaskBranch />

          <!-- Caption -->
          <TaskCaption />

          <!-- Attachments -->
          <TaskAttachments />

          <!-- Post Review -->
          <TaskPostReview />
        </v-row>

        <div class="d-flex justify-end align-center ga-3 mt-4">
          <button class="calender-cancel-btn">Cancel</button>
          <button class="calender-save-btn">Save</button>
        </div>
      </v-col>
      <v-col :cols="12" :lg="4" class="task-activity">
        <TaskActivity />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { useTaskStore } from "@/store/taskStore";
import TaskStatus from "./TaskStatus.vue";
import TaskTags from "./TaskTags.vue";
import TaskDates from "./TaskDates.vue";
import TaskPriority from "./TaskPriority.vue";
import TaskAssignee from "./TaskAssignee.vue";
import TaskDescription from "./TaskDescription.vue";
import TaskPostType from "./TaskPostType.vue";
import TaskPlatforms from "./TaskPlatforms.vue";
import TaskBranch from "./TaskBranch.vue";
import TaskCaption from "./TaskCaption.vue";
import TaskAttachments from "./TaskAttachments.vue";
import TaskPostReview from "./TaskPostReview.vue";
import TaskActivity from "./TaskActivity.vue";

export default {
  name: "TaskForm",
  components: {
    TaskStatus,
    TaskTags,
    TaskDates,
    TaskPriority,
    TaskAssignee,
    TaskDescription,
    TaskPostType,
    TaskPlatforms,
    TaskBranch,
    TaskCaption,
    TaskAttachments,
    TaskPostReview,
    TaskActivity,
  },
  data() {
    return {
      taskStore: useTaskStore(),
    };
  },
};
</script>
