<template>
  <v-app>
    <GlobalNav />
    <v-main class="position-relative">
      <SidebarNavigation v-model:drawer="drawer" />
      <div class="crm-slot">
        <BreadCrumb :linksData="linksData" class="mb-5" />
        <slot></slot>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import SidebarNavigation from "@/components/crm/layouts/sidebar.vue";
import GlobalNav from "@/components/layout/GlobalNav.vue";
import BreadCrumb from "@/components/layout/BreadCrumb.vue";

export default {
  name: "LayoutView",
  props: ["linksData"],
  components: { SidebarNavigation, GlobalNav, BreadCrumb },
  data() {
    return {
      drawer: true,
    };
  },
};
</script>
<style>
.v-application {
  max-width: 100%;
}
.v-navigation-drawer {
  height: 100% !important;
}
.v-main {
  min-height: 100vh;
}
</style>
