<template>
  <div class="placement-card bidding-optimization-traffic">
    <div class="card-header">
      <p class="card-title">Bidding & optimization</p>
    </div>
    <div class="card-body">
      <div class="optimization-goal mb-5">
        <label for=""> Optimization goal </label>
        <v-select
          v-model="select"
          @update:modelValue="setSelectedValue(select.value)"
          :item-props="itemProps"
          :items="items"
          variant="outlined"
          :hint="` ${select.hint}`"
          density="compact"
          return-object
          
        ></v-select>
      </div>
      <div class="cost-per-click">
        <label class="mb-2" for=""
          >Cost cap per click <span>(optional)</span>
        </label>
        <p>
          We aim to spend your entire budget using the maximum delivery bid
          strategy to get the most results. Enter a bid per result if you'd like
          to keep the average cost per result around the stated amount. View
          details
        </p>
        <div class="enter-value d-flex align-center ga-2">
          <input type="number" placeholder="Enter value" name="" id="" />
          <label class="d-block ma-0" for="">EGP/Click </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    select: {
      name: "Clicks",
      department:
        " With this option, your ad delivery won’t be optimized for conversions on your website. ",
      hint: "Billing is based on click (CPC).",
      value: "CLICK",
    },
    items: [
      {
        name: "Clicks",
        department:
          " With this option, your ad delivery won’t be optimized for conversions on your website. ",
        hint: "Billing is based on click (CPC).",
        value: "CLICK",
      },
      {
        name: "Landing page view",
        department:
          " We'll deliver your ads to the people most likely to land on your website. ",
        hint: "Billing is based on impression (oCPM).",
        value: "LANDING_PAGE_VIEW",
      },
    ],
  }),
  methods: {
    itemProps(item) {
      return {
        title: item.name,
        subtitle: item.department,
      };
    },
    setSelectedValue(value) {
      localStorage.setItem("optimizationGoal", value);
    },
  },
};
</script>
