<template>
  <v-container class="bg-white box-shadow rounded">
    <v-row>
      <v-col cols="7">
        <div class="filed mt-3">
          <span class="title">Name</span>
          <div class="input-container">
            <input type="text" v-model="ad_name" placeholder="Name Your ad" />
          </div>
          <div class="warning">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="#444546"
              xmlns="http://www.w3.org/2000/svg"
              class="warning-svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13 8C13 10.7614 10.7614 13 8 13V8V3C10.7614 3 13 5.23858 13 8ZM8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2Z"
              ></path>
            </svg>
            <p class="small-text muted-text">Name is required.</p>
          </div>
        </div>
        <div class="filed mt-3">
          <span class="title">Public Profile</span>
          <div class="profile-pic d-flex align-center ga-3">
            <div class="pic">
              <img
                src="https://cf-st.sc-cdn.net/d/YmmIFdcbee3keYymYPlJq?bo=Eg0aABoAMgEESAJQCGAB&uc=8"
                alt=""
              />
            </div>
            <div class="name d-flex flex-column ga-1">
              <div class="">Mohamed</div>
              <div class="small-text muted-text">Helmy</div>
            </div>
          </div>
        </div>
        <div class="filed mt-3">
          <span class="title">Brand Name (Optional) </span>
          <input
            type="text"
            v-model="brand_name"
            placeholder="Replace Profile Name"
          />
        </div>
        <div class="filed mt-3">
          <span class="title">Headline</span>
          <div class="input-container">
            <input type="text" v-model="headline" placeholder="Name Your ad" />
          </div>
          <div class="warning">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="#444546"
              xmlns="http://www.w3.org/2000/svg"
              class="warning-svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13 8C13 10.7614 10.7614 13 8 13V8V3C10.7614 3 13 5.23858 13 8ZM8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2Z"
              ></path>
            </svg>
            <p class="small-text muted-text">headline is required.</p>
          </div>
        </div>
        <span
          v-if="offer_details_ad_disclaimer_ratio"
          class="radio-small"
          @click="offer_details_ad_disclaimer_ratio = false"
          >Remove Offer Details / Ad Disclaimer</span
        >
        <span
          v-else
          class="radio-small"
          @click="offer_details_ad_disclaimer_ratio = true"
          >Add Offer Details / Ad Disclaimer</span
        >
        <div v-if="offer_details_ad_disclaimer_ratio" class="filed mt-3">
          <span class="title">Offer Details / Ad Disclaimer </span>
          <div class="input-container">
            <input
              type="text"
              v-model="offer_details_ad_disclaimer"
              placeholder="Offer details / Ad Disclaimer"
            />
          </div>
          <div class="warning">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="#444546"
              xmlns="http://www.w3.org/2000/svg"
              class="warning-svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13 8C13 10.7614 10.7614 13 8 13V8V3C10.7614 3 13 5.23858 13 8ZM8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2Z"
              ></path>
            </svg>
            <p class="small-text muted-text">
              Offer Details / Ad Disclaimer is required.
            </p>
          </div>
        </div>
        <div class="filed mt-3">
          <span class="title">Shareable</span>
          <div class="mt-3">
            <SwitchToogle
              @click="ToggleShareable"
              v-model:checked="shareable"
              :notAttached="false"
              :paused="false"
              :disabled="false"
            />
          </div>
        </div>
        <div class="filed mt-3">
          <span class="title">Topsnap Media</span>
          <div class="mt-3">
            <div class="" v-if="image_url != ''">
              <div class="d-flex ga-2">
                <img
                  :src="image_url"
                  width="48"
                  height="80"
                  style="border-radius: 10px"
                  alt=""
                />
                <span class="delete-image" @click="image_url = ''">
                  <svg
                    width="16px"
                    height="16px"
                    viewBox="0 0 16 16"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <title>Icon/SDS/Trash</title>
                    <desc>Created with Sketch.</desc>
                    <g
                      id="Icon/SDS/Trash"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <path
                        d="M11,14 L12,14 L12,5 L11,5 L11,14 Z M7.5,14 L8.5,14 L8.5,5 L7.5,5 L7.5,14 Z M4,14 L5,14 L5,5 L4,5 L4,14 Z M14,3 C14.553,3 15,3.448 15,4 C15,4.552 14.553,5 14,5 L14,14 C14,15.104 13.104,16 12,16 L4,16 C2.896,16 2,15.104 2,14 L2,5 C1.448,5 1,4.552 1,4 C1,3.448 1.448,3 2,3 L14,3 Z M3,2 C2.448,2 2,1.552 2,1 C2,0.448 2.448,0 3,0 L13,0 C13.553,0 14,0.448 14,1 C14,1.552 13.553,2 13,2 L3,2 Z"
                        id="🎨-Fill-Color"
                        fill="#16191C"
                      ></path>
                    </g>
                  </svg>
                </span>
              </div>
            </div>
            <div class="" v-else-if="video_url != ''">
              <div class="d-flex ga-2">
                <video style="border-radius: 10px" width="48" height="80">
                  <source :src="video_url" type="video/mp4" />
                </video>
                <span class="delete-image" @click="video_url = ''">
                  <svg
                    width="16px"
                    height="16px"
                    viewBox="0 0 16 16"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <title>Icon/SDS/Trash</title>
                    <desc>Created with Sketch.</desc>
                    <g
                      id="Icon/SDS/Trash"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <path
                        d="M11,14 L12,14 L12,5 L11,5 L11,14 Z M7.5,14 L8.5,14 L8.5,5 L7.5,5 L7.5,14 Z M4,14 L5,14 L5,5 L4,5 L4,14 Z M14,3 C14.553,3 15,3.448 15,4 C15,4.552 14.553,5 14,5 L14,14 C14,15.104 13.104,16 12,16 L4,16 C2.896,16 2,15.104 2,14 L2,5 C1.448,5 1,4.552 1,4 C1,3.448 1.448,3 2,3 L14,3 Z M3,2 C2.448,2 2,1.552 2,1 C2,0.448 2.448,0 3,0 L13,0 C13.553,0 14,0.448 14,1 C14,1.552 13.553,2 13,2 L3,2 Z"
                        id="🎨-Fill-Color"
                        fill="#16191C"
                      ></path>
                    </g>
                  </svg>
                </span>
              </div>
            </div>
            <UploadAndCropImage
              v-else
              @update-image="updateImage"
              @update-video="updateVideo"
            />
          </div>
        </div>
        <div class="filed mt-3">
          <span class="title">Post to Public Profile</span>
          <div class="d-flex ga-3 mt-3">
            <SwitchToogle
              @click="TogglePostToPublicProfile"
              v-model:checked="post_to_public"
              :notAttached="false"
              :paused="false"
              :disabled="false"
              :is_status_print="false"
            />
            <span v-if="!post_to_public" class="text-subtitle-2"
              >Post uploaded media to your Public Story and Profile for greater
              organic reach!</span
            >
            <span v-if="post_to_public" class="text-subtitle-2"
              >Your uploaded media will appear on your Public Story and Profile
              once you publish your ad!</span
            >
          </div>
        </div>
        <div class="filed mt-3">
          <div class="title">Attachment</div>
          <div class="mt-1">
            <span class="text-subtitle-2 muted-text">Website</span>
          </div>
        </div>
        <div class="filed mt-3">
          <div class="title">Call To Action</div>
          <div class="mt-2">
            <v-select
              variant="solo"
              class="v-select-style"
              v-model="call_to_action"
              :items="call_to_action_items"
              item-title="title"
              item-value="value"
            ></v-select>
          </div>
        </div>
        <div class="filed mt-3">
          <div class="title">Ad Favoriting</div>
          <div class="mt-2">
            <SwitchToogle
              @click="ToggleAdFavoriting"
              v-model:checked="ad_favoriting"
              :notAttached="false"
              :paused="false"
              :disabled="false"
            />
          </div>
        </div>
        <div class="filed mt-3">
          <div class="title">Switch to Standard Button Color</div>
          <div class="mt-2">
            <SwitchToogle
              @click="ToggleStandardButtonColor"
              v-model:checked="standard_button_color"
              :notAttached="false"
              :paused="false"
              :disabled="false"
            />
          </div>
        </div>
        <div class="filed mt-3">
          <span class="title">Website URL</span>
          <div class="input-container">
            <input
              type="text"
              v-model="website_url"
              placeholder="Name Your Website URL"
            />
          </div>
          <div class="warning">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="#444546"
              xmlns="http://www.w3.org/2000/svg"
              class="warning-svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13 8C13 10.7614 10.7614 13 8 13V8V3C10.7614 3 13 5.23858 13 8ZM8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2Z"
              ></path>
            </svg>
            <p class="small-text muted-text">Website URL is required.</p>
          </div>
        </div>
        <div class="filed mt-3">
          <div class="title">Smart Prefetching</div>
          <div class="mt-2">
            <SwitchToogle
              @click="ToggleSmartPerefetching"
              v-model:checked="smart_prefetching"
              :notAttached="false"
              :paused="false"
              :disabled="false"
            />
          </div>
        </div>
      </v-col>
      <v-divider vertical></v-divider>
      <!-- review container  -->
      <v-col cols="5">
        <div class="">
          <div class="filed mt-3">
            <div class="title">Placement Preview</div>
            <div class="mt-2">
              <v-select
                variant="solo"
                class="v-select-style"
                v-model="placement_review"
                :items="[
                  { title: 'Stories', value: 'stories' },
                  { title: 'Spotlight', value: 'spotlight' },
                ]"
                item-title="title"
                item-value="value"
              ></v-select>
            </div>
          </div>
          <div class="mt-3 d-flex justify-center">
            <div
              @mouseenter="playVideo"
              @mouseleave="pauseVideo"
              v-if="placement_review == 'stories'"
              class="data-review"
            >
              <div class="header">
                <svg viewBox="0 0 394 49" xmlns="http://www.w3.org/2000/svg">
                  <rect transform="translate(0.5 0.753906)" fill="black"></rect>
                  <path
                    opacity="0.5"
                    d="M365.281 24.7539V28.7847C365.892 28.4433 366.289 27.6491 366.289 26.7693C366.289 25.8894 365.892 25.0953 365.281 24.7539Z"
                    fill="#ECECEE"
                  ></path>
                  <rect
                    opacity="0.5"
                    x="339.586"
                    y="21.2265"
                    width="24.1846"
                    height="11.0846"
                    rx="3.52692"
                    stroke="#ECECEE"
                    stroke-width="1.00769"
                  ></rect>
                  <rect
                    x="341.098"
                    y="22.7383"
                    width="21.1615"
                    height="8.06154"
                    rx="2.01538"
                    fill="#ECECEE"
                  ></rect>
                  <path
                    d="M323.464 23.234C325.95 23.2341 328.34 24.173 330.142 25.8567C330.277 25.9867 330.494 25.985 330.628 25.853L331.924 24.5665C331.992 24.4995 332.03 24.4088 332.029 24.3144C332.029 24.2201 331.99 24.1298 331.921 24.0636C327.193 19.609 319.734 19.609 315.006 24.0636C314.938 24.1298 314.899 24.22 314.898 24.3144C314.898 24.4087 314.935 24.4995 315.003 24.5665L316.3 25.853C316.434 25.9852 316.651 25.9869 316.786 25.8567C318.588 24.1729 320.979 23.234 323.464 23.234ZM323.464 27.4197C324.83 27.4196 326.147 27.9186 327.159 28.8198C327.296 28.9477 327.512 28.9449 327.645 28.8135L328.94 27.527C329.009 27.4595 329.046 27.368 329.045 27.2728C329.044 27.1777 329.005 27.0869 328.935 27.0208C325.853 24.2018 321.079 24.2018 317.996 27.0208C317.926 27.0869 317.887 27.1778 317.886 27.2729C317.885 27.3681 317.923 27.4596 317.991 27.527L319.286 28.8135C319.419 28.9449 319.635 28.9477 319.772 28.8198C320.784 27.9192 322.1 27.4202 323.464 27.4197ZM325.953 30.4901C326.023 30.4233 326.061 30.3313 326.059 30.2359C326.057 30.1405 326.015 30.0501 325.943 29.9861C324.512 28.7964 322.417 28.7964 320.986 29.9861C320.914 30.0501 320.872 30.1404 320.87 30.2358C320.868 30.3312 320.906 30.4232 320.975 30.4901L323.216 32.7129C323.281 32.7782 323.371 32.815 323.464 32.815C323.558 32.815 323.647 32.7782 323.713 32.7129L325.953 30.4901Z"
                    fill="#ECECEE"
                  ></path>
                  <path
                    d="M288.877 28.2803H290.062C290.717 28.2803 291.248 28.7879 291.248 29.414V31.6813C291.248 32.3074 290.717 32.815 290.062 32.815H288.877C288.222 32.815 287.691 32.3074 287.691 31.6813V29.414C287.691 28.7879 288.222 28.2803 288.877 28.2803ZM294.409 26.013H295.595C296.25 26.013 296.78 26.5206 296.78 27.1467V31.6813C296.78 32.3074 296.25 32.815 295.595 32.815H294.409C293.755 32.815 293.224 32.3074 293.224 31.6813V27.1467C293.224 26.5206 293.755 26.013 294.409 26.013ZM299.942 23.3678H301.127C301.782 23.3678 302.313 23.8754 302.313 24.5015V31.6813C302.313 32.3074 301.782 32.815 301.127 32.815H299.942C299.287 32.815 298.756 32.3074 298.756 31.6813V24.5015C298.756 23.8754 299.287 23.3678 299.942 23.3678ZM305.474 20.7227H306.66C307.314 20.7227 307.845 21.2302 307.845 21.8563V31.6813C307.845 32.3074 307.314 32.815 306.66 32.815H305.474C304.819 32.815 304.289 32.3074 304.289 31.6813V21.8563C304.289 21.2302 304.819 20.7227 305.474 20.7227Z"
                    fill="#ECECEE"
                  ></path>
                  <path
                    d="M47.44 32.7227V22.8273H47.2978L44.2949 24.9352V22.8608L47.4484 20.6525H49.5981V32.7227H47.44ZM55.9385 32.7227V22.8273H55.7963L52.7934 24.9352V22.8608L55.9469 20.6525H58.0966V32.7227H55.9385ZM63.0819 31.0581C62.2956 31.0581 61.7435 30.4893 61.7435 29.7365C61.7435 28.9753 62.2956 28.4149 63.0819 28.4149C63.8765 28.4149 64.4202 28.9753 64.4202 29.7365C64.4202 30.4893 63.8765 31.0581 63.0819 31.0581ZM63.0819 24.9519C62.2956 24.9519 61.7435 24.3915 61.7435 23.6387C61.7435 22.8775 62.2956 22.3171 63.0819 22.3171C63.8765 22.3171 64.4202 22.8775 64.4202 23.6387C64.4202 24.3915 63.8765 24.9519 63.0819 24.9519ZM71.5134 33.0238C68.6193 33.0238 66.9045 30.6817 66.9045 26.675C66.9045 22.6851 68.6444 20.3514 71.5134 20.3514C74.3741 20.3514 76.114 22.6851 76.114 26.6918C76.114 30.6817 74.3992 33.0238 71.5134 33.0238ZM71.5134 31.2338C73.0442 31.2338 73.9308 29.6696 73.9308 26.675C73.9308 23.6972 73.0274 22.1498 71.5134 22.1498C69.9994 22.1498 69.0877 23.7056 69.0877 26.675C69.0877 29.6696 69.9827 31.2338 71.5134 31.2338ZM84.0688 32.7227V30.4224H78.1717V28.624C79.7359 25.8971 81.4506 23.1452 83.0901 20.6525H86.1348V28.5989H87.7659V30.4224H86.1348V32.7227H84.0688ZM80.1708 28.532V28.6491H84.1022V22.4091H83.9851C82.7471 24.2827 81.3502 26.4994 80.1708 28.532Z"
                    fill="#ECECEE"
                  ></path>
                </svg>
              </div>
              <div
                class="body d-flex align-center justify-space-between flex-column position-relative"
              >
                <div
                  class="d-flex align-center justify-space-between pa-2 stories-top-profile"
                >
                  <div class="img-name d-flex align-center ga-2">
                    <img
                      width="28"
                      src="https://cf-st.sc-cdn.net/d/YmmIFdcbee3keYymYPlJq?bo=Eg0aABoAMgEESAJQCGAB&uc=8"
                      alt=""
                    />
                    <span class="text-body-2"
                      ><span class="font-weight-bold">{{ brand_name }}</span
                      >. <br />
                      {{ headline }}</span
                    >
                    <div class="text-caption">Ad</div>
                  </div>
                  <div class="">
                    <svg
                      class="styles-yedxbn"
                      width="108"
                      height="108"
                      viewBox="0 0 108 108"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g filter="url(#filter-more)">
                        <g filter="url(#filter1_d_101_2377)">
                          <path
                            d="M46.5 36C46.5 40.1421 49.8579 43.5 54 43.5C58.1421 43.5 61.5 40.1421 61.5 36C61.5 31.8579 58.1421 28.5 54 28.5C49.8579 28.5 46.5 31.8579 46.5 36Z"
                            fill="white"
                          ></path>
                          <path
                            d="M46.5 57C46.5 61.1421 49.8579 64.5 54 64.5C58.1421 64.5 61.5 61.1421 61.5 57C61.5 52.8579 58.1421 49.5 54 49.5C49.8579 49.5 46.5 52.8579 46.5 57Z"
                            fill="white"
                          ></path>
                          <path
                            d="M54 85.5C49.8579 85.5 46.5 82.1421 46.5 78C46.5 73.8579 49.8579 70.5 54 70.5C58.1421 70.5 61.5 73.8579 61.5 78C61.5 82.1421 58.1421 85.5 54 85.5Z"
                            fill="white"
                          ></path>
                        </g>
                      </g>
                      <defs>
                        <filter
                          id="#filter-more"
                          x="-120"
                          y="-120"
                          width="348"
                          height="348"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          ></feFlood>
                          <feGaussianBlur
                            in="BackgroundImageFix"
                            stdDeviation="60"
                          ></feGaussianBlur>
                          <feComposite
                            in2="SourceAlpha"
                            operator="in"
                            result="effect1_backgroundBlur_101_2377"
                          ></feComposite>
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_backgroundBlur_101_2377"
                            result="shape"
                          ></feBlend>
                        </filter>
                        <filter
                          id="filter1_d_101_2377"
                          x="42.5"
                          y="25.5"
                          width="23"
                          height="65"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          ></feFlood>
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          ></feColorMatrix>
                          <feOffset dy="1"></feOffset>
                          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                          ></feColorMatrix>
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_101_2377"
                          ></feBlend>
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_101_2377"
                            result="shape"
                          ></feBlend>
                        </filter>
                      </defs>
                    </svg>
                  </div>
                </div>
                <div
                  class="data"
                  :style="
                    image_url ? { backgroundImage: `url(${image_url})` } : ''
                  "
                >
                  <div v-if="video_url" class="video-data">
                    <video muted loop id="myVideo">
                      <source :src="video_url" type="video/mp4" />
                    </video>
                  </div>

                  <div class="bottom">
                    <div class="profile-favoriting" style="gap: 10px">
                      <div
                        class="profile-favoriting-data d-flex align-center justify-space-between"
                      >
                        <div class="collection-data d-flex ga-3 align-center">
                          <span
                            class="collection"
                            v-for="(collection, index) in collection_data"
                            :key="index"
                          >
                            <img
                              v-if="collection.image_url"
                              :src="collection.image_url"
                              alt=""
                            />
                          </span>
                        </div>
                      </div>
                      <div class="profile-favoriting-favoriting">
                        <div v-if="ad_favoriting" class="svg">
                          <svg
                            width="48"
                            height="48"
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            class="styles-5mtvke"
                          >
                            <g clip-path="url(#clip)">
                              <g filter="url(#filter-heart)">
                                <path
                                  d="M24 15.1088C32.4677 4.43472 44.9916 15.6853 37.9154 26.6395C35.7984 30.8893 29.0331 36.0287 25.347 38.6643C24.9434 38.9465 24.4766 39.0963 24 39.0963C23.5233 39.0963 23.0563 38.9465 22.6528 38.6643C18.8926 36.0287 12.2013 30.8893 10.0844 26.6395C2.99341 15.6853 15.5914 4.43472 24 15.1088Z"
                                  fill="white"
                                ></path>
                                <path
                                  d="M25.6336 39.074L25.6336 39.0741L25.6378 39.071C27.4865 37.7492 30.1156 35.7937 32.5516 33.6311C34.9651 31.4884 37.2414 29.0975 38.3504 26.8875C41.9976 21.2197 40.6125 15.4001 37.113 12.3848C35.3639 10.8776 33.0877 10.0769 30.6811 10.3706C28.4155 10.6471 26.0857 11.8866 24.0013 14.3211C21.9299 11.8866 19.6058 10.6471 17.3418 10.3706C14.9367 10.0769 12.6575 10.8776 10.904 12.3843C7.39559 15.3989 5.994 21.2187 9.6495 26.8877C10.7579 29.0965 13.0144 31.4869 15.4197 33.6305C17.847 35.7937 20.4769 37.7498 22.3658 39.0737L22.3662 39.074C22.8508 39.413 23.4173 39.5963 24 39.5963C24.5827 39.5963 25.149 39.4129 25.6336 39.074Z"
                                  stroke="black"
                                  stroke-opacity="0.05"
                                  stroke-miterlimit="10"
                                ></path>
                              </g>
                            </g>
                            <defs>
                              <filter
                                id="filter-heart"
                                x="-2"
                                y="-1.18945"
                                width="52"
                                height="52"
                                filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB"
                              >
                                <feFlood
                                  flood-opacity="0"
                                  result="BackgroundImageFix"
                                ></feFlood>
                                <feColorMatrix
                                  in="SourceAlpha"
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                  result="hardAlpha"
                                ></feColorMatrix>
                                <feOffset></feOffset>
                                <feGaussianBlur
                                  stdDeviation="4"
                                ></feGaussianBlur>
                                <feColorMatrix
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                                ></feColorMatrix>
                                <feBlend
                                  mode="normal"
                                  in2="BackgroundImageFix"
                                  result="effect1_dropShadow_3_13290"
                                ></feBlend>
                                <feBlend
                                  mode="normal"
                                  in="SourceGraphic"
                                  in2="effect1_dropShadow_3_13290"
                                  result="shape"
                                ></feBlend>
                              </filter>
                              <clipPath id="clip">
                                <rect
                                  width="48"
                                  height="48"
                                  fill="white"
                                  transform="translate(0 0.810547)"
                                ></rect>
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <div v-else class="ad-text">ad</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="standard-button d-flex align-center ga-2 pa-2">
                <span
                  :class="
                    standard_button_color ? 'button standard-color' : ' button'
                  "
                  >{{ call_to_action }}</span
                >
                <span class="share">
                  <svg
                    class="share-svg"
                    width="156"
                    height="156"
                    viewBox="0 0 156 156"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g filter="url(#filter-go)">
                      <path
                        d="M82.3862 39.7208L118.027 69.4408L117.987 69.4008C120.504 71.5208 121.982 74.6408 121.982 77.9608C121.982 81.2808 120.544 84.4008 117.987 86.5208L82.3862 116.201C80.9878 117.361 79.3096 117.961 77.5915 117.961C76.5127 117.961 75.4339 117.721 74.395 117.241C71.758 116.001 70.0798 113.401 70.0798 110.441V94.0408C62.0487 94.3208 50.4215 96.2008 41.3116 108.361C40.3127 109.681 38.6346 110.241 37.0363 109.801C35.4381 109.361 34.2794 108.001 34.1196 106.361C34.0396 105.561 32.2816 86.6008 44.0686 73.6008C50.3416 66.6808 59.052 62.8008 70.0798 62.0808V45.4808C70.0798 42.5208 71.718 39.9208 74.395 38.6808C77.0721 37.4408 80.1087 37.8408 82.3862 39.7208Z"
                        fill="white"
                      ></path>
                    </g>
                    <defs>
                      <filter
                        id="filter-go"
                        x="6"
                        y="12"
                        width="144"
                        height="144"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        ></feFlood>
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        ></feColorMatrix>
                        <feOffset dy="6"></feOffset>
                        <feGaussianBlur stdDeviation="12"></feGaussianBlur>
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
                        ></feColorMatrix>
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_108_1540"
                        ></feBlend>
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_108_1540"
                          result="shape"
                        ></feBlend>
                      </filter>
                    </defs>
                  </svg>
                </span>
              </div>
              <div class="footer-data d-flex justify-center align-center">
                <div class="mobile-button"></div>
              </div>
            </div>
            <div
              @mouseenter="playVideo"
              @mouseleave="pauseVideo"
              v-if="placement_review == 'spotlight'"
              class="data-review spotlight-review"
            >
              <div class="header">
                <svg viewBox="0 0 394 49" xmlns="http://www.w3.org/2000/svg">
                  <rect transform="translate(0.5 0.753906)" fill="black"></rect>
                  <path
                    opacity="0.5"
                    d="M365.281 24.7539V28.7847C365.892 28.4433 366.289 27.6491 366.289 26.7693C366.289 25.8894 365.892 25.0953 365.281 24.7539Z"
                    fill="#ECECEE"
                  ></path>
                  <rect
                    opacity="0.5"
                    x="339.586"
                    y="21.2265"
                    width="24.1846"
                    height="11.0846"
                    rx="3.52692"
                    stroke="#ECECEE"
                    stroke-width="1.00769"
                  ></rect>
                  <rect
                    x="341.098"
                    y="22.7383"
                    width="21.1615"
                    height="8.06154"
                    rx="2.01538"
                    fill="#ECECEE"
                  ></rect>
                  <path
                    d="M323.464 23.234C325.95 23.2341 328.34 24.173 330.142 25.8567C330.277 25.9867 330.494 25.985 330.628 25.853L331.924 24.5665C331.992 24.4995 332.03 24.4088 332.029 24.3144C332.029 24.2201 331.99 24.1298 331.921 24.0636C327.193 19.609 319.734 19.609 315.006 24.0636C314.938 24.1298 314.899 24.22 314.898 24.3144C314.898 24.4087 314.935 24.4995 315.003 24.5665L316.3 25.853C316.434 25.9852 316.651 25.9869 316.786 25.8567C318.588 24.1729 320.979 23.234 323.464 23.234ZM323.464 27.4197C324.83 27.4196 326.147 27.9186 327.159 28.8198C327.296 28.9477 327.512 28.9449 327.645 28.8135L328.94 27.527C329.009 27.4595 329.046 27.368 329.045 27.2728C329.044 27.1777 329.005 27.0869 328.935 27.0208C325.853 24.2018 321.079 24.2018 317.996 27.0208C317.926 27.0869 317.887 27.1778 317.886 27.2729C317.885 27.3681 317.923 27.4596 317.991 27.527L319.286 28.8135C319.419 28.9449 319.635 28.9477 319.772 28.8198C320.784 27.9192 322.1 27.4202 323.464 27.4197ZM325.953 30.4901C326.023 30.4233 326.061 30.3313 326.059 30.2359C326.057 30.1405 326.015 30.0501 325.943 29.9861C324.512 28.7964 322.417 28.7964 320.986 29.9861C320.914 30.0501 320.872 30.1404 320.87 30.2358C320.868 30.3312 320.906 30.4232 320.975 30.4901L323.216 32.7129C323.281 32.7782 323.371 32.815 323.464 32.815C323.558 32.815 323.647 32.7782 323.713 32.7129L325.953 30.4901Z"
                    fill="#ECECEE"
                  ></path>
                  <path
                    d="M288.877 28.2803H290.062C290.717 28.2803 291.248 28.7879 291.248 29.414V31.6813C291.248 32.3074 290.717 32.815 290.062 32.815H288.877C288.222 32.815 287.691 32.3074 287.691 31.6813V29.414C287.691 28.7879 288.222 28.2803 288.877 28.2803ZM294.409 26.013H295.595C296.25 26.013 296.78 26.5206 296.78 27.1467V31.6813C296.78 32.3074 296.25 32.815 295.595 32.815H294.409C293.755 32.815 293.224 32.3074 293.224 31.6813V27.1467C293.224 26.5206 293.755 26.013 294.409 26.013ZM299.942 23.3678H301.127C301.782 23.3678 302.313 23.8754 302.313 24.5015V31.6813C302.313 32.3074 301.782 32.815 301.127 32.815H299.942C299.287 32.815 298.756 32.3074 298.756 31.6813V24.5015C298.756 23.8754 299.287 23.3678 299.942 23.3678ZM305.474 20.7227H306.66C307.314 20.7227 307.845 21.2302 307.845 21.8563V31.6813C307.845 32.3074 307.314 32.815 306.66 32.815H305.474C304.819 32.815 304.289 32.3074 304.289 31.6813V21.8563C304.289 21.2302 304.819 20.7227 305.474 20.7227Z"
                    fill="#ECECEE"
                  ></path>
                  <path
                    d="M47.44 32.7227V22.8273H47.2978L44.2949 24.9352V22.8608L47.4484 20.6525H49.5981V32.7227H47.44ZM55.9385 32.7227V22.8273H55.7963L52.7934 24.9352V22.8608L55.9469 20.6525H58.0966V32.7227H55.9385ZM63.0819 31.0581C62.2956 31.0581 61.7435 30.4893 61.7435 29.7365C61.7435 28.9753 62.2956 28.4149 63.0819 28.4149C63.8765 28.4149 64.4202 28.9753 64.4202 29.7365C64.4202 30.4893 63.8765 31.0581 63.0819 31.0581ZM63.0819 24.9519C62.2956 24.9519 61.7435 24.3915 61.7435 23.6387C61.7435 22.8775 62.2956 22.3171 63.0819 22.3171C63.8765 22.3171 64.4202 22.8775 64.4202 23.6387C64.4202 24.3915 63.8765 24.9519 63.0819 24.9519ZM71.5134 33.0238C68.6193 33.0238 66.9045 30.6817 66.9045 26.675C66.9045 22.6851 68.6444 20.3514 71.5134 20.3514C74.3741 20.3514 76.114 22.6851 76.114 26.6918C76.114 30.6817 74.3992 33.0238 71.5134 33.0238ZM71.5134 31.2338C73.0442 31.2338 73.9308 29.6696 73.9308 26.675C73.9308 23.6972 73.0274 22.1498 71.5134 22.1498C69.9994 22.1498 69.0877 23.7056 69.0877 26.675C69.0877 29.6696 69.9827 31.2338 71.5134 31.2338ZM84.0688 32.7227V30.4224H78.1717V28.624C79.7359 25.8971 81.4506 23.1452 83.0901 20.6525H86.1348V28.5989H87.7659V30.4224H86.1348V32.7227H84.0688ZM80.1708 28.532V28.6491H84.1022V22.4091H83.9851C82.7471 24.2827 81.3502 26.4994 80.1708 28.532Z"
                    fill="#ECECEE"
                  ></path>
                </svg>
                <div class="w-100 px-5">
                  <img
                    src="@/assets/snapchatImage/spotlight-header.svg"
                    width="100%"
                    alt=""
                  />
                </div>
              </div>
              <div
                class="body d-flex align-center justify-space-between flex-column position-relative"
              >
                <div
                  class="data"
                  :style="
                    image_url ? { backgroundImage: `url(${image_url})` } : ''
                  "
                >
                  <div v-if="video_url" class="video-data">
                    <video muted loop id="myVideo">
                      <source :src="video_url" type="video/mp4" />
                    </video>
                  </div>

                  <div class="bottom">
                    <div class="profile-favoriting">
                      <div
                        class="spotlight-profile-standard-button d-flex flex-column align-start justify-space-between"
                      >
                        <div class="profile d-flex align-center ga-3">
                          <div class="img">
                            <img
                              width="20"
                              src="https://cf-st.sc-cdn.net/d/YmmIFdcbee3keYymYPlJq?bo=Eg0aABoAMgEESAJQCGAB&uc=8"
                              alt=""
                            />
                          </div>
                          <div class="name">
                            <span class="text-body-2"
                              ><span class="font-weight-bold">{{
                                brand_name
                              }}</span
                              >. <br />
                              <span class="small-text">{{
                                headline
                              }}</span></span
                            >
                          </div>
                        </div>
                        <div
                          class="d-flex align-center justify-space-between w-100 spotlight-details"
                        >
                          <span class="text"></span>
                          <span class="ad">ad</span>
                        </div>
                        <div class="collection-data d-flex ga-3 align-center">
                          <span
                            class="collection"
                            v-for="(collection, index) in collection_data"
                            :key="index"
                          >
                            <img
                              v-if="collection.image_url"
                              :src="collection.image_url"
                              alt=""
                            />
                          </span>
                        </div>
                        <div class="standard-button d-flex align-center ga-2">
                          <span
                            :class="
                              standard_button_color
                                ? 'button standard-color'
                                : ' button'
                            "
                            >{{ call_to_action }}</span
                          >
                        </div>
                      </div>

                      <div class="profile-favoriting-favoriting">
                        <div v-if="ad_favoriting" class="svg">
                          <svg
                            width="48"
                            height="48"
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            class="styles-5mtvke"
                          >
                            <g clip-path="url(#clip)">
                              <g filter="url(#filter-heart)">
                                <path
                                  d="M24 15.1088C32.4677 4.43472 44.9916 15.6853 37.9154 26.6395C35.7984 30.8893 29.0331 36.0287 25.347 38.6643C24.9434 38.9465 24.4766 39.0963 24 39.0963C23.5233 39.0963 23.0563 38.9465 22.6528 38.6643C18.8926 36.0287 12.2013 30.8893 10.0844 26.6395C2.99341 15.6853 15.5914 4.43472 24 15.1088Z"
                                  fill="white"
                                ></path>
                                <path
                                  d="M25.6336 39.074L25.6336 39.0741L25.6378 39.071C27.4865 37.7492 30.1156 35.7937 32.5516 33.6311C34.9651 31.4884 37.2414 29.0975 38.3504 26.8875C41.9976 21.2197 40.6125 15.4001 37.113 12.3848C35.3639 10.8776 33.0877 10.0769 30.6811 10.3706C28.4155 10.6471 26.0857 11.8866 24.0013 14.3211C21.9299 11.8866 19.6058 10.6471 17.3418 10.3706C14.9367 10.0769 12.6575 10.8776 10.904 12.3843C7.39559 15.3989 5.994 21.2187 9.6495 26.8877C10.7579 29.0965 13.0144 31.4869 15.4197 33.6305C17.847 35.7937 20.4769 37.7498 22.3658 39.0737L22.3662 39.074C22.8508 39.413 23.4173 39.5963 24 39.5963C24.5827 39.5963 25.149 39.4129 25.6336 39.074Z"
                                  stroke="black"
                                  stroke-opacity="0.05"
                                  stroke-miterlimit="10"
                                ></path>
                              </g>
                            </g>
                            <defs>
                              <filter
                                id="filter-heart"
                                x="-2"
                                y="-1.18945"
                                width="52"
                                height="52"
                                filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB"
                              >
                                <feFlood
                                  flood-opacity="0"
                                  result="BackgroundImageFix"
                                ></feFlood>
                                <feColorMatrix
                                  in="SourceAlpha"
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                  result="hardAlpha"
                                ></feColorMatrix>
                                <feOffset></feOffset>
                                <feGaussianBlur
                                  stdDeviation="4"
                                ></feGaussianBlur>
                                <feColorMatrix
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                                ></feColorMatrix>
                                <feBlend
                                  mode="normal"
                                  in2="BackgroundImageFix"
                                  result="effect1_dropShadow_3_13290"
                                ></feBlend>
                                <feBlend
                                  mode="normal"
                                  in="SourceGraphic"
                                  in2="effect1_dropShadow_3_13290"
                                  result="shape"
                                ></feBlend>
                              </filter>
                              <clipPath id="clip">
                                <rect
                                  width="48"
                                  height="48"
                                  fill="white"
                                  transform="translate(0 0.810547)"
                                ></rect>
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <div class="svg">
                          <svg
                            class="styles-3jo2gc"
                            width="48"
                            height="48"
                            viewBox="0 0 156 156"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g filter="url(#filter-go)">
                              <path
                                d="M82.3862 39.7208L118.027 69.4408L117.987 69.4008C120.504 71.5208 121.982 74.6408 121.982 77.9608C121.982 81.2808 120.544 84.4008 117.987 86.5208L82.3862 116.201C80.9878 117.361 79.3096 117.961 77.5915 117.961C76.5127 117.961 75.4339 117.721 74.395 117.241C71.758 116.001 70.0798 113.401 70.0798 110.441V94.0408C62.0487 94.3208 50.4215 96.2008 41.3116 108.361C40.3127 109.681 38.6346 110.241 37.0363 109.801C35.4381 109.361 34.2794 108.001 34.1196 106.361C34.0396 105.561 32.2816 86.6008 44.0686 73.6008C50.3416 66.6808 59.052 62.8008 70.0798 62.0808V45.4808C70.0798 42.5208 71.718 39.9208 74.395 38.6808C77.0721 37.4408 80.1087 37.8408 82.3862 39.7208Z"
                                fill="white"
                              ></path>
                            </g>
                            <defs>
                              <filter
                                id="filter-go"
                                x="6"
                                y="12"
                                width="144"
                                height="144"
                                filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB"
                              >
                                <feFlood
                                  flood-opacity="0"
                                  result="BackgroundImageFix"
                                ></feFlood>
                                <feColorMatrix
                                  in="SourceAlpha"
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                  result="hardAlpha"
                                ></feColorMatrix>
                                <feOffset dy="6"></feOffset>
                                <feGaussianBlur
                                  stdDeviation="12"
                                ></feGaussianBlur>
                                <feColorMatrix
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
                                ></feColorMatrix>
                                <feBlend
                                  mode="normal"
                                  in2="BackgroundImageFix"
                                  result="effect1_dropShadow_108_1540"
                                ></feBlend>
                                <feBlend
                                  mode="normal"
                                  in="SourceGraphic"
                                  in2="effect1_dropShadow_108_1540"
                                  result="shape"
                                ></feBlend>
                              </filter>
                            </defs>
                          </svg>
                        </div>
                        <div class="svg">
                          <svg
                            class="styles-3jo2gc"
                            width="48"
                            height="48"
                            viewBox="0 0 156 156"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g filter="url(#filter-more)">
                              <path
                                d="M48 88.001C53.5228 88.001 58 83.5238 58 78.001C58 72.4781 53.5228 68.001 48 68.001C42.4771 68.001 38 72.4781 38 78.001C38 83.5238 42.4771 88.001 48 88.001Z"
                                fill="white"
                              ></path>
                              <path
                                d="M78 88.001C83.5228 88.001 88 83.5238 88 78.001C88 72.4781 83.5228 68.001 78 68.001C72.4771 68.001 68 72.4781 68 78.001C68 83.5238 72.4771 88.001 78 88.001Z"
                                fill="white"
                              ></path>
                              <path
                                d="M118 78.001C118 83.5238 113.523 88.001 108 88.001C102.477 88.001 98 83.5238 98 78.001C98 72.4781 102.477 68.001 108 68.001C113.523 68.001 118 72.4781 118 78.001Z"
                                fill="white"
                              ></path>
                            </g>
                            <defs>
                              <filter
                                id="filter-more"
                                x="6"
                                y="12"
                                width="144"
                                height="144"
                                filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB"
                              >
                                <feFlood
                                  flood-opacity="0"
                                  result="BackgroundImageFix"
                                ></feFlood>
                                <feColorMatrix
                                  in="SourceAlpha"
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                  result="hardAlpha"
                                ></feColorMatrix>
                                <feOffset dy="6"></feOffset>
                                <feGaussianBlur
                                  stdDeviation="12"
                                ></feGaussianBlur>
                                <feColorMatrix
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
                                ></feColorMatrix>
                                <feBlend
                                  mode="normal"
                                  in2="BackgroundImageFix"
                                  result="effect1_dropShadow_101_1614"
                                ></feBlend>
                                <feBlend
                                  mode="normal"
                                  in="SourceGraphic"
                                  in2="effect1_dropShadow_101_1614"
                                  result="shape"
                                ></feBlend>
                              </filter>
                            </defs>
                          </svg>
                        </div>
                        <div class="ad-text">ad</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="footer-data d-flex flex-column justify-center align-center"
              >
                <div class="w-100" style="z-index: 1">
                  <img src="@/assets/snapchatImage/1.svg" alt="" />
                </div>
                <div class="mobile-button w-80"></div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
  <v-container style="padding: 15px 0px">
    <div class="budget-and-schedule ad-set-details-card mb-6">
      <h6 class="page-header-title text-h6 mb-3">Collection</h6>
      <v-card
        max-width="100%"
        class="pa-5 mb-5 rounded-lg budget-and-schedule-card"
        elevation="1"
      >
        <div class="">
          <div class="filed mt-3">
            <div class="d-flex ga-3">
              <span
                v-for="(collection, index) in collection_data"
                :key="index"
                @click="OpenStorySnap(index)"
                :class="
                  collection.active
                    ? 'story-snaps-template position-relative active'
                    : 'story-snaps-template position-relative'
                "
                :style="{
                  backgroundImage:
                    collection.image_url && collection.image_url !== ''
                      ? `url(${collection.image_url})`
                      : 'url($(@/assets/snapchatImage/placeholder.png))',
                }"
              >
                <span v-if="collection.image_url === ''">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="white"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 13.8643V6.77002C17 6.47363 16.9321 6.19312 16.8115 5.94287L17.5933 5.28687C17.8516 5.72095 18 6.22827 18 6.77002V13.8643C18 15.521 16.6567 16.8643 15 16.8643H3.7959L4.98779 15.8643H15C16.1045 15.8643 17 14.9688 17 13.8643Z"
                    />
                    <path
                      d="M1.00342 13.9856C1.00098 13.9456 1 13.905 1 13.8643V6.77002C1 5.71753 1.85303 4.86426 2.90576 4.86426H3.41895C4.79346 4.86426 6.01416 3.98462 6.44873 2.68042C6.48486 2.57227 6.53516 2.47192 6.59766 2.3811C6.73145 2.18677 6.91992 2.03687 7.1377 1.94971C7.27588 1.89429 7.42578 1.86426 7.58105 1.86426H10.4189C10.9326 1.86426 11.3887 2.19312 11.5513 2.68042C11.7598 3.30591 12.1489 3.83374 12.6494 4.21362L13.4443 3.54663C13.0098 3.28296 12.6689 2.87012 12.5 2.36426C12.2012 1.46851 11.3633 0.864258 10.4189 0.864258H7.58105C6.63672 0.864258 5.79883 1.46851 5.5 2.36426C5.20117 3.26001 4.36328 3.86426 3.41895 3.86426H2.90576C1.30078 3.86426 0 5.16528 0 6.77002V13.8643C0 14.1626 0.043457 14.4509 0.124512 14.7229L1.00342 13.9856Z"
                    />
                    <path
                      d="M5.06006 10.5818C5.02051 10.356 5 10.1235 5 9.88647C5 7.67725 6.79102 5.88647 9 5.88647C9.48096 5.88647 9.94189 5.97144 10.3691 6.12695L9.42773 6.91675C9.28809 6.89673 9.14551 6.88647 9 6.88647C7.375 6.88647 6.05127 8.17871 6.00146 9.79175L5.06006 10.5818Z"
                    />
                    <path
                      d="M7.63135 13.6462C8.05859 13.8018 8.51904 13.8865 9 13.8865C11.209 13.8865 13 12.0957 13 9.88647C13 9.64941 12.9795 9.41724 12.9399 9.19165L11.9985 9.98145C11.9482 11.5942 10.625 12.8865 9 12.8865C8.85498 12.8865 8.7124 12.8762 8.57275 12.8562L7.63135 13.6462Z"
                    />
                    <path
                      d="M0.573835 16.957C0.396334 16.7455 0.423926 16.4301 0.635464 16.2526L16.7224 2.75404C16.9339 2.57654 17.2493 2.60413 17.4268 2.81567C17.6043 3.02721 17.5767 3.34258 17.3652 3.52008L1.27825 17.0186C1.06671 17.1961 0.751336 17.1685 0.573835 16.957Z"
                    />
                  </svg>
                </span>
                <span class="validate"></span>
              </span>
            </div>
          </div>
          <div v-for="(collection, index) in collection_data" :key="index">
            <div v-if="activeStorySnapIndex === index">
              <div class="filed mt-3">
                <div class="mt-3">
                  <div class="d-flex ga-3">
                    <div class="overflow-hidden rounded">
                      <div
                        class="d-flex ga-2 justify-center align-center rounded"
                        style="
                          background-color: black;
                          height: 120px;
                          width: 120px;
                          overflow: hidden;
                        "
                      >
                        <img
                          v-if="collection.image_url != ''"
                          :src="collection.image_url"
                          width="100%"
                          height="100%"
                          style="border-radius: 10px"
                          alt=""
                        />
                        <span
                          v-else
                          class="d-flex justify-center align-center"
                          style="height: 120px; width: 120px"
                        >
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="white"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M17 13.8643V6.77002C17 6.47363 16.9321 6.19312 16.8115 5.94287L17.5933 5.28687C17.8516 5.72095 18 6.22827 18 6.77002V13.8643C18 15.521 16.6567 16.8643 15 16.8643H3.7959L4.98779 15.8643H15C16.1045 15.8643 17 14.9688 17 13.8643Z"
                            />
                            <path
                              d="M1.00342 13.9856C1.00098 13.9456 1 13.905 1 13.8643V6.77002C1 5.71753 1.85303 4.86426 2.90576 4.86426H3.41895C4.79346 4.86426 6.01416 3.98462 6.44873 2.68042C6.48486 2.57227 6.53516 2.47192 6.59766 2.3811C6.73145 2.18677 6.91992 2.03687 7.1377 1.94971C7.27588 1.89429 7.42578 1.86426 7.58105 1.86426H10.4189C10.9326 1.86426 11.3887 2.19312 11.5513 2.68042C11.7598 3.30591 12.1489 3.83374 12.6494 4.21362L13.4443 3.54663C13.0098 3.28296 12.6689 2.87012 12.5 2.36426C12.2012 1.46851 11.3633 0.864258 10.4189 0.864258H7.58105C6.63672 0.864258 5.79883 1.46851 5.5 2.36426C5.20117 3.26001 4.36328 3.86426 3.41895 3.86426H2.90576C1.30078 3.86426 0 5.16528 0 6.77002V13.8643C0 14.1626 0.043457 14.4509 0.124512 14.7229L1.00342 13.9856Z"
                            />
                            <path
                              d="M5.06006 10.5818C5.02051 10.356 5 10.1235 5 9.88647C5 7.67725 6.79102 5.88647 9 5.88647C9.48096 5.88647 9.94189 5.97144 10.3691 6.12695L9.42773 6.91675C9.28809 6.89673 9.14551 6.88647 9 6.88647C7.375 6.88647 6.05127 8.17871 6.00146 9.79175L5.06006 10.5818Z"
                            />
                            <path
                              d="M7.63135 13.6462C8.05859 13.8018 8.51904 13.8865 9 13.8865C11.209 13.8865 13 12.0957 13 9.88647C13 9.64941 12.9795 9.41724 12.9399 9.19165L11.9985 9.98145C11.9482 11.5942 10.625 12.8865 9 12.8865C8.85498 12.8865 8.7124 12.8762 8.57275 12.8562L7.63135 13.6462Z"
                            />
                            <path
                              d="M0.573835 16.957C0.396334 16.7455 0.423926 16.4301 0.635464 16.2526L16.7224 2.75404C16.9339 2.57654 17.2493 2.60413 17.4268 2.81567C17.6043 3.02721 17.5767 3.34258 17.3652 3.52008L1.27825 17.0186C1.06671 17.1961 0.751336 17.1685 0.573835 16.957Z"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                    <div class="input-data" style="width: 80%">
                      <div class="file">
                        <div class="mb-2 text-subtitle-3">Thumbnail</div>
                        <UploadAndCropImage
                          v-if="collection.image_url === ''"
                          :ImageMinWidthProp="400"
                          :ImageMinHeightProp="400"
                          @update-image="
                            (croppedImage) =>
                              updateCollectionImage(croppedImage, index)
                          "
                        />
                        <span
                          v-if="collection.image_url != ''"
                          class="delete-image hover_btn_blue"
                          @click="collection.image_url = ''"
                        >
                          Remove Image
                        </span>
                      </div>
                      <div class="filed mt-3">
                        <span class="title">Website URL</span>
                        <div class="input-container">
                          <input
                            type="text"
                            v-model="collection.website_url"
                            placeholder="Name Your Website URL"
                          />
                        </div>
                        <div class="warning">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="#444546"
                            xmlns="http://www.w3.org/2000/svg"
                            class="warning-svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M13 8C13 10.7614 10.7614 13 8 13V8V3C10.7614 3 13 5.23858 13 8ZM8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2Z"
                            ></path>
                          </svg>
                          <p class="small-text muted-text">
                            Website URL is required.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </div>
  </v-container>
  <v-container style="padding: 15px 0px">
    <div class="budget-and-schedule ad-set-details-card mb-6">
      <h6 class="page-header-title text-h6 mb-3">Delivery</h6>
      <v-card
        max-width="100%"
        class="pa-5 mb-5 rounded-lg budget-and-schedule-card"
        elevation="1"
      >
        <div class="">
          <div class="filed mt-3">
            <div class="title">Status</div>
            <div class="mt-2">
              <SwitchToogle
                @click="ToggleStatus"
                v-model:checked="status"
                :notAttached="false"
                :paused="false"
                :disabled="false"
              />
            </div>
          </div>
          <div class="filed mt-3">
            <div class="filed mt-3">
              <span class="title">Impression Trackers</span>
              <div class="input-container">
                <div
                  v-for="(impression_tracker, index) in impression_trackers"
                  :key="index"
                  class="impression-trackers-template d-flex align-center ga-3"
                >
                  <input
                    type="text"
                    v-model="impression_trackers[index].value"
                    placeholder="Impression Tracker"
                  />
                  <span
                    class="delete-btn"
                    v-if="impression_trackers.length > 1"
                    @click="removeImpressionTracker(index)"
                    >x</span
                  >
                </div>
                <div>
                  <span class="add-another" @click="addAnotherImpressionTracker"
                    >Add Another</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="filed mt-3">
            <div class="filed mt-3">
              <span class="title">Click Trackers</span>
              <div class="input-container">
                <div
                  v-for="(click_tracker, index) in click_trackers"
                  :key="index"
                  class="click-trackers-template d-flex align-center ga-3"
                >
                  <input
                    type="text"
                    v-model="click_trackers[index].value"
                    placeholder="Click Tracker"
                  />
                  <span
                    v-if="click_trackers.length > 1"
                    class="delete-btn"
                    @click="removeClickTracker(index)"
                    >x</span
                  >
                </div>
                <div>
                  <span class="add-another" @click="addAnotherClickTracker"
                    >Add Another</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </div>
  </v-container>
</template>
<script>
import SwitchToogle from "../Label&input/SwitchToogle.vue";
import UploadAndCropImage from "./UploadAndCropImage.vue";

export default {
  components: { SwitchToogle, UploadAndCropImage },
  data: () => ({
    ad_name: "",
    brand_name: "",
    offer_details_ad_disclaimer: "",
    offer_details_ad_disclaimer_ratio: false,
    shareable: true,
    post_to_public: false,
    image_url: "",
    video_url: "",
    call_to_action_items: [
      {
        title: "Apply Now",
        value: "apply_noe",
      },
      {
        title: "Book Now",
        value: "book_now",
      },
      {
        title: "Buy Now",
        value: "buy_now",
      },
      {
        title: "More",
        value: "more",
      },
    ],
    call_to_action: "more",
    ad_favoriting: true,
    standard_button_color: true,
    website_url: "",
    smart_prefetching: false,
    status: true,
    impression_trackers: [{ value: "" }],
    click_trackers: [{ value: "" }],
    placement_review: "stories",
    current_time: new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    }),
    headline: "",
    collection_data: [
      {
        active: true,
        image_url: "",
        video_url: "",
        website_url: "",
      },
      {
        active: false,
        image_url: "",
        video_url: "",
        website_url: "",
      },
      {
        active: false,
        image_url: "",
        video_url: "",
        website_url: "",
      },
    ],
    activeStorySnapIndex: 0,
  }),
  methods: {
    OpenStorySnap(index) {
      this.activeStorySnapIndex = index;
      this.collection_data.forEach((snap) => {
        snap.active = false;
      });
      this.collection_data[index].active = true;
    },
    createTemplateDialog() {
      alert("createTemplateDialog");
    },
    ToggleShareable() {
      this.ToggleShareable = !this.ToggleShareable;
    },
    updateImage(croppedImage) {
      this.image_url = croppedImage;
    },
    updateVideo(croppedVideo) {
      this.video_url = croppedVideo;
    },
    updateCollectionImage(croppedImage, index) {
      this.collection_data[index].image_url = croppedImage;
    },
    updateCollectionVideo(croppedVideo, index) {
      this.collection_data[index].video_url = croppedVideo;
    },
    TogglePostToPublicProfile() {
      this.TogglePostToPublicProfile = !this.TogglePostToPublicProfile;
    },
    ToggleAdFavoriting() {
      this.ToggleAdFavoriting = !this.ToggleAdFavoriting;
    },
    ToggleStandardButtonColor() {
      this.ToggleStandardButtonColor = !this.ToggleStandardButtonColor;
    },
    ToggleSmartPerefetching() {
      this.ToggleSmartPerefetching = !this.ToggleSmartPerefetching;
    },
    ToggleStatus() {
      this.ToggleStatus = !this.ToggleStatus;
    },
    addAnotherImpressionTracker() {
      this.impression_trackers.push({ value: "" });
    },
    removeImpressionTracker(index) {
      if (this.impression_trackers.length > 1) {
        this.impression_trackers.splice(index, 1);
      }
    },
    addAnotherClickTracker() {
      this.click_trackers.push({ value: "" });
    },
    removeClickTracker(index) {
      if (this.click_trackers.length > 1) {
        this.click_trackers.splice(index, 1);
      }
    },
    playVideo(event) {
      const video = event.target.querySelector("video");
      if (video) video.play();
    },
    pauseVideo(event) {
      const video = event.target.querySelector("video");
      if (video) video.pause();
    },
  },
  watch: {
  },
};
</script>
<style scoped>
.stories-top-profile {
  position: absolute;
  top: 5px;
  width: 100%;
}
.delete-image {
  cursor: pointer;
}
.hover_btn_blue {
  background: unset;
  box-shadow: unset;
  padding: 0px;
  color: #3bf;
  position: relative;
  font-size: 14px;
  font-weight: bold;
  background: none;
  border: none;
  cursor: pointer;
}
.hover_btn_blue:hover {
  border: unset;
  background: unset;
  box-shadow: unset;
  padding: 0px;
}

.hover_btn_blue span {
  position: relative;
  display: inline-block;
}

.hover_btn_blue::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #3bf;
  transition: width 0.3s ease;
}

.hover_btn_blue:hover::before {
  width: 100%;
}
.story-snaps-template {
  -webkit-box-align: center;
  align-items: center;
  aspect-ratio: 9 / 16;
  display: flex;
  flex-direction: row;
  -webkit-box-pack: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 7px;
  background-size: cover;
  background-color: rgb(0, 0, 0);
  position: relative;
}
.story-snaps-template .validate {
  position: absolute;
  top: 0px;
  right: 0px;
  transform: translate(50%, -50%);
  width: 13px;
  height: 13px;
  background-color: red;
  border: white solid 2px;
  border-radius: 50%;
}
.story-snaps-template .delete {
  display: none;
  position: absolute;
  top: 0px;
  right: 0px;
  transform: translate(50%, -50%);
  width: 20px;
  height: 20px;
  background-color: rgb(0, 0, 0);
  border: white solid 2px;
  border-radius: 50%;
  color: white;
  font-size: 10px;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.story-snaps-template.active {
  border: none;
  border-radius: 8px;
  display: flex;
  transition: 0.1s ease-out;
  box-shadow: rgb(247, 217, 1) 0px 0px 0px 2px, rgba(0, 0, 0, 0.2) 0px 2px 12px;
}
.collection-objects {
}
.collection-objects .collection-object {
}
.collection-data {
  margin: 10px 5px;
}
.collection-data .collection {
  aspect-ratio: 1 / 1;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 12px;
  height: 60px;
  width: 60px;
  overflow: hidden;
  background: rgb(235, 234, 234);
}
.collection-data .collection img {
  width: 100%;
  height: 100%;
}
.spotlight-details {
  padding: 0px 8px;
}
.spotlight-details .text,
.spotlight-details .ad {
  font-size: 10px;
}
.spotlight-details .ad {
  background-color: rgba(226, 226, 226, 0.377);
  border-radius: 7px;
  padding: 3px 5px;
}
.spotlight-profile-standard-button {
  width: 100%;
}
.spotlight-profile-standard-button .profile {
  padding: 0px 8px;
}
.see-details {
  -webkit-box-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  -webkit-box-pack: center;
  justify-content: center;
  width: fit-content;
  padding: 0px 8px;
  margin: 5px 11px;
}
.see-details svg {
  height: 10px;
  width: 10px;
}
.see-details span {
  color: rgb(255, 255, 255);
  font-size: 8px;
  font-weight: 500;
  line-height: 16px;
}
.video-data {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  border-radius: 20px;
  overflow: hidden;
}
.video-data video {
  height: 100%;
  width: 100%;
}
.spotlight-review .bottom .profile-favoriting {
  align-items: end !important;
}
.bottom .profile-favoriting {
  display: flex;
  align-items: center;
  padding: 0px 8px;
  justify-content: space-between;
  margin-bottom: 12px;
  gap: 40px;
}
.bottom .profile-favoriting .svg svg,
.bottom .profile-favoriting .ad-text {
  width: 38px;
}
.bottom .profile-favoriting .profile-favoriting-data {
  background-color: rgb(109 109 109 / 26%);
  border-radius: 13px;
  width: 100%;
  padding: 10px;
}
.bottom .profile-favoriting .profile-favoriting-data .profile .name {
  font-size: 12px;
}
.styles-1dxk6ak {
  height: 22px;
}
.styles-yedxbn {
  width: 31px;
  height: 100%;
}
.data-review {
  height: 695px;
  width: 320px;
  background-color: black;
  color: white;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 15px;
  overflow: hidden;
}
.data-review .header {
  padding: 0px;
}
.data-review .body {
  height: 100%;
}
.data-review .body .data {
  height: 100%;
  width: 100%;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  background-position: center;
}
.data-review .body .data .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
}
.data-review .body .data .top .img-name {
  padding: 0px 20px;
}
.data-review .body .data .top .img-name .name {
  font-size: 12px;
  font-weight: 600;
  line-height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.data-review .body .data .top .img-name .ad {
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.32);
  color: rgb(236, 236, 236);
  font-size: 12px;
  font-weight: 500;
  padding: 0px 5px;
}
.data-review .body .data .bottom {
  /* display: flex;
    justify-content: space-between;
    align-items: center; */
  z-index: 1;
}
.standard-button {
  height: 38px;
  width: 100%;
  padding: 0px;
  z-index: 1;
  margin-top: 10px;
}

.standard-button .button {
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-radius: 100px;
  color: rgb(0, 0, 0);
  display: flex;
  font-size: 54px;
  font-weight: 500;
  -webkit-box-pack: center;
  justify-content: center;
  height: 40px;
  width: 100%;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
}
.standard-color {
  background-color: rgb(254, 251, 0) !important;
}
.standard-button .share {
  -webkit-box-align: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1.5px solid rgba(255, 255, 255, 0.1);
  border-radius: 100px;
  display: flex;
  height: 40px;
  -webkit-box-pack: center;
  justify-content: center;
  width: 60px;
}
.share-svg {
  width: 30px;
  height: 30px;
}
.data-review .footer-data {
  z-index: 1;
}
.data-review .footer-data .mobile-button {
  margin-top: 20px;
  height: 5px;
  width: 45%;
  background-color: white;
  border-radius: 10px;
}
.add-another {
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
}
.delete-btn {
  background: #fff;
  border: 1px solid #e4e2e2;
  padding: 0;
  height: 40px;
  width: 40px;
  align-items: center;
  border-radius: 50%;
  box-shadow: none;
  box-sizing: border-box;

  font-size: 14px;
  font-weight: 600;
  outline: 1px;
  padding: 10px;
  transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.delete-btn:hover {
  background: #fff;
  border: 1px solid #f7d901;
}
.bg-white {
  background-color: white;
}
.box-shadow {
  box-shadow: 0 -2px 12px rgba(0, 0, 0, 0.15);
}
input {
  background-color: #f9f9fa;
}
.warning {
  display: flex;
  gap: 5px;
}
.warning-svg {
  display: flex;
  color: rgb(8, 148, 250);
  fill: rgb(8, 148, 250);
  flex-shrink: 0;
}
.input-container {
  position: relative;
}
.input-container .options {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.name-templates {
  height: 80px;
  overflow-y: scroll;
}
.name-template-dialog-card {
  padding: 15px 20px;
}
.name-template-dialog-card .body .title {
  color: rgb(0, 0, 0);
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}
.filed .title {
  color: rgb(0, 0, 0);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
.profile-pic {
  border-radius: 8px;
  border: 1px solid rgb(209, 211, 213);
  padding: 16px;
  background-color: #fff;
  background-clip: padding-box;
}
.pic {
  border-style: solid;
  border-radius: 50%;
  box-sizing: border-box;
  text-align: center;
  color: rgb(68, 69, 70);
  font-weight: bold;
  background-size: cover;
  border-color: rgb(195, 197, 199);
  width: 40px;
  height: 40px;
  line-height: 36px;
  border-width: 1px;
  overflow: hidden;
}
.pic img {
  width: 100%;
  height: 100%;
}
.radio-small {
  color: rgb(84, 85, 87);
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  text-decoration: underline;
}
</style>
