import { defineStore } from "pinia";
export const useGeneralStore = defineStore("useGeneralStore", {
  state: () => ({
    snackbar: {
      color: "",
      show: false,
      message: "",
      timeout: 3000,
    },
  }),
  getters: {
    getSnakebar: (state) => state.snackbar,
  },
  actions: {
    showSnackbar(message, color) {
      this.snackbar = { message, color, show: true, timeout: 3000 };
    },
  },
});
