<template>
  <div class="table-wrapper">
    <div class="table-container insights">
      <v-data-table
        :headers="store?.headers"
        :items="body"
        :items-per-page="5"
        :hide-default-footer="true"
        class="data-table-no-border"
      >
        <template v-slot:item.post="{ item }">
          <div class="d-flex align-center justify-space-between">
            <div class="d-flex align-center ga-2">
              <span class="position-relative"
                ><img :src="item.image" width="35px" height="35px" alt="" />
                <span
                  v-if="item.boosting"
                  class="position-absolute bottom-0 right-0 boosting-icon"
                  ><svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="10" cy="10.25" r="6" fill="white" />
                    <path
                      d="M20.0017 10.2508C20.0017 8.8375 19.285 7.59167 18.195 6.85667C18.4458 5.56667 18.0717 4.17917 17.0725 3.17917C16.0733 2.18 14.6858 1.80583 13.395 2.05667C12.66 0.966667 11.4142 0.25 10.0008 0.25C8.5875 0.25 7.34167 0.966667 6.60667 2.05667C5.31667 1.80583 3.92833 2.18 2.92917 3.17917C1.93 4.17833 1.55583 5.56583 1.80667 6.85667C0.716667 7.59167 0 8.8375 0 10.2508C0 11.6642 0.716667 12.91 1.80667 13.645C1.55583 14.935 1.93 16.3233 2.92917 17.3225C3.92833 18.3217 5.31583 18.6958 6.60667 18.445C7.34167 19.535 8.5875 20.2517 10.0008 20.2517C11.4142 20.2517 12.66 19.535 13.395 18.445C14.685 18.6958 16.0733 18.3217 17.0725 17.3225C18.0717 16.3233 18.4458 14.9358 18.195 13.645C19.285 12.91 20.0017 11.6642 20.0017 10.2508ZM8.87167 9.2175L11.4058 9.64C12.5242 9.82583 13.335 10.7842 13.335 11.9175C13.335 13.2958 12.2133 14.4175 10.835 14.4175V16.0842H9.16833V14.4175C7.79 14.4175 6.66833 13.2958 6.66833 11.9175H8.335C8.335 12.3767 8.70917 12.7508 9.16833 12.7508H10.835C11.295 12.7508 11.6683 12.3767 11.6683 11.9175C11.6683 11.6025 11.4425 11.3358 11.1317 11.2842L8.59667 10.8617C7.47917 10.6758 6.6675 9.7175 6.6675 8.58417C6.6675 7.20583 7.78917 6.08417 9.1675 6.08417V4.4175H10.8342V6.08417C12.2125 6.08417 13.3342 7.20583 13.3342 8.58417H11.6675C11.6675 8.125 11.2942 7.75083 10.8342 7.75083H9.1675C8.70833 7.75083 8.33417 8.125 8.33417 8.58417C8.33417 8.89917 8.56083 9.16583 8.87167 9.2175Z"
                      fill="#237276"
                    />
                  </svg> </span
              ></span>
              <span>{{ item.post }}</span>
            </div>
            <div class="">
              <v-btn
                variant="text"
                :class="['boosting-btn', item.boosting ? 'active' : '']"
                >Boosting</v-btn
              >
            </div>
          </div>
        </template>
        <template v-slot:item.platform="{ item }">
          <div class="d-flex align-center ga-2">
            <span
              ><img
                width="20px"
                height="20px"
                :src="
                  item.platform === 'Snapchat'
                    ? SnapchatIcon
                    : item.platform === 'Instagram'
                    ? InstagramIcon
                    : item.platform === 'Facebook'
                    ? FacebookIcon
                    : item.platform === 'TikTok'
                    ? TikTokIcon
                    : xIcon
                "
                alt=""
                srcset=""
              />
            </span>
          </div>
        </template>
        <template v-slot:item.action="{ item }">
          <Actions :item="item" />
        </template>
      </v-data-table>
    </div>
  </div>
  <Pagination v-if="store?.total_items > 5" :store="store" />
</template>

<script>
import { EngagementAndInsightsStore } from "@/store/EngagementAndInsights/EngagementAndInsights";

import Pagination from "@/components/EngagementInsights/Pagination.vue";
import Actions from "@/components/EngagementInsights/Actions.vue";
import SnapchatIcon from "@/assets/images/icons/snapcaht.svg";
import xIcon from "@/assets/images/icons/x.svg";
import InstagramIcon from "@/assets/images/icons/instagram.svg";
import FacebookIcon from "@/assets/images/icons/facebook.svg";
import TikTokIcon from "@/assets/images/icons/tiktok.svg";

export default {
  name: "EngagementAndInsightsTable",
  props: {
    body: {
      type: Array,
      required: true,
    },
  },
  components: {
    Pagination,
    Actions,
  },

  data() {
    return {
      store: EngagementAndInsightsStore(),
      SnapchatIcon,
      xIcon,
      InstagramIcon,
      FacebookIcon,
      TikTokIcon,
    };
  },
  watch: {},
  computed: {},

  methods: {},
  mounted() {},
  created() {},
};
</script>

<style>
.table-container .v-table {
  margin: 0px !important;
}

.table-container .v-table .v-data-table-footer {
  justify-content: space-between !important;
}

.table-container .v-table .v-table__wrapper table thead {
  background: #edf1f1;
  color: #535862;
}

.table-container .v-table .v-table__wrapper table tbody tr:hover {
  background: #adadad27;
}

.table-container .v-table .v-table__wrapper table tbody tr td {
  padding: 16px !important;
  white-space: nowrap;
}

.table-container thead tr th:hover {
  background-color: #adadad27 !important;
}

.table-container thead tr th div .options .menu {
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.table-container thead tr th div .options .menu {
  display: none;
}
.table-container thead tr th:hover > div .options .menu {
  display: flex;
}

.data-table-no-border
  .v-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td,
.data-table-no-border
  .v-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th {
  border: none !important;
}
.data-table-no-border > .v-table__wrapper > table > tbody > tr > th,
.data-table-no-border > .v-table__wrapper > table > thead > tr > th,
.data-table-no-border > .v-table__wrapper > table > tfoot > tr > th {
  border: none !important;
}
</style>

<style scoped>
.table-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: white;
  border-radius: 8px;
}

.table-container {
  width: 100%;
  position: relative;
  overflow-x: auto;
}

.v-data-table {
  width: 100%;
  margin: 1rem 0;
}

:deep(.v-data-table__wrapper) {
  overflow-x: auto;
  width: 100%;
}

:deep(.v-data-table > .v-data-table__wrapper > table) {
  width: 100%;
  white-space: nowrap;
}

:deep(.v-data-table th) {
  white-space: nowrap;
  min-width: 120px;
  padding: 16px 24px;
  font-weight: 600;
  text-transform: capitalize;
}

.phone-link {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #666666;
  text-decoration: none;
}

.phone-link:hover {
  text-decoration: underline;
}

:deep(.v-data-table) {
  text-transform: capitalize;
}

:deep(.v-data-table__checkbox) {
  width: 48px;
  padding-left: 16px !important;
}

.menu-options-list .v-list-item .v-list-item__content .v-list-item-title {
  width: 150px;
  height: 36px;
  padding: 12px 16px 12px 16px;
  border-radius: 4px;
  margin: 6px 0px;
}

.menu-options-list .v-list-item .v-list-item__content .v-list-item-title:hover {
  background: #c0c0c0;
  color: white;
}
.boosting-icon {
  transform: translate(30%, 30%);
}
.boosting-btn {
  width: 150px;
  height: 32px;
  padding: 10px 0px 10px 0px;
  border-radius: 4px;
  background: #f2f2f2;
  color: #999999;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
}
.boosting-btn.active {
  color: #0464bc;
  background: #f5fafe;
}
</style>
