// Form validation utilities

/**
 * Validates if a value is non-empty.
 * @param {any} value - The value to validate.
 * @returns {boolean} True if valid, otherwise false.
 */
export const validateRequired = (value) => {
  if (Array.isArray(value)) {
    return value.length > 0; // Check for non-empty array
  }
  if (typeof value === "string") {
    return value.trim() !== ""; // Check for non-empty string
  }
  if (typeof value === "number") {
    return !isNaN(value); // Check for valid number
  }
  return value !== null && value !== undefined; // Check for non-null/undefined
};

/**
 * Validates if the provided string is a valid email.
 * @param {string} email - The email to validate.
 * @returns {boolean} True if valid, otherwise false.
 */
export const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return typeof email === "string" && emailRegex.test(email);
};

/**
 * Validates if the phone number is in a correct format.
 * @param {string} phone - The phone number to validate.
 * @returns {boolean} True if valid, otherwise false.
 */
export const validatePhone = (phone) => {
  const phoneRegex = /^\+?[1-9]\d{1,14}$/; // E.164 phone number format
  return typeof phone === "string" && phoneRegex.test(phone.trim());
};

/**
 * Returns an error message if the field value is invalid.
 * @param {string} field - The field name.
 * @param {any} value - The value to validate.
 * @returns {string|null} Error message or null if valid.
 */
export const getFieldError = (field, value) => {
  if (!validateRequired(value)) {
    return `${field} is required`;
  }

  // Additional validation based on field type
  if (field === "email" && !validateEmail(value)) {
    return "Invalid email format";
  }
  if (field === "phone" && !validatePhone(value)) {
    return "Invalid phone format";
  }

  return null; // No errors
};
