<template>
  <div class="payment-form d-flex flex-column ga-3 w-50 mt-5 billing-select">
    <label for="" class="profile-box-title">Payment Method Details</label>

    <label for="">Payment Method Nickname</label>
    <div class="d-flex ga-5 align-center">
      <Input
        v-model="nickname"
        type="text"
        placeholder="description"
        class="w-100"
      />
      <button class="paypal-btn align-center d-flex ga-2">
        <span>Pay with</span>
        <svg
          width="17"
          height="18"
          viewBox="0 0 17 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_5334_124576)">
            <path
              d="M0.261719 8.99941C0.261719 4.45059 3.95059 0.761719 8.49941 0.761719C13.0482 0.761719 16.7371 4.45059 16.7371 8.99941C16.7371 13.5482 13.0482 17.2371 8.49941 17.2371C3.95059 17.2371 0.261719 13.5482 0.261719 8.99941Z"
              fill="#F3F2F2"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.3391 7.61349C11.9971 7.61349 11.7248 7.70314 11.5189 7.7463L11.4558 8.26759C11.5588 8.21779 11.8875 8.12814 12.1564 8.11818C12.4287 8.11154 12.5881 8.16798 12.5316 8.4004C11.7248 8.4004 11.1836 8.56642 11.074 9.08771C10.9146 9.98087 11.8941 9.95763 12.2859 9.56583L12.2394 9.78165H12.9566L13.2687 8.36056C13.3916 7.75626 12.8404 7.60685 12.3391 7.61349ZM12.3855 9.07111C12.3457 9.26037 12.193 9.34337 12.0103 9.35333C11.851 9.35998 11.7082 9.22052 11.8111 9.05451C11.8908 8.91505 12.1133 8.88185 12.2494 8.88185H12.4254C12.4088 8.94494 12.4021 9.0047 12.3855 9.07111Z"
              fill="#1A80AD"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.8756 7.05078L13.2812 9.78008H14.0117L14.6127 7.05078H13.8756Z"
              fill="#1A80AD"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.5293 7.05859H9.19453L8.60352 9.77793H9.37715L9.57637 8.87812H10.1275C10.6721 8.87812 11.117 8.54609 11.2365 7.98496C11.376 7.35078 10.9111 7.05859 10.5293 7.05859ZM10.5061 7.98496C10.4596 8.19082 10.2504 8.35684 10.0512 8.35684H9.69258L9.85195 7.61309H10.2338C10.433 7.61309 10.5525 7.7791 10.5061 7.98496Z"
              fill="#1A80AD"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.34796 7.61349C5.00928 7.61349 4.74034 7.70314 4.54444 7.7463L4.48135 8.26759C4.57432 8.21779 4.89971 8.12814 5.16534 8.11818C5.43428 8.11154 5.59034 8.16798 5.54385 8.4004C4.74698 8.4004 4.21241 8.56642 4.10284 9.08771C3.94678 9.98087 4.91299 9.95763 5.29151 9.56583L5.24503 9.78165H5.96221L6.26768 8.36056C6.39717 7.75626 5.846 7.60685 5.34796 7.61349ZM5.4044 9.07111C5.36456 9.26037 5.2085 9.34337 5.02589 9.35333C4.86983 9.35998 4.7337 9.22052 4.83663 9.05451C4.91631 8.91505 5.13546 8.88185 5.26163 8.88185H5.44424C5.42764 8.94494 5.41104 9.0047 5.4044 9.07111Z"
              fill="#1A5B80"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.58398 7.65234H7.30117L7.41738 8.9207L8.11797 7.65234H8.85508L7.15508 10.6838H6.35156L6.87617 9.79395L6.58398 7.65234Z"
              fill="#1A5B80"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.56914 7.05859H2.23105L1.63672 9.77793H2.42363L2.61621 8.87812H3.17734C3.71523 8.87812 4.16348 8.54609 4.28301 7.98496C4.41582 7.35078 3.96094 7.05859 3.56914 7.05859ZM3.54258 7.98496C3.49609 8.19082 3.29355 8.35684 3.09434 8.35684H2.72578L2.89512 7.61309H3.28027C3.47285 7.61309 3.59902 7.7791 3.54258 7.98496Z"
              fill="#1A5B80"
            />
            <path
              d="M14.768 7.4043V7.08223H14.6484V7.03906H14.9373V7.08223H14.8178V7.4043H14.768Z"
              fill="#1A5B80"
            />
            <path
              d="M14.9863 7.4043V7.03906H15.0594L15.1457 7.29805C15.1523 7.32129 15.159 7.34121 15.1623 7.35117C15.1656 7.33789 15.1723 7.31797 15.1822 7.29141L15.2686 7.03906H15.335V7.4043H15.2885V7.09883L15.1822 7.4043H15.1391L15.0328 7.09219V7.4043H14.9863Z"
              fill="#1A5B80"
            />
          </g>
          <defs>
            <clipPath id="clip0_5334_124576">
              <rect
                width="17"
                height="17"
                fill="white"
                transform="translate(0 0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      </button>
    </div>
    <div class="d-flex mt-5 justify-end ga-3">
      <button class="cancel-btn cursor-pointer">Cancel</button>
      <button class="choose-btn cursor-pointer" style="width: auto">
        Create Payment Method
      </button>
    </div>
  </div>
</template>
<script>
import Input from "@/components/crm/assets/Input.vue";

export default {
  name: "PayPalForm",
  components: { Input },
  data() {
    return {
      nickname: "",
    };
  },
};
</script>
