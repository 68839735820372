<template>
  <div class="card-pattern app-components-card-meta awareness-card">
    <div class="card-header">
      <label for="">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#007e59"
        >
          <path
            d="m423.23-309.85 268.92-268.92L650-620.92 423.23-394.15l-114-114L267.08-466l156.15 156.15ZM480.07-100q-78.84 0-148.21-29.92t-120.68-81.21q-51.31-51.29-81.25-120.63Q100-401.1 100-479.93q0-78.84 29.92-148.21t81.21-120.68q51.29-51.31 120.63-81.25Q401.1-860 479.93-860q78.84 0 148.21 29.92t120.68 81.21q51.31 51.29 81.25 120.63Q860-558.9 860-480.07q0 78.84-29.92 148.21t-81.21 120.68q-51.29 51.31-120.63 81.25Q558.9-100 480.07-100Zm-.07-60q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
          />
        </svg>
        <span class="roboto-black">App</span>
      </label>
    </div>
    <div class="card-body">
      <label for="" class="roboto-bold mb-2">Mobile app store </label>
      <v-select
        return-object
        density="compact"
        hide-details
        :disabled="true"
        label="Select"
        :items="[
          'California',
          'Colorado',
          'Florida',
          'Georgia',
          'Texas',
          'Wyoming',
        ]"
        multiple
        variant="outlined"
      ></v-select>
      <div class="app-name my-3">
        <label for="" class="roboto-bold mb-1">App name</label>
        <div
          class="app-name-input"
          :class="{ disabled: isDisabled }"
          :aria-disabled="isDisabled"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="20px"
            viewBox="0 -960 960 960"
            width="20px"
            fill="#434343"
          >
            <path
              d="M765-144 526-383q-30 22-65.79 34.5-35.79 12.5-76.18 12.5Q284-336 214-406t-70-170q0-100 70-170t170-70q100 0 170 70t70 170.03q0 40.39-12.5 76.18Q599-464 577-434l239 239-51 51ZM384-408q70 0 119-49t49-119q0-70-49-119t-119-49q-70 0-119 49t-49 119q0 70 49 119t119 49Z"
            />
          </svg>
          <input
            :disabled="isDisabled"
            type="search"
            placeholder="Search for an app"
            class="search-input"
          />
        </div>
      </div>
      <div class="selecting-country mt-4">
        <div class="increase-budget-periods">
          <label class="checkbox">
            <input
              type="checkbox"
              @click="toggleSelectingCountry"
              v-model="containerEndDate"
            />
            <span class="checkmark"></span>
            <span class="text"
              >Find your app by selecting a country where it's available.</span
            >
          </label>
        </div>
        <v-select
          v-if="selectngCountryStatus"
          v-model="countrySelected"
          :items="countries"
          density="compact"
          variant="outlined"
          return-object
          item-value="value"
          item-title="title"
          hide-details
        >
          <template v-slot:selection="{ item }">
            {{ item?.title || "" }}
          </template>
          <template v-slot:item="{ item }">
            <v-list-item @click="selectedCountryFun(item)">
              <div class="my-form">
                <div class="ga-3">
                  <input
                    :checked="
                      countrySelected && countrySelected.id === item.raw.id
                    "
                    :id="item.raw.id"
                    type="radio"
                    name="option"
                    :value="item"
                  />
                  <div class="d-flex flex-column ga-1">
                    <label
                      :for="item.raw.id"
                      class="d-block"
                      style="font-size: 14px"
                      >{{ item.title }}</label
                    >
                  </div>
                </div>
              </div>
            </v-list-item>
          </template>
        </v-select>
      </div>
      <div class="performance-goal mb-3">
        <label class="d-block roboto-black" for="">Performance goal</label>
        <v-select
          v-model="performanceGoalSelectedApp"
          :items="performanceGoalApp"
          density="compact"
          variant="outlined"
          return-object
          item-value="value"
          item-title="title"
          hide-details
          :menu-props="{ maxWidth: '596px', minWidth: '100%' }"
        >
          <template v-slot:selection="{ item }">
            {{ item?.title || "" }}
          </template>
          <template v-slot:item="{ item, index }">
            <div v-if="index === 0">
              <p style="font-weight: bold; margin-bottom: 8px">
                App promotion goals
              </p>
            </div>
            <div v-if="index === 3">
              <p style="font-weight: bold; margin-top: 16px">Other goals</p>
            </div>
            <v-list-item @click="selectItemWinnerPerformance(item)">
              <div class="my-form">
                <div class="ga-3">
                  <input
                    :checked="
                      performanceGoalSelectedApp &&
                      performanceGoalSelectedApp.id === item.raw.id
                    "
                    :id="item.raw.id"
                    type="radio"
                    name="option"
                    :value="item"
                  />
                  <div class="d-flex flex-column ga-1">
                    <label
                      :for="item.raw.id"
                      class="d-block"
                      style="font-size: 14px"
                      >{{ item.raw.title }}</label
                    >
                    <label
                      :for="item.raw.id"
                      style="font-size: 12px; color: #1c2b33"
                    >
                      {{ item.raw.descriptions }}
                    </label>
                  </div>
                </div>
              </div>
            </v-list-item>
          </template>
        </v-select>
      </div>
      <div class="cost-per-result mb-3">
        <label for="" class="roboto-black d-block mb-1"
          >Cost per result goal - <span class="roboto-regular">optional</span>
        </label>
        <div class="d-flex input-container align-center mb-1">
          <input type="text" placeholder="$X.XX" name="" disabled id="" />
          <span>USD</span>
        </div>
        <p class="roboto-regular text-description">
          Meta will aim to spend your entire budget and get the most ThruPlays
          using the highest-volume bid strategy. If keeping the average cost per
          result around a certain amount is important, enter a cost per result
          goal.
        </p>
      </div>
      <v-divider class="my-4"></v-divider>
      <button class="show-more" @click="toggleShowMoreOptions">
        <div class="d-flex align-center" v-if="!chargedOptionsApp">
          <span>Show more options</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="20px"
            viewBox="0 -960 960 960"
            width="20px"
            fill="#0a7abf"
          >
            <path d="M480-384 288-576h384L480-384Z" />
          </svg>
        </div>
        <div class="d-flex align-center" v-else>
          <span>Hide options</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="20px"
            viewBox="0 -960 960 960"
            width="20px"
            fill="#0a7abf"
          >
            <path d="m288-384 192-192 192 192H288Z" />
          </svg>
        </div>
      </button>
      <div class="show-more-options-container" v-if="chargedOptionsApp">
        <section class="mb-4">
          <label for="" class="d-block roboto-black"
            >When you are charged
          </label>
          <v-select
            v-model="chargedSelectedItemApp"
            :items="chargedItemsApp"
            density="compact"
            variant="outlined"
            return-object
            item-value="value"
            item-title="title"
            hide-details
          >
            <template v-slot:selection="{ item }">
              {{ item?.title || "" }}
            </template>
            <template v-slot:item="{ item }">
              <v-list-item @click="setCahrgedItemApp(item)">
                <div class="my-form">
                  <div class="ga-3">
                    <input
                      :checked="
                        chargedSelectedItemApp &&
                        chargedSelectedItemApp.id === item.raw.id
                      "
                      :id="item.raw.id"
                      type="radio"
                      name="option"
                      :value="item"
                    />
                    <div class="d-flex flex-column ga-1">
                      <label
                        :for="item.raw.id"
                        class="d-block"
                        style="font-size: 14px"
                        >{{ item.title }}</label
                      >
                    </div>
                  </div>
                </div>
              </v-list-item>
            </template>
          </v-select>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isDisabled: true,
      selectingCountry: false,
      selectngCountryStatus: false,
      countrySelected: {
        id: 0,
        title: "South africa",
        value: "south_africa",
      },
      countries: [
        {
          id: 0,
          title: "South africa",
          value: "south_africa",
        },
        {
          id: 1,
          title: "Egypt",
          value: "egypt",
        },
      ],
      performanceGoalSelectedApp: {
        id: 0,
        title: "Maximise reach of ads",
        descriptions:
          "We'll try to show your ads to as many people as possible.",
        value: "REACH",
      },
      performanceGoalApp: [
        {
          id: 0,
          title: "Maximise number of app events",
          descriptions:
            "We'll try to show your ads to the  people  most likely to take a specific action in your app at least once.",
          value: "ENGAGED_USERS",
        },
        {
          id: 1,
          title: "Maximise number of app installs",
          descriptions:
            "We'll try to show your ads to the  people  most likely to install your app.",
          value: "APP_INSTALLS",
        },
        {
          id: 2,
          title: "Maximise value of conversions",
          descriptions:
            "We'll try to show your ads to the  people  most likely to make higher value purchases.",
          value: "OFFSITE_CONVERSIONS",
        },
        {
          id: 3,
          title: "Maximise number of link clicks",
          descriptions:
            "We'll try to show your ads to the  people  most likely to click on them.",
          value: "LINK_CLICKS",
        },
      ],
      chargedSelectedItemApp: {
        id: 0,
        title: "Impressions",
        value: "IMPRESSIONS",
      },
      chargedItemsApp: [
        {
          id: 0,
          title: "Impression",
          value: "IMPRESSIONS",
        },
        {
          id: 1,
          title: "Link Click (CPC)",
          value: "LINK_CLICKS",
        },
      ],
      chargedOptionsApp: false,
    };
  },
  methods: {
    toggleSelectingCountry() {
      this.selectngCountryStatus = !this.selectngCountryStatus;
    },
    selectedCountryFun(item) {
      this.countrySelected = item.raw;
      localStorage.setItem("countrySelected", item.raw.value);
    },
    selectItemWinnerPerformance(item) {
      this.performanceGoalSelectedApp = item.raw;
      localStorage.setItem("meta_find_winner", item.raw.value);
    },
    setCahrgedItemApp(item) {
      this.chargedSelectedItemApp = item.raw;
      localStorage.setItem("charged_item", item.raw.value);
    },
    toggleShowMoreOptions() {
      this.chargedOptionsApp = !this.chargedOptionsApp;
    },
  },
};
</script>
