<template>
  <div class="story-card position-relative">
    <div class="story-card-header d-flex align-center justify-space-between">
      <div class="card-header-left">
        <div class="user-image d-flex align-center ga-3">
          <img
            src="@/assets/soft-ware-img/be2e93b49a6624b62b6a5bb8c17a2d08.jpeg"
            alt=""
          />
          <p>Ruffles <span>18h</span></p>
        </div>
      </div>
      <div class="card-header-right">
        <div class="user-image d-flex align-center ga-3">
          <span>
            <img src="@/assets/soft-ware-img/more-x.svg" alt="" />
          </span>
          <span>
            <img src="@/assets/soft-ware-img/close-story-icon.svg" alt="" />
          </span>
        </div>
      </div>
    </div>
    <img
      class="story-media"
      v-if="
        post.file.file &&
        post.file.file.type &&
        post.file.file.type.startsWith('image/')
      "
      :src="post.file.preview"
      alt="Post Image"
    />
    <video
      v-if="
        post.file.file &&
        post.file.file.type &&
        post.file.file.type.startsWith('video/')
      "
      height="100%"
      :src="post.file.preview"
      autoplay
      muted
      loop
      class="story-media"
    ></video>
  </div>
  <div class="card-footer">
    <img src="@/assets/soft-ware-img/Default.svg" alt="" />
    <div class="close-reel mt-5">
      <span></span>
    </div>
  </div>
</template>
<script>
import { publishPost } from "@/store/publishPost/publishPost.js";
export default {
  name: "InstgramStoryReview",
  components: {},
  data() {
    return {
      store: publishPost(),
      post: {},
    };
  },
  watch: {
    "store.getCarouselSlider": {
      handler() {
        this.post = this.store.post["instagram"].find(
          (item) => item.type === "story"
        );
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
video {
  object-fit: cover;
}
</style>