<template>
  <Layout :linksData="linksData">
    <v-row class="leads-managements w-100">
      <v-col cols="12" :lg="6" class="header-line-title">
        <div class="leads-managements-link d-flex align-center ga-2 my-3">
          <div
            @click="$router.push({ name: 'Dealsmanagement' })"
            class="link cursor-pointer hoverd-style-line d-flex align-center ga-2"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.825 13L13.425 18.6L12 20L4 12L12 4L13.425 5.4L7.825 11H20V13H7.825Z"
                fill="#1F666A"
              />
            </svg>
            <span>Deals</span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <div class="deal-card">
          <div class="element">
            <div class="title">Total Deals</div>
            <div class="d-flex flex-column ga-3 align-center justify-center">
              <div class="icon">
                <svg
                  width="70"
                  height="71"
                  viewBox="0 0 70 71"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <ellipse
                    cx="34.75"
                    cy="35.5"
                    rx="34.5"
                    ry="35"
                    fill="#DDECED"
                    fill-opacity="0.4"
                  />
                </svg>
              </div>
              <div class="number">{{ total_deals_data.total }}</div>
              <div class="title">Total Deals</div>
              <div class="disc d-flex align-center ga-3">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_2389_55531)">
                    <path
                      d="M10.4999 20C16.0228 20 20.4999 15.5229 20.4999 10C20.4999 4.47716 16.0228 0 10.4999 0C4.97713 0 0.5 4.47716 0.5 10C0.5 15.5229 4.97713 20 10.4999 20Z"
                      fill="#F2F2F2"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M20.1593 8.37904C20.4073 8.22681 20.5382 7.93937 20.4902 7.65239L19.5482 2.02494C19.5166 1.83593 19.4103 1.66756 19.2533 1.55766C19.0963 1.44777 18.9018 1.40559 18.7133 1.44059L13.3713 2.43286C13.0915 2.48481 12.8693 2.69783 12.8056 2.97511C12.7419 3.2524 12.8488 3.54107 13.0777 3.70996L15.4375 5.4506L11.372 11.2562L7.65986 8.65373C7.46589 8.51776 7.22585 8.46443 6.99255 8.50549C6.75927 8.54656 6.55185 8.67864 6.41597 8.87267L0.661571 17.09C0.378717 17.494 0.476858 18.0507 0.880775 18.3336C1.28469 18.6164 1.84143 18.5183 2.12429 18.1143L7.36628 10.6287L11.0784 13.2312C11.2724 13.3671 11.5125 13.4205 11.7458 13.3794C11.979 13.3383 12.1865 13.2062 12.3223 13.0122L16.8751 6.51091L19.3616 8.34513C19.5959 8.51786 19.9113 8.53127 20.1593 8.37904Z"
                      fill="#45C948"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2389_55531">
                      <rect
                        width="20"
                        height="20"
                        fill="white"
                        transform="translate(0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <div class="percentage">{{ total_deals_data.percent }}%</div>
                <div class="text">Deal Growth this year</div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <div class="deal-card">
          <div class="element">
            <div class="title">Deals Values</div>
            <div class="d-flex flex-column ga-3 align-center justify-center">
              <div class="icon">
                <svg
                  width="70"
                  height="70"
                  viewBox="0 0 70 70"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <ellipse
                    cx="35"
                    cy="35"
                    rx="34.5"
                    ry="35"
                    fill="#DDECED"
                    fill-opacity="0.4"
                  />
                </svg>
              </div>
              <div class="number">{{ total_deals_values_data.total }}</div>
              <div class="title">Total Deals</div>
              <div class="disc d-flex align-center ga-3">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_2389_55531)">
                    <path
                      d="M10.4999 20C16.0228 20 20.4999 15.5229 20.4999 10C20.4999 4.47716 16.0228 0 10.4999 0C4.97713 0 0.5 4.47716 0.5 10C0.5 15.5229 4.97713 20 10.4999 20Z"
                      fill="#F2F2F2"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M20.1593 8.37904C20.4073 8.22681 20.5382 7.93937 20.4902 7.65239L19.5482 2.02494C19.5166 1.83593 19.4103 1.66756 19.2533 1.55766C19.0963 1.44777 18.9018 1.40559 18.7133 1.44059L13.3713 2.43286C13.0915 2.48481 12.8693 2.69783 12.8056 2.97511C12.7419 3.2524 12.8488 3.54107 13.0777 3.70996L15.4375 5.4506L11.372 11.2562L7.65986 8.65373C7.46589 8.51776 7.22585 8.46443 6.99255 8.50549C6.75927 8.54656 6.55185 8.67864 6.41597 8.87267L0.661571 17.09C0.378717 17.494 0.476858 18.0507 0.880775 18.3336C1.28469 18.6164 1.84143 18.5183 2.12429 18.1143L7.36628 10.6287L11.0784 13.2312C11.2724 13.3671 11.5125 13.4205 11.7458 13.3794C11.979 13.3383 12.1865 13.2062 12.3223 13.0122L16.8751 6.51091L19.3616 8.34513C19.5959 8.51786 19.9113 8.53127 20.1593 8.37904Z"
                      fill="#45C948"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2389_55531">
                      <rect
                        width="20"
                        height="20"
                        fill="white"
                        transform="translate(0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <div class="percentage">
                  {{ total_deals_values_data.percent }}%
                </div>
                <div class="text">Deal Growth this year</div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <div class="deal-card">
          <div class="element">
            <div class="title">Deals Cost</div>
            <div class="d-flex flex-column ga-3 align-center justify-center">
              <div class="icon">
                <svg
                  width="70"
                  height="70"
                  viewBox="0 0 70 70"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <ellipse
                    cx="35"
                    cy="35"
                    rx="34.5"
                    ry="35"
                    fill="#DDECED"
                    fill-opacity="0.4"
                  />
                </svg>
              </div>
              <div class="number">{{ total_deals_costs_data.total }}</div>
              <div class="title">Total Deals</div>
              <div class="disc d-flex align-center ga-3">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_2389_55531)">
                    <path
                      d="M10.4999 20C16.0228 20 20.4999 15.5229 20.4999 10C20.4999 4.47716 16.0228 0 10.4999 0C4.97713 0 0.5 4.47716 0.5 10C0.5 15.5229 4.97713 20 10.4999 20Z"
                      fill="#F2F2F2"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M20.1593 8.37904C20.4073 8.22681 20.5382 7.93937 20.4902 7.65239L19.5482 2.02494C19.5166 1.83593 19.4103 1.66756 19.2533 1.55766C19.0963 1.44777 18.9018 1.40559 18.7133 1.44059L13.3713 2.43286C13.0915 2.48481 12.8693 2.69783 12.8056 2.97511C12.7419 3.2524 12.8488 3.54107 13.0777 3.70996L15.4375 5.4506L11.372 11.2562L7.65986 8.65373C7.46589 8.51776 7.22585 8.46443 6.99255 8.50549C6.75927 8.54656 6.55185 8.67864 6.41597 8.87267L0.661571 17.09C0.378717 17.494 0.476858 18.0507 0.880775 18.3336C1.28469 18.6164 1.84143 18.5183 2.12429 18.1143L7.36628 10.6287L11.0784 13.2312C11.2724 13.3671 11.5125 13.4205 11.7458 13.3794C11.979 13.3383 12.1865 13.2062 12.3223 13.0122L16.8751 6.51091L19.3616 8.34513C19.5959 8.51786 19.9113 8.53127 20.1593 8.37904Z"
                      fill="#45C948"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2389_55531">
                      <rect
                        width="20"
                        height="20"
                        fill="white"
                        transform="translate(0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <div class="percentage">
                  {{ total_deals_costs_data.percent }}%
                </div>
                <div class="text">Deal Growth this year</div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <div class="deal-card">
          <div class="element">
            <div class="title">Net Profit</div>
            <div class="d-flex flex-column ga-3 align-center justify-center">
              <div class="icon">
                <svg
                  width="70"
                  height="70"
                  viewBox="0 0 70 70"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <ellipse
                    cx="35"
                    cy="35"
                    rx="34.5"
                    ry="35"
                    fill="#DDECED"
                    fill-opacity="0.4"
                  />
                </svg>
              </div>
              <div class="number">{{ total_deals_net_profit_data.total }}</div>
              <div class="title">Total Deals</div>
              <div class="disc d-flex align-center ga-3">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_2389_55531)">
                    <path
                      d="M10.4999 20C16.0228 20 20.4999 15.5229 20.4999 10C20.4999 4.47716 16.0228 0 10.4999 0C4.97713 0 0.5 4.47716 0.5 10C0.5 15.5229 4.97713 20 10.4999 20Z"
                      fill="#F2F2F2"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M20.1593 8.37904C20.4073 8.22681 20.5382 7.93937 20.4902 7.65239L19.5482 2.02494C19.5166 1.83593 19.4103 1.66756 19.2533 1.55766C19.0963 1.44777 18.9018 1.40559 18.7133 1.44059L13.3713 2.43286C13.0915 2.48481 12.8693 2.69783 12.8056 2.97511C12.7419 3.2524 12.8488 3.54107 13.0777 3.70996L15.4375 5.4506L11.372 11.2562L7.65986 8.65373C7.46589 8.51776 7.22585 8.46443 6.99255 8.50549C6.75927 8.54656 6.55185 8.67864 6.41597 8.87267L0.661571 17.09C0.378717 17.494 0.476858 18.0507 0.880775 18.3336C1.28469 18.6164 1.84143 18.5183 2.12429 18.1143L7.36628 10.6287L11.0784 13.2312C11.2724 13.3671 11.5125 13.4205 11.7458 13.3794C11.979 13.3383 12.1865 13.2062 12.3223 13.0122L16.8751 6.51091L19.3616 8.34513C19.5959 8.51786 19.9113 8.53127 20.1593 8.37904Z"
                      fill="#45C948"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2389_55531">
                      <rect
                        width="20"
                        height="20"
                        fill="white"
                        transform="translate(0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <div class="percentage">
                  {{ total_deals_net_profit_data.percent }}%
                </div>
                <div class="text">Deal Growth this year</div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <div class="pa-4 deal-card" elevation="2">
          <div class="d-flex justify-space-start element">
            <div class="title">Deals Status</div>
          </div>
          <div class="d-flex justify-space-around align-center p-3 mt-4">
            <div class="w-100" style="width: 160px !important; height: 160px">
              <Doughnut :data="dealStatusData" :options="chart2Options" />
            </div>
            <div class="data d-flex flex-column ga-2 touched-untouched-labels">
              <div
                v-for="(value, index) in dealStatusData.datasets[0].data"
                :key="index"
                class="d-flex ga-3 align-center"
              >
                <div class="d-flex align-center ga-2">
                  <div
                    :style="{
                      backgroundColor:
                        dealStatusData.datasets[0].backgroundColor[index],
                    }"
                    class="dot"
                  ></div>
                  <span>
                    {{ statusList[index] }}
                  </span>
                </div>
                <span>{{ value }}</span>
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <div class="pa-4 deal-card" elevation="2">
          <div class="d-flex justify-space-start element">
            <div class="title">Deals Stage</div>
          </div>
          <div class="">
            <canvas ref="chartCanvas"></canvas>
          </div>
        </div>
      </v-col>
    </v-row>
  </Layout>
</template>
<script>
import Layout from "@/components/crm/layouts/Layout.vue";
import "@/assets/css/crm/crm-moduls.css";
import { Chart, registerables } from "chart.js";
import { Doughnut } from "vue-chartjs";
import apiClient from "@/core/buildRequest";

Chart.register(...registerables);

export default {
  components: {
    Layout,
    Doughnut,
  },

  data() {
    return {
      selected_deals: [],
      linksData: [
        { name: "Home", link: "/home-page" },
        { name: "Deals Management", link: "" },
      ],
      store: null,

      chart: null,
      statusList: ["active", "won", "lost"],
      dealStageData: {
        labels: ["Active", "Pending", "On-Hold", "Completed", "Closed"],
        datasets: [
          {
            label: "Deal Status",
            data: [50, 70, 60, 54, 90],
            backgroundColor: [
              "#78B5ED",
              "#E6E6E6",
              "#EDBC78",
              "#89FBB2",
              "#FB8989",
            ],
            borderColor: [
              "#78B5ED",
              "#E6E6E6",
              "#EDBC78",
              "#89FBB2",
              "#FB8989",
            ],
            borderWidth: 1,
            borderRadius: 8,
            maxBarThickness: 28,
          },
        ],
      },
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            display: true,
          },
        },
        scales: {
          x: {
            beginAtZero: true,
          },
          y: {
            beginAtZero: true,
          },
        },
      },
      dealStatusData: {
        datasets: [
          {
            data: [52.1, 22.8, 13.9],
            backgroundColor: ["#89FBB2", "#89C4FB", "#FB8989"],
            spacing: 5,
          },
        ],
      },
      total_deals_data: {
        totla: 0,
        percent: 0,
        growth_rate: "Negative",
      },
      total_deals_values_data: {
        totla: 0,
        percent: 0,
        growth_rate: "Negative",
      },
      total_deals_costs_data: {
        totla: 0,
        percent: 0,
        growth_rate: "Negative",
      },
      total_deals_net_profit_data: {
        totla: 0,
        percent: 0,
        growth_rate: "Negative",
      },
    };
  },
  computed: {},
  created() {},
  methods: {
    async getData() {
      try {
        await apiClient
          .get("/leads-managment/deals/get/insights")
          .then((response) => {
            this.dealStageData.labels = response.data.data.type.map((item) => {
              return item.stage;
            });
            this.dealStageData.datasets[0].data = response.data.data.type.map(
              (item) => {
                return item.percentage;
              }
            );
            this.dealStatusData.datasets[0].data =
              response.data.data.status.map((item) => {
                return item.percentage;
              });
            this.statusList = response.data.data.status.map((item) => {
              return item.status;
            });
            this.total_deals_data.total = response.data.data.total_deals;
            this.total_deals_values_data.total = response.data.data.total_value;
            this.total_deals_costs_data.total = response.data.data.total_cost;
            this.total_deals_net_profit_data.total =
              response.data.data.total_profit;
            const ctx = this.$refs.chartCanvas.getContext("2d");
            this.chart = new Chart(ctx, {
              type: "bar",
              data: this.dealStageData,
              options: this.chartOptions,
            });
          });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
<style>
.deal-card {
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 10px 60px 0px #dedede80;
}
.deal-card .data .element {
  height: 40px;
  padding: 0px 24px 0px 16px;
  background: #f7f8f8;
  border-radius: 8px;
}
.deal-card .element .title {
  font-size: 14px;
  font-weight: bolder;
  line-height: 16.94px;
  color: #0d0d0d;
}

.deal-card .number {
  font-size: 16px;
  font-weight: 700;
  line-height: 19.36px;
  color: #1a1a1a;
}

.deal-card .disc {
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  color: #666666;
}

.deal-card .percentage {
  font-size: 16px;
  font-weight: 700;
  line-height: 19.36px;
  color: #1f0609;
}

.deal-card .dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.touched-untouched-labels {
  font-size: 14px;
  color: #666666;
}
.number-chart-percent .chart {
  position: relative;
  border-radius: 15px;
  background-color: rgba(240, 240, 240, 0.342);
  width: 85%;
  height: 8px;
}
.number-chart-percent .chart .background {
  background: linear-gradient(90deg, #1f666a 0%, #3f878d 42%, #4f979f 100%);
  border-radius: 15px;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 0px;
  transition: all 0.5s ease-in-out;
}
</style>
