import Index from "@/views/myAccount/index.vue";
import Edit from "@/views/myAccount/edit.vue";
import NewPaymentMethod from "@/views/myAccount/NewPaymentMethod.vue";

const MyAccountRoutes = [
  {
    path: "/my-account",
    name: "MyAccount",
    component: Index,
    meta: {
      title: "My Account",
      description: "",
    },
  },
  {
    path: "/my-account/edit/:id",
    name: "MyAccountEdit",
    component: Edit,
    meta: {
      title: "My Account Edit",
      description: "",
    },
  },
  {
    path: "/my-account/new-payment-method",
    name: "NewPaymentMethod",
    component: NewPaymentMethod,
    meta: {
      title: "New Payment Method",
      description: "",
    },
  },
];

export default MyAccountRoutes;
