<template>
  <div class="tree-node">
    <div class="node-content" @click="toggleExpand">
      <span 
        v-if="hasChildren"
        :class="['expand-icon', { expanded: isExpanded }]"
      >▸</span>
      <span v-else class="expand-icon-placeholder"></span>
      <label class="checkbox" @click.stop>
        <input 
          :id="item.id"
          type="checkbox" 
          :checked="isSelected"
          @change="$emit('toggle-select', item.id)"
        >
        <span class="checkbox-inner"></span>
      </label>
      <label :for="item.id" class="label">{{ item.name }}</label>
    </div>
    <div v-if="hasChildren && isExpanded" class="children">
      <TreeNode
        v-for="child in item.children"
        :key="child.id"
        :item="child"
        :selected="selected"
        @toggle-select="$emit('toggle-select', $event)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TreeNode',
  props: {
    item: {
      type: Object,
      required: true
    },
    selected: {
      type: Set,
      required: true
    }
  },
  data() {
    return {
      isExpanded: false
    }
  },
  computed: {
    isSelected() {
      return this.selected.has(this.item.id)
    },
    hasChildren() {
      return this.item.children && this.item.children.length > 0
    }
  },
  methods: {
    toggleExpand() {
      if (this.hasChildren) {
        this.isExpanded = !this.isExpanded
      }
    }
  }
}
</script>

<style scoped>
.tree-node {
  font-size: 14px;
}

.node-content {
  display: flex;
  align-items: center;
  padding: 8px 0;
  cursor: pointer;
}

.node-content:hover {
  background-color: #f5f7fa;
}

.expand-icon {
  width: 24px;
  color: #606266;
  transition: transform 0.3s;
  cursor: pointer;
}

.expand-icon.expanded {
  transform: rotate(90deg);
}

.expand-icon-placeholder {
  width: 24px;
}

.checkbox {
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-right: 8px;
}

.checkbox input {
  opacity: 0;
  position: absolute;
}

.checkbox-inner {
  display: inline-block;
  position: relative;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  width: 14px;
  height: 14px;
  background-color: #fff;
  transition: border-color .25s,background-color .25s;
}

.checkbox input:checked + .checkbox-inner {
  background-color: #409eff;
  border-color: #409eff;
}

.checkbox input:checked + .checkbox-inner::after {
  content: '';
  position: absolute;
  left: 4px;
  top: 2px;
  width: 3px;
  height: 7px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.label {
  color: #606266;
}

.children {
  padding-left: 24px;
}
</style>