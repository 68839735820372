<template>
  <!-- Demographics  -->
  <div class="demographics ad-set-details-card mb-6 fs-14">
    <h6 class="page-header-title mb-3">Demographics</h6>
    <v-card
      max-width="100%"
      class="pa-5 mb-5 rounded-lg"
      style="overflow: visible"
      elevation="1"
    >
      <div class="card-body">
        <span class="inputs-title">Regulated Content</span>
        <div class="">
          <label class="snap-checkbox">
            <input v-model="age_restrictions_required" type="checkbox" />
            <span class="snap-checkmark"></span>Age restrictions required
          </label>
          <div class="d-flex align-center justify-start ga-2"></div>

          <div class="">
            <label for="">Age</label>
            <div class="d-1-0-1 ga-2 w-50">
              <v-select
                class="v-select-style"
                v-model="age_restrictions_required_start_age"
                :items="age_restrictions_required_start_age_items"
                variant="solo"
              ></v-select>

              <span class="d-flex align-center justify-center">-</span>
              <v-select
                variant="solo"
                class="v-select-style"
                v-model="age_restrictions_required_start_end_age"
                :items="age_restrictions_required_start_end_age_items"
              ></v-select>
            </div>
            <span
              v-if="show_age_restrictions_required_validate"
              class="d-flex age_restrictions_required_validate mt-2"
            >
              <div class="icon">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="#444546"
                  xmlns="http://www.w3.org/2000/svg"
                  class="age_restrictions_required_validate_svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.99994 8C1.99994 4.68629 4.68623 2 7.99994 2C11.3136 2 13.9999 4.68629 13.9999 8C13.9999 11.3137 11.3136 14 7.99994 14C4.68623 14 1.99994 11.3137 1.99994 8Z"
                  ></path>
                  <path
                    d="M7.16653 10.755C7.16521 10.5899 7.21296 10.4281 7.30372 10.2902C7.39448 10.1523 7.52416 10.0444 7.67631 9.98034C7.82847 9.91625 7.99623 9.89879 8.15831 9.93019C8.3204 9.96158 8.46951 10.0404 8.58672 10.1567C8.70393 10.273 8.78397 10.4214 8.81666 10.5833C8.84936 10.7451 8.83325 10.913 8.77038 11.0657C8.70751 11.2183 8.60071 11.3489 8.46352 11.4407C8.32634 11.5326 8.16496 11.5816 7.99986 11.5816C7.89074 11.5825 7.78253 11.5618 7.68146 11.5206C7.5804 11.4795 7.48848 11.4187 7.41101 11.3419C7.33354 11.265 7.27205 11.1736 7.23009 11.0729C7.18813 10.9721 7.16653 10.8641 7.16653 10.755Z"
                    fill="white"
                  ></path>
                  <path
                    d="M8.00001 3.79333C8.11321 3.7941 8.22508 3.81775 8.32891 3.86284C8.43274 3.90793 8.52638 3.97354 8.60422 4.05574C8.68205 4.13793 8.74247 4.23501 8.78185 4.34114C8.82122 4.44727 8.83874 4.56026 8.83334 4.67333L8.50001 9.04H7.49334L7.16668 4.67333C7.16222 4.56049 7.18038 4.44789 7.22009 4.34217C7.25981 4.23645 7.32027 4.13975 7.39792 4.05775C7.47557 3.97575 7.56884 3.91011 7.67225 3.8647C7.77565 3.81929 7.88709 3.79503 8.00001 3.79333Z"
                    fill="white"
                  ></path>
                </svg>
              </div>
              <div class="text">Age restrictions required</div>
            </span>
            <div
              v-if="age_restrictions_required"
              class="age_restrictions_required_warning mt-2"
            >
              <div class="d-flex ga-3 align-start justify-start">
                <div class="icon">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="#444546"
                    xmlns="http://www.w3.org/2000/svg"
                    class="blue-warning-svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M1.99985 7.9999C1.99985 4.68614 4.68617 1.99982 7.99993 1.99982C11.3137 1.99982 14 4.68614 14 7.9999C14 11.3137 11.3137 14 7.99993 14C4.68617 14 1.99985 11.3137 1.99985 7.9999Z"
                    ></path>
                    <path
                      d="M9.25189 10.8265C8.75462 11.53 8.00008 12.0091 7.15189 12.1599C6.75189 12.1599 6.53856 11.7999 6.72523 10.9599C6.95856 9.99321 7.23189 8.95988 7.39189 8.15988C7.47856 7.81321 7.46523 7.70654 7.39189 7.70654C7.13981 7.77876 6.91034 7.91415 6.72523 8.09988L6.54523 7.71321C7.09596 7.10032 7.8346 6.68762 8.64523 6.53988C9.05189 6.53988 9.14523 6.91321 8.88523 8.02654C8.70523 8.77321 8.43856 9.80654 8.25856 10.5465C8.17856 10.8465 8.17856 11.0199 8.25856 11.0199C8.5634 10.902 8.83224 10.7067 9.03856 10.4532L9.25189 10.8265ZM9.45189 4.82654C9.45545 4.9465 9.43533 5.06598 9.39268 5.17815C9.35004 5.29032 9.2857 5.39299 9.20336 5.48029C9.12101 5.56759 9.02227 5.6378 8.91278 5.68692C8.80328 5.73604 8.68518 5.76309 8.56523 5.76654C8.4651 5.77742 8.36382 5.76546 8.26897 5.73158C8.17413 5.6977 8.0882 5.64277 8.01763 5.57091C7.94706 5.49906 7.89368 5.41215 7.86152 5.31671C7.82935 5.22128 7.81922 5.11979 7.83189 5.01988C7.8354 4.77289 7.93598 4.53721 8.11188 4.3638C8.28779 4.19039 8.52488 4.09319 8.77189 4.09321C8.86584 4.09095 8.95921 4.10857 9.04586 4.14493C9.13252 4.18129 9.21051 4.23555 9.27473 4.30417C9.33894 4.37279 9.38791 4.4542 9.41845 4.54308C9.44898 4.63196 9.46038 4.72629 9.45189 4.81988V4.82654Z"
                      fill="white"
                    ></path>
                  </svg>
                </div>
                <div class="text">
                  Given you have declared your ad content is regulated content
                  please carefully select the legally allowed age targeting in
                  your country or region. Please reference our
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <label for="">Gender</label>
            <div class="d-1-0-1 ga-2 w-25 gender-container">
              <v-btn
                v-for="gender in demographics_gender_list"
                :key="gender"
                @click="demographics_gender = gender"
                :active="demographics_gender === gender"
                class="gender-button"
                >{{ gender }}</v-btn
              >
            </div>
          </div>
          <div class="mt-3">
            <v-select
              label=""
              class="v-select-style"
              v-model="selected_language"
              :items="languages"
              item-title="name"
              item-value="id"
              return-object
              multiple="multiple"
              variant="solo"
            ></v-select>
            <div class="mt-2">
              <div
                class=""
                v-for="(language, index) in selected_language"
                :key="index"
              >
                <div class="d-flex ga-2 selected-language-button">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="#444546"
                    xmlns="http://www.w3.org/2000/svg"
                    class="correct-svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6 10.9394L12.803 4.13635L13.8637 5.19701L6 13.0607L2.13634 9.19701L3.197 8.13635L6 10.9394Z"
                    ></path>
                  </svg>
                  <span>{{ language.name }}</span>
                  <button class="remove-btn" @click="removeLangFromList(index)">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="#444546"
                      xmlns="http://www.w3.org/2000/svg"
                      class="sds-icon sds-icon-cross"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8 9.8535L11.6239 13.4774L12.8614 12.24L9.23744 8.61606L12.8614 4.99214L11.6239 3.7547L8 7.37862L4.37609 3.75471L3.13865 4.99215L6.76256 8.61606L3.13864 12.24L4.37608 13.4774L8 9.8535Z"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="mt-3">
            <label for="">Advanced Demographics</label>
            <div class="selected-demo-graphics">
              <div
                class=""
                v-for="(demographics, index) in selected_advanced_demographics"
                :key="index"
              >
                <div class="d-flex ga-2 selected-language-button">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="#444546"
                    xmlns="http://www.w3.org/2000/svg"
                    class="correct-svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6 10.9394L12.803 4.13635L13.8637 5.19701L6 13.0607L2.13634 9.19701L3.197 8.13635L6 10.9394Z"
                    ></path>
                  </svg>
                  <span>{{ demographics.label }}</span>
                  <button class="remove-btn" @click="removeAdvecedDemo(index)">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="#444546"
                      xmlns="http://www.w3.org/2000/svg"
                      class="sds-icon sds-icon-cross"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8 9.8535L11.6239 13.4774L12.8614 12.24L9.23744 8.61606L12.8614 4.99214L11.6239 3.7547L8 7.37862L4.37609 3.75471L3.13865 4.99215L6.76256 8.61606L3.13864 12.24L4.37608 13.4774L8 9.8535Z"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <treeselect
              v-model="selected_advanced_demographics"
              :multiple="true"
              :options="advancedDemographics"
              :value-format="'object'"
            >
            </treeselect>
          </div> -->
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import apiClient from "@/core/buildRequest";
export default {
  components: {},
  data() {
    return {
      age_restrictions_required: false,
      age_restrictions_required_start_age: "",
      age_restrictions_required_start_age_items: [
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
      ],
      age_restrictions_required_start_end_age: "",

      age_restrictions_required_start_end_age_items: [
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "49",
      ],
      show_age_restrictions_required_validate: false,
      demographics_gender_list: [],
      demographics_gender:
        localStorage.getItem("demographics_gender") || "OTHER",
      selected_language: localStorage.getItem("selected_language")
        ? JSON.parse(localStorage.getItem("selected_language"))
        : [{ name: "English", id: "en" }],
      languages: [
        { name: "English", id: "en" },
        { name: "French", id: "fr" },
        { name: "Spanish", id: "es" },
        { name: "German", id: "de" },
        { name: "Portuguese", id: "pt" },
        { name: "Italian", id: "it" },
      ],
      selected_advanced_demographics: [],
      advancedDemographics: [
        {
          id: "carrier-group-1",
          label: "Household Income",
          children: [
            {
              id: "carrier-1-1",
              label: "Snapchat",
              children: [
                {
                  id: "carrier-1-3-1",
                  label: "Household Income: $100000 - $199999",
                },
                {
                  id: "carrier-1-3-2",
                  label: "Household Income: $200000 or more",
                },
                {
                  id: "carrier-1-3-3",
                  label: "Household Income: $50000 - $99999",
                },
                {
                  id: "carrier-1-3-3",
                  label: "Household Income: Under $50000",
                },
              ],
            },
          ],
        },
      ],
    };
  },
  methods: {
    removeLangFromList(index) {
      this.selected_language.splice(index, 1);
    },
    getAllLanguages() {
      apiClient
        .get("/snapchat/all-languages")
        .then((response) => {
          for (let index = 0; index < response.data.data.length; index++) {
            const element_name = response.data.data[index].languages.name;
            const element_id = response.data.data[index].languages.id;
            this.languages.push({
              name: element_name,
              id: element_id,
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching gender data:", error);
        });
    },
    getAllGenders() {
      apiClient
        .get("/snapchat/gender-groups")
        .then((response) => {
          for (let index = 0; index < response.data.data.length; index++) {
            const element = response.data.data[index].gender.name;
            this.demographics_gender_list.push(element);
          }
        })
        .catch((error) => {
          console.error("Error fetching gender data:", error);
        });
    },
    removeAdvecedDemo(index) {
      this.selected_advanced_demographics.splice(index, 1);
    },
  },
  computed: {
    selectedLanguagesHint() {
      return this.selected_language
        .map((language) => `${language.name} (${language.id})`)
        .join(", ");
    },
  },
  watch: {
    age_restrictions_required() {
      if (this.age_restrictions_required) {
        if (Number(this.age_restrictions_required_start_age) < 18) {
          this.show_age_restrictions_required_validate = true;
        } else {
          this.show_age_restrictions_required_validate = false;
        }
      }
    },
    age_restrictions_required_start_age() {
      if (
        Number(this.age_restrictions_required_start_age) >
        Number(this.age_restrictions_required_start_end_age)
      ) {
        this.age_restrictions_required_start_end_age =
          this.age_restrictions_required_start_age;
      }
      if (this.age_restrictions_required) {
        if (Number(this.age_restrictions_required_start_age) < 18) {
          this.show_age_restrictions_required_validate = true;
        } else {
          this.show_age_restrictions_required_validate = false;
        }
      }
      localStorage.setItem(
        "age_restrictions_required_start_age",
        this.age_restrictions_required_start_age
      );
    },
    age_restrictions_required_start_end_age() {
      if (
        Number(this.age_restrictions_required_start_age) >
        Number(this.age_restrictions_required_start_end_age)
      ) {
        this.age_restrictions_required_start_end_age =
          this.age_restrictions_required_start_age;
      }
      if (this.age_restrictions_required) {
        if (Number(this.age_restrictions_required_start_age) < 18) {
          this.show_age_restrictions_required_validate = true;
        } else {
          this.show_age_restrictions_required_validate = false;
        }
      }
      localStorage.setItem(
        "age_restrictions_required_start_end_age",
        this.age_restrictions_required_start_end_age
      );
    },
    demographics_gender(val) {
      localStorage.setItem("demographics_gender", val);
    },
    selected_language() {
      localStorage.setItem(
        "selected_language",
        JSON.stringify(this.selected_language)
      );
    },
  },
  mounted() {
    this.getAllGenders();
    this.getAllLanguages();
    if (localStorage.getItem("age_restrictions_required_start_age")) {
      this.age_restrictions_required_start_age = localStorage.getItem(
        "age_restrictions_required_start_age"
      );
    } else {
      this.age_restrictions_required_start_age = 13;
      localStorage.setItem(
        "age_restrictions_required_start_age",
        this.age_restrictions_required_start_age
      );
    }
    if (localStorage.getItem("age_restrictions_required_start_end_age")) {
      this.age_restrictions_required_start_end_age = localStorage.getItem(
        "age_restrictions_required_start_end_age"
      );
    } else {
      this.age_restrictions_required_start_end_age = 49;
      localStorage.setItem(
        "age_restrictions_required_start_end_age",
        this.age_restrictions_required_start_end_age
      );
    }
  },
};
</script>
