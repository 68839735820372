<template>
  <v-col :cols="12" :lg="9">
    <div class="mt-4 branch-select">
      <div class="bold-text mb-2">Branch</div>
      <v-select
        v-model="store.selectedBranch"
        :items="branches"
        label="Select Branch"
        item-title="name"
        item-value="id"
        return-object
      >
        <template v-slot:selection="{ item }">
          <div class="d-flex align-center">
            <v-avatar size="24" class="mr-2">
              <v-img :src="item.raw.image" :alt="item.raw.name"></v-img>
            </v-avatar>
            {{ item.raw.name }}
          </div>
        </template>
        <template v-slot:item="{ props, item }">
          <v-list-item v-bind="props" :title="null">
            <template v-slot:prepend>
              <div class="d-flex align-center">
                <v-avatar size="32" class="mr-2">
                  <v-img :src="item.raw.image" :alt="item.raw.name"></v-img>
                </v-avatar>
                {{ item.raw.name }}
              </div>
            </template>
          </v-list-item>
        </template>
      </v-select>
    </div>
  </v-col>
</template>

<script>
import { useTaskStore } from '@/store/taskStore';

export default {
  name: 'TaskBranch',
  data() {
    return {
      store: useTaskStore(),
      branches: [
        {
          id: 1,
          name: 'Main Branch',
          image: 'https://picsum.photos/64/64?random=1',
        },
        {
          id: 2,
          name: 'Development',
          image: 'https://picsum.photos/64/64?random=2',
        },
        {
          id: 3,
          name: 'Feature Branch',
          image: 'https://picsum.photos/64/64?random=3',
        },
        {
          id: 4,
          name: 'Release Branch',
          image: 'https://picsum.photos/64/64?random=4',
        },
        {
          id: 5,
          name: 'Hotfix Branch',
          image: 'https://picsum.photos/64/64?random=5',
        },
      ]
    };
  }
};
</script>
