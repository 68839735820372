import { defineStore } from "pinia";
import apiClient from "@/core/buildRequest";

export const useEventsStore = defineStore("useEventsStore", {
  state: () => ({
    events: [],
    headers: [
      {
        title: "Event",
        name: "Event",
        align: "start",
        key: "event_number",
        menu: true,
        show: true,
        sortable: true,
      },
      {
        title: "Event Name",
        name: "Event Name",
        align: "start",
        show: true,
        menu: true,
        key: "name",
        sortable: true,
      },
      {
        title: "Assigness",
        name: "Assigness",
        align: "start",
        show: true,
        key: "members",
        menu: true,
      },
      {
        title: "Status",
        name: "Status",
        align: "start",
        show: true,
        menu: true,
        key: "status",
      },
      {
        title: "Event Type",
        name: "Event Type",
        align: "start",
        show: true,
        menu: true,
        key: "type",
      },
      {
        title: "Event Date",
        name: "Event Date",
        align: "start",
        show: true,
        menu: true,
        key: "date",
      },
      {
        title: "Add To Calendar",
        name: "Add To Calendar",
        align: "start",
        show: true,
        menu: true,
        key: "add_to_calendar",
      },
      {
        title: "Objectives",
        name: "Objectives",
        align: "start",
        show: true,
        menu: true,
        key: "objectives",
      },
      {
        title: "Contacts",
        name: "Contacts",
        align: "start",
        show: true,
        menu: true,
        key: "contacts",
      },
      {
        title: "Account",
        name: "Account",
        align: "start",
        show: true,
        menu: true,
        key: "account",
      },

      {
        title: "Project",
        name: "Project",
        align: "start",
        show: true,
        menu: true,
        key: "project",
      },
      {
        title: "Task",
        name: "Task",
        align: "start",
        show: true,
        menu: true,
        key: "task",
      },
      {
        title: "Comment",
        name: "Comment",
        align: "start",
        show: true,
        menu: true,
        key: "comment",
      },
      {
        title: "Actions",
        name: "Actions",
        align: "center",
        sortable: false,
        key: "actions",
        show: true,
        menu: true,
      },
    ],
    items_per_page: 5,
    current_page: 1,
    total_events: 0,
    total_pages: 0,
    hasNextPage: false,
    hasPreviousPage: false,
  }),
  getters: {
    getEvents: (state) => state.events,
    getHeaders: (state) => state.headers.filter((header) => header.show),
    getPagination: (state) => ({
      currentPage: state.current_page,
      totalPages: state.total_pages,
      itemsPerPage: state.items_per_page,
      totalEvents: state.total_events,
    }),
    isLoading: (state) => state.loading,
  },
  actions: {
    hideColumnFromHeader(value) {
      this.headers.map((item) => {
        item.show = !value;
      });
    },
    async getAllEvents() {
      try {
        var response = await apiClient.get(
          `/leads-managment/all-events?page=${this.current_page}&per_page=${this.items_per_page}`
        );
        let { data, pagination } = response.data.data;
        this.events = data;
        this.current_page = pagination.current_page;
        this.total_events = pagination.total_items;
        this.total_pages = pagination.total_pages;
        this.hasNextPage = pagination.next_page_url !== "" ? true : false;
        this.hasPreviousPage = pagination.perv_page_url !== "" ? true : false;
      } catch (err) {
        console.error("Error fetching events:", err);
      }
    },
    onPageChange() {
      return this.getAllEvents();
    },
    onItemsPerPageChange() {
      this.current_page = 1;
      return this.getAllEvents();
    },
    previousPage() {
      this.current_page -= 1;
      return this.getAllEvents();
    },
    nextPage() {
      this.current_page += 1;
      return this.getAllEvents();
    },
    pushEvents(events) {
      this.events.unshift(events);
    },
    updateEvent(events) {
      const index = this.events.findIndex((item) => item.id === events.id);
      if (index !== -1) {
        this.events.splice(index, 1, events);
      }
      console.log(events);
    },
    deleteEvent(event) {
      const index = this.events.findIndex((item) => item.id === event.id);
      if (index !== -1) {
        this.events.splice(index, 1);
      }
    },
    updateStatus(id, status) {
      const index = this.events.findIndex((item) => item.id === id);
      if (index !== -1) {
        this.events[index].status = status;
      }
    },
    updateType(id, type) {
      const index = this.events.findIndex((item) => item.id === id);
      if (index !== -1) {
        this.events[index].type = type;
      }
    },
    updateAddToCalender(id, value) {
      const index = this.events.findIndex((item) => item.id === id);
      if (index !== -1) {
        this.events[index].add_to_calendar = value;
      }
    },
  },
});
