<template>
  <div class="tiktok-create-campaign-container">
    <TiktokNavbar />
    <div class="tiktok-create-campaign">
      <button @click="getRedirectUrl">
        <span class="d-flex align-center">
          <img src="@/assets/tiktokImage/tikTiokbusinessLogo.svg" alt="" />
        </span>
        <span>Get Start Your Campaign</span>
      </button>
    </div>
  </div>
</template>

<script>
import TiktokNavbar from "@/components/tiktok/TiktokNavbar.vue";
import apiClient from "@/core/buildRequest";
export default {
  components: {
    TiktokNavbar,
  },
  data() {
    return {};
  },
  methods: {
    getRedirectUrl() {
      apiClient
        .get("/auth/tiktok", localStorage.getItem("user_token"))
        .then((response) => {
          window.open(response.data.data.redirect_url, "_blank");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.tiktok-create-campaign-container {
  width: 100%;
}
div.tiktok-create-campaign {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 8%;
}
.tiktok-create-campaign button {
  background-color: #000;
  color: white;
  padding: 45px 20px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.tiktok-create-campaign button img {
  margin-bottom: 15px;
}
</style>
