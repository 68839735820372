<template>
  <v-card max-width="950px" class="stepper-create-app">
    <h5>Create New App</h5>
    <v-card-text class="pa-3">
      <v-stepper
        v-model="currentStep"
        alt-labels
        editable
        :items="[
          'Download URL Settings',
          'Tracking Settings',
          'App Event Settings',
        ]"
        hide-actions
      >
        <template v-slot:item.1>
          <v-card title="" flat>
            <div class="select-app-type">
              <p class="card-title mb-0">Download URL</p>
              <div>
                <select v-model="platform" @change="" name="platform" id="platform">
                  <option value="ios">iOS</option>
                  <option value="android">Android</option>
                </select>
                <input
                  type="url"
                  v-model="appUrl"
                  @input="fetchAppData"
                  placeholder="Enter app URL"
                />
              </div>
            </div>
            <div class="app-description" v-if="appData">
              <div class="app-name">
                <p>App Icon</p>
                <img :src="appData.iconUrl" :alt="appData.name" />
              </div>
              <div class="app-name">
                <p>App Name</p>
                <p>{{ appData.name }}</p>
              </div>
              <div class="app-name">
                <p>App ID</p>
                <p>{{ appData.bundleId }}</p>
              </div>
            </div>
          </v-card>
        </template>
        <template v-slot:item.2>
          <v-card class="tracking-settings" title="" flat>
            <p class="card-title">
              To get attribution results, select a mobile measurement partner,
              API, or SDK.
            </p>
            <div class="radio-buttons-container">
              <div
                class="radio-button"
                v-for="item in trackingSettings"
                :key="item.id"
              >
                <input
                  type="radio"
                  :id="item.id"
                  :value="item.value"
                  v-model="tracking_type"
                  class="radio-button__input"
                  name="tecking-settings"
                  @change="setTrackingType"
                />
                <label :for="item.id" class="radio-button__label">
                  <span class="radio-button__custom"></span>
                  <div>
                    <span class="ma-0 text-body-1">{{ item.title }}</span>
                  </div>
                </label>
                <div
                  v-if="tracking_type === 'connect' && item.value === 'connect'"
                  class="tracking-description"
                >
                  <v-select
                    hide-details
                    no-data-text=""
                    :items="mobile_partner"
                    return-object
                    density="compact"
                    label=""
                    variant="outlined"
                    placeholder="Select app"
                  >
                    <template v-slot:item="{ item, props }">
                      <v-list-item v-bind="props" class="mobile-partner">
                        <v-list-item-title>{{
                          item.raw.name
                        }}</v-list-item-title>
                        <template v-slot:prepend>
                          <v-img
                            :src="item.raw.icon"
                            :alt="item.raw.name"
                            width="24"
                            height="24"
                            class="me-10"
                          />
                        </template>
                      </v-list-item>
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
            <div class="advertisers">
              <p>
                For advertisers who do not use a tracking partner, TikTok For
                Business currently only supports directing traffic to the app
                store to drive consideration.
              </p>
            </div>
          </v-card>
        </template>
        <template v-slot:item.3>
          <v-card flat class="app-event-settings">
            <div class="app-success">
              <div class="icon-success">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="20px"
                  viewBox="0 -960 960 960"
                  width="20px"
                  fill="#98e3a1"
                >
                  <path
                    d="m429-336 238-237-51-51-187 186-85-84-51 51 136 135Zm51 240q-79 0-149-30t-122.5-82.5Q156-261 126-331T96-480q0-80 30-149.5t82.5-122Q261-804 331-834t149-30q80 0 149.5 30t122 82.5Q804-699 834-629.5T864-480q0 79-30 149t-82.5 122.5Q699-156 629.5-126T480-96Z"
                  />
                </svg>
              </div>
              <div class="success-description">
                <h6>App created in Events Manager</h6>
                <p>
                  Your tracking URL has been set, and standard events will be
                  automatically linked to your app.
                </p>
              </div>
            </div>
            <h6 class="page-header-title my-5">Setup your Events</h6>
            <div class="blow-event mb-4">
              <p>Below are all events supported by TikTok</p>
              <v-select
                :items="items"
                item-title="name"
                v-model="selected_item"
                density="compact"
                variant="outlined"
                return-object
                hide-details
              ></v-select>
            </div>
            <v-data-table
              hide-default-footer="true"
              :headers="data_headers"
              :items="data_table"
            ></v-data-table>
            <v-btn
              class="my-2 open-email-app"
              color="#000"
              elevation="0"
              @click="dialog2 = true"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="20px"
                viewBox="0 -960 960 960"
                width="20px"
                fill="#fff"
              >
                <path
                  d="m768-84-51-51 57-57H624v-72h150l-57-57 51-51 144 144L768-84ZM168-192q-30 0-51-21t-21-51v-432q0-30 21-51t51-21h624q30 0 51 21t21 51v289q-25-14-52-19.5t-56-5.5q-85 0-144.5 58.5T552-230v38H168Zm312-240 312-179v-85L480-517 168-696v85l312 179Z"
                />
              </svg>
              <span>Email app details</span>
            </v-btn>
            <v-dialog
              v-model="dialog2"
              class="email-app-dialog"
              max-width="840"
            >
              <v-card title="Email app details">
                <v-card-text class="app-event-settings">
                  <label class="d-block mb-2" for="">Recipient Email</label>
                  <div class="email-input">
                    <input
                      type="email"
                      placeholder="Please enter an email address"
                      v-model="email_resevice"
                      name=""
                      id=""
                    />
                    <button>Send</button>
                  </div>
                  <div class="tik-tok-app-id mt-5 mb-4">
                    <label for="">TikTok App ID</label>
                    <p class="preview-tiktok-app-id">
                      {{ add_id }}
                    </p>
                    <span class="tiktok-app-id-description">
                      A unique code generated in TikTok Ads Manager to confirm
                      ownership of apps and manage app data. During SDK
                      implementation, you can use this ID as an initialization
                      parameter.
                    </span>
                  </div>
                  <div class="tik-tok-app-id mt-5 mb-4">
                    <label for="">App name</label>
                    <p class="preview-tiktok-app-id" v-if="appData">
                      {{ appData.name }}
                    </p>
                    <p v-else class="preview-tiktok-app-id">no app</p>
                  </div>
                  <div class="tik-tok-app-id mt-5 mb-4">
                    <label for="">App name</label>
                    <p class="preview-tiktok-app-id">
                      To view SDK and API documentation, go to
                      <a :href="href"> Marketing App </a>
                    </p>
                  </div>
                  <h6 class="page-header-title my-5">Setup your Events</h6>
                  <div class="blow-event mb-4">
                    <p>Below are all events supported by TikTok</p>
                    <v-select
                      :items="items"
                      item-title="name"
                      v-model="selected_item"
                      density="compact"
                      variant="outlined"
                      return-object
                      hide-details
                    ></v-select>
                  </div>
                  <v-data-table
                    hide-default-footer="true"
                    :headers="data_headers"
                    :items="data_table"
                  ></v-data-table>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    text="Close"
                    variant="text"
                    @click="dialog2 = false"
                  ></v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <p class="event-description">
              App developers can use app details to set up events.
            </p>
            <p class="event-documentation">
              Need more documentation around Events SDK?Learn more
            </p>
          </v-card>
        </template>
      </v-stepper>
      <div
        class="custom-footer d-flex justify-space-between align-center mt-6 pt-4"
        style="border-top: 1px solid #e0e0e0"
      >
        <div class="left-side-btn">
          <button v-if="currentStep === 1" class="back-btn" @click="goBackStep">Back</button>
          <v-btn variant="text" v-else @click="previousStep" class="text-none">
            <v-icon start>mdi-chevron-left</v-icon>
            Previous
          </v-btn>
        </div>
        <div class="right-side-btn">
          <button @click="nextStep"
            :disabled="isEmailValid"
            :class="isEmailValid ? 'isEmailValid' : 'active' "
            class="text-none next-btn"
            v-if="currentStep < 2">
            Next
          </button>
          <button @click="finishSetup" v-else-if="currentStep === 2">Create</button>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { fetchAndroidAppData } from "@/core/androidAppFetcher.js";
import apiClient from "@/core/buildRequest";
export default {
  name: "CreateNewApp",
  data() {
    return {
      platform: "ios",
      dialog2: false,
      appUrl: "",
      appData: null,
      trackingSettings: [
        {
          title: "Connect to a mobile measurement partner",
          id: 1,
          value: "connect",
        },
        {
          title: "Use TikTok Events SDK",
          id: 2,
          value: "tiktok_event",
        },
        {
          title: "I don’t use a mobile measurement partner service",
          id: 3,
          value: "none",
        },
      ],
      tracking_type: "",
      mobile_partner: [
        {
          id: 1,
          title: "AppsFlyer",
          icon: "https://p16-ttam-va.ibyteimg.com/origin/meteor-i18n-static/005bc00f6e2180553938",
        },
        {
          id: 2,
          title: "Adjust",
          icon: "https://p16-ttam-va.ibyteimg.com/origin/meteor-i18n-static/005bc00f8b19c0622690",
        },
        {
          id: 3,
          title: "Tune",
          icon:"https://p16-ttam-va.ibyteimg.com/origin/meteor-i18n-static/005bc01011d8816787c2",
        },
        {
          id: 4,
          title:'Kochava',
          icon:'https://p16-ttam-va.ibyteimg.com/origin/meteor-i18n-static/005bc0173d000062bbc8'
        },
        {
          id: 5,
          title:'Singular',
          icon:'https://p16-ttam-va.ibyteimg.com/origin/meteor-i18n-static/005bc01672968053fbd2'
        },
        {
          id: 6,
          title:'Tenjin',
          icon:'https://p16-ttam-va.ibyteimg.com/origin/meteor-i18n-static/005bc0100093c065c49a'
        },
        {
          id: 7,
          title:'DCM (Double Click)',
          icon:'https://p16-ttam-va.ibyteimg.com/origin/meteor-i18n-static/201906265d0d263dc35850944f7c9426',
        },
        {
          id: 8,
          title:'Sizmek',
          icon:'https://p16-ttam-va.ibyteimg.com/origin/meteor-i18n-static/201906255d0df79dc9114eaf47b48f57'
        },
        {
          id: 9,
          title:'FlashTalking',
          icon:'https://p16-ttam-va.ibyteimg.com/origin/meteor-i18n-static/201906255d0dae88a7fa430547f9b4ea'
        },
        {
          id: 10,
          title:'Branch',
          icon:'https://p16-ttam-va.ibyteimg.com/origin/meteor-i18n-static/201906255d0d8172c78a44c24614bdfd'
        },
        {
          id: 11,
          title:'Dfinery',
          icon:'https://p16-ttam-va.ibyteimg.com/origin/meteor-i18n-static-sg/dfinery_logo.png'
        },
        {
          id: 12,
          title:'Airbridge',
          icon:'https://p16-ttam-va.ibyteimg.com/origin/meteor-i18n-static-sg/20210124180159_f4454045e69dd695611edc8bf80cb27c_Airbridge_logo.png'
        }
      ],
      selected_item: { id: 1, name: "Entertainment and Media" },
      items: [
        { id: 1, name: "Entertainment and Media" },
        { id: 2, name: "All App Events" },
        { id: 3, name: "Social" },
        { id: 4, name: "Business" },
        { id: 5, name: "E-commerce" },
        { id: 6, name: "Finance" },
        { id: 7, name: "Travel" },
        { id: 8, name: "Gaming" },
      ],
      data_headers: [
        {
          title: "TikTok Event",
          value: "first_column",
        },
        {
          title: "Event Name",

          value: "second_column",
        },
        {
          title: "Description",
          value: "third_column",
        },
      ],
      data_table: [
        {
          first_column: "Herbivore",
          second_column: "Herbivore",
          third_column: "Herbivore",
        },
        {
          first_column: "Herbivore",
          second_column: "Herbivore",
          third_column: "Herbivore",
        },
        {
          first_column: "Herbivore",
          second_column: "Herbivore",
          third_column: "Herbivore",
        },
        {
          first_column: "Herbivore",
          second_column: "Herbivore",
          third_column: "Herbivore",
        },
      ],
      email_resevice: "",
      add_id: "",
      href: `https://business-api.tiktok.com/portal/docs?id=${this.app_id}`,
      isEmailValid: true,
      steps: [
        "Download URL Settings",
        "Tracking Settings",
        "App Event Settings",
      ],
      currentStep: 1,
      canProceed: false,
      isSetupComplete: false,
    };
  },
  mounted() {
    this.add_id = localStorage.getItem("advertiser_id");
  },
  methods: {
    goBackStep() {
      this.$emit("goBackStep", true);
    },
    previousStep() {
      if (this.currentStep > 1) {
        this.currentStep--;
      }
    },
    nextStep() {
      if (this.currentStep < 3) {
        this.currentStep++;
      }
    },
    finishSetup() {
      apiClient.post("/campaign-ads/tiktok/create/app", {
          app_name: this.appData.name,
          package_name: "",
          download_url: this.appUrl,
          advertiser_id: localStorage.getItem("advertiser_id"),
          platform: this.platform,
          partner: "",
          tracking_url:'' ,
      }).then((response) => {
        console.log(response);
      })
    },
    async fetchAppData() {
      if (!this.appUrl) {
        this.appData = null;
        this.isEmailValid = true;
        return;
      }else{
        this.isEmailValid = false;
      }

      try {
        if (this.platform === "ios") {
          const match = this.appUrl.match(/id(\d+)/);
          const appId = match ? match[1] : "";

          if (!appId) return;

          const response = await fetch(
            `https://itunes.apple.com/lookup?id=${appId}`
          );
          const data = await response.json();

          if (data.results && data.results[0]) {
            const result = data.results[0];
            this.appData = {
              name: result.trackName,
              bundleId: result.bundleId,
              iconUrl: result.artworkUrl512 || result.artworkUrl100,
            };
          }
        } else {
          this.appData = await fetchAndroidAppData(this.appUrl);
        }
      } catch (error) {
        console.error("Error fetching app data:", error);
        this.appData = null;
      }
    },
    setTrackingType() {
      console.log(this.tracking_type);
    },
    setMobilePartner() {
      console.log(this.mobile_partner);
    },
  },
};
</script>
