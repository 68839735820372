<template>
  <v-container fluid class="pa-0 create-app">
    <v-row no-gutters>
      <v-col cols="12"><TiktokNavbar /></v-col>
      <v-col cols="2" class="side-bar-container">
        <SideBar />
      </v-col>
      <v-col cols="10"></v-col>
    </v-row>
  </v-container>
</template>

<script>
import SideBar from "@/components/tiktok/create-app/SideBar.vue";
import TiktokNavbar from "@/components/tiktok/TiktokNavbar.vue";

export default {
  components: { TiktokNavbar,SideBar },
  name: "CreateApp",
  data() {
    return {};
  },
};
</script>
