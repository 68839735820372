<template>
  <v-dialog v-model="dialogModel" max-width="800" persistent>
    <v-card class="pa-4">
      <v-card-title>Assign To Account</v-card-title>

      <v-card-text>
        <div class="d-flex flex-column ga-5">
          <span class="error-message" v-for="e in errors" :key="e">{{
            e
          }}</span>
          <template v-for="field in formFields" :key="field.id">
            <div
              :class="[
                field.type === 'autocomplete' ? 'filed-autocomplete' : 'field',
                'd-flex flex-column ga-2',
              ]"
            >
              <label :ref="field.ref" :for="field.id">
                {{ field.label }}
                <span v-if="field.required" class="required">*</span>
              </label>

              <!-- Account Autocomplete -->
              <v-autocomplete
                v-if="field.id === 'account_id'"
                v-model="formData[field.id]"
                :items="all_accounts"
                item-title="account_number"
                item-value="id"
                hide-details
              />

              <!-- Contacts Autocomplete -->
              <v-autocomplete
                v-else-if="field.id === 'contact_id'"
                v-model="formData[field.id]"
                :items="all_contacts"
                item-title="contact_number"
                item-value="id"
                multiple
                chips
                closable-chips
                hide-details
              />
            </div>
          </template>
        </div>
      </v-card-text>

      <v-card-actions class="sticky-footer-dialog">
        <v-spacer></v-spacer>
        <span class="cancel-btn cursor-pointer" @click="cancelDialog"
          >Cancel</span
        >
        <span class="choose-btn cursor-pointer" @click="saveDialog">Save</span>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { useContactsStore } from "@/store/crm/useContactsStore";
import { useAccountsStore } from "@/store/crm/useAccountsStore";
import { getFieldError } from "@/utils/formValdiation.js";
import apiClient from "@/core/buildRequest";

export default {
  name: "AssignAccountDialog",

  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    contacts: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      formData: {
        account_id: "",
        contact_id: [],
      },
      errors: [],
      contacts_store: useContactsStore(),
      accounts_store: useAccountsStore(),
    };
  },

  computed: {
    dialogModel: {
      get() {
        return this.dialog;
      }, 
      set(value) {
        this.$emit("update:dialog", value);
      },
    },

    all_contacts() {
      return this.contacts_store?.getContacts || [];
    },

    all_accounts() {
      return this.accounts_store?.getAccounts || [];
    },

    formFields() {
      return [
        {
          id: "account_id",
          label: "Account",
          type: "autocomplete",
          required: true,
          ref: "accountInput",
        },
        {
          id: "contact_id",
          label: "Contacts",
          type: "autocomplete",
          required: true,
          ref: "contactsInput",
        },
      ];
    },
  },

  watch: {
    contacts: {
      handler(newVal) {
        this.formData.contact_id = newVal;
      },
      deep: true,
    },
  },

  created() {
    if (this.contacts.length > 0) {
      this.formData.contact_id = this.contacts;
    }
  },

  methods: {
    cancelDialog() {
      this.$emit("update:dialog", false);
    },

    validateForm() {
      this.errors = [];
      let isValid = true;

      for (const field of this.formFields) {
        if (!field.required) continue;

        const value = this.formData[field.id];
        const error = getFieldError(field.label, value);

        if (error) {
          this.errors.push(error);
          isValid = false;
          const el = this.$refs[field.ref]?.[0];
          if (el) {
            el.classList.add("error-validation");
          }
        }
      }

      return isValid;
    },
    resetForm() {
      Object.keys(this.formData).forEach((key) => {
        this.formData[key] = Array.isArray(this.formData[key]) ? [] : "";
      });
    },
    async saveDialog() {
      if (!this.validateForm()) return;
      try {

        await apiClient
          .post(`/leads-managment/accounts/assign/contact`, this.formData)
          .then(() => {
            this.contacts_store.getAllContacts();
          });

        this.resetForm();
        this.$emit("update:dialog", false);
      } catch (error) {
        this.errors.push(error.response?.data?.msg || "An error occurred");
        console.error(error);
      }
    },
  },
};
</script>
