<template>
  <v-dialog v-model="dialogModel" max-width="800" persistent>
    <v-card class="pa-4">
      <v-card-title>Add New Contact</v-card-title>

      <v-card-text>
        <div class="d-flex flex-column ga-5">
          <span class="error-message" v-for="e in errors" :key="e">{{
            e
          }}</span>

          <!-- Form Fields -->
          <template v-for="field in formFields" :key="field.id">
            <div
              :class="[
                field.type === 'autocomplete' ? 'filed-autocomplete' : 'field',
                'd-flex flex-column ga-2',
              ]"
            >
              <label :ref="field.ref" :for="field.id">
                {{ field.label }}
                <span v-if="field.required" class="required">*</span>
              </label>

              <!-- Regular Input -->
              <input
                v-if="field.type === 'input'"
                :id="field.id"
                v-model="formData[field.id]"
                :type="field.inputType || 'text'"
              />

              <v-autocomplete
                v-else-if="
                  field.type === 'autocomplete' && field.id !== 'job_title_id'
                "
                v-model="formData[field.id]"
                :items="field.items"
                :item-value="field.itemValue"
                :item-title="field.itemTitle"
                @click="field.onClick"
                @update:search="field.onClick"
                hide-details
                label=""
              >
              </v-autocomplete>
              <v-autocomplete
                v-else-if="
                  field.type === 'autocomplete' && field.id === 'job_title_id'
                "
                v-model="formData[field.id]"
                label=""
                :items="job_titles"
                item-value="id"
                item-title="name"
                @click="onJobTitleAutocompleteOpen"
                @update:search="updateJobTitleSearch"
              >
                <template v-if="job_title_search !== ''" #append-item>
                  <div class="pa-2">
                    {{ job_title_search }} not found.
                    <a
                      class="cursor-pointer text-primary text-decoration-none"
                      @click="handleNoResults"
                      >Click here</a
                    >
                    to add {{ job_title_search }}.
                  </div>
                </template>
              </v-autocomplete>
            </div>
          </template>
        </div>
      </v-card-text>

      <v-card-actions class="sticky-footer-dialog">
        <v-spacer></v-spacer>
        <span class="cancel-btn cursor-pointer" @click="cancelDialog"
          >Cancel</span
        >
        <span class="choose-btn cursor-pointer" @click="saveDialog">Save</span>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { useHelperStore } from "@/store/crm/useHelperStore";
import { useContactsStore } from "@/store/crm/useContactsStore";
import apiClient from "@/core/buildRequest";
import { getFieldError } from "@/utils/formValdiation.js";
export default {
  name: "ContactDialog",

  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      formData: {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        country_id: null,
        city_id: null,
        job_title_id: null,
        potentiality: "",
        notes: "",
      },
      job_title_search: "",
      helper_store: null,
      countries: [],
      cities: [],
      job_titles: [],
      errors: [],
    };
  },

  computed: {
    dialogModel: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("update:dialog", value);
      },
    },

    formFields() {
      return [
        {
          id: "first_name",
          label: "First Name",
          type: "input",
          required: true,
          ref: "firstNameInput",
        },
        {
          id: "last_name",
          label: "Last Name",
          type: "input",
          required: true,
          ref: "lastNameInput",
        },
        {
          id: "phone",
          label: "Phone Number",
          type: "input",
          required: true,
          ref: "phoneInput",
        },
        {
          id: "email",
          label: "Email Address",
          type: "input",
          inputType: "email",
          required: true,
          ref: "emailInput",
        },
        {
          id: "country_id",
          label: "Country",
          type: "autocomplete",
          required: true,
          ref: "countryInput",
          items: this.countries,
          itemValue: "id",
          itemTitle: "name.en",
          onClick: this.onCountryAutocompleteOpen,
        },
        {
          id: "city_id",
          label: "City",
          type: "autocomplete",
          required: true,
          ref: "cityInput",
          items: this.cities,
          itemValue: "id",
          itemTitle: "name.en",
          onClick: () => this.onCityAutocompleteOpen(this.formData.country_id),
        },
        {
          id: "job_title_id",
          label: "Job Title",
          type: "autocomplete",
          required: true,
          ref: "jobTitleInput",
          items: this.job_titles,
          itemValue: "id",
          itemTitle: "name",
        },
        {
          id: "potentiality",
          label: "Potentiality",
          type: "autocomplete",
          required: true,
          ref: "potentialityInput",
          items: ["VIP", "A", "B", "C"],
        },
        {
          id: "notes",
          label: "Notes",
          type: "input",
          required: false,
        },
      ];
    },
  },

  watch: {
    "helper_store.countries": {
      handler(newCountries) {
        this.countries = newCountries;
      },
      deep: true,
    },
    "helper_store.cities": {
      handler(newCities) {
        this.cities = newCities;
      },
      deep: true,
    },
    ...Object.fromEntries(
      [
        "first_name",
        "last_name",
        "email",
        "phone",
        "country_id",
        "city_id",
        "job_title_id",
      ].map((field) => [
        `formData.${field}`,
        function (value) {
          const el = this.$refs[`${field}Input`]?.[0];
          if (el) {
            el.classList[!value ? "add" : "remove"]("error-validation");
          }
        },
      ])
    ),
  },

  created() {
    this.helper_store = useHelperStore();
    this.onJobTitleAutocompleteOpen;
  },

  methods: {
    async onCountryAutocompleteOpen() {
      if (this.helper_store?.getCountries.length === 0) {
        await this.helper_store?.fetchCountries();
        this.countries = this.helper_store?.getCountries;
      }
    },

    async onCityAutocompleteOpen(country_id) {
      if (this.helper_store.selectedCountry !== country_id) {
        this.helper_store.setSelectedCountry(country_id);
        await this.helper_store?.fetchCities();
        this.cities = this.helper_store?.getCities;
      }
    },

    async onJobTitleAutocompleteOpen() {
      if (this.helper_store?.getJobTitles.length === 0) {
        await this.helper_store?.fetchJobTitles();
      }
      this.job_titles = this.helper_store?.getJobTitles;
    },

    cancelDialog() {
      this.$emit("update:dialog", false);
    },

    validateForm() {
      this.errors = []; // Clear previous errors
      let isValid = true;

      // Validate required fields
      for (const field of this.formFields) {
        if (!field.required) continue;

        const value = this.formData[field.id];
        const error = getFieldError(field.label, value);

        if (error) {
          // this.errors.push(error);
          isValid = false;

          // Add visual error indication
          const el = this.$refs[field.ref]?.[0];
          if (el) {
            el.classList.add("error-validation");
          }
        }
      }

      return isValid;
    },

    resetForm() {
      Object.keys(this.formData).forEach((key) => {
        this.formData[key] = typeof this.formData[key] === "string" ? "" : null;
      });
    },

    async saveDialog() {
      if (!this.validateForm()) return;
      try {
        const response = await apiClient.post(
          "/leads-managment/contacts",
          this.formData
        );
        const contact_store = useContactsStore();
        contact_store?.pushContact(response.data.data);
        this.resetForm();
        this.$emit("update:dialog", false);
      } catch (error) {
        this.errors.push(error.response.data.msg);
        console.error(error.response.data.msg);
      }
    },
    updateJobTitleSearch(value) {
      this.job_title_search = value;
    },
    async handleNoResults() {
      try { 
        const response = await apiClient.post("/leads-managment/job-titles", {
          name: this.job_title_search,
        });
        this.helper_store?.pushJobTitle(response.data.data);
        this.formData.job_title_id = response.data.data.id;
      } catch (error) {
        console.error("Failed to create new job title:", error);
      }
    },
  },
};
</script>
