import { defineStore } from "pinia";
import { ref } from "vue";

export const useTaskStore = defineStore("task", () => {
  const name = ref("");
  const description = ref("");
  const status = ref({ title: "To Do", color: "rgba(83, 100, 113, 1)" });
  const tags = ref([]);
  const startDateTime = ref(null);
  const endDateTime = ref(null);
  const priority = ref({ title: "urgent", color: "rgba(233, 44, 44, 1)" });
  const assignee = ref({
    id: 1,
    name: "Mohamed Ahmed",
    img: "@/assets/images/Avatar.svg",
  });
  const postType = ref("sponsored");
  const sponsoredEndDate = ref("");
  const setEndDate = ref(true);
  const selectedPlatforms = ref([]);
  const selectedBranch = ref({
    id: 1,
    name: "Main Branch",
    image: "https://picsum.photos/64/64?random=1",
  });
  const caption = ref("");
  const attachments = ref([]);
  const showComments = ref(true);

  function updateStatus(newStatus) {
    status.value = newStatus;
  }

  function updatePriority(newPriority) {
    priority.value = newPriority;
  }

  function updateAssignee(newAssignee) {
    assignee.value = newAssignee;
  }

  function toggleComments() {
    showComments.value = !showComments.value;
  }

  function updateTags(newTags) {
    tags.value = newTags;
  }

  function updatePlatforms(platforms) {
    selectedPlatforms.value = platforms;
  }

  function updateBranch(branch) {
    selectedBranch.value = branch;
  }

  return {
    name,
    description,
    status,
    tags,
    startDateTime,
    endDateTime,
    priority,
    assignee,
    postType,
    sponsoredEndDate,
    setEndDate,
    selectedPlatforms,
    selectedBranch,
    caption,
    attachments,
    showComments,
    updateStatus,
    updatePriority,
    updateAssignee,
    toggleComments,
    updateTags,
    updatePlatforms,
    updateBranch,
  };
});
