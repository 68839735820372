<template>
  <div class="messaging-app">
    <v-divider></v-divider>
    <div class="facebook-page-select my-3">
      <div class="facebook-page-select-header">
        <label for="" class="d-block roboto-bold mb-1">Facebook Page </label>
        <p class="roboto-regular d-block mb-2">Choose a Page for this ad.</p>
      </div>
      <div class="facebook-page-select-body">
        <div class="card">
          <div class="card-child" @click="toggleSelectedPagesId">
            <div class="page-image">
              <img
                class="page-logo"
                :src="selectedItemPage?.picture.data.url"
                alt=""
              />
              <img
                class="platform-logo"
                src="@/assets/soft-ware-img/facebookLogo.png"
                alt=""
              />
            </div>
            <div class="page-name">
              <div>
                <p class="roboto-bold">Facebook Page</p>
                <p class="roboto-regular">{{ selectedItemPage?.name }}</p>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="20px"
                viewBox="0 -960 960 960"
                width="20px"
                fill="#5f6368"
              >
                <path
                  d="M216-216h51l375-375-51-51-375 375v51Zm-72 72v-153l498-498q11-11 23.84-16 12.83-5 27-5 14.16 0 27.16 5t24 16l51 51q11 11 16 24t5 26.54q0 14.45-5.02 27.54T795-642L297-144H144Zm600-549-51-51 51 51Zm-127.95 76.95L591-642l51 51-25.95-25.05Z"
                />
              </svg>
            </div>
          </div>
          <div class="change-selected-page pa-2" v-if="selectedPagesId">
            <v-select
              v-model="selectedItemPage"
              :items="selectedPages"
              density="compact"
              variant="outlined"
              return-object
              item-value="id"
              item-title="title"
              hide-details
            >
              <template v-slot:selection="{ item }">
                <div class="d-flex align-center ga-2">
                  <img
                    style="width: 20px; height: 20px; border-radius: 50%"
                    :src="item.raw.picture.data.url"
                    alt=""
                  />
                  <span>{{ item.raw?.name || "" }}</span>
                </div>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item @click="setCahrgedItem(item)">
                  <div class="my-form">
                    <div class="ga-3">
                      <input
                        :checked="
                          selectedItemPage &&
                          selectedItemPage.id === item.raw.id
                        "
                        :id="item.raw.id"
                        type="radio"
                        name="website_conversion_option_page_id"
                        :value="item"
                      />
                      <div class="d-flex align-center ga-1">
                        <img
                          style="width: 30px; height: 30px; border-radius: 8px"
                          :src="item.raw.picture.data.url"
                          alt=""
                        />
                        <label
                          :for="item.raw.id"
                          class="d-block"
                          style="font-size: 14px"
                          >{{ item.raw.name }}</label
                        >
                      </div>
                    </div>
                  </div>
                </v-list-item>
              </template>
            </v-select>
          </div>
        </div>

        <div class="messaging-apps-description mb-3">
          <p class="roboto-bold mb-2">Messaging apps</p>
          <span class="roboto-regular d-block">
            Choose at least one destination where you are available to chat. If
            you select multiple apps, we'll send people who tap on your ad to
            the app where they're most likely to chat with you.
          </span>
        </div>
        <div class="messaging-apps-list">
          <div class="increase-budget-periods">
            <label class="checkbox">
              <input
                type="checkbox"
                @click="setMessengerValue"
                v-model="messaging_apps_massenger"
              />
              <span class="checkmark" style="top: 12px"></span>
              <div class="text d-flex align-center ga-4">
                <div style="position: relative">
                  <img
                    :src="selectedItemPage?.picture.data.url"
                    style="border-radius: 50%"
                    alt=""
                  />
                  <img
                    style="
                      width: 20px;
                      height: 20px;
                      border-radius: 50%;
                      position: absolute;
                      bottom: 0;
                      border: 1px solid #fff;
                      right: 0;
                    "
                    src="@/assets/images/meta/messenger_5968771.svg"
                    alt=""
                  />
                </div>
                <div class="messenger-info">
                  <p style="color: #000">Messenger</p>
                  <span class="d-block">{{ selectedItemPage?.name }}</span>
                </div>
              </div>
            </label>
          </div>
        </div>
        <div
          class="messaging-apps-list"
          v-if="selectedItemPage?.instagram_business_account"
        >
          <div class="increase-budget-periods">
            <label class="checkbox">
              <input
                type="checkbox"
                @click="toggleSelectingCountry"
                v-model="messaging_apps_massenger"
              />
              <span class="checkmark" style="top: 12px"></span>
              <div class="text d-flex align-center ga-4">
                <div style="position: relative">
                  <img
                    :src="selectedItemPage?.picture.data.url"
                    style="border-radius: 50%"
                    alt=""
                  />
                  <img
                    style="
                      width: 20px;
                      height: 20px;
                      border-radius: 50%;
                      position: absolute;
                      bottom: 0;
                      border: 1px solid #fff;
                      right: 0;
                    "
                    src="@/assets/images/icons/instagram.svg"
                    alt=""
                  />
                </div>
                <div class="messenger-info">
                  <p style="color: #000">Instagram</p>
                  <span class="d-block">{{ selectedItemPage?.name }}</span>
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
    <v-divider></v-divider>
  </div>
</template>

<script>
import apiClient from "@/core/buildRequest";
export default {
  name: "MessagingApps",
  data() {
    return {
      selectedPagesId: false,
      selectedItemPage: null,
      selectedPages: [],
      messaging_apps_massenger: true,
      messaging_apps_instagram: false,
      messaging_apps_whatsapp: false,
    };
  },
  mounted() {
    this.getPages();
  },
  methods: {
    toggleSelectedPagesId() {
      this.selectedPagesId = !this.selectedPagesId;
    },
    getPages() {
      apiClient
        .get("/publish-posts/all/pages")
        .then((response) => {
          let data = response.data.data.data;
          this.selectedPages = data;
          this.selectedItemPage = data[0];
          console.log("data", data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setCahrgedItem(item) {
      this.selectedItemPage = item.raw;
    },
    setMessengerValue() {
      this.messaging_apps_massenger = !this.messaging_apps_massenger;
      localStorage.setItem(
        "messaging_apps_massenger",
        this.messaging_apps_massenger
      );
    },
  },
};
</script>
