import * as cheerio from 'cheerio';

export async function fetchAndroidAppData(url) {
  try {
    const response = await fetch(url);
    const html = await response.text();
    const $ = cheerio.load(html);

    // Extract app data from Google Play Store page
    const name = $('h1[itemprop="name"]').text().trim();
    const bundleId = url.match(/id=([^&]+)/)[1];
    const iconUrl = $('img[itemprop="image"]').first().attr('src');

    return {
      name: name || 'Unknown App',
      bundleId,
      iconUrl: iconUrl || '@/assets/soft-ware-img/adsPerformance.svg'
    };
  } catch (error) {
    console.error('Error fetching Android app data:', error);
    return null;
  }
}