<template>
  <div class="overview-header d-flex ga-2 align-center">
    <div class="add-activity">
      <v-menu>
        <template v-slot:activator="{ props }">
          <span
            v-bind="props"
            class="d-flex ga-2 align-center cursor-pointer cursor-pointer-hover pa-2"
            ><svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.16602 10.8337H4.16602V9.16699H9.16602V4.16699H10.8327V9.16699H15.8327V10.8337H10.8327V15.8337H9.16602V10.8337Z"
                fill="#4D4D4D"
              />
            </svg>
            <span>Add activity</span>
          </span>
        </template>
        <v-list>
          <v-list-item @click="addMeetingDialog = true">
            <div class="d-flex ga-2 align-center">
              <img :src="MeetingIcon" alt="Meeting" width="24" />
              <span>Meeting</span>
            </div>
          </v-list-item>
          <v-list-item @click="addNoteDialog = true">
            <div class="d-flex ga-2 align-center">
              <img :src="NoteIcon" alt="Note" width="24" />
              <span>Note</span>
            </div>
          </v-list-item>
          <v-list-item @click="addCallSummaryDialog = true">
            <div class="d-flex ga-2 align-center">
              <img :src="CallSuIcon" alt="Call Summary" width="24" />
              <span>Call Summary</span>
            </div>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div class="filter">
      <v-menu>
        <template v-slot:activator="{ props }">
          <span
            v-bind="props"
            class="d-flex ga-2 align-center cursor-pointer cursor-pointer-hover pa-2"
            ><svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.9881 4.52007C11.673 4.14956 8.32686 4.14956 5.01172 4.52007C4.78742 4.54514 4.6794 4.80819 4.82135 4.98366L7.7522 8.60662C8.80211 9.90447 9.37491 11.5233 9.37491 13.1926V15.6003L10.6249 16.517V13.1926C10.6249 11.5233 11.1977 9.90446 12.2476 8.60662L15.1785 4.98366C15.3204 4.80819 15.2124 4.54514 14.9881 4.52007ZM4.87288 3.27781C8.2803 2.89698 11.7195 2.89698 15.1269 3.27781C16.3327 3.41257 16.9134 4.82656 16.1503 5.76983L13.2194 9.39279C12.3495 10.4681 11.8749 11.8094 11.8749 13.1926V17.7503C11.8749 17.9859 11.7425 18.2014 11.5324 18.3079C11.3223 18.4143 11.0702 18.3936 10.8803 18.2543L8.38031 16.421C8.21976 16.3033 8.12491 16.1161 8.12491 15.917V13.1926C8.12491 11.8094 7.65031 10.4682 6.78038 9.39279L3.84953 5.76983C3.08646 4.82656 3.66711 3.41257 4.87288 3.27781Z"
                fill="#4D4D4D"
              />
            </svg>

            <span>Sort By</span>
          </span>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in sortItems"
            :key="index"
            :value="index"
            @click="handleSort(item)"
          >
            <div class="d-flex ga-2 align-center">
              <img :src="item.icon" :alt="item.name" width="24" />
              <span>{{ item.name }}</span>
            </div>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
  <div class="overview-content">
    <OverviewContents
      :selectedSortItem="selectedSortItem"
      :item="item"
      :activities="activities"
    />
  </div>
  <AddMeeting :item="item" v-model:addMeetingDialog="addMeetingDialog" />
  <AddCallSummary
    :item="item"
    v-model:addCallSummaryDialog="addCallSummaryDialog"
  />
  <AddNote :item="item" v-model:addNoteDialog="addNoteDialog" />
</template>

<script>
import NoteIcon from "@/assets/images/icons/note.svg";
import CallSuIcon from "@/assets/images/icons/call-su.svg";
import MeetingIcon from "@/assets/images/icons/meeting.svg";
import OverviewContents from "@/components/crm/leads/comments/OverviewContents.vue";
import AddMeeting from "@/components/crm/leads/comments/newActivity/AddMeeting.vue";
import AddCallSummary from "@/components/crm/leads/comments/newActivity/AddCallSummary.vue";
import AddNote from "@/components/crm/leads/comments/newActivity/AddNote.vue";
export default {
  props: ["item", "activities"],
  name: "OverviewBody",
  components: { OverviewContents, AddMeeting, AddCallSummary, AddNote },
  data() {
    return {
      addMeetingDialog: false,
      addCallSummaryDialog: false,
      addNoteDialog: false,
      selectedSortItem: "",
      sortItems: [
        { icon: MeetingIcon, name: "Newest" },
        { icon: NoteIcon, name: "Oldest" },
      ],
    };
  },
  computed: {
    NoteIcon() {
      return NoteIcon;
    },
    CallSuIcon() {
      return CallSuIcon;
    },
    MeetingIcon() {
      return MeetingIcon;
    },
  },
  methods: {
    handleSort(item) {
      this.selectedSortItem = item.name;
    },
  },
};
</script>

<style scoped>
.overview-header {
  padding: 15px;
}
.add-activity {
  display: flex;
  align-items: center;
}

.filter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.overview-content {
  background-color: #eeeeee;
  padding: 20px;
  padding-top: 10px !important;
  color: #333333;
  font-size: 14px;
  line-height: 1.6;
}
.cursor-pointer-hover:hover {
  background-color: rgba(235, 235, 235, 0.596);
  border-radius: 8px;
}
.header-dialogs .v-overlay__content {
  position: absolute;
  bottom: 0px;
  right: 0px;
}
</style>
