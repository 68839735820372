<template>
  <v-switch
    :model-value="add_to_calendar"
    color="green"
    @update:model-value="updateAddToCalendar"
    :true-value="1"
    :false-value="0"
  ></v-switch>
</template>

<script>
import apiClient from "@/core/buildRequest";
import { useEventsStore } from "@/store/crm/useEventsStore";

export default {
  name: "statusCol",
  props: ["item", "id"],
  data() {
    return {
      add_to_calendar: 0,
      event_store: null,
    };
  },
  mounted() {
    this.add_to_calendar = this.item.add_to_calendar ?? 0;
  },
  created() {
    this.eventStore = useEventsStore();
  },
  watch: {
    "item.add_to_calendar"(newValue) {
      this.add_to_calendar = Number(newValue) ?? 0;
    },
  },
  methods: {
    async updateAddToCalendar(value) {
      this.add_to_calendar = value;
      try {
        await apiClient
          .post(`/leads-managment/deals/add_to_calender/${this.id}`, {
            add_to_calendar: value,
          })
          .then((res) => {
            this.event_store.updateAddToCalender(this.id, value);
            console.log(res);
          });
      } catch (error) {
        console.error("Error updating add_to_calendar:", error);
      }
    },
  },
};
</script>
