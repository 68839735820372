<template>
  <div class="pa-4 dashboard-card" elevation="2">
    <div class="d-flex justify-space-between">
      <span class="text-subtitle-1 font-weight-bold">New Leads</span>
      <div class="d-flex align-center ga-2">
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.5 2.25V5.75M7.5 2.25V5.75M3.125 9.25H18.875M4.875 4H17.125C18.0915 4 18.875 4.7835 18.875 5.75V18C18.875 18.9665 18.0915 19.75 17.125 19.75H4.875C3.9085 19.75 3.125 18.9665 3.125 18V5.75C3.125 4.7835 3.9085 4 4.875 4Z"
            stroke="#1A1A1A"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <v-select
          v-model="new_leads_filter"
          :class="
            trail_2 === 'replied'
              ? 'green-selection'
              : trail_2 === 'not_replied'
              ? 'red-selection'
              : ''
          "
          :items="filter_days"
          item-title="title"
          item-value="value"
        ></v-select>
      </div>
    </div>
    <div class="d-flex justify-center align-center p-3">
      <div
        class="w-50 d-flex ga-3 flex-column justify-center align-center mt-4 card-chart-container"
      >
        <!-- green  -->

        <svg
          v-if="data.growth_rate === 'Negative'"
          width="98"
          height="50"
          viewBox="0 0 98 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M97.25 1C81.6531 2.29974 80.6872 33.4164 65.25 37C52.5202 39.9551 46.0741 23.1804 33.25 25C19.3403 26.9737 14.8891 44.4536 1.25 49H97.25V1Z"
            fill="#ECFDF3"
          />
          <path
            d="M97.25 1C81.6531 2.29974 80.6872 33.4164 65.25 37C52.5202 39.9551 46.0741 23.1804 33.25 25C19.3403 26.9737 14.8891 44.4536 1.25 49H97.25V1Z"
            fill="url(#paint0_linear_2567_71713)"
          />
          <path
            d="M1.25 49C14.8891 44.4536 19.3403 26.9737 33.25 25C46.0741 23.1804 52.5202 39.9551 65.25 37C80.6872 33.4164 81.6531 2.29974 97.25 1"
            stroke="#12B76A"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <defs>
            <linearGradient
              id="paint0_linear_2567_71713"
              x1="49.25"
              y1="1"
              x2="49.25"
              y2="49"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.641167" stop-color="white" stop-opacity="0" />
              <stop offset="1" stop-color="white" />
            </linearGradient>
          </defs>
        </svg>
 
        <!-- red  -->
        <svg
          v-else
          width="99"
          height="50"
          viewBox="0 0 99 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.66406 1C17.261 2.29974 18.2269 33.4164 33.6641 37C46.3939 39.9551 52.8399 23.1804 65.6641 25C79.5738 26.9737 84.025 44.4536 97.6641 49H1.66406V1Z"
            fill="#FEF3F2"
          />
          <path
            d="M1.66406 1C17.261 2.29974 18.2269 33.4164 33.6641 37C46.3939 39.9551 52.8399 23.1804 65.6641 25C79.5738 26.9737 84.025 44.4536 97.6641 49H1.66406V1Z"
            fill="url(#paint0_linear_2567_71753)"
          />
          <path
            d="M97.6641 49C84.025 44.4536 79.5738 26.9737 65.6641 25C52.8399 23.1804 46.3939 39.9551 33.6641 37C18.2269 33.4164 17.261 2.29974 1.66406 1"
            stroke="#F04438"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <defs>
            <linearGradient
              id="paint0_linear_2567_71753"
              x1="49.6641"
              y1="1"
              x2="49.6641"
              y2="49"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.641167" stop-color="white" stop-opacity="0" />
              <stop offset="1" stop-color="white" />
            </linearGradient>
          </defs>
        </svg>

        <span class="total-budget">{{ data.total }}</span>
        <span class="title">total leads</span>
        <div class="d-flex ga-2 footer align-center">
          <div class="icon">
            <svg
              v-if="data.growth_rate === 'Negative'"
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.7493 15.8332V4.1665M10.7493 4.1665L4.91602 9.99984M10.7493 4.1665L16.5827 9.99984"
                stroke="#12B76A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <svg
              v-else
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.99935 4.1665V15.8332M9.99935 15.8332L15.8327 9.99984M9.99935 15.8332L4.16602 9.99984"
                stroke="#F04438"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="value">{{ data.percentage }}</div>
          <span class="subtitle">Value Growth Last week</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewLeadsCard",
  props: {
    data: Object,
  },
  data: () => ({
    new_leads_filter: "",
    filter_days: [
      { title: "Last Day", value: "last_day" },
      { title: "Last Week", value: "last_week" },
      { title: "Last Month", value: "last_month" },
      { title: "Last Year", value: "last_year" },
    ],
  }),
  watch: {
    new_leads_filter(val) {
      console.log(this.new_leads_filter);
      this.$emit("filter", val);
    },
  },
};
</script>
