<template>
  <div class="main-container">
    <div class="rectangle">
      <div class="mask-group">
        <div class="group">
          <div class="group-1"><div class="rectangle-2"></div></div>
        </div>
      </div>
      <div class="frame">
        <span class="home">Home</span>
        <div class="frame-3"><span class="features">Features</span></div>
        <span class="about-us">About us</span
        ><span class="pricing">Pricing</span
      ><span class="privacy-policy">Privacy policy</span
      ><span class="terms-of-service">Terms of service</span>
      </div>
      <div class="flex-row-dcbe">
        <div class="capa"></div>
        <div class="instagram"></div>
        <div class="facebook"></div>
      </div>
      <span class="all-rights-reserved">© 2025 All Rights Reserved</span>
    </div>
    <div class="ellipse"></div>
    <div class="design-elements"></div>
    <div class="header"></div>
    <div class="rectangle-4"></div>
    <div class="component">
      <div class="frame-5">
        <div class="introduction-section">
          <div class="frame-6">
            <div
                class="frame-7"
                :class="{ active: activeTab === 'leadsManagement' }"
                @click="changeContent('leadsManagement')"
            >
              <div class="frame-8"></div>
              <span class="leads-management">Leads management</span>
            </div>
            <div
                class="frame-9"
                :class="{ active: activeTab === 'publishPosts' }"
                @click="changeContent('publishPosts')"
            >
              <div class="save"></div>
              <span class="publish-posts">Publish posts</span>
            </div>
            <div
                class="frame-a"
                :class="{ active: activeTab === 'createAdCampaign' }"
                @click="changeContent('createAdCampaign')"
            >
              <div class="line"></div>
              <span class="create-ad-campaign">Create Ad/Campaign</span>
            </div>
            <div
                class="frame-b"
                :class="{ active: activeTab === 'adsPerformance' }"
                @click="changeContent('adsPerformance')"
            >
              <div class="layer"></div>
              <span class="ads-performance">Ads performance</span>
            </div>
            <div
                class="frame-c"
                :class="{ active: activeTab === 'engagementInsights' }"
                @click="changeContent('engagementInsights')"
            >
              <div class="layer-d"></div>
              <span class="engagements-insights">Engagements & Insights</span>
            </div>
            <div
                class="frame-e"
                :class="{ active: activeTab === 'messagesCenter' }"
                @click="changeContent('messagesCenter')"
            >
              <div class="layer-f"></div>
              <span class="messages-center">Messages center</span>
            </div>
            <div class="frame-e frame-d"
                 :class="{ active: activeTab === 'calender' }"
                 @click="changeContent('calender')">
              <div class="layer-g"></div>
              <span class="messages-center">Calender</span>
            </div>
            <div class="frame-e"
                 :class="{ active: activeTab === 'integrations' }"
                 @click="changeContent('integrations')"
            >

              <div class="layer-h"></div>
              <span class="messages-center">Integrations</span>
            </div>
          </div>
        </div>
        <div class="frame-13">
          <div class="frame-14">
            <div class="frame-15">
              <span class="title">{{ currentContent.title }}</span>
              <span class="description">{{ currentContent.description }}</span>
            </div>
            <div class="cta">
              <span class="label">{{ currentContent.cta }}</span>
            </div>
          </div>
          <div class="group-17"
               :style="{ backgroundImage: `url('${computedBackgroundImage}')` }"
          ></div>
        </div>
      </div>
    </div>
    <div class="ellipse-18"></div>
    <div class="rectangle-19">
      <div class="rectangle-1a"></div>
      <div class="rectangle-1b"></div>
      <div class="rectangle-1c"></div>
    </div>
    <div class="design-elements-1d"></div>
    <div class="frame-1e">
      <div class="upper-cards">
        <div class="card">
          <div class="background"></div>
          <div class="frame-1f">
            <div class="social-media"><div class="png-clipart-odoo"></div></div>
            <span class="website-design-development">Odoo</span>
          </div>
        </div>
        <div class="card-20">
          <div class="background-21"></div>
          <div class="frame-22">
            <div class="social-media-23"><div class="large-blob"></div></div>
            <span class="search-engine-optimization">Geneysis</span>
          </div>
        </div>
      </div>
      <div class="lower-cards">
        <div class="card-24">
          <div class="background-25"></div>
          <div class="frame-26">
            <div class="social-media-27">
              <div class="salesforce-com-logo"></div>
            </div>
            <span class="video-editing-production">Salesforce</span>
          </div>
        </div>
        <div class="card-28">
          <div class="background-29"></div>
          <div class="frame-2a">
            <div class="social-media-2b"><div class="download"></div></div>
            <span class="content-writing">Ziwo</span>
          </div>
        </div>
        <div class="card-2c">
          <div class="background-2d"></div>
          <div class="frame-2e">
            <div class="social-media-2f"><div class="page"></div></div>
            <span class="social-media-marketing">Zoho</span>
          </div>
        </div>
        <div class="card-30">
          <div class="background-31"></div>
          <div class="frame-32">
            <div class="social-media-33"><div class="pic-10"></div></div>
            <span class="pay-per-click">Hubspot</span>
          </div>
        </div>
      </div>
    </div>
    <div class="section-text">
      <span class="our-digital-marketing-expertise"
      >Seamless Software Integrations</span
      ><span class="connect-your-favorite"
    >Connect your favorite tools to streamline your workflow. From analytics
        and marketing to content creation and leads management, manage all tasks
        in one place. Unlock data potential, enhance collaboration, and boost
        productivity.</span
    >
    </div>
    <div class="design-elements-34"></div>
    <div class="rectangle-35"></div>
    <div class="group-36">
      <div class="frame-37">
        <div class="group-38"></div>
        <div class="frame-39">
          <div class="group-3a">
            <div class="group-3b">
              <div class="frame-3c"></div>
              <div class="rectangle-3d"></div>
            </div>
          </div>
        </div>
        <div class="group-3e"></div>
      </div>
      <div class="frame-3f">
        <div class="group-40"></div>
        <div class="group-41"></div>
      </div>
      <div class="frame-42">
        <div class="group-43"></div>
        <div class="group-44"></div>
        <div class="group-45"></div>
      </div>
    </div>
    <div class="frame-46">
      <div class="frame-47">
        <span class="unified-marketing-platform-management"
        >Unified Marketing Platform<br />Management</span
        ><span class="marketing-platforms"
      >Manage all your marketing platforms in one place. Schedule posts,
          track engagement, and analyze performance effortlessly to grow your
          online presence.</span
      >
      </div>
      <div class="cta-48"><span class="learn-more">Learn more</span></div>
    </div>
    <div class="rectangle-49"></div>
    <div class="group-4a">
      <div class="flex-column-bf">
        <span class="contact-us">Contact Us</span>
        <div class="frame-4b">
          <div class="group-4c">
            <div class="group-4d">
              <span class="first-name">First name</span>
              <div class="rectangle-4e"></div>
              <span class="first-name-4f">First name</span>
            </div>
            <div class="group-50">
              <span class="last-name">Last name</span>
              <div class="rectangle-51"></div>
              <span class="last-name-52">Last name</span>
            </div>
          </div>
          <div class="group-53">
            <span class="email">Email</span>
            <div class="rectangle-54"></div>
            <span class="example-email">Ex JohnDoe214@gmail.com</span>
          </div>
          <div class="group-55">
            <span class="help-message">What can we help you with ?</span>
            <div class="rectangle-56"></div>
            <span class="message-input">Type here your message</span>
          </div>
        </div>
        <div class="frame-57"><span class="send">Send</span></div>
      </div>
      <div class="ellipse-58"></div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Home",
  data() {
    return {
      path:'/src/assets/images/',
      currentContent: {
        title: "Leads Management",
        description:
            "Organize and track your leads in a centralized system, making it easy to monitor their progress through your sales funnel. Automate follow-ups, prioritize high-value opportunities, and gain insights into your conversion rates to grow your business effectively.",
        cta: "Learn more",
      },
      contentMap: {
        leadsManagement: {
          title: "Leads Management",
          description:
              "Organize and track your leads in a centralized system, making it easy to monitor their progress through your sales funnel. Automate follow-ups, prioritize high-value opportunities, and gain insights into your conversion rates to grow your business effectively.",
          cta: "Learn more",
        },
        publishPosts: {
          title: "Publish Posts",
          description:
              "Simplify your social media management with a streamlined post creation tool. Schedule, publish, and edit posts across all your channels, ensuring your brand stays consistent and your audience engaged.",
          cta: "Learn more",

        },
        createAdCampaign: {
          title: "Create Ad/Campaign",
          description:
              "Easily create and manage high-impact ad campaigns tailored to your audience. Customize your ads with intuitive tools and launch campaigns across multiple platforms for maximum reach.",
          cta: "Learn more",
        },
        adsPerformance: {
          title: "Ads Performance",
          description:
              "Monitor your ads in real-time with detailed performance data. Identify what’s working, optimize underperforming campaigns, and make data-driven decisions to maximize your ROI and reach your marketing goals.",
          cta: "Learn more",
        },
        engagementInsights: {
          title: "Engagements & Insights",
          description:
              "Dive deep into your audience’s interactions with your content. Track likes, shares, comments, and other engagement metrics from all platforms in one place, and analyze insights to refine your strategy and boost results.",
          cta: "Learn more",
        },
        messagesCenter: {
          title: "Messages Center",
          description:
              "Keep all your communications in one place with a unified inbox. Manage customer inquiries, internal messages, and notifications efficiently, ensuring timely responses and seamless communication.",
          cta: "Go to Messages",
        },
        calender: {
          title: "Calender",
          description:
              "Stay on top of your schedule with a fully integrated calendar. Plan and organize your tasks, events, and campaigns in one place, ensuring you never miss a deadline or opportunity to connect with your audience.",
          cta: "Learn more",
        },
        integrations: {
          title: "Integrations",
          description:
              "Seamlessly connect your favorite tools and platforms to create a unified workflow that saves you time and effort. Access insights from your connected platforms, all within a single system. Simplify your processes and ensure that everything you need is just a click away.",
          cta: "Learn more",
        },
      },

    };
  },
  computed: {
    computedBackgroundImage() {
      const imagesMap = {
        'Publish Posts': 'publishPost.png',
        'Leads Management': 'leads.png',
        'Create Ad/Campaign': '2.png',
        'Ads Performance': 'ads_performance.png',
        'Engagements & Insights': 'engagements_insights.png',
        'Messages Center': 'massageCenter.png',
        'Calender': 'calender.png',
        'Integrations': 'integrations.png',
      };
      return `${this.path}${imagesMap[this.currentContent.title] || ''}`;
    },
  },
  methods: {
    changeContent(key) {
      this.activeTab = key; // Set the active tab
      this.currentContent = this.contentMap[key];

    },
  },
}

</script>


<style scoped>
:root {
  --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
  "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
  "Source Han Sans CN", sans-serif;
}

.main-container {
  overflow: hidden;
}

.main-container,
.main-container * {
  box-sizing: border-box;
}

input,
select,
textarea,
button {
  outline: 0;
}

.main-container {
  position: relative;
  width: 100%;
  height: 4491px;
  margin: 0 auto;
  background: #ffffff;
  overflow: hidden;
}
.rectangle {
  position: relative;
  width: 1680px;
  height: 404px;
  margin: 4087px 0 0 0;
  background: #237276;
  z-index: 33;
  overflow: visible auto;
}
.mask-group {
  position: relative;
  width: 190.501px;
  height: 77.799px;
  margin: 53px 0 0 714px;
  z-index: 34;
  overflow: visible auto;
  -webkit-mask-image: url(../../assets/images/17a3cbb6-16e9-4071-9344-dd77d57ad469.png);
  -webkit-mask-size: cover;
}
.group {
  position: relative;
  width: 138.001px;
  height: 29.5px;
  margin: 22.227px 0 0 52.5px;
  z-index: 35;
}
.group-1 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 36;
}
.rectangle-2 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url(../../assets/images/aab32f0b-2984-4231-8451-b35879016e7d.png)
  no-repeat center;
  background-size: 100% 100%;
  z-index: 37;
}
.frame {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-wrap: nowrap;
  gap: 70px;
  position: relative;
  width: 871px;
  margin: 54.5px 0 0 394px;
  z-index: 38;
}
.home {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 22px;
  color: #ffffff;
  font-family: Inter, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 21.784px;
  text-align: left;
  white-space: nowrap;
  z-index: 39;
}
.frame-3 {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 5.833px;
  position: relative;
  width: 74px;
  z-index: 40;
}
.features {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 22px;
  color: #ffffff;
  font-family: Inter, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 21.784px;
  text-align: left;
  white-space: nowrap;
  z-index: 41;
}
.about-us {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 22px;
  color: #ffffff;
  font-family: Inter, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 21.784px;
  text-align: left;
  white-space: nowrap;
  z-index: 42;
}
.pricing {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 22px;
  color: #ffffff;
  font-family: Inter, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 21.784px;
  text-align: left;
  white-space: nowrap;
  z-index: 43;
}
.privacy-policy {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 22px;
  color: #ffffff;
  font-family: Inter, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 21.784px;
  text-align: left;
  white-space: nowrap;
  z-index: 44;
}
.terms-of-service {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 22px;
  color: #ffffff;
  font-family: Inter, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 21.784px;
  text-align: left;
  white-space: nowrap;
  z-index: 45;
}
.flex-row-dcbe {
  position: relative;
  width: 156.288px;
  height: 25.667px;
  margin: 54px 0 0 761.856px;
  z-index: 50;
}
.capa {
  position: absolute;
  width: 25.667px;
  height: 25.667px;
  top: 0;
  left: 64.121px;
  background: url(../../assets/images/35021991-6b3c-4284-b82d-072ce7b8c791.png)
  no-repeat center;
  background-size: 100% 100%;
  z-index: 49;
  overflow: hidden;
}
.instagram {
  position: absolute;
  width: 16.42%;
  height: 100%;
  top: 0;
  left: 83.58%;
  background: url(../../assets/images/3db44cc0-44e3-4baa-9c75-072ffe96ead6.png)
  no-repeat center;
  background-size: 100% 100%;
  z-index: 50;
}
.facebook {
  position: absolute;
  width: 7.41%;
  height: 90.2%;
  top: 4.54%;
  left: 0;
  background: url(../../assets/images/bbedc610-4eca-4503-949c-71ab0e77cc49.png)
  no-repeat center;
  background-size: 100% 100%;
  z-index: 47;
}
.all-rights-reserved {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 157px;
  height: 15px;
  margin: 54px 0 0 761.5px;
  color: #ffffff;
  font-family: Inter, var(--default-font-family);
  font-size: 12px;
  font-weight: 400;
  line-height: 14.523px;
  text-align: center;
  white-space: nowrap;
  z-index: 51;
}
.ellipse {
  position: relative;
  width: 239px;
  height: 239px;
  margin: 164.445px 0 0 1556.18px;
  background: url(../../assets/images/4b8cf0b4-7779-481e-9fce-b2b0b235519f.png)
  no-repeat center;
  background-size: cover;
  filter: blur(200px);
  z-index: 162;
  border-radius: 50%;
}
.design-elements {
  position: relative;
  width: 850.832px;
  height: 700.222px;
  margin: 534.813px 0 0 -433.007px;
  background: url(../../assets/images/147a2cf0-448d-4ad6-a3bf-d9928bbc1725.png)
  no-repeat center;
  background-size: cover;
  z-index: 90;
}
.header {
  position: absolute;
  width: 1958px;
  height: 1363px;
  top: -380px;
  left: 0;
  background: url(../../assets/images/403fc79d-e295-43a1-9c45-66c7016b77b8.png)
  no-repeat center;
  background-size: cover;
  z-index: 5;
}
.rectangle-4 {
  position: absolute;
  width: 464.443px;
  height: 428.454px;
  top: 778.077px;
  left: -408.607px;
  background: url(../../assets/images/f893fdb9-dc3c-4a5e-a87f-837ac5a82ae6.png)
  no-repeat center;
  background-size: cover;
  z-index: 3;
  border-radius: 24px;
}
.component {
  position: absolute;
  width: 1414px;
  height: 610px;
  top: 904px;
  left: 50%;
  background: #ffffff;
  transform: translate(-50.07%, 0);
  z-index: 52;
  border-radius: 12px;
  box-shadow: 0 0 60px 0 rgba(213, 213, 213, 0.5);
}
.frame-5 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 24px;
  position: relative;
  width: 1318px;
  margin: 58.5px 0 0 48px;
  z-index: 53;
}
.introduction-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  min-width: 0;
  height: 132px;
  padding: 24px 0 24px 0;
  z-index: 54;
  overflow: hidden;
  border-radius: 12px;
}
.frame-6 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  z-index: 55;
}
.frame-7 {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  gap: 24px;
  position: relative;
  /*height: 84px;*/
  z-index: 56;
}
.frame-8 {
  flex-shrink: 0;
  position: relative;
  width: 40px;
  height: 40px;
  background: url(../../assets/images/833cd15a-6e6a-4d75-93d1-0aea7130fba9.png)
  no-repeat center;
  background-size: cover;
  z-index: 57;
  overflow: hidden;
}
.leads-management {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 134px;
  height: 17px;
  color: #000;
  font-family: Inter, var(--default-font-family);
  font-size: 14px;
  /*font-weight: 600;*/
  line-height: 16.943px;
  text-align: center;
  white-space: nowrap;
  z-index: 58;
  opacity: 0.5;
}
.frame-9 {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  gap: 24px;
  position: relative;
  /*height: 84px;*/
  z-index: 59;
  cursor: pointer;
}
.frame-7, .frame-9, .frame-a, .frame-b, .frame-c, .frame-e {
  cursor: pointer;
  padding: 10px;
  /*border: 1px solid #ddd;*/
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
}

/*.frame-7:hover, .frame-9:hover, .frame-a:hover, .frame-b:hover, .frame-c:hover, .frame-e:hover {*/
/*  background-color: #f0f0f0;*/
/*}*/

.frame-13 {
  padding: 20px;
  border: 1px solid #ccc;
  margin-top: 20px;
  border-radius: 8px;
}
.active span{
  /*background-color: #999;*/
  color: #4d4d4d;
  font-weight: 600;
  opacity: 1;
  /*border-color: #0056b3;*/
}

.title {
  font-weight: bold;
  font-size: 18px;
  display: block;
  margin-bottom: 10px;
}

.description {
  font-size: 14px;
  margin-bottom: 20px;
}

.cta {
  font-size: 14px;
  color: #007bff;
  cursor: pointer;
}

.save {
  flex-shrink: 0;
  position: relative;
  width: 38.939px;
  height: 40px;
  background: url(../../assets/images/4281fd5d-6b2a-4bee-9e8b-164974cfe201.png)
  no-repeat center;
  background-size: cover;
  z-index: 60;
  overflow: hidden;
}
.publish-posts {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 89px;
  height: 17px;
  color: #000;
  font-family: Inter, var(--default-font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 16.943px;
  text-align: center;
  white-space: nowrap;
  z-index: 61;
  opacity: 0.5;
}
.frame-a {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  gap: 24px;
  position: relative;
  /*height: 84px;*/
  z-index: 62;
}
.line {
  flex-shrink: 0;
  position: relative;
  width: 40px;
  height: 40px;
  background: url(../../assets/images/d86bfebb-0ec5-4157-8bdb-8d660383bbb1.png)
  no-repeat center;
  background-size: cover;
  z-index: 63;
  overflow: hidden;
}
.create-ad-campaign {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 139px;
  height: 17px;
  color: #000;
  font-family: Inter, var(--default-font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 16.943px;
  text-align: center;
  white-space: nowrap;
  z-index: 64;
  opacity: 0.5;
}
.frame-b {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  gap: 24px;
  position: relative;
  /*height: 84px;*/
  z-index: 65;
}
.layer {
  flex-shrink: 0;
  position: relative;
  width: 39.707px;
  height: 40px;
  background: url(../../assets/images/a81614ff-181d-4a3e-800e-ed63280959c8.png)
  no-repeat center;
  background-size: cover;
  z-index: 66;
  overflow: hidden;
}
.ads-performance {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 115px;
  height: 17px;
  color: #000;
  font-family: Inter, var(--default-font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 16.943px;
  text-align: center;
  white-space: nowrap;
  z-index: 67;
  opacity: 0.5;
}
.frame-c {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  gap: 24px;
  position: relative;
  /*height: 84px;*/
  z-index: 68;
}
.layer-d {
  flex-shrink: 0;
  position: relative;
  width: 40.191px;
  height: 40px;
  background: url(../../assets/images/636622d3-438a-458b-bcf3-38a4aa0bba9c.png)
  no-repeat center;
  background-size: cover;
  z-index: 69;
  overflow: hidden;
}
.engagements-insights {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 159px;
  height: 17px;
  color: #000;
  font-family: Inter, var(--default-font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 16.943px;
  text-align: center;
  white-space: nowrap;
  z-index: 70;
  opacity: 0.5;
}
.frame-e {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  gap: 24px;
  position: relative;
  /*height: 84px;*/
  z-index: 71;
}
.layer-f {
  flex-shrink: 0;
  position: relative;
  width: 40px;
  height: 40px;
  background: url(../../assets/images/e6bf8254-e860-4955-acce-7ebdddc9a901.png)
  no-repeat center;
  background-size: cover;
  z-index: 72;
  overflow: hidden;
}
.layer-g{
  flex-shrink: 0;
  position: relative;
  width: 40px;
  height: 40px;
  background: url(../../assets/images/1bc0061a-3b88-4daf-96e4-20bf33e61bdb.png)
  no-repeat center;
  background-size: cover;
  z-index: 72;
  overflow: hidden;
}
.layer-h{
  flex-shrink: 0;
  position: relative;
  width: 40px;
  height: 40px;
  background: url(../../assets/images/0b9a2bb0-2fff-4c26-8506-a0f8ca4717e3.png)
  no-repeat center;
  background-size: cover;
  z-index: 72;
  overflow: hidden;
}

.messages-center {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 114px;
  height: 17px;
  color: #000;
  font-family: Inter, var(--default-font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 16.943px;
  text-align: center;
  white-space: nowrap;
  z-index: 73;
  opacity: 0.5;
}
.frame-10 {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  gap: 24px;
  position: relative;
  height: 84px;
  z-index: 74;
}
.frame-11 {
  flex-shrink: 0;
  position: relative;
  width: 37.307px;
  height: 40px;
  background: url(../../assets/images/1bc0061a-3b88-4daf-96e4-20bf33e61bdb.png)
  no-repeat center;
  background-size: cover;
  z-index: 75;
  overflow: hidden;
}
.calendar {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 60px;
  height: 17px;
  color: #000;
  font-family: Inter, var(--default-font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 16.943px;
  text-align: center;
  white-space: nowrap;
  z-index: 76;
}
.frame-12 {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  gap: 24px;
  position: relative;
  height: 84px;
  z-index: 77;
}
.integrations-icon {
  flex-shrink: 0;
  position: relative;
  width: 40px;
  height: 40px;
  background: url(../../assets/images/0b9a2bb0-2fff-4c26-8506-a0f8ca4717e3.png)
  no-repeat center;
  background-size: cover;
  z-index: 78;
  overflow: hidden;
}
.integrations {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 79px;
  height: 17px;
  color: #000;
  font-family: Inter, var(--default-font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 16.943px;
  text-align: center;
  white-space: nowrap;
  z-index: 79;
}
.frame-13 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  min-width: 0;
  z-index: 80;
}
.frame-14 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 64px;
  position: relative;
  width: 561px;
  z-index: 81;
}
.frame-15 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 24px;
  position: relative;
  width: 561px;
  z-index: 82;
}
.leads-management-16 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 39px;
  color: #000000;
  font-family: Inter, var(--default-font-family);
  font-size: 32px;
  font-weight: 500;
  line-height: 38.727px;
  text-align: left;
  white-space: nowrap;
  z-index: 83;

}
.organize-track-leads {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  width: 561px;
  height: 96px;
  color: #223140;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  z-index: 84;
}
.cta {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: fit-content;
  height: 40px;
  padding: 9px 14px 9px 14px;
  background: #237276;
  z-index: 85;
  border-radius: 8px;
}
.label {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 17px;
  color: #ffffff;
  font-family: Inter, var(--default-font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  white-space: nowrap;
  z-index: 86;
}
.group-17 {
  flex-shrink: 0;
  position: relative;
  width: 520px;
  height: 336.585px;
  /*background: url(../assets/images/6265b615-750a-433e-a6df-1fa6d5b7e262.png) no-repeat center;*/
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 87;
  border-radius: 12px;
}
.ellipse-18 {
  position: absolute;
  width: 259px;
  height: 259px;
  top: 1373.167px;
  left: 1566.833px;
  background: url(../../assets/images/76d40129-bc38-43ac-b235-084272c633be.png)
  no-repeat center;
  background-size: cover;
  filter: blur(233.333px);
  z-index: 4;
  border-radius: 50%;
}
.rectangle-19 {
  position: absolute;
  width: 1446.667px;
  height: 472.5px;
  top: 1596px;
  left: 50%;
  transform: translate(-52.82%, 0);
  z-index: 6;
}
.rectangle-1a {
  position: relative;
  width: 13.307px;
  height: 13.436px;
  margin: 102.216px 0 0 1199.332px;
  background: url(../../assets/images/cf8df56a-e8d6-4dcd-9e4c-322473fc16f5.png)
  no-repeat center;
  background-size: cover;
  z-index: 92;
}
.rectangle-1b {
  position: relative;
  width: 6.969px;
  height: 7.037px;
  margin: 135.822px 0 0 1356.424px;
  background: url(../../assets/images/c4dccb6a-17fd-47e7-a301-5d470afe2f53.png)
  no-repeat center;
  background-size: cover;
  z-index: 95;
}
.rectangle-1c {
  position: relative;
  width: 12.661px;
  height: 12.784px;
  margin: 1.618px 0 0 946.184px;
  background: url(../../assets/images/38fb4035-5b87-4c44-a15e-d96a66ae17d8.png)
  no-repeat center;
  background-size: cover;
  z-index: 94;
}
.design-elements-1d {
  position: absolute;
  width: 850.832px;
  height: 700.223px;
  top: 1632px;
  left: 1123.579px;
  background: url(../../assets/images/9a90c78b-5d9e-42f7-b7f4-da89dd95c409.png)
  no-repeat center;
  background-size: cover;
  z-index: 89;
}
.frame-1e {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 32px;
  position: absolute;
  width: 1414px;
  height: 495.551px;
  top: 1664.044px;
  left: 132px;
  z-index: 97;
}
.upper-cards {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 32px;
  position: relative;
  width: 689.196px;
  z-index: 98;
}
.card {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  position: relative;
  min-width: 0;
  height: 231.776px;
  z-index: 99;
  overflow: hidden;
  border-radius: 9.271px;
}
.background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0%;
  background: url(../../assets/images/b752b0cf-9c61-42d7-907d-6f3bb8f9b219.png)
  no-repeat center;
  background-size: 100% 100%;
  z-index: 100;
}
.frame-1f {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 26.654px;
  position: absolute;
  width: 55px;
  height: 106.654px;
  top: 50%;
  left: 37.083px;
  transform: translate(0, -50.1%);
  z-index: 101;
}
.social-media {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 55px;
  height: 55px;
  padding: 8px 8px 8px 8px;
  background: #f7f7f7;
  z-index: 102;
  border-radius: 8px;
}
.png-clipart-odoo {
  flex-shrink: 0;
  position: relative;
  width: 27px;
  height: 28px;
  background: url(../../assets/images/c6c36035-51b4-4f3a-8ebb-f60fd3fcff76.png)
  no-repeat center;
  background-size: cover;
  z-index: 103;
}
.website-design-development {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 25px;
  color: #999999;
  font-family: Inter, var(--default-font-family);
  font-size: 20.859811782836914px;
  font-weight: 500;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 104;
}
.card-20 {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  position: relative;
  min-width: 0;
  height: 231.776px;
  z-index: 105;
  overflow: hidden;
  border-radius: 12px;
}
.background-21 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0%;
  background: url(../../assets/images/70db09c3-1aa8-4ab4-9e52-95e8b99c644b.png)
  no-repeat center;
  background-size: 100% 100%;
  z-index: 106;
}
.frame-22 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 23.178px;
  position: absolute;
  width: 92px;
  height: 103.178px;
  top: 50%;
  left: 37.086px;
  transform: translate(0, -49.85%);
  z-index: 107;
}
.social-media-23 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 55px;
  height: 55px;
  padding: 10px 10px 10px 10px;
  background: #fff5f3;
  z-index: 108;
  border-radius: 8px;
}
.large-blob {
  flex-shrink: 0;
  position: relative;
  width: 32px;
  height: 32px;
  background: url(../../assets/images/70708f69af70515f63d814b4a003d02e9711a2b6.png)
  no-repeat center;
  background-size: cover;
  z-index: 109;
}
.search-engine-optimization {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 25px;
  color: #999999;
  font-family: Inter, var(--default-font-family);
  font-size: 20.859811782836914px;
  font-weight: 500;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 110;
}
.lower-cards {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 32px;
  position: relative;
  min-width: 0;
  z-index: 111;
}
.card-24 {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  position: relative;
  height: 231.776px;
  z-index: 112;
  overflow: hidden;
  border-radius: 12px;
}
.background-25 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
  background: url(../../assets/images/d8b7c0cf-8d05-49c2-9071-9761e5dc10e7.png)
  no-repeat center;
  background-size: 100% 100%;
  z-index: 113;
}
.frame-26 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 26.654px;
  position: absolute;
  width: 106px;
  height: 106.654px;
  top: 50%;
  left: 37.086px;
  transform: translate(0, -49.89%);
  z-index: 114;
}
.social-media-27 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 55px;
  height: 55px;
  padding: 10px 10px 10px 10px;
  background: #eefbff;
  z-index: 115;
  border-radius: 8px;
}
.salesforce-com-logo {
  flex-shrink: 0;
  position: relative;
  width: 32px;
  height: 22px;
  background: url(../../assets/images/2ae7a565a60096fe9e648de7fe6c60bcbdfa73af.png)
  no-repeat center;
  background-size: cover;
  z-index: 116;
}
.video-editing-production {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 25px;
  color: #999999;
  font-family: Inter, var(--default-font-family);
  font-size: 20.859811782836914px;
  font-weight: 500;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 117;
}
.card-28 {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  position: relative;
  height: 231.776px;
  z-index: 118;
  overflow: hidden;
  border-radius: 12px;
}
.background-29 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
  background: url(../../assets/images/846d8d95-01fa-4d39-859b-ecddce49c323.png)
  no-repeat center;
  background-size: 100% 100%;
  z-index: 119;
}
.frame-2a {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 26.654px;
  position: absolute;
  width: 55px;
  height: 106.654px;
  top: 50%;
  left: 37.086px;
  transform: translate(0, -49.89%);
  z-index: 120;
}
.social-media-2b {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 55px;
  height: 55px;
  padding: 10px 10px 10px 10px;
  background: #f7f7f7;
  z-index: 121;
  border-radius: 8px;
}
.download {
  flex-shrink: 0;
  position: relative;
  width: 31px;
  height: 35px;
  background: url(../../assets/images/0cb4c3c21f0037ea1b1c09c523912f8b45eb6c57.png)
  no-repeat center;
  background-size: cover;
  z-index: 122;
  border-radius: 8px;
}
.content-writing {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 25px;
  color: #999999;
  font-family: Inter, var(--default-font-family);
  font-size: 20.859811782836914px;
  font-weight: 500;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 123;
}
.card-2c {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  position: relative;
  height: 231.776px;
  z-index: 124;
  overflow: hidden;
  border-radius: 12px;
}
.background-2d {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
  background: url(../../assets/images/723c0ea1-a5bd-47b4-a615-555fa3049afc.png)
  no-repeat center;
  background-size: 100% 100%;
  z-index: 125;
}
.frame-2e {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 22.019px;
  position: absolute;
  width: 55px;
  height: 102.019px;
  top: 50%;
  left: 37.086px;
  transform: translate(0, -50.19%);
  z-index: 126;
}
.social-media-2f {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 55px;
  height: 55px;
  padding: 11px 8px 11px 8px;
  background: #eefaff;
  z-index: 127;
  overflow: hidden;
  border-radius: 8px;
}
.page {
  flex-shrink: 0;
  position: relative;
  width: 24px;
  height: 15.924px;
  background: url(../../assets/images/e98b2b88-fa8e-45f7-a56b-522e733bf470.png)
  no-repeat center;
  background-size: 100% 100%;
  z-index: 128;
}
.social-media-marketing {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 25px;
  color: #999999;
  font-family: Inter, var(--default-font-family);
  font-size: 20.859811782836914px;
  font-weight: 500;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 129;
}
.card-30 {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  position: relative;
  height: 231.776px;
  z-index: 130;
  overflow: hidden;
  border-radius: 12px;
}
.background-31 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
  background: url(../../assets/images/779c0fa8-5e40-47bb-b19f-061e4d69c00b.png)
  no-repeat center;
  background-size: 100% 100%;
  z-index: 131;
}
.frame-32 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 27.813px;
  position: absolute;
  width: 86px;
  height: 107.813px;
  top: 50%;
  left: 37.086px;
  transform: translate(0, -50.28%);
  z-index: 132;
}
.social-media-33 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 55px;
  height: 55px;
  padding: 10px 10px 10px 10px;
  background: #fff5f3;
  z-index: 133;
  border-radius: 8px;
}
.pic-10 {
  flex-shrink: 0;
  position: relative;
  width: 24px;
  height: 24px;
  background: url(../../assets/images/1d93e7d289d34d283c06ef30344756bfc1eab35a.png)
  no-repeat center;
  background-size: cover;
  z-index: 134;
}
.pay-per-click {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 25px;
  color: #999999;
  font-family: Inter, var(--default-font-family);
  font-size: 20.859811782836914px;
  font-weight: 500;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 135;
}
.section-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 24px;
  position: absolute;
  /*width: 695px;*/
  height: 144px;
  top: 1707px;
  left: 700px;
  z-index: 136;
}
.our-digital-marketing-expertise {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 48px;
  color: #0d0d0d;
  font-family: Inter, var(--default-font-family);
  font-size: 40px;
  font-weight: 400;
  line-height: 48px;
  text-align: left;
  white-space: nowrap;
  z-index: 137;
}
.connect-your-favorite {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  width: 695px;
  min-width: 0;
  height: 72px;
  color: #223140;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  z-index: 138;
}
.design-elements-34 {
  position: absolute;
  width: 894.391px;
  height: 941.808px;
  top: 1932.25px;
  left: -507.779px;
  background: url(../../assets/images/0db969b4-5a8c-4400-8863-35e716c10f29.png)
  no-repeat center;
  background-size: cover;
}
.rectangle-35 {
  position: absolute;
  width: 11.897px;
  height: 12.013px;
  top: 2133.713px;
  left: 1302.177px;
  background: url(../../assets/images/9a72250b-6274-446b-b1bf-a87ac36db1ed.png)
  no-repeat center;
  background-size: cover;
  z-index: 93;
}
.group-36 {
  position: absolute;
  width: 440px;
  height: 356px;
  top: 2293px;
  left: 1106px;
  background: #ffffff;
  z-index: 145;
}
.frame-37 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 16px;
  position: absolute;
  width: 80px;
  height: 272px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60.29%);
  z-index: 150;
}
.group-38 {
  flex-shrink: 0;
  position: relative;
  width: 80px;
  height: 80px;
  background: url(../../assets/images/48804d0e-058b-412e-864e-c69c2483ee91.png)
  no-repeat center;
  background-size: cover;
  z-index: 151;
}
.frame-39 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  width: 80px;
  z-index: 152;
}
.group-3a {
  flex-shrink: 0;
  position: relative;
  width: 80px;
  height: 80px;
  z-index: 153;
}
.group-3b {
  position: absolute;
  width: 80px;
  height: 80px;
  top: 0;
  left: 0;
  z-index: 154;
}
.frame-3c {
  position: relative;
  width: 38px;
  height: 38px;
  margin: 21px 0 0 21px;
  background: url(../../assets/images/255620b5-46cb-44fe-bf26-b9c0680c7231.png)
  no-repeat center;
  background-size: cover;
  z-index: 156;
  overflow: hidden;
}
.rectangle-3d {
  position: absolute;
  width: 80px;
  height: 80px;
  top: 0;
  left: 0;
  background: #ffffff;
  z-index: 155;
  border-radius: 12.265px;
  box-shadow: 0 10px 60px 0 rgba(222, 222, 222, 0.5);
}
.group-3e {
  flex-shrink: 0;
  position: relative;
  width: 80px;
  height: 80px;
  background: url(../../assets/images/2742cc30-dd87-4337-92a5-6dc60ab24257.png)
  no-repeat center;
  background-size: cover;
  z-index: 157;
}
.frame-3f {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 16px;
  position: absolute;
  width: 18.18%;
  top: 18.62%;
  left: 13.64%;
  z-index: 147;
}
.group-40 {
  flex-shrink: 0;
  position: relative;
  width: 80px;
  height: 80px;
  background: url(../../assets/images/00352220-0324-4f88-80c1-e307848a57f2.png)
  no-repeat center;
  background-size: cover;
  z-index: 148;
}
.group-41 {
  flex-shrink: 0;
  position: relative;
  width: 80px;
  height: 80px;
  background: url(../../assets/images/33ed9c42-acf8-4d69-90fb-8a8eb22bcaaa.png)
  no-repeat center;
  background-size: cover;
  z-index: 149;
}
.frame-42 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 16px;
  position: absolute;
  width: 80px;
  height: 272px;
  top: 50%;
  left: 50%;
  transform: translate(100%, -39.34%);
  z-index: 158;
}
.group-43 {
  flex-shrink: 0;
  position: relative;
  width: 80px;
  height: 80px;
  background: url(../../assets/images/76c6f895-f896-4f5e-b924-e740764bc61f.png)
  no-repeat center;
  background-size: cover;
  z-index: 159;
}
.group-44 {
  flex-shrink: 0;
  position: relative;
  width: 80px;
  height: 80px;
  background: url(../../assets/images/59ad0214-7a8d-4229-be6a-c6118b8feccb.png)
  no-repeat center;
  background-size: cover;
  z-index: 160;
}
.group-45 {
  flex-shrink: 0;
  position: relative;
  width: 80px;
  height: 80px;
  background: url(../../assets/images/5ea109ea-47a1-4efc-b2fb-4fb33c39fa0a.png)
  no-repeat center;
  background-size: cover;
  z-index: 161;
}
.frame-46 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 74.98px;
  position: absolute;
  width: 762.691px;
  height: 301.961px;
  top: 2320px;
  left: 132px;
  background: #ffffff;
  z-index: 139;
}
.frame-47 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 28.118px;
  position: relative;
  min-width: 0;
  z-index: 140;
}
.unified-marketing-platform-management {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  width: 762.691px;
  height: 96px;
  color: #0d0d0d;
  font-family: Inter, var(--default-font-family);
  font-size: 40px;
  font-weight: 400;
  line-height: 48.409px;
  text-align: left;
  z-index: 141;
}
.marketing-platforms {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  width: 762.691px;
  height: 56px;
  color: #666666;
  font-family: Inter, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  z-index: 142;
}
.cta-48 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 11.716px;
  position: relative;
  width: 117.157px;
  height: 46.863px;
  padding: 10.544px 16.402px 10.544px 16.402px;
  background: #237276;
  z-index: 143;
  border-radius: 9.373px;
}
.learn-more {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 20px;
  color: #ffffff;
  font-family: Inter, var(--default-font-family);
  font-size: 16.401960372924805px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  white-space: nowrap;
  z-index: 144;
}
.rectangle-49 {
  position: absolute;
  width: 448.89px;
  height: 417.842px;
  top: 2626.801px;
  left: 1630.823px;
  background: #4f979f;
  transform: rotate(174.81deg);
  z-index: 164;
  border-radius: 24px;
}
.group-4a {
  position: absolute;
  width: 128.63%;
  height: 906px;
  bottom: 684px;
  left: -19.19%;
  background: url(../../assets/images/cc0d241e-d790-498c-b03a-3e732e5cb5fa.png)
  no-repeat center;
  background-size: cover;
  z-index: 8;
}
.flex-column-bf {
  position: absolute;
  width: 1108.335px;
  height: 544.166px;
  bottom: 210.833px;
  left: 586.036px;
  font-size: 0px;
  z-index: 30;
}
.contact-us {
  display: block;
  position: relative;
  height: 39px;
  margin: 0 0 0 0.33px;
  color: #303a42;
  font-family: Inter, var(--default-font-family);
  font-size: 32px;
  font-weight: 500;
  line-height: 38.727px;
  text-align: left;
  white-space: nowrap;
  z-index: 10;
}
.frame-4b {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 48px;
  position: relative;
  width: 1108.333px;
  margin: 47.999px 0 0 0;
  z-index: 12;
}
.group-4c {
  flex-shrink: 0;
  position: relative;
  width: 1108.333px;
  height: 91.001px;
  z-index: 13;
  border-radius: 8px;
}
.group-4d {
  position: absolute;
  width: 506.396px;
  height: 91.001px;
  top: 0;
  left: 0;
  font-size: 0px;
  z-index: 14;
  border-radius: 8px;
}
.first-name {
  display: block;
  position: relative;
  height: 22px;
  margin: 0 0 0 0;
  color: #303a42;
  font-family: Inter, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 21.784px;
  text-align: left;
  white-space: nowrap;
  z-index: 16;
}
.rectangle-4e {
  position: absolute;
  width: 506.394px;
  height: 40px;
  top: 51.001px;
  left: 0px;
  background: #ffffff;
  z-index: 15;
  border-radius: 8px;
}
.first-name-4f {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 19px;
  top: 61.001px;
  left: 24.336px;
  color: rgba(48, 58, 66, 0.5);
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  white-space: nowrap;
  z-index: 17;
}
.group-50 {
  position: absolute;
  width: 546px;
  height: 91.001px;
  top: 0;
  left: 562.333px;
  font-size: 0px;
  z-index: 18;
  border-radius: 8px;
}
.last-name {
  display: block;
  position: relative;
  height: 22px;
  margin: 0 0 0 0;
  color: #303a42;
  font-family: Inter, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 21.784px;
  text-align: left;
  white-space: nowrap;
  z-index: 20;
}
.rectangle-51 {
  position: absolute;
  width: 546px;
  height: 40px;
  top: 51.001px;
  left: 0;
  background: #ffffff;
  z-index: 19;
  border-radius: 8px;
}
.last-name-52 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 19px;
  top: 61.001px;
  left: 24.336px;
  color: rgba(48, 58, 66, 0.5);
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  white-space: nowrap;
  z-index: 21;
}
.group-53 {
  flex-shrink: 0;
  position: relative;
  width: 1108.333px;
  height: 90.835px;
  font-size: 0px;
  z-index: 22;
  border-radius: 8px;
}
.email {
  display: block;
  position: relative;
  height: 22px;
  margin: 0 0 0 0;
  color: #303a42;
  font-family: Inter, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 21.784px;
  text-align: left;
  white-space: nowrap;
  z-index: 24;
}
.rectangle-54 {
  position: absolute;
  width: 1108.333px;
  height: 40px;
  top: 50.835px;
  left: 0;
  background: #ffffff;
  z-index: 23;
  border-radius: 8px;
}
.example-email {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 19px;
  top: 60.835px;
  left: 24.336px;
  color: rgba(48, 58, 66, 0.5);
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  white-space: nowrap;
  z-index: 25;
}
.group-55 {
  flex-shrink: 0;
  position: relative;
  width: 1108.333px;
  height: 90.667px;
  font-size: 0px;
  z-index: 26;
  border-radius: 8px;
}
.help-message {
  display: block;
  position: relative;
  height: 22px;
  margin: 0 0 0 0;
  color: #303a42;
  font-family: Inter, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 21.784px;
  text-align: left;
  white-space: nowrap;
  z-index: 28;
}
.rectangle-56 {
  position: absolute;
  width: 1108.333px;
  height: 40px;
  top: 50.667px;
  left: 0;
  background: #ffffff;
  z-index: 27;
  border-radius: 8px;
}
.message-input {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 19px;
  top: 60.667px;
  left: 23.334px;
  color: rgba(48, 58, 66, 0.5);
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  white-space: nowrap;
  z-index: 29;
}
.frame-57 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 11.667px;
  position: relative;
  width: fit-content;
  height: 40px;
  float: right;
  margin: 10px 0;
  padding: 3px 15.667px ;
  background: #666666;
  z-index: 30;
  border-radius: 8px;
}
.send {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 19px;
  color: #ffffff;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  white-space: nowrap;
  z-index: 31;
}
.ellipse-58 {
  position: absolute;
  width: 200px;
  height: 200px;
  top: 641px;
  left: 266.367px;
  background: url(../../assets/images/3f098eaf-b06e-4840-b23f-259dfe3ad177.png)
  no-repeat center;
  background-size: cover;
  filter: blur(200px);
  z-index: 1;
  border-radius: 50%;
}



</style>
