<template>
  <Layout :linksData="linksData">
    <v-row class="leads-managements w-100">
      <v-col cols="12" :lg="6" class="header-line-title"> leads </v-col>
      <v-col cols="12" :lg="6" class="d-flex align-center justify-end ga-2">
        <Top :leads="leads" />
      </v-col>
    </v-row>
    <FilterSection />
    <div class="table">
      <DataTable :leads="leads" />
    </div>
  </Layout>
</template>
<script>
import Layout from "@/components/crm/layouts/Layout.vue";
import activityLogIcon from "@/assets/images/icons/activity-log.svg";
import discussionsIcon from "@/assets/images/icons/discussions.svg";
import importIcon from "@/assets/images/icons/import.svg";
import FilterSection from "@/components/crm/leads/Filter.vue";
import Top from "@/components/crm/leads/Top.vue";
import DataTable from "@/components/crm/leads/table.vue";
import { useLeadsStore } from "@/store/crm/useLeadsStore";

export default {
  components: {
    Layout,
    FilterSection,
    Top,
    DataTable,
  },

  data() {
    return {
      activityLogIcon,
      discussionsIcon,
      importIcon,
      linksData: [
        { name: "Home", link: "/home-page" },
        { name: "Leads Management", link: "" },
      ],
      store: useLeadsStore(),
    };
  },
  computed: {
    leads() {
      if (this.store?.getLeads.length === 0) {
        this.store?.getAllLeads();
      }
      return this.store?.getLeads;
    },
  },
  mounted() {
    this.headers = this.store.headers;
  },
};
</script>
