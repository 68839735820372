<template>
  <div class="facebook-card">
    <div class="card-header">
      <div class="user-image">
        <img
          src="@/assets/soft-ware-img/90ebce2e94e5b8c5d77012614109a179.jpeg"
          alt="User"
        />
      </div>
      <div class="user-info">
        <p class="user-name">
          Company Name
          <span class="d-flex">
            <img src="@/assets/soft-ware-img/Verified.svg" alt="Verified" />
          </span>
        </p>
        <p class="post-type">
          <span>Sponsored</span>
          <span>.</span>
          <span class="d-flex">
            <img src="@/assets/soft-ware-img/Map.svg" alt="Location" />
          </span>
        </p>
      </div>
    </div>
    <div class="card-body">
      <img
        class="media"
        v-if="
          post.file.file &&
          post.file.file.type &&
          post.file.file.type.startsWith('image/')
        "
        :src="post.file.preview"
        alt="Post Image"
      />
      <video
        v-if="
          post.file.file &&
          post.file.file.type &&
          post.file.file.type.startsWith('video/')
        "
        width="100%"
        :src="post.file.preview"
        autoplay
        muted
        loop
      ></video>
    </div>
    <div class="card-actions">
      <div class="card-actions-left">
        <p>COMPANY-WEBSITE.COM</p>
        <span>Lorem ipsum dolor sit adipiscing elit, sed dot</span>
      </div>
      <div class="card-actions-right">
        <a href="#"> Learn more </a>
      </div>
    </div>
    <div class="card-footer">
      <button>
        <svg
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.6499 9.17596C16.6499 8.68513 16.4071 8.21475 16.032 7.90798C16.2968 7.58076 16.4071 7.17173 16.363 6.76271C16.2747 5.90375 15.4361 5.20841 14.4651 5.20841H11.5961L11.7727 3.94043C11.8168 3.55185 11.8168 3.22463 11.7285 2.89741C11.3975 1.56808 10.1175 0.627319 8.63887 0.627319C8.30783 0.627319 7.9768 0.750027 7.73404 0.974991C7.49128 1.19996 7.35887 1.48627 7.35887 1.79304V3.47005C7.35887 4.2063 7.13818 4.90164 6.71887 5.53563L5.94645 6.6809C5.83611 6.84451 5.68163 6.96722 5.50507 7.04903C5.35059 6.5991 4.90921 6.27188 4.37956 6.27188H1.84163C1.17956 6.27188 0.649902 6.76271 0.649902 7.37625V14.2683C0.649902 14.8819 1.17956 15.3727 1.84163 15.3727H4.4237C5.08576 15.3727 5.61542 14.8819 5.61542 14.2683V14.1047C6.18921 14.5751 6.93956 14.8614 7.75611 14.8614H13.3616C13.8692 14.8614 14.3768 14.6569 14.7299 14.3297C15.083 14.0025 15.2596 13.573 15.2375 13.1231C15.2375 12.9594 15.2154 12.8163 15.1492 12.6527C15.7451 12.3664 16.1423 11.7937 16.1423 11.1393C16.1423 10.9143 16.0982 10.6894 16.0099 10.4848C16.4071 10.1372 16.6499 9.66679 16.6499 9.17596Z"
            fill="#65676B"
          />
        </svg>
        <span>Like</span>
      </button>
      <!-- Add other buttons here -->
    </div>
  </div>
</template>

<script>
import { publishPost } from "@/store/publishPost/publishPost.js";

export default {
  name: "FacebookPostReview",
  data() {
    return {
      store: publishPost(),
      post: {}, // Initialize `post` to avoid undefined errors
    };
  },
  watch: {
    "store.getCarouselSlider": {
      handler() {
        this.post =
          this.store.post["facebook"]?.find((item) => item.type === "post") ||
          {};
        console.log(this.post);
      },
      immediate: true,
    },
  },
};
</script>
<style scoped>
video {
  object-fit: cover;
}
</style>
