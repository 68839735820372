<template>
  <div class="app-meta-store">
    <label for="" class="roboto-bold mb-2 title">Mobile app Store</label>
    <v-select
      v-model="app_store_selected"
      :items="app_store"
      class="my-2"
      density="compact"
      variant="outlined"
      return-object
      item-value="value"
      item-title="title"
      hide-details
    >
      <template v-slot:selection="{ item }">
        {{ item?.title || "" }}
      </template>
      <template v-slot:item="{ item, index }">
        <v-list-item @click="selectItemWinner(item)">
          <div class="my-form">
            <div class="ga-3">
              <input
                :checked="
                  app_store_selected && app_store_selected.id === item.raw.id
                "
                :id="item.raw.id"
                type="radio"
                :name="`performance_goal_${item.raw.id}`"
                :value="item.raw.value"
              />
              <div class="d-flex flex-column ga-1">
                <label
                  :for="`${item.raw.id}+2`"
                  class="d-block"
                  style="font-size: 14px"
                  >{{ item.raw.title }}</label
                >
              </div>
            </div>
          </div>
        </v-list-item>
      </template>
    </v-select>
    <label for="" class="roboto-bold mb-2 title" >App</label>
    <v-autocomplete
      clearable
      density="compact"
      hide-details
      :items="[
        'California',
        'Colorado',
        'Florida',
        'Georgia',
        'Texas',
        'Wyoming',
      ]"
      variant="outlined"
    ></v-autocomplete>
  </div>
</template>

<script>
export default {
  data() {
    return {
      app_store_selected: {
        id: "m-1",
        title: "Google play",
        value: "GOOGLE_PLAY",
      },
      app_store: [
        {
          id: "m-1",
          title: "Google play",
          value: "GOOGLE_PLAY",
        },
        {
          id: "m-2",
          title: "App Store",
          value: "APP_STORE",
        },
        {
          id: "m-3",
          title: "App store for iPad",
          value: "APP_STORE_IPAD",
        },
        {
          id: "m-4",
          title: "Windows store",
          value: "WINDOWS_STORE",
        },
        {
          id: "m-5",
          title: "Fcabook Canvas",
          value: "Facebook_Canvas",
        },
        {
          id: "m-6",
          title: "Amazon Appstore",
          value: "AMAZON_APPSTORE",
        },
        {
          id: "m-7",
          title: "Games",
          value: "GAMES",
        },
        {
          id: "m-8",
          title: "Meta Quest App Store",
          value: "META_QUEST_APP_STORE",
        },
      ],
    };
  },
  methods: {
    selectItemWinner(item) {
      this.app_store_selected = item.raw;
      localStorage.setItem("app_store_type", item.raw.value);
    },
  },
};
</script>
