<template>
  <div
    class="leads-managements-link d-flex align-center justify-space-between px-3 py-2 mt-3"
  >
    <div class="link cursor-pointer hoverd-style-line d-flex align-center ga-2">
      <span>Latest Images</span>
    </div>
    <div
      class="see-all cursor-pointer"
      @click="$router.push({ name: 'DriveCenterAllImages' })"
    >
      See All
    </div>
  </div>

  <v-row class="files">
    <v-col
      cols="6"
      :lg="2"
      :md="4"
      v-for="(file, index) in store.getImages.slice(0, 6)"
      :key="index"
    >
      <v-sheet class="ma-2 pa-4 file-box d-flex flex-column ga-4">
        <ImageBox :file="file" @edit:file="toggleEditFunction" />
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import { DriveStore } from "@/store/drive/DriveStore";
import ImageBox from "@/components/drive/boxs/ImageBox.vue";
export default {
  name: "LatestImages",
  props: {},
  components: { ImageBox },

  data() {
    return {
      store: null,
    };
  },
  watch: {},
  computed: {},

  methods: {
    formatDate(date) {
      return format(new Date(date), "MMM dd, yyyy HH:mm");
    },
    toggleEditFunction(v) {
      this.$emit("edit:file", v);
    },
  },

  created() {
    this.store = DriveStore();
  },
  mounted() {},
};
</script>
