<template>
  <div class="toggle-container">
    <div class="toggle-switch">
      <label class="switch">
        <input
          type="checkbox"
          checked="checked"
          :disabled="disabled"
          v-model="statusType"
          @change="handleToggleChange"
        />
        <span class="slider" :class="disabled ? 'disabled-input' : 'slider'">
          <svg
            width="12"
            height="12"
            viewBox="0 0 16 16"
            fill="#FFFFFF"
            xmlns="http://www.w3.org/2000/svg"
            class="sds-icon sds-icon-check"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6 10.9394L12.803 4.13635L13.8637 5.19701L6 13.0607L2.13634 9.19701L3.197 8.13635L6 10.9394Z"
            ></path>
          </svg>
          <svg
            width="12"
            height="12"
            viewBox="0 0 16 16"
            fill="#FFFFFF"
            xmlns="http://www.w3.org/2000/svg"
            class="sds-icon sds-icon-cross"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8 9.8535L11.6239 13.4774L12.8614 12.24L9.23744 8.61606L12.8614 4.99214L11.6239 3.7547L8 7.37862L4.37609 3.75471L3.13865 4.99215L6.76256 8.61606L3.13864 12.24L4.37608 13.4774L8 9.8535Z"
            ></path>
          </svg>
        </span>
      </label>
      <p v-if="status_print">
        <span class="status-text" v-if="statusType"> {{ $t("active") }} </span>
        <span class="status-text" v-else-if="disabled">
          {{ $t("buildAdsManually") }}
        </span>
        <span class="status-text" v-else-if="paused"> Paused </span>
        <span class="status-text" v-else-if="notAttached">Not Attached</span>
        <span class="status-text" v-else> {{ $t("Disable") }} </span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    checked: {
      type: Boolean,
      // required: true,
    },
    disabled: {
      // type: Boolean,
    },
    paused: {
      // type: Boolean,
      // required: true,
    },
    notAttached: {
      // type: Boolean,
      // required: true,
    },
    is_status_print: {
      // type: Boolean,
      // required: false,
      default: true,
    },
  },
  data() {
    return {
      statusType: this.checked,
      status_print: this.is_status_print,
    };
  },
  watch: {
    checked(newValue) {
      this.statusType = newValue;
    },
  },
  methods: {
    handleToggleChange() {
      this.$emit("update:checked", this.statusType);
      this.togglepaused(), this.toogleSection();
      this.toggleAttached();
      this.showAlertCard();
    },
    toogleSection() {
      this.$emit("tooglePromotionOptions");
    },
    togglepaused() {
      this.$emit("tooglePaused");
    },
    toggleAttached() {
      this.$emit("toggleAttached");
    },
    showAlertCard() {
      this.$emit("toggleAlertForApp");
    },
  },
};
</script>

<style scoped>
.toggle-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.toggle-switch {
  display: flex;
  align-items: center;
  gap: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(84, 85, 87);
  transition: 0.4s;
  border-radius: 24px;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.slider:hover {
  border: 1px solid rgb(247, 217, 1);
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #4caf50;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.disabled {
  background-color: rgb(84, 85, 87);
}
.status-text {
  font-size: 12px;
  line-height: normal;
}
.disabled-input {
  background-color: rgb(195, 197, 199);
  cursor: no-drop;
}
</style>
