<template>
  <div class="placement-card optimize-location-card">
    <div class="card-header">
      <p class="card-title mb-0">Optimize locations</p>
      <span class="description"
        >Select where you'd like to direct traffic.
      </span>
    </div>
    <div class="card-body mt-8">
      <!-- Start Time Only Option -->
      <div class="radio-buttons-container">
        <div class="radio-button">
          <input
            type="radio"
            id="locationTypeWebsite"
            value="website"
            v-model="locationType"
            class="radio-button__input"
            name="locationType"
            @change="optimizeLocationStatus"
          />
          <label for="locationTypeWebsite" class="radio-button__label">
            <span class="radio-button__custom"></span>
            <div>
              <span class="ma-0">Website</span>
              <p>Send traffic to your website</p>
            </div>
          </label>
        </div>

        <div class="radio-button mt-4" v-if="!ledGenerationType">
          <input
            @change="optimizeLocationStatus"
            type="radio"
            id="locationTypeApp"
            value="app"
            v-model="locationType"
            class="radio-button__input"
            name="locationType"
          />
          <label for="locationTypeApp" class="radio-button__label">
            <span class="radio-button__custom"></span>
            <div>
              <span class="ma-0">App</span>
              <p>Send traffic to your App</p>
            </div>
          </label>
        </div>

        <div class="radio-button mt-4" v-if="ledGenerationType">
          <input
            @change="optimizeLocationStatus"
            type="radio"
            id="INSTANTFORM"
            value="INSTANTFORM"
            v-model="locationType"
            class="radio-button__input"
            name="locationType"
          />
          <label for="INSTANTFORM" class="radio-button__label">
            <span class="radio-button__custom"></span>
            <div>
              <span class="ma-0">Instant form</span>
              <p>Collect leads through an instant form</p>
            </div>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      locationType: "website",
      ledGenerationType: false,
    };
  },
  mounted() {
    if (localStorage.getItem("location_type")) {
      this.locationType = localStorage.getItem("location_type");
    }

    const selectedObject = localStorage.getItem("selected_objective");
    if (selectedObject === "LEAD_GENERATION") {
      this.ledGenerationType = true;
    }
  },
  methods: {
    optimizeLocationStatus() {
      localStorage.setItem("location_type", this.locationType);
    },
  },
};
</script>
