<template>
  <div class="card-pattern meta-advantage-audience">
    <div class="card-header mb-4">
      <label for="">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#007e59"
        >
          <path
            d="m423.23-309.85 268.92-268.92L650-620.92 423.23-394.15l-114-114L267.08-466l156.15 156.15ZM480.07-100q-78.84 0-148.21-29.92t-120.68-81.21q-51.31-51.29-81.25-120.63Q100-401.1 100-479.93q0-78.84 29.92-148.21t81.21-120.68q51.29-51.31 120.63-81.25Q401.1-860 479.93-860q78.84 0 148.21 29.92t120.68 81.21q51.31 51.29 81.25 120.63Q860-558.9 860-480.07q0 78.84-29.92 148.21t-81.21 120.68q-51.29 51.31-120.63 81.25Q558.9-100 480.07-100Zm-.07-60q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
          />
        </svg>
        <span class="roboto-black">Advantage+ audience </span>
      </label>
    </div>

    <div class="card-body">
      <p class="roboto-regular mb-3 description-text">
        Our ad technology automatically finds your audience. If you share an
        audience suggestion, we'll prioritise audiences matching this profile
        before searching more widely. Learn more
      </p>

      <button
        class="audience-suggestion"
        @click="advancedAudience()"
        v-if="!advancedSettings"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="20px"
          viewBox="0 -960 960 960"
          width="20px"
          fill="#5f6368"
        >
          <path
            d="M444-288h72v-156h156v-72H516v-156h-72v156H288v72h156v156Zm36.28 192Q401-96 331-126t-122.5-82.5Q156-261 126-330.96t-30-149.5Q96-560 126-629.5q30-69.5 82.5-122T330.96-834q69.96-30 149.5-30t149.04 30q69.5 30 122 82.5T834-629.28q30 69.73 30 149Q864-401 834-331t-82.5 122.5Q699-156 629.28-126q-69.73 30-149 30Zm-.28-72q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z"
          />
        </svg>
        <span>Audience suggestion (optional)</span>
      </button>

      <div class="custom-audiences mb-3" v-if="advancedSettings">
        <p class="roboto-medium mb-2">Custom Audiences</p>
        <div class="search-input">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="20px"
            viewBox="0 -960 960 960"
            width="20px"
            fill="#5f6368"
          >
            <path
              d="M765-144 526-383q-30 22-65.79 34.5-35.79 12.5-76.18 12.5Q284-336 214-406t-70-170q0-100 70-170t170-70q100 0 170 70t70 170.03q0 40.39-12.5 76.18Q599-464 577-434l239 239-51 51ZM384-408q70 0 119-49t49-119q0-70-49-119t-119-49q-70 0-119 49t-49 119q0 70 49 119t119 49Z"
            />
          </svg>
          <input
            type="text"
            placeholder="Search exiting audience"
            name=""
            id=""
          />
        </div>
      </div>
      <div class="age mb-3" v-if="advancedSettings">
        <div class="age-header d-flex align-center justify-space-between">
          <p class="roboto-bold mb-1">Age</p>
          <button v-if="!editAge" @click="toggleSelectedAge">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="20px"
              viewBox="0 -960 960 960"
              width="20px"
              fill="#0a7abf"
            >
              <path
                d="M216-216h51l375-375-51-51-375 375v51Zm-72 72v-153l498-498q11-11 23.84-16 12.83-5 27-5 14.16 0 27.16 5t24 16l51 51q11 11 16 24t5 26.54q0 14.45-5.02 27.54T795-642L297-144H144Zm600-549-51-51 51 51Zm-127.95 76.95L591-642l51 51-25.95-25.05Z"
              />
            </svg>
            <span>Edit</span>
          </button>
        </div>
        <div class="age-body">
          <p class="roboto-regular" v-if="!editAge">18- 65+</p>
          <div class="select-age-range" v-else>
            <v-container class="pa-0">
              <v-row no-gutters class="pa-0">
                <v-col cols="3" class="start-age pa-0">
                  <v-select
                    v-model="selectedAgeStartRange"
                    :items="startAgeRangeItems"
                    density="compact"
                    variant="outlined"
                    return-object
                    item-value="value"
                    item-title="title"
                    hide-details
                  >
                    <template v-slot:selection="{ item }">
                      {{ item?.title || "" }}
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item @click="setBudgetType(item)">
                        <div class="my-form">
                          <div class="ga-3">
                            <input
                              :checked="
                                selectedAgeStartRange &&
                                selectedAgeStartRange.title === item.raw.title
                              "
                              :id="item.raw.id"
                              type="radio"
                              name="set_min_age"
                              :value="item"
                            />
                            <div class="d-flex flex-column ga-1">
                              <label
                                :for="item.raw.id"
                                class="d-block"
                                style="font-size: 14px"
                                >{{ item.title }}</label
                              >
                            </div>
                          </div>
                        </div>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="3" class="end-age pa-0">
                  <v-select
                    v-model="selectedAgeEndRange"
                    :items="startAgeRangeItems"
                    density="compact"
                    variant="outlined"
                    return-object
                    item-value="value"
                    item-title="title"
                    hide-details
                  >
                    <template v-slot:selection="{ item }">
                      {{ item?.title || "" }}
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item @click="setBudgetTypeEnd(item)">
                        <div class="my-form">
                          <div class="ga-3">
                            <input
                              :checked="
                                selectedAgeEndRange &&
                                selectedAgeEndRange.title === item.raw.title
                              "
                              :id="item.raw.id"
                              type="radio"
                              name="set_max_age"
                              :value="item"
                            />
                            <div class="d-flex flex-column ga-1">
                              <label
                                :for="item.raw.id"
                                class="d-block"
                                style="font-size: 14px"
                                >{{ item.title }}</label
                              >
                            </div>
                          </div>
                        </div>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </div>
      </div>
      <div class="gender age" v-if="advancedSettings">
        <div class="gender-header d-flex align-center justify-space-between">
          <p class="roboto-bold mb-1">Gender</p>
          <button @click="toggleSelectedGender" v-if="!selectedGenderStatus">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="20px"
              viewBox="0 -960 960 960"
              width="20px"
              fill="#0a7abf"
            >
              <path
                d="M216-216h51l375-375-51-51-375 375v51Zm-72 72v-153l498-498q11-11 23.84-16 12.83-5 27-5 14.16 0 27.16 5t24 16l51 51q11 11 16 24t5 26.54q0 14.45-5.02 27.54T795-642L297-144H144Zm600-549-51-51 51 51Zm-127.95 76.95L591-642l51 51-25.95-25.05Z"
              />
            </svg>
            <span>Edit</span>
          </button>
        </div>
        <div class="gender-body">
          <p class="roboto-regular" v-if="!selectedGenderStatus">All gender</p>
          <div class="select-gender-type d-flex align-center" v-else>
            <div
              class="my-form"
              v-for="select in genderSelect"
              :key="select.id"
            >
              <div class="ga-3">
                <input
                  :v-model="select.model"
                  :id="select.id"
                  type="radio"
                  name="select_gender"
                  :value="select.value"
                  :checked="select.checked"
                />
                <div class="d-flex flex-column ga-1">
                  <label
                    @click="setValueStorage(select.value)"
                    :for="select.id"
                    class="d-block"
                    style="font-size: 14px"
                  >
                    {{ select.value }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="detailed-targeting age" v-if="advancedSettings">
        <div
          class="detailed-targeting-header d-flex align-center justify-space-between"
        >
          <p class="roboto-bold mb-1">Detailed targeting</p>
          <button @click="toggleTargeting" v-if="!targetingStatus">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="20px"
              viewBox="0 -960 960 960"
              width="20px"
              fill="#0a7abf"
            >
              <path
                d="M216-216h51l375-375-51-51-375 375v51Zm-72 72v-153l498-498q11-11 23.84-16 12.83-5 27-5 14.16 0 27.16 5t24 16l51 51q11 11 16 24t5 26.54q0 14.45-5.02 27.54T795-642L297-144H144Zm600-549-51-51 51 51Zm-127.95 76.95L591-642l51 51-25.95-25.05Z"
              />
            </svg>
            <span>Edit</span>
          </button>
        </div>
        <div class="detailed-targeting-body">
          <p class="roboto-regular" v-if="!targetingStatus">
            All demographics, interests and behaviours
          </p>
          <div class="people-matching" v-else>
            <p>Include people who match</p>
            <div class="include-search">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="20px"
                viewBox="0 -960 960 960"
                width="20px"
                fill="#261E47"
              >
                <path
                  d="M765-144 526-383q-30 22-65.79 34.5-35.79 12.5-76.18 12.5Q284-336 214-406t-70-170q0-100 70-170t170-70q100 0 170 70t70 170.03q0 40.39-12.5 76.18Q599-464 577-434l239 239-51 51ZM384-408q70 0 119-49t49-119q0-70-49-119t-119-49q-70 0-119 49t-49 119q0 70 49 119t119 49Z"
                />
              </svg>
              <v-autocomplete
                placeholder="Add demographics, interests or behaviours"
                :items="inserts_and_behaviours"
                variant="outlined"
                item-title="name"
                item-value="id"
                return-object
                hide-details
                density="compact"
                v-model="inserts_and_behaviours_selected"
                multiple
              >
                <template v-slot:item="{ item }">
                  <div class="d-flex flex-column ga-1">
                    <v-list-item @click="setInsertsAndBehaviours(item)">
                      <v-list-item-title class="d-flex flex-column align-start ga-1">
                        <span style="font-size: 14px;color: #1c2b33;">{{ item.raw.name }}</span>
                        <p style="font-size: 12px; color: #261e47 ">
                          <span>{{ item.raw.audience_size_lower_bound }} </span>
                          -
                          <span>{{ item.raw.audience_size_upper_bound }}</span>
                        </p>
                      </v-list-item-title>
                    </v-list-item>
                  </div>
                </template>
              </v-autocomplete>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { advancedData } from "@/store/meta/advancedData";
import { mapState } from "pinia";
import apiClient from "@/core/buildRequest";

export default {
  data() {
    return {
      advancedSettings: false,
      selectedAgeStartRange: { id: 0, title: 18, value: 18 },
      selectedAgeEndRange: { id: 47, title: 65, value: 65 },
      editAge: false,
      selectedGenderStatus: false,
      genderSelect: [
        {
          id: 0,
          model: "all_gender",
          value: "All gender",
          checked: true,
        },
        {
          id: 1,
          model: "Men",
          value: "Men",
          checked: false,
        },
        {
          id: 2,
          model: "Women",
          value: "Women",
          checked: false,
        },
      ],
      targetingStatus: false,
      inserts_and_behaviours: [],
      inserts_and_behaviours_selected: [],
    };
  },
  mounted() {
    localStorage.setItem("meta_gender", "all_gender");
    apiClient.get("/campaign-ads/facebook/interests").then((response) => {
      this.inserts_and_behaviours = response.data.data;
      
    });
  },
  computed: {
    ...mapState(advancedData, ["startAgeRangeItems"]),
  },
  watch: {},
  methods: {
    advancedAudience() {
      this.advancedSettings = !this.advancedSettings;
    },
    setBudgetType(item) {
      this.selectedAgeStartRange = item.raw;
      localStorage.setItem("set_start_date", item.raw.value);
    },
    setBudgetTypeEnd(item) {
      this.selectedAgeEndRange = item.raw;
      localStorage.setItem("set_end_date", item.raw.value);
    },
    toggleSelectedAge() {
      this.editAge = !this.editAge;
    },
    setValueStorage(value) {
      localStorage.setItem("meta_gender", value);
    },
    toggleSelectedGender() {
      this.selectedGenderStatus = !this.selectedGenderStatus;
    },
    toggleTargeting() {
      this.targetingStatus = !this.targetingStatus;
    },
    setInsertsAndBehaviours(item) {
      console.log("Updated Selection:", item);
      this.inserts_and_behaviours_selected = item;
      localStorage.setItem('inserts_and_behaviours_selected', JSON.stringify(this.inserts_and_behaviours_selected.id));
    },
  },
};
</script>
