<template>
  <div class="card-pattern dynamic-creative-card">
    <div class="card-header mb-2 d-flex align-center justify-space-between">
      <label for="" class="">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#007e59"
        >
          <path
            d="m423.23-309.85 268.92-268.92L650-620.92 423.23-394.15l-114-114L267.08-466l156.15 156.15ZM480.07-100q-78.84 0-148.21-29.92t-120.68-81.21q-51.31-51.29-81.25-120.63Q100-401.1 100-479.93q0-78.84 29.92-148.21t81.21-120.68q51.29-51.31 120.63-81.25Q401.1-860 479.93-860q78.84 0 148.21 29.92t120.68 81.21q51.31 51.29 81.25 120.63Q860-558.9 860-480.07q0 78.84-29.92 148.21t-81.21 120.68q-51.29 51.31-120.63 81.25Q558.9-100 480.07-100Zm-.07-60q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
          />
        </svg>
        <span class="roboto-black">Dynamic creative </span>
      </label>
      <div class="d-flex align-center ga-2">
        <span class="roboto-light" v-if="!dynamic_creative_status">Off</span>
        <span class="roboto-light" v-else>On</span>
        <label class="toggle-switch">
          <input type="checkbox" v-model="dynamic_creative_status"  />
          <div class="toggle-switch-background">
            <div class="toggle-switch-handle"></div>
          </div>
        </label>
      </div>
    </div>
    <div class="card-body px-1">
      <p class="roboto-regular description-text">
        Provide creative elements, such as images and headlines, and we'll
        automatically generate combinations optimised for your audience.
        Variations may include different formats, templates or audio based on
        one or more elements.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dynamic_creative_status: false,
    };
  },
  mounted() {
    localStorage.setItem(
      "dynamic_creative_status",
      this.dynamic_creative_status
    );
  },
  watch: {
    dynamic_creative_status() {
      localStorage.setItem(
        "dynamic_creative_status",
        this.dynamic_creative_status
      );
    },
  },
};
</script>
