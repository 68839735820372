<template>
  <div class="leads-management-filter">
    <v-row class="ma-0 pa-0">
      <v-col cols="12" class="d-flex ga-3">
        <Input
          v-model="search"
          type="search"
          :icon="SearchIcon"
          placeholder="Search here..."
          @input="handleInput"
        />
        <AdvancedFilter />
        <GroupBy />
      </v-col>
      <v-col cols="12" :lg="6" class="d-flex ga-2 align-center justify-start">
        <v-menu v-if="menu.length > 0" transition="slide-y-transition">
          <template v-slot:activator="{ props }">
            <v-btn
              :disabled="selected_rows.length === 0"
              class="create-menu-btn d-flex align-center ga-2"
              variant="text"
              v-bind="props"
            >
              <span class="fs-14">Create</span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 6L8 10L12 6"
                  stroke="#237276"
                  stroke-width="1.6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </v-btn>
          </template>

          <v-list class="create-list">
            <v-list-item v-for="(item, i) in menu" :key="i">
              <v-list-item-title @click="createItem(item)"
                ><span class="fs-14">{{ item }}</span></v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu v-if="assign_to_account_menu" transition="slide-x-transition">
          <template v-slot:activator="{ props }">
            <v-btn
              class="create-menu-btn assign add-to-account-list"
              variant="text"
              v-bind="props"
            >
              <div class="fs-14 d-flex align-center ga-2">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.25435 2.18182V15.2727H1.16344C0.962612 15.2727 0.799805 15.4356 0.799805 15.6364C0.799805 15.8372 0.962612 16 1.16344 16H16.4362C16.637 16 16.7998 15.8372 16.7998 15.6364C16.7998 15.4356 16.637 15.2727 16.4362 15.2727H15.3453V2.18182C15.3453 0.976836 14.3685 0 13.1634 0H4.43617C3.23119 0 2.25435 0.976836 2.25435 2.18182ZM14.618 15.2727V2.18182C14.618 1.37849 13.9668 0.727273 13.1634 0.727273H4.43617C3.63284 0.727273 2.98162 1.37849 2.98162 2.18182V15.2727H5.89071V11.6364C5.89071 10.4313 6.86755 9.45455 8.07253 9.45455H9.52708C10.7321 9.45455 11.7089 10.4313 11.7089 11.6364V15.2727H14.618ZM10.9816 15.2727V11.6364C10.9816 10.833 10.3304 10.1818 9.52708 10.1818H8.07253C7.26921 10.1818 6.61799 10.833 6.61799 11.6364V15.2727H10.9816ZM5.16344 4C5.16344 3.79917 5.32625 3.63636 5.52708 3.63636H7.7089C7.9097 3.63636 8.07253 3.79917 8.07253 4C8.07253 4.20083 7.9097 4.36364 7.7089 4.36364H5.52708C5.32625 4.36364 5.16344 4.20083 5.16344 4ZM5.52708 6.54545C5.32625 6.54545 5.16344 6.70829 5.16344 6.90909C5.16344 7.10989 5.32625 7.27273 5.52708 7.27273H7.7089C7.9097 7.27273 8.07253 7.10989 8.07253 6.90909C8.07253 6.70829 7.9097 6.54545 7.7089 6.54545H5.52708ZM9.52708 4C9.52708 3.79917 9.68991 3.63636 9.89071 3.63636H12.0725C12.2733 3.63636 12.4362 3.79917 12.4362 4C12.4362 4.20083 12.2733 4.36364 12.0725 4.36364H9.89071C9.68991 4.36364 9.52708 4.20083 9.52708 4ZM9.89071 6.54545C9.68991 6.54545 9.52708 6.70829 9.52708 6.90909C9.52708 7.10989 9.68991 7.27273 9.89071 7.27273H12.0725C12.2733 7.27273 12.4362 7.10989 12.4362 6.90909C12.4362 6.70829 12.2733 6.54545 12.0725 6.54545H9.89071Z"
                    fill="#4D4D4D"
                  />
                </svg>

                <span>Assign to Account</span>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 6L8 10L12 6"
                    stroke="#666666"
                    stroke-width="1.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </v-btn>
          </template>

          <v-list class="create-list fs-14">
            <v-list-item
              v-for="(item, i) in [
                'Assign To New Account',
                'Assign To Existed Account',
              ]"
              :key="i"
            >
              <v-list-item-title @click="assignToAccount(item)">{{
                item
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col cols="12" :lg="6" class="d-flex ga-2 align-center justify-end">
        <button
          @click="OpenNewDialog(parent)"
          class="d-flex align-center ga-3 dashoard-btn"
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.33301 8.66665H3.33301V7.33331H7.33301V3.33331H8.66634V7.33331H12.6663V8.66665H8.66634V12.6666H7.33301V8.66665Z"
              fill="white"
            />
          </svg>

          <span class="fs-14 text-capitalize">Add New {{ parent }}</span>
        </button>
        <button
          @click="$router.push({ name: dashboard_path_name })"
          class="d-flex align-center ga-3 dashoard-btn"
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.666 14.6668H10.666C9.53268 14.6668 8.66602 13.8002 8.66602 12.6668V3.3335C8.66602 2.20016 9.53268 1.3335 10.666 1.3335H12.666C13.7993 1.3335 14.666 2.20016 14.666 3.3335V12.6668C14.666 13.8002 13.7993 14.6668 12.666 14.6668ZM10.666 2.66683C10.266 2.66683 9.99935 2.9335 9.99935 3.3335V12.6668C9.99935 13.0668 10.266 13.3335 10.666 13.3335H12.666C13.066 13.3335 13.3327 13.0668 13.3327 12.6668V3.3335C13.3327 2.9335 13.066 2.66683 12.666 2.66683H10.666Z"
              fill="white"
            />
            <path
              d="M5.33398 14.6665H3.33398C2.20065 14.6665 1.33398 13.7998 1.33398 12.6665V8.6665C1.33398 7.53317 2.20065 6.6665 3.33398 6.6665H5.33398C6.46732 6.6665 7.33398 7.53317 7.33398 8.6665V12.6665C7.33398 13.7998 6.46732 14.6665 5.33398 14.6665ZM3.33398 7.99984C2.93398 7.99984 2.66732 8.2665 2.66732 8.6665V12.6665C2.66732 13.0665 2.93398 13.3332 3.33398 13.3332H5.33398C5.73398 13.3332 6.00065 13.0665 6.00065 12.6665V8.6665C6.00065 8.2665 5.73398 7.99984 5.33398 7.99984H3.33398Z"
              fill="white"
            />
            <path
              d="M5.33398 5.3335H3.33398C2.20065 5.3335 1.33398 4.46683 1.33398 3.3335C1.33398 2.20016 2.20065 1.3335 3.33398 1.3335H5.33398C6.46732 1.3335 7.33398 2.20016 7.33398 3.3335C7.33398 4.46683 6.46732 5.3335 5.33398 5.3335ZM3.33398 2.66683C2.93398 2.66683 2.66732 2.9335 2.66732 3.3335C2.66732 3.7335 2.93398 4.00016 3.33398 4.00016H5.33398C5.73398 4.00016 6.00065 3.7335 6.00065 3.3335C6.00065 2.9335 5.73398 2.66683 5.33398 2.66683H3.33398Z"
              fill="white"
            />
          </svg>
          <span>Dashboard</span>
        </button>
      </v-col>
      <v-col
        v-if="hidden_headers.length > 0"
        cols="12"
        class="d-flex ga-2 align-center justify-end filed-autocomplete"
      >
        <div>
          <v-btn
            id="show-hidden-columns"
            variant="text"
            class="show-hidden-columns pa-0"
          >
            <div class="fs-14 d-flex align-center ga-2">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_3141_74103)">
                  <path
                    d="M11.9603 11.96C10.8207 12.8286 9.43306 13.3099 8.00033 13.3333C3.33366 13.3333 0.666992 7.99996 0.666992 7.99996C1.49625 6.45456 2.64642 5.10436 4.04033 4.03996M6.60032 2.82663C7.05921 2.71921 7.52903 2.66552 8.00033 2.66663C12.667 2.66663 15.3337 7.99996 15.3337 7.99996C14.929 8.75703 14.4464 9.46978 13.8937 10.1266M9.41366 9.41329C9.23056 9.60979 9.00976 9.7674 8.76443 9.87671C8.51909 9.98602 8.25426 10.0448 7.98572 10.0495C7.71718 10.0543 7.45043 10.0049 7.2014 9.90429C6.95236 9.8037 6.72614 9.65398 6.53622 9.46406C6.34631 9.27414 6.19659 9.04792 6.096 8.79889C5.99541 8.54985 5.94601 8.28311 5.95075 8.01457C5.95549 7.74602 6.01426 7.48119 6.12358 7.23586C6.23289 6.99053 6.39049 6.76972 6.58699 6.58663M0.666992 0.666626L15.3337 15.3333"
                    stroke="#666666"
                    stroke-width="1.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3141_74103">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span>Show hidden columns</span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 6L8 10L12 6"
                  stroke="#666666"
                  stroke-width="1.6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </v-btn>
          <v-menu activator="#show-hidden-columns">
            <v-list>
              <v-list-item
                v-for="(item, index) in hidden_headers"
                :key="index"
                :value="index"
              >
                <v-list-item-title
                  class="d-flex align-center ga-2"
                  @click="showHiddenColumn(item.key)"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3141_74103)">
                      <path
                        d="M11.9603 11.96C10.8207 12.8286 9.43306 13.3099 8.00033 13.3333C3.33366 13.3333 0.666992 7.99996 0.666992 7.99996C1.49625 6.45456 2.64642 5.10436 4.04033 4.03996M6.60032 2.82663C7.05921 2.71921 7.52903 2.66552 8.00033 2.66663C12.667 2.66663 15.3337 7.99996 15.3337 7.99996C14.929 8.75703 14.4464 9.46978 13.8937 10.1266M9.41366 9.41329C9.23056 9.60979 9.00976 9.7674 8.76443 9.87671C8.51909 9.98602 8.25426 10.0448 7.98572 10.0495C7.71718 10.0543 7.45043 10.0049 7.2014 9.90429C6.95236 9.8037 6.72614 9.65398 6.53622 9.46406C6.34631 9.27414 6.19659 9.04792 6.096 8.79889C5.99541 8.54985 5.94601 8.28311 5.95075 8.01457C5.95549 7.74602 6.01426 7.48119 6.12358 7.23586C6.23289 6.99053 6.39049 6.76972 6.58699 6.58663M0.666992 0.666626L15.3337 15.3333"
                        stroke="#666666"
                        stroke-width="1.6"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3141_74103">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  {{ item.title }}</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-col>
    </v-row>
    <NewAccount
      :dialog="NewAccountDialog"
      @update:dialog="updateAccountDialog"
      :contacts="this.selected_rows"
    />
    <NewContact
      :dialog="NewContactDialog"
      @update:dialog="updateContactDialog"
    />
    <AssignToAccount
      :dialog="AssignToAccountDialog"
      @update:dialog="updateAssignToAccountDialog"
      :contacts="this.selected_rows"
    />
    <NewProject
      :dialog="NewProjectDialog"
      @update:dialog="updateNewProjectDialog"
      :contacts="this.selected_rows"
    />
    <NewEvent :contacts="this.selected_rows" :dialog="NewEventDialog" @update:dialog="updateNewEventDialog" />
    <NewDeal :contacts="this.selected_rows" :dialog="NewDealDialog" @update:dialog="updateNewDealDialog" />
    <NewTask :contacts="this.selected_rows" :dialog="NewTaskDialog" @update:dialog="updateNewTaskDialog" />
  </div>
</template>

<script>
import Input from "@/components/crm/assets/Input.vue";
import SearchIcon from "@/assets/images/icons/search.svg";
import AdvancedFilter from "@/components/crm/assets/advancedFilter.vue";
import GroupBy from "@/components/crm/accounts/GroupBy.vue";

// dialogs
import NewContact from "@/components/crm/assets/forms/create/NewContact.vue";
import NewAccount from "@/components/crm/assets/forms/create/NewAccount.vue";
import NewProject from "@/components/crm/assets/forms/create/NewProject.vue";
import NewEvent from "@/components/crm/assets/forms/create/NewEvent.vue";
import NewDeal from "@/components/crm/assets/forms/create/NewDeal.vue";
import NewTask from "@/components/crm/assets/forms/create/NewTask.vue";

import AssignToAccount from "@/components/crm/assets/AssignToAccount.vue";

import { useAccountsStore } from "@/store/crm/useAccountsStore";
export default {
  name: "LeadsManagementFilter",
  props: {
    selected_rows: Array,
    menu: Array,
    assign_to_account_menu: Boolean,
    parent: String,
    dashboard_path_name: String,
  },
  components: {
    Input,
    AdvancedFilter,
    GroupBy,
    NewAccount,
    AssignToAccount,
    NewProject,
    NewEvent,
    NewDeal,
    NewTask,
    NewContact,
  },
  data() {
    return {
      search: "",
      SearchIcon: SearchIcon,
      NewAccountDialog: false,
      AssignToAccountDialog: false,
      NewProjectDialog: false,
      NewContactDialog: false,
      NewEventDialog: false,
      NewDealDialog: false,
      NewTaskDialog: false,
    };
  },
  methods: {
    handleInput() {
    },

    updateAccountDialog(value) {
      this.NewAccountDialog = value;
    },
    assignToAccount(item) {
      if (item === "Assign To New Account") {
        this.NewAccountDialog = true;
      } else if (item === "Assign To Existed Account") {
        this.AssignToAccountDialog = true;
      }
    },
    updateAssignToAccountDialog(value) {
      this.AssignToAccountDialog = value;
    },
    updateNewProjectDialog(value) {
      this.NewProjectDialog = value;
    },
    updateContactDialog(value) {
      this.NewContactDialog = value;
    },

    updateNewEventDialog(value) {
      this.NewEventDialog = value;
    },
    updateNewDealDialog(value) {
      this.NewDealDialog = value;
    },
    updateNewTaskDialog(value) {
      this.NewTaskDialog = value;
    },
    createItem(item) {
      if (item === "Project") {
        this.NewProjectDialog = true;
      }
      if (item === "Event") {
        this.NewEventDialog = true;
      }
      if (item === "Deals") {
        this.NewDealDialog = true;
      }
      if (item === "Task") {
        this.NewTaskDialog = true;
      }
    },
    showHiddenColumn(key) {
      const store = useAccountsStore();
      const header = store.headers.find((h) => h.key === key);
      if (header) {
        header.show = true;
      }
    },
    OpenNewDialog(parent) {
      if (parent === "contacts") {
        this.NewContactDialog = true;
      }
      if (parent === "accounts") {
        this.NewAccountDialog = true;
      }
      if (parent === "projects") {
        this.NewProjectDialog = true;
      }
      if (parent === "events") {
        this.NewEventDialog = true;
      }
      if (parent === "deals") {
        this.NewDealDialog = true;
      }
      if (parent === "tasks") {
        this.NewTaskDialog = true;
      }
    },
  },
  computed: {
    accounts() {
      const store = useAccountsStore();
      return store.accounts;
    },
    hidden_headers() {
      const store = useAccountsStore();
      const headers = store.headers.filter((header) => !header.show);
      return headers;
    },
  },
  watch: {},
  mounted() {},
};
</script>
<style>
.conditions-container .select {
  max-width: 20%;
}
.conditions-container .v-input {
  height: 36px;
}

.conditions-container .v-input .v-input__control .v-field .v-field__outline {
  display: none !important;
}
.conditions-container .v-input .v-input__control .v-field,
.conditions-container .v-input .v-input__control .v-field .v-field__field,
.conditions-container .v-input .v-input__control .v-field .v-field__input,
.conditions-container
  .v-input
  .v-input__control
  .v-field
  .v-field__append-inner {
  height: 36px;
  padding: 0px;
  min-height: unset;
  padding: 0px 1px;
}
.conditions-container .v-input .v-input__control .v-field {
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 8px;
}

.conditions-container
  .v-input
  .v-input__control
  .v-field
  .v-field__append-inner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.conditions-container .v-input .v-input__control .v-field.v-field--focused {
  border: 1px solid #237276;
}

.create-list .v-list-item {
  width: 232px;
  height: 32px;
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  background: #fafafa;
  margin-bottom: 5px;
}
.create-list .v-list-item:hover {
  background: #eeeeee;
}
</style>
