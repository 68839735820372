<template>
  <div class="placement-card setting-card targeting-card budget-schedule">
    <div class="card-header">
      <p class="card-title">Budget & schedule</p>
    </div>
    <div class="card-body">
      <!-- Budget Section -->
      <div class="budget-and-schedule mb-10" v-if="!traffickBudget">
        <label class="budget-label">Budget</label>
        <p>Uses campaign budget Daily: EGP {{ budgetSpend }}</p>
      </div>
      <div class="budget mb-7" v-if="traffickBudget">
        <label for="budget" class="mb-1 d-block">Budget</label>
        <div class="container-for-input">
          <select name="" id="" @change="updateBudgetMode">
            <option value="BUDGET_MODE_DAY">Daily</option>
            <option value="BUDGET_MODE_TOTAL">Lifetime</option>
          </select>
          <div
            style="
              width: 39%;
              position: relative;
              display: flex;
              align-items: center;
            "
          >
            <input
              type="number"
              @change="updateBudgetSpend($event.target.value)"
              v-model="budgetSpend"
              name=""
              id=""
              class="no-controls"
              placeholder="At least 500.00"
            />
            <span
              style="
                position: absolute;
                right: 10px;
                color: #6d6e70;
                font-size: 12px;
              "
              >EGP</span
            >
          </div>
        </div>
      </div>

      <!-- Schedule Section -->
      <div class="schedule mb-10">
        <label class="schedule-label mb-2">Schedule</label>
        <div class="radio-buttons-container">
          <!-- Start Time Only Option -->
          <div class="radio-button">
            <input
              @change="setScheduleType"
              type="radio"
              id="singleDate"
              value="SCHEDULE_FROM_NOW"
              v-model="scheduleType"
              class="radio-button__input"
              name="selectData"
            />

            <label for="singleDate" class="radio-button__label">
              <span class="radio-button__custom"></span>
              Set start time and run ad group continuously
            </label>
          </div>

          <div
            v-if="scheduleType === 'SCHEDULE_FROM_NOW'"
            class="date-picker-container"
          >
            <Flatpickr
              @change="setStartDate"
              v-model="startDate"
              :config="dateConfig"
              placeholder="Select start date"
              :class="{ invalid: isStartDateInvalid }"
            />
            <span v-if="isStartDateInvalid" class="error-message">
              Start date cannot be in the past
            </span>
          </div>

          <!-- Start and End Time Option -->
          <div class="radio-button">
            <input
              @change="setScheduleType"
              type="radio"
              id="dateRange"
              value="SCHEDULE_START_END"
              v-model="scheduleType"
              class="radio-button__input"
              name="selectData"
            />

            <label for="dateRange" class="radio-button__label">
              <span class="radio-button__custom"></span>
              Set start and end time
            </label>
          </div>
          <div
            v-if="scheduleType === 'SCHEDULE_START_END'"
            class="date-range-container"
          >
            <div class="date-inputs d-flex align-center ga-2">
              <Flatpickr
                v-model="startDate"
                :config="dateConfig"
                placeholder="Select start date"
                :class="{ invalid: isStartDateInvalid }"
              />
              <span class="date-separator">-</span>
              <Flatpickr
                v-model="endDate"
                :min="startDate"
                :config="dateConfig"
                placeholder="Select end date"
                :class="{ invalid: isEndDateInvalid }"
              />
            </div>
            <span v-if="startDateError" class="error-message">{{
              startDateError
            }}</span>
            <span v-if="endDateError" class="error-message">{{
              endDateError
            }}</span>
          </div>
        </div>
      </div>
      <!-- Dayparting Section -->
      <div class="dayparting demographics">
        <label class="dayparting-label">Dayparting</label>
        <div class="age">
          <div class="mydict">
            <div>
              <label>
                <input
                  type="radio"
                  value="all-day"
                  v-model="dayparting"
                  @change="updatedayparting"
                  name="dayparting"
                />
                <span>All Day</span>
              </label>
              <label>
                <input
                  type="radio"
                  v-model="dayparting"
                  @change="updatedayparting"
                  value="Select specific time"
                  name="dayparting"
                />
                <span> Select specific time </span>
              </label>
            </div>
          </div>
        </div>
        <div
          v-if="dayparting === 'Select specific time'"
          class="spacific-time-day"
        >
          <ScheduleCalenderTiktok
            :last_selected="selected_schedule_times"
            @selectedTimes="getSelectedScheduleTimes"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Flatpickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import ScheduleCalenderTiktok from "./ScheduleCalenderTiktok.vue";
import { tiktokData } from "@/store/tiktokStore/tiktokData.js";
import { mapActions, mapState } from "pinia";

export default {
  name: "BudgetSchedule",
  components: {
    Flatpickr,
    ScheduleCalenderTiktok,
  },

  data() {
    return {
      budgetSpend: 0,
      scheduleType: "SCHEDULE_FROM_NOW",
      startDate: null,
      endDate: null,
      startDateError: "",
      endDateError: "",
      dateConfig: {
        enableTime: true,
        dateFormat: "Y-m-d H:i",
        minDate: "today",
      },
      dayparting: ["all-day"],
      selected_schedule_times: localStorage.getItem(
        "selected_schedule_times_tikTok"
      )
        ? JSON.parse(localStorage.getItem("selected_schedule_times_tikTok"))
        : {},
      traffickBudget: false,
    };
  },

  mounted() {
    this.budgetSpend = Number(localStorage.getItem("budget_spend")) || 0;
    if (localStorage.getItem("dayparting")) {
      this.dayparting = localStorage.getItem("dayparting");
    } else {
      this.dayparting = "all-day";
    }
    localStorage.setItem("scheduleType", this.scheduleType);
    let objectiveType = localStorage.getItem("selected_objective");
    if (objectiveType === "TRAFFIC") {
      this.traffickBudget = true;
    } else {
      this.traffickBudget = false;
    }
  },
  computed: {
    minDate() {
      const now = new Date();
      return now.toISOString().slice(0, 16);
    },
    ...mapState(tiktokData, ["budgetSpend"]),
  },
  watch: {
    startDate: {
      handler(newValue) {
        this.validateDates();
        localStorage.setItem("startDate", newValue);
      },
    },

    endDate: {
      handler(newValue) {
        this.validateDates();
        localStorage.setItem("endDate", newValue);
      },
    },

    scheduleType: {
      handler(newValue) {
        if (newValue === "single") {
          this.endDate = "";
          this.endDateError = "";
        }
        this.validateDates();
      },
    },
  },
  methods: {
    ...mapActions(tiktokData, ["updateBudgetSpend"]),
    updatedayparting() {
      localStorage.setItem("dayparting", this.dayparting);
    },
    getSelectedScheduleTimes(selected_schedule_times) {
      this.selected_schedule_times = selected_schedule_times;
      localStorage.setItem(
        "selected_schedule_times_tikTok",
        JSON.stringify(this.selected_schedule_times)
      );
    },
    setScheduleType() {
      localStorage.setItem("scheduleType", this.scheduleType);
    },
    validateDates() {
      // Reset errors
      this.startDateError = "";
      this.endDateError = "";

      // Validate start date
      if (this.startDate) {
        const startDateTime = new Date(this.startDate);
        const now = new Date();

        if (startDateTime < now) {
          this.startDateError = "Start date cannot be in the past";
          return false;
        }
      }

      // Validate end date if in range mode
      if (this.scheduleType === "range" && this.startDate && this.endDate) {
        const startDateTime = new Date(this.startDate);
        const endDateTime = new Date(this.endDate);

        if (endDateTime <= startDateTime) {
          this.endDateError = "End date must be after start date";
          return false;
        }
      }

      return true;
    },
    getScheduleData() {
      if (!this.validateDates()) {
        return null;
      }

      return {
        type: this.scheduleType,
        startDate: this.startDate,
        endDate: this.scheduleType === "range" ? this.endDate : null,
      };
    },
    updateBudgetMode(event) {
      const selectedValue = event.target.value;
      this.budget_mode = selectedValue;
      localStorage.setItem("budget_mode", this.budget_mode);
    },
  },
};
</script>

<style scoped>
.error-message {
  color: #dc2626;
  font-size: 12px;
  margin-top: 4px;
}

.invalid {
  border-color: #dc2626;
}

:deep(.flatpickr-input) {
  padding: 8px 12px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  width: 200px;
}

:deep(.flatpickr-input:focus) {
  outline: none;
  border-color: #2563eb;
  box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.1);
}
</style>
