<template>
  <div class="tiktok-navbar">
    <div class="tiktok-navbar-left">
      <img src="@/assets/tiktokImage/tikTiokbusinessLogo.svg" alt="" /> <span>Ads Manager   </span>
    </div>
  </div>
</template>

<script>
import "@/assets/css/tiktok/index.css";
export default{
    data() {
        return {}
    }
}
</script>
