<template>
  <Bar
    ref="chartComponent"
    :style="{ height: '484px' }"
    :data="chartData"
    :options="chartOptions"
  />
</template>

<script>
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "BarChart",
  components: {
    Bar,
  },
  props: {
    dataBar: {
      type: Object,
      required: true,
    },
    firstTime: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Female",
            backgroundColor: "rgba(237, 237, 237, 1)",
            borderColor: "#4fc3f7",
            data: [],
            borderRadius: 5,
            fill: true,
          },
          {
            label: "Male",
            backgroundColor: "rgba(53, 140, 145, 0.7)",
            borderColor: "#f87979",
            borderRadius: 5,
            data: [],
            fill: true,
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: "Gender Distribution",
          },
        },
      },
    };
  },
  watch: {
    dataBar: {
      handler(newData) {
        if (this.firstTime) {
          this.chartData = newData;
          return;
        }

        const insights = newData;
        const uniqueLabels = new Set();
        const femaleData = [];
        const maleData = [];

        Object.keys(insights).forEach((key) => {
          const dimensionStats = insights[key].dimension_stats || [];
          dimensionStats.forEach((element) => {
            if (element.age_bucket) {
              uniqueLabels.add(element.age_bucket);
            }
            if (element.gender === "female") {
              femaleData.push(element.impressions || 0);
            } else if (element.gender === "male") {
              maleData.push(element.impressions || 0);
            }
          });
        });

        this.chartData = {
          labels: Array.from(uniqueLabels),
          datasets: [
            {
              ...this.chartData.datasets[0],
              data: femaleData,
            },
            {
              ...this.chartData.datasets[1],
              data: maleData,
            },
          ],
        };
        console.log("child", this.chartData);
        this.updateChart();
      },
      deep: true,
    },
  },
  mounted() {
    this.updateChart();
  },
  methods: {
    updateChart() {
      if (this.$refs.chartComponent && this.$refs.chartComponent.chart) {
        this.$refs.chartComponent.chart.update();
      }
    },
  },
};
</script>
