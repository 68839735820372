<template>
  <div class="budget-scheduling">
    <p class="text-title roboto-bold">Budget scheduling</p>
    <div class="increase-budget-periods">
      <label class="checkbox">
        <input
          type="checkbox"
          @click="specificTimePeriods"
          v-model="specific_time_periods"
        />
        <span class="checkmark"></span>
        <span class="text"
          >Increase your budget during specific time periods</span
        >
      </label>
    </div>
    <v-expansion-panels v-if="specific_time_periods">
      <v-expansion-panel
        v-for="(panel, index) in panels"
        :key="panel.id"
        :title="'Time Period ' + (index + 1)"
      >
        <v-expansion-panel-text>
          <v-container class="scheduling-card">
            <v-row>
              <v-col cols="6">
                <p class="roboto-bold">Starts on</p>
                <div class="date-time-picker" style="position: relative">
                  <flat-pickr
                    v-model="panel.startDateTime"
                    :config="dateConfig"
                    @on-change="setStartDateLocalStorage(panel.id)"
                    class="form-control"
                    placeholder="Select date"
                  />
                  <div class="d-flex align-center px-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="20px"
                      viewBox="0 -960 960 960"
                      width="20px"
                      fill="#000"
                    >
                      <path
                        d="m620.92-316.92 37.16-37.16L506-506.16V-720h-52v236.15l166.92 166.93ZM480.07-116q-75.21 0-141.4-28.42-66.18-28.42-115.99-78.21-49.81-49.79-78.25-116.09Q116-405.01 116-480.39q0-75.38 28.42-141.75t78.21-115.68q49.79-49.31 116.09-77.75Q405.01-844 480.39-844q75.38 0 141.75 28.66 66.38 28.66 115.48 77.79 49.1 49.13 77.74 115.55Q844-555.58 844-480.07q0 75.21-28.42 141.4-28.42 66.18-77.71 115.99-49.29 49.81-115.76 78.25Q555.64-116 480.07-116ZM480-480Zm.48 312q129.47 0 220.5-91.5Q792-351 792-480.48q0-129.47-91.02-220.5Q609.95-792 480.48-792 351-792 259.5-700.98 168-609.95 168-480.48 168-351 259.5-259.5T480.48-168Z"
                      />
                    </svg>
                    <flat-pickr
                      v-model="panel.startTime"
                      :config="timeConfig"
                      class="form-control"
                      placeholder="Select time"
                    />
                  </div>
                </div>
              </v-col>
              <v-col cols="6">
                <p class="roboto-bold">Ends</p>
                <div class="date-time-picker">
                  <flat-pickr
                    v-model="panel.endDateTime"
                    :config="dateConfig"
                    class="form-control"
                    placeholder="Select date"
                  />
                  <div class="d-flex align-center px-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="20px"
                      viewBox="0 -960 960 960"
                      width="20px"
                      fill="#000"
                    >
                      <path
                        d="m620.92-316.92 37.16-37.16L506-506.16V-720h-52v236.15l166.92 166.93ZM480.07-116q-75.21 0-141.4-28.42-66.18-28.42-115.99-78.21-49.81-49.79-78.25-116.09Q116-405.01 116-480.39q0-75.38 28.42-141.75t78.21-115.68q49.79-49.31 116.09-77.75Q405.01-844 480.39-844q75.38 0 141.75 28.66 66.38 28.66 115.48 77.79 49.1 49.13 77.74 115.55Q844-555.58 844-480.07q0 75.21-28.42 141.4-28.42 66.18-77.71 115.99-49.29 49.81-115.76 78.25Q555.64-116 480.07-116ZM480-480Zm.48 312q129.47 0 220.5-91.5Q792-351 792-480.48q0-129.47-91.02-220.5Q609.95-792 480.48-792 351-792 259.5-700.98 168-609.95 168-480.48 168-351 259.5-259.5T480.48-168Z"
                      />
                    </svg>
                    <flat-pickr
                      v-model="panel.endTime"
                      :config="timeConfig"
                      class="form-control"
                      @on-change="setEndDateLocalStorage(panel.id)"
                      placeholder="Select time"
                    />
                  </div>
                </div>
                <p v-if="panel.dateError" class="error-text text-red">
                  {{ panel.dateError }}
                </p>
              </v-col>
              <v-col cols="9">
                <v-select
                  v-model="panel.daily_budget_type_selected"
                  :items="daily_budget_type"
                  density="compact"
                  variant="outlined"
                  return-object
                  item-value="id"
                  item-title="title"
                >
                  <template v-slot:selection="{ item }">
                    {{ item?.title || "" }}
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item @click="selectItem(item, panel.id)">
                      <div class="my-form">
                        <div>
                          <input
                            :checked="
                              panel.daily_budget_type_selected &&
                              panel.daily_budget_type_selected.id ===
                                item.raw.id
                            "
                            :id="item.id"
                            type="radio"
                            name="option"
                            :value="item"
                          />
                          <label :for="item.id">{{ item.title }}</label>
                        </div>
                      </div>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="3" class="pl-0 budget-section">
                <div class="input-budget">
                  <span class="dollar-sign" v-if="!panel.percentageIcon"
                    >$</span
                  >
                  <input
                    type="number"
                    class="no-controls"
                    v-model="panel.budgetValue"
                    @change="setBudgetValueLocalStorage(panel.id)"
                  />
                  <span class="currency-type" v-if="!panel.percentageIcon"
                    >USD</span
                  >
                  <span class="currency-type" v-if="panel.percentageIcon"
                    >%</span
                  >
                </div>
              </v-col>
              <v-col cols="12" class="remove-scheduling-card">
                <button
                  class="d-flex align-center"
                  @click="removePanel(panel.id)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="20px"
                    viewBox="0 -960 960 960"
                    width="20px"
                    fill="#1c2b33"
                  >
                    <path
                      d="M324.31-164q-26.62 0-45.47-18.84Q260-201.69 260-228.31V-696h-48v-52h172v-43.38h192V-748h172v52h-48v467.26q0 27.74-18.65 46.24Q662.7-164 635.69-164H324.31ZM648-696H312v467.69q0 5.39 3.46 8.85t8.85 3.46h311.38q4.62 0 8.46-3.85 3.85-3.84 3.85-8.46V-696ZM400.16-288h51.99v-336h-51.99v336Zm107.69 0h51.99v-336h-51.99v336ZM312-696v480-480Z"
                    />
                  </svg>
                  <span>Remove this period</span>
                </button>
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <div
      class="mt-4 mb-3 add-new-period"
      v-if="panels.length < 50 && specific_time_periods"
    >
      <button class="d-flex align-center ga-1" @click="addPanel">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="20px"
          viewBox="0 -960 960 960"
          width="20px"
          fill="#1c2b33"
        >
          <path
            d="M454-298h52v-156h156v-52H506v-156h-52v156H298v52h156v156Zm26.34 182q-75.11 0-141.48-28.42-66.37-28.42-116.18-78.21-49.81-49.79-78.25-116.09Q116-405.01 116-480.39q0-75.38 28.42-141.25t78.21-115.68q49.79-49.81 116.09-78.25Q405.01-844 480.39-844q75.38 0 141.25 28.42t115.68 78.21q49.81 49.79 78.25 115.85Q844-555.45 844-480.34q0 75.11-28.42 141.48-28.42 66.37-78.21 116.18-49.79 49.81-115.85 78.25Q555.45-116 480.34-116Zm-.34-52q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z"
          />
        </svg>
        <span>Add another time period</span>
      </button>
    </div>
    <div class="add-scheduling mb-3">
      <p class="roboto-bold">Ad scheduling</p>
      <span class="roboto-light d-block">Run ads all the time </span>
    </div>
    <div class="delivery-type" v-if="selectedBidStrategy === 'Bid cap'">
      <p class="roboto-bold">Delivery type</p>
      <div class="increase-budget-periods">
        <label class="checkbox">
          <input
            type="checkbox"
            @change="deliveryType"
            v-model="delivery_type"
          />
          <span class="checkmark"></span>
          <p class="text">
            <span class="d-block">Accelerated</span>
            <span class="d-block">
              Spend your budget and get results as quickly as possible</span
            >
          </p>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  name: "BudgetScheduling",
  props: {
    selectedBidStrategy: {
      type: String,
      required: true,
    },
  },
  components: {
    flatPickr,
  },

  data() {
    return {
      panels: [],

      dateConfig: {
        dateFormat: "Y-m-d",
        minDate: "today",
        defaultDate: "today",
      },
      timeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        defaultDate: "00:00",
      },
      daily_budget_type: [
        {
          id: 0,
          title: "Increase daily budget by value amount ($)",
          value: "ABSOLUTE",
        },
        {
          id: 1,
          title: "Increase daily budget by a percentage (%)",
          value: "MULTIPLIER",
        },
      ],
      specific_time_periods: false,
      delivery_type: false,
      setArrayStartDate: [],
      setArrayEndDate: [],
      setArrayBudgetValue: [],
    };
  },

  created() {
    this.loadSavedPanels();
    if (this.panels.length === 0) {
      this.addPanel();
    }
  },
  methods: {
    createNewPanel() {
      return {
        id: Date.now(),
        startDateTime: new Date(),
        endDateTime: new Date(),
        startTime: "00:00",
        endTime: "00:00",
        dateError: "",
        budgetValue: "",
        daily_budget_type_selected: {
          id: 0,
          title: "Increase daily budget by value amount ($)",
          value: "ABSOLUTE",
        },
        percentageIcon: false,
      };
    },
    addPanel() {
      if (this.panels.length < 50) {
        this.panels.push(this.createNewPanel());
      }
    },
    removePanel(panelId) {
      this.panels = this.panels.filter((panel) => panel.id !== panelId);
      this.savePanelsToStorage();
    },
    loadSavedPanels() {
      try {
        const savedData = localStorage.getItem("budgetSchedulingPanels");
        if (savedData) {
          this.panels = JSON.parse(savedData);
        }
      } catch (error) {
        console.error("Error loading saved panels:", error);
      }
    },
    savePanelsToStorage() {
      try {
        localStorage.setItem(
          "budgetSchedulingPanels",
          JSON.stringify(this.panels)
        );
      } catch (error) {
        console.error("Error saving panels:", error);
      }
    },
    validateDates(panel) {
      if (
        !panel.startDateTime ||
        !panel.startTime ||
        !panel.endDateTime ||
        !panel.endTime
      ) {
        panel.dateError = "";
        return true;
      }

      const startDate = new Date(panel.startDateTime);
      const endDate = new Date(panel.endDateTime);

      startDate.setHours(...panel.startTime.split(":"));
      endDate.setHours(...panel.endTime.split(":"));

      if (endDate <= startDate) {
        panel.dateError = "End date must be after start date";
        return false;
      }

      panel.dateError = "";
      return true;
    },
    handleStartDateChange(newDate, panelId) {
      const panel = this.panels.find((p) => p.id === panelId);
      if (panel) {
        panel.startDateTime = newDate;
        this.$nextTick(() => {
          this.validateDates(panel);
          this.savePanelsToStorage();
        });
      }
    },
    handleStartTimeChange(newTime, panelId) {
      const panel = this.panels.find((p) => p.id === panelId);
      if (panel) {
        panel.startTime = newTime;
        this.$nextTick(() => {
          this.validateDates(panel);
          this.savePanelsToStorage();
        });
      }
    },
    handleEndDateChange(newDate, panelId) {
      const panel = this.panels.find((p) => p.id === panelId);
      if (panel) {
        panel.endDateTime = newDate;
        this.$nextTick(() => {
          this.validateDates(panel);
          this.savePanelsToStorage();
        });
      }
    },
    handleEndTimeChange(newTime, panelId) {
      const panel = this.panels.find((p) => p.id === panelId);
      if (panel) {
        panel.endTime = newTime;
        this.$nextTick(() => {
          this.validateDates(panel);
          this.savePanelsToStorage();
        });
      }
    },
    selectItem(item, panelId) {
      const panel = this.panels.find((p) => p.id === panelId);
      if (panel) {
        panel.daily_budget_type_selected = item.raw;
        panel.percentageIcon = item.raw.id === 1;
        localStorage.setItem("meta_daily_budget_type", item.raw.value);
      }
    },
    specificTimePeriods() {
      this.specific_time_periods = !this.specific_time_periods;
      localStorage.setItem("specific_time_periods", this.specific_time_periods);
    },
    deliveryType() {
      localStorage.setItem("meta_delivery_type", this.delivery_type);
    },
    setStartDateLocalStorage(id) {
      const panel = this.panels.find((panel) => panel.id === id);
      if (panel && !this.setArrayStartDate.includes(panel.startDateTime)) {
        this.setArrayStartDate.push(panel.startDateTime);
        localStorage.setItem(
          "setArrayStartDate",
          JSON.stringify(this.setArrayStartDate)
        );
      }
    },
    setEndDateLocalStorage(id) {
      const panel = this.panels.find((panel) => panel.id === id);
      if (panel && !this.setArrayEndDate.includes(panel.endDateTime)) {
        this.setArrayEndDate.push(panel.endDateTime);
        localStorage.setItem(
          "setArrayEndtDate",
          JSON.stringify(this.setArrayEndDate)
        );
      }
    },
    setBudgetValueLocalStorage(id) {
      const panel = this.panels.find((panel) => panel.id === id);
      if (panel && !this.setArrayBudgetValue.includes(panel.budgetValue)) {
        this.setArrayBudgetValue.push(panel.budgetValue);
        localStorage.setItem(
          "setArrayBudgetValue",
          JSON.stringify(this.setArrayBudgetValue)
        );
      }
    },
  },
  watch: {
    panels: {
      handler() {
        this.savePanelsToStorage;
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.error-text {
  font-size: 0.875rem;
  margin-top: 0.5rem;
  color: #dc3545;
}
</style>
