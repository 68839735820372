<template>
  <v-container class="pa-0">
    <v-row no-gutters style="justify-content: space-between;">
      <v-col cols="8" style="max-width: 64%;">
        <div class="placement-card identity-card">
          <div class="card-header">
            <p class="card-title">Identity</p>
          </div>
          <div class="card-body">
            <div
              class="checked-label mb-5 smart-campaign mb-1 d-flex align-center ga-1"
            >
              <label class="toggle-switch">
                <input
                  @change="userCommentStatus"
                  type="checkbox"
                  v-model="usetiktokAccount"
                  id="user-comment"
                />
                <div class="toggle-switch-background">
                  <div class="toggle-switch-handle"></div>
                  <svg
                    class="closemark"
                    xmlns="http://www.w3.org/2000/svg"
                    height="30px"
                    viewBox="0 -960 960 960"
                    width="30px"
                    fill=""
                  >
                    <path
                      d="M291-253.85 253.85-291l189-189-189-189L291-706.15l189 189 189-189L706.15-669l-189 189 189 189L669-253.85l-189-189-189 189Z"
                    />
                  </svg>
                  <svg
                    class="checkmark"
                    xmlns="http://www.w3.org/2000/svg"
                    height="30px"
                    viewBox="0 -960 960 960"
                    width="30px"
                    fill=""
                  >
                    <path
                      d="M389-259.35 187.35-460l58.89-59.89L389-377.13l324.76-323.76L772.65-642 389-259.35Z"
                    />
                  </svg>
                </div>
              </label>
              <label for="user-comment" class="label-for-input">
                Use TikTok account to deliver Spark Ads
              </label>
            </div>
            <div class="set-custome-identity">
              <label for="">Set custom identity </label>
              <button @click="dialog = !dialog">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="16px"
                  viewBox="0 -960 960 960"
                  width="16px"
                  fill="#6d6e70"
                >
                  <path
                    d="M444-444H240v-72h204v-204h72v204h204v72H516v204h-72v-204Z"
                  />
                </svg>
                <span>Create</span>
              </button>
              <v-dialog
                v-model="dialog"
                class="create-new-custom-identity"
                width="auto"
              >
                <v-card max-width="600" title="Create new custom identity">
                  <div class="px-3 d-flex align-center ma-5 ga-3">
                    <div class="custom-identity-img">
                      <img :src="imagePreview" alt="Profile Image" />
                      <input
                        type="file"
                        ref="fileInput"
                        @change="handleImageUpload"
                        accept="image/*"
                        style="display: none"
                      />
                      <button @click="triggerFileInput">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="15px"
                          viewBox="0 -960 960 960"
                          width="18px"
                          fill="#000"
                        >
                          <path
                            d="M96 0v-112h768V0H96Zm168-326.92h37.92l319-319.77-18.3-20.08-18.85-17.08-319.77 319v37.93Zm-32 32v-82.92l402.77-403.62q6.38-6.39 12.3-8.31 5.91-1.92 11.93-1.92 6.03 0 11.4 1.92t11.09 7.48l37.05 36.44q5.61 6.39 7.92 11.82 2.31 5.44 2.31 11.76 0 5.93-2.38 12.18-2.37 6.25-7.55 11.41L315-294.92h-83Zm463.77-428.85-37.92-35.92 37.92 35.92Zm-74.85 77.08-18.3-20.08-18.85-17.08 37.15 37.16Z"
                          />
                        </svg>
                      </button>
                    </div>
                    <div class="identity-name">
                      <span>@</span>
                      <input type="text" name="" id="" v-model="identityName" />
                      <span style="color: #6d6e70">
                        {{ identityName.length }} / 40
                      </span>
                    </div>
                  </div>
                  <template v-slot:actions>
                    <v-btn
                      class="cancel-dialog"
                      text="Cancel"
                      @click="dialog = false"
                    ></v-btn>
                    <v-btn class="create-identity" text="Create"></v-btn>
                  </template>
                </v-card>
              </v-dialog>
            </div>
          </div>
        </div>
        <AdDetails />
      </v-col>
      <v-col cols="4">
        <PreviewCard />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AdDetails from "./AdDetails.vue";
import PreviewCard from "./PreviewCard.vue";
export default {
  components: { AdDetails, PreviewCard },
  data() {
    return {
      usetiktokAccount: false,
      dialog: false,
      identityName: "",
      imagePreview:
        "https://lf16-ttmp.tiktokstaticb.com/obj/ttastatic-sg/biz_manager_creation/statics/images/identity-default-avatar81d7ba6f.png",
    };
  },
  methods: {
    userCommentStatus() {
      localStorage.setItem("use_tiktok_acc_id", this.usetiktokAccount);
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        if (file.type.startsWith("image/")) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.imagePreview = e.target.result;
          };
          reader.readAsDataURL(file);
          this.errorMessage = "";
        } else {
          this.errorMessage = "Please select an image file";
        }
      }
    },
    resetForm() {
      this.identityName = "";
      this.imagePreview =
        "https://lf16-ttmp.tiktokstaticb.com/obj/ttastatic-sg/biz_manager_creation/statics/images/identity-default-avatar81d7ba6f.png";
      this.errorMessage = "";
    },
  },
};
</script>
