<template>
  <v-container
    fluid
    style="background-color: #f6f6f6; min-height: 100vh"
    class="pa-0 ma-0"
  >
    <v-row no-gutters style="position: fixed; top: 0; width: 100%; z-index: 99">
      <v-col cols="12">
        <TiktokNavbar />
      </v-col>
    </v-row>
    <v-row
      class=""
      style="height: calc(100% - 70px); margin-top: 4%"
      no-gutters
    >
      <v-col cols="2">
        <TikTokSideBar />
      </v-col>
      <v-col cols="10">
        <div
          class="all-contain-first-step all-contain-secound-step all-contain-third-step"
        >
          <AdName />
          <IdentityCard />
          <Tracking />
          <div class="tiktok-footer mt-4">
            <div class="footer d-flex align-center justify-space-between">
              <div>
                <button class="exit-btn">Exit</button>
              </div>
              <div class="continue">
                <button @click="sendMedia()" class="continue-btn">
                  <span>Publish</span>
                  <img
                    v-if="loading"
                    src="@/assets/tiktokImage/spinner.svg"
                    alt=""
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import "@/assets/css/tiktok/index.css";
import TiktokNavbar from "@/components/tiktok/TiktokNavbar.vue";
import TikTokSideBar from "@/components/tiktok/TikTokSideBar.vue";
import AdName from "@/components/tiktok/thirdStep/AdName.vue";
import IdentityCard from "@/components/tiktok/thirdStep/IdentityCard.vue";
import apiClient from "@/core/buildRequest";
import Tracking from "@/components/tiktok/thirdStep/Tracking.vue";
import { tiktokData } from "@/store/tiktokStore/tiktokData";
import { mapState } from "pinia";

export default {
  components: { TikTokSideBar, TiktokNavbar, AdName, IdentityCard, Tracking },
  data() {
    return {
      startDate: null,
      endDate: null,
      loading: false,
    };
  },
  computed: {
    ...mapState(tiktokData, ["videoPreview", "media_fils", "video_id"]),
  },
  mounted() {},
  methods: {
    async sendMedia() {
      this.loading = true;
      if (!this.videoPreview || !this.videoPreview[0]) {
        throw new Error("Video preview is not available.");
      }

      if (!this.media_fils || !this.media_fils[0]) {
        throw new Error("Media file is not available.");
      }
      const fileType = this.videoPreview[0].fileType.includes("video")
        ? "video"
        : "image";
      const mediaData = {
        advertiser_id: "7411099371934089232",
        upload_type: "UPLOAD_BY_FILE",
        file_name: `${
          this.videoPreview[0].fileName
        }-${new Date().toISOString()}`,
        type: fileType,
        image_file: fileType === "image" ? this.media_fils[0] : null,
        video_file: fileType === "video" ? this.media_fils[0] : null,
        flaw_detect: "true",
      };
      console.log("Media Data:", mediaData);
      await apiClient.post(
        "/campaign-ads/tiktok/upload/media",
        mediaData
      ).then((response) => {
        console.log("Response:", response.data.data[0].video_id);
        let  video_id = response.data.data[0].video_id;
        console.log("Video ID:", video_id);
        this.sendRequestApi(video_id);
      }).catch((error) => {
        console.log(error);
      });
      this.loading = false;
    },

    sendRequestApi(video_id) {
      let campaignType = ""
     if(localStorage.getItem("selected_objective") === "APP_PROMOTION" && localStorage.getItem("campaign_type") === "true") {
        campaignType = "IOS14_CAMPAIGN";
     }else if(localStorage.getItem("selected_objective") === "APP_PROMOTION" && localStorage.getItem("campaign_type") === "false"){
        campaignType = "REGULAR_CAMPAIGN";
     }else{
        campaignType = "REGULAR_CAMPAIGN";
     }
      const productSelection = 0; // Default as 0 (disabled)

      const postbackWindowMode =
        localStorage.getItem("campaign_type") === "true"
          ? "POSTBACK_WINDOW_MODE1"
          : "POSTBACK_WINDOW_MODE2";

      // const sameObjectivePlacements = [
      //   "TRAFFIC",
      //   "APP_PROMOTION",
      //   "WEBSITE_CONVERSIONS",
      //   "PRODUCT_SALES",
      // ].includes(localStorage.getItem("selected_objective"));

      // const placement_type = sameObjectivePlacements
      //   ? localStorage.getItem("placement_type")
      //   : "PLACEMENT_TYPE_AUTOMATIC";

      // const placementsArray = sameObjectivePlacements
      //   ? JSON.parse(localStorage.getItem("placements")) || []
      //   : ["PLACEMENT_TIKTOK"];

      const operatingSystem =
        localStorage.getItem("operatingSystem") === "All"
          ? ["ANDROID", "IOS"]
          : [localStorage.getItem("operatingSystem")];

      const formatUTCDate = (dateStr) => {
        const date = new Date(dateStr);
        if (isNaN(date.getTime())) return null;
        return `${date.getUTCFullYear()}-${String(
          date.getUTCMonth() + 1
        ).padStart(2, "0")}-${String(date.getUTCDate()).padStart(
          2,
          "0"
        )} ${String(date.getUTCHours()).padStart(2, "0")}:${String(
          date.getUTCMinutes()
        ).padStart(2, "0")}:${String(date.getUTCSeconds()).padStart(2, "0")}`;
      };

      const formattedStartDate = formatUTCDate(
        localStorage.getItem("startDate")
      );
      const formattedEndDate = formatUTCDate(localStorage.getItem("endDate"));

      let optimizationGoal = "REACH";
      if (
        localStorage.getItem("optimizationGoal") !== "REACH" &&
        localStorage.getItem("selected_objective") !== "REACH"
      ) {
        optimizationGoal = localStorage.getItem("optimizationGoal");
      } else if (
        localStorage.getItem("selected_objective") !== "TRAFFIC" &&
        localStorage.getItem("optimizationGoal")
      ) {
        optimizationGoal = "REACH";
      }

      let billingEvent = "CPM";
      switch (localStorage.getItem("selected_objective")) {
        case "TRAFFIC":
          billingEvent =
            localStorage.getItem("optimizationGoal") === "CLICK"
              ? "CPC"
              : "OCPM";
          break;
        case "VIDEO_VIEWS":
          billingEvent = "CPV";
          break;
        case "ENGAGEMENT":
        case "LEAD_GENERATION":
        case "WEBSITE_CONVERSIONS":
        case "PRODUCT_SALES":
          billingEvent = "OCPM";
          break;
        default:
          billingEvent = "CPM";
      }

      let ageGroup = JSON.parse(localStorage.getItem("selectedValues")) || [];
      if (ageGroup.includes("All")) {
        ageGroup = [
          "AGE_13_17",
          "AGE_18_24",
          "AGE_25_34",
          "AGE_35_44",
          "AGE_45_54",
          "AGE_55_100",
        ];
      }

      let deeplink_format_type = "";

      if (
        localStorage.getItem("deeplink_format_type") &&
        localStorage.getItem("deeplink_format_type").includes("https")
      ) {
        deeplink_format_type = "UNIVERSAL_OR_APP";
      } else {
        deeplink_format_type = "SCHEME";
      }

      const creatCampainData = {
        campaign_name: localStorage.getItem("campaign_name"),
        objective_type: localStorage.getItem("selected_objective"),
        budget_mode: localStorage.getItem("budget_mode"),
        budget: localStorage.getItem("budget_spend"),
        bid_price: Number(localStorage.getItem("budget_spend")) - 100,
        frequency: 2,
        frequency_schedule:3,
        advertiser_id: "7411099371934089232",
        campaign_type: campaignType,
        // campaign_product_source:
        //   localStorage.getItem("selected_objective") === "Product sales"
        //     ? "Catalog"
        //     : null,
        // app_promotion_type: localStorage.getItem("app_promotion_type"),
        rta_product_selection_enabled: productSelection,
        // app_id: "2222345",
        campaign_app_profile_page_state: "OFF",
        operation_status: null,
        postback_window_mode: postbackWindowMode,
      //  is_advanced_dedicated_campaign: 0,
        special_industries: "HOUSING",
        adgroup_name: localStorage.getItem("adgroup_name"),
        placement_type: "PLACEMENT_TYPE_NORMAL",
        placements: ["PLACEMENT_TIKTOK"],
        video_download_disabled:
          localStorage.getItem("video_downloads") === "true",
        location_ids: ["360631"],
        gender: localStorage.getItem("gender"),
        operating_systems: operatingSystem,
        schedule_type: localStorage.getItem("scheduleType"),
        schedule_start_time: formattedStartDate,
        schedule_end_time: formattedEndDate,
        optimization_goal: optimizationGoal,
        bid_type: localStorage.getItem("bid_type"),
        billing_event: billingEvent,
     //   search_result_enabled: 1,
        spending_power: localStorage.getItem("spendPower"),
        shopping_ads_type: "VIDEO",
        age_groups: ageGroup,
        ad_name: localStorage.getItem("ad_tiktok_name"),
        identity_type: "CUSTOMIZED_USER",
        identity_id: "7417366661244567570",
        adgroup_id: "1814328271206466",
        ad_format: localStorage.getItem("ad_formate"),
        video_id: video_id,
        ad_text: localStorage.getItem("text_value"),
        Call_to_action: localStorage.getItem("call_to_action"),
        vertical_video_strategy: "UNSET",
        dynamic_format: "DYNAMIC_CREATIVE",
        // identity_authorized_bc_id: "",
        catalog_id: "111",
        product_specific_type: "ALL",
        image_ids: [
          "ad-site-i18n-sg/202410295d0d579e6810d90c47df8a57",
          "ad-site-i18n-sg/202410295d0d579e6810d90c47df8a57",
        ],
        landing_page_url: "",
        tiktok_page_category: "PROFILE_PAGE",
        phone_region_code: "",
        phone_region_calling_code: "",
        phone_number: "",
        deeplink_type: "NORMAL",
        deeplink_format_type: deeplink_format_type,
        shopping_ads_deeplink_type: "",
        fallback_type: "UNSET",
        // disclaimer_type: "TEXT_LINK",
        // disclaimer_clickable_texts: [""],
        // disclaimer_text: [""],
        utm_key: "",
        utm_value: "",
        dynamic_destination: "DLP",
        playable_url: "",
        app_name: "",
        display_name: "",
        avatar_icon_web_uri: "",
        creative_authorized: 1,
        creative_type: "CUSTOM_INSTANT_PAGE",
        showcase_products: "",
        deeplink_utm_params: "",
      };
      console.log(creatCampainData);
      apiClient
        .post("/campaign-ads/tiktok/create/campaign", creatCampainData)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(
            "Error:",
            error.response ? error.response.data : error.message
          );
        });
    },
  },
};
</script>
