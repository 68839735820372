import EngagmentAndInsights from "@/views/EngagementInsights/EngagementAndInsights.vue";
import EngagementAndInsightsPost from "@/views/EngagementInsights/EngagementAndInsightsPost.vue";
import EngagementAndInsightsEditPost from "@/views/EngagementInsights/EngagementAndInsightsEditPost.vue";
const CalenderRoutes = [
  {
    path: "/engagement-insights",
    name: "EngagementAndInsights",
    component: EngagmentAndInsights,
    meta: {
      title: "Engagement & Insights",
      description: "",
    },
  },
  {
    path: "/post/:id/engagement-insights",
    name: "EngagementAndInsightsPost",
    component: EngagementAndInsightsPost,
    meta: {
      title: "Engagement & Insights Post",
      description: "",
    },
  },
  {
    path: "/post/edit/:id/engagement-insights",
    name: "EngagementAndInsightsEditPost",
    component: EngagementAndInsightsEditPost,
    meta: {
      title: "Engagement & Insights Edit Post",
      description: "",
    },
  },
];

export default CalenderRoutes;
