import { defineStore } from "pinia";
import apiClient from "@/core/buildRequest";

export const useLeadsStore = defineStore("useLeadsStore", {
  state: () => ({
    leads: [],
    headers: [
      {
        title: "Marketing Campaign Time",
        name: "Marketing Campaign Time",
        align: "start",
        sortable: true,
        key: "campaignTime",
      },
      {
        title: "Marketing Campaign",
        name: "Marketing Campaign",
        align: "start",
        sortable: true,
        key: "marketingCampaign",
      },
      {
        title: "Marketing Source",
        align: "start",
        sortable: true,
        key: "platform",
      },
      {
        title: "First Name",
        name: "First Name",
        align: "start",
        sortable: true,
        key: "firstName",
      },
      {
        title: "Last Name",
        name: "Last Name",
        align: "start",
        sortable: true,
        key: "lastName",
      },
      {
        title: "Phone Number",
        name: "Phone Number",
        align: "start",
        sortable: true,
        key: "phoneNumber",
      },
      {
        title: "Status",
        name: "Status",
        align: "start",
        sortable: true,
        key: "status",
      },
      {
        title: "Leads Progress",
        name: "Leads Progress",
        align: "start",
        sortable: true,
        key: "leadsProgressCol",
      },
      {
        title: "Reachability",
        name: "Reachability",
        align: "start",
        sortable: true,
        key: "reachability",
      },
      {
        title: "Comments",
        name: "Comments",
        align: "start",
        sortable: false,
        key: "comments",
      },
      {
        title: "Assignee",
        name: "Assignee",
        align: "start",
        sortable: true,
        key: "assignee",
      },
    ],
  }),
  getters: {
    getLeads: (state) => state.leads,
  },
  actions: {
    async getAllLeads() {
      try {
        var response = await apiClient.get(`/leads-managment/leads`);

        this.leads = response.data.data.map((el) => this.formatLeadData(el));
        // this.leads = [this.formatLeadData(response.data.data[1])];
        console.log(this.leads);
      } catch (err) {
        console.error("Error fetching projects:", err);
      }
    },
    formatLeadData(lead) {
      return {
        id: lead.id,
        campaignTime: lead.lead_genration_time,
        marketingCampaign: lead.campaign_name,
        platform: lead.platform,
        firstName: lead.first_name,
        lastName: lead.last_name,
        phoneNumber: lead.phone,
        status: lead.status,
        reachability: lead.reachablitiy,
        leadsProgressCol: "",
        comments: "",
        assignee: [
          {
            name: "John",
            img: "https://s3-alpha-sig.figma.com/img/c540/a95a/e358f6cea9537602edc7966d50e009f7?Expires=1736121600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=hvK5sAs0ShqbIiRbf5Cn2zVUX~iW9gRttw5BEVGSb8DHa1scEpXZhh0HLrbTKaEEtOBz3KOqMthnMm5nikGZ8ct1~BQmwpCVeWQRHLaT1DoZe9UWcZcAW8p0mQZ7bT9KDN4UZOfgVK4Dr~4aRhz9fwTbruJYtVTbZcqqdiSfK42~mvbwUwYIEvOPrc-kFijt3lh5yJjcoUxrprD1-vkfpu9FHeTylc3R97bNiQo16oWGHLUNiV1xznhrBplNjakDI-4JLQyaduh6YfzeS741dgHUwv8yIA9YzOODe0ZXigEsedii9oWivQHiQxppBezi9ZCPVkSu0odYcCp61ZpO3w__",
          },
          {
            name: "Emily",
            img: "https://s3-alpha-sig.figma.com/img/c540/a95a/e358f6cea9537602edc7966d50e009f7?Expires=1734912000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=qRVgkSQHmcLmGwShpGuLm-mlroh526rz~6Ol5W4~jR8oFjs~M84y9rMd-VMh1pxhoBrwmaubuyUOSxbPt6ZGgyUHD8fKLlAFlb~lDkI5pXptmieIaI0nC7tRXNFwhKzLqKHgNSaNljbf6FwR7EgkXs1wJywczyJbNnzzFmyeF8Wdoj0jf0lY5RUuQIbBJIMO3kMC6JSoK3YTr0OlAu~qZxKjYwfrTm2RIjdwuJ1zfYyO~-IaMP9uioJs0pVEvWSubuCBVfatTaGRLXiEas5tzwFqN0cNxTtkU1GdtxU8GWXo74v6svww2jPZROAhyQmHvyiI3xCbp0binV4HH5a8Rw__",
          },
        ],
        touchedStatus: lead.status,
        reachedStatus: lead.progress_status,
        bookedInfo: {
          doctorName: lead.booking?.doctor_name,
          departmentName: lead.booking?.department,
          service: lead.booking?.service,
          consultationDate: lead.booking?.appointment_date,
        },
        attendanceStatus: lead.booking?.status,
        survey: [],
        activities: [],
        updates: [],
        files: [],
        trails: {
          "1st_trail": lead.call?.["1st_try"],
          "2nd_trail": lead.call?.["2st_try"],
          "3rd_trail": lead.call?.["3st_try"],
        },
        haveTrails: lead.call ? true : false,
        created_at: "2023-06-01T12:00:00.000Z",
        updated_at: "2023-06-01T12:00:00.000Z",
      };
    },
    updateReachabilityStatus(id, val) {
      const index = this.leads.findIndex((item) => item.id === id);
      if (index !== -1) {
        this.leads[index].reachability = val;
      }
    },
  },
});
