import { defineStore } from "pinia";
import apiClient from "@/core/buildRequest";

export const useProjectsStore = defineStore("useProjectsStore", {
  state: () => ({
    projects: [],
    headers: [
      {
        title: "Project",
        name: "Project",
        align: "start",
        key: "project",
        menu: true,
        show: true,
        sortable: true,
      },
      {
        title: "Project Name",
        name: "Project Name",
        align: "start",
        show: true,
        menu: true,
        key: "name",
        sortable: true,
      },
      {
        title: "Assigness",
        name: "Assigness",
        align: "start",
        show: true,
        key: "members",
        menu: true,
      },
      {
        title: "Project Description",
        name: "Project Description",
        align: "start",
        show: true,
        menu: true,
        key: "description",
      },
      {
        title: "Status",
        name: "Status",
        align: "start",
        show: true,
        menu: true,
        key: "status",
      },
      {
        title: "Contacts",
        name: "Contacts",
        align: "start",
        show: true,
        menu: true,
        key: "contacts",
      },
      {
        title: "Project",
        name: "Project",
        align: "start",
        show: true,
        menu: true,
        key: "account",
      },
      {
        title: "Added Time",
        name: "Added Time",
        align: "start",
        show: true,
        menu: true,
        key: "added_date",
      },
      {
        title: "Due Date",
        name: "Due Date",
        align: "start",
        show: true,
        menu: true,
        key: "due_date",
      },
      {
        title: "Add To Calendar",
        name: "Add To Calendar",
        align: "start",
        show: true,
        menu: true,
        key: "add_to_calendar",
      },
      {
        title: "Project Type",
        name: "Project Type",
        align: "start",
        show: true,
        menu: true,
        key: "type",
      },
      {
        title: "Location",
        name: "Location",
        align: "start",
        show: true,
        menu: true,
        key: "location",
      },
      {
        title: "Value",
        name: "Value",
        align: "start",
        show: true,
        menu: true,
        key: "value",
      },
      {
        title: "Currency",
        name: "Currency",
        align: "start",
        show: true,
        menu: true,
        key: "currency",
      },
      {
        title: "Probability",
        name: "Probability",
        align: "start",
        show: true,
        menu: true,
        key: "probability",
      },
      {
        title: "Challenges",
        name: "Challenges",
        align: "start",
        show: true,
        menu: true,
        key: "challenges",
      },
      {
        title: "Latest Feedback",
        name: "Latest Feedback",
        align: "start",
        show: true,
        menu: true,
        key: "feedback",
      },
      {
        title: "Comment",
        name: "Comment",
        align: "start",
        show: true,
        menu: true,
        key: "comment",
      },
      {
        title: "Actions",
        name: "Actions",
        align: "center",
        sortable: false,
        key: "actions",
        show: true,
        menu: true,
      },
    ],
    items_per_page: 5,
    current_page: 1,
    total_projects: 0,
    total_pages: 0,
    hasNextPage: false,
    hasPreviousPage: false,
  }),
  getters: {
    getProjects: (state) => state.projects,
    getHeaders: (state) => state.headers.filter((header) => header.show),
    getPagination: (state) => ({
      currentPage: state.current_page,
      totalPages: state.total_pages,
      itemsPerPage: state.items_per_page,
      totalProjects: state.total_projects,
    }),
    isLoading: (state) => state.loading,
  },
  actions: {
    async getAllProjects() {
      try {
        console.log(
          `/leads-managment/projects?page=${this.current_page}&per_page=${this.items_per_page}`
        );
        var response = await apiClient.get(
          `/leads-managment/projects?page=${this.current_page}&per_page=${this.items_per_page}`
        );
        let { data, pagination } = response.data.data;
        this.projects = data;
        this.current_page = pagination.current_page;
        this.total_projects = pagination.total_items;
        this.total_pages = pagination.total_pages;
        this.hasNextPage = pagination.next_page_url !== "" ? true : false;
        this.hasPreviousPage = pagination.perv_page_url !== "" ? true : false;
      } catch (err) {
        console.error("Error fetching projects:", err);
      }
    },
    hideColumnFromHeader(value) {
      this.headers.map((item) => {
        item.show = !value;
      });
    },

    onPageChange() {
      return this.getAllProjects();
    },
    onItemsPerPageChange() {
      this.current_page = 1;
      return this.getAllProjects();
    },
    previousPage() {
      this.current_page -= 1;
      return this.getAllProjects();
    },
    nextPage() {
      this.current_page += 1;
      return this.getAllProjects();
    },
    pushProjects(projects) {
      this.projects.unshift(projects);
    },
    updateProject(projects) {
      const index = this.projects.findIndex((item) => item.id === projects.id);
      if (index !== -1) {
        this.projects.splice(index, 1, projects);
      }
    },
    deleteProject(projects) {
      const index = this.projects.findIndex((item) => item.id === projects.id);
      if (index !== -1) {
        this.projects.splice(index, 1);
      }
    },
    updateStatus(id, potentiality) {
      const index = this.projects.findIndex((item) => item.id === id);
      if (index !== -1) {
        this.projects[index].status = potentiality;
      }
    },
    updateAddToCalender(id, value) {
      const index = this.projects.findIndex((item) => item.id === id);
      if (index !== -1) {
        this.projects[index].add_to_calendar = value;
      }
    },
  },
});
