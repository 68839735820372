<template>
  <div class="card-header d-flex justify-space-between">
    <div class="card-header-left">
      <div class="user-img">
        <img
          src="@/assets/soft-ware-img/121310ea7ca9dce86458f05a866fc337.jpeg"
          alt="Gallery Image"
          class="gallery-image"
        />
      </div>
      <div class="user-name">
        <p>caroline.fitness</p>
        <span>New York City</span>
      </div>
    </div>
    <div class="card-header-right">
      <span>
        <svg
          width="4"
          height="16"
          viewBox="0 0 4 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.00014 3.17899C2.79898 3.17899 3.44657 2.5314 3.44657 1.73256C3.44657 0.933721 2.79898 0.286133 2.00014 0.286133C1.2013 0.286133 0.553711 0.933721 0.553711 1.73256C0.553711 2.5314 1.2013 3.17899 2.00014 3.17899ZM3.44657 8.20705C3.44657 9.00589 2.79898 9.65347 2.00014 9.65347C1.2013 9.65347 0.553711 9.00589 0.553711 8.20705C0.553711 7.40821 1.2013 6.76062 2.00014 6.76062C2.79898 6.76062 3.44657 7.40821 3.44657 8.20705ZM3.44657 14.2683C3.44657 15.0671 2.79898 15.7147 2.00014 15.7147C1.2013 15.7147 0.553711 15.0671 0.553711 14.2683C0.553711 13.4694 1.2013 12.8218 2.00014 12.8218C2.79898 12.8218 3.44657 13.4694 3.44657 14.2683Z"
            fill="#030303"
          />
        </svg>
      </span>
    </div>
  </div>
  <div class="card-body">
    <img
      class="gallery-image"
      v-if="
        post.file.file &&
        post.file.file.type &&
        post.file.file.type.startsWith('image/')
      "
      :src="post.file.preview"
      alt="Post Image"
    />
    <video
      v-if="
        post.file.file &&
        post.file.file.type &&
        post.file.file.type.startsWith('video/')
      "
      width="100%"
      :src="post.file.preview"
      autoplay
      muted
      loop
      class="gallery-image"
    ></video>
  </div>
  <div class="card-footer">
    <div class="actions">
      <span
        ><svg
          width="28"
          height="27"
          viewBox="0 0 28 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.7786 3.56728C20.5824 3.0575 23.3934 4.42239 24.7267 6.94096L24.9922 7.44249C26.5414 10.3688 25.9314 13.9708 23.5051 16.2238L20.092 19.3932L15.2034 23.3929C14.5035 23.9656 13.497 23.9656 12.7971 23.3929L7.92044 19.4029L4.7042 16.6155C1.90294 14.1878 1.3173 10.0678 3.33107 6.95557L3.54003 6.63263C5.07121 4.26627 7.89228 3.07505 10.6561 3.62781C11.4958 3.79576 12.2671 4.20852 12.8726 4.81407L14.0002 5.94167L14.8294 5.11251C15.6324 4.30948 16.6612 3.77043 17.7786 3.56728ZM23.1359 7.78316C22.1696 5.95795 20.1325 4.96881 18.1006 5.33825C17.3435 5.4759 16.6463 5.84117 16.1022 6.38531L14.0002 8.48725L11.5998 6.08686C11.2456 5.73259 10.7944 5.49111 10.3031 5.39285C8.26332 4.9849 6.1813 5.86405 5.05126 7.61049L4.8423 7.93342C3.31594 10.2923 3.75983 13.4151 5.88307 15.2553L9.08003 18.026L13.9369 21.9998C13.9737 22.0299 14.0267 22.0299 14.0636 21.9998L18.9085 18.0358L22.2803 14.9048C24.1095 13.2063 24.5693 10.4908 23.4014 8.28469L23.1359 7.78316Z"
            fill="#030303"
          />
        </svg>
      </span>
      <span>
        <svg
          width="21"
          height="23"
          viewBox="0 0 22 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.925 11.6975C20.925 13.4755 20.4805 15.1471 19.6991 16.6024C19.2589 17.4221 21.4232 21.8951 20.925 22.405C20.2942 23.0636 16.7289 20.0659 15.9438 20.5308C14.4784 21.3985 12.7771 21.8951 10.9625 21.8951C5.46037 21.8951 1 17.3295 1 11.6975C1 6.0656 5.46037 1.5 10.9625 1.5C16.4647 1.5 20.925 6.0656 20.925 11.6975Z"
            stroke="#030303"
            stroke-width="1.8"
          />
        </svg>
      </span>
      <span>
        <svg
          width="28"
          height="24"
          viewBox="0 0 28 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.3333 11.5L13.6525 21.9365C13.7778 22.5 14.527 22.6216 14.8241 22.1266L25.4194 4.46759C25.6749 4.04176 25.3682 3.5 24.8716 3.5H3.72708C3.13393 3.5 2.86095 4.23795 3.3113 4.62397L11.3333 11.5ZM11.3333 11.5L24.6667 4.16667"
            stroke="#030303"
            stroke-width="1.8"
          />
        </svg>
      </span>
    </div>
    <div class="book-mark">
      <span>
        <svg
          width="28"
          height="27"
          viewBox="0 0 28 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.2204 16.3477L13.2204 16.3477L5.96387 22.2293V4.5H22.0353V13.9821V22.2293L14.7788 16.3477C14.3246 15.9795 13.6746 15.9795 13.2204 16.3477Z"
            stroke="#030303"
            stroke-width="2.25"
          />
        </svg>
      </span>
    </div>
  </div>
  <div class="card-caption">
    <p>
      <span> monica.2839 </span>
      Have you ever wondered how many hours you spend in your car?
      <span class="hashtag"> 🚗 #trip </span>
    </p>
  </div>
</template>
<script>
import { publishPost } from "@/store/publishPost/publishPost.js";
export default {
  name: "InstgramPostReview",
  components: {},
  data() {
    return {
      store: publishPost(),
      post: {},
    };
  },
  watch: {
    "store.getCarouselSlider": {
      handler() {
        this.post = this.store.post["instagram"].find(
          (item) => item.type === "post"
        );
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
video {
  object-fit: cover;
}
</style>