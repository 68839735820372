<template>
  <div class="card-pattern campaign-details-card">
    <div class="card-header mb-4">
      <label for="" class="">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#007e59"
        >
          <path
            d="m423.23-309.85 268.92-268.92L650-620.92 423.23-394.15l-114-114L267.08-466l156.15 156.15ZM480.07-100q-78.84 0-148.21-29.92t-120.68-81.21q-51.31-51.29-81.25-120.63Q100-401.1 100-479.93q0-78.84 29.92-148.21t81.21-120.68q51.29-51.31 120.63-81.25Q401.1-860 479.93-860q78.84 0 148.21 29.92t120.68 81.21q51.31 51.29 81.25 120.63Q860-558.9 860-480.07q0 78.84-29.92 148.21t-81.21 120.68q-51.29 51.31-120.63 81.25Q558.9-100 480.07-100Zm-.07-60q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
          />
        </svg>
        <span class="roboto-black">Campaign details</span>
      </label>
    </div>
    <div class="card-body">
      <SelectedObject />
      <button
        @click="toggleCampaignSpendingLimit"
        class="add-compaign-budget mt-3"
      >
        <span v-if="!campaign_spending_limit">Show more options</span>
        <svg
          v-if="!campaign_spending_limit"
          xmlns="http://www.w3.org/2000/svg"
          height="20px"
          viewBox="0 -960 960 960"
          width="20px"
          fill="#0a7abf"
        >
          <path d="M480-397.85 311.85-566h336.3L480-397.85Z" />
        </svg>
        <span v-if="campaign_spending_limit">Hide options</span>
        <svg
          v-if="campaign_spending_limit"
          xmlns="http://www.w3.org/2000/svg"
          height="20px"
          viewBox="0 -960 960 960"
          width="20px"
          fill="#009995"
        >
          <path d="M311.85-394 480-562.15 648.15-394h-336.3Z" />
        </svg>
      </button>
      <div class="campaign-spending-limit" v-if="campaign_spending_limit">
        <h6 class="mb-1">Campaign spending limit</h6>
        <label class="checkbox">
          <input type="checkbox" v-model="spending_limit" />
          <span class="checkmark"></span>
          <span class="text">Add campaign spending limit</span>
        </label>
        <div class="input-container" ref="input_container" v-if="spending_limit">
          <span class="dollar-sign">$</span>
          <input
            type="number"
            placeholder="No Limit Set"
            name=""
            v-model="setLimitValue"
            id=""
             @input="setLimitValueLocal($event.target.value)" 
            class="input-number-field no-controls"
          />
        </div>
        <div class="spend-review mt-1" v-if="spending_limit">
          <span v-if="validation">Campaign spending limit too low: Your campaign spending limit must be at least US$ ١٠٠٫٠٠ for this currency.</span>
          <p> $0.00 of ${{ setLimitValue }} limit Spent </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectedObject from "@/components/meta/selected-objective/SelectedObject.vue";

export default {
  components: {
    SelectedObject,
  },
  data() {
    return {
      campaign_spending_limit: false,
      spending_limit: false,
      setLimitValue: 0.00,
      validation: false,
    };
  },
  methods: {
    toggleCampaignSpendingLimit() {
      this.campaign_spending_limit = !this.campaign_spending_limit;
    },
    setLimitValueLocal(value) {
      this.setLimitValue = value
      if(this.setLimitValue < 100){
        this.$refs.input_container.style.boxShadow = "0 0 0 2px #f7d7d8 inset";
        this.$refs.input_container.style.border = "1px solid #db373c";
        this.validation = true;
      }else{
        localStorage.setItem("setLimitValue", this.setLimitValue);
        this.validation = false;
        this.$refs.input_container.style.boxShadow = " 0 0 0 2px #cee4f2 inset";
        this.$refs.input_container.style.border = "1px solid #0a7abf";
      }
    }
  },
};
</script>
