<template>
  <div
    class="leads-managements-link d-flex align-center justify-space-between px-3 py-2 mt-3"
  >
    <div class="link cursor-pointer hoverd-style-line d-flex align-center ga-2">
      <span>Latest Folders</span>
    </div>
    <div
      class="see-all cursor-pointer"
      @click="$router.push({ name: 'DriveCenterAllFolders' })"
    >
      See All
    </div>
  </div>

  <v-row class="folders">
    <v-col
      cols="6"
      :lg="3"
      v-for="(folder, index) in store.getFolders.slice(0, 7)"
      :key="index"
    >
      <v-sheet class="ma-2 pa-4 folder-box d-flex flex-column ga-4 h-100">
        <FolderBox :folder="folder" />
      </v-sheet>
    </v-col>
    <v-col cols="6" :lg="3">
      <v-sheet
        class="ma-2 pa-4 folder-box new-folder-box d-flex flex-column ga-4 h-100 cursor-pointer"
        @click="$emit('open:NewFolder', true)"
      >
        <div class="d-flex flex-column ga-4 align-center justify-center h-100">
          <svg
            width="41"
            height="33"
            viewBox="0 0 41 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.33594 32.5C3.23594 32.5 2.2946 32.1087 1.51194 31.326C0.727938 30.542 0.335938 29.6 0.335938 28.5V4.5C0.335938 3.4 0.727938 2.45867 1.51194 1.676C2.2946 0.892 3.23594 0.5 4.33594 0.5H14.6859C15.2193 0.5 15.7279 0.6 16.2119 0.8C16.6946 1 17.1193 1.28333 17.4859 1.65L20.3359 4.5H36.3359C37.4359 4.5 38.3779 4.892 39.1619 5.676C39.9446 6.45867 40.3359 7.4 40.3359 8.5V28.5C40.3359 29.6 39.9446 30.542 39.1619 31.326C38.3779 32.1087 37.4359 32.5 36.3359 32.5H4.33594Z"
              fill="#cccccc"
            />
          </svg>
          <span>Create New Folder</span>
        </div>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import { DriveStore } from "@/store/drive/DriveStore";
import FolderBox from "./boxs/FolderBox.vue";
export default {
  name: "LatestFolder",
  props: {},
  components: { FolderBox },

  data() {
    return {
      store: null,
    };
  },
  watch: {},
  computed: {},

  methods: {
    formatDate(date) {
      return format(new Date(date), "MMM dd, yyyy HH:mm");
    },
  },

  created() {
    this.store = DriveStore();
  },
  mounted() {},
};
</script>
