<template>
  <div class="leads-management-filter">
    <v-row class="ma-0 pa-0">
      <v-col cols="12" :lg="3">
        <Input
          v-model="search"
          type="search"
          :icon="SearchIcon"
          placeholder="Search here..."
          @input="handleInput"
        />
      </v-col>
      <v-col class="d-flex ga-3">
        <AdvancedFilter />
        <GroupBy />
      </v-col>
      <v-col cols="12" class="d-flex justify-end">
        <button
          @click="$router.push({ name: 'LeadsmanagementDashboard' })"
          class="d-flex align-center ga-3 dashoard-btn"
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.666 14.6668H10.666C9.53268 14.6668 8.66602 13.8002 8.66602 12.6668V3.3335C8.66602 2.20016 9.53268 1.3335 10.666 1.3335H12.666C13.7993 1.3335 14.666 2.20016 14.666 3.3335V12.6668C14.666 13.8002 13.7993 14.6668 12.666 14.6668ZM10.666 2.66683C10.266 2.66683 9.99935 2.9335 9.99935 3.3335V12.6668C9.99935 13.0668 10.266 13.3335 10.666 13.3335H12.666C13.066 13.3335 13.3327 13.0668 13.3327 12.6668V3.3335C13.3327 2.9335 13.066 2.66683 12.666 2.66683H10.666Z"
              fill="white"
            />
            <path
              d="M5.33398 14.6665H3.33398C2.20065 14.6665 1.33398 13.7998 1.33398 12.6665V8.6665C1.33398 7.53317 2.20065 6.6665 3.33398 6.6665H5.33398C6.46732 6.6665 7.33398 7.53317 7.33398 8.6665V12.6665C7.33398 13.7998 6.46732 14.6665 5.33398 14.6665ZM3.33398 7.99984C2.93398 7.99984 2.66732 8.2665 2.66732 8.6665V12.6665C2.66732 13.0665 2.93398 13.3332 3.33398 13.3332H5.33398C5.73398 13.3332 6.00065 13.0665 6.00065 12.6665V8.6665C6.00065 8.2665 5.73398 7.99984 5.33398 7.99984H3.33398Z"
              fill="white"
            />
            <path
              d="M5.33398 5.3335H3.33398C2.20065 5.3335 1.33398 4.46683 1.33398 3.3335C1.33398 2.20016 2.20065 1.3335 3.33398 1.3335H5.33398C6.46732 1.3335 7.33398 2.20016 7.33398 3.3335C7.33398 4.46683 6.46732 5.3335 5.33398 5.3335ZM3.33398 2.66683C2.93398 2.66683 2.66732 2.9335 2.66732 3.3335C2.66732 3.7335 2.93398 4.00016 3.33398 4.00016H5.33398C5.73398 4.00016 6.00065 3.7335 6.00065 3.3335C6.00065 2.9335 5.73398 2.66683 5.33398 2.66683H3.33398Z"
              fill="white"
            />
          </svg>

          <span>Dashboard</span>
        </button>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Input from "@/components/crm/assets/Input.vue";
import SearchIcon from "@/assets/images/icons/search.svg";
import AdvancedFilter from "@/components/crm/leads/advancedFilter.vue";
import GroupBy from "@/components/crm/leads/GroupBy.vue";

export default {
  name: "FilterSection",
  components: {
    Input,
    AdvancedFilter,
    GroupBy,
  },
  data() {
    return {
      search: "",
      SearchIcon: SearchIcon,
    };
  },
  methods: {
    handleInput() {
    },
  },
};
</script>
