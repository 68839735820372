<template>
  <div class="search-upload d-flex align-center gap-3 mb-4">
    <input
      type="search"
      v-model="searchQuery"
      placeholder="Search images..."
      class="flex-1 px-4 py-2 border rounded"
      @input="$emit('search', searchQuery)"
    />
    <label
      class="upload-btn d-flex align-center gap-2 px-4 py-2 rounded cursor-pointer"
    >
      <input
        type="file"
        ref="fileInput"
        accept="image/*"
        class="d-none"
        @change="handleFileUpload"
      />
      <span>Upload</span>
    </label>
  </div>
</template>

<script>
export default {
  name: "MediaSelection",
  emits: ["search", "upload"],
  data() {
    return {
      searchQuery: "",
    };
  },
  methods: {
    async handleFileUpload(event) {
      const file = event.target.files[0];
      if (!file) return;

      if (!file.type.startsWith("image/")) {
        alert("Please upload an image file");
        return;
      }
      const maxSize = 5 * 1024 * 1024;
      if (file.size > maxSize) {
        alert("File size should not exceed 5MB");
        return;
      }
      const base64 = await this.fileToBase64(file);
      const newImage = {
        id: Date.now(),
        url: base64,
        type: "account",
      };
      this.$emit("upload", newImage);
      this.$refs.fileInput.value = "";
    },
    fileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
  },
};
</script>

<style scoped>
.search-upload .upload-btn {
  border: 1px solid #a6b3bf;
}
</style>
