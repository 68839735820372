/**
 * Validates if the provided string is a valid URL
 * @param {string} url - The URL to validate
 * @returns {boolean} - Returns true if URL is valid, false otherwise
 */
export function validateUrl(url) {
    if (!url) return false;
    
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  }