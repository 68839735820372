import DriveIndex from "@/views/Drive/index.vue";
import DriveAllVideos from "@/views/Drive/AllVideos.vue";
import DriveAllImages from "@/views/Drive/AllImages.vue";
import DriveAllFolders from "@/views/Drive/AllFolders.vue";
import AllFolderFiles from "@/views/Drive/FolderFiles.vue";
const DriveRoutes = [
  {
    path: "/drive-center",
    name: "DriveCenter",
    component: DriveIndex,
    meta: {
      title: "Drive Center",
      description: "",
    },
  },
  {
    path: "/drive-center/all-videos",
    name: "DriveCenterAllVideos",
    component: DriveAllVideos,
    meta: {
      title: "Drive Center All Videos",
      description: "",
    },
  },
  {
    path: "/drive-center/all-images",
    name: "DriveCenterAllImages",
    component: DriveAllImages,
    meta: {
      title: "Drive Center All Images",
      description: "",
    },
  },
  {
    path: "/drive-center/all-Folders",
    name: "DriveCenterAllFolders",
    component: DriveAllFolders,
    meta: {
      title: "Drive Center All Folders",
      description: "",
    },
  },
  {
    path: "/drive-center/folder/:folderId",
    name: "AllFolderFiles",
    component: AllFolderFiles,
    meta: {
      title: "Drive Center All Folder Files",
      description: "",
    },
  },
];

export default DriveRoutes;
