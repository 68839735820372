<template>
  <v-container
    fluid
    style="margin-top: 0%; padding: 0% 0% 0 0; min-height: calc(100vh - 58px)"
  >
    <v-row style="height: 100%; margin: 0; padding: 0; position: relative">
      <v-col cols="12" class="pa-0">
        <GlobalNav />
      </v-col>
      <v-col
        cols="12"
        style="height: 100%"
        class="pa-0 d-flex align-start ga-6"
      >
        <SideBar />
        <div class="ad-performance-contant">
          <BreadCrumb :linksData="linksData" class="mb-5" />
          <div class="result-last-days">
            <h4 class="mb-3">
              {{ title_date }}
            </h4>
            <v-container fluid class="py-0 px-1">
              <v-row class="w-100 pa-0 ma-0">
                <v-col
                  cols="11"
                  xl="12"
                  md="12"
                  sm="12"
                  class="results-card ga-8 pa-0"
                >
                  <v-card class="card rounded-xl px-7 py-12">
                    <div class="compaigns-result d-flex align-center">
                      <div class="icon-contain">
                        <img
                          src="@/assets/soft-ware-img/ads-performance-campaign.svg"
                          alt=""
                        />
                      </div>
                      <div class="result-contain">
                        <p class="number">
                          {{ formatNumber(campaignNumber) }}
                        </p>
                        <p class="title">Compaigns</p>
                      </div>
                    </div>
                    <div class="compaigns-result d-flex align-center">
                      <div class="icon-contain">
                        <img
                          src="@/assets/soft-ware-img/amount-icon-dollar.svg"
                          alt=""
                        />
                      </div>
                      <div class="result-contain">
                        <p class="number">{{ formatNumber(amountSpent) }}$</p>
                        <p class="title">Amount Spent</p>
                      </div>
                    </div>
                    <div class="compaigns-result d-flex align-center">
                      <div class="icon-contain">
                        <img
                          src="@/assets/soft-ware-img/impressions-icon.svg"
                          alt=""
                        />
                      </div>
                      <div class="result-contain">
                        <p class="number">{{ formatNumber(impressions) }}$</p>
                        <p class="title">Impressions</p>
                      </div>
                    </div>
                    <div class="compaigns-result d-flex align-center">
                      <div class="icon-contain">
                        <img
                          src="@/assets/soft-ware-img/leads-ad-performance-icon.svg"
                          alt=""
                        />
                      </div>
                      <div class="result-contain">
                        <p class="number">
                          {{ formatNumber(leads) }}
                        </p>
                        <p class="title">Leads</p>
                      </div>
                    </div>
                    <div class="compaigns-result d-flex align-center">
                      <div class="icon-contain">
                        <img
                          src="@/assets/soft-ware-img/click-icon.svg"
                          alt=""
                        />
                      </div>
                      <div class="result-contain">
                        <p class="number">{{ formatNumber(clicks) }}</p>
                        <p class="title">Click</p>
                      </div>
                    </div>
                  </v-card>
                  <div class="actions-on-performance">
                    <button
                      @click="dialog = true"
                      class="add-campaign-btn d-flex align-center ga-1"
                    >
                      <span class="d-flex">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="20px"
                          viewBox="0 -960 960 960"
                          width="20px"
                          fill="#fff"
                        >
                          <path
                            d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"
                          />
                        </svg>
                      </span>
                      <span>Add Compaign</span>
                    </button>
                    <v-dialog
                      v-model="dialog"
                      width="auto"
                      class="add-campaign-modal"
                    >
                      <v-card
                        min-width="600"
                        class="dialog-card pa-4 rounded-xl"
                      >
                        <div
                          class="card-title d-flex justify-space-between align-center"
                        >
                          <p>Select Social Media Platform</p>
                          <img
                            @click="dialog = false"
                            src="@/assets/soft-ware-img/X circle.svg"
                            alt=""
                          />
                        </div>
                        <v-card-text>
                          <div>
                            <router-link
                              to="/create-new-camp"
                              style="border: 1px solid #1094f4"
                            >
                              <span>
                                <img
                                  src="@/assets/soft-ware-img/facebook-icon-adcampaign.svg"
                                  alt=""
                                />
                              </span>
                              <span> Facebook </span>
                            </router-link>
                            <router-link
                              to="/create-new-camp"
                              style="border: 0.6px solid #b731a0"
                            >
                              <span>
                                <img
                                  src="@/assets/soft-ware-img/instgrapm.svg"
                                  alt=""
                                />
                              </span>

                              <span> Instagram </span>
                            </router-link>
                            <router-link
                              to="/create-new-camp"
                              style="border: 1px solid #fffa37"
                            >
                              <span>
                                <img
                                  src="@/assets/soft-ware-img/snapchat-icoon-ccampaign.svg"
                                  alt=""
                                />
                              </span>
                              <span> Snapchat </span>
                            </router-link>
                          </div>
                          <div>
                            <router-link
                              to="/create-new-camp"
                              style="border: 1px solid #1275b1"
                            >
                              <span>
                                <img
                                  src="@/assets/soft-ware-img/linkedin-icon.svg"
                                  alt=""
                                />
                              </span>
                              <span> Linked In </span>
                            </router-link>

                            <router-link
                              to="/create-new-camp"
                              style="border: 1px solid #000000"
                            >
                              <span>
                                <img
                                  src="@/assets/soft-ware-img/tikTopkcampaign.svg"
                                  alt=""
                                />
                              </span>
                              <span> Tik Tok </span>
                            </router-link>

                            <router-link
                              to="/create-new-camp"
                              style="border: 1px solid #000000"
                            >
                              <span>
                                <img
                                  src="@/assets/soft-ware-img/x-twitter-icons.svg"
                                  alt=""
                                />
                              </span>
                              <span> X (Twitter) </span>
                            </router-link>
                          </div>
                        </v-card-text>
                        <div class="card-footer">
                          <span>*You can choose multiple platform</span>

                          <button>Add Compaign</button>
                        </div>
                      </v-card>
                    </v-dialog>
                    <button
                      @click="adsReportStatusFunc"
                      class="ads-report-btn d-flex align-center ga-2"
                    >
                      <span class="d-flex">
                        <img
                          src="@/assets/soft-ware-img/ads-report-icon.svg"
                          alt=""
                        />
                      </span>
                      <span>Ads Report</span>
                    </button>
                    <button
                      class="export-btn d-flex align-center ga-2"
                      @click="exportTableToExcel"
                    >
                      <span class="d-flex">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24px"
                          viewBox="0 -960 960 960"
                          width="24px"
                          fill="rgba(35, 114, 118, 1)"
                        >
                          <path
                            d="M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z"
                          />
                        </svg>
                      </span>
                      <span>Export File</span>
                    </button>
                  </div>
                </v-col>
                <v-col
                  cols="8"
                  xl="11"
                  md="12"
                  sm="12"
                  class="d-flex my-5 align-center justify-space-between flex-wrap"
                >
                  <div
                    v-if="hideFilter"
                    class="filter-contain d-flex align-center ga-5"
                  >
                    <div class="select-date">
                      <label for="">Date</label>

                      <v-date-input
                        prepend-icon=""
                        prepend-inner-icon="$calendar"
                        v-model="selectedDate"
                        multiple="range"
                        variant="solo"
                        elevation="0"
                      ></v-date-input>
                    </div>
                    <div class="select-status">
                      <label for="" class="d-block mb-2">Status</label>
                      <v-select
                        v-model="selectedStatus"
                        :items="statusItems"
                        item-text="text"
                        item-value="value"
                        label="Select"
                        persistent-hint
                        return-object
                        single-line
                        variant="solo"
                        elevation="0"
                      ></v-select>
                    </div>
                    <div
                      class="select-status"
                      style="display: flex; align-items: end; gap: 22px"
                    >
                      <div class="select-status">
                        <label for="" class="d-block mb-2">Platform</label>
                        <v-select
                          v-model="selectedPlatform"
                          :items="platformItems"
                          item-title="platform"
                          item-value="platform"
                          label="Select Platform"
                          persistent-hint
                          return-object
                          single-line
                          variant="solo"
                          elevation="0"
                        ></v-select>
                      </div>
                      <div class="actions-on-performance">
                        <button
                          @click="ApplyFilter"
                          class="add-campaign-btn d-flex align-center ga-1"
                        >
                          <span class="d-flex">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="20px"
                              viewBox="0 -960 960 960"
                              width="20px"
                              fill="#fff"
                            >
                              <path
                                d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"
                              />
                            </svg>
                          </span>
                          <span>Apply</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="hide-filter">
                    <button
                      @click="toggleFilter"
                      class="d-flex align-center ga-2"
                    >
                      <span
                        ><img
                          src="@/assets/soft-ware-img/filter-icon-performance.svg"
                          alt=""
                      /></span>
                      <span>
                        <span v-if="hideFilter">Reset & hide</span>
                        <span v-else>Show</span> Filters</span
                      >
                    </button>
                  </div>
                </v-col>
                <v-col cols="12">
                  <div class="table-contain">
                    <table class="w-100" id="data-table" v-if="adsReportStatus">
                      <thead>
                        <tr>
                          <th
                            style="border-radius: 8px 0 0 0"
                            class="d-flex name-contain align-center ga-2"
                          >
                            <div class="checkbox-wrapper-46">
                              <input
                                @change="toggleAllCheckboxes"
                                type="checkbox"
                                id="check-all-name"
                                class="inp-cbx"
                                v-model="checkAll"
                              />
                              <label for="check-all-name" class="cbx"
                                ><span>
                                  <svg
                                    viewBox="0 0 12 10"
                                    height="9px"
                                    width="10px"
                                  >
                                    <polyline
                                      points="1.5 6 4.5 9 10.5 1"
                                    ></polyline></svg></span
                                ><span>Name</span>
                              </label>
                            </div>
                          </th>
                          <th>status</th>
                          <th>Delivery Status</th>
                          <th>Amount Spent</th>
                          <th>Result</th>
                          <th>Cost per Result</th>
                          <th>
                            <span class="d-block">Paid Immpressions</span>
                          </th>
                          <th>Paid eCPM</th>
                          <th>Clicks</th>
                          <th>CPC</th>
                          <th style="border-radius: 0 8px 0 0">Action</th>
                        </tr>
                      </thead>
                      <tbody v-if="loadingFortable">
                        <tr v-for="i in 10" :key="i">
                          <td>
                            <v-skeleton-loader type="text"></v-skeleton-loader>
                          </td>
                          <td>
                            <v-skeleton-loader type="text"></v-skeleton-loader>
                          </td>
                          <td>
                            <v-skeleton-loader type="text"></v-skeleton-loader>
                          </td>
                          <td>
                            <v-skeleton-loader type="text"></v-skeleton-loader>
                          </td>
                          <td>
                            <v-skeleton-loader type="text"></v-skeleton-loader>
                          </td>
                          <td>
                            <v-skeleton-loader type="text"></v-skeleton-loader>
                          </td>
                          <td>
                            <v-skeleton-loader type="text"></v-skeleton-loader>
                          </td>
                          <td>
                            <v-skeleton-loader type="text"></v-skeleton-loader>
                          </td>
                          <td>
                            <v-skeleton-loader type="text"></v-skeleton-loader>
                          </td>
                          <td>
                            <v-skeleton-loader type="text"></v-skeleton-loader>
                          </td>
                          <td>
                            <v-skeleton-loader type="text"></v-skeleton-loader>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr v-for="row in tableData" :key="row.id">
                          <td>
                            <div class="d-flex align-center ga-2">
                              <div class="checkbox-wrapper-46">
                                <input
                                  :checked="row.checked"
                                  type="checkbox"
                                  :id="row.id"
                                  class="inp-cbx"
                                />
                                <label :for="row.id" class="cbx"
                                  ><span>
                                    <svg
                                      viewBox="0 0 12 10"
                                      height="9px"
                                      width="10px"
                                    >
                                      <polyline
                                        points="1.5 6 4.5 9 10.5 1"
                                      ></polyline>
                                    </svg>
                                  </span>
                                  <span
                                    ><img
                                      style="width: 20px"
                                      src="@/assets/soft-ware-img/snapchat-icoon-ccampaign.svg"
                                      alt=""
                                  /></span>
                                  <span v-tooltip:top="row.name">{{
                                    truncateName(row.name, 15)
                                  }}</span>
                                </label>
                              </div>
                            </div>
                          </td>
                          <td class="switch-status">
                            <input
                              class="switch"
                              type="checkbox"
                              @change="
                                changeStatus(
                                  row.status,
                                  row.id,
                                  row.name,
                                  row.start_time,
                                  row.end_time
                                )
                              "
                              :checked="row.status === 'PAUSED' ? false : true"
                            />
                          </td>
                          <td>
                            <div
                              class="deliviery-status d-flex align-center ga-2"
                            >
                              <span
                                :class="
                                  row.delivery_status &&
                                  row.delivery_status.length > 0
                                    ? 'deleviry-status'
                                    : 'status'
                                "
                              >
                              </span>
                              <span
                                class="d-flex"
                                v-tooltip:top="
                                  row.delivery_status.length === 0
                                    ? 'Not Delivering Compain is Scheduled'
                                    : 'Delivering '
                                "
                              >
                                {{
                                  row.delivery_status.length === 0
                                    ? truncateName(
                                        "Not Delivering Compain is Scheduled",
                                        10
                                      )
                                    : "Delivering"
                                }}
                              </span>
                            </div>
                          </td>
                          <td>
                            <span
                              >{{
                                formatLifetimeSpend(
                                  row.lifetime_spend_cap_micro
                                )
                              }}
                              $</span
                            >
                          </td>

                          <td>
                            <span>
                              {{ row.stats.native_leads }} <br />
                              leads</span
                            >
                          </td>

                          <td>
                            <span
                              >{{
                                (
                                  Math.round(
                                    row.calculations.costPerClick * 100
                                  ) / 100
                                ).toFixed(2)
                              }}$ <br />
                              cost per lead</span
                            >
                          </td>

                          <td>
                            <span>
                              {{
                                new Intl.NumberFormat("en-US").format(
                                  row.stats.impressions / 100
                                )
                              }}
                            </span>
                          </td>

                          <td>
                            <span>
                              <span
                                >$
                                {{
                                  new Intl.NumberFormat("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }).format(row.calculations.eCPM)
                                }}</span
                              >
                            </span>
                          </td>

                          <td>
                            <span>{{ row.stats.swipes }}</span>
                          </td>
                          <td>
                            <span
                              >{{
                                new Intl.NumberFormat("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }).format(row.calculations.eCPC)
                              }}$</span
                            >
                          </td>

                          <td>
                            <div class="table-action">
                              <button
                                @click="
                                  showAnalisyes(
                                    row.id,
                                    row.ad_account_id,
                                    row.platform
                                  )
                                "
                              >
                                <img
                                  src="@/assets/soft-ware-img/see-analisyes.svg"
                                  alt=""
                                />
                              </button>
                              <button>
                                <img
                                  src="@/assets/soft-ware-img/Edit.svg"
                                  alt=""
                                />
                              </button>
                              <button>
                                <img
                                  src="@/assets/soft-ware-img/copy-table-action.svg"
                                  alt=""
                                />
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <tbody
                        class="no-data-found position-relative"
                        v-if="tableData.length === 0 && !loadingFortable"
                      >
                        <tr>
                          <td colspan="11">
                            <img
                              class="no-data-img"
                              src="@/assets/soft-ware-img/no-data.jpg"
                              alt=""
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <VerticalBarChart
                      v-else
                      :chartData="data"
                      :chartOptions="options"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import * as XLSX from 'xlsx/xlsx.mjs';
import GlobalNav from "@/components/layout/GlobalNav.vue";
import SideBar from "@/components/layout/SideBar.vue";
import BreadCrumb from "@/components/layout/BreadCrumb.vue";
import { VDateInput } from "vuetify/labs/VDateInput";
import apiClient from "@/core/buildRequest.js";
import VerticalBarChart from "@/components/VerticalBarChart.vue"; 
import "@/assets/css/style.css";
import * as XLSX from "xlsx";

import { toast } from "vue3-toastify";

export default {
  components: { GlobalNav, SideBar, BreadCrumb, VDateInput, VerticalBarChart },
  data() {
    return {
      campaignNumber: 0,
      adsReportStatus: 1,
      impressions: 0,
      amountSpent: 0,
      leads: 0,
      clicks: 0,
      linksData: [
        { name: "Ads", link: "/" },
        { name: "Ads Performance", link: "" },
      ],
      selectedDate: [],
      start_date: null,
      end_date: null,
      statusItems: ["PAUSED", "ACTIVE", "Completed"],

      platformItems: [
        {
          platform: "Snapchat",
        },
        {
          platform: "Meta",
        },
        {
          platform: "Instagram",
        },
        {
          platform: "X",
        },
        {
          platform: "Tik Tok",
        },
      ],
      hideFilter: true,
      ex11: true,
      ex12: true,
      // Filter values
      selectedStatus: null,
      selectedPlatform: null,

      tableData: [],
      checkAll: false,
      dialog: false,
      campaign_id: [],
      campaign_leads: [],
      startTimeCampign: null,
      endTimeCampaign: null,

      data: {
        labels: [
          "impressionSum",
          "Amount Spent",
          "leadsSum",
          "swipesSum",
          "campaign_sum",
        ],
        datasets: [
          {
            label: "snapchat",
            data: [40, 150, 180, 200, 90],
            backgroundColor: "rgba(65, 135, 139, 1)",
            borderColor: "transparent",
            borderWidth: 1,
            borderRadius: 15,
          },
          {
            label: "Facebook",
            data: [40, 150, 180, 200, 90],

            backgroundColor: "rgba(65, 135, 139, 1)",
            borderColor: "transparent",
            borderWidth: 1,
            borderRadius: 15,
          },
          {
            label: "TikTok",
            data: [40, 150, 180, 200, 90],

            backgroundColor: "rgba(65, 135, 139, 1)",
            borderColor: "transparent",
            borderWidth: 1,
            borderRadius: 15,
          },
          {
            label: "Instagram",
            data: [40, 150, 180, 200, 90],

            backgroundColor: "rgba(65, 135, 139, 1)",
            borderColor: "transparent",
            borderWidth: 1,
            borderRadius: 15,
          },
          {
            label: "X (Twitter)",
            data: [40, 150, 180, 200, 90],

            backgroundColor: "rgba(65, 135, 139, 1)",
            borderColor: "transparent",
            borderWidth: 1,
            borderRadius: 15,
          },
          {
            label: "Google",
            data: [40, 150, 180, 200, 90],

            backgroundColor: "rgba(65, 135, 139, 1)",
            borderColor: "transparent",
            borderWidth: 1,
            borderRadius: 15,
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
      campaignStatus: null,
      campaignId: null,
      campaignName: null,
      loadingFortable: true,
      title_date: "Last 30 Days",
    };
  },

  methods: {
    toggleFilter() {
      this.hideFilter = !this.hideFilter;
      this.selectedDate = [];
      this.selectedStatus = null;
      this.selectedPlatform = null;
      this.getData();
    },
    showAnalisyes(id, ad_account_id, platform) {
      this.$router.push(`/ads-performance/${ad_account_id}/${platform}/${id}`);
    },
    truncateName(name, length) {
      if (!name) return ""; // Handle cases where the name is null or undefined
      return name.length > length ? name.slice(0, length) + "..." : name;
    },
    formatLifetimeSpend(value) {
      if (!value || isNaN(value)) return 0; // Handle undefined or invalid numbers
      return Math.ceil(value / 1_000_000);
    },
    exportTableToExcel() {
      const table = document.getElementById("data-table");
      const workbook = XLSX.utils.table_to_book(table); // Convert the table to a workbook
      const fileName = "table-data.xlsx";
      // Generate the Excel file
      XLSX.writeFile(workbook, fileName);
    },
    toggleAllCheckboxes() {},
    adsReportStatusFunc() {
      this.adsReportStatus = !this.adsReportStatus;
    },
    async changeStatus(
      status,
      id,
      campaignName,
      startTimeCampign,
      endTimeCampaign
    ) {
      console.log(status);

      const requestData = {
        status: String(status) === "PAUSED" ? "ACTIVE" : "PAUSED",
        campaign_id: id,
        name: campaignName,
        start_time: startTimeCampign,
        end_time: endTimeCampaign,
      };
      console.log(requestData);
      await apiClient
        .post(`/snapchat/update/campaign/${id}`, requestData)
        .then(() => {
          toast(`Status Updated Successfully`, {
            type: "success",
          });
        });
    },
    getFirstAndLastDate(dates) {
      if (!dates || !Array.isArray(dates) || dates.length === 0) {
        throw new Error("Invalid dates array");
      }

      const sortedDates = dates.sort((a, b) => new Date(a) - new Date(b));

      const firstDate = new Date(sortedDates[0]);
      const lastDate = new Date(sortedDates[sortedDates.length - 1]);

      const formatDate = (date) => date.toISOString().split("T")[0];

      return {
        firstDate: formatDate(firstDate),
        lastDate: formatDate(lastDate),
      };
    },
    async getData() {
      this.loadingFortable = true;
      this.clicks = 0;
      this.leads = 0;
      this.campaignNumber = 0;
      this.amountSpent = 0;
      this.impressions = 0;
      let requestData = {
        advertiser_ids: [], //from api
        bc_id: null, // first in api
        report_type: "BASIC",
        service_type: "AUCTION",
        data_level: "AUCTION_CAMPAIGN",
        dimensions: ["campaign_id", "stat_time_day"],
        metrics: [
          "spend",
          "reach",
          "impressions",
          "clicks",
          "cost_per_result",
          "cpm",
          "cpc",
        ],
        start_date: this.start_date,
        end_date: this.end_date,
        plaform: this.selectedPlatform,
        status: this.selectedStatus,
        multi_adv_report_in_utc_time: false,
        order_type: "ASC", //
        filtering: [{ field_name: "", filter_type: "", filter_value: "" }], //tiktok filter nullable
        matrices: ["impressions", "swipes", "native_leads", "spend"],
        adAccountId: "", // first one from /campaign-ads/snapchat/get/all/ad-accounts/213af24c-d74a-42ce-93bd-18fa01873319
      };
      await apiClient
        .get("/campaign-ads/tiktok/advertiser")
        .then((response) => {
          requestData.advertiser_ids = response.data.data.data.list.map(
            (l) => l.advertiser_id
          );
          apiClient
            .get("/campaign-ads/snapchat/get/all-organizations")
            .then((response) => {
              console.log();
              apiClient
                .get(
                  `/campaign-ads/snapchat/get/all/ad-accounts/${response.data.data.organizations[0].organization.id}`
                )
                .then((response) => {
                  requestData.adAccountId = response.data.data.id;
                  // Fetch campaign data
                  apiClient
                    .post("/all/socials/performance", requestData)
                    .then((response) => {
                      console.log(response);
                      if (response.data && response.data.data) {
                        response.data.data.snapchat.performance.campaigns.forEach(
                          (item) => {
                            this.tableData.push({
                              platform: "snapchat",
                              ad_account_id: item.ad_account_id,
                              id: item.id,
                              checked: false,
                              name: item.name,
                              status: item.status,
                              start_time: item.start_time,
                              end_time: item.end_time,
                              delivery_status: item.delivery_status,
                              lifetime_spend_cap_micro:
                                item.lifetime_spend_cap_micro,
                              native_leads: item?.stats?.native_leads,
                              calculations: {
                                costPerClick:
                                  item.calculations?.costPerClick || null,
                                eCPM: item.calculations?.eCPM || null,
                                eCPC: item.calculations?.eCPC || null,
                              },
                              stats: {
                                impressions: item.stats?.impressions || 0,
                                swipes: item.stats?.swipes || 0,
                              },
                            });
                          }
                        );
                        this.clicks =
                          response.data.data.snapchat.performance.summations.swipesSum;
                        this.leads =
                          response.data.data.snapchat.performance.summations.leadsSum;
                        this.campaignNumber =
                          response.data.data.snapchat.performance.summations.campaign_sum;
                        this.amountSpent =
                          response.data.data.snapchat.performance.summations.spendSum;
                        this.impressions =
                          response.data.data.snapchat.performance.summations.impressionSum;
                        this.loadingFortable = false;
                        this.data.datasets[0].data[0] =
                          response.data.data.snapchat.performance.summations.impressionSum;
                        this.data.datasets[0].data[1] =
                          response.data.data.snapchat.performance.summations.spendSum;
                        this.data.datasets[0].data[2] =
                          response.data.data.snapchat.performance.summations.leadsSum;
                        this.data.datasets[0].data[3] =
                          response.data.data.snapchat.performance.summations.swipesSum;
                        this.data.datasets[0].data[4] =
                          response.data.data.snapchat.performance.summations.campaign_sum;
                        this.data.datasets[1].data[0] =
                          response.data.data.tiktok.performance.summations.impressionSum;
                        this.data.datasets[1].data[1] =
                          response.data.data.tiktok.performance.summations.spendSum;
                        this.data.datasets[1].data[2] =
                          response.data.data.tiktok.performance.summations.leadsSum;
                        this.data.datasets[1].data[3] =
                          response.data.data.tiktok.performance.summations.swipesSum;
                        this.data.datasets[1].data[4] =
                          response.data.data.tiktok.performance.summations.campaign_sum;
                      } else {
                        console.error("Response data is missing or empty.");
                        this.tableData = [];
                      }
                      this.title_date = this.start_date
                        ? "From " +
                          " : " +
                          this.start_date +
                          " To " +
                          " : " +
                          this.end_date
                        : "Last 30 Days";
                    })
                    .catch((error) =>
                      console.error(
                        "Error:",
                        error.response ? error.response.data : error.message
                      )
                    );
                });
            });
        })
        .catch((error) =>
          console.error(
            "Error:",
            error.response ? error.response.data : error.message
          )
        );
    },
    ApplyFilter() {
      this.getData();
    },
    formatNumber(number) {
      return number
        .toFixed(2) // Ensure two decimal places
        .replace(/\d(?=(\d{3})+\.)/g, "$&,") // Add commas for thousands
        .replace(/\.00$/, "");
    },
  },
  watch: {
    checkAll(newValue) {
      // Update all rows when the "select all" checkbox changes
      this.filteredTableData.forEach((row) => {
        row.checked = newValue;
      });
    },
    selectedDate() {
      if (this.selectedDate.length > 0) {
        let dates = this.getFirstAndLastDate(this.selectedDate);
        this.start_date = dates.firstDate;
        this.end_date = dates.lastDate;
      } else {
        this.start_date = null;
        this.end_date = null;
      }
    },
  },
  async mounted() {
    this.getData();
  },

  computed: {
    filteredTableData() {
      return this.tableData.filter((row) => {
        const isStatusMatch =
          !this.selectedStatus || row.status === this.selectedStatus;
        const isDateInRange =
          !this.selectedDate.length ||
          (new Date(row.start_time) >= new Date(this.selectedDate[0]) &&
            new Date(row.end_time) <= new Date(this.selectedDate[1]));

        const isPlatformMatch =
          !this.selectedPlatform ||
          row.platform === this.selectedPlatform.platform;

        return isStatusMatch && isDateInRange && isPlatformMatch;
      });
    },
  },
};
</script>
