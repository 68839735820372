<template>
    <v-dialog :model-value="modelValue" v-bind="$attrs" @update:model-value="$emit('update:modelValue', $event)">
      <slot></slot>
    </v-dialog>
  </template>
  
  <script>
  export default {
    name: 'BaseDialog',
    props: {
      modelValue: {
        type: Boolean,
        required: true
      }
    },
    emits: ['update:modelValue']
  }
  </script>